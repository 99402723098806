import {
	STATUS,
	NAME,
	SET_AUTHORIZATION_TOKEN,
	SECTIONS,
	GET_SECTION_DATA,
	SET_INITIAL_PAGE_ID,
	SET_PAGE_ID,
	SET_THEME,
	SET_OPEN_MODE,
	SET_EXPIRED,
	SET_TERMS
} from '../constants/reducers';

export function setLinks(libSections) {
	return {
		type: SECTIONS,
		payload: {libSections}
	}
}

export function setTheme(themeId) {
	return {
		type: SET_THEME,
		payload: {themeId}
	}
}

export function setOpenMode(value) {
	return {
		type: SET_OPEN_MODE,
		payload: {value}
	}
}

export function setInitialPageId(initialPageId) {
	return{
		type: SET_INITIAL_PAGE_ID,
		payload: {initialPageId}
	}
}

export function setPageId(pageId) {
	return{
		type: SET_PAGE_ID,
		payload: {pageId}
	}
}

export function setStatus(status) {
	return {
		type: STATUS,
		payload: {status}
	}
}

export function setExpired(value) {
	return {
		type: SET_EXPIRED,
		payload: {value}
	}
}

export function setName(name) {
	return {
		type: NAME,
		payload: {name}
	}
}

export function setAuthorizationToken(authorizationToken) {
  return {
    type: SET_AUTHORIZATION_TOKEN,
    payload: {authorizationToken}
  }
}

export function getSectionData(section) {
	return {
		type: GET_SECTION_DATA,
		payload: {...section}
	}
}

export function setTermsAgreed(value) {
	return {
		type: SET_TERMS,
		payload: value
	}
}