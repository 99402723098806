import React from 'react';
import styled from 'styled-components';
import {BLUE, GRAY60} from '../constants/colors'

export const Title = styled.h1`
    font-family: Quicksand;
    font-size: 42px;
    line-height: 52px;
    @media screen and (max-width: 959px) {
            font-size: 30px;
            text-align: center;
    }
`;

export const SubTitle = styled.h3`
    font-family: Quicksand;
    font-size: ${props => props.fontSize ? props.fontSize : '24px'};
    font-weight: ${props => props.light ? '500' : 'normal'};
    margin: ${props => props.margin ? props => props.margin : '0'};
    color: ${props => props.inverted ? 'white' : ''};
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    display: flex;
        @media screen and (max-width: 959px) {
            font-size: 18px;
    }
`;

export const TextLink = styled.a`
    color: ${BLUE};
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    text-decoration: none;
`;

export const TextRegular = styled.p`
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: normal;
    line-height: ${props => props.lineHeight ? props.lineHeight : '24px'};
    letter-spacing: 0.44px;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    margin: ${props => props.margin ? props.margin : '0'};
    color: ${props => props.color ? props.color : ''};  
    img {
        max-width: 100%;
    }
    @media screen and (max-width: 959px) {
        a {
            word-break: break-all;
        }
    }
    
`;

export const TextRegularLight = styled.p`
    font-family: 'Roboto Light';
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.44px;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    margin: ${props => props.margin ? props.margin : '0'};
    color: ${props => props.light ? GRAY60 : ''};  
`;

