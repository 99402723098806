import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {Redirect} from 'react-router';
import {ButtonR, LineSpacer, InputDefault} from '../shared/formElements'
import {Title, TextLink, TextRegular} from '../shared/typography';
import css from './login.module.css';
import Logo from '../components/logo';
import axios from 'axios';
import {stringify} from "qs";
import {setStatus, setName, setAuthorizationToken, setExpired} from '../actions';
import {connect} from 'react-redux';
import {api} from '../constants/endpoints';
import Preloader from '../svg/Preloader';

class Login extends Component {

    state = {
        loading: false,
        success: false,
        message: '',
        password: '',
        c_password: '',
        emailInitial: true,
        email: '',
        passwordInitial: true,
        errorResponse: null,
        redirectToHome: false,
        token: null,
        redirectToLogin: false,
    };

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('t')
        if(token){
            this.setState({token: token})
        }  else this.setState({redirectToHome:true});
    }


    handleChange = prop => event => {
        this.setState({[prop]: event.target.value});
    };

    handleEnterPress = event => {
        if (event.key === 'Enter') {
            this.reset();
        }
    };

    handleError(error) {
        this.setState({loading: false, errorResponse: error});
    }

    isValid() {
        let trimPassword = this.state.password.trim();
        let trimEmail = this.state.email.trim();
        this.setState({
            email: trimEmail,
            password: trimPassword
        });
        return trimEmail && trimPassword
            && trimEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            && trimPassword.length >= 6;
    }

    reset() {
        if (this.isValid()) {
            this.setState({loading: true});
            const postData = stringify({
                email: this.state.email,
                token: this.state.token,
                password: this.state.password,
                password_confirmation: this.state.c_password,
            });

            axios.post(`${api}password/reset`, postData)
                .then(response => {
                    this.setState({success: response.data.success, message: response.data.message, loading: false, errorResponse: false, redirectToLogin: true});

                })
                .catch((error) => {
                    if (error.response) {
                        this.handleError(error.response.data);
                    }
                });
        } else {
            this.setState({
                emailInitial: false,
                passwordInitial: false,
            });
        }
    }

    render() {

        const {password, c_password, email, emailInitial, passwordInitial, loading, errorResponse, success, message,redirectToHome, redirectToLogin} = this.state;
        if (redirectToHome) return <Redirect to={"/"}/>;
        if (redirectToLogin) return <Redirect to={"/login"}/>;

        return (
            <>
                <Box>
                    <Container maxWidth="xs">
                        <Grid container alignItems="center" direction="column" className={css.wrapper}>
                            <Logo className={css.logo} justify="center"/>
                            <Title className={css.login}>Reset Password</Title>

                            <InputDefault id="email" label="Email" variant="outlined" fullWidth
                                          error={!emailInitial && !(email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) || Boolean(this.state.errorResponse)}
                                          id="emailNew"
                                          value={email}
                                          onChange={this.handleChange('email')}
                                          onKeyPress={this.handleEnterPress}
                                          inputProps={{
                                              'aria-label': 'EmailNew',
                                          }}
                                          type='email'
                            />

                            <InputDefault id="password" label="Password" variant="outlined" fullWidth
                                          error={!passwordInitial && !(password && password.length >= 6)}
                                          type={'password'}
                                          value={password}
                                          onKeyPress={this.handleEnterPress}
                                          onChange={this.handleChange('password')}
                                          autoComplete="new-password"
                            />

                            <InputDefault id="c_password" label="Repeat Password" variant="outlined" fullWidth
                                          type={'password'}
                                          error={password !== c_password}
                                          onKeyPress={this.handleEnterPress}
                                          onChange={this.handleChange('c_password')}
                            />

                            <ButtonR fullWidth style={{marginTop:"15px"}}
                                     onClick={() => {
                                         this.reset()
                                     }}
                            >Reset {loading && <Preloader/>} </ButtonR>

                            {errorResponse && <TextRegular style={{color: "red"}}>
                                {errorResponse.message || 'Error'}
                            </TextRegular>}
                            {success && <TextRegular style={{color: "green"}}>
                                {message || 'Password has been changed'}
                            </TextRegular>}



                        </Grid>
                    </Container>
                </Box>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    expired: state.auth.expired,
    openMode: state.auth.openMode,
});

export default connect(mapStateToProps, {
    setStatus, setName, setAuthorizationToken, setExpired
})(Login);