export const STATUS = 'STATUS';
export const NAME = 'NAME';
export const SET_AUTHORIZATION_TOKEN = 'SET_AUTHORIZATION_TOKEN';
export const SET_INITIAL_PAGE_ID = 'SET_INITIAL_PAGE_ID';
export const SET_PAGE_ID = 'SET_PAGE_ID';
export const SET_THEME = 'SET_THEME';
export const SECTIONS = 'SECTIONS';
export const GET_SECTION_DATA = 'SECTION_DATA';
export const SET_OPEN_MODE = 'SET_OPEN_MODE';
export const SET_EXPIRED = 'SET_EXPIRED';
export const SET_TERMS = 'SET_TERMS';