import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
	loadingWrap: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		padding: '0 20px'
	}
};

const Preloader = (props) => {
	return (
		<div className={props.classes.loadingWrap}>
			<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			     width="30px" height="30px" viewBox="0 0 50 50">
				<path fill={props.color ? props.color : "#fff"}
				      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
					<animateTransform attributeType="xml"
					                  attributeName="transform"
					                  type="rotate"
					                  from="0 25 25"
					                  to="360 25 25"
					                  dur="0.6s"
					                  repeatCount="indefinite"/>
				</path>
			</svg>
		</div>
	);
};

export default withStyles(styles)(Preloader);
