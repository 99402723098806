import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import store from '../../store';
//import exporting from "highcharts/modules/exporting";
//exporting(Highcharts);


export const ChartBlock = ({options, theme}) => {
    if (!options) return null;
    
    let prev = 0;

    let {auth:{status}} = store.getState();

    const mainOptions = {
        tooltip: {
            enabled: !!status
        },
        point: false,
        credits: {
            enabled: false
        },

        //theming
        colors: theme.chartColors ? theme.chartColors : '',
        chart: {
            backgroundColor: theme ? theme.background : '',
            color: theme ? theme.color : '',
            spacingLeft: 40,
            style: {},
            events: {
                load: function () {

                    if(this.options.chart.forExport) {
                        this.renderer.image('https://i.postimg.cc/1XvkKWYs/Randomtrack.png', 520, 375, 70, 14)
                            .add();
                    }

                    },

            },
        },
        legend: {
            itemStyle: {
                color: theme.legend,
            },
            ...options.legend
        },

        xAxis: {
            gridLineColor: '#707073',
         //   tickInterval: 1000,
            gridLineDashStyle: theme.gridLineDashStyle,
            labels: {
                style: {
                    color: theme.labels
                }
            },
            ...options.xAxis,
            title: {
                style: {
                    color: theme.legend,
                },
                text: options.xAxis.title.text,
            },
        },
        labels: {
            style: {
                color: theme.legend,
            }
        },
        navigation: {
            buttonOptions: {
                align: 'left',
                y: -10,
                x: -30,
                menuItemStyle: theme.color,
                theme: {
                    fill: theme.background,
                    stroke: theme.exportStroke,
                    cursor: 'pointer',
                    states: {
                        select: {
                            fill: theme.exportStrokeHover
                        },
                        hover: {
                            fill: theme.background,
                            stroke: theme.exportStrokeHover
                        },
                    }
                }
            }
        },

        exporting: {
            // chartOptions: {
            //     chart: {
            //         plotBackgroundImage: 'https://i.stack.imgur.com/E1r9X.png'
            //     }
            // },
            buttons: {
                contextButton: {
                    symbolStroke: theme.color,
                    menuItems: [
                        // 'printChart',
                        // 'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG'
                    ]
                }
            }
        },

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        enabled: false
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: -30,
                            y: 0
                        },
                        title: {
                            text: null
                        }
                    },
                    subtitle: {
                        text: null
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        }
    };

    let yAxisAll = [];
    options.yAxis.forEach(yAxis => {
        const yAxisTheme = {
          //  max: 15,
            startOnTick: false,
            softThreshold: true,
            gridLineColor: '#78787a',
            gridLineDashStyle: theme.gridLineDashStyle,
            title: {
                style: {
                    color: theme.legend
                },
                opposite: yAxis.opposite,
                text: yAxis.title.text,
            },
            labels: {
                style: {
                    color: theme.labels
                },
                formatter: function(){
                        // return Math.abs(this.value) > 999 ? Math.sign(this.value)*((Math.abs(this.value)/1000).toFixed(1)) + 'k' : Math.sign(this.value)*Math.abs(this.value)
                        return Math.sign(this.value)*Math.abs(this.value)
                }
            },

        };
        yAxisAll.push({...yAxis, ...yAxisTheme})
    })
    let yAxisObj = {yAxis: yAxisAll}

    return (
        <HighchartsReact style={{background: "green"}}
                         highcharts={Highcharts}
                         options={{...options, ...mainOptions, ...yAxisObj}}
        />
    );
};