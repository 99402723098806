import React, {Component} from 'react';
import {Container, Box, Grid, Button} from '@material-ui/core';
import {Title, TextRegular, SubTitle, TextLink} from '../shared/typography';
import {BoxWrapper} from '../shared/layout';
import {ButtonQ} from '../shared/formElements';
import axios from "axios";
import {connect} from "react-redux";
import {setAuthorizationToken, setName, setStatus} from "../actions";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {api} from "../constants/endpoints";
import {BLUE, BLUE_SMOOTH, GRAY30} from "../constants/colors";
import styled from "styled-components";
import Preloader from "../svg/Preloader";
import Footer from "../components/Footer";


class Terms extends Component {
    state = {
        declined: false,
        loading: false,
        termsContent: null,

    };

    getTermsText = () => {
        this.setState({loading: true});
        axios.get(`${api}terms`).then(response => {
                this.setState({termsContent: response.data.data[0].content});
                this.setState({loading: false});
                this.setState({isLoaded: true});
            }, error => {
                this.setState({
                    error
                });
            }
        )
    };


    componentDidMount() {
        this.getTermsText();
    }

    render() {
        let {status, openMode, popup} = this.props;
        let {isLoaded, loading} = this.state;

        return (
            <BoxWrapper>
                <Container maxWidth={"lg"}>
                    <Container maxWidth={"md"} style={{padding: 0}}>
                        <Title style={{margin: "40px 0 30px"}}>Terms & Conditions</Title>
                        {loading && <Grid container justify={"center"} style={{paddingTop: "35px"}}><Preloader
                            color={BLUE}/></Grid>}
                        <Grid container spacing={3}>
                            <Grid item container md={12} xs={12}>
                                <div style={{paddingBottom: popup ? "80px" : "" }} dangerouslySetInnerHTML={{__html: this.state.termsContent}}/>
                                {!popup ? <Footer/> : null}
                            </Grid>
                        </Grid>
                    </Container>

                </Container>

                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    open={this.state.declined}
                    onClose={this.handleDecline}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={true}>
                        <div style={{
                            background: "#FFF",
                            padding: "25px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <TextRegular fontSize={20} margin={"0 0 40px"}>
                                You should confirm Terms and Conditions to continue.</TextRegular>
                            <div><ButtonQ onClick={this.handleDecline}>Close</ButtonQ></div>
                        </div>
                    </Fade>
                </Modal>


            </BoxWrapper>
        )
    }
}



const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    openMode: state.auth.openMode,
});

export default connect(mapStateToProps, {
    setStatus, setName, setAuthorizationToken
})(Terms);