import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import LogoSVG from '../svg/logo';
import {SubTitle} from '../shared/typography';
import {Link} from 'react-router-dom'
import styled from 'styled-components';

export default class Logo extends Component {

    render() {
        return (
            <LogoLink to="/" justify={this.props.justify} onClick={this.handleMenu}>
                <LogoSVG size={this.props.size} {...this.props}/> <SubTitle {...this.props} margin="0 10px" fontSize={this.props.fontSize}>RandomTrack</SubTitle>
            </LogoLink>
        )
    }
}
const LogoLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    justify-content: ${props => props.justify};
    &:visited{
        color: initial;
    }
`;