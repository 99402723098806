import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import {Redirect} from 'react-router';
import {ButtonR, LineSpacer, InputDefault} from '../shared/formElements'
import {Title, TextLink, TextRegular} from '../shared/typography';
import css from './login.module.css';
import Logo from '../components/logo';
import axios from 'axios';
import {stringify} from "qs";
import {setStatus, setName, setAuthorizationToken, setExpired} from '../actions';
import {connect} from 'react-redux';
import {api} from '../constants/endpoints';
import Preloader from '../svg/Preloader';

import {UserConfirmation} from "../components/UserConfirmation";
import {TermsConfirmation} from "../components/TermsConfirmation";
import {RESTORE_URI} from "../constants/sitemap";



class Login extends Component {

    state = {
        loading: false,
        password: '',
        email: '',
        emailInitial: true,
        passwordInitial: true,
        showPassword: false,
        token: {
            id: "",
            name: "",
            email: "",
            status: 0,
            permission: 0,
            createdAt: "",
            updatedAt: "",
            deletedAt: null,
            access_token: ''
        },
        status: null,
        errorResponse: null,
        responseData: null,

        validationPopup: false,
        validatedUser: false,
        termsAgreed: false,
    };

    componentDidMount() {
        if (this.props.authorizationToken || localStorage.getItem('access_token')) {
            this.setState({redirectToReferrer: true});
        }
    }

    handleSetStatus = status => this.props.setStatus(status);
    handleSetName = name => this.props.setName(name);
    handleSetAuthorizationToken = token => this.props.setAuthorizationToken(token);

    setSession(authResult) {
        this.handleSetAuthorizationToken(authResult.access_token);
        // this.handleSetStatus(authResult.status);
        this.setState({status: authResult.status});
        this.handleSetName(authResult.name);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + authResult.access_token;
        localStorage.setItem('access_token', authResult.access_token);
        localStorage.setItem('status', authResult.status);
        localStorage.setItem('name', authResult.name);
    }

    handleLogout = () => {
        const postData = stringify({});

        axios.post(`${api}logout`, postData)
            .then(response => {
                if (response.data.success) {
                    axios.defaults.headers.common['Authorization'] = undefined;
                    localStorage.clear();
                    this.handleSetAuthorizationToken(null);
                    this.handleSetStatus(null);
                    this.handleSetName(null);
                    this.props.history.push('/');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };


    handleChange = prop => event => {
        this.setState({[prop]: event.target.value}); //, [`${prop}Initial`]: false
    };



    handleEnterPress = event => {
        if (event.key === 'Enter') {
            this.login();
        }
    };


    handleLogin(data) {
        this.setSession(data);
        this.setState({token: data, loading: false});
        this.setState({validationPopup: true})
    }


    handleLoginError(error) {
        this.setState({loading: false, errorResponse: error});
    }

    isValid() {
        let trimPassword = this.state.password.trim();
        let trimEmail = this.state.email.trim();
        this.setState({
            email: trimEmail,
            password: trimPassword
        });
        return trimEmail && trimPassword
            && trimEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            && trimPassword.length >= 6;
    }

    login() {
        if (this.isValid()) {
            this.setState({loading: true});
            const postData = stringify({
                username: this.state.email,
                password: this.state.password,
                grant_type: "password",
                client_id: 2,
                client_secret: '9evEq0YKSKGT18aVbVCyfeHM7oqDboEkzvviUeBE'
            });

            axios.post(`${api}oauth/token`, postData)
                .then(response => {
                    this.handleLogin(response.data[0]);
                //    this.setState({responseData: response.data[0]})
                    this.props.setExpired(false);

                })
                .catch((error) => {
                    if (error.response) {
                        this.handleLoginError(error.response.data);
                    }
                });
        } else {
            this.setState({
                emailInitial: false,
                passwordInitial: false,
            });
        }
    }

    validateOnCancel = () => {
        this.setState({validationPopup: false, validatedUser: false})
        this.handleLogout();
    }

    validateOnConfirm = () => {
        this.setState({validationPopup: false, validatedUser: true})
    }

    termsOnCancel = () => {
        this.setState({validatedUser: false, termsAgreed: false})
        this.handleLogout();
    }

    termsOnConfirm = () => {
        this.setState({validatedUser: false, termsAgreed: true, redirectToReferrer: true})
        this.handleSetStatus(this.state.status);
    }


    render() {
        const {errorResponse, loading, password, email, passwordInitial, emailInitial} = this.state;

        if (this.props.status || this.props.status === 0) return <Redirect to={"/"}/>;

        return (
            <>
                <UserConfirmation
                    open={this.state.validationPopup}
                    onCancel={this.validateOnCancel}
                    onConfirm={this.validateOnConfirm}
                />
                <TermsConfirmation
                    open={this.state.validatedUser}
                    onCancel={this.termsOnCancel}
                    onConfirm={this.termsOnConfirm}
                />
                <Box>
                    <Container maxWidth="xs">
                        <Grid container alignItems="center" direction="column" className={css.wrapper}>
                            <Logo className={css.logo} justify="center"/>
                            <Title className={css.login}>Log In</Title>
                            {this.props.expired ?
                                <p style={{color: "red"}}>Your session is expired. Please login again.</p> : ''}

                            <InputDefault label="Email" variant="outlined" fullWidth
                                          error={!emailInitial && !(email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))}
                                          id="email"
                                          value={email}
                                          onChange={this.handleChange('email')}
                                          onKeyPress={this.handleEnterPress}
                                          inputProps={{
                                              'aria-label': 'Email',
                                          }}
                            />
                            <InputDefault id="password" label="Password" variant="outlined" fullWidth
                                          error={!passwordInitial && !(password && password.length >= 6)}
                                          id="adornment-password"
                                          type={this.state.showPassword ? 'text' : 'password'}
                                          value={password}
                                          onChange={this.handleChange('password')}
                                          onKeyPress={this.handleEnterPress}

                            />
                            {/*<TextLink className={css.textLink} fontSize="14px" href="/">Restore Password</TextLink>*/}
                            <ButtonR fullWidth style={{marginTop:"15px"}}
                                     onClick={() => {
                                         this.login()
                                     }}

                            >Log In {loading && <Preloader/>} </ButtonR>

                            {errorResponse && <TextRegular style={{color: "red"}}>
                                {errorResponse.message || 'Error'}
                            </TextRegular>}
                            <LineSpacer margin="30px 0 20px "/>
                            <TextRegular>I'm a new user. <TextLink href="/register">Registration</TextLink>
                            </TextRegular>
                            <TextLink href={RESTORE_URI} style={{marginTop:"15px"}}>Restore Password</TextLink>

                        </Grid>
                    </Container>
                </Box>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    expired: state.auth.expired,
    openMode: state.auth.openMode,
});

export default connect(mapStateToProps, {
    setStatus, setName, setAuthorizationToken, setExpired
})(Login);