import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {api} from "../constants/endpoints";
import {setAuthorizationToken, setName, setStatus} from "../actions";



const useInterval = (callback, delay) => {
    const savedCallback = useRef()

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay !== null) {
            const id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

export const CheckAuth = (props) => {
    const [isUserAuth, setIsUserAuth] = useState(false)
    const dispatch = useDispatch()
    const checkUserAuth = () => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

        axios.get(`${api}check_auth`).then(response => {
            if (response) {
                if (response.data && response.data.data) {
                    setIsUserAuth(response.data.data.auth)
                    props.onChangeAuth(response.data.data.auth)
                }

                if (response.data.user_access_level || response.data.user_access_level === 0) {
                    const userTypeStatus = response.data.user_access_level < 0 ? 0 : response.data.user_access_level

                    dispatch(setStatus(userTypeStatus))
                }
            }
            }, error => {
                console.log(error)
                const {status} = error.response

                if (status === 401) {
                    axios.defaults.headers.common['Authorization'] = undefined
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('status')
                    localStorage.removeItem('name')
                    dispatch(setAuthorizationToken(null))
                    dispatch(setStatus(null))
                    dispatch(setName(null))
                    props.onChangeAuth(false)
                }
            }
        )
    }

    useInterval(() => {
        checkUserAuth()
    }, 1000 * 60)

    useEffect(() => {
        checkUserAuth()
    }, [])

    return null
}