export const MAIN_MENU = [
    {title: 'Services', link: '/services'},
    {title: 'Library', link: '/library'},
    {title: 'Contact Us', link: '/contact'}
];
export const MOBILE_MAIN_MENU = [
    {title: 'Services', link: '/services'},
    {title: 'Library', link: '/library'},
    {title: 'Terms & Conditions', link: '/terms'},
    {title: 'Privacy Policy', link: '/privacy'},
    {title: 'Contact Us', link: '/contact'}
];

export const FOOTER_MENU = [
        {title: 'Services', link: '/services'},
        {title: 'Library', link: '/library'},
        {title: 'Contact Us', link: '/contact'},
        {title: 'Privacy Policy', link: '/privacy'},
        {title: 'Terms & Conditions', link: '/terms'},
];