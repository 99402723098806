import React, {useEffect, useState} from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {makeStyles} from '@material-ui/core/styles';
import {TextRegular} from "../shared/typography";
import {ButtonQ} from "../shared/formElements";
import {useSelector} from "react-redux";
import Terms from "../pages/Terms";
import styled from "styled-components";
import {GRAY30} from "../constants/colors";
import {useHistory} from "react-router";


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    text:{
        maxWidth: '650px'
    },
    buttonsWrap:{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginTop: '40px'
    }
}));



export const TermsConfirmation = (props) => {
    const classes = useStyles();
    const openMode = useSelector((state) => state.auth.openMode);
    const history = useHistory();

    const [open, setOpen] = useState(props.open);
    const [scrolledDown, setScrolledDown] = useState(false);

    let handleScrollDown = (e) =>{

        const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop - 100) <= e.target.clientHeight;

        if (bottom) {
            setScrolledDown(true)
        }
    };

    useEffect(() => {

        setOpen(props.open)

    }, [props.open]);



    return (
        <Modal
            open={open}
            onClose={props.onCancel}
            closeAfterTransition
            BackdropComponent={Backdrop}

            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div style={{margin:"10px"}}>
                    <div onScroll={handleScrollDown}
                         style={{
                             width: "100%",
                             maxWidth: "600px",
                             height: "100vh",
                             background: "#FFF",
                             padding: "25px",
                             overflow: "auto",
                             position: "fixed",
                             left: "50%",
                             top: "50%",
                             transform: "translate(-50%, -50%)"
                         }}>
                        <Terms style={{paddingBottom:"60px"}} openMode popup history={history}/>
                    </div>
                    <ModalButtonsContainer>
                        <ModalButton disabled={!scrolledDown} onClick={props.onConfirm}>I agree terms and conditions</ModalButton>
                        <ModalButton onClick={props.onCancel}>I disagree terms and conditions</ModalButton>
                    </ModalButtonsContainer>
                </div>
            </Fade>
        </Modal>
       )
};


const ModalButton = styled(ButtonQ)`
    .MuiButton-label{
        font-size: 14px;
       
    }
    &.Mui-disabled{
        background-color: gray;
    }
        @media screen and (max-width: 960px) {
     width: 45%;   
    }
`;

const ModalButtonsContainer = styled.div`
    display:flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    padding: 15px 0;
    background: white; 
    width:100%;
    max-width: 650px;

`;