export const chart1 =
    {
        xAxis: {
            categories: [
                "10/3/2003",
                "10/6/2003",
                "10/7/2003",
                "10/8/2003",
                "10/9/2003",
                "10/10/2003",
                "10/13/2003",
                "10/14/2003",
                "10/15/2003",
                "10/16/2003",
                "10/17/2003",
                "10/20/2003",
                "10/21/2003",
                "10/22/2003",
                "10/23/2003",
                "10/24/2003",
                "10/27/2003",
                "10/28/2003",
                "10/29/2003",
                "10/30/2003",
                "10/31/2003",
                "11/3/2003",
                "11/4/2003",
                "11/5/2003",
                "11/6/2003",
                "11/7/2003",
                "11/10/2003",
                "11/11/2003",
                "11/12/2003",
                "11/13/2003",
                "11/14/2003",
                "11/17/2003",
                "11/18/2003",
                "11/19/2003",
                "11/20/2003",
                "11/21/2003",
                "11/24/2003",
                "11/25/2003",
                "11/26/2003",
                "11/27/2003",
                "11/28/2003",
                "12/1/2003",
                "12/2/2003",
                "12/3/2003",
                "12/4/2003",
                "12/5/2003",
                "12/8/2003",
                "12/9/2003",
                "12/10/2003",
                "12/11/2003",
                "12/12/2003",
                "12/15/2003",
                "12/16/2003",
                "12/17/2003",
                "12/18/2003",
                "12/19/2003",
                "12/22/2003",
                "12/23/2003",
                "12/24/2003",
                "12/25/2003",
                "12/26/2003",
                "12/29/2003",
                "12/30/2003",
                "12/31/2003",
                "1/1/2004",
                "1/2/2004",
                "1/5/2004",
                "1/6/2004",
                "1/7/2004",
                "1/8/2004",
                "1/9/2004",
                "1/12/2004",
                "1/13/2004",
                "1/14/2004",
                "1/15/2004",
                "1/16/2004",
                "1/19/2004",
                "1/20/2004",
                "1/21/2004",
                "1/22/2004",
                "1/23/2004",
                "1/26/2004",
                "1/27/2004",
                "1/28/2004",
                "1/29/2004",
                "1/30/2004",
                "2/2/2004",
                "2/3/2004",
                "2/4/2004",
                "2/5/2004",
                "2/6/2004",
                "2/9/2004",
                "2/10/2004",
                "2/11/2004",
                "2/12/2004",
                "2/13/2004",
                "2/16/2004",
                "2/17/2004",
                "2/18/2004",
                "2/19/2004",
                "2/20/2004",
                "2/23/2004",
                "2/24/2004",
                "2/25/2004",
                "2/26/2004",
                "2/27/2004",
                "3/1/2004",
                "3/2/2004",
                "3/3/2004",
                "3/4/2004",
                "3/5/2004",
                "3/8/2004",
                "3/9/2004",
                "3/10/2004",
                "3/11/2004",
                "3/12/2004",
                "3/15/2004",
                "3/16/2004",
                "3/17/2004",
                "3/18/2004",
                "3/19/2004",
                "3/22/2004",
                "3/23/2004",
                "3/24/2004",
                "3/25/2004",
                "3/26/2004",
                "3/29/2004",
                "3/30/2004",
                "3/31/2004",
                "4/1/2004",
                "4/2/2004",
                "4/5/2004",
                "4/6/2004",
                "4/7/2004",
                "4/8/2004",
                "4/9/2004",
                "4/12/2004",
                "4/13/2004",
                "4/14/2004",
                "4/15/2004",
                "4/16/2004",
                "4/19/2004",
                "4/20/2004",
                "4/21/2004",
                "4/22/2004",
                "4/23/2004",
                "4/26/2004",
                "4/27/2004",
                "4/28/2004",
                "4/29/2004",
                "4/30/2004",
                "5/3/2004",
                "5/4/2004",
                "5/5/2004",
                "5/6/2004",
                "5/7/2004",
                "5/10/2004",
                "5/11/2004",
                "5/12/2004",
                "5/13/2004",
                "5/14/2004",
                "5/17/2004",
                "5/18/2004",
                "5/19/2004",
                "5/20/2004",
                "5/21/2004",
                "5/24/2004",
                "5/25/2004",
                "5/26/2004",
                "5/27/2004",
                "5/28/2004",
                "5/31/2004",
                "6/1/2004",
                "6/2/2004",
                "6/3/2004",
                "6/4/2004",
                "6/7/2004",
                "6/8/2004",
                "6/9/2004",
                "6/10/2004",
                "6/11/2004",
                "6/14/2004",
                "6/15/2004",
                "6/16/2004",
                "6/17/2004",
                "6/18/2004",
                "6/21/2004",
                "6/22/2004",
                "6/23/2004",
                "6/24/2004",
                "6/25/2004",
                "6/28/2004",
                "6/29/2004",
                "6/30/2004",
                "7/1/2004",
                "7/2/2004",
                "7/5/2004",
                "7/6/2004",
                "7/7/2004",
                "7/8/2004",
                "7/9/2004",
                "7/12/2004",
                "7/13/2004",
                "7/14/2004",
                "7/15/2004",
                "7/16/2004",
                "7/19/2004",
                "7/20/2004",
                "7/21/2004",
                "7/22/2004",
                "7/23/2004",
                "7/26/2004",
                "7/27/2004",
                "7/28/2004",
                "7/29/2004",
                "7/30/2004",
                "8/2/2004",
                "8/3/2004",
                "8/4/2004",
                "8/5/2004",
                "8/6/2004",
                "8/9/2004",
                "8/10/2004",
                "8/11/2004",
                "8/12/2004",
                "8/13/2004",
                "8/16/2004",
                "8/17/2004",
                "8/18/2004",
                "8/19/2004",
                "8/20/2004",
                "8/23/2004",
                "8/24/2004",
                "8/25/2004",
                "8/26/2004",
                "8/27/2004",
                "8/30/2004",
                "8/31/2004",
                "9/1/2004",
                "9/2/2004",
                "9/3/2004",
                "9/6/2004",
                "9/7/2004",
                "9/8/2004",
                "9/9/2004",
                "9/10/2004",
                "9/13/2004",
                "9/14/2004",
                "9/15/2004",
                "9/16/2004",
                "9/17/2004",
                "9/20/2004",
                "9/21/2004",
                "9/22/2004",
                "9/23/2004",
                "9/24/2004",
                "9/27/2004",
                "9/28/2004",
                "9/29/2004",
                "9/30/2004",
                "10/1/2004",
                "10/4/2004",
                "10/5/2004",
                "10/6/2004",
                "10/7/2004",
                "10/8/2004",
                "10/11/2004",
                "10/12/2004",
                "10/13/2004",
                "10/14/2004",
                "10/15/2004",
                "10/18/2004",
                "10/19/2004",
                "10/20/2004",
                "10/21/2004",
                "10/22/2004",
                "10/25/2004",
                "10/26/2004",
                "10/27/2004",
                "10/28/2004",
                "10/29/2004",
                "11/1/2004",
                "11/2/2004",
                "11/3/2004",
                "11/4/2004",
                "11/5/2004",
                "11/8/2004",
                "11/9/2004",
                "11/10/2004",
                "11/11/2004",
                "11/12/2004",
                "11/15/2004",
                "11/16/2004",
                "11/17/2004",
                "11/18/2004",
                "11/19/2004",
                "11/22/2004",
                "11/23/2004",
                "11/24/2004",
                "11/25/2004",
                "11/26/2004",
                "11/29/2004",
                "11/30/2004",
                "12/1/2004",
                "12/2/2004",
                "12/3/2004",
                "12/6/2004",
                "12/7/2004",
                "12/8/2004",
                "12/9/2004",
                "12/10/2004",
                "12/13/2004",
                "12/14/2004",
                "12/15/2004",
                "12/16/2004",
                "12/17/2004",
                "12/20/2004",
                "12/21/2004",
                "12/22/2004",
                "12/23/2004",
                "12/24/2004",
                "12/27/2004",
                "12/28/2004",
                "12/29/2004",
                "12/30/2004",
                "12/31/2004",
                "1/3/2005",
                "1/4/2005",
                "1/5/2005",
                "1/6/2005",
                "1/7/2005",
                "1/10/2005",
                "1/11/2005",
                "1/12/2005",
                "1/13/2005",
                "1/14/2005",
                "1/17/2005",
                "1/18/2005",
                "1/19/2005",
                "1/20/2005",
                "1/21/2005",
                "1/24/2005",
                "1/25/2005",
                "1/26/2005",
                "1/27/2005",
                "1/28/2005",
                "1/31/2005",
                "2/1/2005",
                "2/2/2005",
                "2/3/2005",
                "2/4/2005",
                "2/7/2005",
                "2/8/2005",
                "2/9/2005",
                "2/10/2005",
                "2/11/2005",
                "2/14/2005",
                "2/15/2005",
                "2/16/2005",
                "2/17/2005",
                "2/18/2005",
                "2/21/2005",
                "2/22/2005",
                "2/23/2005",
                "2/24/2005",
                "2/25/2005",
                "2/28/2005",
                "3/1/2005",
                "3/2/2005",
                "3/3/2005",
                "3/4/2005",
                "3/7/2005",
                "3/8/2005",
                "3/9/2005",
                "3/10/2005",
                "3/11/2005",
                "3/14/2005",
                "3/15/2005",
                "3/16/2005",
                "3/17/2005",
                "3/18/2005",
                "3/21/2005",
                "3/22/2005",
                "3/23/2005",
                "3/24/2005",
                "3/25/2005",
                "3/28/2005",
                "3/29/2005",
                "3/30/2005",
                "3/31/2005",
                "4/1/2005",
                "4/4/2005",
                "4/5/2005",
                "4/6/2005",
                "4/7/2005",
                "4/8/2005",
                "4/11/2005",
                "4/12/2005",
                "4/13/2005",
                "4/14/2005",
                "4/15/2005",
                "4/18/2005",
                "4/19/2005",
                "4/20/2005",
                "4/21/2005",
                "4/22/2005",
                "4/25/2005",
                "4/26/2005",
                "4/27/2005",
                "4/28/2005",
                "4/29/2005",
                "5/2/2005",
                "5/3/2005",
                "5/4/2005",
                "5/5/2005",
                "5/6/2005",
                "5/9/2005",
                "5/10/2005",
                "5/11/2005",
                "5/12/2005",
                "5/13/2005",
                "5/16/2005",
                "5/17/2005",
                "5/18/2005",
                "5/19/2005",
                "5/20/2005",
                "5/23/2005",
                "5/24/2005",
                "5/25/2005",
                "5/26/2005",
                "5/27/2005",
                "5/30/2005",
                "5/31/2005",
                "6/1/2005",
                "6/2/2005",
                "6/3/2005",
                "6/6/2005",
                "6/7/2005",
                "6/8/2005",
                "6/9/2005",
                "6/10/2005",
                "6/13/2005",
                "6/14/2005",
                "6/15/2005",
                "6/16/2005",
                "6/17/2005",
                "6/20/2005",
                "6/21/2005",
                "6/22/2005",
                "6/23/2005",
                "6/24/2005",
                "6/27/2005",
                "6/28/2005",
                "6/29/2005",
                "6/30/2005",
                "7/1/2005",
                "7/4/2005",
                "7/5/2005",
                "7/6/2005",
                "7/7/2005",
                "7/8/2005",
                "7/11/2005",
                "7/12/2005",
                "7/13/2005",
                "7/14/2005",
                "7/15/2005",
                "7/18/2005",
                "7/19/2005",
                "7/20/2005",
                "7/21/2005",
                "7/22/2005",
                "7/25/2005",
                "7/26/2005",
                "7/27/2005",
                "7/28/2005",
                "7/29/2005",
                "8/1/2005",
                "8/2/2005",
                "8/3/2005",
                "8/4/2005",
                "8/5/2005",
                "8/8/2005",
                "8/9/2005",
                "8/10/2005",
                "8/11/2005",
                "8/12/2005",
                "8/15/2005",
                "8/16/2005",
                "8/17/2005",
                "8/18/2005",
                "8/19/2005",
                "8/22/2005",
                "8/23/2005",
                "8/24/2005",
                "8/25/2005",
                "8/26/2005",
                "8/29/2005",
                "8/30/2005",
                "8/31/2005",
                "9/1/2005",
                "9/2/2005",
                "9/5/2005",
                "9/6/2005",
                "9/7/2005",
                "9/8/2005",
                "9/9/2005",
                "9/12/2005",
                "9/13/2005",
                "9/14/2005",
                "9/15/2005",
                "9/16/2005",
                "9/19/2005",
                "9/20/2005",
                "9/21/2005",
                "9/22/2005",
                "9/23/2005",
                "9/26/2005",
                "9/27/2005",
                "9/28/2005",
                "9/29/2005",
                "9/30/2005",
                "10/3/2005",
                "10/4/2005",
                "10/5/2005",
                "10/6/2005",
                "10/7/2005",
                "10/10/2005",
                "10/11/2005",
                "10/12/2005",
                "10/13/2005",
                "10/14/2005",
                "10/17/2005",
                "10/18/2005",
                "10/19/2005",
                "10/20/2005",
                "10/21/2005",
                "10/24/2005",
                "10/25/2005",
                "10/26/2005",
                "10/27/2005",
                "10/28/2005",
                "10/31/2005",
                "11/1/2005",
                "11/2/2005",
                "11/3/2005",
                "11/4/2005",
                "11/7/2005",
                "11/8/2005",
                "11/9/2005",
                "11/10/2005",
                "11/11/2005",
                "11/14/2005",
                "11/15/2005",
                "11/16/2005",
                "11/17/2005",
                "11/18/2005",
                "11/21/2005",
                "11/22/2005",
                "11/23/2005",
                "11/24/2005",
                "11/25/2005",
                "11/28/2005",
                "11/29/2005",
                "11/30/2005",
                "12/1/2005",
                "12/2/2005",
                "12/5/2005",
                "12/6/2005",
                "12/7/2005",
                "12/8/2005",
                "12/9/2005",
                "12/12/2005",
                "12/13/2005",
                "12/14/2005",
                "12/15/2005",
                "12/16/2005",
                "12/19/2005",
                "12/20/2005",
                "12/21/2005",
                "12/22/2005",
                "12/23/2005",
                "12/26/2005",
                "12/27/2005",
                "12/28/2005",
                "12/29/2005",
                "12/30/2005",
                "1/2/2006",
                "1/3/2006",
                "1/4/2006",
                "1/5/2006",
                "1/6/2006",
                "1/9/2006",
                "1/10/2006",
                "1/11/2006",
                "1/12/2006",
                "1/13/2006",
                "1/16/2006",
                "1/17/2006",
                "1/18/2006",
                "1/19/2006",
                "1/20/2006",
                "1/23/2006",
                "1/24/2006",
                "1/25/2006",
                "1/26/2006",
                "1/27/2006",
                "1/30/2006",
                "1/31/2006",
                "2/1/2006",
                "2/2/2006",
                "2/3/2006",
                "2/6/2006",
                "2/7/2006",
                "2/8/2006",
                "2/9/2006",
                "2/10/2006",
                "2/13/2006",
                "2/14/2006",
                "2/15/2006",
                "2/16/2006",
                "2/17/2006",
                "2/20/2006",
                "2/21/2006",
                "2/22/2006",
                "2/23/2006",
                "2/24/2006",
                "2/27/2006",
                "2/28/2006",
                "3/1/2006",
                "3/2/2006",
                "3/3/2006",
                "3/6/2006",
                "3/7/2006",
                "3/8/2006",
                "3/9/2006",
                "3/10/2006",
                "3/13/2006",
                "3/14/2006",
                "3/15/2006",
                "3/16/2006",
                "3/17/2006",
                "3/20/2006",
                "3/21/2006",
                "3/22/2006",
                "3/23/2006",
                "3/24/2006",
                "3/27/2006",
                "3/28/2006",
                "3/29/2006",
                "3/30/2006",
                "3/31/2006",
                "4/3/2006",
                "4/4/2006",
                "4/5/2006",
                "4/6/2006",
                "4/7/2006",
                "4/10/2006",
                "4/11/2006",
                "4/12/2006",
                "4/13/2006",
                "4/14/2006",
                "4/17/2006",
                "4/18/2006",
                "4/19/2006",
                "4/20/2006",
                "4/21/2006",
                "4/24/2006",
                "4/25/2006",
                "4/26/2006",
                "4/27/2006",
                "4/28/2006",
                "5/1/2006",
                "5/2/2006",
                "5/3/2006",
                "5/4/2006",
                "5/5/2006",
                "5/8/2006",
                "5/9/2006",
                "5/10/2006",
                "5/11/2006",
                "5/12/2006",
                "5/15/2006",
                "5/16/2006",
                "5/17/2006",
                "5/18/2006",
                "5/19/2006",
                "5/22/2006",
                "5/23/2006",
                "5/24/2006",
                "5/25/2006",
                "5/26/2006",
                "5/29/2006",
                "5/30/2006",
                "5/31/2006",
                "6/1/2006",
                "6/2/2006",
                "6/5/2006",
                "6/6/2006",
                "6/7/2006",
                "6/8/2006",
                "6/9/2006",
                "6/12/2006",
                "6/13/2006",
                "6/14/2006",
                "6/15/2006",
                "6/16/2006",
                "6/19/2006",
                "6/20/2006",
                "6/21/2006",
                "6/22/2006",
                "6/23/2006",
                "6/26/2006",
                "6/27/2006",
                "6/28/2006",
                "6/29/2006",
                "6/30/2006",
                "7/3/2006",
                "7/4/2006",
                "7/5/2006",
                "7/6/2006",
                "7/7/2006",
                "7/10/2006",
                "7/11/2006",
                "7/12/2006",
                "7/13/2006",
                "7/14/2006",
                "7/17/2006",
                "7/18/2006",
                "7/19/2006",
                "7/20/2006",
                "7/21/2006",
                "7/24/2006",
                "7/25/2006",
                "7/26/2006",
                "7/27/2006",
                "7/28/2006",
                "7/31/2006",
                "8/1/2006",
                "8/2/2006",
                "8/3/2006",
                "8/4/2006",
                "8/7/2006",
                "8/8/2006",
                "8/9/2006",
                "8/10/2006",
                "8/11/2006",
                "8/14/2006",
                "8/15/2006",
                "8/16/2006",
                "8/17/2006",
                "8/18/2006",
                "8/21/2006",
                "8/22/2006",
                "8/23/2006",
                "8/24/2006",
                "8/25/2006",
                "8/28/2006",
                "8/29/2006",
                "8/30/2006",
                "8/31/2006",
                "9/1/2006",
                "9/4/2006",
                "9/5/2006",
                "9/6/2006",
                "9/7/2006",
                "9/8/2006",
                "9/11/2006",
                "9/12/2006",
                "9/13/2006",
                "9/14/2006",
                "9/15/2006",
                "9/18/2006",
                "9/19/2006",
                "9/20/2006",
                "9/21/2006",
                "9/22/2006",
                "9/25/2006",
                "9/26/2006",
                "9/27/2006",
                "9/28/2006",
                "9/29/2006",
                "10/2/2006",
                "10/3/2006",
                "10/4/2006",
                "10/5/2006",
                "10/6/2006",
                "10/9/2006",
                "10/10/2006",
                "10/11/2006",
                "10/12/2006",
                "10/13/2006",
                "10/16/2006",
                "10/17/2006",
                "10/18/2006",
                "10/19/2006",
                "10/20/2006",
                "10/23/2006",
                "10/24/2006",
                "10/25/2006",
                "10/26/2006",
                "10/27/2006",
                "10/30/2006",
                "10/31/2006",
                "11/1/2006",
                "11/2/2006",
                "11/3/2006",
                "11/6/2006",
                "11/7/2006",
                "11/8/2006",
                "11/9/2006",
                "11/10/2006",
                "11/13/2006",
                "11/14/2006",
                "11/15/2006",
                "11/16/2006",
                "11/17/2006",
                "11/20/2006",
                "11/21/2006",
                "11/22/2006",
                "11/23/2006",
                "11/24/2006",
                "11/27/2006",
                "11/28/2006",
                "11/29/2006",
                "11/30/2006",
                "12/1/2006",
                "12/4/2006",
                "12/5/2006",
                "12/6/2006",
                "12/7/2006",
                "12/8/2006",
                "12/11/2006",
                "12/12/2006",
                "12/13/2006",
                "12/14/2006",
                "12/15/2006",
                "12/18/2006",
                "12/19/2006",
                "12/20/2006",
                "12/21/2006",
                "12/22/2006",
                "12/25/2006",
                "12/26/2006",
                "12/27/2006",
                "12/28/2006",
                "12/29/2006",
                "1/1/2007",
                "1/2/2007",
                "1/3/2007",
                "1/4/2007",
                "1/5/2007",
                "1/8/2007",
                "1/9/2007",
                "1/10/2007",
                "1/11/2007",
                "1/12/2007",
                "1/15/2007",
                "1/16/2007",
                "1/17/2007",
                "1/18/2007",
                "1/19/2007",
                "1/22/2007",
                "1/23/2007",
                "1/24/2007",
                "1/25/2007",
                "1/26/2007",
                "1/29/2007",
                "1/30/2007",
                "1/31/2007",
                "2/1/2007",
                "2/2/2007",
                "2/5/2007",
                "2/6/2007",
                "2/7/2007",
                "2/8/2007",
                "2/9/2007",
                "2/12/2007",
                "2/13/2007",
                "2/14/2007",
                "2/15/2007",
                "2/16/2007",
                "2/19/2007",
                "2/20/2007",
                "2/21/2007",
                "2/22/2007",
                "2/23/2007",
                "2/26/2007",
                "2/27/2007",
                "2/28/2007",
                "3/1/2007",
                "3/2/2007",
                "3/5/2007",
                "3/6/2007",
                "3/7/2007",
                "3/8/2007",
                "3/9/2007",
                "3/12/2007",
                "3/13/2007",
                "3/14/2007",
                "3/15/2007",
                "3/16/2007",
                "3/19/2007",
                "3/20/2007",
                "3/21/2007",
                "3/22/2007",
                "3/23/2007",
                "3/26/2007",
                "3/27/2007",
                "3/28/2007",
                "3/29/2007",
                "3/30/2007",
                "4/2/2007",
                "4/3/2007",
                "4/4/2007",
                "4/5/2007",
                "4/6/2007",
                "4/9/2007",
                "4/10/2007",
                "4/11/2007",
                "4/12/2007",
                "4/13/2007",
                "4/16/2007",
                "4/17/2007",
                "4/18/2007",
                "4/19/2007",
                "4/20/2007",
                "4/23/2007",
                "4/24/2007",
                "4/25/2007",
                "4/26/2007",
                "4/27/2007",
                "4/30/2007",
                "5/1/2007",
                "5/2/2007",
                "5/3/2007",
                "5/4/2007",
                "5/7/2007",
                "5/8/2007",
                "5/9/2007",
                "5/10/2007",
                "5/11/2007",
                "5/14/2007",
                "5/15/2007",
                "5/16/2007",
                "5/17/2007",
                "5/18/2007",
                "5/21/2007",
                "5/22/2007",
                "5/23/2007",
                "5/24/2007",
                "5/25/2007",
                "5/28/2007",
                "5/29/2007",
                "5/30/2007",
                "5/31/2007",
                "6/1/2007",
                "6/4/2007",
                "6/5/2007",
                "6/6/2007",
                "6/7/2007",
                "6/8/2007",
                "6/11/2007",
                "6/12/2007",
                "6/13/2007",
                "6/14/2007",
                "6/15/2007",
                "6/18/2007",
                "6/19/2007",
                "6/20/2007",
                "6/21/2007",
                "6/22/2007",
                "6/25/2007",
                "6/26/2007",
                "6/27/2007",
                "6/28/2007",
                "6/29/2007",
                "7/2/2007",
                "7/3/2007",
                "7/4/2007",
                "7/5/2007",
                "7/6/2007",
                "7/9/2007",
                "7/10/2007",
                "7/11/2007",
                "7/12/2007",
                "7/13/2007",
                "7/16/2007",
                "7/17/2007",
                "7/18/2007",
                "7/19/2007",
                "7/20/2007",
                "7/23/2007",
                "7/24/2007",
                "7/25/2007",
                "7/26/2007",
                "7/27/2007",
                "7/30/2007",
                "7/31/2007",
                "8/1/2007",
                "8/2/2007",
                "8/3/2007",
                "8/6/2007",
                "8/7/2007",
                "8/8/2007",
                "8/9/2007",
                "8/10/2007",
                "8/13/2007",
                "8/14/2007",
                "8/15/2007",
                "8/16/2007",
                "8/17/2007",
                "8/20/2007",
                "8/21/2007",
                "8/22/2007",
                "8/23/2007",
                "8/24/2007",
                "8/27/2007",
                "8/28/2007",
                "8/29/2007",
                "8/30/2007",
                "8/31/2007",
                "9/3/2007",
                "9/4/2007",
                "9/5/2007",
                "9/6/2007",
                "9/7/2007",
                "9/10/2007",
                "9/11/2007",
                "9/12/2007",
                "9/13/2007",
                "9/14/2007",
                "9/17/2007",
                "9/18/2007",
                "9/19/2007",
                "9/20/2007",
                "9/21/2007",
                "9/24/2007",
                "9/25/2007",
                "9/26/2007",
                "9/27/2007",
                "9/28/2007",
                "10/1/2007",
                "10/2/2007",
                "10/3/2007",
                "10/4/2007",
                "10/5/2007",
                "10/8/2007",
                "10/9/2007",
                "10/10/2007",
                "10/11/2007",
                "10/12/2007",
                "10/15/2007",
                "10/16/2007",
                "10/17/2007",
                "10/18/2007",
                "10/19/2007",
                "10/22/2007",
                "10/23/2007",
                "10/24/2007",
                "10/25/2007",
                "10/26/2007",
                "10/29/2007",
                "10/30/2007",
                "10/31/2007",
                "11/1/2007",
                "11/2/2007",
                "11/5/2007",
                "11/6/2007",
                "11/7/2007",
                "11/8/2007",
                "11/9/2007",
                "11/12/2007",
                "11/13/2007",
                "11/14/2007",
                "11/15/2007",
                "11/16/2007",
                "11/19/2007",
                "11/20/2007",
                "11/21/2007",
                "11/22/2007",
                "11/23/2007",
                "11/26/2007",
                "11/27/2007",
                "11/28/2007",
                "11/29/2007",
                "11/30/2007",
                "12/3/2007",
                "12/4/2007",
                "12/5/2007",
                "12/6/2007",
                "12/7/2007",
                "12/10/2007",
                "12/11/2007",
                "12/12/2007",
                "12/13/2007",
                "12/14/2007",
                "12/17/2007",
                "12/18/2007",
                "12/19/2007",
                "12/20/2007",
                "12/21/2007",
                "12/24/2007",
                "12/25/2007",
                "12/26/2007",
                "12/27/2007",
                "12/28/2007",
                "12/31/2007",
                "1/1/2008",
                "1/2/2008",
                "1/3/2008",
                "1/4/2008",
                "1/7/2008",
                "1/8/2008",
                "1/9/2008",
                "1/10/2008",
                "1/11/2008",
                "1/14/2008",
                "1/15/2008",
                "1/16/2008",
                "1/17/2008",
                "1/18/2008",
                "1/21/2008",
                "1/22/2008",
                "1/23/2008",
                "1/24/2008",
                "1/25/2008",
                "1/28/2008",
                "1/29/2008",
                "1/30/2008",
                "1/31/2008",
                "2/1/2008",
                "2/4/2008",
                "2/5/2008",
                "2/6/2008",
                "2/7/2008",
                "2/8/2008",
                "2/11/2008",
                "2/12/2008",
                "2/13/2008",
                "2/14/2008",
                "2/15/2008",
                "2/18/2008",
                "2/19/2008",
                "2/20/2008",
                "2/21/2008",
                "2/22/2008",
                "2/25/2008",
                "2/26/2008",
                "2/27/2008",
                "2/28/2008",
                "2/29/2008",
                "3/3/2008",
                "3/4/2008",
                "3/5/2008",
                "3/6/2008",
                "3/7/2008",
                "3/10/2008",
                "3/11/2008",
                "3/12/2008",
                "3/13/2008",
                "3/14/2008",
                "3/17/2008",
                "3/18/2008",
                "3/19/2008",
                "3/20/2008",
                "3/21/2008",
                "3/24/2008",
                "3/25/2008",
                "3/26/2008",
                "3/27/2008",
                "3/28/2008",
                "3/31/2008",
                "4/1/2008",
                "4/2/2008",
                "4/3/2008",
                "4/4/2008",
                "4/7/2008",
                "4/8/2008",
                "4/9/2008",
                "4/10/2008",
                "4/11/2008",
                "4/14/2008",
                "4/15/2008",
                "4/16/2008",
                "4/17/2008",
                "4/18/2008",
                "4/21/2008",
                "4/22/2008",
                "4/23/2008",
                "4/24/2008",
                "4/25/2008",
                "4/28/2008",
                "4/29/2008",
                "4/30/2008",
                "5/1/2008",
                "5/2/2008",
                "5/5/2008",
                "5/6/2008",
                "5/7/2008",
                "5/8/2008",
                "5/9/2008",
                "5/12/2008",
                "5/13/2008",
                "5/14/2008",
                "5/15/2008",
                "5/16/2008",
                "5/19/2008",
                "5/20/2008",
                "5/21/2008",
                "5/22/2008",
                "5/23/2008",
                "5/26/2008",
                "5/27/2008",
                "5/28/2008",
                "5/29/2008",
                "5/30/2008",
                "6/2/2008",
                "6/3/2008",
                "6/4/2008",
                "6/5/2008",
                "6/6/2008",
                "6/9/2008",
                "6/10/2008",
                "6/11/2008",
                "6/12/2008",
                "6/13/2008",
                "6/16/2008",
                "6/17/2008",
                "6/18/2008",
                "6/19/2008",
                "6/20/2008",
                "6/23/2008",
                "6/24/2008",
                "6/25/2008",
                "6/26/2008",
                "6/27/2008",
                "6/30/2008",
                "7/1/2008",
                "7/2/2008",
                "7/3/2008",
                "7/4/2008",
                "7/7/2008",
                "7/8/2008",
                "7/9/2008",
                "7/10/2008",
                "7/11/2008",
                "7/14/2008",
                "7/15/2008",
                "7/16/2008",
                "7/17/2008",
                "7/18/2008",
                "7/21/2008",
                "7/22/2008",
                "7/23/2008",
                "7/24/2008",
                "7/25/2008",
                "7/28/2008",
                "7/29/2008",
                "7/30/2008",
                "7/31/2008",
                "8/1/2008",
                "8/4/2008",
                "8/5/2008",
                "8/6/2008",
                "8/7/2008",
                "8/8/2008",
                "8/11/2008",
                "8/12/2008",
                "8/13/2008",
                "8/14/2008",
                "8/15/2008",
                "8/18/2008",
                "8/19/2008",
                "8/20/2008",
                "8/21/2008",
                "8/22/2008",
                "8/25/2008",
                "8/26/2008",
                "8/27/2008",
                "8/28/2008",
                "8/29/2008",
                "9/1/2008",
                "9/2/2008",
                "9/3/2008",
                "9/4/2008",
                "9/5/2008",
                "9/8/2008",
                "9/9/2008",
                "9/10/2008",
                "9/11/2008",
                "9/12/2008",
                "9/15/2008",
                "9/16/2008",
                "9/17/2008",
                "9/18/2008",
                "9/19/2008",
                "9/22/2008",
                "9/23/2008",
                "9/24/2008",
                "9/25/2008",
                "9/26/2008",
                "9/29/2008",
                "9/30/2008",
                "10/1/2008",
                "10/2/2008",
                "10/3/2008",
                "10/6/2008",
                "10/7/2008",
                "10/8/2008",
                "10/9/2008",
                "10/10/2008",
                "10/13/2008",
                "10/14/2008",
                "10/15/2008",
                "10/16/2008",
                "10/17/2008",
                "10/20/2008",
                "10/21/2008",
                "10/22/2008",
                "10/23/2008",
                "10/24/2008",
                "10/27/2008",
                "10/28/2008",
                "10/29/2008",
                "10/30/2008",
                "10/31/2008",
                "11/3/2008",
                "11/4/2008",
                "11/5/2008",
                "11/6/2008",
                "11/7/2008",
                "11/10/2008",
                "11/11/2008",
                "11/12/2008",
                "11/13/2008",
                "11/14/2008",
                "11/17/2008",
                "11/18/2008",
                "11/19/2008",
                "11/20/2008",
                "11/21/2008",
                "11/24/2008",
                "11/25/2008",
                "11/26/2008",
                "11/27/2008",
                "11/28/2008",
                "12/1/2008",
                "12/2/2008",
                "12/3/2008",
                "12/4/2008",
                "12/5/2008",
                "12/8/2008",
                "12/9/2008",
                "12/10/2008",
                "12/11/2008",
                "12/12/2008",
                "12/15/2008",
                "12/16/2008",
                "12/17/2008",
                "12/18/2008",
                "12/19/2008",
                "12/22/2008",
                "12/23/2008",
                "12/24/2008",
                "12/25/2008",
                "12/26/2008",
                "12/29/2008",
                "12/30/2008",
                "12/31/2008",
                "1/1/2009",
                "1/2/2009",
                "1/5/2009",
                "1/6/2009",
                "1/7/2009",
                "1/8/2009",
                "1/9/2009",
                "1/12/2009",
                "1/13/2009",
                "1/14/2009",
                "1/15/2009",
                "1/16/2009",
                "1/19/2009",
                "1/20/2009",
                "1/21/2009",
                "1/22/2009",
                "1/23/2009",
                "1/26/2009",
                "1/27/2009",
                "1/28/2009",
                "1/29/2009",
                "1/30/2009",
                "2/2/2009",
                "2/3/2009",
                "2/4/2009",
                "2/5/2009",
                "2/6/2009",
                "2/9/2009",
                "2/10/2009",
                "2/11/2009",
                "2/12/2009",
                "2/13/2009",
                "2/16/2009",
                "2/17/2009",
                "2/18/2009",
                "2/19/2009",
                "2/20/2009",
                "2/23/2009",
                "2/24/2009",
                "2/25/2009",
                "2/26/2009",
                "2/27/2009",
                "3/2/2009",
                "3/3/2009",
                "3/4/2009",
                "3/5/2009",
                "3/6/2009",
                "3/9/2009",
                "3/10/2009",
                "3/11/2009",
                "3/12/2009",
                "3/13/2009",
                "3/16/2009",
                "3/17/2009",
                "3/18/2009",
                "3/19/2009",
                "3/20/2009",
                "3/23/2009",
                "3/24/2009",
                "3/25/2009",
                "3/26/2009",
                "3/27/2009",
                "3/30/2009",
                "3/31/2009",
                "4/1/2009",
                "4/2/2009",
                "4/3/2009",
                "4/6/2009",
                "4/7/2009",
                "4/8/2009",
                "4/9/2009",
                "4/10/2009",
                "4/13/2009",
                "4/14/2009",
                "4/15/2009",
                "4/16/2009",
                "4/17/2009",
                "4/20/2009",
                "4/21/2009",
                "4/22/2009",
                "4/23/2009",
                "4/24/2009",
                "4/27/2009",
                "4/28/2009",
                "4/29/2009",
                "4/30/2009",
                "5/1/2009",
                "5/4/2009",
                "5/5/2009",
                "5/6/2009",
                "5/7/2009",
                "5/8/2009",
                "5/11/2009",
                "5/12/2009",
                "5/13/2009",
                "5/14/2009",
                "5/15/2009",
                "5/18/2009",
                "5/19/2009",
                "5/20/2009",
                "5/21/2009",
                "5/22/2009",
                "5/25/2009",
                "5/26/2009",
                "5/27/2009",
                "5/28/2009",
                "5/29/2009",
                "6/1/2009",
                "6/2/2009",
                "6/3/2009",
                "6/4/2009",
                "6/5/2009",
                "6/8/2009",
                "6/9/2009",
                "6/10/2009",
                "6/11/2009",
                "6/12/2009",
                "6/15/2009",
                "6/16/2009",
                "6/17/2009",
                "6/18/2009",
                "6/19/2009",
                "6/22/2009",
                "6/23/2009",
                "6/24/2009",
                "6/25/2009",
                "6/26/2009",
                "6/29/2009",
                "6/30/2009",
                "7/1/2009",
                "7/2/2009",
                "7/3/2009",
                "7/6/2009",
                "7/7/2009",
                "7/8/2009",
                "7/9/2009",
                "7/10/2009",
                "7/13/2009",
                "7/14/2009",
                "7/15/2009",
                "7/16/2009",
                "7/17/2009",
                "7/20/2009",
                "7/21/2009",
                "7/22/2009",
                "7/23/2009",
                "7/24/2009",
                "7/27/2009",
                "7/28/2009",
                "7/29/2009",
                "7/30/2009",
                "7/31/2009",
                "8/3/2009",
                "8/4/2009",
                "8/5/2009",
                "8/6/2009",
                "8/7/2009",
                "8/10/2009",
                "8/11/2009",
                "8/12/2009",
                "8/13/2009",
                "8/14/2009",
                "8/17/2009",
                "8/18/2009",
                "8/19/2009",
                "8/20/2009",
                "8/21/2009",
                "8/24/2009",
                "8/25/2009",
                "8/26/2009",
                "8/27/2009",
                "8/28/2009",
                "8/31/2009",
                "9/1/2009",
                "9/2/2009",
                "9/3/2009",
                "9/4/2009",
                "9/7/2009",
                "9/8/2009",
                "9/9/2009",
                "9/10/2009",
                "9/11/2009",
                "9/14/2009",
                "9/15/2009",
                "9/16/2009",
                "9/17/2009",
                "9/18/2009",
                "9/21/2009",
                "9/22/2009",
                "9/23/2009",
                "9/24/2009",
                "9/25/2009",
                "9/28/2009",
                "9/29/2009",
                "9/30/2009",
                "10/1/2009",
                "10/2/2009",
                "10/5/2009",
                "10/6/2009",
                "10/7/2009",
                "10/8/2009",
                "10/9/2009",
                "10/12/2009",
                "10/13/2009",
                "10/14/2009",
                "10/15/2009",
                "10/16/2009",
                "10/19/2009",
                "10/20/2009",
                "10/21/2009",
                "10/22/2009",
                "10/23/2009",
                "10/26/2009",
                "10/27/2009",
                "10/28/2009",
                "10/29/2009",
                "10/30/2009",
                "11/2/2009",
                "11/3/2009",
                "11/4/2009",
                "11/5/2009",
                "11/6/2009",
                "11/9/2009",
                "11/10/2009",
                "11/11/2009",
                "11/12/2009",
                "11/13/2009",
                "11/16/2009",
                "11/17/2009",
                "11/18/2009",
                "11/19/2009",
                "11/20/2009",
                "11/23/2009",
                "11/24/2009",
                "11/25/2009",
                "11/26/2009",
                "11/27/2009",
                "11/30/2009",
                "12/1/2009",
                "12/2/2009",
                "12/3/2009",
                "12/4/2009",
                "12/7/2009",
                "12/8/2009",
                "12/9/2009",
                "12/10/2009",
                "12/11/2009",
                "12/14/2009",
                "12/15/2009",
                "12/16/2009",
                "12/17/2009",
                "12/18/2009",
                "12/21/2009",
                "12/22/2009",
                "12/23/2009",
                "12/24/2009",
                "12/25/2009",
                "12/28/2009",
                "12/29/2009",
                "12/30/2009",
                "12/31/2009",
                "1/1/2010",
                "1/4/2010",
                "1/5/2010",
                "1/6/2010",
                "1/7/2010",
                "1/8/2010",
                "1/11/2010",
                "1/12/2010",
                "1/13/2010",
                "1/14/2010",
                "1/15/2010",
                "1/18/2010",
                "1/19/2010",
                "1/20/2010",
                "1/21/2010",
                "1/22/2010",
                "1/25/2010",
                "1/26/2010",
                "1/27/2010",
                "1/28/2010",
                "1/29/2010",
                "2/1/2010",
                "2/2/2010",
                "2/3/2010",
                "2/4/2010",
                "2/5/2010",
                "2/8/2010",
                "2/9/2010",
                "2/10/2010",
                "2/11/2010",
                "2/12/2010",
                "2/15/2010",
                "2/16/2010",
                "2/17/2010",
                "2/18/2010",
                "2/19/2010",
                "2/22/2010",
                "2/23/2010",
                "2/24/2010",
                "2/25/2010",
                "2/26/2010",
                "3/1/2010",
                "3/2/2010",
                "3/3/2010",
                "3/4/2010",
                "3/5/2010",
                "3/8/2010",
                "3/9/2010",
                "3/10/2010",
                "3/11/2010",
                "3/12/2010",
                "3/15/2010",
                "3/16/2010",
                "3/17/2010",
                "3/18/2010",
                "3/19/2010",
                "3/22/2010",
                "3/23/2010",
                "3/24/2010",
                "3/25/2010",
                "3/26/2010",
                "3/29/2010",
                "3/30/2010",
                "3/31/2010",
                "4/1/2010",
                "4/2/2010",
                "4/5/2010",
                "4/6/2010",
                "4/7/2010",
                "4/8/2010",
                "4/9/2010",
                "4/12/2010",
                "4/13/2010",
                "4/14/2010",
                "4/15/2010",
                "4/16/2010",
                "4/19/2010",
                "4/20/2010",
                "4/21/2010",
                "4/22/2010",
                "4/23/2010",
                "4/26/2010",
                "4/27/2010",
                "4/28/2010",
                "4/29/2010",
                "4/30/2010",
                "5/3/2010",
                "5/4/2010",
                "5/5/2010",
                "5/6/2010",
                "5/7/2010",
                "5/10/2010",
                "5/11/2010",
                "5/12/2010",
                "5/13/2010",
                "5/14/2010",
                "5/17/2010",
                "5/18/2010",
                "5/19/2010",
                "5/20/2010",
                "5/21/2010",
                "5/24/2010",
                "5/25/2010",
                "5/26/2010",
                "5/27/2010",
                "5/28/2010",
                "5/31/2010",
                "6/1/2010",
                "6/2/2010",
                "6/3/2010",
                "6/4/2010",
                "6/7/2010",
                "6/8/2010",
                "6/9/2010",
                "6/10/2010",
                "6/11/2010",
                "6/14/2010",
                "6/15/2010",
                "6/16/2010",
                "6/17/2010",
                "6/18/2010",
                "6/21/2010",
                "6/22/2010",
                "6/23/2010",
                "6/24/2010",
                "6/25/2010",
                "6/28/2010",
                "6/29/2010",
                "6/30/2010",
                "7/1/2010",
                "7/2/2010",
                "7/5/2010",
                "7/6/2010",
                "7/7/2010",
                "7/8/2010",
                "7/9/2010",
                "7/12/2010",
                "7/13/2010",
                "7/14/2010",
                "7/15/2010",
                "7/16/2010",
                "7/19/2010",
                "7/20/2010",
                "7/21/2010",
                "7/22/2010",
                "7/23/2010",
                "7/26/2010",
                "7/27/2010",
                "7/28/2010",
                "7/29/2010",
                "7/30/2010",
                "8/2/2010",
                "8/3/2010",
                "8/4/2010",
                "8/5/2010",
                "8/6/2010",
                "8/9/2010",
                "8/10/2010",
                "8/11/2010",
                "8/12/2010",
                "8/13/2010",
                "8/16/2010",
                "8/17/2010",
                "8/18/2010",
                "8/19/2010",
                "8/20/2010",
                "8/23/2010",
                "8/24/2010",
                "8/25/2010",
                "8/26/2010",
                "8/27/2010",
                "8/30/2010",
                "8/31/2010",
                "9/1/2010",
                "9/2/2010",
                "9/3/2010",
                "9/6/2010",
                "9/7/2010",
                "9/8/2010",
                "9/9/2010",
                "9/10/2010",
                "9/13/2010",
                "9/14/2010",
                "9/15/2010",
                "9/16/2010",
                "9/17/2010",
                "9/20/2010",
                "9/21/2010",
                "9/22/2010",
                "9/23/2010",
                "9/24/2010",
                "9/27/2010",
                "9/28/2010",
                "9/29/2010",
                "9/30/2010",
                "10/1/2010",
                "10/4/2010",
                "10/5/2010",
                "10/6/2010",
                "10/7/2010",
                "10/8/2010",
                "10/11/2010",
                "10/12/2010",
                "10/13/2010",
                "10/14/2010",
                "10/15/2010",
                "10/18/2010",
                "10/19/2010",
                "10/20/2010",
                "10/21/2010",
                "10/22/2010",
                "10/25/2010",
                "10/26/2010",
                "10/27/2010",
                "10/28/2010",
                "10/29/2010",
                "11/1/2010",
                "11/2/2010",
                "11/3/2010",
                "11/4/2010",
                "11/5/2010",
                "11/8/2010",
                "11/9/2010",
                "11/10/2010",
                "11/11/2010",
                "11/12/2010",
                "11/15/2010",
                "11/16/2010",
                "11/17/2010",
                "11/18/2010",
                "11/19/2010",
                "11/22/2010",
                "11/23/2010",
                "11/24/2010",
                "11/25/2010",
                "11/26/2010",
                "11/29/2010",
                "11/30/2010",
                "12/1/2010",
                "12/2/2010",
                "12/3/2010",
                "12/6/2010",
                "12/7/2010",
                "12/8/2010",
                "12/9/2010",
                "12/10/2010",
                "12/13/2010",
                "12/14/2010",
                "12/15/2010",
                "12/16/2010",
                "12/17/2010",
                "12/20/2010",
                "12/21/2010",
                "12/22/2010",
                "12/23/2010",
                "12/24/2010",
                "12/27/2010",
                "12/28/2010",
                "12/29/2010",
                "12/30/2010",
                "12/31/2010",
                "1/3/2011",
                "1/4/2011",
                "1/5/2011",
                "1/6/2011",
                "1/7/2011",
                "1/10/2011",
                "1/11/2011",
                "1/12/2011",
                "1/13/2011",
                "1/14/2011",
                "1/17/2011",
                "1/18/2011",
                "1/19/2011",
                "1/20/2011",
                "1/21/2011",
                "1/24/2011",
                "1/25/2011",
                "1/26/2011",
                "1/27/2011",
                "1/28/2011",
                "1/31/2011",
                "2/1/2011",
                "2/2/2011",
                "2/3/2011",
                "2/4/2011",
                "2/7/2011",
                "2/8/2011",
                "2/9/2011",
                "2/10/2011",
                "2/11/2011",
                "2/14/2011",
                "2/15/2011",
                "2/16/2011",
                "2/17/2011",
                "2/18/2011",
                "2/21/2011",
                "2/22/2011",
                "2/23/2011",
                "2/24/2011",
                "2/25/2011",
                "2/28/2011",
                "3/1/2011",
                "3/2/2011",
                "3/3/2011",
                "3/4/2011",
                "3/7/2011",
                "3/8/2011",
                "3/9/2011",
                "3/10/2011",
                "3/11/2011",
                "3/14/2011",
                "3/15/2011",
                "3/16/2011",
                "3/17/2011",
                "3/18/2011",
                "3/21/2011",
                "3/22/2011",
                "3/23/2011",
                "3/24/2011",
                "3/25/2011",
                "3/28/2011",
                "3/29/2011",
                "3/30/2011",
                "3/31/2011",
                "4/1/2011",
                "4/4/2011",
                "4/5/2011",
                "4/6/2011",
                "4/7/2011",
                "4/8/2011",
                "4/11/2011",
                "4/12/2011",
                "4/13/2011",
                "4/14/2011",
                "4/15/2011",
                "4/18/2011",
                "4/19/2011",
                "4/20/2011",
                "4/21/2011",
                "4/22/2011",
                "4/25/2011",
                "4/26/2011",
                "4/27/2011",
                "4/28/2011",
                "4/29/2011",
                "5/2/2011",
                "5/3/2011",
                "5/4/2011",
                "5/5/2011",
                "5/6/2011",
                "5/9/2011",
                "5/10/2011",
                "5/11/2011",
                "5/12/2011",
                "5/13/2011",
                "5/16/2011",
                "5/17/2011",
                "5/18/2011",
                "5/19/2011",
                "5/20/2011",
                "5/23/2011",
                "5/24/2011",
                "5/25/2011",
                "5/26/2011",
                "5/27/2011",
                "5/30/2011",
                "5/31/2011",
                "6/1/2011",
                "6/2/2011",
                "6/3/2011",
                "6/6/2011",
                "6/7/2011",
                "6/8/2011",
                "6/9/2011",
                "6/10/2011",
                "6/13/2011",
                "6/14/2011",
                "6/15/2011",
                "6/16/2011",
                "6/17/2011",
                "6/20/2011",
                "6/21/2011",
                "6/22/2011",
                "6/23/2011",
                "6/24/2011",
                "6/27/2011",
                "6/28/2011",
                "6/29/2011",
                "6/30/2011",
                "7/1/2011",
                "7/4/2011",
                "7/5/2011",
                "7/6/2011",
                "7/7/2011",
                "7/8/2011",
                "7/11/2011",
                "7/12/2011",
                "7/13/2011",
                "7/14/2011",
                "7/15/2011",
                "7/18/2011",
                "7/19/2011",
                "7/20/2011",
                "7/21/2011",
                "7/22/2011",
                "7/25/2011",
                "7/26/2011",
                "7/27/2011",
                "7/28/2011",
                "7/29/2011",
                "8/1/2011",
                "8/2/2011",
                "8/3/2011",
                "8/4/2011",
                "8/5/2011",
                "8/8/2011",
                "8/9/2011",
                "8/10/2011",
                "8/11/2011",
                "8/12/2011",
                "8/15/2011",
                "8/16/2011",
                "8/17/2011",
                "8/18/2011",
                "8/19/2011",
                "8/22/2011",
                "8/23/2011",
                "8/24/2011",
                "8/25/2011",
                "8/26/2011",
                "8/29/2011",
                "8/30/2011",
                "8/31/2011",
                "9/1/2011",
                "9/2/2011",
                "9/5/2011",
                "9/6/2011",
                "9/7/2011",
                "9/8/2011",
                "9/9/2011",
                "9/12/2011",
                "9/13/2011",
                "9/14/2011",
                "9/15/2011",
                "9/16/2011",
                "9/19/2011",
                "9/20/2011",
                "9/21/2011",
                "9/22/2011",
                "9/23/2011",
                "9/26/2011",
                "9/27/2011",
                "9/28/2011",
                "9/29/2011",
                "9/30/2011",
                "10/3/2011",
                "10/4/2011",
                "10/5/2011",
                "10/6/2011",
                "10/7/2011",
                "10/10/2011",
                "10/11/2011",
                "10/12/2011",
                "10/13/2011",
                "10/14/2011",
                "10/17/2011",
                "10/18/2011",
                "10/19/2011",
                "10/20/2011",
                "10/21/2011",
                "10/24/2011",
                "10/25/2011",
                "10/26/2011",
                "10/27/2011",
                "10/28/2011",
                "10/31/2011",
                "11/1/2011",
                "11/2/2011",
                "11/3/2011",
                "11/4/2011",
                "11/7/2011",
                "11/8/2011",
                "11/9/2011",
                "11/10/2011",
                "11/11/2011",
                "11/14/2011",
                "11/15/2011",
                "11/16/2011",
                "11/17/2011",
                "11/18/2011",
                "11/21/2011",
                "11/22/2011",
                "11/23/2011",
                "11/24/2011",
                "11/25/2011",
                "11/28/2011",
                "11/29/2011",
                "11/30/2011",
                "12/1/2011",
                "12/2/2011",
                "12/5/2011",
                "12/6/2011",
                "12/7/2011",
                "12/8/2011",
                "12/9/2011",
                "12/12/2011",
                "12/13/2011",
                "12/14/2011",
                "12/15/2011",
                "12/16/2011",
                "12/19/2011",
                "12/20/2011",
                "12/21/2011",
                "12/22/2011",
                "12/23/2011",
                "12/26/2011",
                "12/27/2011",
                "12/28/2011",
                "12/29/2011",
                "12/30/2011",
                "1/2/2012",
                "1/3/2012",
                "1/4/2012",
                "1/5/2012",
                "1/6/2012",
                "1/9/2012",
                "1/10/2012",
                "1/11/2012",
                "1/12/2012",
                "1/13/2012",
                "1/16/2012",
                "1/17/2012",
                "1/18/2012",
                "1/19/2012",
                "1/20/2012",
                "1/23/2012",
                "1/24/2012",
                "1/25/2012",
                "1/26/2012",
                "1/27/2012",
                "1/30/2012",
                "1/31/2012",
                "2/1/2012",
                "2/2/2012",
                "2/3/2012",
                "2/6/2012",
                "2/7/2012",
                "2/8/2012",
                "2/9/2012",
                "2/10/2012",
                "2/13/2012",
                "2/14/2012",
                "2/15/2012",
                "2/16/2012",
                "2/17/2012",
                "2/20/2012",
                "2/21/2012",
                "2/22/2012",
                "2/23/2012",
                "2/24/2012",
                "2/27/2012",
                "2/28/2012",
                "2/29/2012",
                "3/1/2012",
                "3/2/2012",
                "3/5/2012",
                "3/6/2012",
                "3/7/2012",
                "3/8/2012",
                "3/9/2012",
                "3/12/2012",
                "3/13/2012",
                "3/14/2012",
                "3/15/2012",
                "3/16/2012",
                "3/19/2012",
                "3/20/2012",
                "3/21/2012",
                "3/22/2012",
                "3/23/2012",
                "3/26/2012",
                "3/27/2012",
                "3/28/2012",
                "3/29/2012",
                "3/30/2012",
                "4/2/2012",
                "4/3/2012",
                "4/4/2012",
                "4/5/2012",
                "4/6/2012",
                "4/9/2012",
                "4/10/2012",
                "4/11/2012",
                "4/12/2012",
                "4/13/2012",
                "4/16/2012",
                "4/17/2012",
                "4/18/2012",
                "4/19/2012",
                "4/20/2012",
                "4/23/2012",
                "4/24/2012",
                "4/25/2012",
                "4/26/2012",
                "4/27/2012",
                "4/30/2012",
                "5/1/2012",
                "5/2/2012",
                "5/3/2012",
                "5/4/2012",
                "5/7/2012",
                "5/8/2012",
                "5/9/2012",
                "5/10/2012",
                "5/11/2012",
                "5/14/2012",
                "5/15/2012",
                "5/16/2012",
                "5/17/2012",
                "5/18/2012",
                "5/21/2012",
                "5/22/2012",
                "5/23/2012",
                "5/24/2012",
                "5/25/2012",
                "5/28/2012",
                "5/29/2012",
                "5/30/2012",
                "5/31/2012",
                "6/1/2012",
                "6/4/2012",
                "6/5/2012",
                "6/6/2012",
                "6/7/2012",
                "6/8/2012",
                "6/11/2012",
                "6/12/2012",
                "6/13/2012",
                "6/14/2012",
                "6/15/2012",
                "6/18/2012",
                "6/19/2012",
                "6/20/2012",
                "6/21/2012",
                "6/22/2012",
                "6/25/2012",
                "6/26/2012",
                "6/27/2012",
                "6/28/2012",
                "6/29/2012",
                "7/2/2012",
                "7/3/2012",
                "7/4/2012",
                "7/5/2012",
                "7/6/2012",
                "7/9/2012",
                "7/10/2012",
                "7/11/2012",
                "7/12/2012",
                "7/13/2012",
                "7/16/2012",
                "7/17/2012",
                "7/18/2012",
                "7/19/2012",
                "7/20/2012",
                "7/23/2012",
                "7/24/2012",
                "7/25/2012",
                "7/26/2012",
                "7/27/2012",
                "7/30/2012",
                "7/31/2012",
                "8/1/2012",
                "8/2/2012",
                "8/3/2012",
                "8/6/2012",
                "8/7/2012",
                "8/8/2012",
                "8/9/2012",
                "8/10/2012",
                "8/13/2012",
                "8/14/2012",
                "8/15/2012",
                "8/16/2012",
                "8/17/2012",
                "8/20/2012",
                "8/21/2012",
                "8/22/2012",
                "8/23/2012",
                "8/24/2012",
                "8/27/2012",
                "8/28/2012",
                "8/29/2012",
                "8/30/2012",
                "8/31/2012",
                "9/3/2012",
                "9/4/2012",
                "9/5/2012",
                "9/6/2012",
                "9/7/2012",
                "9/10/2012",
                "9/11/2012",
                "9/12/2012",
                "9/13/2012",
                "9/14/2012",
                "9/17/2012",
                "9/18/2012",
                "9/19/2012",
                "9/20/2012",
                "9/21/2012",
                "9/24/2012",
                "9/25/2012",
                "9/26/2012",
                "9/27/2012",
                "9/28/2012",
                "10/1/2012",
                "10/2/2012",
                "10/3/2012",
                "10/4/2012",
                "10/5/2012",
                "10/8/2012",
                "10/9/2012",
                "10/10/2012",
                "10/11/2012",
                "10/12/2012",
                "10/15/2012",
                "10/16/2012",
                "10/17/2012",
                "10/18/2012",
                "10/19/2012",
                "10/22/2012",
                "10/23/2012",
                "10/24/2012",
                "10/25/2012",
                "10/26/2012",
                "10/29/2012",
                "10/30/2012",
                "10/31/2012",
                "11/1/2012",
                "11/2/2012",
                "11/5/2012",
                "11/6/2012",
                "11/7/2012",
                "11/8/2012",
                "11/9/2012",
                "11/12/2012",
                "11/13/2012",
                "11/14/2012",
                "11/15/2012",
                "11/16/2012",
                "11/19/2012",
                "11/20/2012",
                "11/21/2012",
                "11/22/2012",
                "11/23/2012",
                "11/26/2012",
                "11/27/2012",
                "11/28/2012",
                "11/29/2012",
                "11/30/2012",
                "12/3/2012",
                "12/4/2012",
                "12/5/2012",
                "12/6/2012",
                "12/7/2012",
                "12/10/2012",
                "12/11/2012",
                "12/12/2012",
                "12/13/2012",
                "12/14/2012",
                "12/17/2012",
                "12/18/2012",
                "12/19/2012",
                "12/20/2012",
                "12/21/2012",
                "12/24/2012",
                "12/25/2012",
                "12/26/2012",
                "12/27/2012",
                "12/28/2012",
                "12/31/2012",
                "1/1/2013",
                "1/2/2013",
                "1/3/2013",
                "1/4/2013",
                "1/7/2013",
                "1/8/2013",
                "1/9/2013",
                "1/10/2013",
                "1/11/2013",
                "1/14/2013",
                "1/15/2013",
                "1/16/2013",
                "1/17/2013",
                "1/18/2013",
                "1/21/2013",
                "1/22/2013",
                "1/23/2013",
                "1/24/2013",
                "1/25/2013",
                "1/28/2013",
                "1/29/2013",
                "1/30/2013",
                "1/31/2013",
                "2/1/2013",
                "2/4/2013",
                "2/5/2013",
                "2/6/2013",
                "2/7/2013",
                "2/8/2013",
                "2/11/2013",
                "2/12/2013",
                "2/13/2013",
                "2/14/2013",
                "2/15/2013",
                "2/18/2013",
                "2/19/2013",
                "2/20/2013",
                "2/21/2013",
                "2/22/2013",
                "2/25/2013",
                "2/26/2013",
                "2/27/2013",
                "2/28/2013",
                "3/1/2013",
                "3/4/2013",
                "3/5/2013",
                "3/6/2013",
                "3/7/2013",
                "3/8/2013",
                "3/11/2013",
                "3/12/2013",
                "3/13/2013",
                "3/14/2013",
                "3/15/2013",
                "3/18/2013",
                "3/19/2013",
                "3/20/2013",
                "3/21/2013",
                "3/22/2013",
                "3/25/2013",
                "3/26/2013",
                "3/27/2013",
                "3/28/2013",
                "3/29/2013",
                "4/1/2013",
                "4/2/2013",
                "4/3/2013",
                "4/4/2013",
                "4/5/2013",
                "4/8/2013",
                "4/9/2013",
                "4/10/2013",
                "4/11/2013",
                "4/12/2013",
                "4/15/2013",
                "4/16/2013",
                "4/17/2013",
                "4/18/2013",
                "4/19/2013",
                "4/22/2013",
                "4/23/2013",
                "4/24/2013",
                "4/25/2013",
                "4/26/2013",
                "4/29/2013",
                "4/30/2013",
                "5/1/2013",
                "5/2/2013",
                "5/3/2013",
                "5/6/2013",
                "5/7/2013",
                "5/8/2013",
                "5/9/2013",
                "5/10/2013",
                "5/13/2013",
                "5/14/2013",
                "5/15/2013",
                "5/16/2013",
                "5/17/2013",
                "5/20/2013",
                "5/21/2013",
                "5/22/2013",
                "5/23/2013",
                "5/24/2013",
                "5/27/2013",
                "5/28/2013",
                "5/29/2013",
                "5/30/2013",
                "5/31/2013",
                "6/3/2013",
                "6/4/2013",
                "6/5/2013",
                "6/6/2013",
                "6/7/2013",
                "6/10/2013",
                "6/11/2013",
                "6/12/2013",
                "6/13/2013",
                "6/14/2013",
                "6/17/2013",
                "6/18/2013",
                "6/19/2013",
                "6/20/2013",
                "6/21/2013",
                "6/24/2013",
                "6/25/2013",
                "6/26/2013",
                "6/27/2013",
                "6/28/2013",
                "7/1/2013",
                "7/2/2013",
                "7/3/2013",
                "7/4/2013",
                "7/5/2013",
                "7/8/2013",
                "7/9/2013",
                "7/10/2013",
                "7/11/2013",
                "7/12/2013",
                "7/15/2013",
                "7/16/2013",
                "7/17/2013",
                "7/18/2013",
                "7/19/2013",
                "7/22/2013",
                "7/23/2013",
                "7/24/2013",
                "7/25/2013",
                "7/26/2013",
                "7/29/2013",
                "7/30/2013",
                "7/31/2013",
                "8/1/2013",
                "8/2/2013",
                "8/5/2013",
                "8/6/2013",
                "8/7/2013",
                "8/8/2013",
                "8/9/2013",
                "8/12/2013",
                "8/13/2013",
                "8/14/2013",
                "8/15/2013",
                "8/16/2013",
                "8/19/2013",
                "8/20/2013",
                "8/21/2013",
                "8/22/2013",
                "8/23/2013",
                "8/26/2013",
                "8/27/2013",
                "8/28/2013",
                "8/29/2013",
                "8/30/2013",
                "9/2/2013",
                "9/3/2013",
                "9/4/2013",
                "9/5/2013",
                "9/6/2013",
                "9/9/2013",
                "9/10/2013",
                "9/11/2013",
                "9/12/2013",
                "9/13/2013",
                "9/16/2013",
                "9/17/2013",
                "9/18/2013",
                "9/19/2013",
                "9/20/2013",
                "9/23/2013",
                "9/24/2013",
                "9/25/2013",
                "9/26/2013",
                "9/27/2013",
                "9/30/2013",
                "10/1/2013",
                "10/2/2013",
                "10/3/2013",
                "10/4/2013",
                "10/7/2013",
                "10/8/2013",
                "10/9/2013",
                "10/10/2013",
                "10/11/2013",
                "10/14/2013",
                "10/15/2013",
                "10/16/2013",
                "10/17/2013",
                "10/18/2013",
                "10/21/2013",
                "10/22/2013",
                "10/23/2013",
                "10/24/2013",
                "10/25/2013",
                "10/28/2013",
                "10/29/2013",
                "10/30/2013",
                "10/31/2013",
                "11/1/2013",
                "11/4/2013",
                "11/5/2013",
                "11/6/2013",
                "11/7/2013",
                "11/8/2013",
                "11/11/2013",
                "11/12/2013",
                "11/13/2013",
                "11/14/2013",
                "11/15/2013",
                "11/18/2013",
                "11/19/2013",
                "11/20/2013",
                "11/21/2013",
                "11/22/2013",
                "11/25/2013",
                "11/26/2013",
                "11/27/2013",
                "11/28/2013",
                "11/29/2013",
                "12/2/2013",
                "12/3/2013",
                "12/4/2013",
                "12/5/2013",
                "12/6/2013",
                "12/9/2013",
                "12/10/2013",
                "12/11/2013",
                "12/12/2013",
                "12/13/2013",
                "12/16/2013",
                "12/17/2013",
                "12/18/2013",
                "12/19/2013",
                "12/20/2013",
                "12/23/2013",
                "12/24/2013",
                "12/25/2013",
                "12/26/2013",
                "12/27/2013",
                "12/30/2013",
                "12/31/2013",
                "1/1/2014",
                "1/2/2014",
                "1/3/2014",
                "1/6/2014",
                "1/7/2014",
                "1/8/2014",
                "1/9/2014",
                "1/10/2014",
                "1/13/2014",
                "1/14/2014",
                "1/15/2014",
                "1/16/2014",
                "1/17/2014",
                "1/20/2014",
                "1/21/2014",
                "1/22/2014",
                "1/23/2014",
                "1/24/2014",
                "1/27/2014",
                "1/28/2014",
                "1/29/2014",
                "1/30/2014",
                "1/31/2014",
                "2/3/2014",
                "2/4/2014",
                "2/5/2014",
                "2/6/2014",
                "2/7/2014",
                "2/10/2014",
                "2/11/2014",
                "2/12/2014",
                "2/13/2014",
                "2/14/2014",
                "2/17/2014",
                "2/18/2014",
                "2/19/2014",
                "2/20/2014",
                "2/21/2014",
                "2/24/2014",
                "2/25/2014",
                "2/26/2014",
                "2/27/2014",
                "2/28/2014",
                "3/3/2014",
                "3/4/2014",
                "3/5/2014",
                "3/6/2014",
                "3/7/2014",
                "3/10/2014",
                "3/11/2014",
                "3/12/2014",
                "3/13/2014",
                "3/14/2014",
                "3/17/2014",
                "3/18/2014",
                "3/19/2014",
                "3/20/2014",
                "3/21/2014",
                "3/24/2014",
                "3/25/2014",
                "3/26/2014",
                "3/27/2014",
                "3/28/2014",
                "3/31/2014",
                "4/1/2014",
                "4/2/2014",
                "4/3/2014",
                "4/4/2014",
                "4/7/2014",
                "4/8/2014",
                "4/9/2014",
                "4/10/2014",
                "4/11/2014",
                "4/14/2014",
                "4/15/2014",
                "4/16/2014",
                "4/17/2014",
                "4/18/2014",
                "4/21/2014",
                "4/22/2014",
                "4/23/2014",
                "4/24/2014",
                "4/25/2014",
                "4/28/2014",
                "4/29/2014",
                "4/30/2014",
                "5/1/2014",
                "5/2/2014",
                "5/5/2014",
                "5/6/2014",
                "5/7/2014",
                "5/8/2014",
                "5/9/2014",
                "5/12/2014",
                "5/13/2014",
                "5/14/2014",
                "5/15/2014",
                "5/16/2014",
                "5/19/2014",
                "5/20/2014",
                "5/21/2014",
                "5/22/2014",
                "5/23/2014",
                "5/26/2014",
                "5/27/2014",
                "5/28/2014",
                "5/29/2014",
                "5/30/2014",
                "6/2/2014",
                "6/3/2014",
                "6/4/2014",
                "6/5/2014",
                "6/6/2014",
                "6/9/2014",
                "6/10/2014",
                "6/11/2014",
                "6/12/2014",
                "6/13/2014",
                "6/16/2014",
                "6/17/2014",
                "6/18/2014",
                "6/19/2014",
                "6/20/2014",
                "6/23/2014",
                "6/24/2014",
                "6/25/2014",
                "6/26/2014",
                "6/27/2014",
                "6/30/2014",
                "7/1/2014",
                "7/2/2014",
                "7/3/2014",
                "7/4/2014",
                "7/7/2014",
                "7/8/2014",
                "7/9/2014",
                "7/10/2014",
                "7/11/2014",
                "7/14/2014",
                "7/15/2014",
                "7/16/2014",
                "7/17/2014",
                "7/18/2014",
                "7/21/2014",
                "7/22/2014",
                "7/23/2014",
                "7/24/2014",
                "7/25/2014",
                "7/28/2014",
                "7/29/2014",
                "7/30/2014",
                "7/31/2014",
                "8/1/2014",
                "8/4/2014",
                "8/5/2014",
                "8/6/2014",
                "8/7/2014",
                "8/8/2014",
                "8/11/2014",
                "8/12/2014",
                "8/13/2014",
                "8/14/2014",
                "8/15/2014",
                "8/18/2014",
                "8/19/2014",
                "8/20/2014",
                "8/21/2014",
                "8/22/2014",
                "8/25/2014",
                "8/26/2014",
                "8/27/2014",
                "8/28/2014",
                "8/29/2014",
                "9/1/2014",
                "9/2/2014",
                "9/3/2014",
                "9/4/2014",
                "9/5/2014",
                "9/8/2014",
                "9/9/2014",
                "9/10/2014",
                "9/11/2014",
                "9/12/2014",
                "9/15/2014",
                "9/16/2014",
                "9/17/2014",
                "9/18/2014",
                "9/19/2014",
                "9/22/2014",
                "9/23/2014",
                "9/24/2014",
                "9/25/2014",
                "9/26/2014",
                "9/29/2014",
                "9/30/2014",
                "10/1/2014",
                "10/2/2014",
                "10/3/2014",
                "10/6/2014",
                "10/7/2014",
                "10/8/2014",
                "10/9/2014",
                "10/10/2014",
                "10/13/2014",
                "10/14/2014",
                "10/15/2014",
                "10/16/2014",
                "10/17/2014",
                "10/20/2014",
                "10/21/2014",
                "10/22/2014",
                "10/23/2014",
                "10/24/2014",
                "10/27/2014",
                "10/28/2014",
                "10/29/2014",
                "10/30/2014",
                "10/31/2014",
                "11/3/2014",
                "11/4/2014",
                "11/5/2014",
                "11/6/2014",
                "11/7/2014",
                "11/10/2014",
                "11/11/2014",
                "11/12/2014",
                "11/13/2014",
                "11/14/2014",
                "11/17/2014",
                "11/18/2014",
                "11/19/2014",
                "11/20/2014",
                "11/21/2014",
                "11/24/2014",
                "11/25/2014",
                "11/26/2014",
                "11/27/2014",
                "11/28/2014",
                "12/1/2014",
                "12/2/2014",
                "12/3/2014",
                "12/4/2014",
                "12/5/2014",
                "12/8/2014",
                "12/9/2014",
                "12/10/2014",
                "12/11/2014",
                "12/12/2014",
                "12/15/2014",
                "12/16/2014",
                "12/17/2014",
                "12/18/2014",
                "12/19/2014",
                "12/22/2014",
                "12/23/2014",
                "12/24/2014",
                "12/25/2014",
                "12/26/2014",
                "12/29/2014",
                "12/30/2014",
                "12/31/2014",
                "1/1/2015",
                "1/2/2015",
                "1/5/2015",
                "1/6/2015",
                "1/7/2015",
                "1/8/2015",
                "1/9/2015",
                "1/12/2015",
                "1/13/2015",
                "1/14/2015",
                "1/15/2015",
                "1/16/2015",
                "1/19/2015",
                "1/20/2015",
                "1/21/2015",
                "1/22/2015",
                "1/23/2015",
                "1/26/2015",
                "1/27/2015",
                "1/28/2015",
                "1/29/2015",
                "1/30/2015",
                "2/2/2015",
                "2/3/2015",
                "2/4/2015",
                "2/5/2015",
                "2/6/2015",
                "2/9/2015",
                "2/10/2015",
                "2/11/2015",
                "2/12/2015",
                "2/13/2015",
                "2/16/2015",
                "2/17/2015",
                "2/18/2015",
                "2/19/2015",
                "2/20/2015",
                "2/23/2015",
                "2/24/2015",
                "2/25/2015",
                "2/26/2015",
                "2/27/2015",
                "3/2/2015",
                "3/3/2015",
                "3/4/2015",
                "3/5/2015",
                "3/6/2015",
                "3/9/2015",
                "3/10/2015",
                "3/11/2015",
                "3/12/2015",
                "3/13/2015",
                "3/16/2015",
                "3/17/2015",
                "3/18/2015",
                "3/19/2015",
                "3/20/2015",
                "3/23/2015",
                "3/24/2015",
                "3/25/2015",
                "3/26/2015",
                "3/27/2015",
                "3/30/2015",
                "3/31/2015",
                "4/1/2015",
                "4/2/2015",
                "4/3/2015",
                "4/6/2015",
                "4/7/2015",
                "4/8/2015",
                "4/9/2015",
                "4/10/2015",
                "4/13/2015",
                "4/14/2015",
                "4/15/2015",
                "4/16/2015",
                "4/17/2015",
                "4/20/2015",
                "4/21/2015",
                "4/22/2015",
                "4/23/2015",
                "4/24/2015",
                "4/27/2015",
                "4/28/2015",
                "4/29/2015",
                "4/30/2015",
                "5/1/2015",
                "5/4/2015",
                "5/5/2015",
                "5/6/2015",
                "5/7/2015",
                "5/8/2015",
                "5/11/2015",
                "5/12/2015",
                "5/13/2015",
                "5/14/2015",
                "5/15/2015",
                "5/18/2015",
                "5/19/2015",
                "5/20/2015",
                "5/21/2015",
                "5/22/2015",
                "5/25/2015",
                "5/26/2015",
                "5/27/2015",
                "5/28/2015",
                "5/29/2015",
                "6/1/2015",
                "6/2/2015",
                "6/3/2015",
                "6/4/2015",
                "6/5/2015",
                "6/8/2015",
                "6/9/2015",
                "6/10/2015",
                "6/11/2015",
                "6/12/2015",
                "6/15/2015",
                "6/16/2015",
                "6/17/2015",
                "6/18/2015",
                "6/19/2015",
                "6/22/2015",
                "6/23/2015",
                "6/24/2015",
                "6/25/2015",
                "6/26/2015",
                "6/29/2015",
                "6/30/2015",
                "7/1/2015",
                "7/2/2015",
                "7/3/2015",
                "7/6/2015",
                "7/7/2015",
                "7/8/2015",
                "7/9/2015",
                "7/10/2015",
                "7/13/2015",
                "7/14/2015",
                "7/15/2015",
                "7/16/2015",
                "7/17/2015",
                "7/20/2015",
                "7/21/2015",
                "7/22/2015",
                "7/23/2015",
                "7/24/2015",
                "7/27/2015",
                "7/28/2015",
                "7/29/2015",
                "7/30/2015",
                "7/31/2015",
                "8/3/2015",
                "8/4/2015",
                "8/5/2015",
                "8/6/2015",
                "8/7/2015",
                "8/10/2015",
                "8/11/2015",
                "8/12/2015",
                "8/13/2015",
                "8/14/2015",
                "8/17/2015",
                "8/18/2015",
                "8/19/2015",
                "8/20/2015",
                "8/21/2015",
                "8/24/2015",
                "8/25/2015",
                "8/26/2015",
                "8/27/2015",
                "8/28/2015",
                "8/31/2015",
                "9/1/2015",
                "9/2/2015",
                "9/3/2015",
                "9/4/2015",
                "9/7/2015",
                "9/8/2015",
                "9/9/2015",
                "9/10/2015",
                "9/11/2015",
                "9/14/2015",
                "9/15/2015",
                "9/16/2015",
                "9/17/2015",
                "9/18/2015",
                "9/21/2015",
                "9/22/2015",
                "9/23/2015",
                "9/24/2015",
                "9/25/2015",
                "9/28/2015",
                "9/29/2015",
                "9/30/2015",
                "10/1/2015",
                "10/2/2015",
                "10/5/2015",
                "10/6/2015",
                "10/7/2015",
                "10/8/2015",
                "10/9/2015",
                "10/12/2015",
                "10/13/2015",
                "10/14/2015",
                "10/15/2015",
                "10/16/2015",
                "10/19/2015",
                "10/20/2015",
                "10/21/2015",
                "10/22/2015",
                "10/23/2015",
                "10/26/2015",
                "10/27/2015",
                "10/28/2015",
                "10/29/2015",
                "10/30/2015",
                "11/2/2015",
                "11/3/2015",
                "11/4/2015",
                "11/5/2015",
                "11/6/2015",
                "11/9/2015",
                "11/10/2015",
                "11/11/2015",
                "11/12/2015",
                "11/13/2015",
                "11/16/2015",
                "11/17/2015",
                "11/18/2015",
                "11/19/2015",
                "11/20/2015",
                "11/23/2015",
                "11/24/2015",
                "11/25/2015",
                "11/26/2015",
                "11/27/2015",
                "11/30/2015",
                "12/1/2015",
                "12/2/2015",
                "12/3/2015",
                "12/4/2015",
                "12/7/2015",
                "12/8/2015",
                "12/9/2015",
                "12/10/2015",
                "12/11/2015",
                "12/14/2015",
                "12/15/2015",
                "12/16/2015",
                "12/17/2015",
                "12/18/2015",
                "12/21/2015",
                "12/22/2015",
                "12/23/2015",
                "12/24/2015",
                "12/25/2015",
                "12/28/2015",
                "12/29/2015",
                "12/30/2015",
                "12/31/2015",
                "1/1/2016",
                "1/4/2016",
                "1/5/2016",
                "1/6/2016",
                "1/7/2016",
                "1/8/2016",
                "1/11/2016",
                "1/12/2016",
                "1/13/2016",
                "1/14/2016",
                "1/15/2016",
                "1/18/2016",
                "1/19/2016",
                "1/20/2016",
                "1/21/2016",
                "1/22/2016",
                "1/25/2016",
                "1/26/2016",
                "1/27/2016",
                "1/28/2016",
                "1/29/2016",
                "2/1/2016",
                "2/2/2016",
                "2/3/2016",
                "2/4/2016",
                "2/5/2016",
                "2/8/2016",
                "2/9/2016",
                "2/10/2016",
                "2/11/2016",
                "2/12/2016",
                "2/15/2016",
                "2/16/2016",
                "2/17/2016",
                "2/18/2016",
                "2/19/2016",
                "2/22/2016",
                "2/23/2016",
                "2/24/2016",
                "2/25/2016",
                "2/26/2016",
                "2/29/2016",
                "3/1/2016",
                "3/2/2016",
                "3/3/2016",
                "3/4/2016",
                "3/7/2016",
                "3/8/2016",
                "3/9/2016",
                "3/10/2016",
                "3/11/2016",
                "3/14/2016",
                "3/15/2016",
                "3/16/2016",
                "3/17/2016",
                "3/18/2016",
                "3/21/2016",
                "3/22/2016",
                "3/23/2016",
                "3/24/2016",
                "3/25/2016",
                "3/28/2016",
                "3/29/2016",
                "3/30/2016",
                "3/31/2016",
                "4/1/2016",
                "4/4/2016",
                "4/5/2016",
                "4/6/2016",
                "4/7/2016",
                "4/8/2016",
                "4/11/2016",
                "4/12/2016",
                "4/13/2016",
                "4/14/2016",
                "4/15/2016",
                "4/18/2016",
                "4/19/2016",
                "4/20/2016",
                "4/21/2016",
                "4/22/2016",
                "4/25/2016",
                "4/26/2016",
                "4/27/2016",
                "4/28/2016",
                "4/29/2016",
                "5/2/2016",
                "5/3/2016",
                "5/4/2016",
                "5/5/2016",
                "5/6/2016",
                "5/9/2016",
                "5/10/2016",
                "5/11/2016",
                "5/12/2016",
                "5/13/2016",
                "5/16/2016",
                "5/17/2016",
                "5/18/2016",
                "5/19/2016",
                "5/20/2016",
                "5/23/2016",
                "5/24/2016",
                "5/25/2016",
                "5/26/2016",
                "5/27/2016",
                "5/30/2016",
                "5/31/2016",
                "6/1/2016",
                "6/2/2016",
                "6/3/2016",
                "6/6/2016",
                "6/7/2016",
                "6/8/2016",
                "6/9/2016",
                "6/10/2016",
                "6/13/2016",
                "6/14/2016",
                "6/15/2016",
                "6/16/2016",
                "6/17/2016",
                "6/20/2016",
                "6/21/2016",
                "6/22/2016",
                "6/23/2016",
                "6/24/2016",
                "6/27/2016",
                "6/28/2016",
                "6/29/2016",
                "6/30/2016",
                "7/1/2016",
                "7/4/2016",
                "7/5/2016",
                "7/6/2016",
                "7/7/2016",
                "7/8/2016",
                "7/11/2016",
                "7/12/2016",
                "7/13/2016",
                "7/14/2016",
                "7/15/2016",
                "7/18/2016",
                "7/19/2016",
                "7/20/2016",
                "7/21/2016",
                "7/22/2016",
                "7/25/2016",
                "7/26/2016",
                "7/27/2016",
                "7/28/2016",
                "7/29/2016",
                "8/1/2016",
                "8/2/2016",
                "8/3/2016",
                "8/4/2016",
                "8/5/2016",
                "8/8/2016",
                "8/9/2016",
                "8/10/2016",
                "8/11/2016",
                "8/12/2016",
                "8/15/2016",
                "8/16/2016",
                "8/17/2016",
                "8/18/2016",
                "8/19/2016",
                "8/22/2016",
                "8/23/2016",
                "8/24/2016",
                "8/25/2016",
                "8/26/2016",
                "8/29/2016",
                "8/30/2016",
                "8/31/2016",
                "9/1/2016",
                "9/2/2016",
                "9/5/2016",
                "9/6/2016",
                "9/7/2016",
                "9/8/2016",
                "9/9/2016",
                "9/12/2016",
                "9/13/2016",
                "9/14/2016",
                "9/15/2016",
                "9/16/2016",
                "9/19/2016",
                "9/20/2016",
                "9/21/2016",
                "9/22/2016",
                "9/23/2016",
                "9/26/2016",
                "9/27/2016",
                "9/28/2016",
                "9/29/2016",
                "9/30/2016",
                "10/3/2016",
                "10/4/2016",
                "10/5/2016",
                "10/6/2016",
                "10/7/2016",
                "10/10/2016",
                "10/11/2016",
                "10/12/2016",
                "10/13/2016",
                "10/14/2016",
                "10/17/2016",
                "10/18/2016",
                "10/19/2016",
                "10/20/2016",
                "10/21/2016",
                "10/24/2016",
                "10/25/2016",
                "10/26/2016",
                "10/27/2016",
                "10/28/2016",
                "10/31/2016",
                "11/1/2016",
                "11/2/2016",
                "11/3/2016",
                "11/4/2016",
                "11/7/2016",
                "11/8/2016",
                "11/9/2016",
                "11/10/2016",
                "11/11/2016",
                "11/14/2016",
                "11/15/2016",
                "11/16/2016",
                "11/17/2016",
                "11/18/2016",
                "11/21/2016",
                "11/22/2016",
                "11/23/2016",
                "11/24/2016",
                "11/25/2016",
                "11/28/2016",
                "11/29/2016",
                "11/30/2016",
                "12/1/2016",
                "12/2/2016",
                "12/5/2016",
                "12/6/2016",
                "12/7/2016",
                "12/8/2016",
                "12/9/2016",
                "12/12/2016",
                "12/13/2016",
                "12/14/2016",
                "12/15/2016",
                "12/16/2016",
                "12/19/2016",
                "12/20/2016",
                "12/21/2016",
                "12/22/2016",
                "12/23/2016",
                "12/26/2016",
                "12/27/2016",
                "12/28/2016",
                "12/29/2016",
                "12/30/2016",
                "1/2/2017",
                "1/3/2017",
                "1/4/2017",
                "1/5/2017",
                "1/6/2017",
                "1/9/2017",
                "1/10/2017",
                "1/11/2017",
                "1/12/2017",
                "1/13/2017",
                "1/16/2017",
                "1/17/2017",
                "1/18/2017",
                "1/19/2017",
                "1/20/2017",
                "1/23/2017",
                "1/24/2017",
                "1/25/2017",
                "1/26/2017",
                "1/27/2017",
                "1/30/2017",
                "1/31/2017",
                "2/1/2017",
                "2/2/2017",
                "2/3/2017",
                "2/6/2017",
                "2/7/2017",
                "2/8/2017",
                "2/9/2017",
                "2/10/2017",
                "2/13/2017",
                "2/14/2017",
                "2/15/2017",
                "2/16/2017",
                "2/17/2017",
                "2/20/2017",
                "2/21/2017",
                "2/22/2017",
                "2/23/2017",
                "2/24/2017",
                "2/27/2017",
                "2/28/2017",
                "3/1/2017",
                "3/2/2017",
                "3/3/2017",
                "3/6/2017",
                "3/7/2017",
                "3/8/2017",
                "3/9/2017",
                "3/10/2017",
                "3/13/2017",
                "3/14/2017",
                "3/15/2017",
                "3/16/2017",
                "3/17/2017",
                "3/20/2017",
                "3/21/2017",
                "3/22/2017",
                "3/23/2017",
                "3/24/2017",
                "3/27/2017",
                "3/28/2017",
                "3/29/2017",
                "3/30/2017",
                "3/31/2017",
                "4/3/2017",
                "4/4/2017",
                "4/5/2017",
                "4/6/2017",
                "4/7/2017",
                "4/10/2017",
                "4/11/2017",
                "4/12/2017",
                "4/13/2017",
                "4/14/2017",
                "4/17/2017",
                "4/18/2017",
                "4/19/2017",
                "4/20/2017",
                "4/21/2017",
                "4/24/2017",
                "4/25/2017",
                "4/26/2017",
                "4/27/2017",
                "4/28/2017",
                "5/1/2017",
                "5/2/2017",
                "5/3/2017",
                "5/4/2017",
                "5/5/2017",
                "5/8/2017",
                "5/9/2017",
                "5/10/2017",
                "5/11/2017",
                "5/12/2017",
                "5/15/2017",
                "5/16/2017",
                "5/17/2017",
                "5/18/2017",
                "5/19/2017",
                "5/22/2017",
                "5/23/2017",
                "5/24/2017",
                "5/25/2017",
                "5/26/2017",
                "5/29/2017",
                "5/30/2017",
                "5/31/2017",
                "6/1/2017",
                "6/2/2017",
                "6/5/2017",
                "6/6/2017",
                "6/7/2017",
                "6/8/2017",
                "6/9/2017",
                "6/12/2017",
                "6/13/2017",
                "6/14/2017",
                "6/15/2017",
                "6/16/2017",
                "6/19/2017",
                "6/20/2017",
                "6/21/2017",
                "6/22/2017",
                "6/23/2017",
                "6/26/2017",
                "6/27/2017",
                "6/28/2017",
                "6/29/2017",
                "6/30/2017",
                "7/3/2017",
                "7/4/2017",
                "7/5/2017",
                "7/6/2017",
                "7/7/2017",
                "7/10/2017",
                "7/11/2017",
                "7/12/2017",
                "7/13/2017",
                "7/14/2017",
                "7/17/2017",
                "7/18/2017",
                "7/19/2017",
                "7/20/2017",
                "7/21/2017",
                "7/24/2017",
                "7/25/2017",
                "7/26/2017",
                "7/27/2017",
                "7/28/2017",
                "7/31/2017",
                "8/1/2017",
                "8/2/2017",
                "8/3/2017",
                "8/4/2017",
                "8/7/2017",
                "8/8/2017",
                "8/9/2017",
                "8/10/2017",
                "8/11/2017",
                "8/14/2017",
                "8/15/2017",
                "8/16/2017",
                "8/17/2017",
                "8/18/2017",
                "8/21/2017",
                "8/22/2017",
                "8/23/2017",
                "8/24/2017",
                "8/25/2017",
                "8/28/2017",
                "8/29/2017",
                "8/30/2017",
                "8/31/2017",
                "9/1/2017",
                "9/4/2017",
                "9/5/2017",
                "9/6/2017",
                "9/7/2017",
                "9/8/2017",
                "9/11/2017",
                "9/12/2017",
                "9/13/2017",
                "9/14/2017",
                "9/15/2017",
                "9/18/2017",
                "9/19/2017",
                "9/20/2017",
                "9/21/2017",
                "9/22/2017",
                "9/25/2017",
                "9/26/2017",
                "9/27/2017",
                "9/28/2017",
                "9/29/2017",
                "10/2/2017",
                "10/3/2017",
                "10/4/2017",
                "10/5/2017",
                "10/6/2017",
                "10/9/2017",
                "10/10/2017",
                "10/11/2017",
                "10/12/2017",
                "10/13/2017",
                "10/16/2017",
                "10/17/2017",
                "10/18/2017",
                "10/19/2017",
                "10/20/2017",
                "10/23/2017",
                "10/24/2017",
                "10/25/2017",
                "10/26/2017",
                "10/27/2017",
                "10/30/2017",
                "10/31/2017",
                "11/1/2017",
                "11/2/2017",
                "11/3/2017",
                "11/6/2017",
                "11/7/2017",
                "11/8/2017",
                "11/9/2017",
                "11/10/2017",
                "11/13/2017",
                "11/14/2017",
                "11/15/2017",
                "11/16/2017",
                "11/17/2017",
                "11/20/2017",
                "11/21/2017",
                "11/22/2017",
                "11/23/2017",
                "11/24/2017",
                "11/27/2017",
                "11/28/2017",
                "11/29/2017",
                "11/30/2017",
                "12/1/2017",
                "12/4/2017",
                "12/5/2017",
                "12/6/2017",
                "12/7/2017",
                "12/8/2017",
                "12/11/2017",
                "12/12/2017",
                "12/13/2017",
                "12/14/2017",
                "12/15/2017",
                "12/18/2017",
                "12/19/2017",
                "12/20/2017",
                "12/21/2017",
                "12/22/2017",
                "12/25/2017",
                "12/26/2017",
                "12/27/2017",
                "12/28/2017",
                "12/29/2017",
                "1/1/2018",
                "1/2/2018",
                "1/3/2018",
                "1/4/2018",
                "1/5/2018",
                "1/8/2018",
                "1/9/2018",
                "1/10/2018",
                "1/11/2018",
                "1/12/2018",
                "1/15/2018",
                "1/16/2018",
                "1/17/2018",
                "1/18/2018",
                "1/19/2018",
                "1/22/2018",
                "1/23/2018",
                "1/24/2018",
                "1/25/2018",
                "1/26/2018",
                "1/29/2018",
                "1/30/2018",
                "1/31/2018",
                "2/1/2018",
                "2/2/2018",
                "2/5/2018",
                "2/6/2018",
                "2/7/2018",
                "2/8/2018",
                "2/9/2018",
                "2/12/2018",
                "2/13/2018",
                "2/14/2018",
                "2/15/2018",
                "2/16/2018",
                "2/19/2018",
                "2/20/2018",
                "2/21/2018",
                "2/22/2018",
                "2/23/2018",
                "2/26/2018",
                "2/27/2018",
                "2/28/2018",
                "3/1/2018",
                "3/2/2018",
                "3/5/2018",
                "3/6/2018",
                "3/7/2018",
                "3/8/2018",
                "3/9/2018",
                "3/12/2018",
                "3/13/2018",
                "3/14/2018",
                "3/15/2018",
                "3/16/2018",
                "3/19/2018",
                "3/20/2018",
                "3/21/2018",
                "3/22/2018",
                "3/23/2018",
                "3/26/2018",
                "3/27/2018",
                "3/28/2018",
                "3/29/2018",
                "3/30/2018",
                "4/2/2018",
                "4/3/2018",
                "4/4/2018",
                "4/5/2018",
                "4/6/2018",
                "4/9/2018",
                "4/10/2018",
                "4/11/2018",
                "4/12/2018",
                "4/13/2018",
                "4/16/2018",
                "4/17/2018",
                "4/18/2018",
                "4/19/2018",
                "4/20/2018",
                "4/23/2018",
                "4/24/2018",
                "4/25/2018",
                "4/26/2018",
                "4/27/2018",
                "4/30/2018",
                "5/1/2018",
                "5/2/2018",
                "5/3/2018",
                "5/4/2018",
                "5/7/2018",
                "5/8/2018",
                "5/9/2018",
                "5/10/2018",
                "5/11/2018",
                "5/14/2018",
                "5/15/2018",
                "5/16/2018",
                "5/17/2018",
                "5/18/2018",
                "5/21/2018",
                "5/22/2018",
                "5/23/2018",
                "5/24/2018",
                "5/25/2018",
                "5/28/2018",
                "5/29/2018",
                "5/30/2018",
                "5/31/2018",
                "6/1/2018",
                "6/4/2018",
                "6/5/2018",
                "6/6/2018",
                "6/7/2018",
                "6/8/2018",
                "6/11/2018",
                "6/12/2018",
                "6/13/2018",
                "6/14/2018",
                "6/15/2018",
                "6/18/2018",
                "6/19/2018",
                "6/20/2018",
                "6/21/2018",
                "6/22/2018",
                "6/25/2018",
                "6/26/2018",
                "6/27/2018",
                "6/28/2018",
                "6/29/2018",
                "7/2/2018",
                "7/3/2018",
                "7/4/2018",
                "7/5/2018",
                "7/6/2018",
                "7/9/2018",
                "7/10/2018",
                "7/11/2018",
                "7/12/2018",
                "7/13/2018",
                "7/16/2018",
                "7/17/2018",
                "7/18/2018",
                "7/19/2018",
                "7/20/2018",
                "7/23/2018",
                "7/24/2018",
                "7/25/2018",
                "7/26/2018",
                "7/27/2018",
                "7/30/2018",
                "7/31/2018",
                "8/1/2018",
                "8/2/2018",
                "8/3/2018",
                "8/6/2018",
                "8/7/2018",
                "8/8/2018",
                "8/9/2018",
                "8/10/2018",
                "8/13/2018",
                "8/14/2018",
                "8/15/2018",
                "8/16/2018",
                "8/17/2018",
                "8/20/2018",
                "8/21/2018",
                "8/22/2018",
                "8/23/2018",
                "8/24/2018",
                "8/27/2018",
                "8/28/2018",
                "8/29/2018",
                "8/30/2018",
                "8/31/2018",
                "9/3/2018",
                "9/4/2018",
                "9/5/2018",
                "9/6/2018",
                "9/7/2018",
                "9/10/2018",
                "9/11/2018",
                "9/12/2018",
                "9/13/2018",
                "9/14/2018",
                "9/17/2018",
                "9/18/2018",
                "9/19/2018",
                "9/20/2018",
                "9/21/2018",
                "9/24/2018",
                "9/25/2018",
                "9/26/2018",
                "9/27/2018",
                "9/28/2018",
                "10/1/2018",
                "10/2/2018",
                "10/3/2018",
                "10/4/2018",
                "10/5/2018",
                "10/8/2018",
                "10/9/2018",
                "10/10/2018",
                "10/11/2018",
                "10/12/2018",
                "10/15/2018",
                "10/16/2018",
                "10/17/2018",
                "10/18/2018",
                "10/19/2018",
                "10/22/2018",
                "10/23/2018",
                "10/24/2018",
                "10/25/2018",
                "10/26/2018",
                "10/29/2018",
                "10/30/2018",
                "10/31/2018",
                "11/1/2018",
                "11/2/2018",
                "11/5/2018",
                "11/6/2018",
                "11/7/2018",
                "11/8/2018",
                "11/9/2018",
                "11/12/2018",
                "11/13/2018",
                "11/14/2018",
                "11/15/2018",
                "11/16/2018",
                "11/19/2018",
                "11/20/2018",
                "11/21/2018",
                "11/22/2018",
                "11/23/2018",
                "11/26/2018",
                "11/27/2018",
                "11/28/2018",
                "11/29/2018",
                "11/30/2018",
                "12/3/2018",
                "12/4/2018",
                "12/5/2018",
                "12/6/2018",
                "12/7/2018",
                "12/10/2018",
                "12/11/2018",
                "12/12/2018",
                "12/13/2018",
                "12/14/2018",
                "12/17/2018",
                "12/18/2018",
                "12/19/2018",
                "12/20/2018",
                "12/21/2018",
                "12/24/2018",
                "12/25/2018",
                "12/26/2018",
                "12/27/2018",
                "12/28/2018",
                "12/31/2018",
                "1/1/2019",
                "1/2/2019",
                "1/3/2019",
                "1/4/2019",
                "1/7/2019",
                "1/8/2019",
                "1/9/2019",
                "1/10/2019",
                "1/11/2019",
                "1/14/2019",
                "1/15/2019",
                "1/16/2019",
                "1/17/2019",
                "1/18/2019",
                "1/21/2019",
                "1/22/2019",
                "1/23/2019",
                "1/24/2019",
                "1/25/2019",
                "1/28/2019",
                "1/29/2019",
                "1/30/2019",
                "1/31/2019",
                "2/1/2019",
                "2/4/2019",
                "2/5/2019",
                "2/6/2019",
                "2/7/2019",
                "2/8/2019",
                "2/11/2019",
                "2/12/2019",
                "2/13/2019",
                "2/14/2019",
                "2/15/2019",
                "2/18/2019",
                "2/19/2019",
                "2/20/2019",
                "2/21/2019",
                "2/22/2019",
                "2/25/2019",
                "2/26/2019",
                "2/27/2019",
                "2/28/2019",
                "3/1/2019",
                "3/4/2019",
                "3/5/2019",
                "3/6/2019",
                "3/7/2019",
                "3/8/2019",
                "3/11/2019",
                "3/12/2019",
                "3/13/2019",
                "3/14/2019",
                "3/15/2019",
                "3/18/2019",
                "3/19/2019",
                "3/20/2019",
                "3/21/2019",
                "3/22/2019",
                "3/25/2019",
                "3/26/2019",
                "3/27/2019",
                "3/28/2019",
                "3/29/2019",
                "4/1/2019",
                "4/2/2019",
                "4/3/2019",
                "4/4/2019",
                "4/5/2019",
                "4/8/2019",
                "4/9/2019",
                "4/10/2019",
                "4/11/2019",
                "4/12/2019",
                "4/15/2019",
                "4/16/2019",
                "4/17/2019",
                "4/18/2019",
                "4/19/2019",
                "4/22/2019",
                "4/23/2019",
                "4/24/2019",
                "4/25/2019",
                "4/26/2019",
                "4/29/2019",
                "4/30/2019",
                "5/1/2019",
                "5/2/2019",
                "5/3/2019",
                "5/6/2019",
                "5/7/2019",
                "5/8/2019",
                "5/9/2019",
                "5/10/2019",
                "5/13/2019",
                "5/14/2019",
                "5/15/2019",
                "5/16/2019",
                "5/17/2019",
                "5/20/2019",
                "5/21/2019",
                "5/22/2019",
                "5/23/2019",
                "5/24/2019",
                "5/27/2019",
                "5/28/2019",
                "5/29/2019",
                "5/30/2019",
                "5/31/2019",
                "6/3/2019",
                "6/4/2019",
                "6/5/2019",
                "6/6/2019",
                "6/7/2019",
                "6/10/2019",
                "6/11/2019",
                "6/12/2019",
                "6/13/2019",
                "6/14/2019",
                "6/17/2019",
                "6/18/2019",
                "6/19/2019",
                "6/20/2019",
                "6/21/2019",
                "6/24/2019",
                "6/25/2019",
                "6/26/2019",
                "6/27/2019",
                "6/28/2019",
                "7/1/2019",
                "7/2/2019",
                "7/3/2019",
                "7/4/2019",
                "7/5/2019",
                "7/8/2019",
                "7/9/2019",
                "7/10/2019",
                "7/11/2019",
                "7/12/2019",
                "7/15/2019",
                "7/16/2019",
                "7/17/2019",
                "7/18/2019",
                "7/19/2019",
                "7/22/2019",
                "7/23/2019",
                "7/24/2019",
                "7/25/2019",
                "7/26/2019",
                "7/29/2019",
                "7/30/2019",
                "7/31/2019",
                "8/1/2019",
                "8/2/2019",
                "8/5/2019",
                "8/6/2019",
                "8/7/2019",
                "8/8/2019",
                "8/9/2019",
                "8/12/2019",
                "8/13/2019",
                "8/14/2019",
                "8/15/2019",
                "8/16/2019",
                "8/19/2019",
                "8/20/2019",
                "8/21/2019",
                "8/22/2019",
                "8/23/2019",
                "8/26/2019",
                "8/27/2019",
                "8/28/2019",
                "8/29/2019",
                "8/30/2019",
                "9/2/2019",
                "9/3/2019",
                "9/4/2019",
                "9/5/2019",
                "9/6/2019",
                "9/9/2019",
                "9/10/2019",
                "9/11/2019",
                "9/12/2019",
                "9/13/2019",
                "9/16/2019",
                "9/17/2019",
                "9/18/2019",
                "9/19/2019",
                "9/20/2019",
                "9/23/2019",
                "9/24/2019",
                "9/25/2019",
                "9/26/2019",
                "9/27/2019",
                "9/30/2019",
                "10/1/2019",
                "10/2/2019",
                "10/3/2019",
                "10/4/2019",
                "10/7/2019",
                "10/8/2019",
                "10/9/2019",
                "10/10/2019",
                "10/11/2019",
                "10/14/2019",
                "10/15/2019",
                "10/16/2019",
                "10/17/2019",
                "10/18/2019",
                "10/21/2019",
                "10/22/2019",
                "10/23/2019",
                "10/24/2019",
                "10/25/2019",
                "10/28/2019",
                "10/29/2019",
                "10/30/2019",
                "10/31/2019",
                "11/1/2019",
                "11/4/2019",
                "11/5/2019",
                "11/6/2019",
                "11/7/2019",
                "11/8/2019",
                "11/11/2019",
                "11/12/2019",
                "11/13/2019",
                "11/14/2019",
                "11/15/2019",
                "11/18/2019",
                "11/19/2019",
                "11/20/2019",
                "11/21/2019",
                "11/22/2019",
                "11/25/2019",
                "11/26/2019",
                "11/27/2019",
                "11/28/2019",
                "11/29/2019",
                "12/2/2019",
                "12/3/2019",
                "12/4/2019",
                "12/5/2019",
                "12/6/2019",
                "12/9/2019",
                "12/10/2019",
                "12/11/2019",
                "12/12/2019",
                "12/13/2019",
                "12/16/2019",
                "12/17/2019",
                "12/18/2019",
                "12/19/2019",
                "12/20/2019",
                "12/23/2019",
                "12/24/2019",
                "12/25/2019",
                "12/26/2019",
                "12/27/2019",
                "12/30/2019",
                "12/31/2019",
                "1/1/2020",
                "1/2/2020",
                "1/3/2020",
                "1/6/2020",
                "1/7/2020",
                "1/8/2020",
                "1/9/2020",
                "1/10/2020",
                "1/13/2020",
                "1/14/2020",
                "1/15/2020",
                "1/16/2020",
                "1/17/2020",
                "1/20/2020",
                "1/21/2020",
                "1/22/2020",
                "1/23/2020",
                "1/24/2020",
                "1/27/2020",
                "1/28/2020",
                "1/29/2020",
                "1/30/2020",
                "1/31/2020",
                "2/3/2020",
                "2/4/2020",
                "2/5/2020",
                "2/6/2020",
                "2/7/2020",
                "2/10/2020",
                "2/11/2020",
                "2/12/2020",
                "2/13/2020",
                "2/14/2020",
                "2/17/2020",
                "2/18/2020",
                "2/19/2020",
                "2/20/2020",
                "2/21/2020",
                "2/24/2020",
                "2/25/2020",
                "2/26/2020",
                "2/27/2020",
                "2/28/2020",
                "3/2/2020",
                "3/3/2020",
                "3/4/2020",
                "3/5/2020",
                "3/6/2020",
                "3/9/2020",
                "3/10/2020",
                "3/11/2020",
                "3/12/2020",
                "3/13/2020",
                "3/16/2020",
                "3/17/2020",
                "3/18/2020",
                "3/19/2020",
                "3/20/2020",
                "3/23/2020",
                "3/24/2020",
                "3/25/2020",
                "3/26/2020",
                "3/27/2020",
                "3/30/2020",
                "3/31/2020",
                "4/1/2020",
                "4/2/2020",
                "4/3/2020",
                "4/6/2020",
                "4/7/2020",
                "4/8/2020",
                "4/9/2020",
                "4/10/2020",
                "4/13/2020",
                "4/14/2020",
                "4/15/2020",
                "4/16/2020",
                "4/17/2020",
                "4/20/2020",
                "4/21/2020",
                "4/22/2020",
                "4/23/2020",
                "4/24/2020",
                "4/27/2020",
                "4/28/2020",
                "4/29/2020",
                "4/30/2020",
                "5/1/2020",
                "5/4/2020",
                "5/5/2020",
                "5/6/2020",
                "5/7/2020",
                "5/8/2020",
                "5/11/2020",
                "5/12/2020",
                "5/13/2020",
                "5/14/2020",
                "5/15/2020",
                "5/18/2020",
                "5/19/2020",
                "5/20/2020",
                "5/21/2020",
                "5/22/2020",
                "5/25/2020",
                "5/26/2020",
                "5/27/2020",
                "5/28/2020",
                "5/29/2020",
                "6/1/2020",
                "6/2/2020",
                "6/3/2020",
                "6/4/2020",
                "6/5/2020",
                "6/8/2020",
                "6/9/2020",
                "6/10/2020",
                "6/11/2020",
                "6/12/2020",
                "6/15/2020",
                "6/16/2020",
                "6/17/2020",
                "6/18/2020",
                "6/19/2020",
                "6/22/2020",
                "6/23/2020",
                "6/24/2020",
                "6/25/2020",
                "6/26/2020",
                "6/29/2020",
                "6/30/2020",
                "7/1/2020",
                "7/2/2020",
                "7/3/2020",
                "7/6/2020",
                "7/7/2020",
                "7/8/2020",
                "7/9/2020",
                "7/10/2020",
                "7/13/2020",
                "7/14/2020",
                "7/15/2020",
                "7/16/2020",
                "7/17/2020",
                "7/20/2020",
                "7/21/2020",
                "7/22/2020",
                "7/23/2020",
                "7/24/2020",
                "7/27/2020",
                "7/28/2020",
                "7/29/2020",
                "7/30/2020",
                "7/31/2020",
                "8/3/2020",
                "8/4/2020",
                "8/5/2020",
                "8/6/2020",
                "8/7/2020",
                "8/10/2020",
                "8/11/2020",
                "8/12/2020",
                "8/13/2020",
                "8/14/2020",
                "8/17/2020",
                "8/18/2020",
                "8/19/2020",
                "8/20/2020",
                "8/21/2020",
                "8/24/2020",
                "8/25/2020",
                "8/26/2020",
                "8/27/2020",
                "8/28/2020",
                "8/31/2020",
                "9/1/2020",
                "9/2/2020",
                "9/3/2020",
                "9/4/2020",
                "9/7/2020",
                "9/8/2020",
                "9/9/2020",
                "9/10/2020",
                "9/11/2020",
                "9/14/2020",
                "9/15/2020",
                "9/16/2020",
                "9/17/2020",
                "9/18/2020",
                "9/21/2020",
                "9/22/2020",
                "9/23/2020",
                "9/24/2020",
                "9/25/2020",
                "9/28/2020",
                "9/29/2020",
                "9/30/2020",
                "10/1/2020",
                "10/2/2020",
                "10/5/2020",
                "10/6/2020",
                "10/7/2020",
                "10/8/2020",
                "10/9/2020",
                "10/12/2020",
                "10/13/2020",
                "10/14/2020",
                "10/15/2020",
                "10/16/2020",
                "10/19/2020",
                "10/20/2020",
                "10/21/2020",
                "10/22/2020",
                "10/23/2020",
                "10/26/2020",
                "10/27/2020",
                "10/28/2020",
                "10/29/2020",
                "10/30/2020",
                "11/2/2020",
                "11/3/2020",
                "11/4/2020",
                "11/5/2020",
                "11/6/2020",
                "11/9/2020",
                "11/10/2020",
                "11/11/2020",
                "11/12/2020",
                "11/13/2020",
                "11/16/2020",
                "11/17/2020",
                "11/18/2020",
                "11/19/2020",
                "11/20/2020",
                "11/23/2020",
                "11/24/2020",
                "11/25/2020",
                "11/26/2020",
                "11/27/2020",
                "11/30/2020",
                "12/1/2020",
                "12/2/2020",
                "12/3/2020",
                "12/4/2020",
                "12/7/2020",
                "12/8/2020",
                "12/9/2020",
                "12/10/2020",
                "12/11/2020",
                "12/14/2020",
                "12/15/2020",
                "12/16/2020",
                "12/17/2020",
                "12/18/2020",
                "12/21/2020",
                "12/22/2020",
                "12/23/2020",
                "12/24/2020",
                "12/25/2020",
                "12/28/2020",
                "12/29/2020",
                "12/30/2020",
                "12/31/2020",
                "1/1/2021",
                "1/4/2021",
                "1/5/2021",
                "1/6/2021",
                "1/7/2021",
                "1/8/2021",
                "1/11/2021",
                "1/12/2021",
                "1/13/2021",
                "1/14/2021",
                "1/15/2021",
                "1/18/2021",
                "1/19/2021",
                "1/20/2021",
                "1/21/2021",
                "1/22/2021",
                "1/25/2021",
                "1/26/2021",
                "1/27/2021",
                "1/28/2021",
                "1/29/2021",
                "2/1/2021",
                "2/2/2021",
                "2/3/2021",
                "2/4/2021",
                "2/5/2021",
                "2/8/2021",
                "2/9/2021",
                "2/10/2021",
                "2/11/2021",
                "2/12/2021",
                "2/15/2021",
                "2/16/2021",
                "2/17/2021",
                "2/18/2021",
                "2/19/2021",
                "2/22/2021",
                "2/23/2021",
                "2/24/2021",
                "2/25/2021",
                "2/26/2021",
                "3/1/2021",
                "3/2/2021",
                "3/3/2021",
                "3/4/2021",
                "3/5/2021",
                "3/8/2021",
                "3/9/2021",
                "3/10/2021",
                "3/11/2021",
                "3/12/2021",
                "3/15/2021",
                "3/16/2021",
                "3/17/2021",
                "3/18/2021",
                "3/19/2021",
                "3/22/2021",
                "3/23/2021",
                "3/24/2021",
                "3/25/2021",
                "3/26/2021",
                "3/29/2021",
                "3/30/2021",
                "3/31/2021",
                "4/1/2021",
                "4/2/2021",
                "4/5/2021",
                "4/6/2021",
                "4/7/2021",
                "4/8/2021",
                "4/9/2021",
                "4/12/2021",
                "4/13/2021",
                "4/14/2021",
                "4/15/2021",
                "4/16/2021",
                "4/19/2021",
                "4/20/2021",
                "4/21/2021",
                "4/22/2021",
                "4/23/2021",
                "4/26/2021",
                "4/27/2021",
                "4/28/2021",
                "4/29/2021",
                "4/30/2021",
                "5/3/2021",
                "5/4/2021",
                "5/5/2021",
                "5/6/2021",
                "5/7/2021",
                "5/10/2021",
                "5/11/2021",
                "5/12/2021",
                "5/13/2021",
                "5/14/2021",
                "5/17/2021",
                "5/18/2021",
                "5/19/2021",
                "5/20/2021",
                "5/21/2021",
                "5/24/2021",
                "5/25/2021",
                "5/26/2021",
                "5/27/2021",
                "5/28/2021",
                "5/31/2021",
                "6/1/2021",
                "6/2/2021",
                "6/3/2021",
                "6/4/2021",
                "6/7/2021",
                "6/8/2021",
                "6/9/2021",
                "6/10/2021",
                "6/11/2021",
                "6/14/2021",
                "6/15/2021",
                "6/16/2021",
                "6/17/2021",
                "6/18/2021",
                "6/21/2021",
                "6/22/2021",
                "6/23/2021",
                "6/24/2021",
                "6/25/2021",
                "6/28/2021",
                "6/29/2021",
                "6/30/2021",
                "7/1/2021",
                "7/2/2021",
                "7/5/2021",
                "7/6/2021",
                "7/7/2021",
                "7/8/2021",
                "7/9/2021",
                "7/12/2021",
                "7/13/2021",
                "7/14/2021",
                "7/15/2021",
                "7/16/2021",
                "7/19/2021",
                "7/20/2021",
                "7/21/2021",
                "7/22/2021",
                "7/23/2021",
                "7/26/2021",
                "7/27/2021",
                "7/28/2021",
                "7/29/2021",
                "7/30/2021",
                "8/2/2021",
                "8/3/2021",
                "8/4/2021",
                "8/5/2021",
                "8/6/2021",
                "8/9/2021",
                "8/10/2021",
                "8/11/2021",
                "8/12/2021",
                "8/13/2021",
                "8/16/2021",
                "8/17/2021",
                "8/18/2021",
                "8/19/2021",
                "8/20/2021",
                "8/23/2021",
                "8/24/2021",
                "8/25/2021",
                "8/26/2021",
                "8/27/2021",
                "8/30/2021",
                "8/31/2021",
                "9/1/2021",
                "9/2/2021",
                "9/3/2021",
                "9/6/2021",
                "9/7/2021",
                "9/8/2021",
                "9/9/2021",
                "9/10/2021",
                "9/13/2021",
                "9/14/2021",
                "9/15/2021",
                "9/16/2021",
                "9/17/2021",
                "9/20/2021",
                "9/21/2021",
                "9/22/2021",
                "9/23/2021",
                "9/24/2021",
                "9/27/2021",
                "9/28/2021",
                "9/29/2021",
                "9/30/2021",
                "10/1/2021",
                "10/4/2021",
                "10/5/2021",
                "10/6/2021",
                "10/7/2021",
                "10/8/2021",
                "10/11/2021",
                "10/12/2021",
                "10/13/2021",
                "10/14/2021",
                "10/15/2021",
                "10/18/2021",
                "10/19/2021",
                "10/20/2021",
                "10/21/2021",
                "10/22/2021",
                "10/25/2021",
                "10/26/2021",
                "10/27/2021",
                "10/28/2021",
                "10/29/2021",
                "11/1/2021",
                "11/2/2021",
                "11/3/2021",
                "11/4/2021",
                "11/5/2021",
                "11/8/2021",
                "11/9/2021",
                "11/10/2021",
                "11/11/2021",
                "11/12/2021",
                "11/15/2021",
                "11/16/2021",
                "11/17/2021",
                "11/18/2021",
                "11/19/2021",
                "11/22/2021",
                "11/23/2021",
                "11/24/2021",
                "11/25/2021",
                "11/26/2021",
                "11/29/2021",
                "11/30/2021",
                "12/1/2021",
                "12/2/2021",
                "12/3/2021",
                "12/6/2021",
                "12/7/2021",
                "12/8/2021",
                "12/9/2021",
                "12/10/2021",
                "12/13/2021",
                "12/14/2021",
                "12/15/2021",
                "12/16/2021",
                "12/17/2021",
                "12/20/2021",
                "12/21/2021",
                "12/22/2021",
                "12/23/2021",
                "12/24/2021",
                "12/27/2021",
                "12/28/2021",
                "12/29/2021",
                "12/30/2021",
                "12/31/2021",
                "1/3/2022",
                "1/4/2022",
                "1/5/2022",
                "1/6/2022",
                "1/7/2022",
                "1/10/2022",
                "1/11/2022",
                "1/12/2022",
                "1/13/2022",
                "1/14/2022",
                "1/17/2022",
                "1/18/2022",
                "1/19/2022",
                "1/20/2022",
                "1/21/2022",
                "1/24/2022",
                "1/25/2022",
                "1/26/2022",
                "1/27/2022",
                "1/28/2022",
                "1/31/2022",
                "2/1/2022",
                "2/2/2022",
                "2/3/2022",
                "2/4/2022",
                "2/7/2022",
                "2/8/2022",
                "2/9/2022",
                "2/10/2022",
                "2/11/2022",
                "2/14/2022",
                "2/15/2022",
                "2/16/2022",
                "2/17/2022",
                "2/18/2022",
                "2/21/2022",
                "2/22/2022",
                "2/23/2022",
                "2/24/2022",
                "2/25/2022",
                "2/28/2022",
                "3/1/2022",
                "3/2/2022",
                "3/3/2022",
                "3/4/2022",
                "3/7/2022",
                "3/8/2022",
                "3/9/2022",
                "3/10/2022",
                "3/11/2022",
                "3/14/2022",
                "3/15/2022",
                "3/16/2022",
                "3/17/2022",
                "3/18/2022",
                "3/21/2022",
                "3/22/2022",
                "3/23/2022",
                "3/24/2022",
                "3/25/2022",
                "3/28/2022",
                "3/29/2022",
                "3/30/2022",
                "3/31/2022",
                "4/1/2022",
                "4/4/2022",
                "4/5/2022",
                "4/6/2022",
                "4/7/2022",
                "4/8/2022",
                "4/11/2022",
                "4/12/2022",
                "4/13/2022",
                "4/14/2022",
                "4/15/2022",
                "4/18/2022",
                "4/19/2022",
                "4/20/2022",
                "4/21/2022",
                "4/22/2022",
                "4/25/2022",
                "4/26/2022",
                "4/27/2022",
                "4/28/2022",
                "4/29/2022",
                "5/2/2022",
                "5/3/2022",
                "5/4/2022",
                "5/5/2022",
                "5/6/2022",
                "5/9/2022",
                "5/10/2022",
                "5/11/2022",
                "5/12/2022",
                "5/13/2022",
                "5/16/2022",
                "5/17/2022",
                "5/18/2022",
                "5/19/2022",
                "5/20/2022",
                "5/23/2022",
                "5/24/2022",
                "5/25/2022",
                "5/26/2022",
                "5/27/2022",
                "5/30/2022",
                "5/31/2022",
                "6/1/2022",
                "6/2/2022",
                "6/3/2022",
                "6/6/2022",
                "6/7/2022",
                "6/8/2022",
                "6/9/2022",
                "6/10/2022",
                "6/13/2022",
                "6/14/2022",
                "6/15/2022",
                "6/16/2022",
                "6/17/2022",
                "6/20/2022",
                "6/21/2022",
                "6/22/2022",
                "6/23/2022",
                "6/24/2022",
                "6/27/2022",
                "6/28/2022",
                "6/29/2022",
                "6/30/2022",
                "7/1/2022",
                "7/4/2022",
                "7/5/2022",
                "7/6/2022",
                "7/7/2022",
                "7/8/2022",
                "7/11/2022",
                "7/12/2022",
                "7/13/2022",
                "7/14/2022",
                "7/15/2022",
                "7/18/2022",
                "7/19/2022",
                "7/20/2022",
                "7/21/2022",
                "7/22/2022",
                "7/25/2022",
                "7/26/2022",
                "7/27/2022",
                "7/28/2022",
                "7/29/2022",
                "8/1/2022",
                "8/2/2022",
                "8/3/2022",
                "8/4/2022",
                "8/5/2022",
                "8/8/2022",
                "8/9/2022",
                "8/10/2022",
                "8/11/2022",
                "8/12/2022",
                "8/15/2022",
                "8/16/2022",
                "8/17/2022",
                "8/18/2022",
                "8/19/2022",
                "8/22/2022",
                "8/23/2022",
                "8/24/2022",
                "8/25/2022",
                "8/26/2022",
                "8/29/2022",
                "8/30/2022",
                "8/31/2022",
                "9/1/2022",
                "9/2/2022",
                "9/5/2022",
                "9/6/2022",
                "9/7/2022",
                "9/8/2022",
                "9/9/2022",
                "9/12/2022",
                "9/13/2022",
                "9/14/2022",
                "9/15/2022",
                "9/16/2022",
                "9/19/2022",
                "9/20/2022",
                "9/21/2022",
                "9/22/2022",
                "9/23/2022",
                "9/26/2022",
                "9/27/2022",
                "9/28/2022",
                "9/29/2022",
                "9/30/2022",
                "10/3/2022",
                "10/4/2022",
                "10/5/2022",
                "10/6/2022",
                "10/7/2022",
                "10/10/2022",
                "10/11/2022",
                "10/12/2022",
                "10/13/2022",
                "10/14/2022",
                "10/17/2022",
                "10/18/2022",
                "10/19/2022",
                "10/20/2022",
                "10/21/2022",
                "10/24/2022",
                "10/25/2022",
                "10/26/2022",
                "10/27/2022",
                "10/28/2022",
                "10/31/2022",
                "11/1/2022",
                "11/2/2022",
                "11/3/2022",
                "11/4/2022",
                "11/7/2022",
                "11/8/2022",
                "11/9/2022",
                "11/10/2022",
                "11/11/2022",
                "11/14/2022",
                "11/15/2022",
                "11/16/2022",
                "11/17/2022",
                "11/18/2022",
                "11/21/2022",
                "11/22/2022",
                "11/23/2022",
                "11/24/2022",
                "11/25/2022",
                "11/28/2022",
                "11/29/2022",
                "11/30/2022",
                "12/1/2022",
                "12/2/2022",
                "12/5/2022",
                "12/6/2022",
                "12/7/2022",
                "12/8/2022",
                "12/9/2022",
                "12/12/2022",
                "12/13/2022",
                "12/14/2022",
                "12/15/2022",
                "12/16/2022",
                "12/19/2022",
                "12/20/2022",
                "12/21/2022",
                "12/22/2022",
                "12/23/2022",
                "12/26/2022",
                "12/27/2022",
                "12/28/2022",
                "12/29/2022",
                "12/30/2022",
                "1/2/2023",
                "1/3/2023",
                "1/4/2023",
                "1/5/2023",
                "1/6/2023",
                "1/9/2023",
                "1/10/2023",
                "1/11/2023",
                "1/12/2023",
                "1/13/2023",
                "1/16/2023",
                "1/17/2023",
                "1/18/2023",
                "1/19/2023",
                "1/20/2023",
                "1/23/2023",
                "1/24/2023",
                "1/25/2023",
                "1/26/2023",
                "1/27/2023",
                "1/30/2023",
                "1/31/2023",
                "2/1/2023",
                "2/2/2023",
                "2/3/2023",
                "2/6/2023",
                "2/7/2023",
                "2/8/2023",
                "2/9/2023",
                "2/10/2023",
                "2/13/2023",
                "2/14/2023",
                "2/15/2023",
                "2/16/2023",
                "2/17/2023",
                "2/20/2023",
                "2/21/2023",
                "2/22/2023",
                "2/23/2023",
                "2/24/2023",
                "2/27/2023",
                "2/28/2023",
                "3/1/2023",
                "3/2/2023",
                "3/3/2023",
                "3/6/2023",
                "3/7/2023",
                "3/8/2023",
                "3/9/2023",
                "3/10/2023",
                "3/13/2023",
                "3/14/2023",
                "3/15/2023",
                "3/16/2023",
                "3/17/2023",
                "3/20/2023",
                "3/21/2023",
                "3/22/2023",
                "3/23/2023",
                "3/24/2023",
                "3/27/2023",
                "3/28/2023",
                "3/29/2023",
                "3/30/2023",
                "3/31/2023",
                "4/3/2023",
                "4/4/2023",
                "4/5/2023",
                "4/6/2023",
                "4/7/2023",
                "4/10/2023",
                "4/11/2023",
                "4/12/2023",
                "4/13/2023",
                "4/14/2023",
                "4/17/2023",
                "4/18/2023",
                "4/19/2023",
                "4/20/2023",
                "4/21/2023",
                "4/24/2023",
                "4/25/2023",
                "4/26/2023",
                "4/27/2023",
                "4/28/2023",
                "5/1/2023",
                "5/2/2023",
                "5/3/2023",
                "5/4/2023",
                "5/5/2023",
                "5/8/2023",
                "5/9/2023",
                "5/10/2023",
                "5/11/2023",
                "5/12/2023",
                "5/15/2023",
                "5/16/2023",
                "5/17/2023",
                "5/18/2023",
                "5/19/2023",
                "5/22/2023",
                "5/23/2023",
                "5/24/2023",
                "5/25/2023",
                "5/26/2023",
                "5/29/2023",
                "5/30/2023",
                "5/31/2023",
                "6/1/2023",
                "6/2/2023",
                "6/5/2023",
                "6/6/2023",
                "6/7/2023",
                "6/8/2023",
                "6/9/2023",
                "6/12/2023",
                "6/13/2023",
                "6/14/2023",
                "6/15/2023",
                "6/16/2023",
                "6/19/2023",
                "6/20/2023",
                "6/21/2023",
                "6/22/2023",
                "6/23/2023",
                "6/26/2023",
                "6/27/2023",
                "6/28/2023",
                "6/29/2023",
                "6/30/2023",
                "7/3/2023",
                "7/4/2023",
                "7/5/2023",
                "7/6/2023",
                "7/7/2023",
                "7/10/2023",
                "7/11/2023",
                "7/12/2023",
                "7/13/2023",
                "7/14/2023",
                "7/17/2023",
                "7/18/2023",
                "7/19/2023",
                "7/20/2023",
                "7/21/2023",
                "7/24/2023",
                "7/25/2023",
                "7/26/2023",
                "7/27/2023",
                "7/28/2023",
                "7/31/2023",
                "8/1/2023",
                "8/2/2023",
                "8/3/2023",
                "8/4/2023",
                "8/7/2023",
                "8/8/2023",
                "8/9/2023",
                "8/10/2023",
                "8/11/2023",
                "8/14/2023",
                "8/15/2023",
                "8/16/2023",
                "8/17/2023",
                "8/18/2023",
                "8/21/2023",
                "8/22/2023",
                "8/23/2023",
                "8/24/2023",
                "8/25/2023",
                "8/28/2023",
                "8/29/2023",
                "8/30/2023",
                "8/31/2023",
                "9/1/2023",
                "9/4/2023",
                "9/5/2023",
                "9/6/2023",
                "9/7/2023",
                "9/8/2023",
                "9/11/2023",
                "9/12/2023",
                "9/13/2023",
                "9/14/2023",
                "9/15/2023",
                "9/18/2023",
                "9/19/2023",
                "9/20/2023",
                "9/21/2023",
                "9/22/2023",
                "9/25/2023",
                "9/26/2023",
                "9/27/2023",
                "9/28/2023",
                "9/29/2023"
            ],
            title: {
                text: null
            },
            tickInterval: 1000
        },
        yAxis: [
            {
                title: {
                    text: "Yield %"
                },
                opposite: false
            }
        ],
        series: [
            {
                name: "Composite_US Yield",
                type: "line",
                yAxis: 0,
                data: [
                    3.65,
                    3.61,
                    3.7,
                    3.66,
                    3.73,
                    3.71,
                    3.69,
                    3.78,
                    3.84,
                    3.93,
                    3.86,
                    3.85,
                    3.81,
                    3.72,
                    3.78,
                    3.69,
                    3.72,
                    3.63,
                    3.75,
                    3.81,
                    3.77,
                    3.82,
                    3.77,
                    3.84,
                    3.9,
                    3.93,
                    3.95,
                    3.95,
                    3.9,
                    3.77,
                    3.7,
                    3.67,
                    3.62,
                    3.73,
                    3.64,
                    3.65,
                    3.73,
                    3.68,
                    3.75,
                    3.75,
                    3.84,
                    3.9,
                    3.89,
                    3.91,
                    3.88,
                    3.73,
                    3.76,
                    3.85,
                    3.8,
                    3.72,
                    3.73,
                    3.75,
                    3.7,
                    3.68,
                    3.64,
                    3.64,
                    3.68,
                    3.77,
                    3.69,
                    3.69,
                    3.65,
                    3.73,
                    3.74,
                    3.75,
                    3.75,
                    3.86,
                    3.86,
                    3.75,
                    3.73,
                    3.76,
                    3.56,
                    3.56,
                    3.48,
                    3.48,
                    3.47,
                    3.54,
                    3.54,
                    3.55,
                    3.51,
                    3.45,
                    3.57,
                    3.62,
                    3.56,
                    3.7,
                    3.68,
                    3.64,
                    3.65,
                    3.59,
                    3.61,
                    3.67,
                    3.58,
                    3.55,
                    3.62,
                    3.52,
                    3.54,
                    3.53,
                    3.53,
                    3.52,
                    3.53,
                    3.51,
                    3.59,
                    3.52,
                    3.5,
                    3.49,
                    3.51,
                    3.45,
                    3.46,
                    3.53,
                    3.54,
                    3.5,
                    3.33,
                    3.23,
                    3.19,
                    3.2,
                    3.17,
                    3.25,
                    3.25,
                    3.16,
                    3.19,
                    3.24,
                    3.25,
                    3.19,
                    3.17,
                    3.19,
                    3.21,
                    3.31,
                    3.37,
                    3.37,
                    3.31,
                    3.36,
                    3.64,
                    3.7,
                    3.64,
                    3.67,
                    3.7,
                    3.7,
                    3.74,
                    3.87,
                    3.89,
                    3.92,
                    3.85,
                    3.9,
                    3.99,
                    3.97,
                    3.92,
                    4.01,
                    3.99,
                    3.93,
                    4.06,
                    4.09,
                    4.06,
                    4.06,
                    4.12,
                    4.13,
                    4.15,
                    4.36,
                    4.36,
                    4.32,
                    4.37,
                    4.44,
                    4.34,
                    4.25,
                    4.29,
                    4.34,
                    4.27,
                    4.33,
                    4.3,
                    4.3,
                    4.22,
                    4.17,
                    4.22,
                    4.22,
                    4.27,
                    4.32,
                    4.29,
                    4.36,
                    4.34,
                    4.34,
                    4.39,
                    4.4,
                    4.43,
                    4.48,
                    4.29,
                    4.33,
                    4.29,
                    4.32,
                    4.29,
                    4.32,
                    4.3,
                    4.24,
                    4.24,
                    4.34,
                    4.29,
                    4.17,
                    4.15,
                    4.03,
                    4.03,
                    4.05,
                    4.05,
                    4.06,
                    4.04,
                    4.04,
                    4.06,
                    4.08,
                    4.08,
                    3.94,
                    3.95,
                    4.06,
                    4.08,
                    4.06,
                    4.05,
                    4.11,
                    4.23,
                    4.19,
                    4.18,
                    4.08,
                    4.06,
                    4.03,
                    4.03,
                    4.01,
                    3.8,
                    3.84,
                    3.9,
                    3.87,
                    3.87,
                    3.82,
                    3.86,
                    3.78,
                    3.83,
                    3.79,
                    3.82,
                    3.87,
                    3.86,
                    3.86,
                    3.81,
                    3.83,
                    3.78,
                    3.71,
                    3.7,
                    3.8,
                    3.89,
                    3.9,
                    3.85,
                    3.76,
                    3.8,
                    3.79,
                    3.75,
                    3.73,
                    3.77,
                    3.67,
                    3.72,
                    3.66,
                    3.66,
                    3.61,
                    3.66,
                    3.67,
                    3.63,
                    3.64,
                    3.72,
                    3.75,
                    3.81,
                    3.79,
                    3.8,
                    3.85,
                    3.88,
                    3.76,
                    3.76,
                    3.73,
                    3.68,
                    3.64,
                    3.68,
                    3.67,
                    3.67,
                    3.62,
                    3.64,
                    3.61,
                    3.61,
                    3.63,
                    3.71,
                    3.68,
                    3.65,
                    3.7,
                    3.68,
                    3.7,
                    3.71,
                    3.83,
                    3.87,
                    3.88,
                    3.9,
                    3.9,
                    3.84,
                    3.86,
                    3.88,
                    3.8,
                    3.79,
                    3.88,
                    3.87,
                    3.88,
                    3.91,
                    3.9,
                    3.94,
                    4.01,
                    4.02,
                    4.04,
                    4.08,
                    3.92,
                    3.9,
                    3.9,
                    3.81,
                    3.86,
                    3.84,
                    3.84,
                    3.82,
                    3.77,
                    3.87,
                    3.89,
                    3.88,
                    3.86,
                    3.87,
                    3.9,
                    3.89,
                    3.97,
                    3.97,
                    4,
                    3.94,
                    3.91,
                    3.92,
                    4,
                    4,
                    3.97,
                    3.99,
                    4,
                    3.97,
                    3.97,
                    3.91,
                    3.96,
                    3.96,
                    3.94,
                    3.94,
                    3.92,
                    3.89,
                    3.88,
                    3.95,
                    3.96,
                    3.99,
                    3.92,
                    3.91,
                    3.92,
                    3.93,
                    3.96,
                    3.87,
                    3.86,
                    3.84,
                    3.78,
                    3.88,
                    3.89,
                    3.88,
                    3.9,
                    3.96,
                    3.98,
                    4.07,
                    4.06,
                    4.08,
                    4.06,
                    4.09,
                    4.08,
                    4.19,
                    4.18,
                    4.19,
                    4.19,
                    4.13,
                    4.14,
                    4.22,
                    4.33,
                    4.3,
                    4.38,
                    4.35,
                    4.38,
                    4.34,
                    4.3,
                    4.34,
                    4.35,
                    4.48,
                    4.43,
                    4.46,
                    4.45,
                    4.49,
                    4.42,
                    4.39,
                    4.32,
                    4.29,
                    4.29,
                    4.3,
                    4.25,
                    4.31,
                    4.3,
                    4.27,
                    4.19,
                    4.19,
                    4.12,
                    4.06,
                    4.09,
                    4.03,
                    4.01,
                    4.13,
                    4.08,
                    4.09,
                    4.11,
                    4.07,
                    3.98,
                    4.05,
                    4.03,
                    4.01,
                    4.02,
                    3.98,
                    4.1,
                    4.13,
                    4.05,
                    4.05,
                    4.02,
                    3.97,
                    3.97,
                    3.96,
                    3.94,
                    3.98,
                    3.99,
                    3.93,
                    3.9,
                    3.95,
                    3.95,
                    3.94,
                    3.94,
                    3.86,
                    3.75,
                    3.78,
                    3.85,
                    3.83,
                    3.79,
                    3.83,
                    3.84,
                    3.95,
                    3.98,
                    3.99,
                    3.99,
                    3.95,
                    3.97,
                    4,
                    3.93,
                    3.83,
                    3.84,
                    3.81,
                    3.79,
                    3.86,
                    3.87,
                    3.81,
                    3.94,
                    3.93,
                    4,
                    3.96,
                    3.95,
                    3.98,
                    3.99,
                    4.04,
                    4.05,
                    4.07,
                    4.07,
                    4.12,
                    4.08,
                    4.07,
                    4.18,
                    4.13,
                    4.15,
                    4.14,
                    4.17,
                    4.11,
                    4.2,
                    4.23,
                    4.25,
                    4.2,
                    4.23,
                    4.31,
                    4.35,
                    4.31,
                    4.31,
                    4.25,
                    4.18,
                    4.22,
                    4.14,
                    4.2,
                    4.13,
                    4.14,
                    4.14,
                    4.11,
                    4.1,
                    4.09,
                    4.14,
                    4.12,
                    4.03,
                    3.94,
                    3.93,
                    3.94,
                    3.93,
                    4,
                    4.04,
                    4.04,
                    4.02,
                    4.07,
                    4.02,
                    4.06,
                    4.1,
                    4.16,
                    4.13,
                    4.15,
                    4.07,
                    4.08,
                    4.16,
                    4.2,
                    4.19,
                    4.18,
                    4.22,
                    4.26,
                    4.31,
                    4.3,
                    4.27,
                    4.32,
                    4.29,
                    4.29,
                    4.33,
                    4.37,
                    4.39,
                    4.41,
                    4.42,
                    4.4,
                    4.39,
                    4.36,
                    4.32,
                    4.38,
                    4.47,
                    4.52,
                    4.48,
                    4.51,
                    4.5,
                    4.51,
                    4.55,
                    4.59,
                    4.61,
                    4.58,
                    4.51,
                    4.6,
                    4.52,
                    4.51,
                    4.57,
                    4.53,
                    4.44,
                    4.42,
                    4.45,
                    4.42,
                    4.38,
                    4.42,
                    4.42,
                    4.38,
                    4.36,
                    4.43,
                    4.45,
                    4.48,
                    4.48,
                    4.53,
                    4.45,
                    4.47,
                    4.41,
                    4.47,
                    4.5,
                    4.47,
                    4.41,
                    4.42,
                    4.39,
                    4.4,
                    4.43,
                    4.46,
                    4.4,
                    4.34,
                    4.34,
                    4.31,
                    4.35,
                    4.33,
                    4.37,
                    4.37,
                    4.33,
                    4.31,
                    4.32,
                    4.34,
                    4.33,
                    4.39,
                    4.42,
                    4.37,
                    4.32,
                    4.32,
                    4.29,
                    4.3,
                    4.34,
                    4.32,
                    4.32,
                    4.35,
                    4.44,
                    4.48,
                    4.48,
                    4.49,
                    4.48,
                    4.53,
                    4.53,
                    4.5,
                    4.52,
                    4.54,
                    4.56,
                    4.54,
                    4.59,
                    4.57,
                    4.6,
                    4.6,
                    4.58,
                    4.54,
                    4.54,
                    4.58,
                    4.54,
                    4.58,
                    4.61,
                    4.62,
                    4.58,
                    4.6,
                    4.64,
                    4.69,
                    4.76,
                    4.73,
                    4.73,
                    4.73,
                    4.76,
                    4.77,
                    4.68,
                    4.71,
                    4.61,
                    4.64,
                    4.63,
                    4.7,
                    4.69,
                    4.73,
                    4.66,
                    4.7,
                    4.78,
                    4.8,
                    4.84,
                    4.83,
                    4.84,
                    4.84,
                    4.82,
                    4.87,
                    4.94,
                    4.92,
                    4.88,
                    4.94,
                    5.01,
                    5.01,
                    4.96,
                    4.92,
                    4.96,
                    4.98,
                    4.96,
                    4.93,
                    5.02,
                    5.06,
                    5.01,
                    4.98,
                    5.07,
                    5.04,
                    5.07,
                    5.09,
                    5.04,
                    5.05,
                    5.06,
                    5.07,
                    5.09,
                    5.14,
                    5.09,
                    5.04,
                    5.08,
                    5,
                    5.01,
                    4.99,
                    4.96,
                    4.98,
                    5.02,
                    4.99,
                    4.99,
                    5.03,
                    5.08,
                    5.06,
                    4.94,
                    4.98,
                    4.97,
                    4.99,
                    4.97,
                    4.95,
                    4.96,
                    4.94,
                    5.05,
                    5.08,
                    5.11,
                    5.12,
                    5.14,
                    5.14,
                    5.2,
                    5.21,
                    5.22,
                    5.19,
                    5.24,
                    5.17,
                    5.11,
                    5.13,
                    5.13,
                    5.2,
                    5.16,
                    5.11,
                    5.11,
                    5.09,
                    5.09,
                    5.05,
                    5.04,
                    5.05,
                    5.12,
                    5.03,
                    5,
                    5.01,
                    5.02,
                    5.04,
                    5,
                    5.01,
                    4.95,
                    4.94,
                    4.93,
                    4.92,
                    4.93,
                    4.86,
                    4.89,
                    4.88,
                    4.89,
                    4.9,
                    4.94,
                    4.97,
                    4.9,
                    4.83,
                    4.84,
                    4.81,
                    4.79,
                    4.78,
                    4.79,
                    4.78,
                    4.76,
                    4.78,
                    4.77,
                    4.74,
                    4.71,
                    4.7,
                    4.7,
                    4.75,
                    4.77,
                    4.76,
                    4.74,
                    4.77,
                    4.74,
                    4.73,
                    4.76,
                    4.77,
                    4.79,
                    4.71,
                    4.71,
                    4.61,
                    4.57,
                    4.52,
                    4.57,
                    4.58,
                    4.59,
                    4.6,
                    4.57,
                    4.58,
                    4.52,
                    4.57,
                    4.67,
                    4.67,
                    4.73,
                    4.76,
                    4.75,
                    4.78,
                    4.76,
                    4.75,
                    4.74,
                    4.76,
                    4.77,
                    4.81,
                    4.81,
                    4.75,
                    4.7,
                    4.65,
                    4.65,
                    4.58,
                    4.54,
                    4.57,
                    4.7,
                    4.68,
                    4.64,
                    4.62,
                    4.61,
                    4.58,
                    4.6,
                    4.56,
                    4.62,
                    4.67,
                    4.6,
                    4.6,
                    4.57,
                    4.56,
                    4.56,
                    4.55,
                    4.53,
                    4.5,
                    4.52,
                    4.45,
                    4.41,
                    4.4,
                    4.41,
                    4.47,
                    4.46,
                    4.53,
                    4.51,
                    4.47,
                    4.56,
                    4.58,
                    4.58,
                    4.57,
                    4.57,
                    4.58,
                    4.53,
                    4.6,
                    4.6,
                    4.59,
                    4.64,
                    4.68,
                    4.7,
                    4.7,
                    4.68,
                    4.66,
                    4.6,
                    4.64,
                    4.65,
                    4.65,
                    4.68,
                    4.73,
                    4.77,
                    4.77,
                    4.74,
                    4.78,
                    4.74,
                    4.78,
                    4.76,
                    4.81,
                    4.81,
                    4.87,
                    4.87,
                    4.89,
                    4.86,
                    4.8,
                    4.83,
                    4.82,
                    4.8,
                    4.76,
                    4.73,
                    4.73,
                    4.78,
                    4.8,
                    4.81,
                    4.73,
                    4.7,
                    4.68,
                    4.68,
                    4.67,
                    4.69,
                    4.73,
                    4.66,
                    4.62,
                    4.49,
                    4.54,
                    4.52,
                    4.46,
                    4.46,
                    4.5,
                    4.46,
                    4.48,
                    4.57,
                    4.53,
                    4.45,
                    4.49,
                    4.5,
                    4.5,
                    4.53,
                    4.51,
                    4.48,
                    4.53,
                    4.56,
                    4.55,
                    4.54,
                    4.56,
                    4.59,
                    4.59,
                    4.59,
                    4.61,
                    4.59,
                    4.62,
                    4.71,
                    4.7,
                    4.67,
                    4.69,
                    4.69,
                    4.72,
                    4.7,
                    4.64,
                    4.61,
                    4.62,
                    4.62,
                    4.59,
                    4.57,
                    4.6,
                    4.65,
                    4.64,
                    4.57,
                    4.59,
                    4.59,
                    4.63,
                    4.59,
                    4.58,
                    4.59,
                    4.62,
                    4.59,
                    4.63,
                    4.65,
                    4.66,
                    4.67,
                    4.71,
                    4.76,
                    4.75,
                    4.79,
                    4.81,
                    4.8,
                    4.83,
                    4.83,
                    4.86,
                    4.85,
                    4.87,
                    4.94,
                    4.92,
                    4.98,
                    4.95,
                    5.1,
                    5.07,
                    5.11,
                    5.25,
                    5.17,
                    5.19,
                    5.13,
                    5.09,
                    5.04,
                    5.09,
                    5.13,
                    5.07,
                    5.02,
                    5.02,
                    5.02,
                    5.05,
                    4.97,
                    4.94,
                    4.99,
                    4.99,
                    5.09,
                    5.13,
                    5.09,
                    4.97,
                    5.03,
                    5.07,
                    5.05,
                    4.99,
                    5,
                    4.98,
                    4.96,
                    4.89,
                    4.89,
                    4.85,
                    4.84,
                    4.69,
                    4.66,
                    4.72,
                    4.65,
                    4.72,
                    4.69,
                    4.58,
                    4.65,
                    4.68,
                    4.8,
                    4.67,
                    4.7,
                    4.65,
                    4.6,
                    4.59,
                    4.51,
                    4.52,
                    4.46,
                    4.42,
                    4.51,
                    4.53,
                    4.51,
                    4.45,
                    4.36,
                    4.43,
                    4.37,
                    4.39,
                    4.39,
                    4.4,
                    4.31,
                    4.36,
                    4.21,
                    4.15,
                    4.22,
                    4.26,
                    4.32,
                    4.31,
                    4.33,
                    4.31,
                    4.38,
                    4.53,
                    4.46,
                    4.47,
                    4.44,
                    4.44,
                    4.39,
                    4.41,
                    4.39,
                    4.36,
                    4.4,
                    4.35,
                    4.48,
                    4.48,
                    4.51,
                    4.51,
                    4.49,
                    4.55,
                    4.54,
                    4.49,
                    4.38,
                    4.31,
                    4.21,
                    4.25,
                    4.23,
                    4.16,
                    4.2,
                    4.23,
                    4.22,
                    4.21,
                    4.32,
                    4.18,
                    4.13,
                    4.15,
                    4.18,
                    4.09,
                    4.05,
                    3.98,
                    3.98,
                    4.05,
                    4.03,
                    3.9,
                    3.93,
                    3.81,
                    3.82,
                    3.68,
                    3.68,
                    3.7,
                    3.52,
                    3.66,
                    3.76,
                    3.67,
                    3.66,
                    3.54,
                    3.58,
                    3.64,
                    3.69,
                    3.8,
                    3.86,
                    3.64,
                    3.78,
                    3.88,
                    3.93,
                    3.83,
                    3.81,
                    3.73,
                    3.75,
                    3.87,
                    3.92,
                    3.92,
                    3.99,
                    3.91,
                    3.78,
                    3.73,
                    3.73,
                    3.59,
                    3.57,
                    3.52,
                    3.49,
                    3.43,
                    3.48,
                    3.52,
                    3.41,
                    3.4,
                    3.32,
                    3.37,
                    3.25,
                    3.24,
                    3.24,
                    3,
                    3.17,
                    3.3,
                    3.15,
                    3.19,
                    3.28,
                    3.25,
                    3.18,
                    3.17,
                    3.21,
                    3.1,
                    3.12,
                    3.29,
                    3.17,
                    3.13,
                    3.18,
                    3.23,
                    3.29,
                    3.26,
                    3.26,
                    3.42,
                    3.43,
                    3.28,
                    3.32,
                    3.43,
                    3.37,
                    3.37,
                    3.17,
                    2.99,
                    3.03,
                    3.08,
                    3.12,
                    3.03,
                    2.98,
                    2.91,
                    3.11,
                    2.96,
                    3.01,
                    2.93,
                    2.75,
                    2.97,
                    2.82,
                    2.85,
                    2.85,
                    3.09,
                    3.06,
                    2.98,
                    3.03,
                    2.97,
                    2.92,
                    3.1,
                    3.17,
                    3.15,
                    3.04,
                    3.13,
                    3.14,
                    3.04,
                    3.11,
                    3.02,
                    3.05,
                    3.15,
                    3.25,
                    3.31,
                    3.3,
                    3.33,
                    3.31,
                    3.34,
                    3.46,
                    3.52,
                    3.47,
                    3.47,
                    3.37,
                    3.42,
                    3.52,
                    3.51,
                    3.54,
                    3.46,
                    3.37,
                    3.37,
                    3.4,
                    3.54,
                    3.55,
                    3.45,
                    3.48,
                    3.46,
                    3.39,
                    3.44,
                    3.56,
                    3.49,
                    3.49,
                    3.57,
                    3.67,
                    3.75,
                    3.74,
                    3.61,
                    3.54,
                    3.62,
                    3.68,
                    3.54,
                    3.69,
                    3.83,
                    3.77,
                    3.95,
                    3.99,
                    4.01,
                    3.91,
                    3.84,
                    3.93,
                    3.88,
                    3.9,
                    3.8,
                    3.81,
                    3.71,
                    3.66,
                    3.65,
                    3.68,
                    3.63,
                    3.63,
                    3.63,
                    3.54,
                    3.53,
                    3.45,
                    3.44,
                    3.62,
                    3.51,
                    3.45,
                    3.56,
                    3.63,
                    3.75,
                    3.7,
                    3.78,
                    3.8,
                    3.66,
                    3.77,
                    3.66,
                    3.7,
                    3.7,
                    3.59,
                    3.57,
                    3.6,
                    3.65,
                    3.69,
                    3.53,
                    3.56,
                    3.62,
                    3.52,
                    3.56,
                    3.52,
                    3.47,
                    3.44,
                    3.44,
                    3.41,
                    3.44,
                    3.5,
                    3.41,
                    3.41,
                    3.39,
                    3.41,
                    3.45,
                    3.45,
                    3.36,
                    3.32,
                    3.24,
                    3.34,
                    3.32,
                    3.21,
                    3.26,
                    3.28,
                    3.33,
                    2.96,
                    3.01,
                    2.97,
                    3.09,
                    3.42,
                    3.44,
                    3.39,
                    3.36,
                    3.45,
                    3.45,
                    3.13,
                    3.4,
                    3.3,
                    3.15,
                    3.12,
                    2.95,
                    2.98,
                    3.14,
                    3.23,
                    3.31,
                    3.37,
                    3.54,
                    3.38,
                    3.4,
                    3.38,
                    3.31,
                    3.2,
                    3.06,
                    3.15,
                    3.13,
                    3.15,
                    3.28,
                    3.28,
                    3.38,
                    3.39,
                    3.31,
                    3.13,
                    3.1,
                    3.07,
                    3.18,
                    3.12,
                    3.12,
                    3,
                    3.14,
                    3.03,
                    2.96,
                    2.86,
                    2.67,
                    2.45,
                    2.61,
                    2.76,
                    2.57,
                    2.49,
                    2.5,
                    2.42,
                    2.22,
                    2.15,
                    2.13,
                    2.03,
                    2.2,
                    2.23,
                    2.12,
                    2.15,
                    2.07,
                    2.04,
                    2,
                    1.77,
                    1.78,
                    1.67,
                    1.74,
                    1.8,
                    1.81,
                    1.86,
                    1.86,
                    1.82,
                    1.78,
                    1.75,
                    1.88,
                    1.88,
                    2.01,
                    2.08,
                    2.05,
                    2.08,
                    2.01,
                    1.95,
                    1.87,
                    1.87,
                    1.78,
                    1.78,
                    1.89,
                    1.89,
                    1.93,
                    2.07,
                    2.1,
                    2.12,
                    2.15,
                    2.05,
                    2.18,
                    2.34,
                    2.36,
                    2.24,
                    2.39,
                    2.44,
                    2.4,
                    2.48,
                    2.48,
                    2.28,
                    2.25,
                    2.27,
                    2.38,
                    2.38,
                    2.15,
                    2.28,
                    2.36,
                    2.31,
                    2.28,
                    2.34,
                    2.46,
                    2.53,
                    2.5,
                    2.34,
                    2.35,
                    2.46,
                    2.3,
                    2.38,
                    2.37,
                    2.5,
                    2.42,
                    2.38,
                    2.38,
                    2.44,
                    2.49,
                    2.05,
                    2.12,
                    2.14,
                    2.17,
                    2.21,
                    2.3,
                    2.26,
                    2.28,
                    2.22,
                    2.16,
                    2.15,
                    2.26,
                    2.37,
                    2.4,
                    2.37,
                    2.34,
                    2.4,
                    2.4,
                    2.33,
                    2.25,
                    2.23,
                    2.3,
                    2.42,
                    2.32,
                    2.38,
                    2.42,
                    2.4,
                    2.46,
                    2.38,
                    2.47,
                    2.57,
                    2.56,
                    2.58,
                    2.59,
                    2.6,
                    2.61,
                    2.75,
                    2.71,
                    2.59,
                    2.59,
                    2.55,
                    2.52,
                    2.57,
                    2.66,
                    2.67,
                    2.61,
                    2.75,
                    2.83,
                    2.83,
                    2.92,
                    3.09,
                    3.02,
                    2.9,
                    3.1,
                    3.05,
                    2.98,
                    3.15,
                    3.33,
                    3.4,
                    3.36,
                    3.43,
                    3.35,
                    3.29,
                    3.22,
                    3.17,
                    3.18,
                    3.33,
                    3.29,
                    3.19,
                    3.15,
                    3.2,
                    3.06,
                    3.05,
                    3,
                    3.04,
                    3.02,
                    2.96,
                    2.96,
                    2.95,
                    2.9,
                    2.77,
                    2.86,
                    2.76,
                    2.8,
                    2.91,
                    3.06,
                    3.01,
                    3.07,
                    3.03,
                    2.91,
                    2.97,
                    3.09,
                    3.09,
                    3.15,
                    3.14,
                    3.14,
                    3.12,
                    3,
                    3.15,
                    3.19,
                    3.23,
                    3.23,
                    3.33,
                    3.26,
                    3.17,
                    3.2,
                    3.07,
                    3.04,
                    2.94,
                    2.98,
                    2.93,
                    2.92,
                    3.06,
                    2.98,
                    2.94,
                    2.93,
                    2.96,
                    2.95,
                    2.89,
                    2.84,
                    2.79,
                    2.82,
                    2.89,
                    2.9,
                    2.94,
                    2.92,
                    2.81,
                    2.82,
                    2.89,
                    2.92,
                    2.95,
                    2.87,
                    2.96,
                    2.97,
                    2.93,
                    2.89,
                    2.87,
                    2.84,
                    2.81,
                    2.81,
                    2.81,
                    2.68,
                    2.71,
                    2.71,
                    2.75,
                    2.68,
                    2.73,
                    2.86,
                    2.86,
                    2.82,
                    2.87,
                    2.92,
                    2.88,
                    2.87,
                    2.81,
                    2.86,
                    2.89,
                    2.97,
                    3.02,
                    2.91,
                    2.87,
                    2.97,
                    2.85,
                    2.87,
                    2.91,
                    2.95,
                    2.93,
                    2.9,
                    2.89,
                    2.88,
                    2.89,
                    2.85,
                    2.84,
                    2.76,
                    2.74,
                    2.78,
                    2.74,
                    2.77,
                    2.76,
                    2.7,
                    2.69,
                    2.69,
                    2.62,
                    2.6,
                    2.66,
                    2.7,
                    2.75,
                    2.86,
                    2.8,
                    2.74,
                    2.79,
                    2.84,
                    2.9,
                    2.92,
                    2.96,
                    2.97,
                    2.85,
                    2.9,
                    3.03,
                    3.11,
                    3.11,
                    3.17,
                    3.17,
                    3.22,
                    3.18,
                    3.2,
                    3.26,
                    3.26,
                    3.23,
                    3.16,
                    3.21,
                    3.22,
                    3.21,
                    3.19,
                    3.09,
                    3.16,
                    3.12,
                    3.05,
                    3.05,
                    3.07,
                    3.03,
                    2.96,
                    2.97,
                    2.99,
                    2.98,
                    3.02,
                    3.01,
                    2.95,
                    3.01,
                    3,
                    3.05,
                    2.95,
                    2.9,
                    2.89,
                    2.98,
                    3.03,
                    3.04,
                    3.01,
                    3.01,
                    2.98,
                    3.06,
                    3.14,
                    3.11,
                    3.12,
                    3.01,
                    3.02,
                    2.98,
                    2.96,
                    2.94,
                    2.93,
                    2.94,
                    2.94,
                    3.01,
                    3.04,
                    3.02,
                    3.05,
                    3.07,
                    3.05,
                    3.05,
                    3,
                    3,
                    3.05,
                    3.07,
                    3.03,
                    3.05,
                    3.22,
                    3.25,
                    3.22,
                    3.23,
                    3.23,
                    3.18,
                    3.23,
                    3.31,
                    3.36,
                    3.32,
                    3.23,
                    3.26,
                    3.25,
                    3.21,
                    3.2,
                    3.23,
                    3.19,
                    3.11,
                    3.16,
                    3.17,
                    3.11,
                    3.15,
                    3.2,
                    3.18,
                    3.05,
                    3.13,
                    3.1,
                    3.03,
                    3.07,
                    2.98,
                    2.91,
                    2.77,
                    2.79,
                    2.9,
                    2.88,
                    2.93,
                    2.88,
                    2.8,
                    2.84,
                    2.7,
                    2.75,
                    2.6,
                    2.63,
                    2.59,
                    2.56,
                    2.6,
                    2.78,
                    2.69,
                    2.69,
                    2.66,
                    2.74,
                    2.76,
                    2.59,
                    2.54,
                    2.58,
                    2.57,
                    2.72,
                    2.63,
                    2.64,
                    2.69,
                    2.65,
                    2.59,
                    2.62,
                    2.63,
                    2.57,
                    2.52,
                    2.54,
                    2.5,
                    2.42,
                    2.36,
                    2.35,
                    2.37,
                    2.4,
                    2.39,
                    2.34,
                    2.38,
                    2.42,
                    2.44,
                    2.45,
                    2.51,
                    2.42,
                    2.37,
                    2.29,
                    2.33,
                    2.32,
                    2.26,
                    2.3,
                    2.36,
                    2.36,
                    2.42,
                    2.34,
                    2.32,
                    2.25,
                    2.3,
                    2.23,
                    2.28,
                    2.23,
                    2.16,
                    2.18,
                    2.1,
                    2.06,
                    2.11,
                    2.06,
                    1.97,
                    2.03,
                    2.03,
                    1.99,
                    2.03,
                    2,
                    1.9,
                    1.95,
                    1.92,
                    2.07,
                    1.96,
                    1.9,
                    1.98,
                    2.02,
                    2.09,
                    2.09,
                    2,
                    2.05,
                    2.16,
                    2.19,
                    2.13,
                    2.06,
                    2.08,
                    2.11,
                    2.09,
                    2.06,
                    1.94,
                    1.94,
                    1.93,
                    1.98,
                    1.91,
                    1.85,
                    1.89,
                    1.89,
                    1.88,
                    1.85,
                    1.83,
                    1.78,
                    1.76,
                    1.75,
                    1.75,
                    1.78,
                    1.77,
                    1.84,
                    1.87,
                    1.82,
                    1.79,
                    1.79,
                    1.84,
                    1.85,
                    1.87,
                    1.94,
                    2.02,
                    1.94,
                    1.88,
                    1.89,
                    1.87,
                    1.84,
                    1.76,
                    1.81,
                    1.83,
                    1.95,
                    1.91,
                    1.93,
                    2.08,
                    2.24,
                    2.15,
                    2.17,
                    2.2,
                    2.19,
                    2.11,
                    2.08,
                    2.24,
                    2.24,
                    2.2,
                    2.16,
                    2.13,
                    2.3,
                    2.33,
                    2.31,
                    2.22,
                    2.42,
                    2.58,
                    2.55,
                    2.65,
                    2.58,
                    2.77,
                    2.83,
                    2.72,
                    2.64,
                    2.64,
                    2.62,
                    2.67,
                    2.72,
                    2.72,
                    2.68,
                    2.81,
                    2.69,
                    2.71,
                    2.65,
                    2.67,
                    2.67,
                    2.8,
                    2.73,
                    2.64,
                    2.6,
                    2.65,
                    2.67,
                    2.6,
                    2.62,
                    2.63,
                    2.66,
                    2.64,
                    2.75,
                    2.71,
                    2.71,
                    2.63,
                    2.7,
                    2.68,
                    2.62,
                    2.66,
                    2.73,
                    2.78,
                    2.86,
                    2.95,
                    2.95,
                    3.07,
                    2.99,
                    3.04,
                    2.99,
                    2.99,
                    2.96,
                    2.98,
                    2.92,
                    2.92,
                    2.93,
                    2.79,
                    2.83,
                    2.82,
                    2.79,
                    2.78,
                    2.74,
                    2.82,
                    2.92,
                    2.84,
                    2.85,
                    2.88,
                    2.81,
                    2.69,
                    2.73,
                    2.67,
                    2.63,
                    2.5,
                    2.58,
                    2.6,
                    2.68,
                    2.68,
                    2.7,
                    2.76,
                    2.8,
                    2.8,
                    2.86,
                    2.82,
                    2.87,
                    2.84,
                    2.8,
                    2.87,
                    2.93,
                    2.91,
                    2.94,
                    2.95,
                    2.85,
                    2.81,
                    2.86,
                    2.76,
                    2.72,
                    2.71,
                    2.76,
                    2.75,
                    2.75,
                    2.71,
                    2.66,
                    2.69,
                    2.65,
                    2.63,
                    2.62,
                    2.6,
                    2.58,
                    2.51,
                    2.5,
                    2.5,
                    2.56,
                    2.51,
                    2.55,
                    2.5,
                    2.47,
                    2.45,
                    2.51,
                    2.5,
                    2.47,
                    2.45,
                    2.44,
                    2.45,
                    2.39,
                    2.4,
                    2.4,
                    2.38,
                    2.27,
                    2.34,
                    2.29,
                    2.29,
                    2.28,
                    2.22,
                    2.29,
                    2.26,
                    2.28,
                    2.39,
                    2.26,
                    2.23,
                    2.24,
                    2.25,
                    2.26,
                    2.26,
                    2.18,
                    2.12,
                    2.19,
                    2.31,
                    2.4,
                    2.46,
                    2.48,
                    2.48,
                    2.4,
                    2.38,
                    2.43,
                    2.3,
                    2.19,
                    2.16,
                    2.16,
                    2.23,
                    2.17,
                    2.18,
                    2.16,
                    2.2,
                    2.28,
                    2.23,
                    2.26,
                    2.22,
                    2.25,
                    2.23,
                    2.08,
                    2.03,
                    1.91,
                    1.94,
                    1.75,
                    1.9,
                    1.7,
                    1.62,
                    1.51,
                    1.68,
                    1.61,
                    1.65,
                    1.57,
                    1.54,
                    1.47,
                    1.48,
                    1.51,
                    1.55,
                    1.66,
                    1.61,
                    1.56,
                    1.62,
                    1.55,
                    1.59,
                    1.51,
                    1.42,
                    1.42,
                    1.43,
                    1.48,
                    1.42,
                    1.36,
                    1.41,
                    1.44,
                    1.43,
                    1.51,
                    1.48,
                    1.4,
                    1.39,
                    1.35,
                    1.25,
                    1.35,
                    1.4,
                    1.46,
                    1.46,
                    1.5,
                    1.43,
                    1.3,
                    1.36,
                    1.42,
                    1.49,
                    1.58,
                    1.58,
                    1.64,
                    1.68,
                    1.64,
                    1.68,
                    1.61,
                    1.62,
                    1.6,
                    1.63,
                    1.64,
                    1.66,
                    1.55,
                    1.63,
                    1.8,
                    1.72,
                    1.54,
                    1.45,
                    1.43,
                    1.5,
                    1.45,
                    1.47,
                    1.5,
                    1.41,
                    1.48,
                    1.48,
                    1.48,
                    1.47,
                    1.44,
                    1.41,
                    1.47,
                    1.43,
                    1.39,
                    1.38,
                    1.38,
                    1.45,
                    1.45,
                    1.46,
                    1.51,
                    1.53,
                    1.47,
                    1.49,
                    1.52,
                    1.46,
                    1.4,
                    1.48,
                    1.44,
                    1.41,
                    1.38,
                    1.38,
                    1.32,
                    1.31,
                    1.39,
                    1.44,
                    1.43,
                    1.5,
                    1.5,
                    1.49,
                    1.41,
                    1.39,
                    1.35,
                    1.35,
                    1.41,
                    1.43,
                    1.44,
                    1.41,
                    1.4,
                    1.41,
                    1.36,
                    1.38,
                    1.33,
                    1.33,
                    1.32,
                    1.35,
                    1.42,
                    1.46,
                    1.48,
                    1.48,
                    1.39,
                    1.35,
                    1.32,
                    1.29,
                    1.25,
                    1.27,
                    1.26,
                    1.35,
                    1.33,
                    1.39,
                    1.41,
                    1.44,
                    1.4,
                    1.4,
                    1.38,
                    1.36,
                    1.42,
                    1.43,
                    1.43,
                    1.48,
                    1.43,
                    1.44,
                    1.43,
                    1.39,
                    1.39,
                    1.41,
                    1.46,
                    1.41,
                    1.44,
                    1.38,
                    1.41,
                    1.45,
                    1.46,
                    1.47,
                    1.55,
                    1.68,
                    1.68,
                    1.7,
                    1.79,
                    1.78,
                    1.72,
                    1.7,
                    1.66,
                    1.66,
                    1.6,
                    1.62,
                    1.59,
                    1.62,
                    1.6,
                    1.71,
                    1.63,
                    1.59,
                    1.48,
                    1.48,
                    1.42,
                    1.46,
                    1.47,
                    1.42,
                    1.41,
                    1.43,
                    1.41,
                    1.4,
                    1.4,
                    1.38,
                    1.41,
                    1.41,
                    1.38,
                    1.38,
                    1.36,
                    1.39,
                    1.37,
                    1.38,
                    1.33,
                    1.32,
                    1.3,
                    1.29,
                    1.31,
                    1.29,
                    1.24,
                    1.25,
                    1.25,
                    1.21,
                    1.24,
                    1.24,
                    1.26,
                    1.23,
                    1.28,
                    1.25,
                    1.25,
                    1.26,
                    1.16,
                    1.11,
                    1.04,
                    1.1,
                    1.13,
                    1.19,
                    1.17,
                    1.17,
                    1.14,
                    1.2,
                    1.15,
                    1.19,
                    1.12,
                    1.13,
                    1.16,
                    1.2,
                    1.17,
                    1.21,
                    1.15,
                    1.17,
                    1.17,
                    1.13,
                    1.18,
                    1.13,
                    1.16,
                    1.16,
                    1.13,
                    1.1,
                    1.07,
                    1.06,
                    1.08,
                    1.05,
                    1.05,
                    1.04,
                    1.07,
                    1.05,
                    1.06,
                    1.02,
                    0.99,
                    0.97,
                    0.97,
                    1.01,
                    1.1,
                    1.06,
                    1.02,
                    1.08,
                    1.04,
                    1.11,
                    1.11,
                    1.17,
                    1.19,
                    1.21,
                    1.18,
                    1.19,
                    1.24,
                    1.31,
                    1.33,
                    1.3,
                    1.3,
                    1.29,
                    1.19,
                    1.19,
                    1.2,
                    1.17,
                    1.15,
                    1.16,
                    1.14,
                    1.07,
                    1.07,
                    1.09,
                    1.11,
                    1.18,
                    1.16,
                    1.15,
                    1.18,
                    1.22,
                    1.18,
                    1.29,
                    1.28,
                    1.25,
                    1.23,
                    1.23,
                    1.21,
                    1.18,
                    1.15,
                    1.11,
                    1.15,
                    1.13,
                    1.12,
                    1.12,
                    1.11,
                    1.15,
                    1.21,
                    1.21,
                    1.19,
                    1.16,
                    1.16,
                    1.16,
                    1.16,
                    1.2,
                    1.3,
                    1.31,
                    1.26,
                    1.3,
                    1.26,
                    1.27,
                    1.32,
                    1.25,
                    1.23,
                    1.23,
                    1.21,
                    1.23,
                    1.22,
                    1.19,
                    1.25,
                    1.16,
                    1.13,
                    1.12,
                    1.12,
                    1.11,
                    1.11,
                    1.11,
                    1.09,
                    1.12,
                    1.16,
                    1.18,
                    1.18,
                    1.19,
                    1.16,
                    1.14,
                    1.13,
                    1.12,
                    1.12,
                    1.12,
                    1.11,
                    1.1,
                    1.09,
                    1.12,
                    1.12,
                    1.14,
                    1.18,
                    1.21,
                    1.19,
                    1.25,
                    1.29,
                    1.29,
                    1.29,
                    1.26,
                    1.27,
                    1.27,
                    1.26,
                    1.24,
                    1.2,
                    1.24,
                    1.24,
                    1.3,
                    1.37,
                    1.35,
                    1.35,
                    1.33,
                    1.31,
                    1.34,
                    1.32,
                    1.3,
                    1.29,
                    1.28,
                    1.33,
                    1.3,
                    1.3,
                    1.3,
                    1.28,
                    1.31,
                    1.4,
                    1.41,
                    1.44,
                    1.43,
                    1.43,
                    1.45,
                    1.39,
                    1.43,
                    1.4,
                    1.39,
                    1.39,
                    1.41,
                    1.42,
                    1.47,
                    1.42,
                    1.43,
                    1.43,
                    1.46,
                    1.43,
                    1.41,
                    1.39,
                    1.31,
                    1.32,
                    1.34,
                    1.32,
                    1.29,
                    1.32,
                    1.34,
                    1.37,
                    1.43,
                    1.46,
                    1.48,
                    1.44,
                    1.45,
                    1.45,
                    1.41,
                    1.38,
                    1.34,
                    1.38,
                    1.35,
                    1.36,
                    1.35,
                    1.34,
                    1.29,
                    1.31,
                    1.31,
                    1.29,
                    1.31,
                    1.27,
                    1.23,
                    1.2,
                    1.23,
                    1.22,
                    1.27,
                    1.26,
                    1.2,
                    1.18,
                    1.21,
                    1.19,
                    1.19,
                    1.21,
                    1.19,
                    1.2,
                    1.2,
                    1.21,
                    1.17,
                    1.17,
                    1.17,
                    1.14,
                    1.14,
                    1.23,
                    1.25,
                    1.26,
                    1.25,
                    1.28,
                    1.36,
                    1.37,
                    1.42,
                    1.38,
                    1.34,
                    1.39,
                    1.4,
                    1.37,
                    1.47,
                    1.45,
                    1.45,
                    1.45,
                    1.59,
                    1.55,
                    1.56,
                    1.57,
                    1.57,
                    1.6,
                    1.55,
                    1.54,
                    1.63,
                    1.67,
                    1.65,
                    1.69,
                    1.6,
                    1.58,
                    1.62,
                    1.62,
                    1.8,
                    1.86,
                    1.98,
                    1.99,
                    2.05,
                    1.98,
                    1.93,
                    1.94,
                    1.93,
                    1.92,
                    1.96,
                    1.96,
                    2.18,
                    2.07,
                    2.06,
                    2.04,
                    1.98,
                    2,
                    1.96,
                    1.95,
                    1.9,
                    1.93,
                    1.89,
                    1.89,
                    1.91,
                    1.98,
                    1.97,
                    1.97,
                    2,
                    2,
                    1.98,
                    2.1,
                    1.98,
                    2.01,
                    2.02,
                    1.98,
                    1.97,
                    1.97,
                    2,
                    2.1,
                    2.1,
                    2.14,
                    2.19,
                    2.24,
                    2.18,
                    2.27,
                    2.28,
                    2.22,
                    2.19,
                    2.11,
                    2.17,
                    2.19,
                    2.21,
                    2.21,
                    2.27,
                    2.32,
                    2.42,
                    2.35,
                    2.31,
                    2.37,
                    2.31,
                    2.31,
                    2.29,
                    2.24,
                    2.23,
                    2.06,
                    2.12,
                    2.11,
                    2.07,
                    2.04,
                    2.01,
                    2.04,
                    2.01,
                    2,
                    2.04,
                    2,
                    1.98,
                    2.03,
                    2.01,
                    2.03,
                    2.04,
                    2.05,
                    2.05,
                    2.05,
                    2.08,
                    2.03,
                    1.96,
                    1.95,
                    1.98,
                    1.9,
                    1.89,
                    1.91,
                    1.9,
                    1.91,
                    1.88,
                    1.93,
                    1.94,
                    2,
                    1.98,
                    2.03,
                    1.99,
                    1.95,
                    2.08,
                    2.08,
                    2.11,
                    2.03,
                    2.01,
                    2.02,
                    1.99,
                    2.03,
                    2.09,
                    2.07,
                    2.04,
                    2.03,
                    2.01,
                    2.05,
                    2.05,
                    2.06,
                    2.1,
                    2.09,
                    2.14,
                    2.18,
                    2.17,
                    2.16,
                    2.13,
                    2.18,
                    2.21,
                    2.2,
                    2.21,
                    2.17,
                    2.22,
                    2.28,
                    2.28,
                    2.31,
                    2.36,
                    2.36,
                    2.37,
                    2.37,
                    2.34,
                    2.38,
                    2.38,
                    2.35,
                    2.36,
                    2.32,
                    2.31,
                    2.38,
                    2.36,
                    2.24,
                    2.21,
                    2.26,
                    2.28,
                    2.24,
                    2.22,
                    2.22,
                    2.24,
                    2.28,
                    2.19,
                    2.13,
                    2.16,
                    2.15,
                    2.09,
                    2.1,
                    2.07,
                    2.01,
                    2.05,
                    2.08,
                    2.11,
                    2.08,
                    2.07,
                    2.13,
                    2.16,
                    2.12,
                    2.13,
                    2.13,
                    2.09,
                    2.13,
                    2.14,
                    2.13,
                    2.14,
                    2.11,
                    2.07,
                    2.06,
                    2.07,
                    2.03,
                    2.12,
                    2.12,
                    2.15,
                    2.21,
                    2.2,
                    2.19,
                    2.16,
                    2.08,
                    2.09,
                    2.13,
                    2.11,
                    2.24,
                    2.24,
                    2.22,
                    2.23,
                    2.24,
                    2.18,
                    2.2,
                    2.23,
                    2.22,
                    2.24,
                    2.3,
                    2.3,
                    2.21,
                    2.19,
                    2.17,
                    2.16,
                    2.12,
                    2.1,
                    2.13,
                    2.12,
                    2.14,
                    2.23,
                    2.23,
                    2.22,
                    2.22,
                    2.21,
                    2.21,
                    2.19,
                    2.22,
                    2.21,
                    2.16,
                    2.14,
                    2.12,
                    2.14,
                    2.13,
                    2.12,
                    2.12,
                    2.13,
                    2.16,
                    2.11,
                    2.05,
                    2.01,
                    2.04,
                    2.05,
                    2.01,
                    2.03,
                    2.05,
                    2.03,
                    2.03,
                    2.02,
                    1.96,
                    2,
                    2.01,
                    2.06,
                    2.12,
                    2.12,
                    2.1,
                    2.11,
                    2.14,
                    2.18,
                    2.17,
                    2.14,
                    2.15,
                    2.15,
                    2.2,
                    2.13,
                    2.15,
                    2.14,
                    2.16,
                    2.12,
                    2.11,
                    2.09,
                    2.09,
                    2.08,
                    2.11,
                    2.17,
                    2.19,
                    2.19,
                    2.17,
                    2.12,
                    2.11,
                    2.09,
                    2.08,
                    2.11,
                    2.12,
                    2.11,
                    2.03,
                    2.07,
                    2.07,
                    2.06,
                    2.06,
                    2.1,
                    2.07,
                    2.09,
                    2.07,
                    2.16,
                    2.16,
                    2.08,
                    2.06,
                    2.07,
                    2.06,
                    2,
                    2.02,
                    2.02,
                    2.03,
                    2,
                    1.99,
                    1.94,
                    1.98,
                    1.99,
                    2.03,
                    2.02,
                    2.03,
                    2.02,
                    2.03,
                    2,
                    1.98,
                    1.98,
                    1.98,
                    2.05,
                    2.03,
                    2.08,
                    2.08,
                    2.09,
                    2.13,
                    2.16,
                    2.17,
                    2.21,
                    2.19,
                    2.18,
                    2.23,
                    2.22,
                    2.19,
                    2.17,
                    2.14,
                    2.18,
                    2.13,
                    2.16,
                    2.12,
                    2.12,
                    2.03,
                    2.05,
                    2.08,
                    2.06,
                    1.98,
                    1.94,
                    1.94,
                    1.91,
                    1.91,
                    1.82,
                    1.74,
                    1.77,
                    1.8,
                    1.8,
                    1.82,
                    1.82,
                    1.88,
                    1.88,
                    1.87,
                    1.91,
                    1.95,
                    1.94,
                    1.97,
                    1.99,
                    1.98,
                    1.99,
                    2.03,
                    1.94,
                    2,
                    2,
                    2.01,
                    1.98,
                    1.96,
                    1.98,
                    1.96,
                    2,
                    1.98,
                    1.96,
                    1.95,
                    1.91,
                    1.9,
                    1.9,
                    1.82,
                    1.89,
                    1.94,
                    1.94,
                    1.9,
                    2,
                    1.96,
                    1.92,
                    1.86,
                    1.88,
                    1.8,
                    1.84,
                    1.79,
                    1.87,
                    1.93,
                    1.9,
                    1.91,
                    2,
                    2.01,
                    2.01,
                    2.01,
                    1.95,
                    1.93,
                    1.91,
                    1.91,
                    1.86,
                    1.8,
                    1.71,
                    1.72,
                    1.75,
                    1.68,
                    1.64,
                    1.63,
                    1.59,
                    1.44,
                    1.57,
                    1.57,
                    1.53,
                    1.61,
                    1.61,
                    1.55,
                    1.58,
                    1.58,
                    1.48,
                    1.51,
                    1.4,
                    1.42,
                    1.54,
                    1.5,
                    1.56,
                    1.72,
                    1.74,
                    1.75,
                    1.78,
                    1.74,
                    1.79,
                    1.79,
                    1.87,
                    1.8,
                    1.85,
                    1.85,
                    1.8,
                    1.72,
                    1.71,
                    1.78,
                    1.75,
                    1.83,
                    1.86,
                    1.85,
                    1.84,
                    1.97,
                    1.92,
                    1.87,
                    1.86,
                    1.86,
                    1.85,
                    1.81,
                    1.8,
                    1.65,
                    1.72,
                    1.67,
                    1.65,
                    1.62,
                    1.67,
                    1.72,
                    1.7,
                    1.68,
                    1.65,
                    1.59,
                    1.63,
                    1.55,
                    1.6,
                    1.6,
                    1.63,
                    1.68,
                    1.67,
                    1.65,
                    1.62,
                    1.6,
                    1.59,
                    1.59,
                    1.6,
                    1.62,
                    1.69,
                    1.66,
                    1.61,
                    1.63,
                    1.7,
                    1.73,
                    1.73,
                    1.81,
                    1.82,
                    1.87,
                    1.91,
                    1.87,
                    1.82,
                    1.94,
                    1.91,
                    1.93,
                    1.87,
                    1.8,
                    1.88,
                    1.95,
                    1.9,
                    1.85,
                    1.89,
                    1.89,
                    1.83,
                    1.83,
                    1.83,
                    1.8,
                    1.86,
                    1.93,
                    2.03,
                    1.97,
                    2.07,
                    2.04,
                    2.1,
                    2.13,
                    2.05,
                    2.07,
                    2.03,
                    1.98,
                    1.97,
                    1.98,
                    1.91,
                    2.02,
                    2.05,
                    2.02,
                    2.06,
                    2.11,
                    1.97,
                    2,
                    2.06,
                    2.01,
                    2.01,
                    1.92,
                    1.9,
                    1.84,
                    1.95,
                    2.03,
                    2.09,
                    2.03,
                    1.99,
                    2,
                    2.01,
                    2.04,
                    1.99,
                    2,
                    1.95,
                    1.94,
                    1.89,
                    1.92,
                    1.95,
                    1.94,
                    1.85,
                    1.83,
                    1.91,
                    1.96,
                    1.92,
                    1.87,
                    1.92,
                    1.83,
                    1.83,
                    1.88,
                    1.9,
                    1.87,
                    1.89,
                    1.81,
                    1.77,
                    1.73,
                    1.69,
                    1.74,
                    1.83,
                    1.84,
                    1.85,
                    1.88,
                    1.82,
                    1.85,
                    1.82,
                    1.8,
                    1.8,
                    1.85,
                    1.87,
                    1.88,
                    1.85,
                    1.85,
                    1.95,
                    1.95,
                    1.83,
                    1.79,
                    1.85,
                    1.78,
                    1.8,
                    1.79,
                    1.82,
                    1.76,
                    1.71,
                    1.7,
                    1.7,
                    1.64,
                    1.7,
                    1.68,
                    1.72,
                    1.75,
                    1.74,
                    1.74,
                    1.7,
                    1.62,
                    1.68,
                    1.69,
                    1.68,
                    1.73,
                    1.69,
                    1.69,
                    1.75,
                    1.73,
                    1.7,
                    1.78,
                    1.85,
                    1.83,
                    1.86,
                    1.9,
                    1.93,
                    1.93,
                    2.03,
                    2.04,
                    2.03,
                    2.03,
                    2.01,
                    1.96,
                    1.96,
                    1.96,
                    1.98,
                    1.96,
                    1.97,
                    1.95,
                    1.95,
                    1.95,
                    1.95,
                    1.93,
                    1.93,
                    1.86,
                    1.91,
                    2.02,
                    1.99,
                    1.95,
                    1.94,
                    1.93,
                    1.95,
                    1.84,
                    1.94,
                    1.98,
                    2.02,
                    1.96,
                    1.94,
                    1.93,
                    1.97,
                    1.99,
                    1.98,
                    1.98,
                    1.97,
                    2.04,
                    2.04,
                    2.01,
                    2.01,
                    1.99,
                    1.97,
                    1.91,
                    1.87,
                    1.84,
                    1.88,
                    1.82,
                    1.81,
                    1.8,
                    1.74,
                    1.74,
                    1.77,
                    1.7,
                    1.74,
                    1.77,
                    1.72,
                    1.71,
                    1.7,
                    1.69,
                    1.62,
                    1.66,
                    1.55,
                    1.58,
                    1.53,
                    1.54,
                    1.45,
                    1.44,
                    1.39,
                    1.4,
                    1.48,
                    1.48,
                    1.49,
                    1.54,
                    1.47,
                    1.48,
                    1.49,
                    1.46,
                    1.48,
                    1.44,
                    1.5,
                    1.47,
                    1.57,
                    1.59,
                    1.59,
                    1.62,
                    1.66,
                    1.58,
                    1.63,
                    1.69,
                    1.74,
                    1.72,
                    1.73,
                    1.64,
                    1.63,
                    1.6,
                    1.65,
                    1.68,
                    1.62,
                    1.64,
                    1.64,
                    1.62,
                    1.54,
                    1.54,
                    1.49,
                    1.49,
                    1.48,
                    1.45,
                    1.48,
                    1.41,
                    1.43,
                    1.44,
                    1.49,
                    1.49,
                    1.52,
                    1.48,
                    1.5,
                    1.52,
                    1.58,
                    1.6,
                    1.62,
                    1.64,
                    1.66,
                    1.58,
                    1.56,
                    1.56,
                    1.6,
                    1.52,
                    1.5,
                    1.47,
                    1.5,
                    1.47,
                    1.49,
                    1.47,
                    1.49,
                    1.45,
                    1.51,
                    1.54,
                    1.62,
                    1.61,
                    1.6,
                    1.6,
                    1.63,
                    1.63,
                    1.59,
                    1.62,
                    1.62,
                    1.61,
                    1.61,
                    1.58,
                    1.47,
                    1.49,
                    1.47,
                    1.47,
                    1.45,
                    1.4,
                    1.37,
                    1.38,
                    1.32,
                    1.33,
                    1.36,
                    1.44,
                    1.45,
                    1.44,
                    1.5,
                    1.32,
                    1.21,
                    1.24,
                    1.29,
                    1.23,
                    1.22,
                    1.22,
                    1.16,
                    1.16,
                    1.17,
                    1.15,
                    1.23,
                    1.3,
                    1.27,
                    1.32,
                    1.33,
                    1.36,
                    1.33,
                    1.36,
                    1.33,
                    1.34,
                    1.36,
                    1.35,
                    1.29,
                    1.29,
                    1.24,
                    1.3,
                    1.32,
                    1.31,
                    1.26,
                    1.36,
                    1.37,
                    1.33,
                    1.29,
                    1.35,
                    1.31,
                    1.35,
                    1.37,
                    1.34,
                    1.32,
                    1.37,
                    1.34,
                    1.34,
                    1.35,
                    1.37,
                    1.43,
                    1.36,
                    1.37,
                    1.39,
                    1.37,
                    1.4,
                    1.4,
                    1.33,
                    1.33,
                    1.39,
                    1.45,
                    1.43,
                    1.49,
                    1.45,
                    1.43,
                    1.45,
                    1.47,
                    1.44,
                    1.42,
                    1.39,
                    1.39,
                    1.35,
                    1.33,
                    1.35,
                    1.34,
                    1.37,
                    1.4,
                    1.46,
                    1.47,
                    1.51,
                    1.49,
                    1.49,
                    1.53,
                    1.53,
                    1.5,
                    1.54,
                    1.51,
                    1.48,
                    1.48,
                    1.5,
                    1.49,
                    1.52,
                    1.52,
                    1.55,
                    1.6,
                    1.59,
                    1.57,
                    1.56,
                    1.53,
                    1.54,
                    1.5,
                    1.56,
                    1.59,
                    1.76,
                    1.85,
                    1.85,
                    1.97,
                    1.94,
                    1.95,
                    2.02,
                    2.08,
                    2.04,
                    2.04,
                    2.09,
                    2.09,
                    2.1,
                    2.05,
                    2.03,
                    2.11,
                    2.17,
                    2.1,
                    2.12,
                    2.11,
                    2.06,
                    2.12,
                    2.18,
                    2.18,
                    2.19,
                    2.31,
                    2.34,
                    2.33,
                    2.28,
                    2.3,
                    2.28,
                    2.29,
                    2.28,
                    2.28,
                    2.31,
                    2.25,
                    2.21,
                    2.19,
                    2.19,
                    2.19,
                    2.18,
                    2.09,
                    2.17,
                    2.12,
                    2.13,
                    2.13,
                    2.11,
                    2.15,
                    2.15,
                    2.07,
                    2.18,
                    2.22,
                    2.2,
                    2.13,
                    2.2,
                    2.24,
                    2.24,
                    2.22,
                    2.22,
                    2.18,
                    2.2,
                    2.2,
                    2.19,
                    2.13,
                    2.12,
                    2.07,
                    2.13,
                    2.15,
                    2.18,
                    2.22,
                    2.24,
                    2.19,
                    2.16,
                    2.16,
                    2.17,
                    2.16,
                    2.12,
                    2.06,
                    2.12,
                    2.16,
                    2.22,
                    2.25,
                    2.24,
                    2.26,
                    2.28,
                    2.33,
                    2.37,
                    2.34,
                    2.38,
                    2.36,
                    2.25,
                    2.3,
                    2.26,
                    2.22,
                    2.18,
                    2.17,
                    2.19,
                    2.18,
                    2.15,
                    2.19,
                    2.15,
                    2.19,
                    2.15,
                    2.09,
                    2.13,
                    2.09,
                    2.1,
                    2.15,
                    2.13,
                    2.06,
                    2,
                    2,
                    2,
                    2.01,
                    1.93,
                    1.98,
                    2,
                    2.01,
                    2.04,
                    2.09,
                    2.07,
                    2.06,
                    2.05,
                    2.08,
                    2.04,
                    2.08,
                    2.12,
                    2.12,
                    2.15,
                    2.16,
                    2.18,
                    2.15,
                    2.09,
                    2.1,
                    2.09,
                    1.99,
                    2,
                    2.01,
                    2.03,
                    2.05,
                    2.02,
                    2.02,
                    2.02,
                    2.02,
                    1.98,
                    1.98,
                    1.99,
                    1.94,
                    1.96,
                    1.93,
                    1.96,
                    1.97,
                    1.98,
                    2,
                    2,
                    1.92,
                    1.96,
                    1.95,
                    1.99,
                    1.96,
                    1.97,
                    1.95,
                    1.95,
                    1.95,
                    2.01,
                    2.02,
                    2.06,
                    2.1,
                    2.14,
                    2.14,
                    2.12,
                    2.15,
                    2.17,
                    2.15,
                    2.14,
                    2.1,
                    2.12,
                    2.1,
                    2.09,
                    2.04,
                    2.05,
                    2.04,
                    2.02,
                    2.04,
                    2.11,
                    2.06,
                    2.08,
                    2.06,
                    2.06,
                    2.03,
                    2.05,
                    2,
                    2.04,
                    2.03,
                    2.04,
                    2.03,
                    1.98,
                    1.97,
                    1.99,
                    2.05,
                    2,
                    1.97,
                    1.98,
                    1.97,
                    2,
                    1.95,
                    1.98,
                    1.96,
                    1.95,
                    1.92,
                    1.92,
                    1.91,
                    1.95,
                    1.95,
                    1.85,
                    1.89,
                    1.83,
                    1.84,
                    1.92,
                    1.96,
                    1.98,
                    1.98,
                    2,
                    2.03,
                    2.04,
                    2.07,
                    2.08,
                    2.06,
                    2.03,
                    2.05,
                    2.11,
                    2.1,
                    2.13,
                    2.14,
                    2.12,
                    2.12,
                    2.15,
                    2.16,
                    2.16,
                    2.16,
                    2.15,
                    2.13,
                    2.09,
                    2.13,
                    2.13,
                    2.17,
                    2.14,
                    2.2,
                    2.18,
                    2.23,
                    2.24,
                    2.27,
                    2.22,
                    2.18,
                    2.2,
                    2.2,
                    2.17,
                    2.16,
                    2.15,
                    2.15,
                    2.17,
                    2.18,
                    2.22,
                    2.24,
                    2.21,
                    2.18,
                    2.22,
                    2.2,
                    2.23,
                    2.23,
                    2.18,
                    2.18,
                    2.2,
                    2.19,
                    2.19,
                    2.25,
                    2.27,
                    2.24,
                    2.26,
                    2.25,
                    2.23,
                    2.25,
                    2.26,
                    2.27,
                    2.29,
                    2.22,
                    2.24,
                    2.25,
                    2.28,
                    2.34,
                    2.37,
                    2.36,
                    2.37,
                    2.37,
                    2.36,
                    2.3,
                    2.33,
                    2.31,
                    2.31,
                    2.36,
                    2.35,
                    2.36,
                    2.38,
                    2.38,
                    2.44,
                    2.44,
                    2.43,
                    2.45,
                    2.45,
                    2.44,
                    2.5,
                    2.52,
                    2.55,
                    2.55,
                    2.51,
                    2.54,
                    2.52,
                    2.57,
                    2.59,
                    2.61,
                    2.61,
                    2.68,
                    2.71,
                    2.57,
                    2.67,
                    2.7,
                    2.68,
                    2.7,
                    2.71,
                    2.69,
                    2.77,
                    2.78,
                    2.75,
                    2.75,
                    2.77,
                    2.82,
                    2.79,
                    2.74,
                    2.74,
                    2.78,
                    2.75,
                    2.69,
                    2.75,
                    2.76,
                    2.77,
                    2.77,
                    2.74,
                    2.77,
                    2.75,
                    2.73,
                    2.71,
                    2.73,
                    2.74,
                    2.76,
                    2.8,
                    2.78,
                    2.72,
                    2.71,
                    2.75,
                    2.67,
                    2.69,
                    2.65,
                    2.65,
                    2.64,
                    2.69,
                    2.71,
                    2.74,
                    2.68,
                    2.69,
                    2.71,
                    2.7,
                    2.75,
                    2.75,
                    2.75,
                    2.76,
                    2.8,
                    2.83,
                    2.88,
                    2.9,
                    2.91,
                    2.93,
                    2.9,
                    2.88,
                    2.88,
                    2.89,
                    2.88,
                    2.86,
                    2.87,
                    2.87,
                    2.9,
                    2.92,
                    2.9,
                    2.9,
                    2.93,
                    3,
                    3.02,
                    3.02,
                    2.97,
                    2.98,
                    2.98,
                    2.91,
                    2.9,
                    2.85,
                    2.85,
                    2.68,
                    2.77,
                    2.78,
                    2.83,
                    2.87,
                    2.85,
                    2.89,
                    2.84,
                    2.86,
                    2.87,
                    2.89,
                    2.9,
                    2.87,
                    2.86,
                    2.85,
                    2.83,
                    2.87,
                    2.83,
                    2.83,
                    2.82,
                    2.81,
                    2.76,
                    2.78,
                    2.8,
                    2.81,
                    2.78,
                    2.78,
                    2.78,
                    2.77,
                    2.8,
                    2.8,
                    2.8,
                    2.8,
                    2.78,
                    2.81,
                    2.81,
                    2.82,
                    2.79,
                    2.83,
                    2.89,
                    2.88,
                    2.91,
                    2.92,
                    2.9,
                    2.91,
                    2.9,
                    2.94,
                    2.92,
                    2.88,
                    2.87,
                    2.91,
                    2.9,
                    2.87,
                    2.81,
                    2.81,
                    2.84,
                    2.8,
                    2.8,
                    2.8,
                    2.76,
                    2.77,
                    2.76,
                    2.77,
                    2.76,
                    2.79,
                    2.83,
                    2.83,
                    2.8,
                    2.8,
                    2.8,
                    2.84,
                    2.84,
                    2.81,
                    2.88,
                    2.88,
                    2.92,
                    2.91,
                    2.92,
                    2.95,
                    2.94,
                    3,
                    3,
                    3.01,
                    3.01,
                    3.03,
                    3.04,
                    3,
                    3,
                    3.01,
                    3.02,
                    3.01,
                    3.11,
                    3.12,
                    3.15,
                    3.15,
                    3.13,
                    3.08,
                    3.08,
                    3.09,
                    3.08,
                    3.09,
                    3.13,
                    3.1,
                    3.12,
                    3.12,
                    3.09,
                    3.02,
                    3.04,
                    2.99,
                    3,
                    3.04,
                    3.06,
                    3.04,
                    3.12,
                    3.11,
                    3.14,
                    3.16,
                    3.16,
                    3.11,
                    3.11,
                    3.06,
                    3.04,
                    3.02,
                    2.97,
                    2.97,
                    2.97,
                    2.98,
                    2.98,
                    2.95,
                    2.97,
                    2.97,
                    2.96,
                    2.94,
                    2.9,
                    2.89,
                    2.85,
                    2.85,
                    2.82,
                    2.77,
                    2.79,
                    2.81,
                    2.84,
                    2.83,
                    2.81,
                    2.78,
                    2.73,
                    2.69,
                    2.74,
                    2.71,
                    2.66,
                    2.66,
                    2.73,
                    2.69,
                    2.64,
                    2.6,
                    2.6,
                    2.54,
                    2.46,
                    2.58,
                    2.62,
                    2.65,
                    2.63,
                    2.65,
                    2.61,
                    2.61,
                    2.62,
                    2.63,
                    2.66,
                    2.7,
                    2.7,
                    2.66,
                    2.66,
                    2.63,
                    2.68,
                    2.66,
                    2.63,
                    2.58,
                    2.53,
                    2.59,
                    2.63,
                    2.6,
                    2.6,
                    2.56,
                    2.54,
                    2.56,
                    2.59,
                    2.61,
                    2.56,
                    2.58,
                    2.58,
                    2.55,
                    2.56,
                    2.6,
                    2.56,
                    2.57,
                    2.54,
                    2.58,
                    2.61,
                    2.65,
                    2.62,
                    2.62,
                    2.6,
                    2.54,
                    2.53,
                    2.54,
                    2.51,
                    2.53,
                    2.53,
                    2.49,
                    2.51,
                    2.52,
                    2.43,
                    2.44,
                    2.34,
                    2.29,
                    2.31,
                    2.26,
                    2.3,
                    2.32,
                    2.41,
                    2.38,
                    2.43,
                    2.42,
                    2.4,
                    2.42,
                    2.4,
                    2.37,
                    2.4,
                    2.47,
                    2.46,
                    2.5,
                    2.5,
                    2.47,
                    2.47,
                    2.49,
                    2.46,
                    2.42,
                    2.43,
                    2.39,
                    2.42,
                    2.39,
                    2.4,
                    2.44,
                    2.42,
                    2.37,
                    2.36,
                    2.38,
                    2.34,
                    2.37,
                    2.29,
                    2.3,
                    2.26,
                    2.28,
                    2.28,
                    2.31,
                    2.33,
                    2.28,
                    2.22,
                    2.22,
                    2.22,
                    2.17,
                    2.16,
                    2.12,
                    2.02,
                    1.95,
                    2.01,
                    2.01,
                    2,
                    1.97,
                    2.03,
                    2.03,
                    2,
                    1.97,
                    1.96,
                    1.97,
                    1.95,
                    1.89,
                    1.9,
                    1.92,
                    1.88,
                    1.85,
                    1.92,
                    1.89,
                    1.89,
                    1.91,
                    1.86,
                    1.84,
                    1.84,
                    1.93,
                    1.95,
                    1.97,
                    1.94,
                    2.02,
                    2,
                    1.97,
                    1.99,
                    1.93,
                    1.9,
                    1.93,
                    1.92,
                    1.96,
                    1.93,
                    1.97,
                    1.96,
                    1.96,
                    1.95,
                    1.92,
                    1.79,
                    1.75,
                    1.61,
                    1.61,
                    1.64,
                    1.63,
                    1.66,
                    1.57,
                    1.64,
                    1.53,
                    1.48,
                    1.49,
                    1.54,
                    1.49,
                    1.53,
                    1.56,
                    1.48,
                    1.48,
                    1.42,
                    1.43,
                    1.45,
                    1.44,
                    1.44,
                    1.39,
                    1.39,
                    1.49,
                    1.5,
                    1.57,
                    1.66,
                    1.67,
                    1.7,
                    1.82,
                    1.77,
                    1.73,
                    1.74,
                    1.72,
                    1.66,
                    1.66,
                    1.59,
                    1.67,
                    1.64,
                    1.62,
                    1.6,
                    1.56,
                    1.52,
                    1.44,
                    1.44,
                    1.47,
                    1.44,
                    1.49,
                    1.58,
                    1.64,
                    1.64,
                    1.68,
                    1.65,
                    1.66,
                    1.66,
                    1.7,
                    1.67,
                    1.68,
                    1.67,
                    1.71,
                    1.75,
                    1.75,
                    1.69,
                    1.61,
                    1.63,
                    1.69,
                    1.76,
                    1.73,
                    1.82,
                    1.84,
                    1.84,
                    1.84,
                    1.79,
                    1.72,
                    1.74,
                    1.73,
                    1.7,
                    1.67,
                    1.7,
                    1.7,
                    1.68,
                    1.67,
                    1.7,
                    1.7,
                    1.7,
                    1.73,
                    1.63,
                    1.69,
                    1.72,
                    1.75,
                    1.74,
                    1.76,
                    1.71,
                    1.81,
                    1.74,
                    1.79,
                    1.79,
                    1.83,
                    1.83,
                    1.82,
                    1.84,
                    1.8,
                    1.8,
                    1.81,
                    1.78,
                    1.77,
                    1.8,
                    1.8,
                    1.77,
                    1.69,
                    1.71,
                    1.71,
                    1.77,
                    1.75,
                    1.73,
                    1.75,
                    1.72,
                    1.69,
                    1.72,
                    1.72,
                    1.72,
                    1.67,
                    1.67,
                    1.64,
                    1.59,
                    1.52,
                    1.57,
                    1.49,
                    1.5,
                    1.41,
                    1.44,
                    1.51,
                    1.56,
                    1.55,
                    1.49,
                    1.48,
                    1.51,
                    1.54,
                    1.53,
                    1.5,
                    1.5,
                    1.48,
                    1.49,
                    1.44,
                    1.4,
                    1.29,
                    1.27,
                    1.25,
                    1.16,
                    1.04,
                    1.05,
                    0.87,
                    0.92,
                    0.79,
                    0.69,
                    0.51,
                    0.74,
                    0.79,
                    0.7,
                    0.84,
                    0.6,
                    0.91,
                    0.99,
                    0.91,
                    0.65,
                    0.6,
                    0.68,
                    0.7,
                    0.69,
                    0.53,
                    0.57,
                    0.52,
                    0.47,
                    0.49,
                    0.49,
                    0.55,
                    0.58,
                    0.62,
                    0.56,
                    0.56,
                    0.61,
                    0.59,
                    0.48,
                    0.49,
                    0.5,
                    0.47,
                    0.45,
                    0.49,
                    0.49,
                    0.49,
                    0.53,
                    0.49,
                    0.5,
                    0.5,
                    0.48,
                    0.5,
                    0.52,
                    0.54,
                    0.47,
                    0.51,
                    0.53,
                    0.49,
                    0.48,
                    0.46,
                    0.48,
                    0.55,
                    0.51,
                    0.51,
                    0.51,
                    0.5,
                    0.5,
                    0.52,
                    0.51,
                    0.51,
                    0.48,
                    0.48,
                    0.5,
                    0.56,
                    0.62,
                    0.68,
                    0.66,
                    0.61,
                    0.52,
                    0.5,
                    0.52,
                    0.53,
                    0.55,
                    0.54,
                    0.52,
                    0.51,
                    0.52,
                    0.52,
                    0.5,
                    0.51,
                    0.47,
                    0.45,
                    0.47,
                    0.49,
                    0.48,
                    0.48,
                    0.49,
                    0.46,
                    0.48,
                    0.45,
                    0.48,
                    0.45,
                    0.46,
                    0.46,
                    0.45,
                    0.46,
                    0.44,
                    0.43,
                    0.44,
                    0.42,
                    0.43,
                    0.45,
                    0.42,
                    0.41,
                    0.39,
                    0.37,
                    0.39,
                    0.35,
                    0.39,
                    0.37,
                    0.4,
                    0.41,
                    0.46,
                    0.49,
                    0.52,
                    0.5,
                    0.49,
                    0.47,
                    0.48,
                    0.46,
                    0.45,
                    0.47,
                    0.49,
                    0.49,
                    0.53,
                    0.5,
                    0.49,
                    0.46,
                    0.45,
                    0.44,
                    0.51,
                    0.51,
                    0.47,
                    0.49,
                    0.47,
                    0.46,
                    0.47,
                    0.48,
                    0.49,
                    0.48,
                    0.49,
                    0.47,
                    0.47,
                    0.47,
                    0.47,
                    0.46,
                    0.46,
                    0.45,
                    0.48,
                    0.47,
                    0.49,
                    0.56,
                    0.52,
                    0.56,
                    0.56,
                    0.56,
                    0.56,
                    0.52,
                    0.51,
                    0.52,
                    0.53,
                    0.55,
                    0.56,
                    0.59,
                    0.62,
                    0.61,
                    0.58,
                    0.55,
                    0.55,
                    0.6,
                    0.63,
                    0.61,
                    0.65,
                    0.55,
                    0.54,
                    0.59,
                    0.68,
                    0.71,
                    0.71,
                    0.64,
                    0.65,
                    0.66,
                    0.62,
                    0.63,
                    0.6,
                    0.6,
                    0.62,
                    0.64,
                    0.64,
                    0.64,
                    0.6,
                    0.6,
                    0.67,
                    0.68,
                    0.65,
                    0.69,
                    0.65,
                    0.66,
                    0.67,
                    0.65,
                    0.63,
                    0.63,
                    0.64,
                    0.64,
                    0.66,
                    0.66,
                    0.66,
                    0.64,
                    0.66,
                    0.64,
                    0.64,
                    0.64,
                    0.66,
                    0.65,
                    0.64,
                    0.64,
                    0.63,
                    0.67,
                    0.73,
                    0.77,
                    0.8,
                    0.83,
                    0.81,
                    0.78,
                    0.81,
                    0.77,
                    0.77,
                    0.77,
                    0.76,
                    0.78,
                    0.76,
                    0.72,
                    0.72,
                    0.71,
                    0.74,
                    0.74,
                    0.75,
                    0.77,
                    0.8,
                    0.8,
                    0.81,
                    0.82,
                    0.82,
                    0.79,
                    0.81,
                    0.85,
                    0.85,
                    0.94,
                    0.91,
                    0.92,
                    0.96,
                    0.98,
                    0.95,
                    0.99,
                    1.17,
                    1.07,
                    1.06,
                    1.03,
                    1.11,
                    1.18,
                    1.18,
                    1.22,
                    1.17,
                    1.15,
                    1.16,
                    1.23,
                    1.22,
                    1.22,
                    1.22,
                    1.29,
                    1.3,
                    1.28,
                    1.22,
                    1.21,
                    1.23,
                    1.27,
                    1.3,
                    1.3,
                    1.34,
                    1.29,
                    1.35,
                    1.31,
                    1.26,
                    1.27,
                    1.23,
                    1.26,
                    1.27,
                    1.22,
                    1.24,
                    1.2,
                    1.21,
                    1.22,
                    1.18,
                    1.18,
                    1.16,
                    1.19,
                    1.2,
                    1.25,
                    1.23,
                    1.25,
                    1.24,
                    1.21,
                    1.2,
                    1.18,
                    1.19,
                    1.18,
                    1.19,
                    1.21,
                    1.28,
                    1.24,
                    1.22,
                    1.24,
                    1.23,
                    1.26,
                    1.22,
                    1.22,
                    1.2,
                    1.16,
                    1.18,
                    1.21,
                    1.2,
                    1.2,
                    1.21,
                    1.19,
                    1.23,
                    1.17,
                    1.18,
                    1.15,
                    1.12,
                    1.07,
                    1.1,
                    1.14,
                    1.14,
                    1.24,
                    1.19,
                    1.16,
                    1.19,
                    1.16,
                    1.18,
                    1.2,
                    1.22,
                    1.19,
                    1.18,
                    1.18,
                    1.18,
                    1.14,
                    1.14,
                    1.07,
                    1.05,
                    1.02,
                    1.07,
                    1.08,
                    1.13,
                    1.07,
                    1.04,
                    1.03,
                    0.95,
                    0.95,
                    1.01,
                    1,
                    0.99,
                    1.01,
                    0.97,
                    0.97,
                    1,
                    0.96,
                    0.92,
                    0.91,
                    0.93,
                    0.97,
                    1.03,
                    1.06,
                    1.09,
                    1.07,
                    1.09,
                    1.02,
                    1.01,
                    1.01,
                    1.01,
                    1.01,
                    1.02,
                    1.01,
                    1.05,
                    1.08,
                    1.1,
                    1.05,
                    1.02,
                    1.04,
                    1.03,
                    1.02,
                    1.05,
                    1.05,
                    1.1,
                    1.07,
                    1.04,
                    1.08,
                    1.07,
                    1.03,
                    1.05,
                    1.09,
                    1.11,
                    1.07,
                    1.08,
                    1.08,
                    1.19,
                    1.2,
                    1.24,
                    1.28,
                    1.25,
                    1.23,
                    1.19,
                    1.21,
                    1.25,
                    1.25,
                    1.3,
                    1.34,
                    1.34,
                    1.32,
                    1.3,
                    1.28,
                    1.35,
                    1.39,
                    1.4,
                    1.41,
                    1.47,
                    1.41,
                    1.4,
                    1.39,
                    1.35,
                    1.38,
                    1.37,
                    1.37,
                    1.35,
                    1.4,
                    1.32,
                    1.25,
                    1.3,
                    1.26,
                    1.38,
                    1.38,
                    1.39,
                    1.43,
                    1.45,
                    1.41,
                    1.4,
                    1.38,
                    1.47,
                    1.5,
                    1.49,
                    1.49,
                    1.32,
                    1.32,
                    1.3,
                    1.27,
                    1.33,
                    1.24,
                    1.32,
                    1.36,
                    1.4,
                    1.38,
                    1.37,
                    1.31,
                    1.34,
                    1.35,
                    1.29,
                    1.29,
                    1.29,
                    1.34,
                    1.33,
                    1.37,
                    1.37,
                    1.36,
                    1.36,
                    1.42,
                    1.39,
                    1.39,
                    1.49,
                    1.5,
                    1.57,
                    1.59,
                    1.63,
                    1.64,
                    1.62,
                    1.63,
                    1.59,
                    1.67,
                    1.67,
                    1.77,
                    1.76,
                    1.7,
                    1.66,
                    1.66,
                    1.66,
                    1.77,
                    1.73,
                    1.69,
                    1.69,
                    1.7,
                    1.69,
                    1.75,
                    1.84,
                    1.84,
                    1.89,
                    1.88,
                    1.99,
                    1.9,
                    1.95,
                    1.99,
                    1.98,
                    1.9,
                    1.87,
                    1.87,
                    1.9,
                    1.95,
                    1.91,
                    1.91,
                    1.77,
                    1.66,
                    1.82,
                    1.79,
                    1.68,
                    1.74,
                    1.81,
                    1.92,
                    1.95,
                    1.97,
                    2.11,
                    2.12,
                    2.18,
                    2.16,
                    2.15,
                    2.31,
                    2.39,
                    2.3,
                    2.39,
                    2.51,
                    2.51,
                    2.45,
                    2.39,
                    2.4,
                    2.47,
                    2.47,
                    2.62,
                    2.64,
                    2.68,
                    2.73,
                    2.78,
                    2.71,
                    2.68,
                    2.81,
                    2.81,
                    2.82,
                    2.93,
                    2.85,
                    2.94,
                    2.92,
                    2.84,
                    2.73,
                    2.83,
                    2.83,
                    2.94,
                    2.99,
                    3,
                    2.92,
                    3.02,
                    3.1,
                    2.99,
                    2.95,
                    2.91,
                    2.83,
                    2.89,
                    2.85,
                    2.97,
                    2.89,
                    2.84,
                    2.79,
                    2.86,
                    2.75,
                    2.73,
                    2.73,
                    2.73,
                    2.73,
                    2.83,
                    2.91,
                    2.91,
                    2.93,
                    3.04,
                    2.98,
                    3.03,
                    3.05,
                    3.21,
                    3.42,
                    3.53,
                    3.33,
                    3.24,
                    3.28,
                    3.28,
                    3.32,
                    3.19,
                    3.12,
                    3.16,
                    3.23,
                    3.2,
                    3.12,
                    3.03,
                    2.88,
                    2.88,
                    2.81,
                    2.95,
                    3.01,
                    3.1,
                    3.02,
                    2.99,
                    2.98,
                    3.01,
                    2.97,
                    3.04,
                    3.09,
                    3.1,
                    2.93,
                    2.8,
                    2.84,
                    2.86,
                    2.81,
                    2.69,
                    2.66,
                    2.6,
                    2.8,
                    2.77,
                    2.74,
                    2.89,
                    2.83,
                    2.87,
                    2.85,
                    2.94,
                    2.89,
                    2.84,
                    2.88,
                    2.97,
                    2.95,
                    3.03,
                    3.09,
                    3.11,
                    3.17,
                    3.09,
                    3.12,
                    3.18,
                    3.18,
                    3.27,
                    3.33,
                    3.24,
                    3.24,
                    3.4,
                    3.31,
                    3.37,
                    3.37,
                    3.4,
                    3.49,
                    3.5,
                    3.56,
                    3.54,
                    3.59,
                    3.65,
                    3.65,
                    3.83,
                    3.83,
                    4.06,
                    4.06,
                    3.84,
                    3.9,
                    3.96,
                    3.76,
                    3.74,
                    3.86,
                    3.95,
                    4.01,
                    4.01,
                    4.06,
                    4.01,
                    4.07,
                    4.14,
                    4.12,
                    4.11,
                    4.25,
                    4.34,
                    4.28,
                    4.3,
                    4.18,
                    4.09,
                    3.99,
                    4.1,
                    4.14,
                    4.15,
                    4.2,
                    4.26,
                    4.24,
                    4.3,
                    4.21,
                    4.17,
                    3.88,
                    3.88,
                    3.92,
                    3.83,
                    3.77,
                    3.85,
                    3.92,
                    3.92,
                    3.85,
                    3.79,
                    3.79,
                    3.77,
                    3.78,
                    3.83,
                    3.67,
                    3.58,
                    3.57,
                    3.68,
                    3.64,
                    3.52,
                    3.59,
                    3.67,
                    3.7,
                    3.58,
                    3.55,
                    3.53,
                    3.55,
                    3.65,
                    3.73,
                    3.72,
                    3.74,
                    3.8,
                    3.8,
                    3.89,
                    3.92,
                    3.88,
                    3.94,
                    3.94,
                    3.82,
                    3.76,
                    3.82,
                    3.63,
                    3.6,
                    3.67,
                    3.6,
                    3.49,
                    3.56,
                    3.56,
                    3.58,
                    3.4,
                    3.44,
                    3.52,
                    3.56,
                    3.51,
                    3.49,
                    3.54,
                    3.56,
                    3.6,
                    3.56,
                    3.47,
                    3.44,
                    3.59,
                    3.73,
                    3.75,
                    3.7,
                    3.76,
                    3.83,
                    3.81,
                    3.87,
                    3.92,
                    3.97,
                    3.92,
                    3.92,
                    4.06,
                    4.03,
                    3.99,
                    4.08,
                    4.04,
                    4.05,
                    4.12,
                    4.18,
                    4.1,
                    4.11,
                    4.14,
                    4.17,
                    4.05,
                    3.83,
                    3.64,
                    3.77,
                    3.5,
                    3.66,
                    3.46,
                    3.54,
                    3.68,
                    3.47,
                    3.43,
                    3.39,
                    3.56,
                    3.62,
                    3.62,
                    3.62,
                    3.52,
                    3.46,
                    3.36,
                    3.34,
                    3.34,
                    3.44,
                    3.47,
                    3.48,
                    3.43,
                    3.47,
                    3.56,
                    3.65,
                    3.63,
                    3.65,
                    3.58,
                    3.62,
                    3.53,
                    3.42,
                    3.47,
                    3.56,
                    3.45,
                    3.6,
                    3.44,
                    3.32,
                    3.35,
                    3.42,
                    3.5,
                    3.51,
                    3.41,
                    3.37,
                    3.45,
                    3.49,
                    3.53,
                    3.57,
                    3.67,
                    3.7,
                    3.74,
                    3.72,
                    3.78,
                    3.87,
                    3.86,
                    3.86,
                    3.75,
                    3.7,
                    3.65,
                    3.77,
                    3.75,
                    3.73,
                    3.87,
                    3.79,
                    3.83,
                    3.81,
                    3.9,
                    3.89,
                    3.81,
                    3.87,
                    3.87,
                    3.84,
                    3.84,
                    3.92,
                    3.86,
                    3.84,
                    3.9,
                    3.84,
                    3.98,
                    4,
                    4.02,
                    4.02,
                    4.09,
                    4.19,
                    4.21,
                    4.11,
                    4.1,
                    3.97,
                    3.86,
                    3.94,
                    3.91,
                    3.9,
                    3.87,
                    3.98,
                    3.96,
                    4.01,
                    4.03,
                    3.99,
                    4.12,
                    4.06,
                    4.07,
                    4.12,
                    4.16,
                    4.23,
                    4.08,
                    4.13,
                    4.07,
                    4.07,
                    4.17,
                    4.23,
                    4.28,
                    4.29,
                    4.33,
                    4.34,
                    4.32,
                    4.4,
                    4.41,
                    4.28,
                    4.33,
                    4.34,
                    4.3,
                    4.2,
                    4.19,
                    4.18,
                    4.24,
                    4.24,
                    4.32,
                    4.35,
                    4.31,
                    4.33,
                    4.35,
                    4.35,
                    4.32,
                    4.35,
                    4.4,
                    4.38,
                    4.44,
                    4.5,
                    4.56,
                    4.5,
                    4.57,
                    4.57,
                    4.65,
                    4.6,
                    4.59
                ]
            },
            {
                name: "Analogue",
                type: "line",
                yAxis: 0,
                data: [
                    3.7,
                    3.74,
                    3.8,
                    3.75,
                    3.77,
                    3.8,
                    3.78,
                    3.87,
                    3.91,
                    4.02,
                    4.01,
                    4,
                    3.96,
                    3.89,
                    3.92,
                    3.91,
                    3.94,
                    3.92,
                    3.97,
                    4.01,
                    4.02,
                    4.06,
                    4.05,
                    4.03,
                    4.04,
                    4.1,
                    4.09,
                    4.05,
                    3.99,
                    3.98,
                    3.96,
                    3.95,
                    3.93,
                    3.93,
                    3.83,
                    3.82,
                    3.86,
                    3.9,
                    3.96,
                    3.97,
                    3.98,
                    4.01,
                    4.03,
                    4.06,
                    4.06,
                    3.98,
                    4,
                    4.05,
                    4.02,
                    3.97,
                    3.99,
                    3.99,
                    3.91,
                    3.85,
                    3.86,
                    3.87,
                    3.9,
                    3.95,
                    3.91,
                    3.93,
                    3.91,
                    3.98,
                    4,
                    4.01,
                    4.01,
                    4.1,
                    4.13,
                    4.07,
                    4.03,
                    4.02,
                    3.97,
                    3.99,
                    3.92,
                    3.92,
                    3.91,
                    3.96,
                    4,
                    4.03,
                    4.05,
                    4,
                    4.04,
                    4.05,
                    4.04,
                    4.09,
                    4.07,
                    4.05,
                    4.05,
                    4,
                    3.99,
                    4.02,
                    4.01,
                    4.02,
                    4.01,
                    3.96,
                    3.95,
                    3.97,
                    3.96,
                    3.96,
                    3.97,
                    3.96,
                    4.01,
                    3.98,
                    3.95,
                    3.94,
                    3.97,
                    3.96,
                    4.03,
                    4.07,
                    4.08,
                    4.06,
                    3.98,
                    3.93,
                    3.89,
                    3.81,
                    3.76,
                    3.81,
                    3.83,
                    3.77,
                    3.85,
                    3.85,
                    3.84,
                    3.77,
                    3.77,
                    3.82,
                    3.79,
                    3.87,
                    3.92,
                    3.91,
                    3.86,
                    3.9,
                    4.01,
                    4.08,
                    4.08,
                    4.01,
                    4.02,
                    4,
                    4.06,
                    4.09,
                    4.06,
                    4.08,
                    4.03,
                    4.06,
                    4.1,
                    4.09,
                    4,
                    3.98,
                    4,
                    3.98,
                    4,
                    3.98,
                    3.98,
                    3.99,
                    4.04,
                    4.05,
                    4.01,
                    4.15,
                    4.17,
                    4.15,
                    4.23,
                    4.3,
                    4.19,
                    4.15,
                    4.22,
                    4.31,
                    4.29,
                    4.27,
                    4.31,
                    4.28,
                    4.22,
                    4.21,
                    4.23,
                    4.25,
                    4.3,
                    4.29,
                    4.26,
                    4.26,
                    4.27,
                    4.22,
                    4.24,
                    4.22,
                    4.22,
                    4.23,
                    4.13,
                    4.14,
                    4.12,
                    4.14,
                    4.1,
                    4.12,
                    4.09,
                    4.08,
                    4.09,
                    4.13,
                    4.11,
                    4.13,
                    4.12,
                    4.06,
                    4.08,
                    4.08,
                    4.04,
                    4.01,
                    3.95,
                    3.95,
                    3.97,
                    3.98,
                    3.98,
                    3.9,
                    3.9,
                    3.9,
                    3.94,
                    3.93,
                    3.93,
                    3.93,
                    3.98,
                    4,
                    4.04,
                    4,
                    3.98,
                    3.98,
                    3.97,
                    3.99,
                    3.9,
                    3.91,
                    3.92,
                    3.94,
                    3.96,
                    3.97,
                    3.98,
                    3.92,
                    3.94,
                    3.99,
                    3.99,
                    3.99,
                    3.98,
                    3.96,
                    3.95,
                    3.95,
                    3.93,
                    3.92,
                    3.91,
                    3.96,
                    4,
                    4,
                    3.97,
                    3.92,
                    3.95,
                    3.92,
                    3.93,
                    3.93,
                    3.93,
                    3.87,
                    3.88,
                    3.86,
                    3.85,
                    3.85,
                    3.86,
                    3.86,
                    3.85,
                    3.86,
                    3.88,
                    3.94,
                    3.97,
                    3.95,
                    3.97,
                    3.97,
                    4.02,
                    3.99,
                    3.99,
                    3.96,
                    3.93,
                    3.91,
                    3.92,
                    3.89,
                    3.89,
                    3.86,
                    3.9,
                    3.89,
                    3.88,
                    3.92,
                    3.95,
                    4,
                    3.97,
                    3.96,
                    3.98,
                    4.02,
                    3.99,
                    4.04,
                    4.05,
                    4.06,
                    4.06,
                    4.07,
                    4.06,
                    4.08,
                    4.09,
                    4.05,
                    4.04,
                    4.1,
                    4.11,
                    4.1,
                    4.12,
                    4.11,
                    4.13,
                    4.14,
                    4.15,
                    4.16,
                    4.17,
                    4.09,
                    4.08,
                    4.08,
                    4.06,
                    4.07,
                    4.05,
                    4.07,
                    4.06,
                    4.06,
                    4.11,
                    4.12,
                    4.12,
                    4.12,
                    4.11,
                    4.13,
                    4.12,
                    4.16,
                    4.16,
                    4.16,
                    4.12,
                    4.1,
                    4.08,
                    4.12,
                    4.09,
                    4.09,
                    4.07,
                    4.08,
                    4.05,
                    4.05,
                    4.04,
                    4.03,
                    4.03,
                    4.04,
                    4.02,
                    4,
                    3.99,
                    3.99,
                    4,
                    4.04,
                    4.06,
                    4.04,
                    4.04,
                    4.05,
                    4.03,
                    4.05,
                    4.02,
                    4.03,
                    4.05,
                    4.08,
                    4.14,
                    4.14,
                    4.17,
                    4.15,
                    4.18,
                    4.17,
                    4.22,
                    4.21,
                    4.24,
                    4.26,
                    4.27,
                    4.25,
                    4.31,
                    4.31,
                    4.3,
                    4.3,
                    4.26,
                    4.28,
                    4.33,
                    4.37,
                    4.31,
                    4.35,
                    4.29,
                    4.32,
                    4.3,
                    4.32,
                    4.32,
                    4.31,
                    4.36,
                    4.27,
                    4.28,
                    4.28,
                    4.29,
                    4.25,
                    4.25,
                    4.27,
                    4.25,
                    4.25,
                    4.25,
                    4.21,
                    4.23,
                    4.24,
                    4.22,
                    4.18,
                    4.17,
                    4.15,
                    4.11,
                    4.11,
                    4.1,
                    4.1,
                    4.2,
                    4.18,
                    4.17,
                    4.16,
                    4.11,
                    4.1,
                    4.09,
                    4.09,
                    4.06,
                    4.09,
                    4.07,
                    4.12,
                    4.14,
                    4.11,
                    4.09,
                    4.06,
                    3.99,
                    4,
                    4.01,
                    3.96,
                    4.01,
                    4.04,
                    4,
                    3.98,
                    3.99,
                    3.93,
                    3.95,
                    3.95,
                    3.9,
                    3.88,
                    3.88,
                    3.92,
                    3.91,
                    3.85,
                    3.87,
                    3.88,
                    3.89,
                    3.93,
                    3.93,
                    3.93,
                    3.92,
                    3.95,
                    3.97,
                    3.92,
                    3.85,
                    3.86,
                    3.85,
                    3.86,
                    3.89,
                    3.9,
                    3.85,
                    3.92,
                    3.9,
                    3.92,
                    3.9,
                    3.91,
                    3.9,
                    3.92,
                    3.95,
                    3.94,
                    3.91,
                    3.89,
                    3.93,
                    3.93,
                    3.95,
                    4.02,
                    4.01,
                    3.99,
                    3.99,
                    4.04,
                    4.03,
                    4.08,
                    4.1,
                    4.11,
                    4.11,
                    4.12,
                    4.13,
                    4.15,
                    4.12,
                    4.11,
                    4.11,
                    4.05,
                    4.1,
                    4.06,
                    4.09,
                    4.05,
                    4.07,
                    4.09,
                    4.05,
                    4.04,
                    4.06,
                    4.08,
                    4.06,
                    4.06,
                    4,
                    4.03,
                    4.05,
                    4.06,
                    4.07,
                    4.07,
                    4.09,
                    4.08,
                    4.11,
                    4.1,
                    4.12,
                    4.16,
                    4.21,
                    4.2,
                    4.17,
                    4.13,
                    4.16,
                    4.17,
                    4.2,
                    4.19,
                    4.23,
                    4.3,
                    4.31,
                    4.31,
                    4.28,
                    4.24,
                    4.2,
                    4.22,
                    4.21,
                    4.26,
                    4.25,
                    4.3,
                    4.28,
                    4.29,
                    4.31,
                    4.24,
                    4.24,
                    4.18,
                    4.19,
                    4.24,
                    4.28,
                    4.28,
                    4.28,
                    4.31,
                    4.38,
                    4.39,
                    4.41,
                    4.43,
                    4.43,
                    4.35,
                    4.37,
                    4.3,
                    4.3,
                    4.26,
                    4.27,
                    4.26,
                    4.27,
                    4.3,
                    4.25,
                    4.23,
                    4.24,
                    4.21,
                    4.24,
                    4.27,
                    4.28,
                    4.29,
                    4.29,
                    4.31,
                    4.38,
                    4.3,
                    4.22,
                    4.19,
                    4.23,
                    4.25,
                    4.26,
                    4.24,
                    4.21,
                    4.21,
                    4.2,
                    4.23,
                    4.25,
                    4.24,
                    4.22,
                    4.17,
                    4.13,
                    4.17,
                    4.16,
                    4.2,
                    4.2,
                    4.19,
                    4.16,
                    4.16,
                    4.15,
                    4.16,
                    4.21,
                    4.26,
                    4.22,
                    4.2,
                    4.22,
                    4.25,
                    4.22,
                    4.28,
                    4.3,
                    4.24,
                    4.22,
                    4.28,
                    4.32,
                    4.37,
                    4.39,
                    4.43,
                    4.43,
                    4.42,
                    4.43,
                    4.44,
                    4.45,
                    4.43,
                    4.39,
                    4.39,
                    4.36,
                    4.36,
                    4.37,
                    4.4,
                    4.39,
                    4.38,
                    4.41,
                    4.41,
                    4.45,
                    4.47,
                    4.48,
                    4.42,
                    4.44,
                    4.47,
                    4.46,
                    4.48,
                    4.44,
                    4.4,
                    4.39,
                    4.42,
                    4.44,
                    4.42,
                    4.44,
                    4.42,
                    4.4,
                    4.39,
                    4.39,
                    4.4,
                    4.41,
                    4.39,
                    4.39,
                    4.42,
                    4.44,
                    4.5,
                    4.51,
                    4.51,
                    4.48,
                    4.47,
                    4.5,
                    4.55,
                    4.56,
                    4.53,
                    4.54,
                    4.57,
                    4.57,
                    4.57,
                    4.59,
                    4.65,
                    4.65,
                    4.63,
                    4.59,
                    4.59,
                    4.63,
                    4.65,
                    4.65,
                    4.7,
                    4.71,
                    4.73,
                    4.71,
                    4.7,
                    4.74,
                    4.72,
                    4.72,
                    4.75,
                    4.73,
                    4.65,
                    4.6,
                    4.65,
                    4.62,
                    4.6,
                    4.54,
                    4.49,
                    4.53,
                    4.49,
                    4.53,
                    4.57,
                    4.61,
                    4.63,
                    4.61,
                    4.59,
                    4.57,
                    4.52,
                    4.46,
                    4.45,
                    4.46,
                    4.45,
                    4.39,
                    4.46,
                    4.5,
                    4.55,
                    4.51,
                    4.47,
                    4.46,
                    4.51,
                    4.53,
                    4.53,
                    4.51,
                    4.51,
                    4.54,
                    4.54,
                    4.54,
                    4.53,
                    4.52,
                    4.5,
                    4.44,
                    4.44,
                    4.42,
                    4.41,
                    4.41,
                    4.37,
                    4.37,
                    4.35,
                    4.35,
                    4.4,
                    4.4,
                    4.39,
                    4.43,
                    4.41,
                    4.44,
                    4.44,
                    4.43,
                    4.43,
                    4.45,
                    4.47,
                    4.46,
                    4.47,
                    4.47,
                    4.5,
                    4.48,
                    4.5,
                    4.48,
                    4.47,
                    4.45,
                    4.45,
                    4.45,
                    4.42,
                    4.44,
                    4.42,
                    4.38,
                    4.37,
                    4.34,
                    4.32,
                    4.31,
                    4.29,
                    4.28,
                    4.3,
                    4.32,
                    4.32,
                    4.3,
                    4.26,
                    4.26,
                    4.22,
                    4.23,
                    4.24,
                    4.22,
                    4.23,
                    4.18,
                    4.15,
                    4.13,
                    4.12,
                    4.1,
                    4.12,
                    4.14,
                    4.16,
                    4.18,
                    4.15,
                    4.13,
                    4.1,
                    4.14,
                    4.2,
                    4.21,
                    4.24,
                    4.25,
                    4.25,
                    4.27,
                    4.28,
                    4.26,
                    4.23,
                    4.24,
                    4.24,
                    4.28,
                    4.3,
                    4.29,
                    4.29,
                    4.26,
                    4.21,
                    4.16,
                    4.15,
                    4.15,
                    4.23,
                    4.25,
                    4.24,
                    4.25,
                    4.24,
                    4.24,
                    4.21,
                    4.21,
                    4.25,
                    4.24,
                    4.18,
                    4.14,
                    4.13,
                    4.14,
                    4.14,
                    4.14,
                    4.14,
                    4.12,
                    4.15,
                    4.15,
                    4.16,
                    4.16,
                    4.15,
                    4.2,
                    4.17,
                    4.17,
                    4.18,
                    4.14,
                    4.17,
                    4.19,
                    4.16,
                    4.17,
                    4.18,
                    4.14,
                    4.09,
                    4.12,
                    4.12,
                    4.11,
                    4.12,
                    4.16,
                    4.19,
                    4.19,
                    4.2,
                    4.2,
                    4.13,
                    4.13,
                    4.14,
                    4.14,
                    4.14,
                    4.15,
                    4.18,
                    4.2,
                    4.21,
                    4.24,
                    4.23,
                    4.26,
                    4.28,
                    4.32,
                    4.35,
                    4.37,
                    4.37,
                    4.38,
                    4.38,
                    4.38,
                    4.37,
                    4.39,
                    4.36,
                    4.34,
                    4.31,
                    4.29,
                    4.35,
                    4.35,
                    4.36,
                    4.36,
                    4.34,
                    4.33,
                    4.33,
                    4.33,
                    4.36,
                    4.39,
                    4.38,
                    4.38,
                    4.3,
                    4.27,
                    4.26,
                    4.23,
                    4.18,
                    4.24,
                    4.22,
                    4.26,
                    4.31,
                    4.27,
                    4.24,
                    4.23,
                    4.25,
                    4.25,
                    4.27,
                    4.28,
                    4.3,
                    4.32,
                    4.33,
                    4.33,
                    4.32,
                    4.31,
                    4.34,
                    4.3,
                    4.25,
                    4.26,
                    4.28,
                    4.3,
                    4.35,
                    4.34,
                    4.32,
                    4.34,
                    4.32,
                    4.3,
                    4.26,
                    4.21,
                    4.2,
                    4.21,
                    4.22,
                    4.21,
                    4.22,
                    4.23,
                    4.23,
                    4.23,
                    4.2,
                    4.21,
                    4.22,
                    4.24,
                    4.23,
                    4.23,
                    4.23,
                    4.24,
                    4.22,
                    4.21,
                    4.22,
                    4.19,
                    4.19,
                    4.23,
                    4.24,
                    4.26,
                    4.29,
                    4.3,
                    4.31,
                    4.3,
                    4.3,
                    4.31,
                    4.3,
                    4.34,
                    4.39,
                    4.41,
                    4.44,
                    4.44,
                    4.51,
                    4.45,
                    4.49,
                    4.55,
                    4.52,
                    4.54,
                    4.52,
                    4.53,
                    4.5,
                    4.54,
                    4.61,
                    4.6,
                    4.57,
                    4.54,
                    4.53,
                    4.53,
                    4.51,
                    4.5,
                    4.54,
                    4.54,
                    4.57,
                    4.58,
                    4.6,
                    4.58,
                    4.58,
                    4.61,
                    4.62,
                    4.61,
                    4.64,
                    4.62,
                    4.63,
                    4.62,
                    4.61,
                    4.62,
                    4.62,
                    4.55,
                    4.53,
                    4.57,
                    4.57,
                    4.53,
                    4.51,
                    4.49,
                    4.46,
                    4.49,
                    4.53,
                    4.48,
                    4.45,
                    4.43,
                    4.4,
                    4.35,
                    4.32,
                    4.29,
                    4.32,
                    4.34,
                    4.37,
                    4.42,
                    4.41,
                    4.38,
                    4.32,
                    4.3,
                    4.28,
                    4.34,
                    4.33,
                    4.33,
                    4.27,
                    4.29,
                    4.24,
                    4.2,
                    4.23,
                    4.26,
                    4.28,
                    4.3,
                    4.3,
                    4.32,
                    4.35,
                    4.4,
                    4.39,
                    4.39,
                    4.4,
                    4.41,
                    4.41,
                    4.43,
                    4.4,
                    4.45,
                    4.46,
                    4.46,
                    4.5,
                    4.5,
                    4.49,
                    4.48,
                    4.5,
                    4.49,
                    4.51,
                    4.48,
                    4.42,
                    4.4,
                    4.35,
                    4.35,
                    4.36,
                    4.36,
                    4.35,
                    4.38,
                    4.45,
                    4.41,
                    4.43,
                    4.4,
                    4.38,
                    4.33,
                    4.37,
                    4.27,
                    4.23,
                    4.22,
                    4.16,
                    4.16,
                    4.22,
                    4.16,
                    4.09,
                    4.05,
                    4.09,
                    4.01,
                    4,
                    4.03,
                    4.02,
                    4.05,
                    4.02,
                    4.04,
                    4.07,
                    4.01,
                    3.97,
                    3.95,
                    3.98,
                    4.02,
                    4.03,
                    3.95,
                    4,
                    4,
                    3.98,
                    3.9,
                    3.89,
                    3.86,
                    3.85,
                    3.9,
                    3.95,
                    3.97,
                    4.01,
                    3.97,
                    3.9,
                    3.9,
                    3.9,
                    3.86,
                    3.83,
                    3.79,
                    3.73,
                    3.7,
                    3.73,
                    3.71,
                    3.64,
                    3.64,
                    3.57,
                    3.53,
                    3.55,
                    3.6,
                    3.54,
                    3.41,
                    3.58,
                    3.63,
                    3.62,
                    3.61,
                    3.71,
                    3.75,
                    3.7,
                    3.67,
                    3.68,
                    3.62,
                    3.58,
                    3.65,
                    3.56,
                    3.55,
                    3.59,
                    3.59,
                    3.65,
                    3.67,
                    3.64,
                    3.75,
                    3.78,
                    3.71,
                    3.71,
                    3.74,
                    3.79,
                    3.81,
                    3.8,
                    3.71,
                    3.66,
                    3.72,
                    3.76,
                    3.72,
                    3.68,
                    3.61,
                    3.69,
                    3.7,
                    3.68,
                    3.63,
                    3.4,
                    3.52,
                    3.45,
                    3.45,
                    3.45,
                    3.54,
                    3.52,
                    3.49,
                    3.53,
                    3.52,
                    3.44,
                    3.54,
                    3.62,
                    3.64,
                    3.54,
                    3.57,
                    3.54,
                    3.5,
                    3.51,
                    3.5,
                    3.51,
                    3.58,
                    3.64,
                    3.69,
                    3.7,
                    3.77,
                    3.74,
                    3.75,
                    3.78,
                    3.8,
                    3.81,
                    3.81,
                    3.72,
                    3.78,
                    3.89,
                    3.91,
                    3.93,
                    3.93,
                    3.87,
                    3.86,
                    3.84,
                    3.89,
                    3.92,
                    3.89,
                    3.93,
                    3.97,
                    3.94,
                    3.96,
                    3.99,
                    3.92,
                    3.92,
                    3.95,
                    3.92,
                    3.97,
                    4.01,
                    3.99,
                    3.95,
                    3.94,
                    3.96,
                    3.94,
                    3.97,
                    3.98,
                    3.95,
                    4,
                    3.99,
                    4.05,
                    4.01,
                    4.01,
                    3.98,
                    3.97,
                    3.93,
                    3.9,
                    3.95,
                    3.93,
                    3.87,
                    3.88,
                    3.91,
                    3.95,
                    3.95,
                    3.97,
                    3.9,
                    3.81,
                    3.8,
                    3.8,
                    3.88,
                    3.85,
                    3.81,
                    3.8,
                    3.82,
                    3.83,
                    3.81,
                    3.8,
                    3.8,
                    3.77,
                    3.74,
                    3.68,
                    3.64,
                    3.68,
                    3.59,
                    3.54,
                    3.51,
                    3.46,
                    3.52,
                    3.47,
                    3.46,
                    3.47,
                    3.45,
                    3.44,
                    3.41,
                    3.44,
                    3.37,
                    3.34,
                    3.34,
                    3.37,
                    3.38,
                    3.35,
                    3.39,
                    3.38,
                    3.33,
                    3.33,
                    3.32,
                    3.22,
                    3.13,
                    3.03,
                    3.08,
                    3.17,
                    3.07,
                    3.09,
                    3.06,
                    3.08,
                    2.85,
                    2.69,
                    2.62,
                    2.73,
                    3.11,
                    3.23,
                    3.14,
                    3.03,
                    3.05,
                    3.01,
                    2.74,
                    2.78,
                    2.67,
                    2.57,
                    2.47,
                    2.25,
                    2.21,
                    2.12,
                    2.24,
                    2.26,
                    2.39,
                    2.48,
                    2.29,
                    2.18,
                    2.18,
                    2.24,
                    2.26,
                    2.1,
                    1.98,
                    1.8,
                    1.74,
                    1.82,
                    1.83,
                    2.01,
                    2.01,
                    1.93,
                    1.89,
                    2,
                    1.86,
                    1.92,
                    1.91,
                    1.9,
                    1.82,
                    1.75,
                    1.69,
                    1.61,
                    1.43,
                    1.21,
                    0.85,
                    1.03,
                    1.1,
                    1.2,
                    1.2,
                    1.2,
                    1.24,
                    1.2,
                    1.16,
                    1.2,
                    1.17,
                    1.13,
                    1.08,
                    1.05,
                    1.12,
                    1.16,
                    1.04,
                    1.04,
                    1,
                    1.09,
                    0.99,
                    0.95,
                    0.96,
                    0.93,
                    0.9,
                    0.91,
                    0.91,
                    0.93,
                    0.9,
                    0.93,
                    0.95,
                    1.02,
                    1.12,
                    1.26,
                    1.55,
                    1.46,
                    1.42,
                    1.38,
                    1.37,
                    1.28,
                    1.25,
                    1.34,
                    1.38,
                    1.41,
                    1.4,
                    1.42,
                    1.48,
                    1.5,
                    1.53,
                    1.67,
                    1.85,
                    1.9,
                    1.8,
                    1.91,
                    2,
                    1.97,
                    2.08,
                    2.23,
                    2.11,
                    2.05,
                    2.03,
                    2.08,
                    2.09,
                    1.93,
                    1.96,
                    1.99,
                    1.93,
                    1.89,
                    1.82,
                    1.92,
                    1.91,
                    1.92,
                    1.79,
                    1.78,
                    1.86,
                    1.8,
                    1.77,
                    1.75,
                    1.83,
                    1.85,
                    1.92,
                    2,
                    2.1,
                    2.14,
                    2.13,
                    2.23,
                    2.17,
                    2.26,
                    2.34,
                    2.36,
                    2.42,
                    2.38,
                    2.27,
                    2.18,
                    2.23,
                    2.33,
                    2.47,
                    2.53,
                    2.43,
                    2.34,
                    2.39,
                    2.43,
                    2.43,
                    2.41,
                    2.39,
                    2.35,
                    2.41,
                    2.38,
                    2.37,
                    2.44,
                    2.48,
                    2.55,
                    2.49,
                    2.52,
                    2.55,
                    2.53,
                    2.53,
                    2.58,
                    2.59,
                    2.64,
                    2.81,
                    2.79,
                    2.7,
                    2.69,
                    2.61,
                    2.55,
                    2.63,
                    2.72,
                    2.77,
                    2.77,
                    2.86,
                    2.88,
                    2.89,
                    2.95,
                    3.11,
                    3.05,
                    3.04,
                    3.19,
                    3.18,
                    3.11,
                    3.17,
                    3.3,
                    3.3,
                    3.25,
                    3.33,
                    3.29,
                    3.18,
                    3.07,
                    2.98,
                    2.94,
                    3.03,
                    3.06,
                    2.99,
                    2.93,
                    2.94,
                    2.91,
                    2.87,
                    2.84,
                    2.88,
                    2.87,
                    2.81,
                    2.83,
                    2.77,
                    2.73,
                    2.62,
                    2.67,
                    2.59,
                    2.56,
                    2.7,
                    2.83,
                    2.84,
                    2.93,
                    2.96,
                    2.88,
                    2.9,
                    3.03,
                    3.05,
                    3.06,
                    3.01,
                    2.98,
                    3.02,
                    2.93,
                    3.07,
                    3.09,
                    3.11,
                    3.11,
                    3.19,
                    3.16,
                    3.08,
                    3.07,
                    3,
                    2.95,
                    2.86,
                    2.92,
                    2.91,
                    2.97,
                    3.01,
                    2.94,
                    2.88,
                    2.89,
                    2.87,
                    2.85,
                    2.78,
                    2.79,
                    2.71,
                    2.77,
                    2.87,
                    2.91,
                    2.96,
                    2.95,
                    2.89,
                    2.88,
                    2.88,
                    2.93,
                    2.99,
                    2.92,
                    2.93,
                    2.92,
                    2.92,
                    2.9,
                    2.82,
                    2.79,
                    2.74,
                    2.76,
                    2.77,
                    2.69,
                    2.68,
                    2.65,
                    2.71,
                    2.74,
                    2.78,
                    2.89,
                    2.87,
                    2.91,
                    2.94,
                    2.99,
                    2.97,
                    3.01,
                    2.98,
                    2.99,
                    2.97,
                    2.99,
                    3.01,
                    2.95,
                    2.9,
                    2.94,
                    2.94,
                    2.93,
                    2.95,
                    3.04,
                    3.07,
                    3.08,
                    3.14,
                    3.11,
                    3.14,
                    3.09,
                    3.06,
                    3.03,
                    3.01,
                    3.02,
                    3.01,
                    3.01,
                    3.01,
                    2.94,
                    2.94,
                    2.93,
                    2.83,
                    2.86,
                    2.94,
                    2.96,
                    3,
                    3.02,
                    3.01,
                    2.96,
                    2.95,
                    2.98,
                    3.06,
                    3.09,
                    3.13,
                    3.17,
                    3.11,
                    3.13,
                    3.22,
                    3.26,
                    3.24,
                    3.27,
                    3.27,
                    3.3,
                    3.28,
                    3.29,
                    3.31,
                    3.31,
                    3.3,
                    3.27,
                    3.32,
                    3.37,
                    3.37,
                    3.37,
                    3.29,
                    3.27,
                    3.27,
                    3.25,
                    3.25,
                    3.27,
                    3.25,
                    3.22,
                    3.19,
                    3.2,
                    3.18,
                    3.14,
                    3.17,
                    3.16,
                    3.2,
                    3.22,
                    3.24,
                    3.14,
                    3.05,
                    3.05,
                    3.11,
                    3.12,
                    3.12,
                    3.09,
                    3.09,
                    3.09,
                    3.15,
                    3.17,
                    3.11,
                    3.09,
                    3.04,
                    3.03,
                    3,
                    2.98,
                    2.96,
                    2.98,
                    3,
                    2.99,
                    3.04,
                    3.08,
                    3.07,
                    3.08,
                    3.09,
                    3.1,
                    3.11,
                    3.09,
                    3.11,
                    3.1,
                    3.09,
                    3.06,
                    3.08,
                    3.16,
                    3.18,
                    3.16,
                    3.16,
                    3.17,
                    3.18,
                    3.2,
                    3.23,
                    3.29,
                    3.3,
                    3.28,
                    3.27,
                    3.26,
                    3.26,
                    3.24,
                    3.27,
                    3.28,
                    3.24,
                    3.23,
                    3.25,
                    3.27,
                    3.27,
                    3.31,
                    3.32,
                    3.27,
                    3.32,
                    3.33,
                    3.29,
                    3.3,
                    3.22,
                    3.15,
                    3.01,
                    3,
                    3.1,
                    3.09,
                    3.13,
                    3.11,
                    3.05,
                    3.03,
                    2.94,
                    2.93,
                    2.77,
                    2.81,
                    2.83,
                    2.75,
                    2.78,
                    2.94,
                    2.92,
                    2.89,
                    2.83,
                    2.85,
                    2.91,
                    2.79,
                    2.72,
                    2.74,
                    2.7,
                    2.78,
                    2.75,
                    2.76,
                    2.78,
                    2.78,
                    2.75,
                    2.75,
                    2.79,
                    2.74,
                    2.68,
                    2.67,
                    2.65,
                    2.59,
                    2.54,
                    2.51,
                    2.47,
                    2.46,
                    2.49,
                    2.44,
                    2.45,
                    2.51,
                    2.57,
                    2.57,
                    2.61,
                    2.58,
                    2.52,
                    2.43,
                    2.43,
                    2.41,
                    2.4,
                    2.43,
                    2.47,
                    2.48,
                    2.5,
                    2.49,
                    2.48,
                    2.45,
                    2.52,
                    2.52,
                    2.52,
                    2.5,
                    2.43,
                    2.44,
                    2.42,
                    2.35,
                    2.31,
                    2.27,
                    2.21,
                    2.22,
                    2.21,
                    2.2,
                    2.21,
                    2.18,
                    2.11,
                    2.15,
                    2.13,
                    2.21,
                    2.14,
                    2.08,
                    2.11,
                    2.15,
                    2.22,
                    2.25,
                    2.21,
                    2.21,
                    2.3,
                    2.35,
                    2.38,
                    2.32,
                    2.34,
                    2.35,
                    2.34,
                    2.33,
                    2.35,
                    2.38,
                    2.31,
                    2.32,
                    2.26,
                    2.27,
                    2.29,
                    2.29,
                    2.3,
                    2.28,
                    2.35,
                    2.41,
                    2.37,
                    2.44,
                    2.45,
                    2.46,
                    2.52,
                    2.62,
                    2.63,
                    2.57,
                    2.56,
                    2.54,
                    2.57,
                    2.59,
                    2.63,
                    2.66,
                    2.66,
                    2.65,
                    2.63,
                    2.67,
                    2.64,
                    2.63,
                    2.56,
                    2.61,
                    2.63,
                    2.66,
                    2.67,
                    2.7,
                    2.68,
                    2.72,
                    2.65,
                    2.7,
                    2.73,
                    2.73,
                    2.72,
                    2.69,
                    2.77,
                    2.78,
                    2.76,
                    2.76,
                    2.74,
                    2.84,
                    2.88,
                    2.89,
                    2.86,
                    2.95,
                    2.96,
                    2.92,
                    2.96,
                    2.99,
                    3.06,
                    3.14,
                    3.11,
                    3.08,
                    3.07,
                    3.06,
                    3.1,
                    3.11,
                    3.1,
                    3.06,
                    3.1,
                    3.06,
                    3.08,
                    3.05,
                    3.09,
                    3.13,
                    3.23,
                    3.22,
                    3.16,
                    3.15,
                    3.21,
                    3.23,
                    3.17,
                    3.17,
                    3.16,
                    3.2,
                    3.2,
                    3.19,
                    3.09,
                    3.11,
                    3.09,
                    3.14,
                    3.13,
                    3.12,
                    3.17,
                    3.21,
                    3.24,
                    3.25,
                    3.28,
                    3.27,
                    3.33,
                    3.28,
                    3.28,
                    3.25,
                    3.27,
                    3.26,
                    3.24,
                    3.25,
                    3.3,
                    3.29,
                    3.24,
                    3.28,
                    3.28,
                    3.25,
                    3.26,
                    3.27,
                    3.31,
                    3.36,
                    3.35,
                    3.37,
                    3.41,
                    3.35,
                    3.28,
                    3.26,
                    3.18,
                    3.16,
                    3.09,
                    3.19,
                    3.21,
                    3.25,
                    3.22,
                    3.22,
                    3.29,
                    3.26,
                    3.27,
                    3.3,
                    3.28,
                    3.34,
                    3.36,
                    3.37,
                    3.4,
                    3.41,
                    3.4,
                    3.45,
                    3.44,
                    3.39,
                    3.38,
                    3.41,
                    3.36,
                    3.32,
                    3.32,
                    3.34,
                    3.33,
                    3.33,
                    3.32,
                    3.28,
                    3.29,
                    3.27,
                    3.27,
                    3.26,
                    3.24,
                    3.23,
                    3.17,
                    3.13,
                    3.14,
                    3.16,
                    3.1,
                    3.11,
                    3.05,
                    2.96,
                    2.93,
                    2.99,
                    2.94,
                    2.92,
                    2.89,
                    2.9,
                    2.9,
                    2.87,
                    2.87,
                    2.88,
                    2.87,
                    2.81,
                    2.83,
                    2.8,
                    2.79,
                    2.79,
                    2.75,
                    2.77,
                    2.72,
                    2.73,
                    2.82,
                    2.74,
                    2.69,
                    2.67,
                    2.67,
                    2.7,
                    2.69,
                    2.69,
                    2.69,
                    2.74,
                    2.81,
                    2.89,
                    2.91,
                    2.93,
                    2.96,
                    2.9,
                    2.89,
                    2.91,
                    2.83,
                    2.77,
                    2.74,
                    2.74,
                    2.74,
                    2.74,
                    2.77,
                    2.76,
                    2.76,
                    2.83,
                    2.83,
                    2.88,
                    2.85,
                    2.86,
                    2.85,
                    2.79,
                    2.78,
                    2.69,
                    2.62,
                    2.52,
                    2.55,
                    2.43,
                    2.43,
                    2.43,
                    2.45,
                    2.36,
                    2.39,
                    2.36,
                    2.31,
                    2.16,
                    2.12,
                    2.14,
                    2.15,
                    2.18,
                    2.24,
                    2.2,
                    2.24,
                    2.24,
                    2.25,
                    2.24,
                    2.16,
                    2.12,
                    2.04,
                    2.09,
                    2.1,
                    2.03,
                    2.02,
                    2.02,
                    1.97,
                    2.05,
                    2.07,
                    1.99,
                    1.98,
                    1.92,
                    1.75,
                    1.8,
                    1.8,
                    1.91,
                    1.9,
                    1.9,
                    1.81,
                    1.73,
                    1.77,
                    1.77,
                    1.89,
                    1.98,
                    1.98,
                    2.04,
                    2.07,
                    2.05,
                    2.07,
                    2.05,
                    2.05,
                    2.02,
                    2.01,
                    2.07,
                    2.11,
                    2.08,
                    2.17,
                    2.31,
                    2.28,
                    2.17,
                    2.11,
                    2.13,
                    2.14,
                    2.18,
                    2.19,
                    2.17,
                    2.07,
                    2.1,
                    2.1,
                    2.09,
                    2.05,
                    1.95,
                    1.95,
                    1.98,
                    1.89,
                    1.89,
                    1.87,
                    1.87,
                    1.91,
                    1.96,
                    2.01,
                    2.03,
                    2.1,
                    2.08,
                    2.09,
                    2.09,
                    2.06,
                    2,
                    2.03,
                    2.05,
                    2,
                    1.94,
                    1.89,
                    1.87,
                    1.86,
                    1.96,
                    2,
                    1.98,
                    2.04,
                    2.04,
                    2.01,
                    1.95,
                    1.93,
                    1.92,
                    1.93,
                    1.98,
                    2.04,
                    2.07,
                    2.03,
                    2.05,
                    2.02,
                    1.98,
                    2,
                    1.99,
                    1.97,
                    2,
                    2.04,
                    2.13,
                    2.12,
                    2.14,
                    2.16,
                    2.19,
                    2.18,
                    2.16,
                    2.15,
                    2.15,
                    2.18,
                    2.2,
                    2.24,
                    2.27,
                    2.28,
                    2.3,
                    2.3,
                    2.32,
                    2.33,
                    2.3,
                    2.33,
                    2.37,
                    2.39,
                    2.4,
                    2.43,
                    2.46,
                    2.46,
                    2.45,
                    2.41,
                    2.43,
                    2.43,
                    2.42,
                    2.39,
                    2.37,
                    2.31,
                    2.32,
                    2.36,
                    2.42,
                    2.41,
                    2.48,
                    2.54,
                    2.55,
                    2.57,
                    2.61,
                    2.62,
                    2.56,
                    2.55,
                    2.52,
                    2.5,
                    2.47,
                    2.46,
                    2.41,
                    2.41,
                    2.44,
                    2.46,
                    2.39,
                    2.36,
                    2.29,
                    2.27,
                    2.25,
                    2.31,
                    2.31,
                    2.28,
                    2.25,
                    2.26,
                    2.27,
                    2.24,
                    2.24,
                    2.21,
                    2.23,
                    2.23,
                    2.23,
                    2.23,
                    2.22,
                    2.22,
                    2.21,
                    2.2,
                    2.15,
                    2.1,
                    2.09,
                    2.04,
                    2.06,
                    2.02,
                    2.01,
                    2.01,
                    1.97,
                    1.98,
                    1.97,
                    1.99,
                    2.02,
                    1.98,
                    2,
                    1.96,
                    1.97,
                    1.99,
                    1.93,
                    1.91,
                    1.84,
                    1.84,
                    1.91,
                    1.95,
                    1.95,
                    1.94,
                    1.95,
                    1.97,
                    1.92,
                    1.93,
                    1.93,
                    1.95,
                    1.98,
                    1.98,
                    1.93,
                    1.94,
                    1.91,
                    1.92,
                    1.91,
                    1.88,
                    1.93,
                    1.92,
                    1.94,
                    1.96,
                    1.95,
                    1.91,
                    1.87,
                    1.86,
                    1.86,
                    1.81,
                    1.83,
                    1.83,
                    1.83,
                    1.82,
                    1.84,
                    1.86,
                    1.81,
                    1.78,
                    1.76,
                    1.77,
                    1.87,
                    1.89,
                    1.91,
                    1.94,
                    1.91,
                    1.96,
                    1.97,
                    2.03,
                    2.03,
                    2.05,
                    2.03,
                    2,
                    2.02,
                    2.04,
                    2.04,
                    2.04,
                    2.03,
                    2.03,
                    2.01,
                    2.03,
                    2.02,
                    2.01,
                    2,
                    1.99,
                    1.94,
                    1.91,
                    1.9,
                    1.92,
                    1.93,
                    1.96,
                    2.05,
                    2.07,
                    2.08,
                    2.1,
                    2.18,
                    2.37,
                    2.35,
                    2.32,
                    2.3,
                    2.24,
                    2.22,
                    2.17,
                    2.15,
                    2.1,
                    2.13,
                    2.11,
                    2.11,
                    2.15,
                    2.16,
                    2.22,
                    2.25,
                    2.24,
                    2.21,
                    2.17,
                    2.14,
                    2.12,
                    2.12,
                    2.14,
                    2.19,
                    2.25,
                    2.22,
                    2.25,
                    2.21,
                    2.18,
                    2.21,
                    2.16,
                    2.16,
                    2.16,
                    2.2,
                    2.22,
                    2.21,
                    2.18,
                    2.21,
                    2.19,
                    2.16,
                    2.13,
                    2.12,
                    2.1,
                    2.09,
                    2.1,
                    2.12,
                    2.12,
                    2.15,
                    2.14,
                    2.16,
                    2.17,
                    2.15,
                    2.14,
                    2.12,
                    2.13,
                    2.15,
                    2.17,
                    2.16,
                    2.17,
                    2.18,
                    2.22,
                    2.23,
                    2.23,
                    2.25,
                    2.29,
                    2.28,
                    2.31,
                    2.34,
                    2.36,
                    2.36,
                    2.34,
                    2.34,
                    2.34,
                    2.35,
                    2.34,
                    2.32,
                    2.34,
                    2.34,
                    2.39,
                    2.41,
                    2.42,
                    2.42,
                    2.37,
                    2.39,
                    2.42,
                    2.41,
                    2.41,
                    2.41,
                    2.37,
                    2.39,
                    2.42,
                    2.41,
                    2.41,
                    2.4,
                    2.43,
                    2.41,
                    2.41,
                    2.43,
                    2.45,
                    2.47,
                    2.47,
                    2.47,
                    2.45,
                    2.43,
                    2.43,
                    2.43,
                    2.44,
                    2.46,
                    2.48,
                    2.46,
                    2.44,
                    2.43,
                    2.45,
                    2.43,
                    2.41,
                    2.4,
                    2.36,
                    2.35,
                    2.37,
                    2.38,
                    2.35,
                    2.36,
                    2.36,
                    2.38,
                    2.39,
                    2.4,
                    2.43,
                    2.41,
                    2.39,
                    2.42,
                    2.39,
                    2.36,
                    2.36,
                    2.38,
                    2.35,
                    2.36,
                    2.36,
                    2.35,
                    2.32,
                    2.32,
                    2.31,
                    2.31,
                    2.3,
                    2.26,
                    2.25,
                    2.23,
                    2.24,
                    2.22,
                    2.24,
                    2.25,
                    2.21,
                    2.16,
                    2.15,
                    2.12,
                    2.04,
                    2.1,
                    2.13,
                    2.13,
                    2.14,
                    2.16,
                    2.14,
                    2.12,
                    2.1,
                    2.05,
                    2.04,
                    2.09,
                    2.11,
                    2.14,
                    2.13,
                    2.15,
                    2.2,
                    2.2,
                    2.21,
                    2.16,
                    2.13,
                    2.16,
                    2.19,
                    2.19,
                    2.21,
                    2.18,
                    2.17,
                    2.15,
                    2.21,
                    2.15,
                    2.12,
                    2.14,
                    2.13,
                    2.16,
                    2.09,
                    2.09,
                    2.1,
                    2.08,
                    2.07,
                    2.04,
                    2.04,
                    2.01,
                    2.01,
                    2.04,
                    2.06,
                    2.01,
                    2.01,
                    1.98,
                    2.02,
                    2.01,
                    2.02,
                    2.04,
                    2.05,
                    2.08,
                    2.07,
                    2.07,
                    2.17,
                    2.13,
                    2.11,
                    2.12,
                    2.11,
                    2.13,
                    2.14,
                    2.16,
                    2.16,
                    2.17,
                    2.2,
                    2.21,
                    2.2,
                    2.21,
                    2.21,
                    2.19,
                    2.2,
                    2.21,
                    2.25,
                    2.31,
                    2.27,
                    2.3,
                    2.32,
                    2.29,
                    2.3,
                    2.31,
                    2.33,
                    2.35,
                    2.32,
                    2.31,
                    2.32,
                    2.32,
                    2.33,
                    2.32,
                    2.32,
                    2.33,
                    2.34,
                    2.32,
                    2.33,
                    2.29,
                    2.29,
                    2.29,
                    2.32,
                    2.34,
                    2.37,
                    2.35,
                    2.34,
                    2.39,
                    2.39,
                    2.38,
                    2.36,
                    2.38,
                    2.4,
                    2.39,
                    2.41,
                    2.42,
                    2.42,
                    2.37,
                    2.35,
                    2.36,
                    2.34,
                    2.33,
                    2.35,
                    2.35,
                    2.34,
                    2.36,
                    2.34,
                    2.34,
                    2.38,
                    2.36,
                    2.38,
                    2.38,
                    2.39,
                    2.36,
                    2.33,
                    2.34,
                    2.34,
                    2.31,
                    2.29,
                    2.3,
                    2.31,
                    2.32,
                    2.3,
                    2.32,
                    2.29,
                    2.29,
                    2.28,
                    2.3,
                    2.34,
                    2.31,
                    2.34,
                    2.34,
                    2.36,
                    2.33,
                    2.33,
                    2.34,
                    2.34,
                    2.34,
                    2.34,
                    2.36,
                    2.37,
                    2.37,
                    2.36,
                    2.37,
                    2.38,
                    2.37,
                    2.38,
                    2.37,
                    2.36,
                    2.38,
                    2.37,
                    2.37,
                    2.34,
                    2.37,
                    2.37,
                    2.39,
                    2.4,
                    2.38,
                    2.4,
                    2.39,
                    2.41,
                    2.43,
                    2.46,
                    2.45,
                    2.47,
                    2.47,
                    2.48,
                    2.51,
                    2.51,
                    2.51,
                    2.51,
                    2.48,
                    2.48,
                    2.53,
                    2.55,
                    2.5,
                    2.48,
                    2.49,
                    2.51,
                    2.49,
                    2.49,
                    2.49,
                    2.48,
                    2.51,
                    2.45,
                    2.38,
                    2.38,
                    2.38,
                    2.35,
                    2.36,
                    2.33,
                    2.3,
                    2.28,
                    2.29,
                    2.32,
                    2.33,
                    2.34,
                    2.36,
                    2.38,
                    2.36,
                    2.35,
                    2.36,
                    2.36,
                    2.35,
                    2.33,
                    2.33,
                    2.34,
                    2.34,
                    2.34,
                    2.34,
                    2.35,
                    2.34,
                    2.36,
                    2.37,
                    2.4,
                    2.42,
                    2.39,
                    2.39,
                    2.35,
                    2.31,
                    2.32,
                    2.34,
                    2.35,
                    2.35,
                    2.32,
                    2.33,
                    2.33,
                    2.33,
                    2.31,
                    2.3,
                    2.31,
                    2.32,
                    2.34,
                    2.38,
                    2.37,
                    2.35,
                    2.32,
                    2.3,
                    2.29,
                    2.28,
                    2.27,
                    2.27,
                    2.26,
                    2.29,
                    2.35,
                    2.35,
                    2.35,
                    2.33,
                    2.33,
                    2.33,
                    2.31,
                    2.3,
                    2.29,
                    2.28,
                    2.29,
                    2.29,
                    2.28,
                    2.26,
                    2.24,
                    2.26,
                    2.26,
                    2.26,
                    2.25,
                    2.25,
                    2.25,
                    2.26,
                    2.25,
                    2.24,
                    2.25,
                    2.29,
                    2.29,
                    2.29,
                    2.28,
                    2.28,
                    2.3,
                    2.29,
                    2.29,
                    2.31,
                    2.3,
                    2.3,
                    2.32,
                    2.34,
                    2.35,
                    2.35,
                    2.32,
                    2.32,
                    2.34,
                    2.37,
                    2.37,
                    2.39,
                    2.41,
                    2.42,
                    2.41,
                    2.39,
                    2.39,
                    2.39,
                    2.38,
                    2.38,
                    2.4,
                    2.42,
                    2.42,
                    2.41,
                    2.38,
                    2.38,
                    2.38,
                    2.36,
                    2.37,
                    2.36,
                    2.35,
                    2.32,
                    2.33,
                    2.34,
                    2.33,
                    2.33,
                    2.37,
                    2.39,
                    2.39,
                    2.39,
                    2.4,
                    2.41,
                    2.37,
                    2.35,
                    2.35,
                    2.34,
                    2.32,
                    2.33,
                    2.32,
                    2.32,
                    2.3,
                    2.29,
                    2.26,
                    2.26,
                    2.26,
                    2.26,
                    2.27,
                    2.26,
                    2.26,
                    2.26,
                    2.26,
                    2.24,
                    2.26,
                    2.27,
                    2.31,
                    2.33,
                    2.32,
                    2.32,
                    2.3,
                    2.31,
                    2.31,
                    2.35,
                    2.36,
                    2.34,
                    2.33,
                    2.29,
                    2.28,
                    2.28,
                    2.24,
                    2.25,
                    2.26,
                    2.22,
                    2.2,
                    2.17,
                    2.18,
                    2.14,
                    2.14,
                    2.13,
                    2.13,
                    2.1,
                    2.1,
                    2.11,
                    2.09,
                    2.1,
                    2.03,
                    1.97,
                    1.98,
                    2.02,
                    2.01,
                    2.01,
                    2.02,
                    2.03,
                    2.02,
                    2.02,
                    2.04,
                    2.05,
                    2.04,
                    2.08,
                    2.1,
                    2.11,
                    2.11,
                    2.13,
                    2.11,
                    2.14,
                    2.13,
                    2.12,
                    2.09,
                    2.1,
                    2.08,
                    2.05,
                    2.06,
                    2.08,
                    2.08,
                    2.1,
                    2.08,
                    2.09,
                    2.08,
                    2.04,
                    2.05,
                    2.07,
                    2.07,
                    2.06,
                    2.08,
                    2.04,
                    2.03,
                    2,
                    1.99,
                    1.92,
                    1.91,
                    1.93,
                    1.98,
                    1.99,
                    2.02,
                    2.04,
                    2.06,
                    2.04,
                    2.03,
                    2.05,
                    2.01,
                    1.99,
                    2.02,
                    2.03,
                    2.03,
                    1.98,
                    1.88,
                    1.9,
                    1.93,
                    1.9,
                    1.85,
                    1.82,
                    1.82,
                    1.78,
                    1.82,
                    1.82,
                    1.81,
                    1.84,
                    1.85,
                    1.82,
                    1.84,
                    1.85,
                    1.79,
                    1.81,
                    1.78,
                    1.8,
                    1.88,
                    1.88,
                    1.88,
                    1.93,
                    1.94,
                    1.94,
                    1.93,
                    1.9,
                    1.93,
                    1.94,
                    1.97,
                    1.96,
                    2.01,
                    2.01,
                    1.98,
                    1.96,
                    1.97,
                    2.05,
                    2.09,
                    2.15,
                    2.17,
                    2.17,
                    2.13,
                    2.12,
                    2.05,
                    2.02,
                    2.01,
                    2,
                    2,
                    1.95,
                    1.94,
                    1.97,
                    2.05,
                    2.02,
                    2.01,
                    2,
                    2.03,
                    2.03,
                    1.98,
                    1.94,
                    1.97,
                    1.98,
                    2,
                    1.99,
                    2,
                    2.01,
                    2,
                    2.01,
                    1.99,
                    2,
                    2,
                    2.02,
                    2.05,
                    2.09,
                    2.08,
                    2.07,
                    2.1,
                    2.1,
                    2.1,
                    2.12,
                    2.13,
                    2.15,
                    2.17,
                    2.2,
                    2.18,
                    2.18,
                    2.17,
                    2.15,
                    2.15,
                    2.19,
                    2.17,
                    2.17,
                    2.14,
                    2.09,
                    2.11,
                    2.14,
                    2.14,
                    2.1,
                    2.14,
                    2.15,
                    2.12,
                    2.1,
                    2.1,
                    2.11,
                    2.13,
                    2.17,
                    2.2,
                    2.18,
                    2.22,
                    2.21,
                    2.22,
                    2.21,
                    2.17,
                    2.2,
                    2.19,
                    2.17,
                    2.18,
                    2.16,
                    2.12,
                    2.2,
                    2.24,
                    2.21,
                    2.22,
                    2.23,
                    2.13,
                    2.13,
                    2.17,
                    2.16,
                    2.15,
                    2.09,
                    2.07,
                    1.98,
                    2.04,
                    2.08,
                    2.08,
                    2.05,
                    2.01,
                    2.02,
                    2.01,
                    2.01,
                    2,
                    1.97,
                    1.91,
                    1.89,
                    1.84,
                    1.84,
                    1.85,
                    1.85,
                    1.81,
                    1.75,
                    1.77,
                    1.79,
                    1.77,
                    1.75,
                    1.77,
                    1.75,
                    1.73,
                    1.73,
                    1.74,
                    1.7,
                    1.7,
                    1.68,
                    1.65,
                    1.59,
                    1.52,
                    1.59,
                    1.63,
                    1.65,
                    1.69,
                    1.71,
                    1.65,
                    1.65,
                    1.62,
                    1.61,
                    1.62,
                    1.7,
                    1.74,
                    1.77,
                    1.75,
                    1.72,
                    1.73,
                    1.77,
                    1.74,
                    1.72,
                    1.72,
                    1.68,
                    1.67,
                    1.6,
                    1.57,
                    1.47,
                    1.46,
                    1.48,
                    1.52,
                    1.53,
                    1.55,
                    1.56,
                    1.62,
                    1.68,
                    1.64,
                    1.65,
                    1.61,
                    1.57,
                    1.6,
                    1.6,
                    1.56,
                    1.6,
                    1.58,
                    1.6,
                    1.63,
                    1.6,
                    1.58,
                    1.58,
                    1.66,
                    1.66,
                    1.7,
                    1.73,
                    1.73,
                    1.71,
                    1.76,
                    1.79,
                    1.76,
                    1.73,
                    1.73,
                    1.69,
                    1.68,
                    1.7,
                    1.73,
                    1.73,
                    1.75,
                    1.74,
                    1.73,
                    1.73,
                    1.73,
                    1.72,
                    1.72,
                    1.69,
                    1.7,
                    1.77,
                    1.75,
                    1.7,
                    1.69,
                    1.67,
                    1.66,
                    1.59,
                    1.59,
                    1.63,
                    1.63,
                    1.61,
                    1.61,
                    1.58,
                    1.61,
                    1.65,
                    1.65,
                    1.64,
                    1.64,
                    1.67,
                    1.67,
                    1.67,
                    1.67,
                    1.66,
                    1.67,
                    1.6,
                    1.54,
                    1.53,
                    1.51,
                    1.48,
                    1.46,
                    1.48,
                    1.41,
                    1.38,
                    1.42,
                    1.33,
                    1.33,
                    1.35,
                    1.32,
                    1.31,
                    1.35,
                    1.33,
                    1.3,
                    1.28,
                    1.2,
                    1.19,
                    1.19,
                    1.16,
                    1.07,
                    1.02,
                    1.02,
                    1.01,
                    1.08,
                    1.11,
                    1.16,
                    1.19,
                    1.14,
                    1.15,
                    1.17,
                    1.19,
                    1.24,
                    1.25,
                    1.28,
                    1.29,
                    1.36,
                    1.4,
                    1.43,
                    1.44,
                    1.45,
                    1.4,
                    1.42,
                    1.43,
                    1.49,
                    1.48,
                    1.45,
                    1.48,
                    1.49,
                    1.52,
                    1.56,
                    1.56,
                    1.5,
                    1.46,
                    1.47,
                    1.45,
                    1.46,
                    1.49,
                    1.47,
                    1.46,
                    1.44,
                    1.39,
                    1.4,
                    1.38,
                    1.38,
                    1.38,
                    1.42,
                    1.43,
                    1.44,
                    1.41,
                    1.42,
                    1.47,
                    1.5,
                    1.51,
                    1.55,
                    1.56,
                    1.57,
                    1.54,
                    1.54,
                    1.53,
                    1.49,
                    1.44,
                    1.43,
                    1.43,
                    1.4,
                    1.36,
                    1.39,
                    1.38,
                    1.38,
                    1.34,
                    1.37,
                    1.4,
                    1.43,
                    1.41,
                    1.41,
                    1.4,
                    1.43,
                    1.45,
                    1.44,
                    1.46,
                    1.46,
                    1.44,
                    1.44,
                    1.41,
                    1.35,
                    1.38,
                    1.4,
                    1.4,
                    1.38,
                    1.34,
                    1.28,
                    1.26,
                    1.27,
                    1.23,
                    1.25,
                    1.29,
                    1.27,
                    1.29,
                    1.37,
                    1.23,
                    1.1,
                    1.11,
                    1.18,
                    1.18,
                    1.19,
                    1.18,
                    1.13,
                    1.13,
                    1.14,
                    1.14,
                    1.19,
                    1.26,
                    1.26,
                    1.29,
                    1.33,
                    1.34,
                    1.31,
                    1.32,
                    1.31,
                    1.36,
                    1.35,
                    1.35,
                    1.35,
                    1.37,
                    1.34,
                    1.35,
                    1.31,
                    1.33,
                    1.37,
                    1.43,
                    1.46,
                    1.43,
                    1.38,
                    1.4,
                    1.37,
                    1.39,
                    1.39,
                    1.41,
                    1.42,
                    1.46,
                    1.44,
                    1.42,
                    1.42,
                    1.43,
                    1.42,
                    1.43,
                    1.44,
                    1.46,
                    1.44,
                    1.45,
                    1.46,
                    1.44,
                    1.46,
                    1.49,
                    1.51,
                    1.47,
                    1.47,
                    1.46,
                    1.46,
                    1.47,
                    1.48,
                    1.46,
                    1.47,
                    1.5,
                    1.5,
                    1.46,
                    1.44,
                    1.46,
                    1.46,
                    1.49,
                    1.5,
                    1.51,
                    1.55,
                    1.58,
                    1.58,
                    1.59,
                    1.59,
                    1.59,
                    1.57,
                    1.62,
                    1.63,
                    1.62,
                    1.63,
                    1.65,
                    1.64,
                    1.65,
                    1.69,
                    1.7,
                    1.71,
                    1.73,
                    1.73,
                    1.73,
                    1.68,
                    1.66,
                    1.64,
                    1.7,
                    1.72,
                    1.83,
                    1.94,
                    1.98,
                    1.99,
                    1.98,
                    2.01,
                    2.06,
                    2.11,
                    2.11,
                    2.11,
                    2.13,
                    2.13,
                    2.12,
                    2.07,
                    2.06,
                    2.15,
                    2.2,
                    2.16,
                    2.17,
                    2.22,
                    2.2,
                    2.25,
                    2.27,
                    2.24,
                    2.22,
                    2.24,
                    2.2,
                    2.18,
                    2.14,
                    2.18,
                    2.18,
                    2.22,
                    2.23,
                    2.21,
                    2.23,
                    2.2,
                    2.16,
                    2.17,
                    2.18,
                    2.21,
                    2.22,
                    2.18,
                    2.2,
                    2.19,
                    2.2,
                    2.24,
                    2.24,
                    2.23,
                    2.22,
                    2.2,
                    2.25,
                    2.31,
                    2.27,
                    2.25,
                    2.28,
                    2.32,
                    2.33,
                    2.32,
                    2.31,
                    2.28,
                    2.27,
                    2.28,
                    2.25,
                    2.23,
                    2.21,
                    2.2,
                    2.23,
                    2.25,
                    2.26,
                    2.29,
                    2.33,
                    2.3,
                    2.27,
                    2.27,
                    2.3,
                    2.3,
                    2.28,
                    2.23,
                    2.23,
                    2.24,
                    2.27,
                    2.29,
                    2.31,
                    2.3,
                    2.3,
                    2.3,
                    2.31,
                    2.3,
                    2.32,
                    2.29,
                    2.28,
                    2.32,
                    2.29,
                    2.29,
                    2.25,
                    2.23,
                    2.21,
                    2.22,
                    2.2,
                    2.21,
                    2.2,
                    2.21,
                    2.22,
                    2.17,
                    2.17,
                    2.17,
                    2.16,
                    2.15,
                    2.15,
                    2.1,
                    2.1,
                    2.09,
                    2.1,
                    2.05,
                    2.02,
                    2.02,
                    2.04,
                    2.04,
                    2.08,
                    2.14,
                    2.14,
                    2.15,
                    2.15,
                    2.16,
                    2.14,
                    2.14,
                    2.13,
                    2.13,
                    2.12,
                    2.12,
                    2.12,
                    2.13,
                    2.07,
                    2.06,
                    2.04,
                    1.97,
                    1.98,
                    1.99,
                    2,
                    2,
                    1.98,
                    1.99,
                    1.98,
                    1.97,
                    1.96,
                    1.96,
                    1.97,
                    1.95,
                    1.93,
                    1.91,
                    1.93,
                    1.96,
                    1.99,
                    1.95,
                    1.95,
                    1.89,
                    1.86,
                    1.85,
                    1.89,
                    1.88,
                    1.88,
                    1.91,
                    1.92,
                    1.93,
                    1.95,
                    1.99,
                    2.01,
                    2.01,
                    2.04,
                    2.04,
                    2.06,
                    2.06,
                    2.06,
                    2.05,
                    2.09,
                    2.08,
                    2.1,
                    2.08,
                    2.09,
                    2.08,
                    2.09,
                    2.06,
                    2.07,
                    2.09,
                    2.14,
                    2.13,
                    2.14,
                    2.12,
                    2.15,
                    2.12,
                    2.13,
                    2.1,
                    2.14,
                    2.16,
                    2.18,
                    2.17,
                    2.11,
                    2.07,
                    2.07,
                    2.09,
                    2.07,
                    2.05,
                    2.04,
                    2.04,
                    2.07,
                    2.05,
                    2.06,
                    2.06,
                    2.05,
                    2.03,
                    2.04,
                    2.05,
                    2.08,
                    2.08,
                    2.04,
                    2.06,
                    2.05,
                    2.07,
                    2.13,
                    2.15,
                    2.17,
                    2.18,
                    2.2,
                    2.24,
                    2.24,
                    2.24,
                    2.25,
                    2.26,
                    2.24,
                    2.23,
                    2.27,
                    2.27,
                    2.25,
                    2.25,
                    2.27,
                    2.27,
                    2.27,
                    2.29,
                    2.28,
                    2.29,
                    2.29,
                    2.29,
                    2.23,
                    2.22,
                    2.22,
                    2.23,
                    2.22,
                    2.25,
                    2.25,
                    2.27,
                    2.3,
                    2.3,
                    2.3,
                    2.28,
                    2.29,
                    2.31,
                    2.31,
                    2.29,
                    2.29,
                    2.3,
                    2.31,
                    2.31,
                    2.31,
                    2.31,
                    2.29,
                    2.26,
                    2.28,
                    2.28,
                    2.27,
                    2.29,
                    2.29,
                    2.27,
                    2.27,
                    2.25,
                    2.23,
                    2.23,
                    2.23,
                    2.22,
                    2.23,
                    2.19,
                    2.16,
                    2.17,
                    2.18,
                    2.19,
                    2.22,
                    2.18,
                    2.17,
                    2.16,
                    2.18,
                    2.23,
                    2.24,
                    2.26,
                    2.26,
                    2.26,
                    2.25,
                    2.25,
                    2.28,
                    2.29,
                    2.29,
                    2.34,
                    2.34,
                    2.38,
                    2.41,
                    2.41,
                    2.43,
                    2.44,
                    2.42,
                    2.46,
                    2.46,
                    2.47,
                    2.5,
                    2.52,
                    2.52,
                    2.5,
                    2.49,
                    2.5,
                    2.49,
                    2.53,
                    2.55,
                    2.54,
                    2.56,
                    2.57,
                    2.57,
                    2.51,
                    2.53,
                    2.53,
                    2.51,
                    2.47,
                    2.48,
                    2.46,
                    2.52,
                    2.54,
                    2.51,
                    2.51,
                    2.53,
                    2.55,
                    2.52,
                    2.55,
                    2.56,
                    2.57,
                    2.54,
                    2.52,
                    2.53,
                    2.52,
                    2.53,
                    2.51,
                    2.52,
                    2.53,
                    2.53,
                    2.52,
                    2.5,
                    2.51,
                    2.49,
                    2.5,
                    2.53,
                    2.53,
                    2.49,
                    2.45,
                    2.46,
                    2.43,
                    2.39,
                    2.36,
                    2.36,
                    2.34,
                    2.34,
                    2.34,
                    2.34,
                    2.31,
                    2.3,
                    2.35,
                    2.41,
                    2.43,
                    2.45,
                    2.42,
                    2.41,
                    2.44,
                    2.48,
                    2.48,
                    2.51,
                    2.5,
                    2.5,
                    2.48,
                    2.48,
                    2.48,
                    2.48,
                    2.48,
                    2.47,
                    2.46,
                    2.46,
                    2.47,
                    2.5,
                    2.5,
                    2.5,
                    2.49,
                    2.53,
                    2.53,
                    2.54,
                    2.5,
                    2.51,
                    2.51,
                    2.46,
                    2.43,
                    2.37,
                    2.37,
                    2.27,
                    2.3,
                    2.3,
                    2.36,
                    2.4,
                    2.39,
                    2.42,
                    2.38,
                    2.37,
                    2.38,
                    2.36,
                    2.36,
                    2.34,
                    2.33,
                    2.33,
                    2.32,
                    2.32,
                    2.29,
                    2.29,
                    2.27,
                    2.26,
                    2.24,
                    2.23,
                    2.26,
                    2.3,
                    2.29,
                    2.26,
                    2.27,
                    2.28,
                    2.3,
                    2.32,
                    2.29,
                    2.29,
                    2.28,
                    2.28,
                    2.27,
                    2.28,
                    2.29,
                    2.32,
                    2.34,
                    2.34,
                    2.38,
                    2.37,
                    2.36,
                    2.37,
                    2.37,
                    2.39,
                    2.37,
                    2.36,
                    2.36,
                    2.4,
                    2.43,
                    2.4,
                    2.37,
                    2.36,
                    2.36,
                    2.32,
                    2.32,
                    2.32,
                    2.32,
                    2.33,
                    2.34,
                    2.33,
                    2.32,
                    2.35,
                    2.38,
                    2.4,
                    2.37,
                    2.35,
                    2.34,
                    2.36,
                    2.36,
                    2.33,
                    2.34,
                    2.34,
                    2.36,
                    2.33,
                    2.33,
                    2.37,
                    2.36,
                    2.37,
                    2.4,
                    2.43,
                    2.44,
                    2.45,
                    2.45,
                    2.41,
                    2.41,
                    2.4,
                    2.4,
                    2.38,
                    2.43,
                    2.45,
                    2.46,
                    2.45,
                    2.44,
                    2.4,
                    2.37,
                    2.39,
                    2.39,
                    2.41,
                    2.42,
                    2.39,
                    2.39,
                    2.37,
                    2.35,
                    2.3,
                    2.27,
                    2.28,
                    2.29,
                    2.29,
                    2.32,
                    2.3,
                    2.35,
                    2.35,
                    2.35,
                    2.37,
                    2.35,
                    2.32,
                    2.32,
                    2.28,
                    2.26,
                    2.25,
                    2.24,
                    2.22,
                    2.19,
                    2.2,
                    2.18,
                    2.15,
                    2.16,
                    2.15,
                    2.18,
                    2.21,
                    2.19,
                    2.2,
                    2.17,
                    2.15,
                    2.1,
                    2.06,
                    2.04,
                    2.01,
                    2.03,
                    2.04,
                    2.01,
                    1.98,
                    1.97,
                    1.93,
                    1.92,
                    1.91,
                    1.88,
                    1.89,
                    1.94,
                    1.89,
                    1.86,
                    1.84,
                    1.84,
                    1.8,
                    1.75,
                    1.86,
                    1.91,
                    1.98,
                    1.98,
                    2.01,
                    2.02,
                    1.99,
                    2.02,
                    2.02,
                    2.04,
                    2.06,
                    2.06,
                    2.01,
                    2.02,
                    2.01,
                    2.04,
                    2.03,
                    2.02,
                    2.06,
                    2.07,
                    2.08,
                    2.09,
                    2.08,
                    2.05,
                    2.04,
                    2.03,
                    2.04,
                    2.06,
                    2.09,
                    2.07,
                    2.06,
                    2.06,
                    2.07,
                    2.1,
                    2.13,
                    2.12,
                    2.14,
                    2.13,
                    2.15,
                    2.17,
                    2.19,
                    2.18,
                    2.17,
                    2.16,
                    2.12,
                    2.09,
                    2.1,
                    2.1,
                    2.12,
                    2.13,
                    2.12,
                    2.13,
                    2.14,
                    2.11,
                    2.12,
                    2.06,
                    2.02,
                    2.01,
                    1.96,
                    2,
                    2.02,
                    2.08,
                    2.1,
                    2.13,
                    2.13,
                    2.15,
                    2.16,
                    2.16,
                    2.16,
                    2.16,
                    2.21,
                    2.18,
                    2.2,
                    2.2,
                    2.2,
                    2.2,
                    2.2,
                    2.19,
                    2.18,
                    2.18,
                    2.17,
                    2.18,
                    2.15,
                    2.12,
                    2.12,
                    2.13,
                    2.1,
                    2.06,
                    2.07,
                    2.04,
                    2.08,
                    2.02,
                    2.03,
                    2,
                    2,
                    1.96,
                    1.95,
                    1.98,
                    1.94,
                    1.87,
                    1.89,
                    1.89,
                    1.86,
                    1.88,
                    1.89,
                    1.85,
                    1.83,
                    1.87,
                    1.88,
                    1.86,
                    1.86,
                    1.87,
                    1.86,
                    1.82,
                    1.79,
                    1.76,
                    1.75,
                    1.77,
                    1.8,
                    1.86,
                    1.86,
                    1.85,
                    1.81,
                    1.86,
                    1.85,
                    1.85,
                    1.86,
                    1.83,
                    1.81,
                    1.81,
                    1.85,
                    1.85,
                    1.87,
                    1.87,
                    1.91,
                    1.91,
                    1.91,
                    1.93,
                    1.89,
                    1.88,
                    1.91,
                    1.93,
                    1.94,
                    1.92,
                    1.93,
                    1.92,
                    1.9,
                    1.9,
                    1.88,
                    1.82,
                    1.76,
                    1.66,
                    1.67,
                    1.67,
                    1.68,
                    1.68,
                    1.62,
                    1.64,
                    1.59,
                    1.56,
                    1.56,
                    1.59,
                    1.55,
                    1.57,
                    1.57,
                    1.54,
                    1.53,
                    1.51,
                    1.53,
                    1.55,
                    1.52,
                    1.53,
                    1.49,
                    1.51,
                    1.57,
                    1.58,
                    1.62,
                    1.68,
                    1.69,
                    1.71,
                    1.79,
                    1.77,
                    1.75,
                    1.71,
                    1.7,
                    1.68,
                    1.68,
                    1.61,
                    1.64,
                    1.62,
                    1.57,
                    1.56,
                    1.56,
                    1.52,
                    1.49,
                    1.5,
                    1.52,
                    1.48,
                    1.5,
                    1.53,
                    1.59,
                    1.6,
                    1.61,
                    1.61,
                    1.63,
                    1.65,
                    1.67,
                    1.66,
                    1.66,
                    1.68,
                    1.71,
                    1.73,
                    1.71,
                    1.65,
                    1.6,
                    1.65,
                    1.71,
                    1.76,
                    1.78,
                    1.81,
                    1.85,
                    1.84,
                    1.82,
                    1.78,
                    1.73,
                    1.74,
                    1.76,
                    1.75,
                    1.71,
                    1.72,
                    1.73,
                    1.74,
                    1.75,
                    1.76,
                    1.76,
                    1.74,
                    1.77,
                    1.74,
                    1.78,
                    1.81,
                    1.84,
                    1.84,
                    1.85,
                    1.85,
                    1.91,
                    1.91,
                    1.94,
                    1.96,
                    1.97,
                    1.98,
                    1.97,
                    1.96,
                    1.95,
                    1.96,
                    1.95,
                    1.95,
                    1.96,
                    1.99,
                    1.99,
                    2.02,
                    1.98,
                    1.98,
                    1.95,
                    1.97,
                    1.97,
                    1.95,
                    1.98,
                    1.96,
                    1.92,
                    1.93,
                    1.95,
                    1.96,
                    1.94,
                    1.92,
                    1.9,
                    1.86,
                    1.8,
                    1.84,
                    1.8,
                    1.8,
                    1.76,
                    1.76,
                    1.8,
                    1.83,
                    1.86,
                    1.83,
                    1.81,
                    1.83,
                    1.86,
                    1.86,
                    1.85,
                    1.85,
                    1.83,
                    1.83,
                    1.82,
                    1.8,
                    1.74,
                    1.71,
                    1.71,
                    1.64,
                    1.55,
                    1.56,
                    1.52,
                    1.55,
                    1.44,
                    1.3,
                    0.98,
                    1.06,
                    1.01,
                    0.87,
                    0.97,
                    0.73,
                    0.76,
                    0.64,
                    0.5,
                    0.63,
                    0.68,
                    0.86,
                    0.96,
                    0.94,
                    0.85,
                    0.77,
                    0.75,
                    0.73,
                    0.82,
                    0.85,
                    0.93,
                    0.97,
                    0.98,
                    1.02,
                    1.03,
                    1.07,
                    1.08,
                    0.96,
                    0.87,
                    0.86,
                    0.8,
                    0.77,
                    0.85,
                    0.84,
                    0.89,
                    0.95,
                    0.95,
                    0.97,
                    0.96,
                    0.93,
                    0.93,
                    0.95,
                    0.97,
                    0.95,
                    0.98,
                    1.02,
                    0.94,
                    0.93,
                    0.89,
                    0.92,
                    1,
                    1.01,
                    1.03,
                    1,
                    0.96,
                    0.97,
                    0.99,
                    1.02,
                    1.04,
                    0.99,
                    1.03,
                    1.04,
                    1.1,
                    1.11,
                    1.19,
                    1.21,
                    1.16,
                    1.16,
                    1.1,
                    1.1,
                    1.11,
                    1.17,
                    1.15,
                    1.16,
                    1.17,
                    1.22,
                    1.25,
                    1.22,
                    1.22,
                    1.2,
                    1.19,
                    1.2,
                    1.23,
                    1.27,
                    1.28,
                    1.34,
                    1.29,
                    1.31,
                    1.3,
                    1.3,
                    1.29,
                    1.28,
                    1.26,
                    1.26,
                    1.3,
                    1.31,
                    1.35,
                    1.35,
                    1.32,
                    1.36,
                    1.37,
                    1.36,
                    1.38,
                    1.37,
                    1.37,
                    1.41,
                    1.4,
                    1.45,
                    1.48,
                    1.47,
                    1.46,
                    1.49,
                    1.53,
                    1.54,
                    1.51,
                    1.52,
                    1.54,
                    1.52,
                    1.48,
                    1.5,
                    1.55,
                    1.59,
                    1.62,
                    1.63,
                    1.63,
                    1.65,
                    1.64,
                    1.6,
                    1.56,
                    1.6,
                    1.62,
                    1.6,
                    1.62,
                    1.59,
                    1.57,
                    1.58,
                    1.61,
                    1.62,
                    1.61,
                    1.6,
                    1.57,
                    1.55,
                    1.53,
                    1.5,
                    1.5,
                    1.54,
                    1.56,
                    1.57,
                    1.58,
                    1.59,
                    1.63,
                    1.62,
                    1.68,
                    1.7,
                    1.7,
                    1.71,
                    1.68,
                    1.66,
                    1.66,
                    1.68,
                    1.69,
                    1.69,
                    1.72,
                    1.75,
                    1.76,
                    1.74,
                    1.72,
                    1.69,
                    1.71,
                    1.73,
                    1.73,
                    1.76,
                    1.69,
                    1.69,
                    1.72,
                    1.79,
                    1.8,
                    1.8,
                    1.73,
                    1.77,
                    1.78,
                    1.76,
                    1.76,
                    1.74,
                    1.74,
                    1.77,
                    1.82,
                    1.84,
                    1.84,
                    1.85,
                    1.87,
                    1.91,
                    1.95,
                    1.94,
                    1.97,
                    1.95,
                    1.94,
                    1.95,
                    1.92,
                    1.9,
                    1.89,
                    1.92,
                    1.95,
                    1.98,
                    1.99,
                    1.98,
                    1.96,
                    1.98,
                    1.96,
                    1.95,
                    1.95,
                    1.97,
                    1.99,
                    2.01,
                    2.01,
                    2.02,
                    2.05,
                    2.1,
                    2.15,
                    2.17,
                    2.2,
                    2.21,
                    2.22,
                    2.25,
                    2.24,
                    2.25,
                    2.31,
                    2.34,
                    2.38,
                    2.28,
                    2.27,
                    2.23,
                    2.2,
                    2.22,
                    2.2,
                    2.25,
                    2.3,
                    2.34,
                    2.35,
                    2.38,
                    2.38,
                    2.38,
                    2.36,
                    2.39,
                    2.42,
                    2.43,
                    2.49,
                    2.49,
                    2.45,
                    2.44,
                    2.47,
                    2.47,
                    2.45,
                    2.52,
                    2.49,
                    2.51,
                    2.54,
                    2.58,
                    2.56,
                    2.56,
                    2.54,
                    2.53,
                    2.56,
                    2.6,
                    2.62,
                    2.61,
                    2.63,
                    2.62,
                    2.63,
                    2.64,
                    2.64,
                    2.61,
                    2.59,
                    2.6,
                    2.64,
                    2.64,
                    2.62,
                    2.67,
                    2.66,
                    2.69,
                    2.67,
                    2.64,
                    2.66,
                    2.64,
                    2.66,
                    2.67,
                    2.65,
                    2.67,
                    2.65,
                    2.68,
                    2.68,
                    2.64,
                    2.63,
                    2.64,
                    2.65,
                    2.7,
                    2.76,
                    2.76,
                    2.77,
                    2.73,
                    2.73,
                    2.73,
                    2.75,
                    2.75,
                    2.78,
                    2.81,
                    2.77,
                    2.79,
                    2.7,
                    2.7,
                    2.72,
                    2.73,
                    2.7,
                    2.67,
                    2.66,
                    2.66,
                    2.64,
                    2.64,
                    2.65,
                    2.68,
                    2.69,
                    2.74,
                    2.72,
                    2.69,
                    2.66,
                    2.63,
                    2.61,
                    2.56,
                    2.58,
                    2.59,
                    2.62,
                    2.61,
                    2.58,
                    2.55,
                    2.51,
                    2.54,
                    2.57,
                    2.62,
                    2.6,
                    2.64,
                    2.61,
                    2.6,
                    2.61,
                    2.61,
                    2.6,
                    2.6,
                    2.58,
                    2.53,
                    2.47,
                    2.54,
                    2.58,
                    2.62,
                    2.59,
                    2.58,
                    2.57,
                    2.47,
                    2.45,
                    2.49,
                    2.52,
                    2.55,
                    2.57,
                    2.53,
                    2.58,
                    2.65,
                    2.63,
                    2.58,
                    2.58,
                    2.58,
                    2.57,
                    2.61,
                    2.62,
                    2.63,
                    2.65,
                    2.66,
                    2.59,
                    2.56,
                    2.51,
                    2.51,
                    2.45,
                    2.41,
                    2.43,
                    2.5,
                    2.55,
                    2.55,
                    2.57,
                    2.55,
                    2.55,
                    2.55,
                    2.54,
                    2.56,
                    2.58,
                    2.61,
                    2.62,
                    2.59,
                    2.61,
                    2.6,
                    2.58,
                    2.58,
                    2.58,
                    2.57,
                    2.5,
                    2.5,
                    2.49,
                    2.57,
                    2.57,
                    2.61,
                    2.65,
                    2.6,
                    2.58,
                    2.56,
                    2.56,
                    2.6,
                    2.58,
                    2.64,
                    2.69,
                    2.71,
                    2.69,
                    2.69,
                    2.7,
                    2.76,
                    2.8,
                    2.81,
                    2.87,
                    2.93,
                    2.91,
                    2.94,
                    2.96,
                    2.93,
                    2.86,
                    2.83,
                    2.76,
                    2.76,
                    2.8,
                    2.8,
                    2.76,
                    2.83,
                    2.84,
                    2.94,
                    2.95,
                    2.97,
                    3,
                    3,
                    2.97,
                    2.97,
                    2.92,
                    2.92,
                    2.91,
                    2.9,
                    2.91,
                    2.78,
                    2.77,
                    2.77,
                    2.73,
                    2.76,
                    2.7,
                    2.7,
                    2.78,
                    2.82,
                    2.75,
                    2.73,
                    2.66,
                    2.66,
                    2.71,
                    2.69,
                    2.66,
                    2.63,
                    2.7,
                    2.73,
                    2.75,
                    2.75,
                    2.78,
                    2.77,
                    2.84,
                    2.86,
                    2.85,
                    2.92,
                    2.91,
                    2.87,
                    2.84,
                    2.88,
                    2.89,
                    2.97,
                    2.95,
                    2.94,
                    2.96,
                    2.97,
                    2.99,
                    2.94,
                    2.91,
                    2.8,
                    2.83,
                    2.78,
                    2.81,
                    2.81,
                    2.82,
                    2.86,
                    2.82,
                    2.81,
                    2.8,
                    2.85,
                    2.86,
                    2.89,
                    2.92,
                    2.95,
                    2.94,
                    2.95,
                    2.89,
                    2.89,
                    2.85,
                    2.81,
                    2.79,
                    2.81,
                    2.89,
                    2.87,
                    2.87,
                    2.88,
                    2.91,
                    2.98,
                    2.96,
                    2.92,
                    3.03,
                    3.11,
                    3.11,
                    3.14,
                    3.21,
                    3.25,
                    3.14,
                    3.17,
                    3.3,
                    3.29,
                    3.34,
                    3.41,
                    3.45,
                    3.46,
                    3.51,
                    3.5,
                    3.42,
                    3.41,
                    3.36,
                    3.35,
                    3.36,
                    3.41,
                    3.4,
                    3.38,
                    3.42,
                    3.45,
                    3.37,
                    3.35,
                    3.43,
                    3.43,
                    3.45,
                    3.5,
                    3.44,
                    3.52,
                    3.46,
                    3.37,
                    3.3,
                    3.33,
                    3.4,
                    3.43,
                    3.36,
                    3.36,
                    3.38,
                    3.39,
                    3.41,
                    3.3,
                    3.18,
                    3.23,
                    3.12,
                    3.24,
                    3.22,
                    3.31,
                    3.3,
                    3.21,
                    3.17,
                    3.19,
                    3.14,
                    3.12,
                    3.18,
                    3.22,
                    3.23,
                    3.25,
                    3.27,
                    3.29,
                    3.36,
                    3.41,
                    3.39,
                    3.43,
                    3.45,
                    3.48,
                    3.4,
                    3.4,
                    3.38,
                    3.27,
                    3.26,
                    3.23,
                    3.27,
                    3.15,
                    3.09,
                    3.13,
                    3.14,
                    3.07,
                    2.98,
                    2.89,
                    2.83,
                    2.81,
                    2.77,
                    2.76,
                    2.82,
                    2.88,
                    2.8,
                    2.76,
                    2.78,
                    2.81,
                    2.8,
                    2.85,
                    2.89,
                    2.9,
                    2.8,
                    2.79,
                    2.8,
                    2.82,
                    2.89,
                    2.88,
                    2.91,
                    2.86,
                    2.87,
                    2.89,
                    2.88,
                    2.92,
                    2.91,
                    2.91,
                    2.87,
                    2.95,
                    2.94,
                    2.91,
                    2.91,
                    2.94,
                    2.96,
                    3.01,
                    3.02,
                    3.04,
                    3.1,
                    3.09,
                    3.08,
                    3.07,
                    3.05,
                    3.03,
                    3,
                    2.99,
                    3,
                    3.06,
                    3,
                    2.99,
                    3.02,
                    3.02,
                    3.05,
                    3.06,
                    3.04,
                    2.97,
                    2.95,
                    3,
                    2.96,
                    3.01,
                    2.98,
                    2.95,
                    2.95,
                    2.87,
                    2.77,
                    2.74,
                    2.76,
                    2.8,
                    2.85,
                    2.89,
                    2.94,
                    2.94,
                    2.97,
                    2.93,
                    2.98,
                    3.06,
                    3.1,
                    3.07,
                    3.12,
                    3.17,
                    3.2,
                    3.24,
                    3.17,
                    3.1,
                    3.11,
                    3.17,
                    3.21,
                    3.21,
                    3.22,
                    3.15,
                    3.21,
                    3.28,
                    3.23,
                    3.18,
                    3.06,
                    3.13,
                    3.13,
                    3.11,
                    3.07,
                    3.05,
                    3.04,
                    3.09,
                    3.05,
                    3.05,
                    3.07,
                    3.06,
                    3,
                    3,
                    3.05,
                    3.04,
                    3.07,
                    3.08,
                    3,
                    2.9,
                    2.95,
                    2.94,
                    2.94,
                    2.89,
                    2.85,
                    2.82,
                    2.78,
                    2.82,
                    2.89,
                    2.91,
                    2.86,
                    2.88,
                    2.89,
                    2.93,
                    2.94,
                    2.94,
                    2.98,
                    2.98,
                    2.94,
                    2.92,
                    2.94,
                    2.9,
                    2.9,
                    2.93,
                    2.94,
                    2.9,
                    2.89,
                    2.9,
                    2.88,
                    2.81,
                    2.91,
                    2.96,
                    2.99,
                    2.99,
                    3,
                    3.05,
                    3.05,
                    3.03,
                    2.97,
                    2.97,
                    2.95,
                    2.99,
                    3.03,
                    3.08,
                    3.11,
                    3.11,
                    3.12,
                    3.08,
                    3.08,
                    3.12,
                    3.16,
                    3.13,
                    3.13,
                    3.2,
                    3.16,
                    3.15,
                    3.15,
                    3.13,
                    3.13,
                    3.21,
                    3.27,
                    3.28,
                    3.26,
                    3.21,
                    3.15,
                    3.09,
                    3.01,
                    2.92,
                    2.98,
                    2.91,
                    2.88,
                    2.75,
                    2.8,
                    2.9,
                    2.88,
                    2.88,
                    2.82,
                    2.88,
                    2.93,
                    2.97,
                    2.97,
                    2.96,
                    2.93,
                    2.86,
                    2.84,
                    2.82,
                    2.88,
                    2.91,
                    2.93,
                    2.92,
                    2.93,
                    2.97,
                    3,
                    2.97,
                    2.96,
                    2.94,
                    2.94,
                    2.91,
                    2.84,
                    2.85,
                    2.88,
                    2.81,
                    2.87,
                    2.81,
                    2.75,
                    2.77,
                    2.81,
                    2.87,
                    2.87,
                    2.81,
                    2.8,
                    2.82,
                    2.83,
                    2.85,
                    2.88,
                    2.95,
                    2.97,
                    2.97,
                    2.99,
                    2.99,
                    3.02,
                    3.02,
                    3.04,
                    3.01,
                    2.95,
                    2.94,
                    3.01,
                    3.03,
                    3.02,
                    3.07,
                    3.04,
                    3.06,
                    3.04,
                    3.09,
                    3.09,
                    3.08,
                    3.09,
                    3.09,
                    3.07,
                    3.06,
                    3.11,
                    3.05,
                    3.03,
                    3.05,
                    3.03,
                    3.1,
                    3.12,
                    3.17,
                    3.17,
                    3.17,
                    3.2,
                    3.2,
                    3.17,
                    3.15,
                    3.15,
                    3.12,
                    3.16,
                    3.17,
                    3.15,
                    3.15,
                    3.23,
                    3.24,
                    3.29,
                    3.32,
                    3.28,
                    3.32,
                    3.33,
                    3.34,
                    3.34,
                    3.33,
                    3.35,
                    3.31,
                    3.34,
                    3.27,
                    3.25,
                    3.28,
                    3.31,
                    3.28,
                    3.27,
                    3.25,
                    3.27,
                    3.26,
                    3.3,
                    3.33,
                    3.29,
                    3.3,
                    3.28,
                    3.28,
                    3.24,
                    3.23,
                    3.22,
                    3.25,
                    3.28,
                    3.33,
                    3.34,
                    3.32,
                    3.35,
                    3.35,
                    3.35,
                    3.33,
                    3.37,
                    3.41,
                    3.4,
                    3.43,
                    3.42,
                    3.44,
                    3.43,
                    3.42,
                    3.37,
                    3.41,
                    3.43,
                    3.4
                ]
            }
        ],
        legend: {
            enabled: true
        },
        title: {
            text: null
        }

    }

export const chart2 = {

    xAxis: {
        categories: [
            "10/1/2008",
            "10/2/2008",
            "10/3/2008",
            "10/6/2008",
            "10/7/2008",
            "10/8/2008",
            "10/9/2008",
            "10/10/2008",
            "10/13/2008",
            "10/14/2008",
            "10/15/2008",
            "10/16/2008",
            "10/17/2008",
            "10/20/2008",
            "10/21/2008",
            "10/22/2008",
            "10/23/2008",
            "10/24/2008",
            "10/27/2008",
            "10/28/2008",
            "10/29/2008",
            "10/30/2008",
            "10/31/2008",
            "11/3/2008",
            "11/4/2008",
            "11/5/2008",
            "11/6/2008",
            "11/7/2008",
            "11/10/2008",
            "11/11/2008",
            "11/12/2008",
            "11/13/2008",
            "11/14/2008",
            "11/17/2008",
            "11/18/2008",
            "11/19/2008",
            "11/20/2008",
            "11/21/2008",
            "11/24/2008",
            "11/25/2008",
            "11/26/2008",
            "11/27/2008",
            "11/28/2008",
            "12/1/2008",
            "12/2/2008",
            "12/3/2008",
            "12/4/2008",
            "12/5/2008",
            "12/8/2008",
            "12/9/2008",
            "12/10/2008",
            "12/11/2008",
            "12/12/2008",
            "12/15/2008",
            "12/16/2008",
            "12/17/2008",
            "12/18/2008",
            "12/19/2008",
            "12/22/2008",
            "12/23/2008",
            "12/24/2008",
            "12/25/2008",
            "12/26/2008",
            "12/29/2008",
            "12/30/2008",
            "12/31/2008",
            "1/1/2009",
            "1/2/2009",
            "1/5/2009",
            "1/6/2009",
            "1/7/2009",
            "1/8/2009",
            "1/9/2009",
            "1/12/2009",
            "1/13/2009",
            "1/14/2009",
            "1/15/2009",
            "1/16/2009",
            "1/19/2009",
            "1/20/2009",
            "1/21/2009",
            "1/22/2009",
            "1/23/2009",
            "1/26/2009",
            "1/27/2009",
            "1/28/2009",
            "1/29/2009",
            "1/30/2009",
            "2/2/2009",
            "2/3/2009",
            "2/4/2009",
            "2/5/2009",
            "2/6/2009",
            "2/9/2009",
            "2/10/2009",
            "2/11/2009",
            "2/12/2009",
            "2/13/2009",
            "2/16/2009",
            "2/17/2009",
            "2/18/2009",
            "2/19/2009",
            "2/20/2009",
            "2/23/2009",
            "2/24/2009",
            "2/25/2009",
            "2/26/2009",
            "2/27/2009",
            "3/2/2009",
            "3/3/2009",
            "3/4/2009",
            "3/5/2009",
            "3/6/2009",
            "3/9/2009",
            "3/10/2009",
            "3/11/2009",
            "3/12/2009",
            "3/13/2009",
            "3/16/2009",
            "3/17/2009",
            "3/18/2009",
            "3/19/2009",
            "3/20/2009",
            "3/23/2009",
            "3/24/2009",
            "3/25/2009",
            "3/26/2009",
            "3/27/2009",
            "3/30/2009",
            "3/31/2009",
            "4/1/2009",
            "4/2/2009",
            "4/3/2009",
            "4/6/2009",
            "4/7/2009",
            "4/8/2009",
            "4/9/2009",
            "4/10/2009",
            "4/13/2009",
            "4/14/2009",
            "4/15/2009",
            "4/16/2009",
            "4/17/2009",
            "4/20/2009",
            "4/21/2009",
            "4/22/2009",
            "4/23/2009",
            "4/24/2009",
            "4/27/2009",
            "4/28/2009",
            "4/29/2009",
            "4/30/2009",
            "5/1/2009",
            "5/4/2009",
            "5/5/2009",
            "5/6/2009",
            "5/7/2009",
            "5/8/2009",
            "5/11/2009",
            "5/12/2009",
            "5/13/2009",
            "5/14/2009",
            "5/15/2009",
            "5/18/2009",
            "5/19/2009",
            "5/20/2009",
            "5/21/2009",
            "5/22/2009",
            "5/25/2009",
            "5/26/2009",
            "5/27/2009",
            "5/28/2009",
            "5/29/2009",
            "6/1/2009",
            "6/2/2009",
            "6/3/2009",
            "6/4/2009",
            "6/5/2009",
            "6/8/2009",
            "6/9/2009",
            "6/10/2009",
            "6/11/2009",
            "6/12/2009",
            "6/15/2009",
            "6/16/2009",
            "6/17/2009",
            "6/18/2009",
            "6/19/2009",
            "6/22/2009",
            "6/23/2009",
            "6/24/2009",
            "6/25/2009",
            "6/26/2009",
            "6/29/2009",
            "6/30/2009",
            "7/1/2009",
            "7/2/2009",
            "7/3/2009",
            "7/6/2009",
            "7/7/2009",
            "7/8/2009",
            "7/9/2009",
            "7/10/2009",
            "7/13/2009",
            "7/14/2009",
            "7/15/2009",
            "7/16/2009",
            "7/17/2009",
            "7/20/2009",
            "7/21/2009",
            "7/22/2009",
            "7/23/2009",
            "7/24/2009",
            "7/27/2009",
            "7/28/2009",
            "7/29/2009",
            "7/30/2009",
            "7/31/2009",
            "8/3/2009",
            "8/4/2009",
            "8/5/2009",
            "8/6/2009",
            "8/7/2009",
            "8/10/2009",
            "8/11/2009",
            "8/12/2009",
            "8/13/2009",
            "8/14/2009",
            "8/17/2009",
            "8/18/2009",
            "8/19/2009",
            "8/20/2009",
            "8/21/2009",
            "8/24/2009",
            "8/25/2009",
            "8/26/2009",
            "8/27/2009",
            "8/28/2009",
            "8/31/2009",
            "9/1/2009",
            "9/2/2009",
            "9/3/2009",
            "9/4/2009",
            "9/7/2009",
            "9/8/2009",
            "9/9/2009",
            "9/10/2009",
            "9/11/2009",
            "9/14/2009",
            "9/15/2009",
            "9/16/2009",
            "9/17/2009",
            "9/18/2009",
            "9/21/2009",
            "9/22/2009",
            "9/23/2009",
            "9/24/2009",
            "9/25/2009",
            "9/28/2009",
            "9/29/2009",
            "9/30/2009",
            "10/1/2009",
            "10/2/2009",
            "10/5/2009",
            "10/6/2009",
            "10/7/2009",
            "10/8/2009",
            "10/9/2009",
            "10/12/2009",
            "10/13/2009",
            "10/14/2009",
            "10/15/2009",
            "10/16/2009",
            "10/19/2009",
            "10/20/2009",
            "10/21/2009",
            "10/22/2009",
            "10/23/2009",
            "10/26/2009",
            "10/27/2009",
            "10/28/2009",
            "10/29/2009",
            "10/30/2009",
            "11/2/2009",
            "11/3/2009",
            "11/4/2009",
            "11/5/2009",
            "11/6/2009",
            "11/9/2009",
            "11/10/2009",
            "11/11/2009",
            "11/12/2009",
            "11/13/2009",
            "11/16/2009",
            "11/17/2009",
            "11/18/2009",
            "11/19/2009",
            "11/20/2009",
            "11/23/2009",
            "11/24/2009",
            "11/25/2009",
            "11/26/2009",
            "11/27/2009",
            "11/30/2009",
            "12/1/2009",
            "12/2/2009",
            "12/3/2009",
            "12/4/2009",
            "12/7/2009",
            "12/8/2009",
            "12/9/2009",
            "12/10/2009",
            "12/11/2009",
            "12/14/2009",
            "12/15/2009",
            "12/16/2009",
            "12/17/2009",
            "12/18/2009",
            "12/21/2009",
            "12/22/2009",
            "12/23/2009",
            "12/24/2009",
            "12/25/2009",
            "12/28/2009",
            "12/29/2009",
            "12/30/2009",
            "12/31/2009",
            "1/1/2010",
            "1/4/2010",
            "1/5/2010",
            "1/6/2010",
            "1/7/2010",
            "1/8/2010",
            "1/11/2010",
            "1/12/2010",
            "1/13/2010",
            "1/14/2010",
            "1/15/2010",
            "1/18/2010",
            "1/19/2010",
            "1/20/2010",
            "1/21/2010",
            "1/22/2010",
            "1/25/2010",
            "1/26/2010",
            "1/27/2010",
            "1/28/2010",
            "1/29/2010",
            "2/1/2010",
            "2/2/2010",
            "2/3/2010",
            "2/4/2010",
            "2/5/2010",
            "2/8/2010",
            "2/9/2010",
            "2/10/2010",
            "2/11/2010",
            "2/12/2010",
            "2/15/2010",
            "2/16/2010",
            "2/17/2010",
            "2/18/2010",
            "2/19/2010",
            "2/22/2010",
            "2/23/2010",
            "2/24/2010",
            "2/25/2010",
            "2/26/2010",
            "3/1/2010",
            "3/2/2010",
            "3/3/2010",
            "3/4/2010",
            "3/5/2010",
            "3/8/2010",
            "3/9/2010",
            "3/10/2010",
            "3/11/2010",
            "3/12/2010",
            "3/15/2010",
            "3/16/2010",
            "3/17/2010",
            "3/18/2010",
            "3/19/2010",
            "3/22/2010",
            "3/23/2010",
            "3/24/2010",
            "3/25/2010",
            "3/26/2010",
            "3/29/2010",
            "3/30/2010",
            "3/31/2010",
            "4/1/2010",
            "4/2/2010",
            "4/5/2010",
            "4/6/2010",
            "4/7/2010",
            "4/8/2010",
            "4/9/2010",
            "4/12/2010",
            "4/13/2010",
            "4/14/2010",
            "4/15/2010",
            "4/16/2010",
            "4/19/2010",
            "4/20/2010",
            "4/21/2010",
            "4/22/2010",
            "4/23/2010",
            "4/26/2010",
            "4/27/2010",
            "4/28/2010",
            "4/29/2010",
            "4/30/2010",
            "5/3/2010",
            "5/4/2010",
            "5/5/2010",
            "5/6/2010",
            "5/7/2010",
            "5/10/2010",
            "5/11/2010",
            "5/12/2010",
            "5/13/2010",
            "5/14/2010",
            "5/17/2010",
            "5/18/2010",
            "5/19/2010",
            "5/20/2010",
            "5/21/2010",
            "5/24/2010",
            "5/25/2010",
            "5/26/2010",
            "5/27/2010",
            "5/28/2010",
            "5/31/2010",
            "6/1/2010",
            "6/2/2010",
            "6/3/2010",
            "6/4/2010",
            "6/7/2010",
            "6/8/2010",
            "6/9/2010",
            "6/10/2010",
            "6/11/2010",
            "6/14/2010",
            "6/15/2010",
            "6/16/2010",
            "6/17/2010",
            "6/18/2010",
            "6/21/2010",
            "6/22/2010",
            "6/23/2010",
            "6/24/2010",
            "6/25/2010",
            "6/28/2010",
            "6/29/2010",
            "6/30/2010",
            "7/1/2010",
            "7/2/2010",
            "7/5/2010",
            "7/6/2010",
            "7/7/2010",
            "7/8/2010",
            "7/9/2010",
            "7/12/2010",
            "7/13/2010",
            "7/14/2010",
            "7/15/2010",
            "7/16/2010",
            "7/19/2010",
            "7/20/2010",
            "7/21/2010",
            "7/22/2010",
            "7/23/2010",
            "7/26/2010",
            "7/27/2010",
            "7/28/2010",
            "7/29/2010",
            "7/30/2010",
            "8/2/2010",
            "8/3/2010",
            "8/4/2010",
            "8/5/2010",
            "8/6/2010",
            "8/9/2010",
            "8/10/2010",
            "8/11/2010",
            "8/12/2010",
            "8/13/2010",
            "8/16/2010",
            "8/17/2010",
            "8/18/2010",
            "8/19/2010",
            "8/20/2010",
            "8/23/2010",
            "8/24/2010",
            "8/25/2010",
            "8/26/2010",
            "8/27/2010",
            "8/30/2010",
            "8/31/2010",
            "9/1/2010",
            "9/2/2010",
            "9/3/2010",
            "9/6/2010",
            "9/7/2010",
            "9/8/2010",
            "9/9/2010",
            "9/10/2010",
            "9/13/2010",
            "9/14/2010",
            "9/15/2010",
            "9/16/2010",
            "9/17/2010",
            "9/20/2010",
            "9/21/2010",
            "9/22/2010",
            "9/23/2010",
            "9/24/2010",
            "9/27/2010",
            "9/28/2010",
            "9/29/2010",
            "9/30/2010",
            "10/1/2010",
            "10/4/2010",
            "10/5/2010",
            "10/6/2010",
            "10/7/2010",
            "10/8/2010",
            "10/11/2010",
            "10/12/2010",
            "10/13/2010",
            "10/14/2010",
            "10/15/2010",
            "10/18/2010",
            "10/19/2010",
            "10/20/2010",
            "10/21/2010",
            "10/22/2010",
            "10/25/2010",
            "10/26/2010",
            "10/27/2010",
            "10/28/2010",
            "10/29/2010",
            "11/1/2010",
            "11/2/2010",
            "11/3/2010",
            "11/4/2010",
            "11/5/2010",
            "11/8/2010",
            "11/9/2010",
            "11/10/2010",
            "11/11/2010",
            "11/12/2010",
            "11/15/2010",
            "11/16/2010",
            "11/17/2010",
            "11/18/2010",
            "11/19/2010",
            "11/22/2010",
            "11/23/2010",
            "11/24/2010",
            "11/25/2010",
            "11/26/2010",
            "11/29/2010",
            "11/30/2010",
            "12/1/2010",
            "12/2/2010",
            "12/3/2010",
            "12/6/2010",
            "12/7/2010",
            "12/8/2010",
            "12/9/2010",
            "12/10/2010",
            "12/13/2010",
            "12/14/2010",
            "12/15/2010",
            "12/16/2010",
            "12/17/2010",
            "12/20/2010",
            "12/21/2010",
            "12/22/2010",
            "12/23/2010",
            "12/24/2010",
            "12/27/2010",
            "12/28/2010",
            "12/29/2010",
            "12/30/2010",
            "12/31/2010",
            "1/3/2011",
            "1/4/2011",
            "1/5/2011",
            "1/6/2011",
            "1/7/2011",
            "1/10/2011",
            "1/11/2011",
            "1/12/2011",
            "1/13/2011",
            "1/14/2011",
            "1/17/2011",
            "1/18/2011",
            "1/19/2011",
            "1/20/2011",
            "1/21/2011",
            "1/24/2011",
            "1/25/2011",
            "1/26/2011",
            "1/27/2011",
            "1/28/2011",
            "1/31/2011",
            "2/1/2011",
            "2/2/2011",
            "2/3/2011",
            "2/4/2011",
            "2/7/2011",
            "2/8/2011",
            "2/9/2011",
            "2/10/2011",
            "2/11/2011",
            "2/14/2011",
            "2/15/2011",
            "2/16/2011",
            "2/17/2011",
            "2/18/2011",
            "2/21/2011",
            "2/22/2011",
            "2/23/2011",
            "2/24/2011",
            "2/25/2011",
            "2/28/2011",
            "3/1/2011",
            "3/2/2011",
            "3/3/2011",
            "3/4/2011",
            "3/7/2011",
            "3/8/2011",
            "3/9/2011",
            "3/10/2011",
            "3/11/2011",
            "3/14/2011",
            "3/15/2011",
            "3/16/2011",
            "3/17/2011",
            "3/18/2011",
            "3/21/2011",
            "3/22/2011",
            "3/23/2011",
            "3/24/2011",
            "3/25/2011",
            "3/28/2011",
            "3/29/2011",
            "3/30/2011",
            "3/31/2011",
            "4/1/2011",
            "4/4/2011",
            "4/5/2011",
            "4/6/2011",
            "4/7/2011",
            "4/8/2011",
            "4/11/2011",
            "4/12/2011",
            "4/13/2011",
            "4/14/2011",
            "4/15/2011",
            "4/18/2011",
            "4/19/2011",
            "4/20/2011",
            "4/21/2011",
            "4/22/2011",
            "4/25/2011",
            "4/26/2011",
            "4/27/2011",
            "4/28/2011",
            "4/29/2011",
            "5/2/2011",
            "5/3/2011",
            "5/4/2011",
            "5/5/2011",
            "5/6/2011",
            "5/9/2011",
            "5/10/2011",
            "5/11/2011",
            "5/12/2011",
            "5/13/2011",
            "5/16/2011",
            "5/17/2011",
            "5/18/2011",
            "5/19/2011",
            "5/20/2011",
            "5/23/2011",
            "5/24/2011",
            "5/25/2011",
            "5/26/2011",
            "5/27/2011",
            "5/30/2011",
            "5/31/2011",
            "6/1/2011",
            "6/2/2011",
            "6/3/2011",
            "6/6/2011",
            "6/7/2011",
            "6/8/2011",
            "6/9/2011",
            "6/10/2011",
            "6/13/2011",
            "6/14/2011",
            "6/15/2011",
            "6/16/2011",
            "6/17/2011",
            "6/20/2011",
            "6/21/2011",
            "6/22/2011",
            "6/23/2011",
            "6/24/2011",
            "6/27/2011",
            "6/28/2011",
            "6/29/2011",
            "6/30/2011",
            "7/1/2011",
            "7/4/2011",
            "7/5/2011",
            "7/6/2011",
            "7/7/2011",
            "7/8/2011",
            "7/11/2011",
            "7/12/2011",
            "7/13/2011",
            "7/14/2011",
            "7/15/2011",
            "7/18/2011",
            "7/19/2011",
            "7/20/2011",
            "7/21/2011",
            "7/22/2011",
            "7/25/2011",
            "7/26/2011",
            "7/27/2011",
            "7/28/2011",
            "7/29/2011",
            "8/1/2011",
            "8/2/2011",
            "8/3/2011",
            "8/4/2011",
            "8/5/2011",
            "8/8/2011",
            "8/9/2011",
            "8/10/2011",
            "8/11/2011",
            "8/12/2011",
            "8/15/2011",
            "8/16/2011",
            "8/17/2011",
            "8/18/2011",
            "8/19/2011",
            "8/22/2011",
            "8/23/2011",
            "8/24/2011",
            "8/25/2011",
            "8/26/2011",
            "8/29/2011",
            "8/30/2011",
            "8/31/2011",
            "9/1/2011",
            "9/2/2011",
            "9/5/2011",
            "9/6/2011",
            "9/7/2011",
            "9/8/2011",
            "9/9/2011",
            "9/12/2011",
            "9/13/2011",
            "9/14/2011",
            "9/15/2011",
            "9/16/2011",
            "9/19/2011",
            "9/20/2011",
            "9/21/2011",
            "9/22/2011",
            "9/23/2011",
            "9/26/2011",
            "9/27/2011",
            "9/28/2011",
            "9/29/2011",
            "9/30/2011",
            "10/3/2011",
            "10/4/2011",
            "10/5/2011",
            "10/6/2011",
            "10/7/2011",
            "10/10/2011",
            "10/11/2011",
            "10/12/2011",
            "10/13/2011",
            "10/14/2011",
            "10/17/2011",
            "10/18/2011",
            "10/19/2011",
            "10/20/2011",
            "10/21/2011",
            "10/24/2011",
            "10/25/2011",
            "10/26/2011",
            "10/27/2011",
            "10/28/2011",
            "10/31/2011",
            "11/1/2011",
            "11/2/2011",
            "11/3/2011",
            "11/4/2011",
            "11/7/2011",
            "11/8/2011",
            "11/9/2011",
            "11/10/2011",
            "11/11/2011",
            "11/14/2011",
            "11/15/2011",
            "11/16/2011",
            "11/17/2011",
            "11/18/2011",
            "11/21/2011",
            "11/22/2011",
            "11/23/2011",
            "11/24/2011",
            "11/25/2011",
            "11/28/2011",
            "11/29/2011",
            "11/30/2011",
            "12/1/2011",
            "12/2/2011",
            "12/5/2011",
            "12/6/2011",
            "12/7/2011",
            "12/8/2011",
            "12/9/2011",
            "12/12/2011",
            "12/13/2011",
            "12/14/2011",
            "12/15/2011",
            "12/16/2011",
            "12/19/2011",
            "12/20/2011",
            "12/21/2011",
            "12/22/2011",
            "12/23/2011",
            "12/26/2011",
            "12/27/2011",
            "12/28/2011",
            "12/29/2011",
            "12/30/2011",
            "1/2/2012",
            "1/3/2012",
            "1/4/2012",
            "1/5/2012",
            "1/6/2012",
            "1/9/2012",
            "1/10/2012",
            "1/11/2012",
            "1/12/2012",
            "1/13/2012",
            "1/16/2012",
            "1/17/2012",
            "1/18/2012",
            "1/19/2012",
            "1/20/2012",
            "1/23/2012",
            "1/24/2012",
            "1/25/2012",
            "1/26/2012",
            "1/27/2012",
            "1/30/2012",
            "1/31/2012",
            "2/1/2012",
            "2/2/2012",
            "2/3/2012",
            "2/6/2012",
            "2/7/2012",
            "2/8/2012",
            "2/9/2012",
            "2/10/2012",
            "2/13/2012",
            "2/14/2012",
            "2/15/2012",
            "2/16/2012",
            "2/17/2012",
            "2/20/2012",
            "2/21/2012",
            "2/22/2012",
            "2/23/2012",
            "2/24/2012",
            "2/27/2012",
            "2/28/2012",
            "2/29/2012",
            "3/1/2012",
            "3/2/2012",
            "3/5/2012",
            "3/6/2012",
            "3/7/2012",
            "3/8/2012",
            "3/9/2012",
            "3/12/2012",
            "3/13/2012",
            "3/14/2012",
            "3/15/2012",
            "3/16/2012",
            "3/19/2012",
            "3/20/2012",
            "3/21/2012",
            "3/22/2012",
            "3/23/2012",
            "3/26/2012",
            "3/27/2012",
            "3/28/2012",
            "3/29/2012",
            "3/30/2012",
            "4/2/2012",
            "4/3/2012",
            "4/4/2012",
            "4/5/2012",
            "4/6/2012",
            "4/9/2012",
            "4/10/2012",
            "4/11/2012",
            "4/12/2012",
            "4/13/2012",
            "4/16/2012",
            "4/17/2012",
            "4/18/2012",
            "4/19/2012",
            "4/20/2012",
            "4/23/2012",
            "4/24/2012",
            "4/25/2012",
            "4/26/2012",
            "4/27/2012",
            "4/30/2012",
            "5/1/2012",
            "5/2/2012",
            "5/3/2012",
            "5/4/2012",
            "5/7/2012",
            "5/8/2012",
            "5/9/2012",
            "5/10/2012",
            "5/11/2012",
            "5/14/2012",
            "5/15/2012",
            "5/16/2012",
            "5/17/2012",
            "5/18/2012",
            "5/21/2012",
            "5/22/2012",
            "5/23/2012",
            "5/24/2012",
            "5/25/2012",
            "5/28/2012",
            "5/29/2012",
            "5/30/2012",
            "5/31/2012",
            "6/1/2012",
            "6/4/2012",
            "6/5/2012",
            "6/6/2012",
            "6/7/2012",
            "6/8/2012",
            "6/11/2012",
            "6/12/2012",
            "6/13/2012",
            "6/14/2012",
            "6/15/2012",
            "6/18/2012",
            "6/19/2012",
            "6/20/2012",
            "6/21/2012",
            "6/22/2012",
            "6/25/2012",
            "6/26/2012",
            "6/27/2012",
            "6/28/2012",
            "6/29/2012",
            "7/2/2012",
            "7/3/2012",
            "7/4/2012",
            "7/5/2012",
            "7/6/2012",
            "7/9/2012",
            "7/10/2012",
            "7/11/2012",
            "7/12/2012",
            "7/13/2012",
            "7/16/2012",
            "7/17/2012",
            "7/18/2012",
            "7/19/2012",
            "7/20/2012",
            "7/23/2012",
            "7/24/2012",
            "7/25/2012",
            "7/26/2012",
            "7/27/2012",
            "7/30/2012",
            "7/31/2012",
            "8/1/2012",
            "8/2/2012",
            "8/3/2012",
            "8/6/2012",
            "8/7/2012",
            "8/8/2012",
            "8/9/2012",
            "8/10/2012",
            "8/13/2012",
            "8/14/2012",
            "8/15/2012",
            "8/16/2012",
            "8/17/2012",
            "8/20/2012",
            "8/21/2012",
            "8/22/2012",
            "8/23/2012",
            "8/24/2012",
            "8/27/2012",
            "8/28/2012",
            "8/29/2012",
            "8/30/2012",
            "8/31/2012",
            "9/3/2012",
            "9/4/2012",
            "9/5/2012",
            "9/6/2012",
            "9/7/2012",
            "9/10/2012",
            "9/11/2012",
            "9/12/2012",
            "9/13/2012",
            "9/14/2012",
            "9/17/2012",
            "9/18/2012",
            "9/19/2012",
            "9/20/2012",
            "9/21/2012",
            "9/24/2012",
            "9/25/2012",
            "9/26/2012",
            "9/27/2012",
            "9/28/2012",
            "10/1/2012",
            "10/2/2012",
            "10/3/2012",
            "10/4/2012",
            "10/5/2012",
            "10/8/2012",
            "10/9/2012",
            "10/10/2012",
            "10/11/2012",
            "10/12/2012",
            "10/15/2012",
            "10/16/2012",
            "10/17/2012",
            "10/18/2012",
            "10/19/2012",
            "10/22/2012",
            "10/23/2012",
            "10/24/2012",
            "10/25/2012",
            "10/26/2012",
            "10/29/2012",
            "10/30/2012",
            "10/31/2012",
            "11/1/2012",
            "11/2/2012",
            "11/5/2012",
            "11/6/2012",
            "11/7/2012",
            "11/8/2012",
            "11/9/2012",
            "11/12/2012",
            "11/13/2012",
            "11/14/2012",
            "11/15/2012",
            "11/16/2012",
            "11/19/2012",
            "11/20/2012",
            "11/21/2012",
            "11/22/2012",
            "11/23/2012",
            "11/26/2012",
            "11/27/2012",
            "11/28/2012",
            "11/29/2012",
            "11/30/2012",
            "12/3/2012",
            "12/4/2012",
            "12/5/2012",
            "12/6/2012",
            "12/7/2012",
            "12/10/2012",
            "12/11/2012",
            "12/12/2012",
            "12/13/2012",
            "12/14/2012",
            "12/17/2012",
            "12/18/2012",
            "12/19/2012",
            "12/20/2012",
            "12/21/2012",
            "12/24/2012",
            "12/25/2012",
            "12/26/2012",
            "12/27/2012",
            "12/28/2012",
            "12/31/2012",
            "1/1/2013",
            "1/2/2013",
            "1/3/2013",
            "1/4/2013",
            "1/7/2013",
            "1/8/2013",
            "1/9/2013",
            "1/10/2013",
            "1/11/2013",
            "1/14/2013",
            "1/15/2013",
            "1/16/2013",
            "1/17/2013",
            "1/18/2013",
            "1/21/2013",
            "1/22/2013",
            "1/23/2013",
            "1/24/2013",
            "1/25/2013",
            "1/28/2013",
            "1/29/2013",
            "1/30/2013",
            "1/31/2013",
            "2/1/2013",
            "2/4/2013",
            "2/5/2013",
            "2/6/2013",
            "2/7/2013",
            "2/8/2013",
            "2/11/2013",
            "2/12/2013",
            "2/13/2013",
            "2/14/2013",
            "2/15/2013",
            "2/18/2013",
            "2/19/2013",
            "2/20/2013",
            "2/21/2013",
            "2/22/2013",
            "2/25/2013",
            "2/26/2013",
            "2/27/2013",
            "2/28/2013",
            "3/1/2013",
            "3/4/2013",
            "3/5/2013",
            "3/6/2013",
            "3/7/2013",
            "3/8/2013",
            "3/11/2013",
            "3/12/2013",
            "3/13/2013",
            "3/14/2013",
            "3/15/2013",
            "3/18/2013",
            "3/19/2013",
            "3/20/2013",
            "3/21/2013",
            "3/22/2013",
            "3/25/2013",
            "3/26/2013",
            "3/27/2013",
            "3/28/2013",
            "3/29/2013",
            "4/1/2013",
            "4/2/2013",
            "4/3/2013",
            "4/4/2013",
            "4/5/2013",
            "4/8/2013",
            "4/9/2013",
            "4/10/2013",
            "4/11/2013",
            "4/12/2013",
            "4/15/2013",
            "4/16/2013",
            "4/17/2013",
            "4/18/2013",
            "4/19/2013",
            "4/22/2013",
            "4/23/2013",
            "4/24/2013",
            "4/25/2013",
            "4/26/2013",
            "4/29/2013",
            "4/30/2013",
            "5/1/2013",
            "5/2/2013",
            "5/3/2013",
            "5/6/2013",
            "5/7/2013",
            "5/8/2013",
            "5/9/2013",
            "5/10/2013",
            "5/13/2013",
            "5/14/2013",
            "5/15/2013",
            "5/16/2013",
            "5/17/2013",
            "5/20/2013",
            "5/21/2013",
            "5/22/2013",
            "5/23/2013",
            "5/24/2013",
            "5/27/2013",
            "5/28/2013",
            "5/29/2013",
            "5/30/2013",
            "5/31/2013",
            "6/3/2013",
            "6/4/2013",
            "6/5/2013",
            "6/6/2013",
            "6/7/2013",
            "6/10/2013",
            "6/11/2013",
            "6/12/2013",
            "6/13/2013",
            "6/14/2013",
            "6/17/2013",
            "6/18/2013",
            "6/19/2013",
            "6/20/2013",
            "6/21/2013",
            "6/24/2013",
            "6/25/2013",
            "6/26/2013",
            "6/27/2013",
            "6/28/2013",
            "7/1/2013",
            "7/2/2013",
            "7/3/2013",
            "7/4/2013",
            "7/5/2013",
            "7/8/2013",
            "7/9/2013",
            "7/10/2013",
            "7/11/2013",
            "7/12/2013",
            "7/15/2013",
            "7/16/2013",
            "7/17/2013",
            "7/18/2013",
            "7/19/2013",
            "7/22/2013",
            "7/23/2013",
            "7/24/2013",
            "7/25/2013",
            "7/26/2013",
            "7/29/2013",
            "7/30/2013",
            "7/31/2013",
            "8/1/2013",
            "8/2/2013",
            "8/5/2013",
            "8/6/2013",
            "8/7/2013",
            "8/8/2013",
            "8/9/2013",
            "8/12/2013",
            "8/13/2013",
            "8/14/2013",
            "8/15/2013",
            "8/16/2013",
            "8/19/2013",
            "8/20/2013",
            "8/21/2013",
            "8/22/2013",
            "8/23/2013",
            "8/26/2013",
            "8/27/2013",
            "8/28/2013",
            "8/29/2013",
            "8/30/2013",
            "9/2/2013",
            "9/3/2013",
            "9/4/2013",
            "9/5/2013",
            "9/6/2013",
            "9/9/2013",
            "9/10/2013",
            "9/11/2013",
            "9/12/2013",
            "9/13/2013",
            "9/16/2013",
            "9/17/2013",
            "9/18/2013",
            "9/19/2013",
            "9/20/2013",
            "9/23/2013",
            "9/24/2013",
            "9/25/2013",
            "9/26/2013",
            "9/27/2013",
            "9/30/2013",
            "10/1/2013",
            "10/2/2013",
            "10/3/2013",
            "10/4/2013",
            "10/7/2013",
            "10/8/2013",
            "10/9/2013",
            "10/10/2013",
            "10/11/2013",
            "10/14/2013",
            "10/15/2013",
            "10/16/2013",
            "10/17/2013",
            "10/18/2013",
            "10/21/2013",
            "10/22/2013",
            "10/23/2013",
            "10/24/2013",
            "10/25/2013",
            "10/28/2013",
            "10/29/2013",
            "10/30/2013",
            "10/31/2013",
            "11/1/2013",
            "11/4/2013",
            "11/5/2013",
            "11/6/2013",
            "11/7/2013",
            "11/8/2013",
            "11/11/2013",
            "11/12/2013",
            "11/13/2013",
            "11/14/2013",
            "11/15/2013",
            "11/18/2013",
            "11/19/2013",
            "11/20/2013",
            "11/21/2013",
            "11/22/2013",
            "11/25/2013",
            "11/26/2013",
            "11/27/2013",
            "11/28/2013",
            "11/29/2013",
            "12/2/2013",
            "12/3/2013",
            "12/4/2013",
            "12/5/2013",
            "12/6/2013",
            "12/9/2013",
            "12/10/2013",
            "12/11/2013",
            "12/12/2013",
            "12/13/2013",
            "12/16/2013",
            "12/17/2013",
            "12/18/2013",
            "12/19/2013",
            "12/20/2013",
            "12/23/2013",
            "12/24/2013",
            "12/25/2013",
            "12/26/2013",
            "12/27/2013",
            "12/30/2013",
            "12/31/2013",
            "1/1/2014",
            "1/2/2014",
            "1/3/2014",
            "1/6/2014",
            "1/7/2014",
            "1/8/2014",
            "1/9/2014",
            "1/10/2014",
            "1/13/2014",
            "1/14/2014",
            "1/15/2014",
            "1/16/2014",
            "1/17/2014",
            "1/20/2014",
            "1/21/2014",
            "1/22/2014",
            "1/23/2014",
            "1/24/2014",
            "1/27/2014",
            "1/28/2014",
            "1/29/2014",
            "1/30/2014",
            "1/31/2014",
            "2/3/2014",
            "2/4/2014",
            "2/5/2014",
            "2/6/2014",
            "2/7/2014",
            "2/10/2014",
            "2/11/2014",
            "2/12/2014",
            "2/13/2014",
            "2/14/2014",
            "2/17/2014",
            "2/18/2014",
            "2/19/2014",
            "2/20/2014",
            "2/21/2014",
            "2/24/2014",
            "2/25/2014",
            "2/26/2014",
            "2/27/2014",
            "2/28/2014",
            "3/3/2014",
            "3/4/2014",
            "3/5/2014",
            "3/6/2014",
            "3/7/2014",
            "3/10/2014",
            "3/11/2014",
            "3/12/2014",
            "3/13/2014",
            "3/14/2014",
            "3/17/2014",
            "3/18/2014",
            "3/19/2014",
            "3/20/2014",
            "3/21/2014",
            "3/24/2014",
            "3/25/2014",
            "3/26/2014",
            "3/27/2014",
            "3/28/2014",
            "3/31/2014",
            "4/1/2014",
            "4/2/2014",
            "4/3/2014",
            "4/4/2014",
            "4/7/2014",
            "4/8/2014",
            "4/9/2014",
            "4/10/2014",
            "4/11/2014",
            "4/14/2014",
            "4/15/2014",
            "4/16/2014",
            "4/17/2014",
            "4/18/2014",
            "4/21/2014",
            "4/22/2014",
            "4/23/2014",
            "4/24/2014",
            "4/25/2014",
            "4/28/2014",
            "4/29/2014",
            "4/30/2014",
            "5/1/2014",
            "5/2/2014",
            "5/5/2014",
            "5/6/2014",
            "5/7/2014",
            "5/8/2014",
            "5/9/2014",
            "5/12/2014",
            "5/13/2014",
            "5/14/2014",
            "5/15/2014",
            "5/16/2014",
            "5/19/2014",
            "5/20/2014",
            "5/21/2014",
            "5/22/2014",
            "5/23/2014",
            "5/26/2014",
            "5/27/2014",
            "5/28/2014",
            "5/29/2014",
            "5/30/2014",
            "6/2/2014",
            "6/3/2014",
            "6/4/2014",
            "6/5/2014",
            "6/6/2014",
            "6/9/2014",
            "6/10/2014",
            "6/11/2014",
            "6/12/2014",
            "6/13/2014",
            "6/16/2014",
            "6/17/2014",
            "6/18/2014",
            "6/19/2014",
            "6/20/2014",
            "6/23/2014",
            "6/24/2014",
            "6/25/2014",
            "6/26/2014",
            "6/27/2014",
            "6/30/2014",
            "7/1/2014",
            "7/2/2014",
            "7/3/2014",
            "7/4/2014",
            "7/7/2014",
            "7/8/2014",
            "7/9/2014",
            "7/10/2014",
            "7/11/2014",
            "7/14/2014",
            "7/15/2014",
            "7/16/2014",
            "7/17/2014",
            "7/18/2014",
            "7/21/2014",
            "7/22/2014",
            "7/23/2014",
            "7/24/2014",
            "7/25/2014",
            "7/28/2014",
            "7/29/2014",
            "7/30/2014",
            "7/31/2014",
            "8/1/2014",
            "8/4/2014",
            "8/5/2014",
            "8/6/2014",
            "8/7/2014",
            "8/8/2014",
            "8/11/2014",
            "8/12/2014",
            "8/13/2014",
            "8/14/2014",
            "8/15/2014",
            "8/18/2014",
            "8/19/2014",
            "8/20/2014",
            "8/21/2014",
            "8/22/2014",
            "8/25/2014",
            "8/26/2014",
            "8/27/2014",
            "8/28/2014",
            "8/29/2014",
            "9/1/2014",
            "9/2/2014",
            "9/3/2014",
            "9/4/2014",
            "9/5/2014",
            "9/8/2014",
            "9/9/2014",
            "9/10/2014",
            "9/11/2014",
            "9/12/2014",
            "9/15/2014",
            "9/16/2014",
            "9/17/2014",
            "9/18/2014",
            "9/19/2014",
            "9/22/2014",
            "9/23/2014",
            "9/24/2014",
            "9/25/2014",
            "9/26/2014",
            "9/29/2014",
            "9/30/2014",
            "10/1/2014",
            "10/2/2014",
            "10/3/2014",
            "10/6/2014",
            "10/7/2014",
            "10/8/2014",
            "10/9/2014",
            "10/10/2014",
            "10/13/2014",
            "10/14/2014",
            "10/15/2014",
            "10/16/2014",
            "10/17/2014",
            "10/20/2014",
            "10/21/2014",
            "10/22/2014",
            "10/23/2014",
            "10/24/2014",
            "10/27/2014",
            "10/28/2014",
            "10/29/2014",
            "10/30/2014",
            "10/31/2014",
            "11/3/2014",
            "11/4/2014",
            "11/5/2014",
            "11/6/2014",
            "11/7/2014",
            "11/10/2014",
            "11/11/2014",
            "11/12/2014",
            "11/13/2014",
            "11/14/2014",
            "11/17/2014",
            "11/18/2014",
            "11/19/2014",
            "11/20/2014",
            "11/21/2014",
            "11/24/2014",
            "11/25/2014",
            "11/26/2014",
            "11/27/2014",
            "11/28/2014",
            "12/1/2014",
            "12/2/2014",
            "12/3/2014",
            "12/4/2014",
            "12/5/2014",
            "12/8/2014",
            "12/9/2014",
            "12/10/2014",
            "12/11/2014",
            "12/12/2014",
            "12/15/2014",
            "12/16/2014",
            "12/17/2014",
            "12/18/2014",
            "12/19/2014",
            "12/22/2014",
            "12/23/2014",
            "12/24/2014",
            "12/25/2014",
            "12/26/2014",
            "12/29/2014",
            "12/30/2014",
            "12/31/2014",
            "1/1/2015",
            "1/2/2015",
            "1/5/2015",
            "1/6/2015",
            "1/7/2015",
            "1/8/2015",
            "1/9/2015",
            "1/12/2015",
            "1/13/2015",
            "1/14/2015",
            "1/15/2015",
            "1/16/2015",
            "1/19/2015",
            "1/20/2015",
            "1/21/2015",
            "1/22/2015",
            "1/23/2015",
            "1/26/2015",
            "1/27/2015",
            "1/28/2015",
            "1/29/2015",
            "1/30/2015",
            "2/2/2015",
            "2/3/2015",
            "2/4/2015",
            "2/5/2015",
            "2/6/2015",
            "2/9/2015",
            "2/10/2015",
            "2/11/2015",
            "2/12/2015",
            "2/13/2015",
            "2/16/2015",
            "2/17/2015",
            "2/18/2015",
            "2/19/2015",
            "2/20/2015",
            "2/23/2015",
            "2/24/2015",
            "2/25/2015",
            "2/26/2015",
            "2/27/2015",
            "3/2/2015",
            "3/3/2015",
            "3/4/2015",
            "3/5/2015",
            "3/6/2015",
            "3/9/2015",
            "3/10/2015",
            "3/11/2015",
            "3/12/2015",
            "3/13/2015",
            "3/16/2015",
            "3/17/2015",
            "3/18/2015",
            "3/19/2015",
            "3/20/2015",
            "3/23/2015",
            "3/24/2015",
            "3/25/2015",
            "3/26/2015",
            "3/27/2015",
            "3/30/2015",
            "3/31/2015",
            "4/1/2015",
            "4/2/2015",
            "4/3/2015",
            "4/6/2015",
            "4/7/2015",
            "4/8/2015",
            "4/9/2015",
            "4/10/2015",
            "4/13/2015",
            "4/14/2015",
            "4/15/2015",
            "4/16/2015",
            "4/17/2015",
            "4/20/2015",
            "4/21/2015",
            "4/22/2015",
            "4/23/2015",
            "4/24/2015",
            "4/27/2015",
            "4/28/2015",
            "4/29/2015",
            "4/30/2015",
            "5/1/2015",
            "5/4/2015",
            "5/5/2015",
            "5/6/2015",
            "5/7/2015",
            "5/8/2015",
            "5/11/2015",
            "5/12/2015",
            "5/13/2015",
            "5/14/2015",
            "5/15/2015",
            "5/18/2015",
            "5/19/2015",
            "5/20/2015",
            "5/21/2015",
            "5/22/2015",
            "5/25/2015",
            "5/26/2015",
            "5/27/2015",
            "5/28/2015",
            "5/29/2015",
            "6/1/2015",
            "6/2/2015",
            "6/3/2015",
            "6/4/2015",
            "6/5/2015",
            "6/8/2015",
            "6/9/2015",
            "6/10/2015",
            "6/11/2015",
            "6/12/2015",
            "6/15/2015",
            "6/16/2015",
            "6/17/2015",
            "6/18/2015",
            "6/19/2015",
            "6/22/2015",
            "6/23/2015",
            "6/24/2015",
            "6/25/2015",
            "6/26/2015",
            "6/29/2015",
            "6/30/2015",
            "7/1/2015",
            "7/2/2015",
            "7/3/2015",
            "7/6/2015",
            "7/7/2015",
            "7/8/2015",
            "7/9/2015",
            "7/10/2015",
            "7/13/2015",
            "7/14/2015",
            "7/15/2015",
            "7/16/2015",
            "7/17/2015",
            "7/20/2015",
            "7/21/2015",
            "7/22/2015",
            "7/23/2015",
            "7/24/2015",
            "7/27/2015",
            "7/28/2015",
            "7/29/2015",
            "7/30/2015",
            "7/31/2015",
            "8/3/2015",
            "8/4/2015",
            "8/5/2015",
            "8/6/2015",
            "8/7/2015",
            "8/10/2015",
            "8/11/2015",
            "8/12/2015",
            "8/13/2015",
            "8/14/2015",
            "8/17/2015",
            "8/18/2015",
            "8/19/2015",
            "8/20/2015",
            "8/21/2015",
            "8/24/2015",
            "8/25/2015",
            "8/26/2015",
            "8/27/2015",
            "8/28/2015",
            "8/31/2015",
            "9/1/2015",
            "9/2/2015",
            "9/3/2015",
            "9/4/2015",
            "9/7/2015",
            "9/8/2015",
            "9/9/2015",
            "9/10/2015",
            "9/11/2015",
            "9/14/2015",
            "9/15/2015",
            "9/16/2015",
            "9/17/2015",
            "9/18/2015",
            "9/21/2015",
            "9/22/2015",
            "9/23/2015",
            "9/24/2015",
            "9/25/2015",
            "9/28/2015",
            "9/29/2015",
            "9/30/2015",
            "10/1/2015",
            "10/2/2015",
            "10/5/2015",
            "10/6/2015",
            "10/7/2015",
            "10/8/2015",
            "10/9/2015",
            "10/12/2015",
            "10/13/2015",
            "10/14/2015",
            "10/15/2015",
            "10/16/2015",
            "10/19/2015",
            "10/20/2015",
            "10/21/2015",
            "10/22/2015",
            "10/23/2015",
            "10/26/2015",
            "10/27/2015",
            "10/28/2015",
            "10/29/2015",
            "10/30/2015",
            "11/2/2015",
            "11/3/2015",
            "11/4/2015",
            "11/5/2015",
            "11/6/2015",
            "11/9/2015",
            "11/10/2015",
            "11/11/2015",
            "11/12/2015",
            "11/13/2015",
            "11/16/2015",
            "11/17/2015",
            "11/18/2015",
            "11/19/2015",
            "11/20/2015",
            "11/23/2015",
            "11/24/2015",
            "11/25/2015",
            "11/26/2015",
            "11/27/2015",
            "11/30/2015",
            "12/1/2015",
            "12/2/2015",
            "12/3/2015",
            "12/4/2015",
            "12/7/2015",
            "12/8/2015",
            "12/9/2015",
            "12/10/2015",
            "12/11/2015",
            "12/14/2015",
            "12/15/2015",
            "12/16/2015",
            "12/17/2015",
            "12/18/2015",
            "12/21/2015",
            "12/22/2015",
            "12/23/2015",
            "12/24/2015",
            "12/25/2015",
            "12/28/2015",
            "12/29/2015",
            "12/30/2015",
            "12/31/2015",
            "1/1/2016",
            "1/4/2016",
            "1/5/2016",
            "1/6/2016",
            "1/7/2016",
            "1/8/2016",
            "1/11/2016",
            "1/12/2016",
            "1/13/2016",
            "1/14/2016",
            "1/15/2016",
            "1/18/2016",
            "1/19/2016",
            "1/20/2016",
            "1/21/2016",
            "1/22/2016",
            "1/25/2016",
            "1/26/2016",
            "1/27/2016",
            "1/28/2016",
            "1/29/2016",
            "2/1/2016",
            "2/2/2016",
            "2/3/2016",
            "2/4/2016",
            "2/5/2016",
            "2/8/2016",
            "2/9/2016",
            "2/10/2016",
            "2/11/2016",
            "2/12/2016",
            "2/15/2016",
            "2/16/2016",
            "2/17/2016",
            "2/18/2016",
            "2/19/2016",
            "2/22/2016",
            "2/23/2016",
            "2/24/2016",
            "2/25/2016",
            "2/26/2016",
            "2/29/2016",
            "3/1/2016",
            "3/2/2016",
            "3/3/2016",
            "3/4/2016",
            "3/7/2016",
            "3/8/2016",
            "3/9/2016",
            "3/10/2016",
            "3/11/2016",
            "3/14/2016",
            "3/15/2016",
            "3/16/2016",
            "3/17/2016",
            "3/18/2016",
            "3/21/2016",
            "3/22/2016",
            "3/23/2016",
            "3/24/2016",
            "3/25/2016",
            "3/28/2016",
            "3/29/2016",
            "3/30/2016",
            "3/31/2016",
            "4/1/2016",
            "4/4/2016",
            "4/5/2016",
            "4/6/2016",
            "4/7/2016",
            "4/8/2016",
            "4/11/2016",
            "4/12/2016",
            "4/13/2016",
            "4/14/2016",
            "4/15/2016",
            "4/18/2016",
            "4/19/2016",
            "4/20/2016",
            "4/21/2016",
            "4/22/2016",
            "4/25/2016",
            "4/26/2016",
            "4/27/2016",
            "4/28/2016",
            "4/29/2016",
            "5/2/2016",
            "5/3/2016",
            "5/4/2016",
            "5/5/2016",
            "5/6/2016",
            "5/9/2016",
            "5/10/2016",
            "5/11/2016",
            "5/12/2016",
            "5/13/2016",
            "5/16/2016",
            "5/17/2016",
            "5/18/2016",
            "5/19/2016",
            "5/20/2016",
            "5/23/2016",
            "5/24/2016",
            "5/25/2016",
            "5/26/2016",
            "5/27/2016",
            "5/30/2016",
            "5/31/2016",
            "6/1/2016",
            "6/2/2016",
            "6/3/2016",
            "6/6/2016",
            "6/7/2016",
            "6/8/2016",
            "6/9/2016",
            "6/10/2016",
            "6/13/2016",
            "6/14/2016",
            "6/15/2016",
            "6/16/2016",
            "6/17/2016",
            "6/20/2016",
            "6/21/2016",
            "6/22/2016",
            "6/23/2016",
            "6/24/2016",
            "6/27/2016",
            "6/28/2016",
            "6/29/2016",
            "6/30/2016",
            "7/1/2016",
            "7/4/2016",
            "7/5/2016",
            "7/6/2016",
            "7/7/2016",
            "7/8/2016",
            "7/11/2016",
            "7/12/2016",
            "7/13/2016",
            "7/14/2016",
            "7/15/2016",
            "7/18/2016",
            "7/19/2016",
            "7/20/2016",
            "7/21/2016",
            "7/22/2016",
            "7/25/2016",
            "7/26/2016",
            "7/27/2016",
            "7/28/2016",
            "7/29/2016",
            "8/1/2016",
            "8/2/2016",
            "8/3/2016",
            "8/4/2016",
            "8/5/2016",
            "8/8/2016",
            "8/9/2016",
            "8/10/2016",
            "8/11/2016",
            "8/12/2016",
            "8/15/2016",
            "8/16/2016",
            "8/17/2016",
            "8/18/2016",
            "8/19/2016",
            "8/22/2016",
            "8/23/2016",
            "8/24/2016",
            "8/25/2016",
            "8/26/2016",
            "8/29/2016",
            "8/30/2016",
            "8/31/2016",
            "9/1/2016",
            "9/2/2016",
            "9/5/2016",
            "9/6/2016",
            "9/7/2016",
            "9/8/2016",
            "9/9/2016",
            "9/12/2016",
            "9/13/2016",
            "9/14/2016",
            "9/15/2016",
            "9/16/2016",
            "9/19/2016",
            "9/20/2016",
            "9/21/2016",
            "9/22/2016",
            "9/23/2016",
            "9/26/2016",
            "9/27/2016",
            "9/28/2016",
            "9/29/2016",
            "9/30/2016",
            "10/3/2016",
            "10/4/2016",
            "10/5/2016",
            "10/6/2016",
            "10/7/2016",
            "10/10/2016",
            "10/11/2016",
            "10/12/2016",
            "10/13/2016",
            "10/14/2016",
            "10/17/2016",
            "10/18/2016",
            "10/19/2016",
            "10/20/2016",
            "10/21/2016",
            "10/24/2016",
            "10/25/2016",
            "10/26/2016",
            "10/27/2016",
            "10/28/2016",
            "10/31/2016",
            "11/1/2016",
            "11/2/2016",
            "11/3/2016",
            "11/4/2016",
            "11/7/2016",
            "11/8/2016",
            "11/9/2016",
            "11/10/2016",
            "11/11/2016",
            "11/14/2016",
            "11/15/2016",
            "11/16/2016",
            "11/17/2016",
            "11/18/2016",
            "11/21/2016",
            "11/22/2016",
            "11/23/2016",
            "11/24/2016",
            "11/25/2016",
            "11/28/2016",
            "11/29/2016",
            "11/30/2016",
            "12/1/2016",
            "12/2/2016",
            "12/5/2016",
            "12/6/2016",
            "12/7/2016",
            "12/8/2016",
            "12/9/2016",
            "12/12/2016",
            "12/13/2016",
            "12/14/2016",
            "12/15/2016",
            "12/16/2016",
            "12/19/2016",
            "12/20/2016",
            "12/21/2016",
            "12/22/2016",
            "12/23/2016",
            "12/26/2016",
            "12/27/2016",
            "12/28/2016",
            "12/29/2016",
            "12/30/2016",
            "1/2/2017",
            "1/3/2017",
            "1/4/2017",
            "1/5/2017",
            "1/6/2017",
            "1/9/2017",
            "1/10/2017",
            "1/11/2017",
            "1/12/2017",
            "1/13/2017",
            "1/16/2017",
            "1/17/2017",
            "1/18/2017",
            "1/19/2017",
            "1/20/2017",
            "1/23/2017",
            "1/24/2017",
            "1/25/2017",
            "1/26/2017",
            "1/27/2017",
            "1/30/2017",
            "1/31/2017",
            "2/1/2017",
            "2/2/2017",
            "2/3/2017",
            "2/6/2017",
            "2/7/2017",
            "2/8/2017",
            "2/9/2017",
            "2/10/2017",
            "2/13/2017",
            "2/14/2017",
            "2/15/2017",
            "2/16/2017",
            "2/17/2017",
            "2/20/2017",
            "2/21/2017",
            "2/22/2017",
            "2/23/2017",
            "2/24/2017",
            "2/27/2017",
            "2/28/2017",
            "3/1/2017",
            "3/2/2017",
            "3/3/2017",
            "3/6/2017",
            "3/7/2017",
            "3/8/2017",
            "3/9/2017",
            "3/10/2017",
            "3/13/2017",
            "3/14/2017",
            "3/15/2017",
            "3/16/2017",
            "3/17/2017",
            "3/20/2017",
            "3/21/2017",
            "3/22/2017",
            "3/23/2017",
            "3/24/2017",
            "3/27/2017",
            "3/28/2017",
            "3/29/2017",
            "3/30/2017",
            "3/31/2017",
            "4/3/2017",
            "4/4/2017",
            "4/5/2017",
            "4/6/2017",
            "4/7/2017",
            "4/10/2017",
            "4/11/2017",
            "4/12/2017",
            "4/13/2017",
            "4/14/2017",
            "4/17/2017",
            "4/18/2017",
            "4/19/2017",
            "4/20/2017",
            "4/21/2017",
            "4/24/2017",
            "4/25/2017",
            "4/26/2017",
            "4/27/2017",
            "4/28/2017",
            "5/1/2017",
            "5/2/2017",
            "5/3/2017",
            "5/4/2017",
            "5/5/2017",
            "5/8/2017",
            "5/9/2017",
            "5/10/2017",
            "5/11/2017",
            "5/12/2017",
            "5/15/2017",
            "5/16/2017",
            "5/17/2017",
            "5/18/2017",
            "5/19/2017",
            "5/22/2017",
            "5/23/2017",
            "5/24/2017",
            "5/25/2017",
            "5/26/2017",
            "5/29/2017",
            "5/30/2017",
            "5/31/2017",
            "6/1/2017",
            "6/2/2017",
            "6/5/2017",
            "6/6/2017",
            "6/7/2017",
            "6/8/2017",
            "6/9/2017",
            "6/12/2017",
            "6/13/2017",
            "6/14/2017",
            "6/15/2017",
            "6/16/2017",
            "6/19/2017",
            "6/20/2017",
            "6/21/2017",
            "6/22/2017",
            "6/23/2017",
            "6/26/2017",
            "6/27/2017",
            "6/28/2017",
            "6/29/2017",
            "6/30/2017",
            "7/3/2017",
            "7/4/2017",
            "7/5/2017",
            "7/6/2017",
            "7/7/2017",
            "7/10/2017",
            "7/11/2017",
            "7/12/2017",
            "7/13/2017",
            "7/14/2017",
            "7/17/2017",
            "7/18/2017",
            "7/19/2017",
            "7/20/2017",
            "7/21/2017",
            "7/24/2017",
            "7/25/2017",
            "7/26/2017",
            "7/27/2017",
            "7/28/2017",
            "7/31/2017",
            "8/1/2017",
            "8/2/2017",
            "8/3/2017",
            "8/4/2017",
            "8/7/2017",
            "8/8/2017",
            "8/9/2017",
            "8/10/2017",
            "8/11/2017",
            "8/14/2017",
            "8/15/2017",
            "8/16/2017",
            "8/17/2017",
            "8/18/2017",
            "8/21/2017",
            "8/22/2017",
            "8/23/2017",
            "8/24/2017",
            "8/25/2017",
            "8/28/2017",
            "8/29/2017",
            "8/30/2017",
            "8/31/2017",
            "9/1/2017",
            "9/4/2017",
            "9/5/2017",
            "9/6/2017",
            "9/7/2017",
            "9/8/2017",
            "9/11/2017",
            "9/12/2017",
            "9/13/2017",
            "9/14/2017",
            "9/15/2017",
            "9/18/2017",
            "9/19/2017",
            "9/20/2017",
            "9/21/2017",
            "9/22/2017",
            "9/25/2017",
            "9/26/2017",
            "9/27/2017",
            "9/28/2017",
            "9/29/2017",
            "10/2/2017",
            "10/3/2017",
            "10/4/2017",
            "10/5/2017",
            "10/6/2017",
            "10/9/2017",
            "10/10/2017",
            "10/11/2017",
            "10/12/2017",
            "10/13/2017",
            "10/16/2017",
            "10/17/2017",
            "10/18/2017",
            "10/19/2017",
            "10/20/2017",
            "10/23/2017",
            "10/24/2017",
            "10/25/2017",
            "10/26/2017",
            "10/27/2017",
            "10/30/2017",
            "10/31/2017",
            "11/1/2017",
            "11/2/2017",
            "11/3/2017",
            "11/6/2017",
            "11/7/2017",
            "11/8/2017",
            "11/9/2017",
            "11/10/2017",
            "11/13/2017",
            "11/14/2017",
            "11/15/2017",
            "11/16/2017",
            "11/17/2017",
            "11/20/2017",
            "11/21/2017",
            "11/22/2017",
            "11/23/2017",
            "11/24/2017",
            "11/27/2017",
            "11/28/2017",
            "11/29/2017",
            "11/30/2017",
            "12/1/2017",
            "12/4/2017",
            "12/5/2017",
            "12/6/2017",
            "12/7/2017",
            "12/8/2017",
            "12/11/2017",
            "12/12/2017",
            "12/13/2017",
            "12/14/2017",
            "12/15/2017",
            "12/18/2017",
            "12/19/2017",
            "12/20/2017",
            "12/21/2017",
            "12/22/2017",
            "12/25/2017",
            "12/26/2017",
            "12/27/2017",
            "12/28/2017",
            "12/29/2017",
            "1/1/2018",
            "1/2/2018",
            "1/3/2018",
            "1/4/2018",
            "1/5/2018",
            "1/8/2018",
            "1/9/2018",
            "1/10/2018",
            "1/11/2018",
            "1/12/2018",
            "1/15/2018",
            "1/16/2018",
            "1/17/2018",
            "1/18/2018",
            "1/19/2018",
            "1/22/2018",
            "1/23/2018",
            "1/24/2018",
            "1/25/2018",
            "1/26/2018",
            "1/29/2018",
            "1/30/2018",
            "1/31/2018",
            "2/1/2018",
            "2/2/2018",
            "2/5/2018",
            "2/6/2018",
            "2/7/2018",
            "2/8/2018",
            "2/9/2018",
            "2/12/2018",
            "2/13/2018",
            "2/14/2018",
            "2/15/2018",
            "2/16/2018",
            "2/19/2018",
            "2/20/2018",
            "2/21/2018",
            "2/22/2018",
            "2/23/2018",
            "2/26/2018",
            "2/27/2018",
            "2/28/2018",
            "3/1/2018",
            "3/2/2018",
            "3/5/2018",
            "3/6/2018",
            "3/7/2018",
            "3/8/2018",
            "3/9/2018",
            "3/12/2018",
            "3/13/2018",
            "3/14/2018",
            "3/15/2018",
            "3/16/2018",
            "3/19/2018",
            "3/20/2018",
            "3/21/2018",
            "3/22/2018",
            "3/23/2018",
            "3/26/2018",
            "3/27/2018",
            "3/28/2018",
            "3/29/2018",
            "3/30/2018",
            "4/2/2018",
            "4/3/2018",
            "4/4/2018",
            "4/5/2018",
            "4/6/2018",
            "4/9/2018",
            "4/10/2018",
            "4/11/2018",
            "4/12/2018",
            "4/13/2018",
            "4/16/2018",
            "4/17/2018",
            "4/18/2018",
            "4/19/2018",
            "4/20/2018",
            "4/23/2018",
            "4/24/2018",
            "4/25/2018",
            "4/26/2018",
            "4/27/2018",
            "4/30/2018",
            "5/1/2018",
            "5/2/2018",
            "5/3/2018",
            "5/4/2018",
            "5/7/2018",
            "5/8/2018",
            "5/9/2018",
            "5/10/2018",
            "5/11/2018",
            "5/14/2018",
            "5/15/2018",
            "5/16/2018",
            "5/17/2018",
            "5/18/2018",
            "5/21/2018",
            "5/22/2018",
            "5/23/2018",
            "5/24/2018",
            "5/25/2018",
            "5/28/2018",
            "5/29/2018",
            "5/30/2018",
            "5/31/2018",
            "6/1/2018",
            "6/4/2018",
            "6/5/2018",
            "6/6/2018",
            "6/7/2018",
            "6/8/2018",
            "6/11/2018",
            "6/12/2018",
            "6/13/2018",
            "6/14/2018",
            "6/15/2018",
            "6/18/2018",
            "6/19/2018",
            "6/20/2018",
            "6/21/2018",
            "6/22/2018",
            "6/25/2018",
            "6/26/2018",
            "6/27/2018",
            "6/28/2018",
            "6/29/2018",
            "7/2/2018",
            "7/3/2018",
            "7/4/2018",
            "7/5/2018",
            "7/6/2018",
            "7/9/2018",
            "7/10/2018",
            "7/11/2018",
            "7/12/2018",
            "7/13/2018",
            "7/16/2018",
            "7/17/2018",
            "7/18/2018",
            "7/19/2018",
            "7/20/2018",
            "7/23/2018",
            "7/24/2018",
            "7/25/2018",
            "7/26/2018",
            "7/27/2018",
            "7/30/2018",
            "7/31/2018",
            "8/1/2018",
            "8/2/2018",
            "8/3/2018",
            "8/6/2018",
            "8/7/2018",
            "8/8/2018",
            "8/9/2018",
            "8/10/2018",
            "8/13/2018",
            "8/14/2018",
            "8/15/2018",
            "8/16/2018",
            "8/17/2018",
            "8/20/2018",
            "8/21/2018",
            "8/22/2018",
            "8/23/2018",
            "8/24/2018",
            "8/27/2018",
            "8/28/2018",
            "8/29/2018",
            "8/30/2018",
            "8/31/2018",
            "9/3/2018",
            "9/4/2018",
            "9/5/2018",
            "9/6/2018",
            "9/7/2018",
            "9/10/2018",
            "9/11/2018",
            "9/12/2018",
            "9/13/2018",
            "9/14/2018",
            "9/17/2018",
            "9/18/2018",
            "9/19/2018",
            "9/20/2018",
            "9/21/2018",
            "9/24/2018",
            "9/25/2018",
            "9/26/2018",
            "9/27/2018",
            "9/28/2018",
            "10/1/2018",
            "10/2/2018",
            "10/3/2018",
            "10/4/2018",
            "10/5/2018",
            "10/8/2018",
            "10/9/2018",
            "10/10/2018",
            "10/11/2018",
            "10/12/2018",
            "10/15/2018",
            "10/16/2018",
            "10/17/2018",
            "10/18/2018",
            "10/19/2018",
            "10/22/2018",
            "10/23/2018",
            "10/24/2018",
            "10/25/2018",
            "10/26/2018",
            "10/29/2018",
            "10/30/2018",
            "10/31/2018",
            "11/1/2018",
            "11/2/2018",
            "11/5/2018",
            "11/6/2018",
            "11/7/2018",
            "11/8/2018",
            "11/9/2018",
            "11/12/2018",
            "11/13/2018",
            "11/14/2018",
            "11/15/2018",
            "11/16/2018",
            "11/19/2018",
            "11/20/2018",
            "11/21/2018",
            "11/22/2018",
            "11/23/2018",
            "11/26/2018",
            "11/27/2018",
            "11/28/2018",
            "11/29/2018",
            "11/30/2018",
            "12/3/2018",
            "12/4/2018",
            "12/5/2018",
            "12/6/2018",
            "12/7/2018",
            "12/10/2018",
            "12/11/2018",
            "12/12/2018",
            "12/13/2018",
            "12/14/2018",
            "12/17/2018",
            "12/18/2018",
            "12/19/2018",
            "12/20/2018",
            "12/21/2018",
            "12/24/2018",
            "12/25/2018",
            "12/26/2018",
            "12/27/2018",
            "12/28/2018",
            "12/31/2018",
            "1/1/2019",
            "1/2/2019",
            "1/3/2019",
            "1/4/2019",
            "1/7/2019",
            "1/8/2019",
            "1/9/2019",
            "1/10/2019",
            "1/11/2019",
            "1/14/2019",
            "1/15/2019",
            "1/16/2019",
            "1/17/2019",
            "1/18/2019",
            "1/21/2019",
            "1/22/2019",
            "1/23/2019",
            "1/24/2019",
            "1/25/2019",
            "1/28/2019",
            "1/29/2019",
            "1/30/2019",
            "1/31/2019",
            "2/1/2019",
            "2/4/2019",
            "2/5/2019",
            "2/6/2019",
            "2/7/2019",
            "2/8/2019",
            "2/11/2019",
            "2/12/2019",
            "2/13/2019",
            "2/14/2019",
            "2/15/2019",
            "2/18/2019",
            "2/19/2019",
            "2/20/2019",
            "2/21/2019",
            "2/22/2019",
            "2/25/2019",
            "2/26/2019",
            "2/27/2019",
            "2/28/2019",
            "3/1/2019",
            "3/4/2019",
            "3/5/2019",
            "3/6/2019",
            "3/7/2019",
            "3/8/2019",
            "3/11/2019",
            "3/12/2019",
            "3/13/2019",
            "3/14/2019",
            "3/15/2019",
            "3/18/2019",
            "3/19/2019",
            "3/20/2019",
            "3/21/2019",
            "3/22/2019",
            "3/25/2019",
            "3/26/2019",
            "3/27/2019",
            "3/28/2019",
            "3/29/2019",
            "4/1/2019",
            "4/2/2019",
            "4/3/2019",
            "4/4/2019",
            "4/5/2019",
            "4/8/2019",
            "4/9/2019",
            "4/10/2019",
            "4/11/2019",
            "4/12/2019",
            "4/15/2019",
            "4/16/2019",
            "4/17/2019",
            "4/18/2019",
            "4/19/2019",
            "4/22/2019",
            "4/23/2019",
            "4/24/2019",
            "4/25/2019",
            "4/26/2019",
            "4/29/2019",
            "4/30/2019",
            "5/1/2019",
            "5/2/2019",
            "5/3/2019",
            "5/6/2019",
            "5/7/2019",
            "5/8/2019",
            "5/9/2019",
            "5/10/2019",
            "5/13/2019",
            "5/14/2019",
            "5/15/2019",
            "5/16/2019",
            "5/17/2019",
            "5/20/2019",
            "5/21/2019",
            "5/22/2019",
            "5/23/2019",
            "5/24/2019",
            "5/27/2019",
            "5/28/2019",
            "5/29/2019",
            "5/30/2019",
            "5/31/2019",
            "6/3/2019",
            "6/4/2019",
            "6/5/2019",
            "6/6/2019",
            "6/7/2019",
            "6/10/2019",
            "6/11/2019",
            "6/12/2019",
            "6/13/2019",
            "6/14/2019",
            "6/17/2019",
            "6/18/2019",
            "6/19/2019",
            "6/20/2019",
            "6/21/2019",
            "6/24/2019",
            "6/25/2019",
            "6/26/2019",
            "6/27/2019",
            "6/28/2019",
            "7/1/2019",
            "7/2/2019",
            "7/3/2019",
            "7/4/2019",
            "7/5/2019",
            "7/8/2019",
            "7/9/2019",
            "7/10/2019",
            "7/11/2019",
            "7/12/2019",
            "7/15/2019",
            "7/16/2019",
            "7/17/2019",
            "7/18/2019",
            "7/19/2019",
            "7/22/2019",
            "7/23/2019",
            "7/24/2019",
            "7/25/2019",
            "7/26/2019",
            "7/29/2019",
            "7/30/2019",
            "7/31/2019",
            "8/1/2019",
            "8/2/2019",
            "8/5/2019",
            "8/6/2019",
            "8/7/2019",
            "8/8/2019",
            "8/9/2019",
            "8/12/2019",
            "8/13/2019",
            "8/14/2019",
            "8/15/2019",
            "8/16/2019",
            "8/19/2019",
            "8/20/2019",
            "8/21/2019",
            "8/22/2019",
            "8/23/2019",
            "8/26/2019",
            "8/27/2019",
            "8/28/2019",
            "8/29/2019",
            "8/30/2019",
            "9/2/2019",
            "9/3/2019",
            "9/4/2019",
            "9/5/2019",
            "9/6/2019",
            "9/9/2019",
            "9/10/2019",
            "9/11/2019",
            "9/12/2019",
            "9/13/2019",
            "9/16/2019",
            "9/17/2019",
            "9/18/2019",
            "9/19/2019",
            "9/20/2019",
            "9/23/2019",
            "9/24/2019",
            "9/25/2019",
            "9/26/2019",
            "9/27/2019",
            "9/30/2019",
            "10/1/2019",
            "10/2/2019",
            "10/3/2019",
            "10/4/2019",
            "10/7/2019",
            "10/8/2019",
            "10/9/2019",
            "10/10/2019",
            "10/11/2019",
            "10/14/2019",
            "10/15/2019",
            "10/16/2019",
            "10/17/2019",
            "10/18/2019",
            "10/21/2019",
            "10/22/2019",
            "10/23/2019",
            "10/24/2019",
            "10/25/2019",
            "10/28/2019",
            "10/29/2019",
            "10/30/2019",
            "10/31/2019",
            "11/1/2019",
            "11/4/2019",
            "11/5/2019",
            "11/6/2019",
            "11/7/2019",
            "11/8/2019",
            "11/11/2019",
            "11/12/2019",
            "11/13/2019",
            "11/14/2019",
            "11/15/2019",
            "11/18/2019",
            "11/19/2019",
            "11/20/2019",
            "11/21/2019",
            "11/22/2019",
            "11/25/2019",
            "11/26/2019",
            "11/27/2019",
            "11/28/2019",
            "11/29/2019",
            "12/2/2019",
            "12/3/2019",
            "12/4/2019",
            "12/5/2019",
            "12/6/2019",
            "12/9/2019",
            "12/10/2019",
            "12/11/2019",
            "12/12/2019",
            "12/13/2019",
            "12/16/2019",
            "12/17/2019",
            "12/18/2019",
            "12/19/2019",
            "12/20/2019",
            "12/23/2019",
            "12/24/2019",
            "12/25/2019",
            "12/26/2019",
            "12/27/2019",
            "12/30/2019",
            "12/31/2019",
            "1/1/2020",
            "1/2/2020",
            "1/3/2020",
            "1/6/2020",
            "1/7/2020",
            "1/8/2020",
            "1/9/2020",
            "1/10/2020",
            "1/13/2020",
            "1/14/2020",
            "1/15/2020",
            "1/16/2020",
            "1/17/2020",
            "1/20/2020",
            "1/21/2020",
            "1/22/2020",
            "1/23/2020",
            "1/24/2020",
            "1/27/2020",
            "1/28/2020",
            "1/29/2020",
            "1/30/2020",
            "1/31/2020",
            "2/3/2020",
            "2/4/2020",
            "2/5/2020",
            "2/6/2020",
            "2/7/2020",
            "2/10/2020",
            "2/11/2020",
            "2/12/2020",
            "2/13/2020",
            "2/14/2020",
            "2/17/2020",
            "2/18/2020",
            "2/19/2020",
            "2/20/2020",
            "2/21/2020",
            "2/24/2020",
            "2/25/2020",
            "2/26/2020",
            "2/27/2020",
            "2/28/2020",
            "3/2/2020",
            "3/3/2020",
            "3/4/2020",
            "3/5/2020",
            "3/6/2020",
            "3/9/2020",
            "3/10/2020",
            "3/11/2020",
            "3/12/2020",
            "3/13/2020",
            "3/16/2020",
            "3/17/2020",
            "3/18/2020",
            "3/19/2020",
            "3/20/2020",
            "3/23/2020",
            "3/24/2020",
            "3/25/2020",
            "3/26/2020",
            "3/27/2020",
            "3/30/2020",
            "3/31/2020",
            "4/1/2020",
            "4/2/2020",
            "4/3/2020",
            "4/6/2020",
            "4/7/2020",
            "4/8/2020",
            "4/9/2020",
            "4/10/2020",
            "4/13/2020",
            "4/14/2020",
            "4/15/2020",
            "4/16/2020",
            "4/17/2020",
            "4/20/2020",
            "4/21/2020",
            "4/22/2020",
            "4/23/2020",
            "4/24/2020",
            "4/27/2020",
            "4/28/2020",
            "4/29/2020",
            "4/30/2020",
            "5/1/2020",
            "5/4/2020",
            "5/5/2020",
            "5/6/2020",
            "5/7/2020",
            "5/8/2020",
            "5/11/2020",
            "5/12/2020",
            "5/13/2020",
            "5/14/2020",
            "5/15/2020",
            "5/18/2020",
            "5/19/2020",
            "5/20/2020",
            "5/21/2020",
            "5/22/2020",
            "5/25/2020",
            "5/26/2020",
            "5/27/2020",
            "5/28/2020",
            "5/29/2020",
            "6/1/2020",
            "6/2/2020",
            "6/3/2020",
            "6/4/2020",
            "6/5/2020",
            "6/8/2020",
            "6/9/2020",
            "6/10/2020",
            "6/11/2020",
            "6/12/2020",
            "6/15/2020",
            "6/16/2020",
            "6/17/2020",
            "6/18/2020",
            "6/19/2020",
            "6/22/2020",
            "6/23/2020",
            "6/24/2020",
            "6/25/2020",
            "6/26/2020",
            "6/29/2020",
            "6/30/2020",
            "7/1/2020",
            "7/2/2020",
            "7/3/2020",
            "7/6/2020",
            "7/7/2020",
            "7/8/2020",
            "7/9/2020",
            "7/10/2020",
            "7/13/2020",
            "7/14/2020",
            "7/15/2020",
            "7/16/2020",
            "7/17/2020",
            "7/20/2020",
            "7/21/2020",
            "7/22/2020",
            "7/23/2020",
            "7/24/2020",
            "7/27/2020",
            "7/28/2020",
            "7/29/2020",
            "7/30/2020",
            "7/31/2020",
            "8/3/2020",
            "8/4/2020",
            "8/5/2020",
            "8/6/2020",
            "8/7/2020",
            "8/10/2020",
            "8/11/2020",
            "8/12/2020",
            "8/13/2020",
            "8/14/2020",
            "8/17/2020",
            "8/18/2020",
            "8/19/2020",
            "8/20/2020",
            "8/21/2020",
            "8/24/2020",
            "8/25/2020",
            "8/26/2020",
            "8/27/2020",
            "8/28/2020",
            "8/31/2020",
            "9/1/2020",
            "9/2/2020",
            "9/3/2020",
            "9/4/2020",
            "9/7/2020",
            "9/8/2020",
            "9/9/2020",
            "9/10/2020",
            "9/11/2020",
            "9/14/2020",
            "9/15/2020",
            "9/16/2020",
            "9/17/2020",
            "9/18/2020",
            "9/21/2020",
            "9/22/2020",
            "9/23/2020",
            "9/24/2020",
            "9/25/2020",
            "9/28/2020",
            "9/29/2020",
            "9/30/2020",
            "10/1/2020",
            "10/2/2020",
            "10/5/2020",
            "10/6/2020",
            "10/7/2020",
            "10/8/2020",
            "10/9/2020",
            "10/12/2020",
            "10/13/2020",
            "10/14/2020",
            "10/15/2020",
            "10/16/2020",
            "10/19/2020",
            "10/20/2020",
            "10/21/2020",
            "10/22/2020",
            "10/23/2020",
            "10/26/2020",
            "10/27/2020",
            "10/28/2020",
            "10/29/2020",
            "10/30/2020",
            "11/2/2020",
            "11/3/2020",
            "11/4/2020",
            "11/5/2020",
            "11/6/2020",
            "11/9/2020",
            "11/10/2020",
            "11/11/2020",
            "11/12/2020",
            "11/13/2020",
            "11/16/2020",
            "11/17/2020",
            "11/18/2020",
            "11/19/2020",
            "11/20/2020",
            "11/23/2020",
            "11/24/2020",
            "11/25/2020",
            "11/26/2020",
            "11/27/2020",
            "11/30/2020",
            "12/1/2020",
            "12/2/2020",
            "12/3/2020",
            "12/4/2020",
            "12/7/2020",
            "12/8/2020",
            "12/9/2020",
            "12/10/2020",
            "12/11/2020",
            "12/14/2020",
            "12/15/2020",
            "12/16/2020",
            "12/17/2020",
            "12/18/2020",
            "12/21/2020",
            "12/22/2020",
            "12/23/2020",
            "12/24/2020",
            "12/25/2020",
            "12/28/2020",
            "12/29/2020",
            "12/30/2020",
            "12/31/2020",
            "1/1/2021",
            "1/4/2021",
            "1/5/2021",
            "1/6/2021",
            "1/7/2021",
            "1/8/2021",
            "1/11/2021",
            "1/12/2021",
            "1/13/2021",
            "1/14/2021",
            "1/15/2021",
            "1/18/2021",
            "1/19/2021",
            "1/20/2021",
            "1/21/2021",
            "1/22/2021",
            "1/25/2021",
            "1/26/2021",
            "1/27/2021",
            "1/28/2021",
            "1/29/2021",
            "2/1/2021",
            "2/2/2021",
            "2/3/2021",
            "2/4/2021",
            "2/5/2021",
            "2/8/2021",
            "2/9/2021",
            "2/10/2021",
            "2/11/2021",
            "2/12/2021",
            "2/15/2021",
            "2/16/2021",
            "2/17/2021",
            "2/18/2021",
            "2/19/2021",
            "2/22/2021",
            "2/23/2021",
            "2/24/2021",
            "2/25/2021",
            "2/26/2021",
            "3/1/2021",
            "3/2/2021",
            "3/3/2021",
            "3/4/2021",
            "3/5/2021",
            "3/8/2021",
            "3/9/2021",
            "3/10/2021",
            "3/11/2021",
            "3/12/2021",
            "3/15/2021",
            "3/16/2021",
            "3/17/2021",
            "3/18/2021",
            "3/19/2021",
            "3/22/2021",
            "3/23/2021",
            "3/24/2021",
            "3/25/2021",
            "3/26/2021",
            "3/29/2021",
            "3/30/2021",
            "3/31/2021",
            "4/1/2021",
            "4/2/2021",
            "4/5/2021",
            "4/6/2021",
            "4/7/2021",
            "4/8/2021",
            "4/9/2021",
            "4/12/2021",
            "4/13/2021",
            "4/14/2021",
            "4/15/2021",
            "4/16/2021",
            "4/19/2021",
            "4/20/2021",
            "4/21/2021",
            "4/22/2021",
            "4/23/2021",
            "4/26/2021",
            "4/27/2021",
            "4/28/2021",
            "4/29/2021",
            "4/30/2021",
            "5/3/2021",
            "5/4/2021",
            "5/5/2021",
            "5/6/2021",
            "5/7/2021",
            "5/10/2021",
            "5/11/2021",
            "5/12/2021",
            "5/13/2021",
            "5/14/2021",
            "5/17/2021",
            "5/18/2021",
            "5/19/2021",
            "5/20/2021",
            "5/21/2021",
            "5/24/2021",
            "5/25/2021",
            "5/26/2021",
            "5/27/2021",
            "5/28/2021",
            "5/31/2021",
            "6/1/2021",
            "6/2/2021",
            "6/3/2021",
            "6/4/2021",
            "6/7/2021",
            "6/8/2021",
            "6/9/2021",
            "6/10/2021",
            "6/11/2021",
            "6/14/2021",
            "6/15/2021",
            "6/16/2021",
            "6/17/2021",
            "6/18/2021",
            "6/21/2021",
            "6/22/2021",
            "6/23/2021",
            "6/24/2021",
            "6/25/2021",
            "6/28/2021",
            "6/29/2021",
            "6/30/2021",
            "7/1/2021",
            "7/2/2021",
            "7/5/2021",
            "7/6/2021",
            "7/7/2021",
            "7/8/2021",
            "7/9/2021",
            "7/12/2021",
            "7/13/2021",
            "7/14/2021",
            "7/15/2021",
            "7/16/2021",
            "7/19/2021",
            "7/20/2021",
            "7/21/2021",
            "7/22/2021",
            "7/23/2021",
            "7/26/2021",
            "7/27/2021",
            "7/28/2021",
            "7/29/2021",
            "7/30/2021",
            "8/2/2021",
            "8/3/2021",
            "8/4/2021",
            "8/5/2021",
            "8/6/2021",
            "8/9/2021",
            "8/10/2021",
            "8/11/2021",
            "8/12/2021",
            "8/13/2021",
            "8/16/2021",
            "8/17/2021",
            "8/18/2021",
            "8/19/2021",
            "8/20/2021",
            "8/23/2021",
            "8/24/2021",
            "8/25/2021",
            "8/26/2021",
            "8/27/2021",
            "8/30/2021",
            "8/31/2021",
            "9/1/2021",
            "9/2/2021",
            "9/3/2021",
            "9/6/2021",
            "9/7/2021",
            "9/8/2021",
            "9/9/2021",
            "9/10/2021",
            "9/13/2021",
            "9/14/2021",
            "9/15/2021",
            "9/16/2021",
            "9/17/2021",
            "9/20/2021",
            "9/21/2021",
            "9/22/2021",
            "9/23/2021",
            "9/24/2021",
            "9/27/2021",
            "9/28/2021",
            "9/29/2021",
            "9/30/2021",
            "10/1/2021",
            "10/4/2021",
            "10/5/2021",
            "10/6/2021",
            "10/7/2021",
            "10/8/2021",
            "10/11/2021",
            "10/12/2021",
            "10/13/2021",
            "10/14/2021",
            "10/15/2021",
            "10/18/2021",
            "10/19/2021",
            "10/20/2021",
            "10/21/2021",
            "10/22/2021",
            "10/25/2021",
            "10/26/2021",
            "10/27/2021",
            "10/28/2021",
            "10/29/2021",
            "11/1/2021",
            "11/2/2021",
            "11/3/2021",
            "11/4/2021",
            "11/5/2021",
            "11/8/2021",
            "11/9/2021",
            "11/10/2021",
            "11/11/2021",
            "11/12/2021",
            "11/15/2021",
            "11/16/2021",
            "11/17/2021",
            "11/18/2021",
            "11/19/2021",
            "11/22/2021",
            "11/23/2021",
            "11/24/2021",
            "11/25/2021",
            "11/26/2021",
            "11/29/2021",
            "11/30/2021",
            "12/1/2021",
            "12/2/2021",
            "12/3/2021",
            "12/6/2021",
            "12/7/2021",
            "12/8/2021",
            "12/9/2021",
            "12/10/2021",
            "12/13/2021",
            "12/14/2021",
            "12/15/2021",
            "12/16/2021",
            "12/17/2021",
            "12/20/2021",
            "12/21/2021",
            "12/22/2021",
            "12/23/2021",
            "12/24/2021",
            "12/27/2021",
            "12/28/2021",
            "12/29/2021",
            "12/30/2021",
            "12/31/2021",
            "1/3/2022",
            "1/4/2022",
            "1/5/2022",
            "1/6/2022",
            "1/7/2022",
            "1/10/2022",
            "1/11/2022",
            "1/12/2022",
            "1/13/2022",
            "1/14/2022",
            "1/17/2022",
            "1/18/2022",
            "1/19/2022",
            "1/20/2022",
            "1/21/2022",
            "1/24/2022",
            "1/25/2022",
            "1/26/2022",
            "1/27/2022",
            "1/28/2022",
            "1/31/2022",
            "2/1/2022",
            "2/2/2022",
            "2/3/2022",
            "2/4/2022",
            "2/7/2022",
            "2/8/2022",
            "2/9/2022",
            "2/10/2022",
            "2/11/2022",
            "2/14/2022",
            "2/15/2022",
            "2/16/2022",
            "2/17/2022",
            "2/18/2022",
            "2/21/2022",
            "2/22/2022",
            "2/23/2022",
            "2/24/2022",
            "2/25/2022",
            "2/28/2022",
            "3/1/2022",
            "3/2/2022",
            "3/3/2022",
            "3/4/2022",
            "3/7/2022",
            "3/8/2022",
            "3/9/2022",
            "3/10/2022",
            "3/11/2022",
            "3/14/2022",
            "3/15/2022",
            "3/16/2022",
            "3/17/2022",
            "3/18/2022",
            "3/21/2022",
            "3/22/2022",
            "3/23/2022",
            "3/24/2022",
            "3/25/2022",
            "3/28/2022",
            "3/29/2022",
            "3/30/2022",
            "3/31/2022",
            "4/1/2022",
            "4/4/2022",
            "4/5/2022",
            "4/6/2022",
            "4/7/2022",
            "4/8/2022",
            "4/11/2022",
            "4/12/2022",
            "4/13/2022",
            "4/14/2022",
            "4/15/2022",
            "4/18/2022",
            "4/19/2022",
            "4/20/2022",
            "4/21/2022",
            "4/22/2022",
            "4/25/2022",
            "4/26/2022",
            "4/27/2022",
            "4/28/2022",
            "4/29/2022",
            "5/2/2022",
            "5/3/2022",
            "5/4/2022",
            "5/5/2022",
            "5/6/2022",
            "5/9/2022",
            "5/10/2022",
            "5/11/2022",
            "5/12/2022",
            "5/13/2022",
            "5/16/2022",
            "5/17/2022",
            "5/18/2022",
            "5/19/2022",
            "5/20/2022",
            "5/23/2022",
            "5/24/2022",
            "5/25/2022",
            "5/26/2022",
            "5/27/2022",
            "5/30/2022",
            "5/31/2022",
            "6/1/2022",
            "6/2/2022",
            "6/3/2022",
            "6/6/2022",
            "6/7/2022",
            "6/8/2022",
            "6/9/2022",
            "6/10/2022",
            "6/13/2022",
            "6/14/2022",
            "6/15/2022",
            "6/16/2022",
            "6/17/2022",
            "6/20/2022",
            "6/21/2022",
            "6/22/2022",
            "6/23/2022",
            "6/24/2022",
            "6/27/2022",
            "6/28/2022",
            "6/29/2022",
            "6/30/2022",
            "7/1/2022",
            "7/4/2022",
            "7/5/2022",
            "7/6/2022",
            "7/7/2022",
            "7/8/2022",
            "7/11/2022",
            "7/12/2022",
            "7/13/2022",
            "7/14/2022",
            "7/15/2022",
            "7/18/2022",
            "7/19/2022",
            "7/20/2022",
            "7/21/2022",
            "7/22/2022",
            "7/25/2022",
            "7/26/2022",
            "7/27/2022",
            "7/28/2022",
            "7/29/2022",
            "8/1/2022",
            "8/2/2022",
            "8/3/2022",
            "8/4/2022",
            "8/5/2022",
            "8/8/2022",
            "8/9/2022",
            "8/10/2022",
            "8/11/2022",
            "8/12/2022",
            "8/15/2022",
            "8/16/2022",
            "8/17/2022",
            "8/18/2022",
            "8/19/2022",
            "8/22/2022",
            "8/23/2022",
            "8/24/2022",
            "8/25/2022",
            "8/26/2022",
            "8/29/2022",
            "8/30/2022",
            "8/31/2022",
            "9/1/2022",
            "9/2/2022",
            "9/5/2022",
            "9/6/2022",
            "9/7/2022",
            "9/8/2022",
            "9/9/2022",
            "9/12/2022",
            "9/13/2022",
            "9/14/2022",
            "9/15/2022",
            "9/16/2022",
            "9/19/2022",
            "9/20/2022",
            "9/21/2022",
            "9/22/2022",
            "9/23/2022",
            "9/26/2022",
            "9/27/2022",
            "9/28/2022",
            "9/29/2022",
            "9/30/2022",
            "10/3/2022",
            "10/4/2022",
            "10/5/2022",
            "10/6/2022",
            "10/7/2022",
            "10/10/2022",
            "10/11/2022",
            "10/12/2022",
            "10/13/2022",
            "10/14/2022",
            "10/17/2022",
            "10/18/2022",
            "10/19/2022",
            "10/20/2022",
            "10/21/2022",
            "10/24/2022",
            "10/25/2022",
            "10/26/2022",
            "10/27/2022",
            "10/28/2022",
            "10/31/2022",
            "11/1/2022",
            "11/2/2022",
            "11/3/2022",
            "11/4/2022",
            "11/7/2022",
            "11/8/2022",
            "11/9/2022",
            "11/10/2022",
            "11/11/2022",
            "11/14/2022",
            "11/15/2022",
            "11/16/2022",
            "11/17/2022",
            "11/18/2022",
            "11/21/2022",
            "11/22/2022",
            "11/23/2022",
            "11/24/2022",
            "11/25/2022",
            "11/28/2022",
            "11/29/2022",
            "11/30/2022",
            "12/1/2022",
            "12/2/2022",
            "12/5/2022",
            "12/6/2022",
            "12/7/2022",
            "12/8/2022",
            "12/9/2022",
            "12/12/2022",
            "12/13/2022",
            "12/14/2022",
            "12/15/2022",
            "12/16/2022",
            "12/19/2022",
            "12/20/2022",
            "12/21/2022",
            "12/22/2022",
            "12/23/2022",
            "12/26/2022",
            "12/27/2022",
            "12/28/2022",
            "12/29/2022",
            "12/30/2022",
            "1/2/2023",
            "1/3/2023",
            "1/4/2023",
            "1/5/2023",
            "1/6/2023",
            "1/9/2023",
            "1/10/2023",
            "1/11/2023",
            "1/12/2023",
            "1/13/2023",
            "1/16/2023",
            "1/17/2023",
            "1/18/2023",
            "1/19/2023",
            "1/20/2023",
            "1/23/2023",
            "1/24/2023",
            "1/25/2023",
            "1/26/2023",
            "1/27/2023",
            "1/30/2023",
            "1/31/2023",
            "2/1/2023",
            "2/2/2023",
            "2/3/2023",
            "2/6/2023",
            "2/7/2023",
            "2/8/2023",
            "2/9/2023",
            "2/10/2023",
            "2/13/2023",
            "2/14/2023",
            "2/15/2023",
            "2/16/2023",
            "2/17/2023",
            "2/20/2023",
            "2/21/2023",
            "2/22/2023",
            "2/23/2023",
            "2/24/2023",
            "2/27/2023",
            "2/28/2023",
            "3/1/2023",
            "3/2/2023",
            "3/3/2023",
            "3/6/2023",
            "3/7/2023",
            "3/8/2023",
            "3/9/2023",
            "3/10/2023",
            "3/13/2023",
            "3/14/2023",
            "3/15/2023",
            "3/16/2023",
            "3/17/2023",
            "3/20/2023",
            "3/21/2023",
            "3/22/2023",
            "3/23/2023",
            "3/24/2023",
            "3/27/2023",
            "3/28/2023",
            "3/29/2023",
            "3/30/2023",
            "3/31/2023",
            "4/3/2023",
            "4/4/2023",
            "4/5/2023",
            "4/6/2023",
            "4/7/2023",
            "4/10/2023",
            "4/11/2023",
            "4/12/2023",
            "4/13/2023",
            "4/14/2023",
            "4/17/2023",
            "4/18/2023",
            "4/19/2023",
            "4/20/2023",
            "4/21/2023",
            "4/24/2023",
            "4/25/2023",
            "4/26/2023",
            "4/27/2023",
            "4/28/2023",
            "5/1/2023",
            "5/2/2023",
            "5/3/2023",
            "5/4/2023",
            "5/5/2023",
            "5/8/2023",
            "5/9/2023",
            "5/10/2023",
            "5/11/2023",
            "5/12/2023",
            "5/15/2023",
            "5/16/2023",
            "5/17/2023",
            "5/18/2023",
            "5/19/2023",
            "5/22/2023",
            "5/23/2023",
            "5/24/2023",
            "5/25/2023",
            "5/26/2023",
            "5/29/2023",
            "5/30/2023",
            "5/31/2023",
            "6/1/2023",
            "6/2/2023",
            "6/5/2023",
            "6/6/2023",
            "6/7/2023",
            "6/8/2023",
            "6/9/2023",
            "6/12/2023",
            "6/13/2023",
            "6/14/2023",
            "6/15/2023",
            "6/16/2023",
            "6/19/2023",
            "6/20/2023",
            "6/21/2023",
            "6/22/2023",
            "6/23/2023",
            "6/26/2023",
            "6/27/2023",
            "6/28/2023",
            "6/29/2023",
            "6/30/2023",
            "7/3/2023",
            "7/4/2023",
            "7/5/2023",
            "7/6/2023",
            "7/7/2023",
            "7/10/2023",
            "7/11/2023",
            "7/12/2023",
            "7/13/2023",
            "7/14/2023",
            "7/17/2023",
            "7/18/2023",
            "7/19/2023",
            "7/20/2023",
            "7/21/2023",
            "7/24/2023",
            "7/25/2023",
            "7/26/2023",
            "7/27/2023",
            "7/28/2023",
            "7/31/2023",
            "8/1/2023",
            "8/2/2023",
            "8/3/2023",
            "8/4/2023",
            "8/7/2023",
            "8/8/2023",
            "8/9/2023",
            "8/10/2023",
            "8/11/2023",
            "8/14/2023",
            "8/15/2023",
            "8/16/2023",
            "8/17/2023",
            "8/18/2023",
            "8/21/2023",
            "8/22/2023",
            "8/23/2023",
            "8/24/2023",
            "8/25/2023",
            "8/28/2023",
            "8/29/2023",
            "8/30/2023",
            "8/31/2023",
            "9/1/2023",
            "9/4/2023",
            "9/5/2023",
            "9/6/2023",
            "9/7/2023",
            "9/8/2023",
            "9/11/2023",
            "9/12/2023",
            "9/13/2023",
            "9/14/2023",
            "9/15/2023",
            "9/18/2023",
            "9/19/2023",
            "9/20/2023",
            "9/21/2023",
            "9/22/2023",
            "9/25/2023",
            "9/26/2023",
            "9/27/2023",
            "9/28/2023",
            "9/29/2023",
            "10/2/2023"
        ],
        title: {
            text: null
        },
        tickInterval: 500
    },
    yAxis: [
        {
            title: {
                text: "%"
            },
            opposite: false
        }
    ],
    series: [
        {
            name: "",
            type: "column",
            yAxis: 0,
            data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                10,
                9,
                7,
                6,
                8,
                9,
                8,
                7,
                9,
                7,
                7,
                6,
                6,
                8,
                10,
                7,
                5,
                6,
                4,
                5,
                8,
                11,
                11,
                11,
                14,
                13,
                10,
                11,
                10,
                8,
                7,
                9,
                8,
                9,
                10,
                9,
                6,
                7,
                10,
                8,
                9,
                8,
                8,
                2,
                2,
                0,
                -3,
                -3,
                1,
                -1,
                1,
                4,
                3,
                3,
                3,
                2,
                0,
                1,
                -1,
                -1,
                -4,
                -4,
                -3,
                -2,
                -2,
                0,
                0,
                1,
                3,
                3,
                1,
                1,
                1,
                0,
                1,
                1,
                0,
                2,
                3,
                1,
                2,
                3,
                3,
                3,
                1,
                2,
                4,
                3,
                1,
                2,
                1,
                1,
                2,
                3,
                0,
                0,
                0,
                -2,
                -3,
                -5,
                -5,
                -6,
                -5,
                -6,
                -5,
                -4,
                -3,
                -4,
                -7,
                -6,
                -4,
                -4,
                -3,
                -3,
                -4,
                -4,
                -3,
                -3,
                -3,
                -2,
                0,
                1,
                1,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                4,
                4,
                3,
                3,
                3,
                3,
                2,
                2,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                2,
                2,
                3,
                3,
                2,
                3,
                3,
                3,
                4,
                4,
                5,
                4,
                4,
                4,
                3,
                4,
                3,
                1,
                1,
                1,
                1,
                0,
                1,
                1,
                0,
                -1,
                0,
                -2,
                -3,
                -3,
                -3,
                -4,
                -4,
                -4,
                -2,
                -1,
                -1,
                -1,
                0,
                1,
                1,
                2,
                2,
                2,
                0,
                1,
                2,
                3,
                4,
                5,
                5,
                5,
                5,
                4,
                5,
                5,
                2,
                1,
                2,
                2,
                1,
                2,
                1,
                0,
                -1,
                0,
                1,
                -1,
                -2,
                -3,
                -2,
                -2,
                -2,
                0,
                1,
                2,
                1,
                1,
                3,
                4,
                3,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                3,
                2,
                2,
                2,
                3,
                2,
                2,
                3,
                2,
                3,
                4,
                3,
                2,
                3,
                3,
                4,
                4,
                4,
                5,
                5,
                3,
                5,
                2,
                4,
                5,
                5,
                4,
                4,
                4,
                3,
                3,
                2,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                1,
                2,
                2,
                2,
                1,
                3,
                2,
                1,
                1,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                -2,
                -1,
                -1,
                0,
                0,
                1,
                0,
                1,
                1,
                1,
                0,
                1,
                2,
                2,
                3,
                4,
                4,
                4,
                3,
                3,
                3,
                1,
                3,
                3,
                1,
                0,
                -2,
                -2,
                -2,
                -3,
                -3,
                -3,
                -2,
                -1,
                -3,
                -5,
                -6,
                -6,
                -6,
                -6,
                -4,
                -5,
                -5,
                -2,
                -2,
                -2,
                0,
                1,
                1,
                1,
                1,
                2,
                2,
                3,
                2,
                1,
                2,
                4,
                4,
                5,
                4,
                4,
                3,
                4,
                4,
                3,
                2,
                1,
                1,
                1,
                1,
                1,
                2,
                1,
                2,
                1,
                1,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                1,
                0,
                0,
                1,
                1,
                1,
                2,
                2,
                1,
                1,
                2,
                2,
                1,
                0,
                -2,
                -3,
                -3,
                -2,
                -2,
                -1,
                -2,
                -4,
                -4,
                -4,
                -4,
                -6,
                -5,
                -5,
                -6,
                -6,
                -5,
                -5,
                -6,
                -6,
                -6,
                -5,
                -4,
                -3,
                -2,
                -4,
                -5,
                -4,
                -4,
                -2,
                -2,
                0,
                0,
                1,
                0,
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                2,
                2,
                1,
                2,
                2,
                3,
                2,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                2,
                2,
                2,
                1,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                3,
                3,
                3,
                3,
                3,
                2,
                1,
                1,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                2,
                2,
                1,
                0,
                1,
                1,
                1,
                1,
                3,
                2,
                3,
                3,
                3,
                3,
                3,
                4,
                3,
                3,
                4,
                3,
                3,
                2,
                2,
                1,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                1,
                1,
                0,
                0,
                1,
                -1,
                0,
                1,
                1,
                1,
                2,
                1,
                1,
                2,
                1,
                1,
                0,
                0,
                -1,
                0,
                0,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                1,
                2,
                2,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                0,
                1,
                0,
                1,
                1,
                2,
                2,
                1,
                1,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                2,
                2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -1,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -1,
                0,
                1,
                0,
                0,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                2,
                2,
                2,
                3,
                3,
                4,
                3,
                5,
                5,
                5,
                4,
                4,
                3,
                3,
                3,
                5,
                5,
                4,
                4,
                3,
                3,
                3,
                2,
                2,
                2,
                1,
                2,
                1,
                2,
                -1,
                0,
                1,
                1,
                1,
                2,
                1,
                1,
                0,
                0,
                1,
                3,
                3,
                2,
                1,
                2,
                2,
                3,
                4,
                3,
                2,
                1,
                1,
                0,
                -1,
                -1,
                0,
                -1,
                0,
                -1,
                -1,
                -1,
                -3,
                -5,
                -3,
                -3,
                -4,
                -3,
                -2,
                -2,
                -4,
                -4,
                -4,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                1,
                2,
                2,
                2,
                3,
                2,
                1,
                -1,
                0,
                1,
                0,
                0,
                2,
                1,
                2,
                2,
                3,
                3,
                3,
                3,
                2,
                1,
                1,
                0,
                0,
                1,
                2,
                2,
                3,
                3,
                1,
                1,
                2,
                2,
                1,
                1,
                0,
                1,
                -1,
                -1,
                -1,
                -2,
                -1,
                0,
                -1,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -2,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                1,
                1,
                2,
                2,
                2,
                3,
                3,
                3,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                3,
                3,
                4,
                4,
                4,
                4,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                1,
                1,
                2,
                2,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                0,
                0,
                -1,
                -1,
                0,
                1,
                0,
                1,
                0,
                1,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -2,
                -2,
                0,
                0,
                1,
                0,
                -1,
                -2,
                -1,
                -2,
                -1,
                -2,
                -1,
                -1,
                -1,
                -1,
                0,
                -1,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                -1,
                0,
                -1,
                -1,
                0,
                1,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                2,
                2,
                3,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                1,
                2,
                2,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                0,
                -1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                3,
                3,
                2,
                3,
                2,
                2,
                1,
                2,
                1,
                1,
                2,
                1,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                -1,
                -1,
                0,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                2,
                1,
                1,
                1,
                2,
                2,
                1,
                2,
                1,
                2,
                1,
                1,
                2,
                1,
                1,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -2,
                -2,
                -2,
                -2,
                -1,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                1,
                1,
                0,
                0,
                1,
                0,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                1,
                1,
                0,
                1,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                0,
                0,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                1,
                2,
                2,
                3,
                3,
                4,
                4,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                3,
                3,
                3,
                3,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                4,
                2,
                3,
                2,
                2,
                3,
                3,
                3,
                3,
                4,
                5,
                5,
                6,
                6,
                5,
                5,
                6,
                6,
                6,
                5,
                5,
                6,
                4,
                5,
                5,
                5,
                6,
                6,
                6,
                6,
                5,
                5,
                5,
                3,
                4,
                3,
                3,
                4,
                3,
                4,
                4,
                4,
                4,
                4,
                4,
                3,
                3,
                1,
                1,
                -1,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                1,
                1,
                0,
                -1,
                0,
                -1,
                -1,
                0,
                0,
                2,
                1,
                2,
                2,
                3,
                2,
                2,
                3,
                4,
                3,
                3,
                3,
                2,
                1,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                1,
                0,
                -1,
                0,
                -1,
                -1,
                -1,
                -2,
                -3,
                -4,
                -3,
                -3,
                -3,
                -2,
                -2,
                -2,
                -1,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                -1,
                -1,
                0,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                3,
                3,
                3,
                4,
                4,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                4,
                4,
                5,
                4,
                5,
                4,
                5,
                4,
                4,
                4,
                4,
                4,
                3,
                3,
                3,
                2,
                1,
                1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -2,
                0,
                -2,
                -1,
                -1,
                -2,
                -3,
                -3,
                -3,
                -3,
                -2,
                -3,
                -3,
                -3,
                -1,
                0,
                2,
                1,
                1,
                1,
                0,
                1,
                1,
                0,
                1,
                0,
                0,
                1,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                4,
                4,
                5,
                5,
                5,
                5,
                4,
                4,
                4,
                3,
                3,
                3,
                4,
                4,
                4,
                4,
                3,
                4,
                4,
                3,
                3,
                3,
                3,
                2,
                2,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                3,
                2,
                2,
                3,
                3,
                2,
                2,
                3,
                2,
                1,
                1,
                1,
                1,
                2,
                1,
                0,
                0,
                1,
                2,
                1,
                1,
                1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                0,
                -1,
                0,
                0,
                0,
                -1,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                -2,
                -3,
                -2,
                -1,
                -2,
                -1,
                0,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                1,
                1,
                0,
                0,
                0,
                -1,
                0,
                -1,
                -1,
                -2,
                -2,
                -1,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -2,
                -1,
                -1,
                -1,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                2,
                2,
                2,
                3,
                3,
                1,
                2,
                1,
                1,
                0,
                1,
                1,
                0,
                0,
                0,
                0,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -2,
                -3,
                -3,
                -3,
                -3,
                -2,
                -2,
                -2,
                -2,
                -1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                2,
                1,
                2,
                2,
                1,
                1,
                0,
                -1,
                -1,
                -2,
                -1,
                0,
                0,
                0,
                0,
                -1,
                0,
                1,
                2,
                2,
                1,
                2,
                1,
                1,
                1,
                1,
                0,
                1,
                1,
                1,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                2,
                1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                2,
                3,
                3,
                4,
                4,
                3,
                4,
                3,
                4,
                4,
                3,
                2,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                2,
                1,
                0,
                1,
                1,
                1,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                0,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                1,
                1,
                1,
                1,
                1,
                0,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                4,
                4,
                4,
                4,
                4,
                4,
                3,
                3,
                3,
                3,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                1,
                2,
                1,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                1,
                1,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                1,
                1,
                0,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                1,
                1,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                4,
                3,
                4,
                3,
                3,
                3,
                4,
                3,
                2,
                2,
                2,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                -1,
                0,
                1,
                1,
                0,
                1,
                0,
                -1,
                0,
                1,
                0,
                0,
                0,
                -1,
                -1,
                0,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                2,
                2,
                2,
                2,
                3,
                2,
                1,
                2,
                2,
                1,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                1,
                1,
                3,
                2,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                1,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                1,
                1,
                1,
                1,
                2,
                2,
                1,
                1,
                1,
                0,
                1,
                1,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                2,
                2,
                3,
                4,
                4,
                3,
                3,
                3,
                4,
                4,
                3,
                4,
                4,
                4,
                4,
                4,
                4,
                3,
                3,
                3,
                4,
                3,
                3,
                3,
                2,
                1,
                2,
                2,
                4,
                4,
                3,
                3,
                4,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                -2,
                -3,
                -3,
                -3,
                -3,
                -3,
                -2,
                -2,
                -3,
                -3,
                -3,
                -3,
                -3,
                -2,
                -2,
                -1,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                2,
                2,
                2,
                2,
                2,
                4,
                3,
                3,
                4,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                4,
                3,
                3,
                2,
                1,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -2,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -3,
                -2,
                -2,
                -2,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                2,
                3,
                2,
                3,
                2,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                -2,
                -3,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -2,
                -2,
                -1,
                -1,
                -1,
                0,
                -1,
                0,
                0,
                1,
                2,
                1,
                0,
                0,
                1,
                2,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                3,
                7,
                5,
                4,
                5,
                3,
                6,
                6,
                8,
                6,
                9,
                10,
                10,
                10,
                11,
                12,
                13,
                12,
                13,
                10,
                8,
                9,
                7,
                2,
                6,
                6,
                6,
                8,
                7,
                7,
                6,
                9,
                10,
                10,
                10,
                10,
                11,
                11,
                10,
                9,
                10,
                11,
                12,
                11,
                11,
                11,
                10,
                10,
                10,
                9,
                7,
                5,
                5,
                2,
                -2,
                -3,
                -3,
                -3,
                -4,
                -5,
                -5,
                -4,
                -5,
                -4,
                -3,
                -3,
                -4,
                -3,
                -3,
                -3,
                -3,
                -3,
                -2,
                0,
                0,
                1,
                2,
                1,
                1,
                1,
                0,
                0,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                2,
                1,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                -1,
                -1,
                -2,
                -1,
                -2,
                -1,
                -1,
                -2,
                1,
                0,
                1,
                2,
                2,
                2,
                2,
                1,
                1,
                0,
                0,
                1,
                2,
                2,
                2,
                1,
                1,
                -1,
                -1,
                -2,
                -1,
                0,
                -1,
                -1,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -1,
                -1,
                -1,
                -2,
                -1,
                -1,
                0,
                1,
                1,
                3,
                2,
                3,
                3,
                2,
                3,
                2,
                3,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                4,
                3,
                2,
                3,
                2,
                2,
                2,
                2,
                3,
                3,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                4,
                3,
                3,
                2,
                1,
                2,
                3,
                4,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                4,
                3,
                3,
                2,
                3,
                4,
                4,
                4,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                3,
                3,
                3,
                2,
                2,
                1,
                1,
                2,
                0,
                0,
                -1,
                0,
                0,
                1,
                1,
                2,
                2,
                1,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                2,
                1,
                1,
                2,
                2,
                3,
                2,
                2,
                3,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                2,
                3,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                1,
                1,
                1,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                -1,
                0,
                0,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                1,
                2,
                1,
                1,
                1,
                1,
                -1,
                -1,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                0,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                1,
                0,
                1,
                1,
                1,
                0,
                1,
                1,
                1,
                1,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                -2,
                -2,
                -3,
                -4,
                -3,
                -3,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -1,
                -2,
                -2,
                -1,
                -2,
                -1,
                -1,
                -2,
                -1,
                -1,
                2,
                1,
                3,
                3,
                2,
                3,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                2,
                1,
                2,
                1,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                4,
                4,
                5,
                6,
                6,
                5,
                5,
                5,
                5,
                3,
                3,
                5,
                5,
                6,
                6,
                6,
                6,
                6,
                4,
                5,
                5,
                3,
                2,
                2,
                1,
                0,
                -1,
                2,
                2,
                2,
                3,
                4,
                5,
                3,
                3,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                2,
                2,
                1,
                1,
                2,
                2,
                2,
                2,
                1,
                1,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                2,
                1,
                2,
                2,
                1,
                1,
                1,
                3,
                2,
                2,
                1,
                2,
                2,
                4,
                4,
                3,
                4,
                3,
                3,
                3,
                4,
                4,
                3,
                3,
                3,
                4,
                4,
                4,
                4,
                4,
                4,
                3,
                1,
                2,
                3,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                -1,
                0,
                1,
                0,
                -3,
                -2,
                -2,
                -1,
                -1,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                -1,
                1,
                0,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                2,
                1,
                2,
                2,
                2,
                3,
                3,
                4,
                6,
                6,
                6,
                5,
                5,
                5,
                3,
                2,
                2,
                2,
                2,
                3,
                4,
                5,
                3,
                4,
                2,
                2,
                3,
                2,
                1,
                0,
                -2,
                -3,
                -3,
                -2,
                -2,
                -3,
                -1,
                0,
                -1,
                -1,
                -2,
                -2,
                -2,
                -3,
                -2,
                -3,
                -1,
                -1,
                -1,
                -1,
                0,
                1,
                2,
                2,
                3,
                2,
                1,
                2,
                2,
                2,
                3,
                4,
                4,
                4,
                3,
                2,
                2,
                2,
                1,
                0,
                0,
                -1,
                -1,
                -2,
                -2,
                -3,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -2,
                -3,
                -3,
                -3,
                -3,
                -2,
                -2,
                -2,
                -2,
                -1,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                1,
                0,
                1,
                0,
                0,
                -1,
                -1,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                0,
                -1,
                -1,
                -1,
                0,
                -1,
                -1,
                -1,
                -1,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                -1,
                -1,
                0,
                0,
                0,
                -1,
                0,
                -1,
                -2,
                -3,
                -3,
                -3,
                -3,
                -2,
                -2,
                -2,
                -2,
                -1,
                -1,
                0,
                0,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                2,
                1,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                1,
                0,
                1,
                1,
                0,
                0,
                1,
                1,
                1,
                1,
                1,
                0,
                0,
                -1,
                0,
                0,
                0,
                0,
                0,
                0,
                1,
                1,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                2,
                2,
                2
            ]
        }
    ],
    legend: {
        enabled: false
    },
    title: {
        text: null
    }

}

export const chart3 = {


    xAxis: {
        categories: [
            "1/10/1974",
            "1/11/1974",
            "1/14/1974",
            "1/15/1974",
            "1/16/1974",
            "1/17/1974",
            "1/18/1974",
            "1/21/1974",
            "1/22/1974",
            "1/23/1974",
            "1/24/1974",
            "1/25/1974",
            "1/28/1974",
            "1/29/1974",
            "1/30/1974",
            "1/31/1974",
            "2/1/1974",
            "2/4/1974",
            "2/5/1974",
            "2/6/1974",
            "2/7/1974",
            "2/8/1974",
            "2/11/1974",
            "2/12/1974",
            "2/13/1974",
            "2/14/1974",
            "2/15/1974",
            "2/18/1974",
            "2/19/1974",
            "2/20/1974",
            "2/21/1974",
            "2/22/1974",
            "2/25/1974",
            "2/26/1974",
            "2/27/1974",
            "2/28/1974",
            "3/1/1974",
            "3/4/1974",
            "3/5/1974",
            "3/6/1974",
            "3/7/1974",
            "3/8/1974",
            "3/11/1974",
            "3/12/1974",
            "3/13/1974",
            "3/14/1974",
            "3/15/1974",
            "3/18/1974",
            "3/19/1974",
            "3/20/1974",
            "3/21/1974",
            "3/22/1974",
            "3/25/1974",
            "3/26/1974",
            "3/27/1974",
            "3/28/1974",
            "3/29/1974",
            "4/1/1974",
            "4/2/1974",
            "4/3/1974",
            "4/4/1974",
            "4/5/1974",
            "4/8/1974",
            "4/9/1974",
            "4/10/1974",
            "4/11/1974",
            "4/12/1974",
            "4/15/1974",
            "4/16/1974",
            "4/17/1974",
            "4/18/1974",
            "4/19/1974",
            "4/22/1974",
            "4/23/1974",
            "4/24/1974",
            "4/25/1974",
            "4/26/1974",
            "4/29/1974",
            "4/30/1974",
            "5/1/1974",
            "5/2/1974",
            "5/3/1974",
            "5/6/1974",
            "5/7/1974",
            "5/8/1974",
            "5/9/1974",
            "5/10/1974",
            "5/13/1974",
            "5/14/1974",
            "5/15/1974",
            "5/16/1974",
            "5/17/1974",
            "5/20/1974",
            "5/21/1974",
            "5/22/1974",
            "5/23/1974",
            "5/24/1974",
            "5/27/1974",
            "5/28/1974",
            "5/29/1974",
            "5/30/1974",
            "5/31/1974",
            "6/3/1974",
            "6/4/1974",
            "6/5/1974",
            "6/6/1974",
            "6/7/1974",
            "6/10/1974",
            "6/11/1974",
            "6/12/1974",
            "6/13/1974",
            "6/14/1974",
            "6/17/1974",
            "6/18/1974",
            "6/19/1974",
            "6/20/1974",
            "6/21/1974",
            "6/24/1974",
            "6/25/1974",
            "6/26/1974",
            "6/27/1974",
            "6/28/1974",
            "7/1/1974",
            "7/2/1974",
            "7/3/1974",
            "7/4/1974",
            "7/5/1974",
            "7/8/1974",
            "7/9/1974",
            "7/10/1974",
            "7/11/1974",
            "7/12/1974",
            "7/15/1974",
            "7/16/1974",
            "7/17/1974",
            "7/18/1974",
            "7/19/1974",
            "7/22/1974",
            "7/23/1974",
            "7/24/1974",
            "7/25/1974",
            "7/26/1974",
            "7/29/1974",
            "7/30/1974",
            "7/31/1974",
            "8/1/1974",
            "8/2/1974",
            "8/5/1974",
            "8/6/1974",
            "8/7/1974",
            "8/8/1974",
            "8/9/1974",
            "8/12/1974",
            "8/13/1974",
            "8/14/1974",
            "8/15/1974",
            "8/16/1974",
            "8/19/1974",
            "8/20/1974",
            "8/21/1974",
            "8/22/1974",
            "8/23/1974",
            "8/26/1974",
            "8/27/1974",
            "8/28/1974",
            "8/29/1974",
            "8/30/1974",
            "9/2/1974",
            "9/3/1974",
            "9/4/1974",
            "9/5/1974",
            "9/6/1974",
            "9/9/1974",
            "9/10/1974",
            "9/11/1974",
            "9/12/1974",
            "9/13/1974",
            "9/16/1974",
            "9/17/1974",
            "9/18/1974",
            "9/19/1974",
            "9/20/1974",
            "9/23/1974",
            "9/24/1974",
            "9/25/1974",
            "9/26/1974",
            "9/27/1974",
            "9/30/1974",
            "10/1/1974",
            "10/2/1974",
            "10/3/1974",
            "10/4/1974",
            "10/7/1974",
            "10/8/1974",
            "10/9/1974",
            "10/10/1974",
            "10/11/1974",
            "10/14/1974",
            "10/15/1974",
            "10/16/1974",
            "10/17/1974",
            "10/18/1974",
            "10/21/1974",
            "10/22/1974",
            "10/23/1974",
            "10/24/1974",
            "10/25/1974",
            "10/28/1974",
            "10/29/1974",
            "10/30/1974",
            "10/31/1974",
            "11/1/1974",
            "11/4/1974",
            "11/5/1974",
            "11/6/1974",
            "11/7/1974",
            "11/8/1974",
            "11/11/1974",
            "11/12/1974",
            "11/13/1974",
            "11/14/1974",
            "11/15/1974",
            "11/18/1974",
            "11/19/1974",
            "11/20/1974",
            "11/21/1974",
            "11/22/1974",
            "11/25/1974",
            "11/26/1974",
            "11/27/1974",
            "11/28/1974",
            "11/29/1974",
            "12/2/1974",
            "12/3/1974",
            "12/4/1974",
            "12/5/1974",
            "12/6/1974",
            "12/9/1974",
            "12/10/1974",
            "12/11/1974",
            "12/12/1974",
            "12/13/1974",
            "12/16/1974",
            "12/17/1974",
            "12/18/1974",
            "12/19/1974",
            "12/20/1974",
            "12/23/1974",
            "12/24/1974",
            "12/25/1974",
            "12/26/1974",
            "12/27/1974",
            "12/30/1974",
            "12/31/1974",
            "1/1/1975",
            "1/2/1975",
            "1/3/1975",
            "1/6/1975",
            "1/7/1975",
            "1/8/1975",
            "1/9/1975",
            "1/10/1975",
            "1/13/1975",
            "1/14/1975",
            "1/15/1975",
            "1/16/1975",
            "1/17/1975",
            "1/20/1975",
            "1/21/1975",
            "1/22/1975",
            "1/23/1975",
            "1/24/1975",
            "1/27/1975",
            "1/28/1975",
            "1/29/1975",
            "1/30/1975",
            "1/31/1975",
            "2/3/1975",
            "2/4/1975",
            "2/5/1975",
            "2/6/1975",
            "2/7/1975",
            "2/10/1975",
            "2/11/1975",
            "2/12/1975",
            "2/13/1975",
            "2/14/1975",
            "2/17/1975",
            "2/18/1975",
            "2/19/1975",
            "2/20/1975",
            "2/21/1975",
            "2/24/1975",
            "2/25/1975",
            "2/26/1975",
            "2/27/1975",
            "2/28/1975",
            "3/3/1975",
            "3/4/1975",
            "3/5/1975",
            "3/6/1975",
            "3/7/1975",
            "3/10/1975",
            "3/11/1975",
            "3/12/1975",
            "3/13/1975",
            "3/14/1975",
            "3/17/1975",
            "3/18/1975",
            "3/19/1975",
            "3/20/1975",
            "3/21/1975",
            "3/24/1975",
            "3/25/1975",
            "3/26/1975",
            "3/27/1975",
            "3/28/1975",
            "3/31/1975",
            "4/1/1975",
            "4/2/1975",
            "4/3/1975",
            "4/4/1975",
            "4/7/1975",
            "4/8/1975",
            "4/9/1975",
            "4/10/1975",
            "4/11/1975",
            "4/14/1975",
            "4/15/1975",
            "4/16/1975",
            "4/17/1975",
            "4/18/1975",
            "4/21/1975",
            "4/22/1975",
            "4/23/1975",
            "4/24/1975",
            "4/25/1975",
            "4/28/1975",
            "4/29/1975",
            "4/30/1975",
            "5/1/1975",
            "5/2/1975",
            "5/5/1975",
            "5/6/1975",
            "5/7/1975",
            "5/8/1975",
            "5/9/1975",
            "5/12/1975",
            "5/13/1975",
            "5/14/1975",
            "5/15/1975",
            "5/16/1975",
            "5/19/1975",
            "5/20/1975",
            "5/21/1975",
            "5/22/1975",
            "5/23/1975",
            "5/26/1975",
            "5/27/1975",
            "5/28/1975",
            "5/29/1975",
            "5/30/1975",
            "6/2/1975",
            "6/3/1975",
            "6/4/1975",
            "6/5/1975",
            "6/6/1975",
            "6/9/1975",
            "6/10/1975",
            "6/11/1975",
            "6/12/1975",
            "6/13/1975",
            "6/16/1975",
            "6/17/1975",
            "6/18/1975",
            "6/19/1975",
            "6/20/1975",
            "6/23/1975",
            "6/24/1975",
            "6/25/1975",
            "6/26/1975",
            "6/27/1975",
            "6/30/1975",
            "7/1/1975",
            "7/2/1975",
            "7/3/1975",
            "7/4/1975",
            "7/7/1975",
            "7/8/1975",
            "7/9/1975",
            "7/10/1975",
            "7/11/1975",
            "7/14/1975",
            "7/15/1975",
            "7/16/1975",
            "7/17/1975",
            "7/18/1975",
            "7/21/1975",
            "7/22/1975",
            "7/23/1975",
            "7/24/1975",
            "7/25/1975",
            "7/28/1975",
            "7/29/1975",
            "7/30/1975",
            "7/31/1975",
            "8/1/1975",
            "8/4/1975",
            "8/5/1975",
            "8/6/1975",
            "8/7/1975",
            "8/8/1975",
            "8/11/1975",
            "8/12/1975",
            "8/13/1975",
            "8/14/1975",
            "8/15/1975",
            "8/18/1975",
            "8/19/1975",
            "8/20/1975",
            "8/21/1975",
            "8/22/1975",
            "8/25/1975",
            "8/26/1975",
            "8/27/1975",
            "8/28/1975",
            "8/29/1975",
            "9/1/1975",
            "9/2/1975",
            "9/3/1975",
            "9/4/1975",
            "9/5/1975",
            "9/8/1975",
            "9/9/1975",
            "9/10/1975",
            "9/11/1975",
            "9/12/1975",
            "9/15/1975",
            "9/16/1975",
            "9/17/1975",
            "9/18/1975",
            "9/19/1975",
            "9/22/1975",
            "9/23/1975",
            "9/24/1975",
            "9/25/1975",
            "9/26/1975",
            "9/29/1975",
            "9/30/1975",
            "10/1/1975",
            "10/2/1975",
            "10/3/1975",
            "10/6/1975",
            "10/7/1975",
            "10/8/1975",
            "10/9/1975",
            "10/10/1975",
            "10/13/1975",
            "10/14/1975",
            "10/15/1975",
            "10/16/1975",
            "10/17/1975",
            "10/20/1975",
            "10/21/1975",
            "10/22/1975",
            "10/23/1975",
            "10/24/1975",
            "10/27/1975",
            "10/28/1975",
            "10/29/1975",
            "10/30/1975",
            "10/31/1975",
            "11/3/1975",
            "11/4/1975",
            "11/5/1975",
            "11/6/1975",
            "11/7/1975",
            "11/10/1975",
            "11/11/1975",
            "11/12/1975",
            "11/13/1975",
            "11/14/1975",
            "11/17/1975",
            "11/18/1975",
            "11/19/1975",
            "11/20/1975",
            "11/21/1975",
            "11/24/1975",
            "11/25/1975",
            "11/26/1975",
            "11/27/1975",
            "11/28/1975",
            "12/1/1975",
            "12/2/1975",
            "12/3/1975",
            "12/4/1975",
            "12/5/1975",
            "12/8/1975",
            "12/9/1975",
            "12/10/1975",
            "12/11/1975",
            "12/12/1975",
            "12/15/1975",
            "12/16/1975",
            "12/17/1975",
            "12/18/1975",
            "12/19/1975",
            "12/22/1975",
            "12/23/1975",
            "12/24/1975",
            "12/25/1975",
            "12/26/1975",
            "12/29/1975",
            "12/30/1975",
            "12/31/1975",
            "1/1/1976",
            "1/2/1976",
            "1/5/1976",
            "1/6/1976",
            "1/7/1976",
            "1/8/1976",
            "1/9/1976",
            "1/12/1976",
            "1/13/1976",
            "1/14/1976",
            "1/15/1976",
            "1/16/1976",
            "1/19/1976",
            "1/20/1976",
            "1/21/1976",
            "1/22/1976",
            "1/23/1976",
            "1/26/1976",
            "1/27/1976",
            "1/28/1976",
            "1/29/1976",
            "1/30/1976",
            "2/2/1976",
            "2/3/1976",
            "2/4/1976",
            "2/5/1976",
            "2/6/1976",
            "2/9/1976",
            "2/10/1976",
            "2/11/1976",
            "2/12/1976",
            "2/13/1976",
            "2/16/1976",
            "2/17/1976",
            "2/18/1976",
            "2/19/1976",
            "2/20/1976",
            "2/23/1976",
            "2/24/1976",
            "2/25/1976",
            "2/26/1976",
            "2/27/1976",
            "3/1/1976",
            "3/2/1976",
            "3/3/1976",
            "3/4/1976",
            "3/5/1976",
            "3/8/1976",
            "3/9/1976",
            "3/10/1976",
            "3/11/1976",
            "3/12/1976",
            "3/15/1976",
            "3/16/1976",
            "3/17/1976",
            "3/18/1976",
            "3/19/1976",
            "3/22/1976",
            "3/23/1976",
            "3/24/1976",
            "3/25/1976",
            "3/26/1976",
            "3/29/1976",
            "3/30/1976",
            "3/31/1976",
            "4/1/1976",
            "4/2/1976",
            "4/5/1976",
            "4/6/1976",
            "4/7/1976",
            "4/8/1976",
            "4/9/1976",
            "4/12/1976",
            "4/13/1976",
            "4/14/1976",
            "4/15/1976",
            "4/16/1976",
            "4/19/1976",
            "4/20/1976",
            "4/21/1976",
            "4/22/1976",
            "4/23/1976",
            "4/26/1976",
            "4/27/1976",
            "4/28/1976",
            "4/29/1976",
            "4/30/1976",
            "5/3/1976",
            "5/4/1976",
            "5/5/1976",
            "5/6/1976",
            "5/7/1976",
            "5/10/1976",
            "5/11/1976",
            "5/12/1976",
            "5/13/1976",
            "5/14/1976",
            "5/17/1976",
            "5/18/1976",
            "5/19/1976",
            "5/20/1976",
            "5/21/1976",
            "5/24/1976",
            "5/25/1976",
            "5/26/1976",
            "5/27/1976",
            "5/28/1976",
            "5/31/1976",
            "6/1/1976",
            "6/2/1976",
            "6/3/1976",
            "6/4/1976",
            "6/7/1976",
            "6/8/1976",
            "6/9/1976",
            "6/10/1976",
            "6/11/1976",
            "6/14/1976",
            "6/15/1976",
            "6/16/1976",
            "6/17/1976",
            "6/18/1976",
            "6/21/1976",
            "6/22/1976",
            "6/23/1976",
            "6/24/1976",
            "6/25/1976",
            "6/28/1976",
            "6/29/1976",
            "6/30/1976",
            "7/1/1976",
            "7/2/1976",
            "7/5/1976",
            "7/6/1976",
            "7/7/1976",
            "7/8/1976",
            "7/9/1976",
            "7/12/1976",
            "7/13/1976",
            "7/14/1976",
            "7/15/1976",
            "7/16/1976",
            "7/19/1976",
            "7/20/1976",
            "7/21/1976",
            "7/22/1976",
            "7/23/1976",
            "7/26/1976",
            "7/27/1976",
            "7/28/1976",
            "7/29/1976",
            "7/30/1976",
            "8/2/1976",
            "8/3/1976",
            "8/4/1976",
            "8/5/1976",
            "8/6/1976",
            "8/9/1976",
            "8/10/1976",
            "8/11/1976",
            "8/12/1976",
            "8/13/1976",
            "8/16/1976",
            "8/17/1976",
            "8/18/1976",
            "8/19/1976",
            "8/20/1976",
            "8/23/1976",
            "8/24/1976",
            "8/25/1976",
            "8/26/1976",
            "8/27/1976",
            "8/30/1976",
            "8/31/1976",
            "9/1/1976",
            "9/2/1976",
            "9/3/1976",
            "9/6/1976",
            "9/7/1976",
            "9/8/1976",
            "9/9/1976",
            "9/10/1976",
            "9/13/1976",
            "9/14/1976",
            "9/15/1976",
            "9/16/1976",
            "9/17/1976",
            "9/20/1976",
            "9/21/1976",
            "9/22/1976",
            "9/23/1976",
            "9/24/1976",
            "9/27/1976",
            "9/28/1976",
            "9/29/1976",
            "9/30/1976",
            "10/1/1976",
            "10/4/1976",
            "10/5/1976",
            "10/6/1976",
            "10/7/1976",
            "10/8/1976",
            "10/11/1976",
            "10/12/1976",
            "10/13/1976",
            "10/14/1976",
            "10/15/1976",
            "10/18/1976",
            "10/19/1976",
            "10/20/1976",
            "10/21/1976",
            "10/22/1976",
            "10/25/1976",
            "10/26/1976",
            "10/27/1976",
            "10/28/1976",
            "10/29/1976",
            "11/1/1976",
            "11/2/1976",
            "11/3/1976",
            "11/4/1976",
            "11/5/1976",
            "11/8/1976",
            "11/9/1976",
            "11/10/1976",
            "11/11/1976",
            "11/12/1976",
            "11/15/1976",
            "11/16/1976",
            "11/17/1976",
            "11/18/1976",
            "11/19/1976",
            "11/22/1976",
            "11/23/1976",
            "11/24/1976",
            "11/25/1976",
            "11/26/1976",
            "11/29/1976",
            "11/30/1976",
            "12/1/1976",
            "12/2/1976",
            "12/3/1976",
            "12/6/1976",
            "12/7/1976",
            "12/8/1976",
            "12/9/1976",
            "12/10/1976",
            "12/13/1976",
            "12/14/1976",
            "12/15/1976",
            "12/16/1976",
            "12/17/1976",
            "12/20/1976",
            "12/21/1976",
            "12/22/1976",
            "12/23/1976",
            "12/24/1976",
            "12/27/1976",
            "12/28/1976",
            "12/29/1976",
            "12/30/1976",
            "12/31/1976",
            "1/3/1977",
            "1/4/1977",
            "1/5/1977",
            "1/6/1977",
            "1/7/1977",
            "1/10/1977",
            "1/11/1977",
            "1/12/1977",
            "1/13/1977",
            "1/14/1977",
            "1/17/1977",
            "1/18/1977",
            "1/19/1977",
            "1/20/1977",
            "1/21/1977",
            "1/24/1977",
            "1/25/1977",
            "1/26/1977",
            "1/27/1977",
            "1/28/1977",
            "1/31/1977",
            "2/1/1977",
            "2/2/1977",
            "2/3/1977",
            "2/4/1977",
            "2/7/1977",
            "2/8/1977",
            "2/9/1977",
            "2/10/1977",
            "2/11/1977",
            "2/14/1977",
            "2/15/1977",
            "2/16/1977",
            "2/17/1977",
            "2/18/1977",
            "2/21/1977",
            "2/22/1977",
            "2/23/1977",
            "2/24/1977",
            "2/25/1977",
            "2/28/1977",
            "3/1/1977",
            "3/2/1977",
            "3/3/1977",
            "3/4/1977",
            "3/7/1977",
            "3/8/1977",
            "3/9/1977",
            "3/10/1977",
            "3/11/1977",
            "3/14/1977",
            "3/15/1977",
            "3/16/1977",
            "3/17/1977",
            "3/18/1977",
            "3/21/1977",
            "3/22/1977",
            "3/23/1977",
            "3/24/1977",
            "3/25/1977",
            "3/28/1977",
            "3/29/1977",
            "3/30/1977",
            "3/31/1977",
            "4/1/1977",
            "4/4/1977",
            "4/5/1977",
            "4/6/1977",
            "4/7/1977",
            "4/8/1977",
            "4/11/1977",
            "4/12/1977",
            "4/13/1977",
            "4/14/1977",
            "4/15/1977",
            "4/18/1977",
            "4/19/1977",
            "4/20/1977",
            "4/21/1977",
            "4/22/1977",
            "4/25/1977",
            "4/26/1977",
            "4/27/1977",
            "4/28/1977",
            "4/29/1977",
            "5/2/1977",
            "5/3/1977",
            "5/4/1977",
            "5/5/1977",
            "5/6/1977",
            "5/9/1977",
            "5/10/1977",
            "5/11/1977",
            "5/12/1977",
            "5/13/1977",
            "5/16/1977",
            "5/17/1977",
            "5/18/1977",
            "5/19/1977",
            "5/20/1977",
            "5/23/1977",
            "5/24/1977",
            "5/25/1977",
            "5/26/1977",
            "5/27/1977",
            "5/30/1977",
            "5/31/1977",
            "6/1/1977",
            "6/2/1977",
            "6/3/1977",
            "6/6/1977",
            "6/7/1977",
            "6/8/1977",
            "6/9/1977",
            "6/10/1977",
            "6/13/1977",
            "6/14/1977",
            "6/15/1977",
            "6/16/1977",
            "6/17/1977",
            "6/20/1977",
            "6/21/1977",
            "6/22/1977",
            "6/23/1977",
            "6/24/1977",
            "6/27/1977",
            "6/28/1977",
            "6/29/1977",
            "6/30/1977",
            "7/1/1977",
            "7/4/1977",
            "7/5/1977",
            "7/6/1977",
            "7/7/1977",
            "7/8/1977",
            "7/11/1977",
            "7/12/1977",
            "7/13/1977",
            "7/14/1977",
            "7/15/1977",
            "7/18/1977",
            "7/19/1977",
            "7/20/1977",
            "7/21/1977",
            "7/22/1977",
            "7/25/1977",
            "7/26/1977",
            "7/27/1977",
            "7/28/1977",
            "7/29/1977",
            "8/1/1977",
            "8/2/1977",
            "8/3/1977",
            "8/4/1977",
            "8/5/1977",
            "8/8/1977",
            "8/9/1977",
            "8/10/1977",
            "8/11/1977",
            "8/12/1977",
            "8/15/1977",
            "8/16/1977",
            "8/17/1977",
            "8/18/1977",
            "8/19/1977",
            "8/22/1977",
            "8/23/1977",
            "8/24/1977",
            "8/25/1977",
            "8/26/1977",
            "8/29/1977",
            "8/30/1977",
            "8/31/1977",
            "9/1/1977",
            "9/2/1977",
            "9/5/1977",
            "9/6/1977",
            "9/7/1977",
            "9/8/1977",
            "9/9/1977",
            "9/12/1977",
            "9/13/1977",
            "9/14/1977",
            "9/15/1977",
            "9/16/1977",
            "9/19/1977",
            "9/20/1977",
            "9/21/1977",
            "9/22/1977",
            "9/23/1977",
            "9/26/1977",
            "9/27/1977",
            "9/28/1977",
            "9/29/1977",
            "9/30/1977",
            "10/3/1977",
            "10/4/1977",
            "10/5/1977",
            "10/6/1977",
            "10/7/1977",
            "10/10/1977",
            "10/11/1977",
            "10/12/1977",
            "10/13/1977",
            "10/14/1977",
            "10/17/1977",
            "10/18/1977",
            "10/19/1977",
            "10/20/1977",
            "10/21/1977",
            "10/24/1977",
            "10/25/1977",
            "10/26/1977",
            "10/27/1977",
            "10/28/1977",
            "10/31/1977",
            "11/1/1977",
            "11/2/1977",
            "11/3/1977",
            "11/4/1977",
            "11/7/1977",
            "11/8/1977",
            "11/9/1977",
            "11/10/1977",
            "11/11/1977",
            "11/14/1977",
            "11/15/1977",
            "11/16/1977",
            "11/17/1977",
            "11/18/1977",
            "11/21/1977",
            "11/22/1977",
            "11/23/1977",
            "11/24/1977",
            "11/25/1977",
            "11/28/1977",
            "11/29/1977",
            "11/30/1977",
            "12/1/1977",
            "12/2/1977",
            "12/5/1977",
            "12/6/1977",
            "12/7/1977",
            "12/8/1977",
            "12/9/1977",
            "12/12/1977",
            "12/13/1977",
            "12/14/1977",
            "12/15/1977",
            "12/16/1977",
            "12/19/1977",
            "12/20/1977",
            "12/21/1977",
            "12/22/1977",
            "12/23/1977",
            "12/26/1977",
            "12/27/1977",
            "12/28/1977",
            "12/29/1977",
            "12/30/1977",
            "1/2/1978",
            "1/3/1978",
            "1/4/1978",
            "1/5/1978",
            "1/6/1978",
            "1/9/1978",
            "1/10/1978",
            "1/11/1978",
            "1/12/1978",
            "1/13/1978",
            "1/16/1978",
            "1/17/1978",
            "1/18/1978",
            "1/19/1978",
            "1/20/1978",
            "1/23/1978",
            "1/24/1978",
            "1/25/1978",
            "1/26/1978",
            "1/27/1978",
            "1/30/1978",
            "1/31/1978",
            "2/1/1978",
            "2/2/1978",
            "2/3/1978",
            "2/6/1978",
            "2/7/1978",
            "2/8/1978",
            "2/9/1978",
            "2/10/1978",
            "2/13/1978",
            "2/14/1978",
            "2/15/1978",
            "2/16/1978",
            "2/17/1978",
            "2/20/1978",
            "2/21/1978",
            "2/22/1978",
            "2/23/1978",
            "2/24/1978",
            "2/27/1978",
            "2/28/1978",
            "3/1/1978",
            "3/2/1978",
            "3/3/1978",
            "3/6/1978",
            "3/7/1978",
            "3/8/1978",
            "3/9/1978",
            "3/10/1978",
            "3/13/1978",
            "3/14/1978",
            "3/15/1978",
            "3/16/1978",
            "3/17/1978",
            "3/20/1978",
            "3/21/1978",
            "3/22/1978",
            "3/23/1978",
            "3/24/1978",
            "3/27/1978",
            "3/28/1978",
            "3/29/1978",
            "3/30/1978",
            "3/31/1978",
            "4/3/1978",
            "4/4/1978",
            "4/5/1978",
            "4/6/1978",
            "4/7/1978",
            "4/10/1978",
            "4/11/1978",
            "4/12/1978",
            "4/13/1978",
            "4/14/1978",
            "4/17/1978",
            "4/18/1978",
            "4/19/1978",
            "4/20/1978",
            "4/21/1978",
            "4/24/1978",
            "4/25/1978",
            "4/26/1978",
            "4/27/1978",
            "4/28/1978",
            "5/1/1978",
            "5/2/1978",
            "5/3/1978",
            "5/4/1978",
            "5/5/1978",
            "5/8/1978",
            "5/9/1978",
            "5/10/1978",
            "5/11/1978",
            "5/12/1978",
            "5/15/1978",
            "5/16/1978",
            "5/17/1978",
            "5/18/1978",
            "5/19/1978",
            "5/22/1978",
            "5/23/1978",
            "5/24/1978",
            "5/25/1978",
            "5/26/1978",
            "5/29/1978",
            "5/30/1978",
            "5/31/1978",
            "6/1/1978",
            "6/2/1978",
            "6/5/1978",
            "6/6/1978",
            "6/7/1978",
            "6/8/1978",
            "6/9/1978",
            "6/12/1978",
            "6/13/1978",
            "6/14/1978",
            "6/15/1978",
            "6/16/1978",
            "6/19/1978",
            "6/20/1978",
            "6/21/1978",
            "6/22/1978",
            "6/23/1978",
            "6/26/1978",
            "6/27/1978",
            "6/28/1978",
            "6/29/1978",
            "6/30/1978",
            "7/3/1978",
            "7/4/1978",
            "7/5/1978",
            "7/6/1978",
            "7/7/1978",
            "7/10/1978",
            "7/11/1978",
            "7/12/1978",
            "7/13/1978",
            "7/14/1978",
            "7/17/1978",
            "7/18/1978",
            "7/19/1978",
            "7/20/1978",
            "7/21/1978",
            "7/24/1978",
            "7/25/1978",
            "7/26/1978",
            "7/27/1978",
            "7/28/1978",
            "7/31/1978",
            "8/1/1978",
            "8/2/1978",
            "8/3/1978",
            "8/4/1978",
            "8/7/1978",
            "8/8/1978",
            "8/9/1978",
            "8/10/1978",
            "8/11/1978",
            "8/14/1978",
            "8/15/1978",
            "8/16/1978",
            "8/17/1978",
            "8/18/1978",
            "8/21/1978",
            "8/22/1978",
            "8/23/1978",
            "8/24/1978",
            "8/25/1978",
            "8/28/1978",
            "8/29/1978",
            "8/30/1978",
            "8/31/1978",
            "9/1/1978",
            "9/4/1978",
            "9/5/1978",
            "9/6/1978",
            "9/7/1978",
            "9/8/1978",
            "9/11/1978",
            "9/12/1978",
            "9/13/1978",
            "9/14/1978",
            "9/15/1978",
            "9/18/1978",
            "9/19/1978",
            "9/20/1978",
            "9/21/1978",
            "9/22/1978",
            "9/25/1978",
            "9/26/1978",
            "9/27/1978",
            "9/28/1978",
            "9/29/1978",
            "10/2/1978",
            "10/3/1978",
            "10/4/1978",
            "10/5/1978",
            "10/6/1978",
            "10/9/1978",
            "10/10/1978",
            "10/11/1978",
            "10/12/1978",
            "10/13/1978",
            "10/16/1978",
            "10/17/1978",
            "10/18/1978",
            "10/19/1978",
            "10/20/1978",
            "10/23/1978",
            "10/24/1978",
            "10/25/1978",
            "10/26/1978",
            "10/27/1978",
            "10/30/1978",
            "10/31/1978",
            "11/1/1978",
            "11/2/1978",
            "11/3/1978",
            "11/6/1978",
            "11/7/1978",
            "11/8/1978",
            "11/9/1978",
            "11/10/1978",
            "11/13/1978",
            "11/14/1978",
            "11/15/1978",
            "11/16/1978",
            "11/17/1978",
            "11/20/1978",
            "11/21/1978",
            "11/22/1978",
            "11/23/1978",
            "11/24/1978",
            "11/27/1978",
            "11/28/1978",
            "11/29/1978",
            "11/30/1978",
            "12/1/1978",
            "12/4/1978",
            "12/5/1978",
            "12/6/1978",
            "12/7/1978",
            "12/8/1978",
            "12/11/1978",
            "12/12/1978",
            "12/13/1978",
            "12/14/1978",
            "12/15/1978",
            "12/18/1978",
            "12/19/1978",
            "12/20/1978",
            "12/21/1978",
            "12/22/1978",
            "12/25/1978",
            "12/26/1978",
            "12/27/1978",
            "12/28/1978",
            "12/29/1978",
            "1/1/1979",
            "1/2/1979",
            "1/3/1979",
            "1/4/1979",
            "1/5/1979",
            "1/8/1979",
            "1/9/1979",
            "1/10/1979",
            "1/11/1979",
            "1/12/1979",
            "1/15/1979",
            "1/16/1979",
            "1/17/1979",
            "1/18/1979",
            "1/19/1979",
            "1/22/1979",
            "1/23/1979",
            "1/24/1979",
            "1/25/1979",
            "1/26/1979",
            "1/29/1979",
            "1/30/1979",
            "1/31/1979",
            "2/1/1979",
            "2/2/1979",
            "2/5/1979",
            "2/6/1979",
            "2/7/1979",
            "2/8/1979",
            "2/9/1979",
            "2/12/1979",
            "2/13/1979",
            "2/14/1979",
            "2/15/1979",
            "2/16/1979",
            "2/19/1979",
            "2/20/1979",
            "2/21/1979",
            "2/22/1979",
            "2/23/1979",
            "2/26/1979",
            "2/27/1979",
            "2/28/1979",
            "3/1/1979",
            "3/2/1979",
            "3/5/1979",
            "3/6/1979",
            "3/7/1979",
            "3/8/1979",
            "3/9/1979",
            "3/12/1979",
            "3/13/1979",
            "3/14/1979",
            "3/15/1979",
            "3/16/1979",
            "3/19/1979",
            "3/20/1979",
            "3/21/1979",
            "3/22/1979",
            "3/23/1979",
            "3/26/1979",
            "3/27/1979",
            "3/28/1979",
            "3/29/1979",
            "3/30/1979",
            "4/2/1979",
            "4/3/1979",
            "4/4/1979",
            "4/5/1979",
            "4/6/1979",
            "4/9/1979",
            "4/10/1979",
            "4/11/1979",
            "4/12/1979",
            "4/13/1979",
            "4/16/1979",
            "4/17/1979",
            "4/18/1979",
            "4/19/1979",
            "4/20/1979",
            "4/23/1979",
            "4/24/1979",
            "4/25/1979",
            "4/26/1979",
            "4/27/1979",
            "4/30/1979",
            "5/1/1979",
            "5/2/1979",
            "5/3/1979",
            "5/4/1979",
            "5/7/1979",
            "5/8/1979",
            "5/9/1979",
            "5/10/1979",
            "5/11/1979",
            "5/14/1979",
            "5/15/1979",
            "5/16/1979",
            "5/17/1979",
            "5/18/1979",
            "5/21/1979",
            "5/22/1979",
            "5/23/1979",
            "5/24/1979",
            "5/25/1979",
            "5/28/1979",
            "5/29/1979",
            "5/30/1979",
            "5/31/1979",
            "6/1/1979",
            "6/4/1979",
            "6/5/1979",
            "6/6/1979",
            "6/7/1979",
            "6/8/1979",
            "6/11/1979",
            "6/12/1979",
            "6/13/1979",
            "6/14/1979",
            "6/15/1979",
            "6/18/1979",
            "6/19/1979",
            "6/20/1979",
            "6/21/1979",
            "6/22/1979",
            "6/25/1979",
            "6/26/1979",
            "6/27/1979",
            "6/28/1979",
            "6/29/1979",
            "7/2/1979",
            "7/3/1979",
            "7/4/1979",
            "7/5/1979",
            "7/6/1979",
            "7/9/1979",
            "7/10/1979",
            "7/11/1979",
            "7/12/1979",
            "7/13/1979",
            "7/16/1979",
            "7/17/1979",
            "7/18/1979",
            "7/19/1979",
            "7/20/1979",
            "7/23/1979",
            "7/24/1979",
            "7/25/1979",
            "7/26/1979",
            "7/27/1979",
            "7/30/1979",
            "7/31/1979",
            "8/1/1979",
            "8/2/1979",
            "8/3/1979",
            "8/6/1979",
            "8/7/1979",
            "8/8/1979",
            "8/9/1979",
            "8/10/1979",
            "8/13/1979",
            "8/14/1979",
            "8/15/1979",
            "8/16/1979",
            "8/17/1979",
            "8/20/1979",
            "8/21/1979",
            "8/22/1979",
            "8/23/1979",
            "8/24/1979",
            "8/27/1979",
            "8/28/1979",
            "8/29/1979",
            "8/30/1979",
            "8/31/1979",
            "9/3/1979",
            "9/4/1979",
            "9/5/1979",
            "9/6/1979",
            "9/7/1979",
            "9/10/1979",
            "9/11/1979",
            "9/12/1979",
            "9/13/1979",
            "9/14/1979",
            "9/17/1979",
            "9/18/1979",
            "9/19/1979",
            "9/20/1979",
            "9/21/1979",
            "9/24/1979",
            "9/25/1979",
            "9/26/1979",
            "9/27/1979",
            "9/28/1979",
            "10/1/1979",
            "10/2/1979",
            "10/3/1979",
            "10/4/1979",
            "10/5/1979",
            "10/8/1979",
            "10/9/1979",
            "10/10/1979",
            "10/11/1979",
            "10/12/1979",
            "10/15/1979",
            "10/16/1979",
            "10/17/1979",
            "10/18/1979",
            "10/19/1979",
            "10/22/1979",
            "10/23/1979",
            "10/24/1979",
            "10/25/1979",
            "10/26/1979",
            "10/29/1979",
            "10/30/1979",
            "10/31/1979",
            "11/1/1979",
            "11/2/1979",
            "11/5/1979",
            "11/6/1979",
            "11/7/1979",
            "11/8/1979",
            "11/9/1979",
            "11/12/1979",
            "11/13/1979",
            "11/14/1979",
            "11/15/1979",
            "11/16/1979",
            "11/19/1979",
            "11/20/1979",
            "11/21/1979",
            "11/22/1979",
            "11/23/1979",
            "11/26/1979",
            "11/27/1979",
            "11/28/1979",
            "11/29/1979",
            "11/30/1979",
            "12/3/1979",
            "12/4/1979",
            "12/5/1979",
            "12/6/1979",
            "12/7/1979",
            "12/10/1979",
            "12/11/1979",
            "12/12/1979",
            "12/13/1979",
            "12/14/1979",
            "12/17/1979",
            "12/18/1979",
            "12/19/1979",
            "12/20/1979",
            "12/21/1979",
            "12/24/1979",
            "12/25/1979",
            "12/26/1979",
            "12/27/1979",
            "12/28/1979",
            "12/31/1979",
            "1/1/1980",
            "1/2/1980",
            "1/3/1980",
            "1/4/1980",
            "1/7/1980",
            "1/8/1980",
            "1/9/1980",
            "1/10/1980",
            "1/11/1980",
            "1/14/1980",
            "1/15/1980",
            "1/16/1980",
            "1/17/1980",
            "1/18/1980",
            "1/21/1980",
            "1/22/1980",
            "1/23/1980",
            "1/24/1980",
            "1/25/1980",
            "1/28/1980",
            "1/29/1980",
            "1/30/1980",
            "1/31/1980",
            "2/1/1980",
            "2/4/1980",
            "2/5/1980",
            "2/6/1980",
            "2/7/1980",
            "2/8/1980",
            "2/11/1980",
            "2/12/1980",
            "2/13/1980",
            "2/14/1980",
            "2/15/1980",
            "2/18/1980",
            "2/19/1980",
            "2/20/1980",
            "2/21/1980",
            "2/22/1980",
            "2/25/1980",
            "2/26/1980",
            "2/27/1980",
            "2/28/1980",
            "2/29/1980",
            "3/3/1980",
            "3/4/1980",
            "3/5/1980",
            "3/6/1980",
            "3/7/1980",
            "3/10/1980",
            "3/11/1980",
            "3/12/1980",
            "3/13/1980",
            "3/14/1980",
            "3/17/1980",
            "3/18/1980",
            "3/19/1980",
            "3/20/1980",
            "3/21/1980",
            "3/24/1980",
            "3/25/1980",
            "3/26/1980",
            "3/27/1980",
            "3/28/1980",
            "3/31/1980",
            "4/1/1980",
            "4/2/1980",
            "4/3/1980",
            "4/4/1980",
            "4/7/1980",
            "4/8/1980",
            "4/9/1980",
            "4/10/1980",
            "4/11/1980",
            "4/14/1980",
            "4/15/1980",
            "4/16/1980",
            "4/17/1980",
            "4/18/1980",
            "4/21/1980",
            "4/22/1980",
            "4/23/1980",
            "4/24/1980",
            "4/25/1980",
            "4/28/1980",
            "4/29/1980",
            "4/30/1980",
            "5/1/1980",
            "5/2/1980",
            "5/5/1980",
            "5/6/1980",
            "5/7/1980",
            "5/8/1980",
            "5/9/1980",
            "5/12/1980",
            "5/13/1980",
            "5/14/1980",
            "5/15/1980",
            "5/16/1980",
            "5/19/1980",
            "5/20/1980",
            "5/21/1980",
            "5/22/1980",
            "5/23/1980",
            "5/26/1980",
            "5/27/1980",
            "5/28/1980",
            "5/29/1980",
            "5/30/1980",
            "6/2/1980",
            "6/3/1980",
            "6/4/1980",
            "6/5/1980",
            "6/6/1980",
            "6/9/1980",
            "6/10/1980",
            "6/11/1980",
            "6/12/1980",
            "6/13/1980",
            "6/16/1980",
            "6/17/1980",
            "6/18/1980",
            "6/19/1980",
            "6/20/1980",
            "6/23/1980",
            "6/24/1980",
            "6/25/1980",
            "6/26/1980",
            "6/27/1980",
            "6/30/1980",
            "7/1/1980",
            "7/2/1980",
            "7/3/1980",
            "7/4/1980",
            "7/7/1980",
            "7/8/1980",
            "7/9/1980",
            "7/10/1980",
            "7/11/1980",
            "7/14/1980",
            "7/15/1980",
            "7/16/1980",
            "7/17/1980",
            "7/18/1980",
            "7/21/1980",
            "7/22/1980",
            "7/23/1980",
            "7/24/1980",
            "7/25/1980",
            "7/28/1980",
            "7/29/1980",
            "7/30/1980",
            "7/31/1980",
            "8/1/1980",
            "8/4/1980",
            "8/5/1980",
            "8/6/1980",
            "8/7/1980",
            "8/8/1980",
            "8/11/1980",
            "8/12/1980",
            "8/13/1980",
            "8/14/1980",
            "8/15/1980",
            "8/18/1980",
            "8/19/1980",
            "8/20/1980",
            "8/21/1980",
            "8/22/1980",
            "8/25/1980",
            "8/26/1980",
            "8/27/1980",
            "8/28/1980",
            "8/29/1980",
            "9/1/1980",
            "9/2/1980",
            "9/3/1980",
            "9/4/1980",
            "9/5/1980",
            "9/8/1980",
            "9/9/1980",
            "9/10/1980",
            "9/11/1980",
            "9/12/1980",
            "9/15/1980",
            "9/16/1980",
            "9/17/1980",
            "9/18/1980",
            "9/19/1980",
            "9/22/1980",
            "9/23/1980",
            "9/24/1980",
            "9/25/1980",
            "9/26/1980",
            "9/29/1980",
            "9/30/1980",
            "10/1/1980",
            "10/2/1980",
            "10/3/1980",
            "10/6/1980",
            "10/7/1980",
            "10/8/1980",
            "10/9/1980",
            "10/10/1980",
            "10/13/1980",
            "10/14/1980",
            "10/15/1980",
            "10/16/1980",
            "10/17/1980",
            "10/20/1980",
            "10/21/1980",
            "10/22/1980",
            "10/23/1980",
            "10/24/1980",
            "10/27/1980",
            "10/28/1980",
            "10/29/1980",
            "10/30/1980",
            "10/31/1980",
            "11/3/1980",
            "11/4/1980",
            "11/5/1980",
            "11/6/1980",
            "11/7/1980",
            "11/10/1980",
            "11/11/1980",
            "11/12/1980",
            "11/13/1980",
            "11/14/1980",
            "11/17/1980",
            "11/18/1980",
            "11/19/1980",
            "11/20/1980",
            "11/21/1980",
            "11/24/1980",
            "11/25/1980",
            "11/26/1980",
            "11/27/1980",
            "11/28/1980",
            "12/1/1980",
            "12/2/1980",
            "12/3/1980",
            "12/4/1980",
            "12/5/1980",
            "12/8/1980",
            "12/9/1980",
            "12/10/1980",
            "12/11/1980",
            "12/12/1980",
            "12/15/1980",
            "12/16/1980",
            "12/17/1980",
            "12/18/1980",
            "12/19/1980",
            "12/22/1980",
            "12/23/1980",
            "12/24/1980",
            "12/25/1980",
            "12/26/1980",
            "12/29/1980",
            "12/30/1980",
            "12/31/1980",
            "1/1/1981",
            "1/2/1981",
            "1/5/1981",
            "1/6/1981",
            "1/7/1981",
            "1/8/1981",
            "1/9/1981",
            "1/12/1981",
            "1/13/1981",
            "1/14/1981",
            "1/15/1981",
            "1/16/1981",
            "1/19/1981",
            "1/20/1981",
            "1/21/1981",
            "1/22/1981",
            "1/23/1981",
            "1/26/1981",
            "1/27/1981",
            "1/28/1981",
            "1/29/1981",
            "1/30/1981",
            "2/2/1981",
            "2/3/1981",
            "2/4/1981",
            "2/5/1981",
            "2/6/1981",
            "2/9/1981",
            "2/10/1981",
            "2/11/1981",
            "2/12/1981",
            "2/13/1981",
            "2/16/1981",
            "2/17/1981",
            "2/18/1981",
            "2/19/1981",
            "2/20/1981",
            "2/23/1981",
            "2/24/1981",
            "2/25/1981",
            "2/26/1981",
            "2/27/1981",
            "3/2/1981",
            "3/3/1981",
            "3/4/1981",
            "3/5/1981",
            "3/6/1981",
            "3/9/1981",
            "3/10/1981",
            "3/11/1981",
            "3/12/1981",
            "3/13/1981",
            "3/16/1981",
            "3/17/1981",
            "3/18/1981",
            "3/19/1981",
            "3/20/1981",
            "3/23/1981",
            "3/24/1981",
            "3/25/1981",
            "3/26/1981",
            "3/27/1981",
            "3/30/1981",
            "3/31/1981",
            "4/1/1981",
            "4/2/1981",
            "4/3/1981",
            "4/6/1981",
            "4/7/1981",
            "4/8/1981",
            "4/9/1981",
            "4/10/1981",
            "4/13/1981",
            "4/14/1981",
            "4/15/1981",
            "4/16/1981",
            "4/17/1981",
            "4/20/1981",
            "4/21/1981",
            "4/22/1981",
            "4/23/1981",
            "4/24/1981",
            "4/27/1981",
            "4/28/1981",
            "4/29/1981",
            "4/30/1981",
            "5/1/1981",
            "5/4/1981",
            "5/5/1981",
            "5/6/1981",
            "5/7/1981",
            "5/8/1981",
            "5/11/1981",
            "5/12/1981",
            "5/13/1981",
            "5/14/1981",
            "5/15/1981",
            "5/18/1981",
            "5/19/1981",
            "5/20/1981",
            "5/21/1981",
            "5/22/1981",
            "5/25/1981",
            "5/26/1981",
            "5/27/1981",
            "5/28/1981",
            "5/29/1981",
            "6/1/1981",
            "6/2/1981",
            "6/3/1981",
            "6/4/1981",
            "6/5/1981",
            "6/8/1981",
            "6/9/1981",
            "6/10/1981",
            "6/11/1981",
            "6/12/1981",
            "6/15/1981",
            "6/16/1981",
            "6/17/1981",
            "6/18/1981",
            "6/19/1981",
            "6/22/1981",
            "6/23/1981",
            "6/24/1981",
            "6/25/1981",
            "6/26/1981",
            "6/29/1981",
            "6/30/1981",
            "7/1/1981",
            "7/2/1981",
            "7/3/1981",
            "7/6/1981",
            "7/7/1981",
            "7/8/1981",
            "7/9/1981",
            "7/10/1981",
            "7/13/1981",
            "7/14/1981",
            "7/15/1981",
            "7/16/1981",
            "7/17/1981",
            "7/20/1981",
            "7/21/1981",
            "7/22/1981",
            "7/23/1981",
            "7/24/1981",
            "7/27/1981",
            "7/28/1981",
            "7/29/1981",
            "7/30/1981",
            "7/31/1981",
            "8/3/1981",
            "8/4/1981",
            "8/5/1981",
            "8/6/1981",
            "8/7/1981",
            "8/10/1981",
            "8/11/1981",
            "8/12/1981",
            "8/13/1981",
            "8/14/1981",
            "8/17/1981",
            "8/18/1981",
            "8/19/1981",
            "8/20/1981",
            "8/21/1981",
            "8/24/1981",
            "8/25/1981",
            "8/26/1981",
            "8/27/1981",
            "8/28/1981",
            "8/31/1981",
            "9/1/1981",
            "9/2/1981",
            "9/3/1981",
            "9/4/1981",
            "9/7/1981",
            "9/8/1981",
            "9/9/1981",
            "9/10/1981",
            "9/11/1981",
            "9/14/1981",
            "9/15/1981",
            "9/16/1981",
            "9/17/1981",
            "9/18/1981",
            "9/21/1981",
            "9/22/1981",
            "9/23/1981",
            "9/24/1981",
            "9/25/1981",
            "9/28/1981",
            "9/29/1981",
            "9/30/1981",
            "10/1/1981",
            "10/2/1981",
            "10/5/1981",
            "10/6/1981",
            "10/7/1981",
            "10/8/1981",
            "10/9/1981",
            "10/12/1981",
            "10/13/1981",
            "10/14/1981",
            "10/15/1981",
            "10/16/1981",
            "10/19/1981",
            "10/20/1981",
            "10/21/1981",
            "10/22/1981",
            "10/23/1981",
            "10/26/1981",
            "10/27/1981",
            "10/28/1981",
            "10/29/1981",
            "10/30/1981",
            "11/2/1981",
            "11/3/1981",
            "11/4/1981",
            "11/5/1981",
            "11/6/1981",
            "11/9/1981",
            "11/10/1981",
            "11/11/1981",
            "11/12/1981",
            "11/13/1981",
            "11/16/1981",
            "11/17/1981",
            "11/18/1981",
            "11/19/1981",
            "11/20/1981",
            "11/23/1981",
            "11/24/1981",
            "11/25/1981",
            "11/26/1981",
            "11/27/1981",
            "11/30/1981",
            "12/1/1981",
            "12/2/1981",
            "12/3/1981",
            "12/4/1981",
            "12/7/1981",
            "12/8/1981",
            "12/9/1981",
            "12/10/1981",
            "12/11/1981",
            "12/14/1981",
            "12/15/1981",
            "12/16/1981",
            "12/17/1981",
            "12/18/1981",
            "12/21/1981",
            "12/22/1981",
            "12/23/1981",
            "12/24/1981",
            "12/25/1981",
            "12/28/1981",
            "12/29/1981",
            "12/30/1981",
            "12/31/1981",
            "1/1/1982",
            "1/4/1982",
            "1/5/1982",
            "1/6/1982",
            "1/7/1982",
            "1/8/1982",
            "1/11/1982",
            "1/12/1982",
            "1/13/1982",
            "1/14/1982",
            "1/15/1982",
            "1/18/1982",
            "1/19/1982",
            "1/20/1982",
            "1/21/1982",
            "1/22/1982",
            "1/25/1982",
            "1/26/1982",
            "1/27/1982",
            "1/28/1982",
            "1/29/1982",
            "2/1/1982",
            "2/2/1982",
            "2/3/1982",
            "2/4/1982",
            "2/5/1982",
            "2/8/1982",
            "2/9/1982",
            "2/10/1982",
            "2/11/1982",
            "2/12/1982",
            "2/15/1982",
            "2/16/1982",
            "2/17/1982",
            "2/18/1982",
            "2/19/1982",
            "2/22/1982",
            "2/23/1982",
            "2/24/1982",
            "2/25/1982",
            "2/26/1982",
            "3/1/1982",
            "3/2/1982",
            "3/3/1982",
            "3/4/1982",
            "3/5/1982",
            "3/8/1982",
            "3/9/1982",
            "3/10/1982",
            "3/11/1982",
            "3/12/1982",
            "3/15/1982",
            "3/16/1982",
            "3/17/1982",
            "3/18/1982",
            "3/19/1982",
            "3/22/1982",
            "3/23/1982",
            "3/24/1982",
            "3/25/1982",
            "3/26/1982",
            "3/29/1982",
            "3/30/1982",
            "3/31/1982",
            "4/1/1982",
            "4/2/1982",
            "4/5/1982",
            "4/6/1982",
            "4/7/1982",
            "4/8/1982",
            "4/9/1982",
            "4/12/1982",
            "4/13/1982",
            "4/14/1982",
            "4/15/1982",
            "4/16/1982",
            "4/19/1982",
            "4/20/1982",
            "4/21/1982",
            "4/22/1982",
            "4/23/1982",
            "4/26/1982",
            "4/27/1982",
            "4/28/1982",
            "4/29/1982",
            "4/30/1982",
            "5/3/1982",
            "5/4/1982",
            "5/5/1982",
            "5/6/1982",
            "5/7/1982",
            "5/10/1982",
            "5/11/1982",
            "5/12/1982",
            "5/13/1982",
            "5/14/1982",
            "5/17/1982",
            "5/18/1982",
            "5/19/1982",
            "5/20/1982",
            "5/21/1982",
            "5/24/1982",
            "5/25/1982",
            "5/26/1982",
            "5/27/1982",
            "5/28/1982",
            "5/31/1982",
            "6/1/1982",
            "6/2/1982",
            "6/3/1982",
            "6/4/1982",
            "6/7/1982",
            "6/8/1982",
            "6/9/1982",
            "6/10/1982",
            "6/11/1982",
            "6/14/1982",
            "6/15/1982",
            "6/16/1982",
            "6/17/1982",
            "6/18/1982",
            "6/21/1982",
            "6/22/1982",
            "6/23/1982",
            "6/24/1982",
            "6/25/1982",
            "6/28/1982",
            "6/29/1982",
            "6/30/1982",
            "7/1/1982",
            "7/2/1982",
            "7/5/1982",
            "7/6/1982",
            "7/7/1982",
            "7/8/1982",
            "7/9/1982",
            "7/12/1982",
            "7/13/1982",
            "7/14/1982",
            "7/15/1982",
            "7/16/1982",
            "7/19/1982",
            "7/20/1982",
            "7/21/1982",
            "7/22/1982",
            "7/23/1982",
            "7/26/1982",
            "7/27/1982",
            "7/28/1982",
            "7/29/1982",
            "7/30/1982",
            "8/2/1982",
            "8/3/1982",
            "8/4/1982",
            "8/5/1982",
            "8/6/1982",
            "8/9/1982",
            "8/10/1982",
            "8/11/1982",
            "8/12/1982",
            "8/13/1982",
            "8/16/1982",
            "8/17/1982",
            "8/18/1982",
            "8/19/1982",
            "8/20/1982",
            "8/23/1982",
            "8/24/1982",
            "8/25/1982",
            "8/26/1982",
            "8/27/1982",
            "8/30/1982",
            "8/31/1982",
            "9/1/1982",
            "9/2/1982",
            "9/3/1982",
            "9/6/1982",
            "9/7/1982",
            "9/8/1982",
            "9/9/1982",
            "9/10/1982",
            "9/13/1982",
            "9/14/1982",
            "9/15/1982",
            "9/16/1982",
            "9/17/1982",
            "9/20/1982",
            "9/21/1982",
            "9/22/1982",
            "9/23/1982",
            "9/24/1982",
            "9/27/1982",
            "9/28/1982",
            "9/29/1982",
            "9/30/1982",
            "10/1/1982",
            "10/4/1982",
            "10/5/1982",
            "10/6/1982",
            "10/7/1982",
            "10/8/1982",
            "10/11/1982",
            "10/12/1982",
            "10/13/1982",
            "10/14/1982",
            "10/15/1982",
            "10/18/1982",
            "10/19/1982",
            "10/20/1982",
            "10/21/1982",
            "10/22/1982",
            "10/25/1982",
            "10/26/1982",
            "10/27/1982",
            "10/28/1982",
            "10/29/1982",
            "11/1/1982",
            "11/2/1982",
            "11/3/1982",
            "11/4/1982",
            "11/5/1982",
            "11/8/1982",
            "11/9/1982",
            "11/10/1982",
            "11/11/1982",
            "11/12/1982",
            "11/15/1982",
            "11/16/1982",
            "11/17/1982",
            "11/18/1982",
            "11/19/1982",
            "11/22/1982",
            "11/23/1982",
            "11/24/1982",
            "11/25/1982",
            "11/26/1982",
            "11/29/1982",
            "11/30/1982",
            "12/1/1982",
            "12/2/1982",
            "12/3/1982",
            "12/6/1982",
            "12/7/1982",
            "12/8/1982",
            "12/9/1982",
            "12/10/1982",
            "12/13/1982",
            "12/14/1982",
            "12/15/1982",
            "12/16/1982",
            "12/17/1982",
            "12/20/1982",
            "12/21/1982",
            "12/22/1982",
            "12/23/1982",
            "12/24/1982",
            "12/27/1982",
            "12/28/1982",
            "12/29/1982",
            "12/30/1982",
            "12/31/1982",
            "1/3/1983",
            "1/4/1983",
            "1/5/1983",
            "1/6/1983",
            "1/7/1983",
            "1/10/1983",
            "1/11/1983",
            "1/12/1983",
            "1/13/1983",
            "1/14/1983",
            "1/17/1983",
            "1/18/1983",
            "1/19/1983",
            "1/20/1983",
            "1/21/1983",
            "1/24/1983",
            "1/25/1983",
            "1/26/1983",
            "1/27/1983",
            "1/28/1983",
            "1/31/1983",
            "2/1/1983",
            "2/2/1983",
            "2/3/1983",
            "2/4/1983",
            "2/7/1983",
            "2/8/1983",
            "2/9/1983",
            "2/10/1983",
            "2/11/1983",
            "2/14/1983",
            "2/15/1983",
            "2/16/1983",
            "2/17/1983",
            "2/18/1983",
            "2/21/1983",
            "2/22/1983",
            "2/23/1983",
            "2/24/1983",
            "2/25/1983",
            "2/28/1983",
            "3/1/1983",
            "3/2/1983",
            "3/3/1983",
            "3/4/1983",
            "3/7/1983",
            "3/8/1983",
            "3/9/1983",
            "3/10/1983",
            "3/11/1983",
            "3/14/1983",
            "3/15/1983",
            "3/16/1983",
            "3/17/1983",
            "3/18/1983",
            "3/21/1983",
            "3/22/1983",
            "3/23/1983",
            "3/24/1983",
            "3/25/1983",
            "3/28/1983",
            "3/29/1983",
            "3/30/1983",
            "3/31/1983",
            "4/1/1983",
            "4/4/1983",
            "4/5/1983",
            "4/6/1983",
            "4/7/1983",
            "4/8/1983",
            "4/11/1983",
            "4/12/1983",
            "4/13/1983",
            "4/14/1983",
            "4/15/1983",
            "4/18/1983",
            "4/19/1983",
            "4/20/1983",
            "4/21/1983",
            "4/22/1983",
            "4/25/1983",
            "4/26/1983",
            "4/27/1983",
            "4/28/1983",
            "4/29/1983",
            "5/2/1983",
            "5/3/1983",
            "5/4/1983",
            "5/5/1983",
            "5/6/1983",
            "5/9/1983",
            "5/10/1983",
            "5/11/1983",
            "5/12/1983",
            "5/13/1983",
            "5/16/1983",
            "5/17/1983",
            "5/18/1983",
            "5/19/1983",
            "5/20/1983",
            "5/23/1983",
            "5/24/1983",
            "5/25/1983",
            "5/26/1983",
            "5/27/1983",
            "5/30/1983",
            "5/31/1983",
            "6/1/1983",
            "6/2/1983",
            "6/3/1983",
            "6/6/1983",
            "6/7/1983",
            "6/8/1983",
            "6/9/1983",
            "6/10/1983",
            "6/13/1983",
            "6/14/1983",
            "6/15/1983",
            "6/16/1983",
            "6/17/1983",
            "6/20/1983",
            "6/21/1983",
            "6/22/1983",
            "6/23/1983",
            "6/24/1983",
            "6/27/1983",
            "6/28/1983",
            "6/29/1983",
            "6/30/1983",
            "7/1/1983",
            "7/4/1983",
            "7/5/1983",
            "7/6/1983",
            "7/7/1983",
            "7/8/1983",
            "7/11/1983",
            "7/12/1983",
            "7/13/1983",
            "7/14/1983",
            "7/15/1983",
            "7/18/1983",
            "7/19/1983",
            "7/20/1983",
            "7/21/1983",
            "7/22/1983",
            "7/25/1983",
            "7/26/1983",
            "7/27/1983",
            "7/28/1983",
            "7/29/1983",
            "8/1/1983",
            "8/2/1983",
            "8/3/1983",
            "8/4/1983",
            "8/5/1983",
            "8/8/1983",
            "8/9/1983",
            "8/10/1983",
            "8/11/1983",
            "8/12/1983",
            "8/15/1983",
            "8/16/1983",
            "8/17/1983",
            "8/18/1983",
            "8/19/1983",
            "8/22/1983",
            "8/23/1983",
            "8/24/1983",
            "8/25/1983",
            "8/26/1983",
            "8/29/1983",
            "8/30/1983",
            "8/31/1983",
            "9/1/1983",
            "9/2/1983",
            "9/5/1983",
            "9/6/1983",
            "9/7/1983",
            "9/8/1983",
            "9/9/1983",
            "9/12/1983",
            "9/13/1983",
            "9/14/1983",
            "9/15/1983",
            "9/16/1983",
            "9/19/1983",
            "9/20/1983",
            "9/21/1983",
            "9/22/1983",
            "9/23/1983",
            "9/26/1983",
            "9/27/1983",
            "9/28/1983",
            "9/29/1983",
            "9/30/1983",
            "10/3/1983",
            "10/4/1983",
            "10/5/1983",
            "10/6/1983",
            "10/7/1983",
            "10/10/1983",
            "10/11/1983",
            "10/12/1983",
            "10/13/1983",
            "10/14/1983",
            "10/17/1983",
            "10/18/1983",
            "10/19/1983",
            "10/20/1983",
            "10/21/1983",
            "10/24/1983",
            "10/25/1983",
            "10/26/1983",
            "10/27/1983",
            "10/28/1983",
            "10/31/1983",
            "11/1/1983",
            "11/2/1983",
            "11/3/1983",
            "11/4/1983",
            "11/7/1983",
            "11/8/1983",
            "11/9/1983",
            "11/10/1983",
            "11/11/1983",
            "11/14/1983",
            "11/15/1983",
            "11/16/1983",
            "11/17/1983",
            "11/18/1983",
            "11/21/1983",
            "11/22/1983",
            "11/23/1983",
            "11/24/1983",
            "11/25/1983",
            "11/28/1983",
            "11/29/1983",
            "11/30/1983",
            "12/1/1983",
            "12/2/1983",
            "12/5/1983",
            "12/6/1983",
            "12/7/1983",
            "12/8/1983",
            "12/9/1983",
            "12/12/1983",
            "12/13/1983",
            "12/14/1983",
            "12/15/1983",
            "12/16/1983",
            "12/19/1983",
            "12/20/1983",
            "12/21/1983",
            "12/22/1983",
            "12/23/1983",
            "12/26/1983",
            "12/27/1983",
            "12/28/1983",
            "12/29/1983",
            "12/30/1983",
            "1/2/1984",
            "1/3/1984",
            "1/4/1984",
            "1/5/1984",
            "1/6/1984",
            "1/9/1984",
            "1/10/1984",
            "1/11/1984",
            "1/12/1984",
            "1/13/1984",
            "1/16/1984",
            "1/17/1984",
            "1/18/1984",
            "1/19/1984",
            "1/20/1984",
            "1/23/1984",
            "1/24/1984",
            "1/25/1984",
            "1/26/1984",
            "1/27/1984",
            "1/30/1984",
            "1/31/1984",
            "2/1/1984",
            "2/2/1984",
            "2/3/1984",
            "2/6/1984",
            "2/7/1984",
            "2/8/1984",
            "2/9/1984",
            "2/10/1984",
            "2/13/1984",
            "2/14/1984",
            "2/15/1984",
            "2/16/1984",
            "2/17/1984",
            "2/20/1984",
            "2/21/1984",
            "2/22/1984",
            "2/23/1984",
            "2/24/1984",
            "2/27/1984",
            "2/28/1984",
            "2/29/1984",
            "3/1/1984",
            "3/2/1984",
            "3/5/1984",
            "3/6/1984",
            "3/7/1984",
            "3/8/1984",
            "3/9/1984",
            "3/12/1984",
            "3/13/1984",
            "3/14/1984",
            "3/15/1984",
            "3/16/1984",
            "3/19/1984",
            "3/20/1984",
            "3/21/1984",
            "3/22/1984",
            "3/23/1984",
            "3/26/1984",
            "3/27/1984",
            "3/28/1984",
            "3/29/1984",
            "3/30/1984",
            "4/2/1984",
            "4/3/1984",
            "4/4/1984",
            "4/5/1984",
            "4/6/1984",
            "4/9/1984",
            "4/10/1984",
            "4/11/1984",
            "4/12/1984",
            "4/13/1984",
            "4/16/1984",
            "4/17/1984",
            "4/18/1984",
            "4/19/1984",
            "4/20/1984",
            "4/23/1984",
            "4/24/1984",
            "4/25/1984",
            "4/26/1984",
            "4/27/1984",
            "4/30/1984",
            "5/1/1984",
            "5/2/1984",
            "5/3/1984",
            "5/4/1984",
            "5/7/1984",
            "5/8/1984",
            "5/9/1984",
            "5/10/1984",
            "5/11/1984",
            "5/14/1984",
            "5/15/1984",
            "5/16/1984",
            "5/17/1984",
            "5/18/1984",
            "5/21/1984",
            "5/22/1984",
            "5/23/1984",
            "5/24/1984",
            "5/25/1984",
            "5/28/1984",
            "5/29/1984",
            "5/30/1984",
            "5/31/1984",
            "6/1/1984",
            "6/4/1984",
            "6/5/1984",
            "6/6/1984",
            "6/7/1984",
            "6/8/1984",
            "6/11/1984",
            "6/12/1984",
            "6/13/1984",
            "6/14/1984",
            "6/15/1984",
            "6/18/1984",
            "6/19/1984",
            "6/20/1984",
            "6/21/1984",
            "6/22/1984",
            "6/25/1984",
            "6/26/1984",
            "6/27/1984",
            "6/28/1984",
            "6/29/1984",
            "7/2/1984",
            "7/3/1984",
            "7/4/1984",
            "7/5/1984",
            "7/6/1984",
            "7/9/1984",
            "7/10/1984",
            "7/11/1984",
            "7/12/1984",
            "7/13/1984",
            "7/16/1984",
            "7/17/1984",
            "7/18/1984",
            "7/19/1984",
            "7/20/1984",
            "7/23/1984",
            "7/24/1984",
            "7/25/1984",
            "7/26/1984",
            "7/27/1984",
            "7/30/1984",
            "7/31/1984",
            "8/1/1984",
            "8/2/1984",
            "8/3/1984",
            "8/6/1984",
            "8/7/1984",
            "8/8/1984",
            "8/9/1984",
            "8/10/1984",
            "8/13/1984",
            "8/14/1984",
            "8/15/1984",
            "8/16/1984",
            "8/17/1984",
            "8/20/1984",
            "8/21/1984",
            "8/22/1984",
            "8/23/1984",
            "8/24/1984",
            "8/27/1984",
            "8/28/1984",
            "8/29/1984",
            "8/30/1984",
            "8/31/1984",
            "9/3/1984",
            "9/4/1984",
            "9/5/1984",
            "9/6/1984",
            "9/7/1984",
            "9/10/1984",
            "9/11/1984",
            "9/12/1984",
            "9/13/1984",
            "9/14/1984",
            "9/17/1984",
            "9/18/1984",
            "9/19/1984",
            "9/20/1984",
            "9/21/1984",
            "9/24/1984",
            "9/25/1984",
            "9/26/1984",
            "9/27/1984",
            "9/28/1984",
            "10/1/1984",
            "10/2/1984",
            "10/3/1984",
            "10/4/1984",
            "10/5/1984",
            "10/8/1984",
            "10/9/1984",
            "10/10/1984",
            "10/11/1984",
            "10/12/1984",
            "10/15/1984",
            "10/16/1984",
            "10/17/1984",
            "10/18/1984",
            "10/19/1984",
            "10/22/1984",
            "10/23/1984",
            "10/24/1984",
            "10/25/1984",
            "10/26/1984",
            "10/29/1984",
            "10/30/1984",
            "10/31/1984",
            "11/1/1984",
            "11/2/1984",
            "11/5/1984",
            "11/6/1984",
            "11/7/1984",
            "11/8/1984",
            "11/9/1984",
            "11/12/1984",
            "11/13/1984",
            "11/14/1984",
            "11/15/1984",
            "11/16/1984",
            "11/19/1984",
            "11/20/1984",
            "11/21/1984",
            "11/22/1984",
            "11/23/1984",
            "11/26/1984",
            "11/27/1984",
            "11/28/1984",
            "11/29/1984",
            "11/30/1984",
            "12/3/1984",
            "12/4/1984",
            "12/5/1984",
            "12/6/1984",
            "12/7/1984",
            "12/10/1984",
            "12/11/1984",
            "12/12/1984",
            "12/13/1984",
            "12/14/1984",
            "12/17/1984",
            "12/18/1984",
            "12/19/1984",
            "12/20/1984",
            "12/21/1984",
            "12/24/1984",
            "12/25/1984",
            "12/26/1984",
            "12/27/1984",
            "12/28/1984",
            "12/31/1984",
            "1/1/1985",
            "1/2/1985",
            "1/3/1985",
            "1/4/1985",
            "1/7/1985",
            "1/8/1985",
            "1/9/1985",
            "1/10/1985",
            "1/11/1985",
            "1/14/1985",
            "1/15/1985",
            "1/16/1985",
            "1/17/1985",
            "1/18/1985",
            "1/21/1985",
            "1/22/1985",
            "1/23/1985",
            "1/24/1985",
            "1/25/1985",
            "1/28/1985",
            "1/29/1985",
            "1/30/1985",
            "1/31/1985",
            "2/1/1985",
            "2/4/1985",
            "2/5/1985",
            "2/6/1985",
            "2/7/1985",
            "2/8/1985",
            "2/11/1985",
            "2/12/1985",
            "2/13/1985",
            "2/14/1985",
            "2/15/1985",
            "2/18/1985",
            "2/19/1985",
            "2/20/1985",
            "2/21/1985",
            "2/22/1985",
            "2/25/1985",
            "2/26/1985",
            "2/27/1985",
            "2/28/1985",
            "3/1/1985",
            "3/4/1985",
            "3/5/1985",
            "3/6/1985",
            "3/7/1985",
            "3/8/1985",
            "3/11/1985",
            "3/12/1985",
            "3/13/1985",
            "3/14/1985",
            "3/15/1985",
            "3/18/1985",
            "3/19/1985",
            "3/20/1985",
            "3/21/1985",
            "3/22/1985",
            "3/25/1985",
            "3/26/1985",
            "3/27/1985",
            "3/28/1985",
            "3/29/1985",
            "4/1/1985",
            "4/2/1985",
            "4/3/1985",
            "4/4/1985",
            "4/5/1985",
            "4/8/1985",
            "4/9/1985",
            "4/10/1985",
            "4/11/1985",
            "4/12/1985",
            "4/15/1985",
            "4/16/1985",
            "4/17/1985",
            "4/18/1985",
            "4/19/1985",
            "4/22/1985",
            "4/23/1985",
            "4/24/1985",
            "4/25/1985",
            "4/26/1985",
            "4/29/1985",
            "4/30/1985",
            "5/1/1985",
            "5/2/1985",
            "5/3/1985",
            "5/6/1985",
            "5/7/1985",
            "5/8/1985",
            "5/9/1985",
            "5/10/1985",
            "5/13/1985",
            "5/14/1985",
            "5/15/1985",
            "5/16/1985",
            "5/17/1985",
            "5/20/1985",
            "5/21/1985",
            "5/22/1985",
            "5/23/1985",
            "5/24/1985",
            "5/27/1985",
            "5/28/1985",
            "5/29/1985",
            "5/30/1985",
            "5/31/1985",
            "6/3/1985",
            "6/4/1985",
            "6/5/1985",
            "6/6/1985",
            "6/7/1985",
            "6/10/1985",
            "6/11/1985",
            "6/12/1985",
            "6/13/1985",
            "6/14/1985",
            "6/17/1985",
            "6/18/1985",
            "6/19/1985",
            "6/20/1985",
            "6/21/1985",
            "6/24/1985",
            "6/25/1985",
            "6/26/1985",
            "6/27/1985",
            "6/28/1985",
            "7/1/1985",
            "7/2/1985",
            "7/3/1985",
            "7/4/1985",
            "7/5/1985",
            "7/8/1985",
            "7/9/1985",
            "7/10/1985",
            "7/11/1985",
            "7/12/1985",
            "7/15/1985",
            "7/16/1985",
            "7/17/1985",
            "7/18/1985",
            "7/19/1985",
            "7/22/1985",
            "7/23/1985",
            "7/24/1985",
            "7/25/1985",
            "7/26/1985",
            "7/29/1985",
            "7/30/1985",
            "7/31/1985",
            "8/1/1985",
            "8/2/1985",
            "8/5/1985",
            "8/6/1985",
            "8/7/1985",
            "8/8/1985",
            "8/9/1985",
            "8/12/1985",
            "8/13/1985",
            "8/14/1985",
            "8/15/1985",
            "8/16/1985",
            "8/19/1985",
            "8/20/1985",
            "8/21/1985",
            "8/22/1985",
            "8/23/1985",
            "8/26/1985",
            "8/27/1985",
            "8/28/1985",
            "8/29/1985",
            "8/30/1985",
            "9/2/1985",
            "9/3/1985",
            "9/4/1985",
            "9/5/1985",
            "9/6/1985",
            "9/9/1985",
            "9/10/1985",
            "9/11/1985",
            "9/12/1985",
            "9/13/1985",
            "9/16/1985",
            "9/17/1985",
            "9/18/1985",
            "9/19/1985",
            "9/20/1985",
            "9/23/1985",
            "9/24/1985",
            "9/25/1985",
            "9/26/1985",
            "9/27/1985",
            "9/30/1985",
            "10/1/1985",
            "10/2/1985",
            "10/3/1985",
            "10/4/1985",
            "10/7/1985",
            "10/8/1985",
            "10/9/1985",
            "10/10/1985",
            "10/11/1985",
            "10/14/1985",
            "10/15/1985",
            "10/16/1985",
            "10/17/1985",
            "10/18/1985",
            "10/21/1985",
            "10/22/1985",
            "10/23/1985",
            "10/24/1985",
            "10/25/1985",
            "10/28/1985",
            "10/29/1985",
            "10/30/1985",
            "10/31/1985",
            "11/1/1985",
            "11/4/1985",
            "11/5/1985",
            "11/6/1985",
            "11/7/1985",
            "11/8/1985",
            "11/11/1985",
            "11/12/1985",
            "11/13/1985",
            "11/14/1985",
            "11/15/1985",
            "11/18/1985",
            "11/19/1985",
            "11/20/1985",
            "11/21/1985",
            "11/22/1985",
            "11/25/1985",
            "11/26/1985",
            "11/27/1985",
            "11/28/1985",
            "11/29/1985",
            "12/2/1985",
            "12/3/1985",
            "12/4/1985",
            "12/5/1985",
            "12/6/1985",
            "12/9/1985",
            "12/10/1985",
            "12/11/1985",
            "12/12/1985",
            "12/13/1985",
            "12/16/1985",
            "12/17/1985",
            "12/18/1985",
            "12/19/1985",
            "12/20/1985",
            "12/23/1985",
            "12/24/1985",
            "12/25/1985",
            "12/26/1985",
            "12/27/1985",
            "12/30/1985",
            "12/31/1985",
            "1/1/1986",
            "1/2/1986",
            "1/3/1986",
            "1/6/1986",
            "1/7/1986",
            "1/8/1986",
            "1/9/1986",
            "1/10/1986",
            "1/13/1986",
            "1/14/1986",
            "1/15/1986",
            "1/16/1986",
            "1/17/1986",
            "1/20/1986",
            "1/21/1986",
            "1/22/1986",
            "1/23/1986",
            "1/24/1986",
            "1/27/1986",
            "1/28/1986",
            "1/29/1986",
            "1/30/1986",
            "1/31/1986",
            "2/3/1986",
            "2/4/1986",
            "2/5/1986",
            "2/6/1986",
            "2/7/1986",
            "2/10/1986",
            "2/11/1986",
            "2/12/1986",
            "2/13/1986",
            "2/14/1986",
            "2/17/1986",
            "2/18/1986",
            "2/19/1986",
            "2/20/1986",
            "2/21/1986",
            "2/24/1986",
            "2/25/1986",
            "2/26/1986",
            "2/27/1986",
            "2/28/1986",
            "3/3/1986",
            "3/4/1986",
            "3/5/1986",
            "3/6/1986",
            "3/7/1986",
            "3/10/1986",
            "3/11/1986",
            "3/12/1986",
            "3/13/1986",
            "3/14/1986",
            "3/17/1986",
            "3/18/1986",
            "3/19/1986",
            "3/20/1986",
            "3/21/1986",
            "3/24/1986",
            "3/25/1986",
            "3/26/1986",
            "3/27/1986",
            "3/28/1986",
            "3/31/1986",
            "4/1/1986",
            "4/2/1986",
            "4/3/1986",
            "4/4/1986",
            "4/7/1986",
            "4/8/1986",
            "4/9/1986",
            "4/10/1986",
            "4/11/1986",
            "4/14/1986",
            "4/15/1986",
            "4/16/1986",
            "4/17/1986",
            "4/18/1986",
            "4/21/1986",
            "4/22/1986",
            "4/23/1986",
            "4/24/1986",
            "4/25/1986",
            "4/28/1986",
            "4/29/1986",
            "4/30/1986",
            "5/1/1986",
            "5/2/1986",
            "5/5/1986",
            "5/6/1986",
            "5/7/1986",
            "5/8/1986",
            "5/9/1986",
            "5/12/1986",
            "5/13/1986",
            "5/14/1986",
            "5/15/1986",
            "5/16/1986",
            "5/19/1986",
            "5/20/1986",
            "5/21/1986",
            "5/22/1986",
            "5/23/1986",
            "5/26/1986",
            "5/27/1986",
            "5/28/1986",
            "5/29/1986",
            "5/30/1986",
            "6/2/1986",
            "6/3/1986",
            "6/4/1986",
            "6/5/1986",
            "6/6/1986",
            "6/9/1986",
            "6/10/1986",
            "6/11/1986",
            "6/12/1986",
            "6/13/1986",
            "6/16/1986",
            "6/17/1986",
            "6/18/1986",
            "6/19/1986",
            "6/20/1986",
            "6/23/1986",
            "6/24/1986",
            "6/25/1986",
            "6/26/1986",
            "6/27/1986",
            "6/30/1986",
            "7/1/1986",
            "7/2/1986",
            "7/3/1986",
            "7/4/1986",
            "7/7/1986",
            "7/8/1986",
            "7/9/1986",
            "7/10/1986",
            "7/11/1986",
            "7/14/1986",
            "7/15/1986",
            "7/16/1986",
            "7/17/1986",
            "7/18/1986",
            "7/21/1986",
            "7/22/1986",
            "7/23/1986",
            "7/24/1986",
            "7/25/1986",
            "7/28/1986",
            "7/29/1986",
            "7/30/1986",
            "7/31/1986",
            "8/1/1986",
            "8/4/1986",
            "8/5/1986",
            "8/6/1986",
            "8/7/1986",
            "8/8/1986",
            "8/11/1986",
            "8/12/1986",
            "8/13/1986",
            "8/14/1986",
            "8/15/1986",
            "8/18/1986",
            "8/19/1986",
            "8/20/1986",
            "8/21/1986",
            "8/22/1986",
            "8/25/1986",
            "8/26/1986",
            "8/27/1986",
            "8/28/1986",
            "8/29/1986",
            "9/1/1986",
            "9/2/1986",
            "9/3/1986",
            "9/4/1986",
            "9/5/1986",
            "9/8/1986",
            "9/9/1986",
            "9/10/1986",
            "9/11/1986",
            "9/12/1986",
            "9/15/1986",
            "9/16/1986",
            "9/17/1986",
            "9/18/1986",
            "9/19/1986",
            "9/22/1986",
            "9/23/1986",
            "9/24/1986",
            "9/25/1986",
            "9/26/1986",
            "9/29/1986",
            "9/30/1986",
            "10/1/1986",
            "10/2/1986",
            "10/3/1986",
            "10/6/1986",
            "10/7/1986",
            "10/8/1986",
            "10/9/1986",
            "10/10/1986",
            "10/13/1986",
            "10/14/1986",
            "10/15/1986",
            "10/16/1986",
            "10/17/1986",
            "10/20/1986",
            "10/21/1986",
            "10/22/1986",
            "10/23/1986",
            "10/24/1986",
            "10/27/1986",
            "10/28/1986",
            "10/29/1986",
            "10/30/1986",
            "10/31/1986",
            "11/3/1986",
            "11/4/1986",
            "11/5/1986",
            "11/6/1986",
            "11/7/1986",
            "11/10/1986",
            "11/11/1986",
            "11/12/1986",
            "11/13/1986",
            "11/14/1986",
            "11/17/1986",
            "11/18/1986",
            "11/19/1986",
            "11/20/1986",
            "11/21/1986",
            "11/24/1986",
            "11/25/1986",
            "11/26/1986",
            "11/27/1986",
            "11/28/1986",
            "12/1/1986",
            "12/2/1986",
            "12/3/1986",
            "12/4/1986",
            "12/5/1986",
            "12/8/1986",
            "12/9/1986",
            "12/10/1986",
            "12/11/1986",
            "12/12/1986",
            "12/15/1986",
            "12/16/1986",
            "12/17/1986",
            "12/18/1986",
            "12/19/1986",
            "12/22/1986",
            "12/23/1986",
            "12/24/1986",
            "12/25/1986",
            "12/26/1986",
            "12/29/1986",
            "12/30/1986",
            "12/31/1986",
            "1/1/1987",
            "1/2/1987",
            "1/5/1987",
            "1/6/1987",
            "1/7/1987",
            "1/8/1987",
            "1/9/1987",
            "1/12/1987",
            "1/13/1987",
            "1/14/1987",
            "1/15/1987",
            "1/16/1987",
            "1/19/1987",
            "1/20/1987",
            "1/21/1987",
            "1/22/1987",
            "1/23/1987",
            "1/26/1987",
            "1/27/1987",
            "1/28/1987",
            "1/29/1987",
            "1/30/1987",
            "2/2/1987",
            "2/3/1987",
            "2/4/1987",
            "2/5/1987",
            "2/6/1987",
            "2/9/1987",
            "2/10/1987",
            "2/11/1987",
            "2/12/1987",
            "2/13/1987",
            "2/16/1987",
            "2/17/1987",
            "2/18/1987",
            "2/19/1987",
            "2/20/1987",
            "2/23/1987",
            "2/24/1987",
            "2/25/1987",
            "2/26/1987",
            "2/27/1987",
            "3/2/1987",
            "3/3/1987",
            "3/4/1987",
            "3/5/1987",
            "3/6/1987",
            "3/9/1987",
            "3/10/1987",
            "3/11/1987",
            "3/12/1987",
            "3/13/1987",
            "3/16/1987",
            "3/17/1987",
            "3/18/1987",
            "3/19/1987",
            "3/20/1987",
            "3/23/1987",
            "3/24/1987",
            "3/25/1987",
            "3/26/1987",
            "3/27/1987",
            "3/30/1987",
            "3/31/1987",
            "4/1/1987",
            "4/2/1987",
            "4/3/1987",
            "4/6/1987",
            "4/7/1987",
            "4/8/1987",
            "4/9/1987",
            "4/10/1987",
            "4/13/1987",
            "4/14/1987",
            "4/15/1987",
            "4/16/1987",
            "4/17/1987",
            "4/20/1987",
            "4/21/1987",
            "4/22/1987",
            "4/23/1987",
            "4/24/1987",
            "4/27/1987",
            "4/28/1987",
            "4/29/1987",
            "4/30/1987",
            "5/1/1987",
            "5/4/1987",
            "5/5/1987",
            "5/6/1987",
            "5/7/1987",
            "5/8/1987",
            "5/11/1987",
            "5/12/1987",
            "5/13/1987",
            "5/14/1987",
            "5/15/1987",
            "5/18/1987",
            "5/19/1987",
            "5/20/1987",
            "5/21/1987",
            "5/22/1987",
            "5/25/1987",
            "5/26/1987",
            "5/27/1987",
            "5/28/1987",
            "5/29/1987",
            "6/1/1987",
            "6/2/1987",
            "6/3/1987",
            "6/4/1987",
            "6/5/1987",
            "6/8/1987",
            "6/9/1987",
            "6/10/1987",
            "6/11/1987",
            "6/12/1987",
            "6/15/1987",
            "6/16/1987",
            "6/17/1987",
            "6/18/1987",
            "6/19/1987",
            "6/22/1987",
            "6/23/1987",
            "6/24/1987",
            "6/25/1987",
            "6/26/1987",
            "6/29/1987",
            "6/30/1987",
            "7/1/1987",
            "7/2/1987",
            "7/3/1987",
            "7/6/1987",
            "7/7/1987",
            "7/8/1987",
            "7/9/1987",
            "7/10/1987",
            "7/13/1987",
            "7/14/1987",
            "7/15/1987",
            "7/16/1987",
            "7/17/1987",
            "7/20/1987",
            "7/21/1987",
            "7/22/1987",
            "7/23/1987",
            "7/24/1987",
            "7/27/1987",
            "7/28/1987",
            "7/29/1987",
            "7/30/1987",
            "7/31/1987",
            "8/3/1987",
            "8/4/1987",
            "8/5/1987",
            "8/6/1987",
            "8/7/1987",
            "8/10/1987",
            "8/11/1987",
            "8/12/1987",
            "8/13/1987",
            "8/14/1987",
            "8/17/1987",
            "8/18/1987",
            "8/19/1987",
            "8/20/1987",
            "8/21/1987",
            "8/24/1987",
            "8/25/1987",
            "8/26/1987",
            "8/27/1987",
            "8/28/1987",
            "8/31/1987",
            "9/1/1987",
            "9/2/1987",
            "9/3/1987",
            "9/4/1987",
            "9/7/1987",
            "9/8/1987",
            "9/9/1987",
            "9/10/1987",
            "9/11/1987",
            "9/14/1987",
            "9/15/1987",
            "9/16/1987",
            "9/17/1987",
            "9/18/1987",
            "9/21/1987",
            "9/22/1987",
            "9/23/1987",
            "9/24/1987",
            "9/25/1987",
            "9/28/1987",
            "9/29/1987",
            "9/30/1987",
            "10/1/1987",
            "10/2/1987",
            "10/5/1987",
            "10/6/1987",
            "10/7/1987",
            "10/8/1987",
            "10/9/1987",
            "10/12/1987",
            "10/13/1987",
            "10/14/1987",
            "10/15/1987",
            "10/16/1987",
            "10/19/1987",
            "10/20/1987",
            "10/21/1987",
            "10/22/1987",
            "10/23/1987",
            "10/26/1987",
            "10/27/1987",
            "10/28/1987",
            "10/29/1987",
            "10/30/1987",
            "11/2/1987",
            "11/3/1987",
            "11/4/1987",
            "11/5/1987",
            "11/6/1987",
            "11/9/1987",
            "11/10/1987",
            "11/11/1987",
            "11/12/1987",
            "11/13/1987",
            "11/16/1987",
            "11/17/1987",
            "11/18/1987",
            "11/19/1987",
            "11/20/1987",
            "11/23/1987",
            "11/24/1987",
            "11/25/1987",
            "11/26/1987",
            "11/27/1987",
            "11/30/1987",
            "12/1/1987",
            "12/2/1987",
            "12/3/1987",
            "12/4/1987",
            "12/7/1987",
            "12/8/1987",
            "12/9/1987",
            "12/10/1987",
            "12/11/1987",
            "12/14/1987",
            "12/15/1987",
            "12/16/1987",
            "12/17/1987",
            "12/18/1987",
            "12/21/1987",
            "12/22/1987",
            "12/23/1987",
            "12/24/1987",
            "12/25/1987",
            "12/28/1987",
            "12/29/1987",
            "12/30/1987",
            "12/31/1987",
            "1/1/1988",
            "1/4/1988",
            "1/5/1988",
            "1/6/1988",
            "1/7/1988",
            "1/8/1988",
            "1/11/1988",
            "1/12/1988",
            "1/13/1988",
            "1/14/1988",
            "1/15/1988",
            "1/18/1988",
            "1/19/1988",
            "1/20/1988",
            "1/21/1988",
            "1/22/1988",
            "1/25/1988",
            "1/26/1988",
            "1/27/1988",
            "1/28/1988",
            "1/29/1988",
            "2/1/1988",
            "2/2/1988",
            "2/3/1988",
            "2/4/1988",
            "2/5/1988",
            "2/8/1988",
            "2/9/1988",
            "2/10/1988",
            "2/11/1988",
            "2/12/1988",
            "2/15/1988",
            "2/16/1988",
            "2/17/1988",
            "2/18/1988",
            "2/19/1988",
            "2/22/1988",
            "2/23/1988",
            "2/24/1988",
            "2/25/1988",
            "2/26/1988",
            "2/29/1988",
            "3/1/1988",
            "3/2/1988",
            "3/3/1988",
            "3/4/1988",
            "3/7/1988",
            "3/8/1988",
            "3/9/1988",
            "3/10/1988",
            "3/11/1988",
            "3/14/1988",
            "3/15/1988",
            "3/16/1988",
            "3/17/1988",
            "3/18/1988",
            "3/21/1988",
            "3/22/1988",
            "3/23/1988",
            "3/24/1988",
            "3/25/1988",
            "3/28/1988",
            "3/29/1988",
            "3/30/1988",
            "3/31/1988",
            "4/1/1988",
            "4/4/1988",
            "4/5/1988",
            "4/6/1988",
            "4/7/1988",
            "4/8/1988",
            "4/11/1988",
            "4/12/1988",
            "4/13/1988",
            "4/14/1988",
            "4/15/1988",
            "4/18/1988",
            "4/19/1988",
            "4/20/1988",
            "4/21/1988",
            "4/22/1988",
            "4/25/1988",
            "4/26/1988",
            "4/27/1988",
            "4/28/1988",
            "4/29/1988",
            "5/2/1988",
            "5/3/1988",
            "5/4/1988",
            "5/5/1988",
            "5/6/1988",
            "5/9/1988",
            "5/10/1988",
            "5/11/1988",
            "5/12/1988",
            "5/13/1988",
            "5/16/1988",
            "5/17/1988",
            "5/18/1988",
            "5/19/1988",
            "5/20/1988",
            "5/23/1988",
            "5/24/1988",
            "5/25/1988",
            "5/26/1988",
            "5/27/1988",
            "5/30/1988",
            "5/31/1988",
            "6/1/1988",
            "6/2/1988",
            "6/3/1988",
            "6/6/1988",
            "6/7/1988",
            "6/8/1988",
            "6/9/1988",
            "6/10/1988",
            "6/13/1988",
            "6/14/1988",
            "6/15/1988",
            "6/16/1988",
            "6/17/1988",
            "6/20/1988",
            "6/21/1988",
            "6/22/1988",
            "6/23/1988",
            "6/24/1988",
            "6/27/1988",
            "6/28/1988",
            "6/29/1988",
            "6/30/1988",
            "7/1/1988",
            "7/4/1988",
            "7/5/1988",
            "7/6/1988",
            "7/7/1988",
            "7/8/1988",
            "7/11/1988",
            "7/12/1988",
            "7/13/1988",
            "7/14/1988",
            "7/15/1988",
            "7/18/1988",
            "7/19/1988",
            "7/20/1988",
            "7/21/1988",
            "7/22/1988",
            "7/25/1988",
            "7/26/1988",
            "7/27/1988",
            "7/28/1988",
            "7/29/1988",
            "8/1/1988",
            "8/2/1988",
            "8/3/1988",
            "8/4/1988",
            "8/5/1988",
            "8/8/1988",
            "8/9/1988",
            "8/10/1988",
            "8/11/1988",
            "8/12/1988",
            "8/15/1988",
            "8/16/1988",
            "8/17/1988",
            "8/18/1988",
            "8/19/1988",
            "8/22/1988",
            "8/23/1988",
            "8/24/1988",
            "8/25/1988",
            "8/26/1988",
            "8/29/1988",
            "8/30/1988",
            "8/31/1988",
            "9/1/1988",
            "9/2/1988",
            "9/5/1988",
            "9/6/1988",
            "9/7/1988",
            "9/8/1988",
            "9/9/1988",
            "9/12/1988",
            "9/13/1988",
            "9/14/1988",
            "9/15/1988",
            "9/16/1988",
            "9/19/1988",
            "9/20/1988",
            "9/21/1988",
            "9/22/1988",
            "9/23/1988",
            "9/26/1988",
            "9/27/1988",
            "9/28/1988",
            "9/29/1988",
            "9/30/1988",
            "10/3/1988",
            "10/4/1988",
            "10/5/1988",
            "10/6/1988",
            "10/7/1988",
            "10/10/1988",
            "10/11/1988",
            "10/12/1988",
            "10/13/1988",
            "10/14/1988",
            "10/17/1988",
            "10/18/1988",
            "10/19/1988",
            "10/20/1988",
            "10/21/1988",
            "10/24/1988",
            "10/25/1988",
            "10/26/1988",
            "10/27/1988",
            "10/28/1988",
            "10/31/1988",
            "11/1/1988",
            "11/2/1988",
            "11/3/1988",
            "11/4/1988",
            "11/7/1988",
            "11/8/1988",
            "11/9/1988",
            "11/10/1988",
            "11/11/1988",
            "11/14/1988",
            "11/15/1988",
            "11/16/1988",
            "11/17/1988",
            "11/18/1988",
            "11/21/1988",
            "11/22/1988",
            "11/23/1988",
            "11/24/1988",
            "11/25/1988",
            "11/28/1988",
            "11/29/1988",
            "11/30/1988",
            "12/1/1988",
            "12/2/1988",
            "12/5/1988",
            "12/6/1988",
            "12/7/1988",
            "12/8/1988",
            "12/9/1988",
            "12/12/1988",
            "12/13/1988",
            "12/14/1988",
            "12/15/1988",
            "12/16/1988",
            "12/19/1988",
            "12/20/1988",
            "12/21/1988",
            "12/22/1988",
            "12/23/1988",
            "12/26/1988",
            "12/27/1988",
            "12/28/1988",
            "12/29/1988",
            "12/30/1988",
            "1/2/1989",
            "1/3/1989",
            "1/4/1989",
            "1/5/1989",
            "1/6/1989",
            "1/9/1989",
            "1/10/1989",
            "1/11/1989",
            "1/12/1989",
            "1/13/1989",
            "1/16/1989",
            "1/17/1989",
            "1/18/1989",
            "1/19/1989",
            "1/20/1989",
            "1/23/1989",
            "1/24/1989",
            "1/25/1989",
            "1/26/1989",
            "1/27/1989",
            "1/30/1989",
            "1/31/1989",
            "2/1/1989",
            "2/2/1989",
            "2/3/1989",
            "2/6/1989",
            "2/7/1989",
            "2/8/1989",
            "2/9/1989",
            "2/10/1989",
            "2/13/1989",
            "2/14/1989",
            "2/15/1989",
            "2/16/1989",
            "2/17/1989",
            "2/20/1989",
            "2/21/1989",
            "2/22/1989",
            "2/23/1989",
            "2/24/1989",
            "2/27/1989",
            "2/28/1989",
            "3/1/1989",
            "3/2/1989",
            "3/3/1989",
            "3/6/1989",
            "3/7/1989",
            "3/8/1989",
            "3/9/1989",
            "3/10/1989",
            "3/13/1989",
            "3/14/1989",
            "3/15/1989",
            "3/16/1989",
            "3/17/1989",
            "3/20/1989",
            "3/21/1989",
            "3/22/1989",
            "3/23/1989",
            "3/24/1989",
            "3/27/1989",
            "3/28/1989",
            "3/29/1989",
            "3/30/1989",
            "3/31/1989",
            "4/3/1989",
            "4/4/1989",
            "4/5/1989",
            "4/6/1989",
            "4/7/1989",
            "4/10/1989",
            "4/11/1989",
            "4/12/1989",
            "4/13/1989",
            "4/14/1989",
            "4/17/1989",
            "4/18/1989",
            "4/19/1989",
            "4/20/1989",
            "4/21/1989",
            "4/24/1989",
            "4/25/1989",
            "4/26/1989",
            "4/27/1989",
            "4/28/1989",
            "5/1/1989",
            "5/2/1989",
            "5/3/1989",
            "5/4/1989",
            "5/5/1989",
            "5/8/1989",
            "5/9/1989",
            "5/10/1989",
            "5/11/1989",
            "5/12/1989",
            "5/15/1989",
            "5/16/1989",
            "5/17/1989",
            "5/18/1989",
            "5/19/1989",
            "5/22/1989",
            "5/23/1989",
            "5/24/1989",
            "5/25/1989",
            "5/26/1989",
            "5/29/1989",
            "5/30/1989",
            "5/31/1989",
            "6/1/1989",
            "6/2/1989",
            "6/5/1989",
            "6/6/1989",
            "6/7/1989",
            "6/8/1989",
            "6/9/1989",
            "6/12/1989",
            "6/13/1989",
            "6/14/1989",
            "6/15/1989",
            "6/16/1989",
            "6/19/1989",
            "6/20/1989",
            "6/21/1989",
            "6/22/1989",
            "6/23/1989",
            "6/26/1989",
            "6/27/1989",
            "6/28/1989",
            "6/29/1989",
            "6/30/1989",
            "7/3/1989",
            "7/4/1989",
            "7/5/1989",
            "7/6/1989",
            "7/7/1989",
            "7/10/1989",
            "7/11/1989",
            "7/12/1989",
            "7/13/1989",
            "7/14/1989",
            "7/17/1989",
            "7/18/1989",
            "7/19/1989",
            "7/20/1989",
            "7/21/1989",
            "7/24/1989",
            "7/25/1989",
            "7/26/1989",
            "7/27/1989",
            "7/28/1989",
            "7/31/1989",
            "8/1/1989",
            "8/2/1989",
            "8/3/1989",
            "8/4/1989",
            "8/7/1989",
            "8/8/1989",
            "8/9/1989",
            "8/10/1989",
            "8/11/1989",
            "8/14/1989",
            "8/15/1989",
            "8/16/1989",
            "8/17/1989",
            "8/18/1989",
            "8/21/1989",
            "8/22/1989",
            "8/23/1989",
            "8/24/1989",
            "8/25/1989",
            "8/28/1989",
            "8/29/1989",
            "8/30/1989",
            "8/31/1989",
            "9/1/1989",
            "9/4/1989",
            "9/5/1989",
            "9/6/1989",
            "9/7/1989",
            "9/8/1989",
            "9/11/1989",
            "9/12/1989",
            "9/13/1989",
            "9/14/1989",
            "9/15/1989",
            "9/18/1989",
            "9/19/1989",
            "9/20/1989",
            "9/21/1989",
            "9/22/1989",
            "9/25/1989",
            "9/26/1989",
            "9/27/1989",
            "9/28/1989",
            "9/29/1989",
            "10/2/1989",
            "10/3/1989",
            "10/4/1989",
            "10/5/1989",
            "10/6/1989",
            "10/9/1989",
            "10/10/1989",
            "10/11/1989",
            "10/12/1989",
            "10/13/1989",
            "10/16/1989",
            "10/17/1989",
            "10/18/1989",
            "10/19/1989",
            "10/20/1989",
            "10/23/1989",
            "10/24/1989",
            "10/25/1989",
            "10/26/1989",
            "10/27/1989",
            "10/30/1989",
            "10/31/1989",
            "11/1/1989",
            "11/2/1989",
            "11/3/1989",
            "11/6/1989",
            "11/7/1989",
            "11/8/1989",
            "11/9/1989",
            "11/10/1989",
            "11/13/1989",
            "11/14/1989",
            "11/15/1989",
            "11/16/1989",
            "11/17/1989",
            "11/20/1989",
            "11/21/1989",
            "11/22/1989",
            "11/23/1989",
            "11/24/1989",
            "11/27/1989",
            "11/28/1989",
            "11/29/1989",
            "11/30/1989",
            "12/1/1989",
            "12/4/1989",
            "12/5/1989",
            "12/6/1989",
            "12/7/1989",
            "12/8/1989",
            "12/11/1989",
            "12/12/1989",
            "12/13/1989",
            "12/14/1989",
            "12/15/1989",
            "12/18/1989",
            "12/19/1989",
            "12/20/1989",
            "12/21/1989",
            "12/22/1989",
            "12/25/1989",
            "12/26/1989",
            "12/27/1989",
            "12/28/1989",
            "12/29/1989",
            "1/1/1990",
            "1/2/1990",
            "1/3/1990",
            "1/4/1990",
            "1/5/1990",
            "1/8/1990",
            "1/9/1990",
            "1/10/1990",
            "1/11/1990",
            "1/12/1990",
            "1/15/1990",
            "1/16/1990",
            "1/17/1990",
            "1/18/1990",
            "1/19/1990",
            "1/22/1990",
            "1/23/1990",
            "1/24/1990",
            "1/25/1990",
            "1/26/1990",
            "1/29/1990",
            "1/30/1990",
            "1/31/1990",
            "2/1/1990",
            "2/2/1990",
            "2/5/1990",
            "2/6/1990",
            "2/7/1990",
            "2/8/1990",
            "2/9/1990",
            "2/12/1990",
            "2/13/1990",
            "2/14/1990",
            "2/15/1990",
            "2/16/1990",
            "2/19/1990",
            "2/20/1990",
            "2/21/1990",
            "2/22/1990",
            "2/23/1990",
            "2/26/1990",
            "2/27/1990",
            "2/28/1990",
            "3/1/1990",
            "3/2/1990",
            "3/5/1990",
            "3/6/1990",
            "3/7/1990",
            "3/8/1990",
            "3/9/1990",
            "3/12/1990",
            "3/13/1990",
            "3/14/1990",
            "3/15/1990",
            "3/16/1990",
            "3/19/1990",
            "3/20/1990",
            "3/21/1990",
            "3/22/1990",
            "3/23/1990",
            "3/26/1990",
            "3/27/1990",
            "3/28/1990",
            "3/29/1990",
            "3/30/1990",
            "4/2/1990",
            "4/3/1990",
            "4/4/1990",
            "4/5/1990",
            "4/6/1990",
            "4/9/1990",
            "4/10/1990",
            "4/11/1990",
            "4/12/1990",
            "4/13/1990",
            "4/16/1990",
            "4/17/1990",
            "4/18/1990",
            "4/19/1990",
            "4/20/1990",
            "4/23/1990",
            "4/24/1990",
            "4/25/1990",
            "4/26/1990",
            "4/27/1990",
            "4/30/1990",
            "5/1/1990",
            "5/2/1990",
            "5/3/1990",
            "5/4/1990",
            "5/7/1990",
            "5/8/1990",
            "5/9/1990",
            "5/10/1990",
            "5/11/1990",
            "5/14/1990",
            "5/15/1990",
            "5/16/1990",
            "5/17/1990",
            "5/18/1990",
            "5/21/1990",
            "5/22/1990",
            "5/23/1990",
            "5/24/1990",
            "5/25/1990",
            "5/28/1990",
            "5/29/1990",
            "5/30/1990",
            "5/31/1990",
            "6/1/1990",
            "6/4/1990",
            "6/5/1990",
            "6/6/1990",
            "6/7/1990",
            "6/8/1990",
            "6/11/1990",
            "6/12/1990",
            "6/13/1990",
            "6/14/1990",
            "6/15/1990",
            "6/18/1990",
            "6/19/1990",
            "6/20/1990",
            "6/21/1990",
            "6/22/1990",
            "6/25/1990",
            "6/26/1990",
            "6/27/1990",
            "6/28/1990",
            "6/29/1990",
            "7/2/1990",
            "7/3/1990",
            "7/4/1990",
            "7/5/1990",
            "7/6/1990",
            "7/9/1990",
            "7/10/1990",
            "7/11/1990",
            "7/12/1990",
            "7/13/1990",
            "7/16/1990",
            "7/17/1990",
            "7/18/1990",
            "7/19/1990",
            "7/20/1990",
            "7/23/1990",
            "7/24/1990",
            "7/25/1990",
            "7/26/1990",
            "7/27/1990",
            "7/30/1990",
            "7/31/1990",
            "8/1/1990",
            "8/2/1990",
            "8/3/1990",
            "8/6/1990",
            "8/7/1990",
            "8/8/1990",
            "8/9/1990",
            "8/10/1990",
            "8/13/1990",
            "8/14/1990",
            "8/15/1990",
            "8/16/1990",
            "8/17/1990",
            "8/20/1990",
            "8/21/1990",
            "8/22/1990",
            "8/23/1990",
            "8/24/1990",
            "8/27/1990",
            "8/28/1990",
            "8/29/1990",
            "8/30/1990",
            "8/31/1990",
            "9/3/1990",
            "9/4/1990",
            "9/5/1990",
            "9/6/1990",
            "9/7/1990",
            "9/10/1990",
            "9/11/1990",
            "9/12/1990",
            "9/13/1990",
            "9/14/1990",
            "9/17/1990",
            "9/18/1990",
            "9/19/1990",
            "9/20/1990",
            "9/21/1990",
            "9/24/1990",
            "9/25/1990",
            "9/26/1990",
            "9/27/1990",
            "9/28/1990",
            "10/1/1990",
            "10/2/1990",
            "10/3/1990",
            "10/4/1990",
            "10/5/1990",
            "10/8/1990",
            "10/9/1990",
            "10/10/1990",
            "10/11/1990",
            "10/12/1990",
            "10/15/1990",
            "10/16/1990",
            "10/17/1990",
            "10/18/1990",
            "10/19/1990",
            "10/22/1990",
            "10/23/1990",
            "10/24/1990",
            "10/25/1990",
            "10/26/1990",
            "10/29/1990",
            "10/30/1990",
            "10/31/1990",
            "11/1/1990",
            "11/2/1990",
            "11/5/1990",
            "11/6/1990",
            "11/7/1990",
            "11/8/1990",
            "11/9/1990",
            "11/12/1990",
            "11/13/1990",
            "11/14/1990",
            "11/15/1990",
            "11/16/1990",
            "11/19/1990",
            "11/20/1990",
            "11/21/1990",
            "11/22/1990",
            "11/23/1990",
            "11/26/1990",
            "11/27/1990",
            "11/28/1990",
            "11/29/1990",
            "11/30/1990",
            "12/3/1990",
            "12/4/1990",
            "12/5/1990",
            "12/6/1990",
            "12/7/1990",
            "12/10/1990",
            "12/11/1990",
            "12/12/1990",
            "12/13/1990",
            "12/14/1990",
            "12/17/1990",
            "12/18/1990",
            "12/19/1990",
            "12/20/1990",
            "12/21/1990",
            "12/24/1990",
            "12/25/1990",
            "12/26/1990",
            "12/27/1990",
            "12/28/1990",
            "12/31/1990",
            "1/1/1991",
            "1/2/1991",
            "1/3/1991",
            "1/4/1991",
            "1/7/1991",
            "1/8/1991",
            "1/9/1991",
            "1/10/1991",
            "1/11/1991",
            "1/14/1991",
            "1/15/1991",
            "1/16/1991",
            "1/17/1991",
            "1/18/1991",
            "1/21/1991",
            "1/22/1991",
            "1/23/1991",
            "1/24/1991",
            "1/25/1991",
            "1/28/1991",
            "1/29/1991",
            "1/30/1991",
            "1/31/1991",
            "2/1/1991",
            "2/4/1991",
            "2/5/1991",
            "2/6/1991",
            "2/7/1991",
            "2/8/1991",
            "2/11/1991",
            "2/12/1991",
            "2/13/1991",
            "2/14/1991",
            "2/15/1991",
            "2/18/1991",
            "2/19/1991",
            "2/20/1991",
            "2/21/1991",
            "2/22/1991",
            "2/25/1991",
            "2/26/1991",
            "2/27/1991",
            "2/28/1991",
            "3/1/1991",
            "3/4/1991",
            "3/5/1991",
            "3/6/1991",
            "3/7/1991",
            "3/8/1991",
            "3/11/1991",
            "3/12/1991",
            "3/13/1991",
            "3/14/1991",
            "3/15/1991",
            "3/18/1991",
            "3/19/1991",
            "3/20/1991",
            "3/21/1991",
            "3/22/1991",
            "3/25/1991",
            "3/26/1991",
            "3/27/1991",
            "3/28/1991",
            "3/29/1991",
            "4/1/1991",
            "4/2/1991",
            "4/3/1991",
            "4/4/1991",
            "4/5/1991",
            "4/8/1991",
            "4/9/1991",
            "4/10/1991",
            "4/11/1991",
            "4/12/1991",
            "4/15/1991",
            "4/16/1991",
            "4/17/1991",
            "4/18/1991",
            "4/19/1991",
            "4/22/1991",
            "4/23/1991",
            "4/24/1991",
            "4/25/1991",
            "4/26/1991",
            "4/29/1991",
            "4/30/1991",
            "5/1/1991",
            "5/2/1991",
            "5/3/1991",
            "5/6/1991",
            "5/7/1991",
            "5/8/1991",
            "5/9/1991",
            "5/10/1991",
            "5/13/1991",
            "5/14/1991",
            "5/15/1991",
            "5/16/1991",
            "5/17/1991",
            "5/20/1991",
            "5/21/1991",
            "5/22/1991",
            "5/23/1991",
            "5/24/1991",
            "5/27/1991",
            "5/28/1991",
            "5/29/1991",
            "5/30/1991",
            "5/31/1991",
            "6/3/1991",
            "6/4/1991",
            "6/5/1991",
            "6/6/1991",
            "6/7/1991",
            "6/10/1991",
            "6/11/1991",
            "6/12/1991",
            "6/13/1991",
            "6/14/1991",
            "6/17/1991",
            "6/18/1991",
            "6/19/1991",
            "6/20/1991",
            "6/21/1991",
            "6/24/1991",
            "6/25/1991",
            "6/26/1991",
            "6/27/1991",
            "6/28/1991",
            "7/1/1991",
            "7/2/1991",
            "7/3/1991",
            "7/4/1991",
            "7/5/1991",
            "7/8/1991",
            "7/9/1991",
            "7/10/1991",
            "7/11/1991",
            "7/12/1991",
            "7/15/1991",
            "7/16/1991",
            "7/17/1991",
            "7/18/1991",
            "7/19/1991",
            "7/22/1991",
            "7/23/1991",
            "7/24/1991",
            "7/25/1991",
            "7/26/1991",
            "7/29/1991",
            "7/30/1991",
            "7/31/1991",
            "8/1/1991",
            "8/2/1991",
            "8/5/1991",
            "8/6/1991",
            "8/7/1991",
            "8/8/1991",
            "8/9/1991",
            "8/12/1991",
            "8/13/1991",
            "8/14/1991",
            "8/15/1991",
            "8/16/1991",
            "8/19/1991",
            "8/20/1991",
            "8/21/1991",
            "8/22/1991",
            "8/23/1991",
            "8/26/1991",
            "8/27/1991",
            "8/28/1991",
            "8/29/1991",
            "8/30/1991",
            "9/2/1991",
            "9/3/1991",
            "9/4/1991",
            "9/5/1991",
            "9/6/1991",
            "9/9/1991",
            "9/10/1991",
            "9/11/1991",
            "9/12/1991",
            "9/13/1991",
            "9/16/1991",
            "9/17/1991",
            "9/18/1991",
            "9/19/1991",
            "9/20/1991",
            "9/23/1991",
            "9/24/1991",
            "9/25/1991",
            "9/26/1991",
            "9/27/1991",
            "9/30/1991",
            "10/1/1991",
            "10/2/1991",
            "10/3/1991",
            "10/4/1991",
            "10/7/1991",
            "10/8/1991",
            "10/9/1991",
            "10/10/1991",
            "10/11/1991",
            "10/14/1991",
            "10/15/1991",
            "10/16/1991",
            "10/17/1991",
            "10/18/1991",
            "10/21/1991",
            "10/22/1991",
            "10/23/1991",
            "10/24/1991",
            "10/25/1991",
            "10/28/1991",
            "10/29/1991",
            "10/30/1991",
            "10/31/1991",
            "11/1/1991",
            "11/4/1991",
            "11/5/1991",
            "11/6/1991",
            "11/7/1991",
            "11/8/1991",
            "11/11/1991",
            "11/12/1991",
            "11/13/1991",
            "11/14/1991",
            "11/15/1991",
            "11/18/1991",
            "11/19/1991",
            "11/20/1991",
            "11/21/1991",
            "11/22/1991",
            "11/25/1991",
            "11/26/1991",
            "11/27/1991",
            "11/28/1991",
            "11/29/1991",
            "12/2/1991",
            "12/3/1991",
            "12/4/1991",
            "12/5/1991",
            "12/6/1991",
            "12/9/1991",
            "12/10/1991",
            "12/11/1991",
            "12/12/1991",
            "12/13/1991",
            "12/16/1991",
            "12/17/1991",
            "12/18/1991",
            "12/19/1991",
            "12/20/1991",
            "12/23/1991",
            "12/24/1991",
            "12/25/1991",
            "12/26/1991",
            "12/27/1991",
            "12/30/1991",
            "12/31/1991",
            "1/1/1992",
            "1/2/1992",
            "1/3/1992",
            "1/6/1992",
            "1/7/1992",
            "1/8/1992",
            "1/9/1992",
            "1/10/1992",
            "1/13/1992",
            "1/14/1992",
            "1/15/1992",
            "1/16/1992",
            "1/17/1992",
            "1/20/1992",
            "1/21/1992",
            "1/22/1992",
            "1/23/1992",
            "1/24/1992",
            "1/27/1992",
            "1/28/1992",
            "1/29/1992",
            "1/30/1992",
            "1/31/1992",
            "2/3/1992",
            "2/4/1992",
            "2/5/1992",
            "2/6/1992",
            "2/7/1992",
            "2/10/1992",
            "2/11/1992",
            "2/12/1992",
            "2/13/1992",
            "2/14/1992",
            "2/17/1992",
            "2/18/1992",
            "2/19/1992",
            "2/20/1992",
            "2/21/1992",
            "2/24/1992",
            "2/25/1992",
            "2/26/1992",
            "2/27/1992",
            "2/28/1992",
            "3/2/1992",
            "3/3/1992",
            "3/4/1992",
            "3/5/1992",
            "3/6/1992",
            "3/9/1992",
            "3/10/1992",
            "3/11/1992",
            "3/12/1992",
            "3/13/1992",
            "3/16/1992",
            "3/17/1992",
            "3/18/1992",
            "3/19/1992",
            "3/20/1992",
            "3/23/1992",
            "3/24/1992",
            "3/25/1992",
            "3/26/1992",
            "3/27/1992",
            "3/30/1992",
            "3/31/1992",
            "4/1/1992",
            "4/2/1992",
            "4/3/1992",
            "4/6/1992",
            "4/7/1992",
            "4/8/1992",
            "4/9/1992",
            "4/10/1992",
            "4/13/1992",
            "4/14/1992",
            "4/15/1992",
            "4/16/1992",
            "4/17/1992",
            "4/20/1992",
            "4/21/1992",
            "4/22/1992",
            "4/23/1992",
            "4/24/1992",
            "4/27/1992",
            "4/28/1992",
            "4/29/1992",
            "4/30/1992",
            "5/1/1992",
            "5/4/1992",
            "5/5/1992",
            "5/6/1992",
            "5/7/1992",
            "5/8/1992",
            "5/11/1992",
            "5/12/1992",
            "5/13/1992",
            "5/14/1992",
            "5/15/1992",
            "5/18/1992",
            "5/19/1992",
            "5/20/1992",
            "5/21/1992",
            "5/22/1992",
            "5/25/1992",
            "5/26/1992",
            "5/27/1992",
            "5/28/1992",
            "5/29/1992",
            "6/1/1992",
            "6/2/1992",
            "6/3/1992",
            "6/4/1992",
            "6/5/1992",
            "6/8/1992",
            "6/9/1992",
            "6/10/1992",
            "6/11/1992",
            "6/12/1992",
            "6/15/1992",
            "6/16/1992",
            "6/17/1992",
            "6/18/1992",
            "6/19/1992",
            "6/22/1992",
            "6/23/1992",
            "6/24/1992",
            "6/25/1992",
            "6/26/1992",
            "6/29/1992",
            "6/30/1992",
            "7/1/1992",
            "7/2/1992",
            "7/3/1992",
            "7/6/1992",
            "7/7/1992",
            "7/8/1992",
            "7/9/1992",
            "7/10/1992",
            "7/13/1992",
            "7/14/1992",
            "7/15/1992",
            "7/16/1992",
            "7/17/1992",
            "7/20/1992",
            "7/21/1992",
            "7/22/1992",
            "7/23/1992",
            "7/24/1992",
            "7/27/1992",
            "7/28/1992",
            "7/29/1992",
            "7/30/1992",
            "7/31/1992",
            "8/3/1992",
            "8/4/1992",
            "8/5/1992",
            "8/6/1992",
            "8/7/1992",
            "8/10/1992",
            "8/11/1992",
            "8/12/1992",
            "8/13/1992",
            "8/14/1992",
            "8/17/1992",
            "8/18/1992",
            "8/19/1992",
            "8/20/1992",
            "8/21/1992",
            "8/24/1992",
            "8/25/1992",
            "8/26/1992",
            "8/27/1992",
            "8/28/1992",
            "8/31/1992",
            "9/1/1992",
            "9/2/1992",
            "9/3/1992",
            "9/4/1992",
            "9/7/1992",
            "9/8/1992",
            "9/9/1992",
            "9/10/1992",
            "9/11/1992",
            "9/14/1992",
            "9/15/1992",
            "9/16/1992",
            "9/17/1992",
            "9/18/1992",
            "9/21/1992",
            "9/22/1992",
            "9/23/1992",
            "9/24/1992",
            "9/25/1992",
            "9/28/1992",
            "9/29/1992",
            "9/30/1992",
            "10/1/1992",
            "10/2/1992",
            "10/5/1992",
            "10/6/1992",
            "10/7/1992",
            "10/8/1992",
            "10/9/1992",
            "10/12/1992",
            "10/13/1992",
            "10/14/1992",
            "10/15/1992",
            "10/16/1992",
            "10/19/1992",
            "10/20/1992",
            "10/21/1992",
            "10/22/1992",
            "10/23/1992",
            "10/26/1992",
            "10/27/1992",
            "10/28/1992",
            "10/29/1992",
            "10/30/1992",
            "11/2/1992",
            "11/3/1992",
            "11/4/1992",
            "11/5/1992",
            "11/6/1992",
            "11/9/1992",
            "11/10/1992",
            "11/11/1992",
            "11/12/1992",
            "11/13/1992",
            "11/16/1992",
            "11/17/1992",
            "11/18/1992",
            "11/19/1992",
            "11/20/1992",
            "11/23/1992",
            "11/24/1992",
            "11/25/1992",
            "11/26/1992",
            "11/27/1992",
            "11/30/1992",
            "12/1/1992",
            "12/2/1992",
            "12/3/1992",
            "12/4/1992",
            "12/7/1992",
            "12/8/1992",
            "12/9/1992",
            "12/10/1992",
            "12/11/1992",
            "12/14/1992",
            "12/15/1992",
            "12/16/1992",
            "12/17/1992",
            "12/18/1992",
            "12/21/1992",
            "12/22/1992",
            "12/23/1992",
            "12/24/1992",
            "12/25/1992",
            "12/28/1992",
            "12/29/1992",
            "12/30/1992",
            "12/31/1992",
            "1/1/1993",
            "1/4/1993",
            "1/5/1993",
            "1/6/1993",
            "1/7/1993",
            "1/8/1993",
            "1/11/1993",
            "1/12/1993",
            "1/13/1993",
            "1/14/1993",
            "1/15/1993",
            "1/18/1993",
            "1/19/1993",
            "1/20/1993",
            "1/21/1993",
            "1/22/1993",
            "1/25/1993",
            "1/26/1993",
            "1/27/1993",
            "1/28/1993",
            "1/29/1993",
            "2/1/1993",
            "2/2/1993",
            "2/3/1993",
            "2/4/1993",
            "2/5/1993",
            "2/8/1993",
            "2/9/1993",
            "2/10/1993",
            "2/11/1993",
            "2/12/1993",
            "2/15/1993",
            "2/16/1993",
            "2/17/1993",
            "2/18/1993",
            "2/19/1993",
            "2/22/1993",
            "2/23/1993",
            "2/24/1993",
            "2/25/1993",
            "2/26/1993",
            "3/1/1993",
            "3/2/1993",
            "3/3/1993",
            "3/4/1993",
            "3/5/1993",
            "3/8/1993",
            "3/9/1993",
            "3/10/1993",
            "3/11/1993",
            "3/12/1993",
            "3/15/1993",
            "3/16/1993",
            "3/17/1993",
            "3/18/1993",
            "3/19/1993",
            "3/22/1993",
            "3/23/1993",
            "3/24/1993",
            "3/25/1993",
            "3/26/1993",
            "3/29/1993",
            "3/30/1993",
            "3/31/1993",
            "4/1/1993",
            "4/2/1993",
            "4/5/1993",
            "4/6/1993",
            "4/7/1993",
            "4/8/1993",
            "4/9/1993",
            "4/12/1993",
            "4/13/1993",
            "4/14/1993",
            "4/15/1993",
            "4/16/1993",
            "4/19/1993",
            "4/20/1993",
            "4/21/1993",
            "4/22/1993",
            "4/23/1993",
            "4/26/1993",
            "4/27/1993",
            "4/28/1993",
            "4/29/1993",
            "4/30/1993",
            "5/3/1993",
            "5/4/1993",
            "5/5/1993",
            "5/6/1993",
            "5/7/1993",
            "5/10/1993",
            "5/11/1993",
            "5/12/1993",
            "5/13/1993",
            "5/14/1993",
            "5/17/1993",
            "5/18/1993",
            "5/19/1993",
            "5/20/1993",
            "5/21/1993",
            "5/24/1993",
            "5/25/1993",
            "5/26/1993",
            "5/27/1993",
            "5/28/1993",
            "5/31/1993",
            "6/1/1993",
            "6/2/1993",
            "6/3/1993",
            "6/4/1993",
            "6/7/1993",
            "6/8/1993",
            "6/9/1993",
            "6/10/1993",
            "6/11/1993",
            "6/14/1993",
            "6/15/1993",
            "6/16/1993",
            "6/17/1993",
            "6/18/1993",
            "6/21/1993",
            "6/22/1993",
            "6/23/1993",
            "6/24/1993",
            "6/25/1993",
            "6/28/1993",
            "6/29/1993",
            "6/30/1993",
            "7/1/1993",
            "7/2/1993",
            "7/5/1993",
            "7/6/1993",
            "7/7/1993",
            "7/8/1993",
            "7/9/1993",
            "7/12/1993",
            "7/13/1993",
            "7/14/1993",
            "7/15/1993",
            "7/16/1993",
            "7/19/1993",
            "7/20/1993",
            "7/21/1993",
            "7/22/1993",
            "7/23/1993",
            "7/26/1993",
            "7/27/1993",
            "7/28/1993",
            "7/29/1993",
            "7/30/1993",
            "8/2/1993",
            "8/3/1993",
            "8/4/1993",
            "8/5/1993",
            "8/6/1993",
            "8/9/1993",
            "8/10/1993",
            "8/11/1993",
            "8/12/1993",
            "8/13/1993",
            "8/16/1993",
            "8/17/1993",
            "8/18/1993",
            "8/19/1993",
            "8/20/1993",
            "8/23/1993",
            "8/24/1993",
            "8/25/1993",
            "8/26/1993",
            "8/27/1993",
            "8/30/1993",
            "8/31/1993",
            "9/1/1993",
            "9/2/1993",
            "9/3/1993",
            "9/6/1993",
            "9/7/1993",
            "9/8/1993",
            "9/9/1993",
            "9/10/1993",
            "9/13/1993",
            "9/14/1993",
            "9/15/1993",
            "9/16/1993",
            "9/17/1993",
            "9/20/1993",
            "9/21/1993",
            "9/22/1993",
            "9/23/1993",
            "9/24/1993",
            "9/27/1993",
            "9/28/1993",
            "9/29/1993",
            "9/30/1993",
            "10/1/1993",
            "10/4/1993",
            "10/5/1993",
            "10/6/1993",
            "10/7/1993",
            "10/8/1993",
            "10/11/1993",
            "10/12/1993",
            "10/13/1993",
            "10/14/1993",
            "10/15/1993",
            "10/18/1993",
            "10/19/1993",
            "10/20/1993",
            "10/21/1993",
            "10/22/1993",
            "10/25/1993",
            "10/26/1993",
            "10/27/1993",
            "10/28/1993",
            "10/29/1993",
            "11/1/1993",
            "11/2/1993",
            "11/3/1993",
            "11/4/1993",
            "11/5/1993",
            "11/8/1993",
            "11/9/1993",
            "11/10/1993",
            "11/11/1993",
            "11/12/1993",
            "11/15/1993",
            "11/16/1993",
            "11/17/1993",
            "11/18/1993",
            "11/19/1993",
            "11/22/1993",
            "11/23/1993",
            "11/24/1993",
            "11/25/1993",
            "11/26/1993",
            "11/29/1993",
            "11/30/1993",
            "12/1/1993",
            "12/2/1993",
            "12/3/1993",
            "12/6/1993",
            "12/7/1993",
            "12/8/1993",
            "12/9/1993",
            "12/10/1993",
            "12/13/1993",
            "12/14/1993",
            "12/15/1993",
            "12/16/1993",
            "12/17/1993",
            "12/20/1993",
            "12/21/1993",
            "12/22/1993",
            "12/23/1993",
            "12/24/1993",
            "12/27/1993",
            "12/28/1993",
            "12/29/1993",
            "12/30/1993",
            "12/31/1993",
            "1/3/1994",
            "1/4/1994",
            "1/5/1994",
            "1/6/1994",
            "1/7/1994",
            "1/10/1994",
            "1/11/1994",
            "1/12/1994",
            "1/13/1994",
            "1/14/1994",
            "1/17/1994",
            "1/18/1994",
            "1/19/1994",
            "1/20/1994",
            "1/21/1994",
            "1/24/1994",
            "1/25/1994",
            "1/26/1994",
            "1/27/1994",
            "1/28/1994",
            "1/31/1994",
            "2/1/1994",
            "2/2/1994",
            "2/3/1994",
            "2/4/1994",
            "2/7/1994",
            "2/8/1994",
            "2/9/1994",
            "2/10/1994",
            "2/11/1994",
            "2/14/1994",
            "2/15/1994",
            "2/16/1994",
            "2/17/1994",
            "2/18/1994",
            "2/21/1994",
            "2/22/1994",
            "2/23/1994",
            "2/24/1994",
            "2/25/1994",
            "2/28/1994",
            "3/1/1994",
            "3/2/1994",
            "3/3/1994",
            "3/4/1994",
            "3/7/1994",
            "3/8/1994",
            "3/9/1994",
            "3/10/1994",
            "3/11/1994",
            "3/14/1994",
            "3/15/1994",
            "3/16/1994",
            "3/17/1994",
            "3/18/1994",
            "3/21/1994",
            "3/22/1994",
            "3/23/1994",
            "3/24/1994",
            "3/25/1994",
            "3/28/1994",
            "3/29/1994",
            "3/30/1994",
            "3/31/1994",
            "4/1/1994",
            "4/4/1994",
            "4/5/1994",
            "4/6/1994",
            "4/7/1994",
            "4/8/1994",
            "4/11/1994",
            "4/12/1994",
            "4/13/1994",
            "4/14/1994",
            "4/15/1994",
            "4/18/1994",
            "4/19/1994",
            "4/20/1994",
            "4/21/1994",
            "4/22/1994",
            "4/25/1994",
            "4/26/1994",
            "4/27/1994",
            "4/28/1994",
            "4/29/1994",
            "5/2/1994",
            "5/3/1994",
            "5/4/1994",
            "5/5/1994",
            "5/6/1994",
            "5/9/1994",
            "5/10/1994",
            "5/11/1994",
            "5/12/1994",
            "5/13/1994",
            "5/16/1994",
            "5/17/1994",
            "5/18/1994",
            "5/19/1994",
            "5/20/1994",
            "5/23/1994",
            "5/24/1994",
            "5/25/1994",
            "5/26/1994",
            "5/27/1994",
            "5/30/1994",
            "5/31/1994",
            "6/1/1994",
            "6/2/1994",
            "6/3/1994",
            "6/6/1994",
            "6/7/1994",
            "6/8/1994",
            "6/9/1994",
            "6/10/1994",
            "6/13/1994",
            "6/14/1994",
            "6/15/1994",
            "6/16/1994",
            "6/17/1994",
            "6/20/1994",
            "6/21/1994",
            "6/22/1994",
            "6/23/1994",
            "6/24/1994",
            "6/27/1994",
            "6/28/1994",
            "6/29/1994",
            "6/30/1994",
            "7/1/1994",
            "7/4/1994",
            "7/5/1994",
            "7/6/1994",
            "7/7/1994",
            "7/8/1994",
            "7/11/1994",
            "7/12/1994",
            "7/13/1994",
            "7/14/1994",
            "7/15/1994",
            "7/18/1994",
            "7/19/1994",
            "7/20/1994",
            "7/21/1994",
            "7/22/1994",
            "7/25/1994",
            "7/26/1994",
            "7/27/1994",
            "7/28/1994",
            "7/29/1994",
            "8/1/1994",
            "8/2/1994",
            "8/3/1994",
            "8/4/1994",
            "8/5/1994",
            "8/8/1994",
            "8/9/1994",
            "8/10/1994",
            "8/11/1994",
            "8/12/1994",
            "8/15/1994",
            "8/16/1994",
            "8/17/1994",
            "8/18/1994",
            "8/19/1994",
            "8/22/1994",
            "8/23/1994",
            "8/24/1994",
            "8/25/1994",
            "8/26/1994",
            "8/29/1994",
            "8/30/1994",
            "8/31/1994",
            "9/1/1994",
            "9/2/1994",
            "9/5/1994",
            "9/6/1994",
            "9/7/1994",
            "9/8/1994",
            "9/9/1994",
            "9/12/1994",
            "9/13/1994",
            "9/14/1994",
            "9/15/1994",
            "9/16/1994",
            "9/19/1994",
            "9/20/1994",
            "9/21/1994",
            "9/22/1994",
            "9/23/1994",
            "9/26/1994",
            "9/27/1994",
            "9/28/1994",
            "9/29/1994",
            "9/30/1994",
            "10/3/1994",
            "10/4/1994",
            "10/5/1994",
            "10/6/1994",
            "10/7/1994",
            "10/10/1994",
            "10/11/1994",
            "10/12/1994",
            "10/13/1994",
            "10/14/1994",
            "10/17/1994",
            "10/18/1994",
            "10/19/1994",
            "10/20/1994",
            "10/21/1994",
            "10/24/1994",
            "10/25/1994",
            "10/26/1994",
            "10/27/1994",
            "10/28/1994",
            "10/31/1994",
            "11/1/1994",
            "11/2/1994",
            "11/3/1994",
            "11/4/1994",
            "11/7/1994",
            "11/8/1994",
            "11/9/1994",
            "11/10/1994",
            "11/11/1994",
            "11/14/1994",
            "11/15/1994",
            "11/16/1994",
            "11/17/1994",
            "11/18/1994",
            "11/21/1994",
            "11/22/1994",
            "11/23/1994",
            "11/24/1994",
            "11/25/1994",
            "11/28/1994",
            "11/29/1994",
            "11/30/1994",
            "12/1/1994",
            "12/2/1994",
            "12/5/1994",
            "12/6/1994",
            "12/7/1994",
            "12/8/1994",
            "12/9/1994",
            "12/12/1994",
            "12/13/1994",
            "12/14/1994",
            "12/15/1994",
            "12/16/1994",
            "12/19/1994",
            "12/20/1994",
            "12/21/1994",
            "12/22/1994",
            "12/23/1994",
            "12/26/1994",
            "12/27/1994",
            "12/28/1994",
            "12/29/1994",
            "12/30/1994",
            "1/2/1995",
            "1/3/1995",
            "1/4/1995",
            "1/5/1995",
            "1/6/1995",
            "1/9/1995",
            "1/10/1995",
            "1/11/1995",
            "1/12/1995",
            "1/13/1995",
            "1/16/1995",
            "1/17/1995",
            "1/18/1995",
            "1/19/1995",
            "1/20/1995",
            "1/23/1995",
            "1/24/1995",
            "1/25/1995",
            "1/26/1995",
            "1/27/1995",
            "1/30/1995",
            "1/31/1995",
            "2/1/1995",
            "2/2/1995",
            "2/3/1995",
            "2/6/1995",
            "2/7/1995",
            "2/8/1995",
            "2/9/1995",
            "2/10/1995",
            "2/13/1995",
            "2/14/1995",
            "2/15/1995",
            "2/16/1995",
            "2/17/1995",
            "2/20/1995",
            "2/21/1995",
            "2/22/1995",
            "2/23/1995",
            "2/24/1995",
            "2/27/1995",
            "2/28/1995",
            "3/1/1995",
            "3/2/1995",
            "3/3/1995",
            "3/6/1995",
            "3/7/1995",
            "3/8/1995",
            "3/9/1995",
            "3/10/1995",
            "3/13/1995",
            "3/14/1995",
            "3/15/1995",
            "3/16/1995",
            "3/17/1995",
            "3/20/1995",
            "3/21/1995",
            "3/22/1995",
            "3/23/1995",
            "3/24/1995",
            "3/27/1995",
            "3/28/1995",
            "3/29/1995",
            "3/30/1995",
            "3/31/1995",
            "4/3/1995",
            "4/4/1995",
            "4/5/1995",
            "4/6/1995",
            "4/7/1995",
            "4/10/1995",
            "4/11/1995",
            "4/12/1995",
            "4/13/1995",
            "4/14/1995",
            "4/17/1995",
            "4/18/1995",
            "4/19/1995",
            "4/20/1995",
            "4/21/1995",
            "4/24/1995",
            "4/25/1995",
            "4/26/1995",
            "4/27/1995",
            "4/28/1995",
            "5/1/1995",
            "5/2/1995",
            "5/3/1995",
            "5/4/1995",
            "5/5/1995",
            "5/8/1995",
            "5/9/1995",
            "5/10/1995",
            "5/11/1995",
            "5/12/1995",
            "5/15/1995",
            "5/16/1995",
            "5/17/1995",
            "5/18/1995",
            "5/19/1995",
            "5/22/1995",
            "5/23/1995",
            "5/24/1995",
            "5/25/1995",
            "5/26/1995",
            "5/29/1995",
            "5/30/1995",
            "5/31/1995",
            "6/1/1995",
            "6/2/1995",
            "6/5/1995",
            "6/6/1995",
            "6/7/1995",
            "6/8/1995",
            "6/9/1995",
            "6/12/1995",
            "6/13/1995",
            "6/14/1995",
            "6/15/1995",
            "6/16/1995",
            "6/19/1995",
            "6/20/1995",
            "6/21/1995",
            "6/22/1995",
            "6/23/1995",
            "6/26/1995",
            "6/27/1995",
            "6/28/1995",
            "6/29/1995",
            "6/30/1995",
            "7/3/1995",
            "7/4/1995",
            "7/5/1995",
            "7/6/1995",
            "7/7/1995",
            "7/10/1995",
            "7/11/1995",
            "7/12/1995",
            "7/13/1995",
            "7/14/1995",
            "7/17/1995",
            "7/18/1995",
            "7/19/1995",
            "7/20/1995",
            "7/21/1995",
            "7/24/1995",
            "7/25/1995",
            "7/26/1995",
            "7/27/1995",
            "7/28/1995",
            "7/31/1995",
            "8/1/1995",
            "8/2/1995",
            "8/3/1995",
            "8/4/1995",
            "8/7/1995",
            "8/8/1995",
            "8/9/1995",
            "8/10/1995",
            "8/11/1995",
            "8/14/1995",
            "8/15/1995",
            "8/16/1995",
            "8/17/1995",
            "8/18/1995",
            "8/21/1995",
            "8/22/1995",
            "8/23/1995",
            "8/24/1995",
            "8/25/1995",
            "8/28/1995",
            "8/29/1995",
            "8/30/1995",
            "8/31/1995",
            "9/1/1995",
            "9/4/1995",
            "9/5/1995",
            "9/6/1995",
            "9/7/1995",
            "9/8/1995",
            "9/11/1995",
            "9/12/1995",
            "9/13/1995",
            "9/14/1995",
            "9/15/1995",
            "9/18/1995",
            "9/19/1995",
            "9/20/1995",
            "9/21/1995",
            "9/22/1995",
            "9/25/1995",
            "9/26/1995",
            "9/27/1995",
            "9/28/1995",
            "9/29/1995",
            "10/2/1995",
            "10/3/1995",
            "10/4/1995",
            "10/5/1995",
            "10/6/1995",
            "10/9/1995",
            "10/10/1995",
            "10/11/1995",
            "10/12/1995",
            "10/13/1995",
            "10/16/1995",
            "10/17/1995",
            "10/18/1995",
            "10/19/1995",
            "10/20/1995",
            "10/23/1995",
            "10/24/1995",
            "10/25/1995",
            "10/26/1995",
            "10/27/1995",
            "10/30/1995",
            "10/31/1995",
            "11/1/1995",
            "11/2/1995",
            "11/3/1995",
            "11/6/1995",
            "11/7/1995",
            "11/8/1995",
            "11/9/1995",
            "11/10/1995",
            "11/13/1995",
            "11/14/1995",
            "11/15/1995",
            "11/16/1995",
            "11/17/1995",
            "11/20/1995",
            "11/21/1995",
            "11/22/1995",
            "11/23/1995",
            "11/24/1995",
            "11/27/1995",
            "11/28/1995",
            "11/29/1995",
            "11/30/1995",
            "12/1/1995",
            "12/4/1995",
            "12/5/1995",
            "12/6/1995",
            "12/7/1995",
            "12/8/1995",
            "12/11/1995",
            "12/12/1995",
            "12/13/1995",
            "12/14/1995",
            "12/15/1995",
            "12/18/1995",
            "12/19/1995",
            "12/20/1995",
            "12/21/1995",
            "12/22/1995",
            "12/25/1995",
            "12/26/1995",
            "12/27/1995",
            "12/28/1995",
            "12/29/1995",
            "1/1/1996",
            "1/2/1996",
            "1/3/1996",
            "1/4/1996",
            "1/5/1996",
            "1/8/1996",
            "1/9/1996",
            "1/10/1996",
            "1/11/1996",
            "1/12/1996",
            "1/15/1996",
            "1/16/1996",
            "1/17/1996",
            "1/18/1996",
            "1/19/1996",
            "1/22/1996",
            "1/23/1996",
            "1/24/1996",
            "1/25/1996",
            "1/26/1996",
            "1/29/1996",
            "1/30/1996",
            "1/31/1996",
            "2/1/1996",
            "2/2/1996",
            "2/5/1996",
            "2/6/1996",
            "2/7/1996",
            "2/8/1996",
            "2/9/1996",
            "2/12/1996",
            "2/13/1996",
            "2/14/1996",
            "2/15/1996",
            "2/16/1996",
            "2/19/1996",
            "2/20/1996",
            "2/21/1996",
            "2/22/1996",
            "2/23/1996",
            "2/26/1996",
            "2/27/1996",
            "2/28/1996",
            "2/29/1996",
            "3/1/1996",
            "3/4/1996",
            "3/5/1996",
            "3/6/1996",
            "3/7/1996",
            "3/8/1996",
            "3/11/1996",
            "3/12/1996",
            "3/13/1996",
            "3/14/1996",
            "3/15/1996",
            "3/18/1996",
            "3/19/1996",
            "3/20/1996",
            "3/21/1996",
            "3/22/1996",
            "3/25/1996",
            "3/26/1996",
            "3/27/1996",
            "3/28/1996",
            "3/29/1996",
            "4/1/1996",
            "4/2/1996",
            "4/3/1996",
            "4/4/1996",
            "4/5/1996",
            "4/8/1996",
            "4/9/1996",
            "4/10/1996",
            "4/11/1996",
            "4/12/1996",
            "4/15/1996",
            "4/16/1996",
            "4/17/1996",
            "4/18/1996",
            "4/19/1996",
            "4/22/1996",
            "4/23/1996",
            "4/24/1996",
            "4/25/1996",
            "4/26/1996",
            "4/29/1996",
            "4/30/1996",
            "5/1/1996",
            "5/2/1996",
            "5/3/1996",
            "5/6/1996",
            "5/7/1996",
            "5/8/1996",
            "5/9/1996",
            "5/10/1996",
            "5/13/1996",
            "5/14/1996",
            "5/15/1996",
            "5/16/1996",
            "5/17/1996",
            "5/20/1996",
            "5/21/1996",
            "5/22/1996",
            "5/23/1996",
            "5/24/1996",
            "5/27/1996",
            "5/28/1996",
            "5/29/1996",
            "5/30/1996",
            "5/31/1996",
            "6/3/1996",
            "6/4/1996",
            "6/5/1996",
            "6/6/1996",
            "6/7/1996",
            "6/10/1996",
            "6/11/1996",
            "6/12/1996",
            "6/13/1996",
            "6/14/1996",
            "6/17/1996",
            "6/18/1996",
            "6/19/1996",
            "6/20/1996",
            "6/21/1996",
            "6/24/1996",
            "6/25/1996",
            "6/26/1996",
            "6/27/1996",
            "6/28/1996",
            "7/1/1996",
            "7/2/1996",
            "7/3/1996",
            "7/4/1996",
            "7/5/1996",
            "7/8/1996",
            "7/9/1996",
            "7/10/1996",
            "7/11/1996",
            "7/12/1996",
            "7/15/1996",
            "7/16/1996",
            "7/17/1996",
            "7/18/1996",
            "7/19/1996",
            "7/22/1996",
            "7/23/1996",
            "7/24/1996",
            "7/25/1996",
            "7/26/1996",
            "7/29/1996",
            "7/30/1996",
            "7/31/1996",
            "8/1/1996",
            "8/2/1996",
            "8/5/1996",
            "8/6/1996",
            "8/7/1996",
            "8/8/1996",
            "8/9/1996",
            "8/12/1996",
            "8/13/1996",
            "8/14/1996",
            "8/15/1996",
            "8/16/1996",
            "8/19/1996",
            "8/20/1996",
            "8/21/1996",
            "8/22/1996",
            "8/23/1996",
            "8/26/1996",
            "8/27/1996",
            "8/28/1996",
            "8/29/1996",
            "8/30/1996",
            "9/2/1996",
            "9/3/1996",
            "9/4/1996",
            "9/5/1996",
            "9/6/1996",
            "9/9/1996",
            "9/10/1996",
            "9/11/1996",
            "9/12/1996",
            "9/13/1996",
            "9/16/1996",
            "9/17/1996",
            "9/18/1996",
            "9/19/1996",
            "9/20/1996",
            "9/23/1996",
            "9/24/1996",
            "9/25/1996",
            "9/26/1996",
            "9/27/1996",
            "9/30/1996",
            "10/1/1996",
            "10/2/1996",
            "10/3/1996",
            "10/4/1996",
            "10/7/1996",
            "10/8/1996",
            "10/9/1996",
            "10/10/1996",
            "10/11/1996",
            "10/14/1996",
            "10/15/1996",
            "10/16/1996",
            "10/17/1996",
            "10/18/1996",
            "10/21/1996",
            "10/22/1996",
            "10/23/1996",
            "10/24/1996",
            "10/25/1996",
            "10/28/1996",
            "10/29/1996",
            "10/30/1996",
            "10/31/1996",
            "11/1/1996",
            "11/4/1996",
            "11/5/1996",
            "11/6/1996",
            "11/7/1996",
            "11/8/1996",
            "11/11/1996",
            "11/12/1996",
            "11/13/1996",
            "11/14/1996",
            "11/15/1996",
            "11/18/1996",
            "11/19/1996",
            "11/20/1996",
            "11/21/1996",
            "11/22/1996",
            "11/25/1996",
            "11/26/1996",
            "11/27/1996",
            "11/28/1996",
            "11/29/1996",
            "12/2/1996",
            "12/3/1996",
            "12/4/1996",
            "12/5/1996",
            "12/6/1996",
            "12/9/1996",
            "12/10/1996",
            "12/11/1996",
            "12/12/1996",
            "12/13/1996",
            "12/16/1996",
            "12/17/1996",
            "12/18/1996",
            "12/19/1996",
            "12/20/1996",
            "12/23/1996",
            "12/24/1996",
            "12/25/1996",
            "12/26/1996",
            "12/27/1996",
            "12/30/1996",
            "12/31/1996",
            "1/1/1997",
            "1/2/1997",
            "1/3/1997",
            "1/6/1997",
            "1/7/1997",
            "1/8/1997",
            "1/9/1997",
            "1/10/1997",
            "1/13/1997",
            "1/14/1997",
            "1/15/1997",
            "1/16/1997",
            "1/17/1997",
            "1/20/1997",
            "1/21/1997",
            "1/22/1997",
            "1/23/1997",
            "1/24/1997",
            "1/27/1997",
            "1/28/1997",
            "1/29/1997",
            "1/30/1997",
            "1/31/1997",
            "2/3/1997",
            "2/4/1997",
            "2/5/1997",
            "2/6/1997",
            "2/7/1997",
            "2/10/1997",
            "2/11/1997",
            "2/12/1997",
            "2/13/1997",
            "2/14/1997",
            "2/17/1997",
            "2/18/1997",
            "2/19/1997",
            "2/20/1997",
            "2/21/1997",
            "2/24/1997",
            "2/25/1997",
            "2/26/1997",
            "2/27/1997",
            "2/28/1997",
            "3/3/1997",
            "3/4/1997",
            "3/5/1997",
            "3/6/1997",
            "3/7/1997",
            "3/10/1997",
            "3/11/1997",
            "3/12/1997",
            "3/13/1997",
            "3/14/1997",
            "3/17/1997",
            "3/18/1997",
            "3/19/1997",
            "3/20/1997",
            "3/21/1997",
            "3/24/1997",
            "3/25/1997",
            "3/26/1997",
            "3/27/1997",
            "3/28/1997",
            "3/31/1997",
            "4/1/1997",
            "4/2/1997",
            "4/3/1997",
            "4/4/1997",
            "4/7/1997",
            "4/8/1997",
            "4/9/1997",
            "4/10/1997",
            "4/11/1997",
            "4/14/1997",
            "4/15/1997",
            "4/16/1997",
            "4/17/1997",
            "4/18/1997",
            "4/21/1997",
            "4/22/1997",
            "4/23/1997",
            "4/24/1997",
            "4/25/1997",
            "4/28/1997",
            "4/29/1997",
            "4/30/1997",
            "5/1/1997",
            "5/2/1997",
            "5/5/1997",
            "5/6/1997",
            "5/7/1997",
            "5/8/1997",
            "5/9/1997",
            "5/12/1997",
            "5/13/1997",
            "5/14/1997",
            "5/15/1997",
            "5/16/1997",
            "5/19/1997",
            "5/20/1997",
            "5/21/1997",
            "5/22/1997",
            "5/23/1997",
            "5/26/1997",
            "5/27/1997",
            "5/28/1997",
            "5/29/1997",
            "5/30/1997",
            "6/2/1997",
            "6/3/1997",
            "6/4/1997",
            "6/5/1997",
            "6/6/1997",
            "6/9/1997",
            "6/10/1997",
            "6/11/1997",
            "6/12/1997",
            "6/13/1997",
            "6/16/1997",
            "6/17/1997",
            "6/18/1997",
            "6/19/1997",
            "6/20/1997",
            "6/23/1997",
            "6/24/1997",
            "6/25/1997",
            "6/26/1997",
            "6/27/1997",
            "6/30/1997",
            "7/1/1997",
            "7/2/1997",
            "7/3/1997",
            "7/4/1997",
            "7/7/1997",
            "7/8/1997",
            "7/9/1997",
            "7/10/1997",
            "7/11/1997",
            "7/14/1997",
            "7/15/1997",
            "7/16/1997",
            "7/17/1997",
            "7/18/1997",
            "7/21/1997",
            "7/22/1997",
            "7/23/1997",
            "7/24/1997",
            "7/25/1997",
            "7/28/1997",
            "7/29/1997",
            "7/30/1997",
            "7/31/1997",
            "8/1/1997",
            "8/4/1997",
            "8/5/1997",
            "8/6/1997",
            "8/7/1997",
            "8/8/1997",
            "8/11/1997",
            "8/12/1997",
            "8/13/1997",
            "8/14/1997",
            "8/15/1997",
            "8/18/1997",
            "8/19/1997",
            "8/20/1997",
            "8/21/1997",
            "8/22/1997",
            "8/25/1997",
            "8/26/1997",
            "8/27/1997",
            "8/28/1997",
            "8/29/1997",
            "9/1/1997",
            "9/2/1997",
            "9/3/1997",
            "9/4/1997",
            "9/5/1997",
            "9/8/1997",
            "9/9/1997",
            "9/10/1997",
            "9/11/1997",
            "9/12/1997",
            "9/15/1997",
            "9/16/1997",
            "9/17/1997",
            "9/18/1997",
            "9/19/1997",
            "9/22/1997",
            "9/23/1997",
            "9/24/1997",
            "9/25/1997",
            "9/26/1997",
            "9/29/1997",
            "9/30/1997",
            "10/1/1997",
            "10/2/1997",
            "10/3/1997",
            "10/6/1997",
            "10/7/1997",
            "10/8/1997",
            "10/9/1997",
            "10/10/1997",
            "10/13/1997",
            "10/14/1997",
            "10/15/1997",
            "10/16/1997",
            "10/17/1997",
            "10/20/1997",
            "10/21/1997",
            "10/22/1997",
            "10/23/1997",
            "10/24/1997",
            "10/27/1997",
            "10/28/1997",
            "10/29/1997",
            "10/30/1997",
            "10/31/1997",
            "11/3/1997",
            "11/4/1997",
            "11/5/1997",
            "11/6/1997",
            "11/7/1997",
            "11/10/1997",
            "11/11/1997",
            "11/12/1997",
            "11/13/1997",
            "11/14/1997",
            "11/17/1997",
            "11/18/1997",
            "11/19/1997",
            "11/20/1997",
            "11/21/1997",
            "11/24/1997",
            "11/25/1997",
            "11/26/1997",
            "11/27/1997",
            "11/28/1997",
            "12/1/1997",
            "12/2/1997",
            "12/3/1997",
            "12/4/1997",
            "12/5/1997",
            "12/8/1997",
            "12/9/1997",
            "12/10/1997",
            "12/11/1997",
            "12/12/1997",
            "12/15/1997",
            "12/16/1997",
            "12/17/1997",
            "12/18/1997",
            "12/19/1997",
            "12/22/1997",
            "12/23/1997",
            "12/24/1997",
            "12/25/1997",
            "12/26/1997",
            "12/29/1997",
            "12/30/1997",
            "12/31/1997",
            "1/1/1998",
            "1/2/1998",
            "1/5/1998",
            "1/6/1998",
            "1/7/1998",
            "1/8/1998",
            "1/9/1998",
            "1/12/1998",
            "1/13/1998",
            "1/14/1998",
            "1/15/1998",
            "1/16/1998",
            "1/19/1998",
            "1/20/1998",
            "1/21/1998",
            "1/22/1998",
            "1/23/1998",
            "1/26/1998",
            "1/27/1998",
            "1/28/1998",
            "1/29/1998",
            "1/30/1998",
            "2/2/1998",
            "2/3/1998",
            "2/4/1998",
            "2/5/1998",
            "2/6/1998",
            "2/9/1998",
            "2/10/1998",
            "2/11/1998",
            "2/12/1998",
            "2/13/1998",
            "2/16/1998",
            "2/17/1998",
            "2/18/1998",
            "2/19/1998",
            "2/20/1998",
            "2/23/1998",
            "2/24/1998",
            "2/25/1998",
            "2/26/1998",
            "2/27/1998",
            "3/2/1998",
            "3/3/1998",
            "3/4/1998",
            "3/5/1998",
            "3/6/1998",
            "3/9/1998",
            "3/10/1998",
            "3/11/1998",
            "3/12/1998",
            "3/13/1998",
            "3/16/1998",
            "3/17/1998",
            "3/18/1998",
            "3/19/1998",
            "3/20/1998",
            "3/23/1998",
            "3/24/1998",
            "3/25/1998",
            "3/26/1998",
            "3/27/1998",
            "3/30/1998",
            "3/31/1998",
            "4/1/1998",
            "4/2/1998",
            "4/3/1998",
            "4/6/1998",
            "4/7/1998",
            "4/8/1998",
            "4/9/1998",
            "4/10/1998",
            "4/13/1998",
            "4/14/1998",
            "4/15/1998",
            "4/16/1998",
            "4/17/1998",
            "4/20/1998",
            "4/21/1998",
            "4/22/1998",
            "4/23/1998",
            "4/24/1998",
            "4/27/1998",
            "4/28/1998",
            "4/29/1998",
            "4/30/1998",
            "5/1/1998",
            "5/4/1998",
            "5/5/1998",
            "5/6/1998",
            "5/7/1998",
            "5/8/1998",
            "5/11/1998",
            "5/12/1998",
            "5/13/1998",
            "5/14/1998",
            "5/15/1998",
            "5/18/1998",
            "5/19/1998",
            "5/20/1998",
            "5/21/1998",
            "5/22/1998",
            "5/25/1998",
            "5/26/1998",
            "5/27/1998",
            "5/28/1998",
            "5/29/1998",
            "6/1/1998",
            "6/2/1998",
            "6/3/1998",
            "6/4/1998",
            "6/5/1998",
            "6/8/1998",
            "6/9/1998",
            "6/10/1998",
            "6/11/1998",
            "6/12/1998",
            "6/15/1998",
            "6/16/1998",
            "6/17/1998",
            "6/18/1998",
            "6/19/1998",
            "6/22/1998",
            "6/23/1998",
            "6/24/1998",
            "6/25/1998",
            "6/26/1998",
            "6/29/1998",
            "6/30/1998",
            "7/1/1998",
            "7/2/1998",
            "7/3/1998",
            "7/6/1998",
            "7/7/1998",
            "7/8/1998",
            "7/9/1998",
            "7/10/1998",
            "7/13/1998",
            "7/14/1998",
            "7/15/1998",
            "7/16/1998",
            "7/17/1998",
            "7/20/1998",
            "7/21/1998",
            "7/22/1998",
            "7/23/1998",
            "7/24/1998",
            "7/27/1998",
            "7/28/1998",
            "7/29/1998",
            "7/30/1998",
            "7/31/1998",
            "8/3/1998",
            "8/4/1998",
            "8/5/1998",
            "8/6/1998",
            "8/7/1998",
            "8/10/1998",
            "8/11/1998",
            "8/12/1998",
            "8/13/1998",
            "8/14/1998",
            "8/17/1998",
            "8/18/1998",
            "8/19/1998",
            "8/20/1998",
            "8/21/1998",
            "8/24/1998",
            "8/25/1998",
            "8/26/1998",
            "8/27/1998",
            "8/28/1998",
            "8/31/1998",
            "9/1/1998",
            "9/2/1998",
            "9/3/1998",
            "9/4/1998",
            "9/7/1998",
            "9/8/1998",
            "9/9/1998",
            "9/10/1998",
            "9/11/1998",
            "9/14/1998",
            "9/15/1998",
            "9/16/1998",
            "9/17/1998",
            "9/18/1998",
            "9/21/1998",
            "9/22/1998",
            "9/23/1998",
            "9/24/1998",
            "9/25/1998",
            "9/28/1998",
            "9/29/1998",
            "9/30/1998",
            "10/1/1998",
            "10/2/1998",
            "10/5/1998",
            "10/6/1998",
            "10/7/1998",
            "10/8/1998",
            "10/9/1998",
            "10/12/1998",
            "10/13/1998",
            "10/14/1998",
            "10/15/1998",
            "10/16/1998",
            "10/19/1998",
            "10/20/1998",
            "10/21/1998",
            "10/22/1998",
            "10/23/1998",
            "10/26/1998",
            "10/27/1998",
            "10/28/1998",
            "10/29/1998",
            "10/30/1998",
            "11/2/1998",
            "11/3/1998",
            "11/4/1998",
            "11/5/1998",
            "11/6/1998",
            "11/9/1998",
            "11/10/1998",
            "11/11/1998",
            "11/12/1998",
            "11/13/1998",
            "11/16/1998",
            "11/17/1998",
            "11/18/1998",
            "11/19/1998",
            "11/20/1998",
            "11/23/1998",
            "11/24/1998",
            "11/25/1998",
            "11/26/1998",
            "11/27/1998",
            "11/30/1998",
            "12/1/1998",
            "12/2/1998",
            "12/3/1998",
            "12/4/1998",
            "12/7/1998",
            "12/8/1998",
            "12/9/1998",
            "12/10/1998",
            "12/11/1998",
            "12/14/1998",
            "12/15/1998",
            "12/16/1998",
            "12/17/1998",
            "12/18/1998",
            "12/21/1998",
            "12/22/1998",
            "12/23/1998",
            "12/24/1998",
            "12/25/1998",
            "12/28/1998",
            "12/29/1998",
            "12/30/1998",
            "12/31/1998",
            "1/1/1999",
            "1/4/1999",
            "1/5/1999",
            "1/6/1999",
            "1/7/1999",
            "1/8/1999",
            "1/11/1999",
            "1/12/1999",
            "1/13/1999",
            "1/14/1999",
            "1/15/1999",
            "1/18/1999",
            "1/19/1999",
            "1/20/1999",
            "1/21/1999",
            "1/22/1999",
            "1/25/1999",
            "1/26/1999",
            "1/27/1999",
            "1/28/1999",
            "1/29/1999",
            "2/1/1999",
            "2/2/1999",
            "2/3/1999",
            "2/4/1999",
            "2/5/1999",
            "2/8/1999",
            "2/9/1999",
            "2/10/1999",
            "2/11/1999",
            "2/12/1999",
            "2/15/1999",
            "2/16/1999",
            "2/17/1999",
            "2/18/1999",
            "2/19/1999",
            "2/22/1999",
            "2/23/1999",
            "2/24/1999",
            "2/25/1999",
            "2/26/1999",
            "3/1/1999",
            "3/2/1999",
            "3/3/1999",
            "3/4/1999",
            "3/5/1999",
            "3/8/1999",
            "3/9/1999",
            "3/10/1999",
            "3/11/1999",
            "3/12/1999",
            "3/15/1999",
            "3/16/1999",
            "3/17/1999",
            "3/18/1999",
            "3/19/1999",
            "3/22/1999",
            "3/23/1999",
            "3/24/1999",
            "3/25/1999",
            "3/26/1999",
            "3/29/1999",
            "3/30/1999",
            "3/31/1999",
            "4/1/1999",
            "4/2/1999",
            "4/5/1999",
            "4/6/1999",
            "4/7/1999",
            "4/8/1999",
            "4/9/1999",
            "4/12/1999",
            "4/13/1999",
            "4/14/1999",
            "4/15/1999",
            "4/16/1999",
            "4/19/1999",
            "4/20/1999",
            "4/21/1999",
            "4/22/1999",
            "4/23/1999",
            "4/26/1999",
            "4/27/1999",
            "4/28/1999",
            "4/29/1999",
            "4/30/1999",
            "5/3/1999",
            "5/4/1999",
            "5/5/1999",
            "5/6/1999",
            "5/7/1999",
            "5/10/1999",
            "5/11/1999",
            "5/12/1999",
            "5/13/1999",
            "5/14/1999",
            "5/17/1999",
            "5/18/1999",
            "5/19/1999",
            "5/20/1999",
            "5/21/1999",
            "5/24/1999",
            "5/25/1999",
            "5/26/1999",
            "5/27/1999",
            "5/28/1999",
            "5/31/1999",
            "6/1/1999",
            "6/2/1999",
            "6/3/1999",
            "6/4/1999",
            "6/7/1999",
            "6/8/1999",
            "6/9/1999",
            "6/10/1999",
            "6/11/1999",
            "6/14/1999",
            "6/15/1999",
            "6/16/1999",
            "6/17/1999",
            "6/18/1999",
            "6/21/1999",
            "6/22/1999",
            "6/23/1999",
            "6/24/1999",
            "6/25/1999",
            "6/28/1999",
            "6/29/1999",
            "6/30/1999",
            "7/1/1999",
            "7/2/1999",
            "7/5/1999",
            "7/6/1999",
            "7/7/1999",
            "7/8/1999",
            "7/9/1999",
            "7/12/1999",
            "7/13/1999",
            "7/14/1999",
            "7/15/1999",
            "7/16/1999",
            "7/19/1999",
            "7/20/1999",
            "7/21/1999",
            "7/22/1999",
            "7/23/1999",
            "7/26/1999",
            "7/27/1999",
            "7/28/1999",
            "7/29/1999",
            "7/30/1999",
            "8/2/1999",
            "8/3/1999",
            "8/4/1999",
            "8/5/1999",
            "8/6/1999",
            "8/9/1999",
            "8/10/1999",
            "8/11/1999",
            "8/12/1999",
            "8/13/1999",
            "8/16/1999",
            "8/17/1999",
            "8/18/1999",
            "8/19/1999",
            "8/20/1999",
            "8/23/1999",
            "8/24/1999",
            "8/25/1999",
            "8/26/1999",
            "8/27/1999",
            "8/30/1999",
            "8/31/1999",
            "9/1/1999",
            "9/2/1999",
            "9/3/1999",
            "9/6/1999",
            "9/7/1999",
            "9/8/1999",
            "9/9/1999",
            "9/10/1999",
            "9/13/1999",
            "9/14/1999",
            "9/15/1999",
            "9/16/1999",
            "9/17/1999",
            "9/20/1999",
            "9/21/1999",
            "9/22/1999",
            "9/23/1999",
            "9/24/1999",
            "9/27/1999",
            "9/28/1999",
            "9/29/1999",
            "9/30/1999",
            "10/1/1999",
            "10/4/1999",
            "10/5/1999",
            "10/6/1999",
            "10/7/1999",
            "10/8/1999",
            "10/11/1999",
            "10/12/1999",
            "10/13/1999",
            "10/14/1999",
            "10/15/1999",
            "10/18/1999",
            "10/19/1999",
            "10/20/1999",
            "10/21/1999",
            "10/22/1999",
            "10/25/1999",
            "10/26/1999",
            "10/27/1999",
            "10/28/1999",
            "10/29/1999",
            "11/1/1999",
            "11/2/1999",
            "11/3/1999",
            "11/4/1999",
            "11/5/1999",
            "11/8/1999",
            "11/9/1999",
            "11/10/1999",
            "11/11/1999",
            "11/12/1999",
            "11/15/1999",
            "11/16/1999",
            "11/17/1999",
            "11/18/1999",
            "11/19/1999",
            "11/22/1999",
            "11/23/1999",
            "11/24/1999",
            "11/25/1999",
            "11/26/1999",
            "11/29/1999",
            "11/30/1999",
            "12/1/1999",
            "12/2/1999",
            "12/3/1999",
            "12/6/1999",
            "12/7/1999",
            "12/8/1999",
            "12/9/1999",
            "12/10/1999",
            "12/13/1999",
            "12/14/1999",
            "12/15/1999",
            "12/16/1999",
            "12/17/1999",
            "12/20/1999",
            "12/21/1999",
            "12/22/1999",
            "12/23/1999",
            "12/24/1999",
            "12/27/1999",
            "12/28/1999",
            "12/29/1999",
            "12/30/1999",
            "12/31/1999",
            "1/3/2000",
            "1/4/2000",
            "1/5/2000",
            "1/6/2000",
            "1/7/2000",
            "1/10/2000",
            "1/11/2000",
            "1/12/2000",
            "1/13/2000",
            "1/14/2000",
            "1/17/2000",
            "1/18/2000",
            "1/19/2000",
            "1/20/2000",
            "1/21/2000",
            "1/24/2000",
            "1/25/2000",
            "1/26/2000",
            "1/27/2000",
            "1/28/2000",
            "1/31/2000",
            "2/1/2000",
            "2/2/2000",
            "2/3/2000",
            "2/4/2000",
            "2/7/2000",
            "2/8/2000",
            "2/9/2000",
            "2/10/2000",
            "2/11/2000",
            "2/14/2000",
            "2/15/2000",
            "2/16/2000",
            "2/17/2000",
            "2/18/2000",
            "2/21/2000",
            "2/22/2000",
            "2/23/2000",
            "2/24/2000",
            "2/25/2000",
            "2/28/2000",
            "2/29/2000",
            "3/1/2000",
            "3/2/2000",
            "3/3/2000",
            "3/6/2000",
            "3/7/2000",
            "3/8/2000",
            "3/9/2000",
            "3/10/2000",
            "3/13/2000",
            "3/14/2000",
            "3/15/2000",
            "3/16/2000",
            "3/17/2000",
            "3/20/2000",
            "3/21/2000",
            "3/22/2000",
            "3/23/2000",
            "3/24/2000",
            "3/27/2000",
            "3/28/2000",
            "3/29/2000",
            "3/30/2000",
            "3/31/2000",
            "4/3/2000",
            "4/4/2000",
            "4/5/2000",
            "4/6/2000",
            "4/7/2000",
            "4/10/2000",
            "4/11/2000",
            "4/12/2000",
            "4/13/2000",
            "4/14/2000",
            "4/17/2000",
            "4/18/2000",
            "4/19/2000",
            "4/20/2000",
            "4/21/2000",
            "4/24/2000",
            "4/25/2000",
            "4/26/2000",
            "4/27/2000",
            "4/28/2000",
            "5/1/2000",
            "5/2/2000",
            "5/3/2000",
            "5/4/2000",
            "5/5/2000",
            "5/8/2000",
            "5/9/2000",
            "5/10/2000",
            "5/11/2000",
            "5/12/2000",
            "5/15/2000",
            "5/16/2000",
            "5/17/2000",
            "5/18/2000",
            "5/19/2000",
            "5/22/2000",
            "5/23/2000",
            "5/24/2000",
            "5/25/2000",
            "5/26/2000",
            "5/29/2000",
            "5/30/2000",
            "5/31/2000",
            "6/1/2000",
            "6/2/2000",
            "6/5/2000",
            "6/6/2000",
            "6/7/2000",
            "6/8/2000",
            "6/9/2000",
            "6/12/2000",
            "6/13/2000",
            "6/14/2000",
            "6/15/2000",
            "6/16/2000",
            "6/19/2000",
            "6/20/2000",
            "6/21/2000",
            "6/22/2000",
            "6/23/2000",
            "6/26/2000",
            "6/27/2000",
            "6/28/2000",
            "6/29/2000",
            "6/30/2000",
            "7/3/2000",
            "7/4/2000",
            "7/5/2000",
            "7/6/2000",
            "7/7/2000",
            "7/10/2000",
            "7/11/2000",
            "7/12/2000",
            "7/13/2000",
            "7/14/2000",
            "7/17/2000",
            "7/18/2000",
            "7/19/2000",
            "7/20/2000",
            "7/21/2000",
            "7/24/2000",
            "7/25/2000",
            "7/26/2000",
            "7/27/2000",
            "7/28/2000",
            "7/31/2000",
            "8/1/2000",
            "8/2/2000",
            "8/3/2000",
            "8/4/2000",
            "8/7/2000",
            "8/8/2000",
            "8/9/2000",
            "8/10/2000",
            "8/11/2000",
            "8/14/2000",
            "8/15/2000",
            "8/16/2000",
            "8/17/2000",
            "8/18/2000",
            "8/21/2000",
            "8/22/2000",
            "8/23/2000",
            "8/24/2000",
            "8/25/2000",
            "8/28/2000",
            "8/29/2000",
            "8/30/2000",
            "8/31/2000",
            "9/1/2000",
            "9/4/2000",
            "9/5/2000",
            "9/6/2000",
            "9/7/2000",
            "9/8/2000",
            "9/11/2000",
            "9/12/2000",
            "9/13/2000",
            "9/14/2000",
            "9/15/2000",
            "9/18/2000",
            "9/19/2000",
            "9/20/2000",
            "9/21/2000",
            "9/22/2000",
            "9/25/2000",
            "9/26/2000",
            "9/27/2000",
            "9/28/2000",
            "9/29/2000",
            "10/2/2000",
            "10/3/2000",
            "10/4/2000",
            "10/5/2000",
            "10/6/2000",
            "10/9/2000",
            "10/10/2000",
            "10/11/2000",
            "10/12/2000",
            "10/13/2000",
            "10/16/2000",
            "10/17/2000",
            "10/18/2000",
            "10/19/2000",
            "10/20/2000",
            "10/23/2000",
            "10/24/2000",
            "10/25/2000",
            "10/26/2000",
            "10/27/2000",
            "10/30/2000",
            "10/31/2000",
            "11/1/2000",
            "11/2/2000",
            "11/3/2000",
            "11/6/2000",
            "11/7/2000",
            "11/8/2000",
            "11/9/2000",
            "11/10/2000",
            "11/13/2000",
            "11/14/2000",
            "11/15/2000",
            "11/16/2000",
            "11/17/2000",
            "11/20/2000",
            "11/21/2000",
            "11/22/2000",
            "11/23/2000",
            "11/24/2000",
            "11/27/2000",
            "11/28/2000",
            "11/29/2000",
            "11/30/2000",
            "12/1/2000",
            "12/4/2000",
            "12/5/2000",
            "12/6/2000",
            "12/7/2000",
            "12/8/2000",
            "12/11/2000",
            "12/12/2000",
            "12/13/2000",
            "12/14/2000",
            "12/15/2000",
            "12/18/2000",
            "12/19/2000",
            "12/20/2000",
            "12/21/2000",
            "12/22/2000",
            "12/25/2000",
            "12/26/2000",
            "12/27/2000",
            "12/28/2000",
            "12/29/2000",
            "1/1/2001",
            "1/2/2001",
            "1/3/2001",
            "1/4/2001",
            "1/5/2001",
            "1/8/2001",
            "1/9/2001",
            "1/10/2001",
            "1/11/2001",
            "1/12/2001",
            "1/15/2001",
            "1/16/2001",
            "1/17/2001",
            "1/18/2001",
            "1/19/2001",
            "1/22/2001",
            "1/23/2001",
            "1/24/2001",
            "1/25/2001",
            "1/26/2001",
            "1/29/2001",
            "1/30/2001",
            "1/31/2001",
            "2/1/2001",
            "2/2/2001",
            "2/5/2001",
            "2/6/2001",
            "2/7/2001",
            "2/8/2001",
            "2/9/2001",
            "2/12/2001",
            "2/13/2001",
            "2/14/2001",
            "2/15/2001",
            "2/16/2001",
            "2/19/2001",
            "2/20/2001",
            "2/21/2001",
            "2/22/2001",
            "2/23/2001",
            "2/26/2001",
            "2/27/2001",
            "2/28/2001",
            "3/1/2001",
            "3/2/2001",
            "3/5/2001",
            "3/6/2001",
            "3/7/2001",
            "3/8/2001",
            "3/9/2001",
            "3/12/2001",
            "3/13/2001",
            "3/14/2001",
            "3/15/2001",
            "3/16/2001",
            "3/19/2001",
            "3/20/2001",
            "3/21/2001",
            "3/22/2001",
            "3/23/2001",
            "3/26/2001",
            "3/27/2001",
            "3/28/2001",
            "3/29/2001",
            "3/30/2001",
            "4/2/2001",
            "4/3/2001",
            "4/4/2001",
            "4/5/2001",
            "4/6/2001",
            "4/9/2001",
            "4/10/2001",
            "4/11/2001",
            "4/12/2001",
            "4/13/2001",
            "4/16/2001",
            "4/17/2001",
            "4/18/2001",
            "4/19/2001",
            "4/20/2001",
            "4/23/2001",
            "4/24/2001",
            "4/25/2001",
            "4/26/2001",
            "4/27/2001",
            "4/30/2001",
            "5/1/2001",
            "5/2/2001",
            "5/3/2001",
            "5/4/2001",
            "5/7/2001",
            "5/8/2001",
            "5/9/2001",
            "5/10/2001",
            "5/11/2001",
            "5/14/2001",
            "5/15/2001",
            "5/16/2001",
            "5/17/2001",
            "5/18/2001",
            "5/21/2001",
            "5/22/2001",
            "5/23/2001",
            "5/24/2001",
            "5/25/2001",
            "5/28/2001",
            "5/29/2001",
            "5/30/2001",
            "5/31/2001",
            "6/1/2001",
            "6/4/2001",
            "6/5/2001",
            "6/6/2001",
            "6/7/2001",
            "6/8/2001",
            "6/11/2001",
            "6/12/2001",
            "6/13/2001",
            "6/14/2001",
            "6/15/2001",
            "6/18/2001",
            "6/19/2001",
            "6/20/2001",
            "6/21/2001",
            "6/22/2001",
            "6/25/2001",
            "6/26/2001",
            "6/27/2001",
            "6/28/2001",
            "6/29/2001",
            "7/2/2001",
            "7/3/2001",
            "7/4/2001",
            "7/5/2001",
            "7/6/2001",
            "7/9/2001",
            "7/10/2001",
            "7/11/2001",
            "7/12/2001",
            "7/13/2001",
            "7/16/2001",
            "7/17/2001",
            "7/18/2001",
            "7/19/2001",
            "7/20/2001",
            "7/23/2001",
            "7/24/2001",
            "7/25/2001",
            "7/26/2001",
            "7/27/2001",
            "7/30/2001",
            "7/31/2001",
            "8/1/2001",
            "8/2/2001",
            "8/3/2001",
            "8/6/2001",
            "8/7/2001",
            "8/8/2001",
            "8/9/2001",
            "8/10/2001",
            "8/13/2001",
            "8/14/2001",
            "8/15/2001",
            "8/16/2001",
            "8/17/2001",
            "8/20/2001",
            "8/21/2001",
            "8/22/2001",
            "8/23/2001",
            "8/24/2001",
            "8/27/2001",
            "8/28/2001",
            "8/29/2001",
            "8/30/2001",
            "8/31/2001",
            "9/3/2001",
            "9/4/2001",
            "9/5/2001",
            "9/6/2001",
            "9/7/2001",
            "9/10/2001",
            "9/11/2001",
            "9/12/2001",
            "9/13/2001",
            "9/14/2001",
            "9/17/2001",
            "9/18/2001",
            "9/19/2001",
            "9/20/2001",
            "9/21/2001",
            "9/24/2001",
            "9/25/2001",
            "9/26/2001",
            "9/27/2001",
            "9/28/2001",
            "10/1/2001",
            "10/2/2001",
            "10/3/2001",
            "10/4/2001",
            "10/5/2001",
            "10/8/2001",
            "10/9/2001",
            "10/10/2001",
            "10/11/2001",
            "10/12/2001",
            "10/15/2001",
            "10/16/2001",
            "10/17/2001",
            "10/18/2001",
            "10/19/2001",
            "10/22/2001",
            "10/23/2001",
            "10/24/2001",
            "10/25/2001",
            "10/26/2001",
            "10/29/2001",
            "10/30/2001",
            "10/31/2001",
            "11/1/2001",
            "11/2/2001",
            "11/5/2001",
            "11/6/2001",
            "11/7/2001",
            "11/8/2001",
            "11/9/2001",
            "11/12/2001",
            "11/13/2001",
            "11/14/2001",
            "11/15/2001",
            "11/16/2001",
            "11/19/2001",
            "11/20/2001",
            "11/21/2001",
            "11/22/2001",
            "11/23/2001",
            "11/26/2001",
            "11/27/2001",
            "11/28/2001",
            "11/29/2001",
            "11/30/2001",
            "12/3/2001",
            "12/4/2001",
            "12/5/2001",
            "12/6/2001",
            "12/7/2001",
            "12/10/2001",
            "12/11/2001",
            "12/12/2001",
            "12/13/2001",
            "12/14/2001",
            "12/17/2001",
            "12/18/2001",
            "12/19/2001",
            "12/20/2001",
            "12/21/2001",
            "12/24/2001",
            "12/25/2001",
            "12/26/2001",
            "12/27/2001",
            "12/28/2001",
            "12/31/2001",
            "1/1/2002",
            "1/2/2002",
            "1/3/2002",
            "1/4/2002",
            "1/7/2002",
            "1/8/2002",
            "1/9/2002",
            "1/10/2002",
            "1/11/2002",
            "1/14/2002",
            "1/15/2002",
            "1/16/2002",
            "1/17/2002",
            "1/18/2002",
            "1/21/2002",
            "1/22/2002",
            "1/23/2002",
            "1/24/2002",
            "1/25/2002",
            "1/28/2002",
            "1/29/2002",
            "1/30/2002",
            "1/31/2002",
            "2/1/2002",
            "2/4/2002",
            "2/5/2002",
            "2/6/2002",
            "2/7/2002",
            "2/8/2002",
            "2/11/2002",
            "2/12/2002",
            "2/13/2002",
            "2/14/2002",
            "2/15/2002",
            "2/18/2002",
            "2/19/2002",
            "2/20/2002",
            "2/21/2002",
            "2/22/2002",
            "2/25/2002",
            "2/26/2002",
            "2/27/2002",
            "2/28/2002",
            "3/1/2002",
            "3/4/2002",
            "3/5/2002",
            "3/6/2002",
            "3/7/2002",
            "3/8/2002",
            "3/11/2002",
            "3/12/2002",
            "3/13/2002",
            "3/14/2002",
            "3/15/2002",
            "3/18/2002",
            "3/19/2002",
            "3/20/2002",
            "3/21/2002",
            "3/22/2002",
            "3/25/2002",
            "3/26/2002",
            "3/27/2002",
            "3/28/2002",
            "3/29/2002",
            "4/1/2002",
            "4/2/2002",
            "4/3/2002",
            "4/4/2002",
            "4/5/2002",
            "4/8/2002",
            "4/9/2002",
            "4/10/2002",
            "4/11/2002",
            "4/12/2002",
            "4/15/2002",
            "4/16/2002",
            "4/17/2002",
            "4/18/2002",
            "4/19/2002",
            "4/22/2002",
            "4/23/2002",
            "4/24/2002",
            "4/25/2002",
            "4/26/2002",
            "4/29/2002",
            "4/30/2002",
            "5/1/2002",
            "5/2/2002",
            "5/3/2002",
            "5/6/2002",
            "5/7/2002",
            "5/8/2002",
            "5/9/2002",
            "5/10/2002",
            "5/13/2002",
            "5/14/2002",
            "5/15/2002",
            "5/16/2002",
            "5/17/2002",
            "5/20/2002",
            "5/21/2002",
            "5/22/2002",
            "5/23/2002",
            "5/24/2002",
            "5/27/2002",
            "5/28/2002",
            "5/29/2002",
            "5/30/2002",
            "5/31/2002",
            "6/3/2002",
            "6/4/2002",
            "6/5/2002",
            "6/6/2002",
            "6/7/2002",
            "6/10/2002",
            "6/11/2002",
            "6/12/2002",
            "6/13/2002",
            "6/14/2002",
            "6/17/2002",
            "6/18/2002",
            "6/19/2002",
            "6/20/2002",
            "6/21/2002",
            "6/24/2002",
            "6/25/2002",
            "6/26/2002",
            "6/27/2002",
            "6/28/2002",
            "7/1/2002",
            "7/2/2002",
            "7/3/2002",
            "7/4/2002",
            "7/5/2002",
            "7/8/2002",
            "7/9/2002",
            "7/10/2002",
            "7/11/2002",
            "7/12/2002",
            "7/15/2002",
            "7/16/2002",
            "7/17/2002",
            "7/18/2002",
            "7/19/2002",
            "7/22/2002",
            "7/23/2002",
            "7/24/2002",
            "7/25/2002",
            "7/26/2002",
            "7/29/2002",
            "7/30/2002",
            "7/31/2002",
            "8/1/2002",
            "8/2/2002",
            "8/5/2002",
            "8/6/2002",
            "8/7/2002",
            "8/8/2002",
            "8/9/2002",
            "8/12/2002",
            "8/13/2002",
            "8/14/2002",
            "8/15/2002",
            "8/16/2002",
            "8/19/2002",
            "8/20/2002",
            "8/21/2002",
            "8/22/2002",
            "8/23/2002",
            "8/26/2002",
            "8/27/2002",
            "8/28/2002",
            "8/29/2002",
            "8/30/2002",
            "9/2/2002",
            "9/3/2002",
            "9/4/2002",
            "9/5/2002",
            "9/6/2002",
            "9/9/2002",
            "9/10/2002",
            "9/11/2002",
            "9/12/2002",
            "9/13/2002",
            "9/16/2002",
            "9/17/2002",
            "9/18/2002",
            "9/19/2002",
            "9/20/2002",
            "9/23/2002",
            "9/24/2002",
            "9/25/2002",
            "9/26/2002",
            "9/27/2002",
            "9/30/2002",
            "10/1/2002",
            "10/2/2002",
            "10/3/2002",
            "10/4/2002",
            "10/7/2002",
            "10/8/2002",
            "10/9/2002",
            "10/10/2002",
            "10/11/2002",
            "10/14/2002",
            "10/15/2002",
            "10/16/2002",
            "10/17/2002",
            "10/18/2002",
            "10/21/2002",
            "10/22/2002",
            "10/23/2002",
            "10/24/2002",
            "10/25/2002",
            "10/28/2002",
            "10/29/2002",
            "10/30/2002",
            "10/31/2002",
            "11/1/2002",
            "11/4/2002",
            "11/5/2002",
            "11/6/2002",
            "11/7/2002",
            "11/8/2002",
            "11/11/2002",
            "11/12/2002",
            "11/13/2002",
            "11/14/2002",
            "11/15/2002",
            "11/18/2002",
            "11/19/2002",
            "11/20/2002",
            "11/21/2002",
            "11/22/2002",
            "11/25/2002",
            "11/26/2002",
            "11/27/2002",
            "11/28/2002",
            "11/29/2002",
            "12/2/2002",
            "12/3/2002",
            "12/4/2002",
            "12/5/2002",
            "12/6/2002",
            "12/9/2002",
            "12/10/2002",
            "12/11/2002",
            "12/12/2002",
            "12/13/2002",
            "12/16/2002",
            "12/17/2002",
            "12/18/2002",
            "12/19/2002",
            "12/20/2002",
            "12/23/2002",
            "12/24/2002",
            "12/25/2002",
            "12/26/2002",
            "12/27/2002",
            "12/30/2002",
            "12/31/2002",
            "1/1/2003",
            "1/2/2003",
            "1/3/2003",
            "1/6/2003",
            "1/7/2003",
            "1/8/2003",
            "1/9/2003",
            "1/10/2003",
            "1/13/2003",
            "1/14/2003",
            "1/15/2003",
            "1/16/2003",
            "1/17/2003",
            "1/20/2003",
            "1/21/2003",
            "1/22/2003",
            "1/23/2003",
            "1/24/2003",
            "1/27/2003",
            "1/28/2003",
            "1/29/2003",
            "1/30/2003",
            "1/31/2003",
            "2/3/2003",
            "2/4/2003",
            "2/5/2003",
            "2/6/2003",
            "2/7/2003",
            "2/10/2003",
            "2/11/2003",
            "2/12/2003",
            "2/13/2003",
            "2/14/2003",
            "2/17/2003",
            "2/18/2003",
            "2/19/2003",
            "2/20/2003",
            "2/21/2003",
            "2/24/2003",
            "2/25/2003",
            "2/26/2003",
            "2/27/2003",
            "2/28/2003",
            "3/3/2003",
            "3/4/2003",
            "3/5/2003",
            "3/6/2003",
            "3/7/2003",
            "3/10/2003",
            "3/11/2003",
            "3/12/2003",
            "3/13/2003",
            "3/14/2003",
            "3/17/2003",
            "3/18/2003",
            "3/19/2003",
            "3/20/2003",
            "3/21/2003",
            "3/24/2003",
            "3/25/2003",
            "3/26/2003",
            "3/27/2003",
            "3/28/2003",
            "3/31/2003",
            "4/1/2003",
            "4/2/2003",
            "4/3/2003",
            "4/4/2003",
            "4/7/2003",
            "4/8/2003",
            "4/9/2003",
            "4/10/2003",
            "4/11/2003",
            "4/14/2003",
            "4/15/2003",
            "4/16/2003",
            "4/17/2003",
            "4/18/2003",
            "4/21/2003",
            "4/22/2003",
            "4/23/2003",
            "4/24/2003",
            "4/25/2003",
            "4/28/2003",
            "4/29/2003",
            "4/30/2003",
            "5/1/2003",
            "5/2/2003",
            "5/5/2003",
            "5/6/2003",
            "5/7/2003",
            "5/8/2003",
            "5/9/2003",
            "5/12/2003",
            "5/13/2003",
            "5/14/2003",
            "5/15/2003",
            "5/16/2003",
            "5/19/2003",
            "5/20/2003",
            "5/21/2003",
            "5/22/2003",
            "5/23/2003",
            "5/26/2003",
            "5/27/2003",
            "5/28/2003",
            "5/29/2003",
            "5/30/2003",
            "6/2/2003",
            "6/3/2003",
            "6/4/2003",
            "6/5/2003",
            "6/6/2003",
            "6/9/2003",
            "6/10/2003",
            "6/11/2003",
            "6/12/2003",
            "6/13/2003",
            "6/16/2003",
            "6/17/2003",
            "6/18/2003",
            "6/19/2003",
            "6/20/2003",
            "6/23/2003",
            "6/24/2003",
            "6/25/2003",
            "6/26/2003",
            "6/27/2003",
            "6/30/2003",
            "7/1/2003",
            "7/2/2003",
            "7/3/2003",
            "7/4/2003",
            "7/7/2003",
            "7/8/2003",
            "7/9/2003",
            "7/10/2003",
            "7/11/2003",
            "7/14/2003",
            "7/15/2003",
            "7/16/2003",
            "7/17/2003",
            "7/18/2003",
            "7/21/2003",
            "7/22/2003",
            "7/23/2003",
            "7/24/2003",
            "7/25/2003",
            "7/28/2003",
            "7/29/2003",
            "7/30/2003",
            "7/31/2003",
            "8/1/2003",
            "8/4/2003",
            "8/5/2003",
            "8/6/2003",
            "8/7/2003",
            "8/8/2003",
            "8/11/2003",
            "8/12/2003",
            "8/13/2003",
            "8/14/2003",
            "8/15/2003",
            "8/18/2003",
            "8/19/2003",
            "8/20/2003",
            "8/21/2003",
            "8/22/2003",
            "8/25/2003",
            "8/26/2003",
            "8/27/2003",
            "8/28/2003",
            "8/29/2003",
            "9/1/2003",
            "9/2/2003",
            "9/3/2003",
            "9/4/2003",
            "9/5/2003",
            "9/8/2003",
            "9/9/2003",
            "9/10/2003",
            "9/11/2003",
            "9/12/2003",
            "9/15/2003",
            "9/16/2003",
            "9/17/2003",
            "9/18/2003",
            "9/19/2003",
            "9/22/2003",
            "9/23/2003",
            "9/24/2003",
            "9/25/2003",
            "9/26/2003",
            "9/29/2003",
            "9/30/2003",
            "10/1/2003",
            "10/2/2003",
            "10/3/2003",
            "10/6/2003",
            "10/7/2003",
            "10/8/2003",
            "10/9/2003",
            "10/10/2003",
            "10/13/2003",
            "10/14/2003",
            "10/15/2003",
            "10/16/2003",
            "10/17/2003",
            "10/20/2003",
            "10/21/2003",
            "10/22/2003",
            "10/23/2003",
            "10/24/2003",
            "10/27/2003",
            "10/28/2003",
            "10/29/2003",
            "10/30/2003",
            "10/31/2003",
            "11/3/2003",
            "11/4/2003",
            "11/5/2003",
            "11/6/2003",
            "11/7/2003",
            "11/10/2003",
            "11/11/2003",
            "11/12/2003",
            "11/13/2003",
            "11/14/2003",
            "11/17/2003",
            "11/18/2003",
            "11/19/2003",
            "11/20/2003",
            "11/21/2003",
            "11/24/2003",
            "11/25/2003",
            "11/26/2003",
            "11/27/2003",
            "11/28/2003",
            "12/1/2003",
            "12/2/2003",
            "12/3/2003",
            "12/4/2003",
            "12/5/2003",
            "12/8/2003",
            "12/9/2003",
            "12/10/2003",
            "12/11/2003",
            "12/12/2003",
            "12/15/2003",
            "12/16/2003",
            "12/17/2003",
            "12/18/2003",
            "12/19/2003",
            "12/22/2003",
            "12/23/2003",
            "12/24/2003",
            "12/25/2003",
            "12/26/2003",
            "12/29/2003",
            "12/30/2003",
            "12/31/2003",
            "1/1/2004",
            "1/2/2004",
            "1/5/2004",
            "1/6/2004",
            "1/7/2004",
            "1/8/2004",
            "1/9/2004",
            "1/12/2004",
            "1/13/2004",
            "1/14/2004",
            "1/15/2004",
            "1/16/2004",
            "1/19/2004",
            "1/20/2004",
            "1/21/2004",
            "1/22/2004",
            "1/23/2004",
            "1/26/2004",
            "1/27/2004",
            "1/28/2004",
            "1/29/2004",
            "1/30/2004",
            "2/2/2004",
            "2/3/2004",
            "2/4/2004",
            "2/5/2004",
            "2/6/2004",
            "2/9/2004",
            "2/10/2004",
            "2/11/2004",
            "2/12/2004",
            "2/13/2004",
            "2/16/2004",
            "2/17/2004",
            "2/18/2004",
            "2/19/2004",
            "2/20/2004",
            "2/23/2004",
            "2/24/2004",
            "2/25/2004",
            "2/26/2004",
            "2/27/2004",
            "3/1/2004",
            "3/2/2004",
            "3/3/2004",
            "3/4/2004",
            "3/5/2004",
            "3/8/2004",
            "3/9/2004",
            "3/10/2004",
            "3/11/2004",
            "3/12/2004",
            "3/15/2004",
            "3/16/2004",
            "3/17/2004",
            "3/18/2004",
            "3/19/2004",
            "3/22/2004",
            "3/23/2004",
            "3/24/2004",
            "3/25/2004",
            "3/26/2004",
            "3/29/2004",
            "3/30/2004",
            "3/31/2004",
            "4/1/2004",
            "4/2/2004",
            "4/5/2004",
            "4/6/2004",
            "4/7/2004",
            "4/8/2004",
            "4/9/2004",
            "4/12/2004",
            "4/13/2004",
            "4/14/2004",
            "4/15/2004",
            "4/16/2004",
            "4/19/2004",
            "4/20/2004",
            "4/21/2004",
            "4/22/2004",
            "4/23/2004",
            "4/26/2004",
            "4/27/2004",
            "4/28/2004",
            "4/29/2004",
            "4/30/2004",
            "5/3/2004",
            "5/4/2004",
            "5/5/2004",
            "5/6/2004",
            "5/7/2004",
            "5/10/2004",
            "5/11/2004",
            "5/12/2004",
            "5/13/2004",
            "5/14/2004",
            "5/17/2004",
            "5/18/2004",
            "5/19/2004",
            "5/20/2004",
            "5/21/2004",
            "5/24/2004",
            "5/25/2004",
            "5/26/2004",
            "5/27/2004",
            "5/28/2004",
            "5/31/2004",
            "6/1/2004",
            "6/2/2004",
            "6/3/2004",
            "6/4/2004",
            "6/7/2004",
            "6/8/2004",
            "6/9/2004",
            "6/10/2004",
            "6/11/2004",
            "6/14/2004",
            "6/15/2004",
            "6/16/2004",
            "6/17/2004",
            "6/18/2004",
            "6/21/2004",
            "6/22/2004",
            "6/23/2004",
            "6/24/2004",
            "6/25/2004",
            "6/28/2004",
            "6/29/2004",
            "6/30/2004",
            "7/1/2004",
            "7/2/2004",
            "7/5/2004",
            "7/6/2004",
            "7/7/2004",
            "7/8/2004",
            "7/9/2004",
            "7/12/2004",
            "7/13/2004",
            "7/14/2004",
            "7/15/2004",
            "7/16/2004",
            "7/19/2004",
            "7/20/2004",
            "7/21/2004",
            "7/22/2004",
            "7/23/2004",
            "7/26/2004",
            "7/27/2004",
            "7/28/2004",
            "7/29/2004",
            "7/30/2004",
            "8/2/2004",
            "8/3/2004",
            "8/4/2004",
            "8/5/2004",
            "8/6/2004",
            "8/9/2004",
            "8/10/2004",
            "8/11/2004",
            "8/12/2004",
            "8/13/2004",
            "8/16/2004",
            "8/17/2004",
            "8/18/2004",
            "8/19/2004",
            "8/20/2004",
            "8/23/2004",
            "8/24/2004",
            "8/25/2004",
            "8/26/2004",
            "8/27/2004",
            "8/30/2004",
            "8/31/2004",
            "9/1/2004",
            "9/2/2004",
            "9/3/2004",
            "9/6/2004",
            "9/7/2004",
            "9/8/2004",
            "9/9/2004",
            "9/10/2004",
            "9/13/2004",
            "9/14/2004",
            "9/15/2004",
            "9/16/2004",
            "9/17/2004",
            "9/20/2004",
            "9/21/2004",
            "9/22/2004",
            "9/23/2004",
            "9/24/2004",
            "9/27/2004",
            "9/28/2004",
            "9/29/2004",
            "9/30/2004",
            "10/1/2004",
            "10/4/2004",
            "10/5/2004",
            "10/6/2004",
            "10/7/2004",
            "10/8/2004",
            "10/11/2004",
            "10/12/2004",
            "10/13/2004",
            "10/14/2004",
            "10/15/2004",
            "10/18/2004",
            "10/19/2004",
            "10/20/2004",
            "10/21/2004",
            "10/22/2004",
            "10/25/2004",
            "10/26/2004",
            "10/27/2004",
            "10/28/2004",
            "10/29/2004",
            "11/1/2004",
            "11/2/2004",
            "11/3/2004",
            "11/4/2004",
            "11/5/2004",
            "11/8/2004",
            "11/9/2004",
            "11/10/2004",
            "11/11/2004",
            "11/12/2004",
            "11/15/2004",
            "11/16/2004",
            "11/17/2004",
            "11/18/2004",
            "11/19/2004",
            "11/22/2004",
            "11/23/2004",
            "11/24/2004",
            "11/25/2004",
            "11/26/2004",
            "11/29/2004",
            "11/30/2004",
            "12/1/2004",
            "12/2/2004",
            "12/3/2004",
            "12/6/2004",
            "12/7/2004",
            "12/8/2004",
            "12/9/2004",
            "12/10/2004",
            "12/13/2004",
            "12/14/2004",
            "12/15/2004",
            "12/16/2004",
            "12/17/2004",
            "12/20/2004",
            "12/21/2004",
            "12/22/2004",
            "12/23/2004",
            "12/24/2004",
            "12/27/2004",
            "12/28/2004",
            "12/29/2004",
            "12/30/2004",
            "12/31/2004",
            "1/3/2005",
            "1/4/2005",
            "1/5/2005",
            "1/6/2005",
            "1/7/2005",
            "1/10/2005",
            "1/11/2005",
            "1/12/2005",
            "1/13/2005",
            "1/14/2005",
            "1/17/2005",
            "1/18/2005",
            "1/19/2005",
            "1/20/2005",
            "1/21/2005",
            "1/24/2005",
            "1/25/2005",
            "1/26/2005",
            "1/27/2005",
            "1/28/2005",
            "1/31/2005",
            "2/1/2005",
            "2/2/2005",
            "2/3/2005",
            "2/4/2005",
            "2/7/2005",
            "2/8/2005",
            "2/9/2005",
            "2/10/2005",
            "2/11/2005",
            "2/14/2005",
            "2/15/2005",
            "2/16/2005",
            "2/17/2005",
            "2/18/2005",
            "2/21/2005",
            "2/22/2005",
            "2/23/2005",
            "2/24/2005",
            "2/25/2005",
            "2/28/2005",
            "3/1/2005",
            "3/2/2005",
            "3/3/2005",
            "3/4/2005",
            "3/7/2005",
            "3/8/2005",
            "3/9/2005",
            "3/10/2005",
            "3/11/2005",
            "3/14/2005",
            "3/15/2005",
            "3/16/2005",
            "3/17/2005",
            "3/18/2005",
            "3/21/2005",
            "3/22/2005",
            "3/23/2005",
            "3/24/2005",
            "3/25/2005",
            "3/28/2005",
            "3/29/2005",
            "3/30/2005",
            "3/31/2005",
            "4/1/2005",
            "4/4/2005",
            "4/5/2005",
            "4/6/2005",
            "4/7/2005",
            "4/8/2005",
            "4/11/2005",
            "4/12/2005",
            "4/13/2005",
            "4/14/2005",
            "4/15/2005",
            "4/18/2005",
            "4/19/2005",
            "4/20/2005",
            "4/21/2005",
            "4/22/2005",
            "4/25/2005",
            "4/26/2005",
            "4/27/2005",
            "4/28/2005",
            "4/29/2005",
            "5/2/2005",
            "5/3/2005",
            "5/4/2005",
            "5/5/2005",
            "5/6/2005",
            "5/9/2005",
            "5/10/2005",
            "5/11/2005",
            "5/12/2005",
            "5/13/2005",
            "5/16/2005",
            "5/17/2005",
            "5/18/2005",
            "5/19/2005",
            "5/20/2005",
            "5/23/2005",
            "5/24/2005",
            "5/25/2005",
            "5/26/2005",
            "5/27/2005",
            "5/30/2005",
            "5/31/2005",
            "6/1/2005",
            "6/2/2005",
            "6/3/2005",
            "6/6/2005",
            "6/7/2005",
            "6/8/2005",
            "6/9/2005",
            "6/10/2005",
            "6/13/2005",
            "6/14/2005",
            "6/15/2005",
            "6/16/2005",
            "6/17/2005",
            "6/20/2005",
            "6/21/2005",
            "6/22/2005",
            "6/23/2005",
            "6/24/2005",
            "6/27/2005",
            "6/28/2005",
            "6/29/2005",
            "6/30/2005",
            "7/1/2005",
            "7/4/2005",
            "7/5/2005",
            "7/6/2005",
            "7/7/2005",
            "7/8/2005",
            "7/11/2005",
            "7/12/2005",
            "7/13/2005",
            "7/14/2005",
            "7/15/2005",
            "7/18/2005",
            "7/19/2005",
            "7/20/2005",
            "7/21/2005",
            "7/22/2005",
            "7/25/2005",
            "7/26/2005",
            "7/27/2005",
            "7/28/2005",
            "7/29/2005",
            "8/1/2005",
            "8/2/2005",
            "8/3/2005",
            "8/4/2005",
            "8/5/2005",
            "8/8/2005",
            "8/9/2005",
            "8/10/2005",
            "8/11/2005",
            "8/12/2005",
            "8/15/2005",
            "8/16/2005",
            "8/17/2005",
            "8/18/2005",
            "8/19/2005",
            "8/22/2005",
            "8/23/2005",
            "8/24/2005",
            "8/25/2005",
            "8/26/2005",
            "8/29/2005",
            "8/30/2005",
            "8/31/2005",
            "9/1/2005",
            "9/2/2005",
            "9/5/2005",
            "9/6/2005",
            "9/7/2005",
            "9/8/2005",
            "9/9/2005",
            "9/12/2005",
            "9/13/2005",
            "9/14/2005",
            "9/15/2005",
            "9/16/2005",
            "9/19/2005",
            "9/20/2005",
            "9/21/2005",
            "9/22/2005",
            "9/23/2005",
            "9/26/2005",
            "9/27/2005",
            "9/28/2005",
            "9/29/2005",
            "9/30/2005",
            "10/3/2005",
            "10/4/2005",
            "10/5/2005",
            "10/6/2005",
            "10/7/2005",
            "10/10/2005",
            "10/11/2005",
            "10/12/2005",
            "10/13/2005",
            "10/14/2005",
            "10/17/2005",
            "10/18/2005",
            "10/19/2005",
            "10/20/2005",
            "10/21/2005",
            "10/24/2005",
            "10/25/2005",
            "10/26/2005",
            "10/27/2005",
            "10/28/2005",
            "10/31/2005",
            "11/1/2005",
            "11/2/2005",
            "11/3/2005",
            "11/4/2005",
            "11/7/2005",
            "11/8/2005",
            "11/9/2005",
            "11/10/2005",
            "11/11/2005",
            "11/14/2005",
            "11/15/2005",
            "11/16/2005",
            "11/17/2005",
            "11/18/2005",
            "11/21/2005",
            "11/22/2005",
            "11/23/2005",
            "11/24/2005",
            "11/25/2005",
            "11/28/2005",
            "11/29/2005",
            "11/30/2005",
            "12/1/2005",
            "12/2/2005",
            "12/5/2005",
            "12/6/2005",
            "12/7/2005",
            "12/8/2005",
            "12/9/2005",
            "12/12/2005",
            "12/13/2005",
            "12/14/2005",
            "12/15/2005",
            "12/16/2005",
            "12/19/2005",
            "12/20/2005",
            "12/21/2005",
            "12/22/2005",
            "12/23/2005",
            "12/26/2005",
            "12/27/2005",
            "12/28/2005",
            "12/29/2005",
            "12/30/2005",
            "1/2/2006",
            "1/3/2006",
            "1/4/2006",
            "1/5/2006",
            "1/6/2006",
            "1/9/2006",
            "1/10/2006",
            "1/11/2006",
            "1/12/2006",
            "1/13/2006",
            "1/16/2006",
            "1/17/2006",
            "1/18/2006",
            "1/19/2006",
            "1/20/2006",
            "1/23/2006",
            "1/24/2006",
            "1/25/2006",
            "1/26/2006",
            "1/27/2006",
            "1/30/2006",
            "1/31/2006",
            "2/1/2006",
            "2/2/2006",
            "2/3/2006",
            "2/6/2006",
            "2/7/2006",
            "2/8/2006",
            "2/9/2006",
            "2/10/2006",
            "2/13/2006",
            "2/14/2006",
            "2/15/2006",
            "2/16/2006",
            "2/17/2006",
            "2/20/2006",
            "2/21/2006",
            "2/22/2006",
            "2/23/2006",
            "2/24/2006",
            "2/27/2006",
            "2/28/2006",
            "3/1/2006",
            "3/2/2006",
            "3/3/2006",
            "3/6/2006",
            "3/7/2006",
            "3/8/2006",
            "3/9/2006",
            "3/10/2006",
            "3/13/2006",
            "3/14/2006",
            "3/15/2006",
            "3/16/2006",
            "3/17/2006",
            "3/20/2006",
            "3/21/2006",
            "3/22/2006",
            "3/23/2006",
            "3/24/2006",
            "3/27/2006",
            "3/28/2006",
            "3/29/2006",
            "3/30/2006",
            "3/31/2006",
            "4/3/2006",
            "4/4/2006",
            "4/5/2006",
            "4/6/2006",
            "4/7/2006",
            "4/10/2006",
            "4/11/2006",
            "4/12/2006",
            "4/13/2006",
            "4/14/2006",
            "4/17/2006",
            "4/18/2006",
            "4/19/2006",
            "4/20/2006",
            "4/21/2006",
            "4/24/2006",
            "4/25/2006",
            "4/26/2006",
            "4/27/2006",
            "4/28/2006",
            "5/1/2006",
            "5/2/2006",
            "5/3/2006",
            "5/4/2006",
            "5/5/2006",
            "5/8/2006",
            "5/9/2006",
            "5/10/2006",
            "5/11/2006",
            "5/12/2006",
            "5/15/2006",
            "5/16/2006",
            "5/17/2006",
            "5/18/2006",
            "5/19/2006",
            "5/22/2006",
            "5/23/2006",
            "5/24/2006",
            "5/25/2006",
            "5/26/2006",
            "5/29/2006",
            "5/30/2006",
            "5/31/2006",
            "6/1/2006",
            "6/2/2006",
            "6/5/2006",
            "6/6/2006",
            "6/7/2006",
            "6/8/2006",
            "6/9/2006",
            "6/12/2006",
            "6/13/2006",
            "6/14/2006",
            "6/15/2006",
            "6/16/2006",
            "6/19/2006",
            "6/20/2006",
            "6/21/2006",
            "6/22/2006",
            "6/23/2006",
            "6/26/2006",
            "6/27/2006",
            "6/28/2006",
            "6/29/2006",
            "6/30/2006",
            "7/3/2006",
            "7/4/2006",
            "7/5/2006",
            "7/6/2006",
            "7/7/2006",
            "7/10/2006",
            "7/11/2006",
            "7/12/2006",
            "7/13/2006",
            "7/14/2006",
            "7/17/2006",
            "7/18/2006",
            "7/19/2006",
            "7/20/2006",
            "7/21/2006",
            "7/24/2006",
            "7/25/2006",
            "7/26/2006",
            "7/27/2006",
            "7/28/2006",
            "7/31/2006",
            "8/1/2006",
            "8/2/2006",
            "8/3/2006",
            "8/4/2006",
            "8/7/2006",
            "8/8/2006",
            "8/9/2006",
            "8/10/2006",
            "8/11/2006",
            "8/14/2006",
            "8/15/2006",
            "8/16/2006",
            "8/17/2006",
            "8/18/2006",
            "8/21/2006",
            "8/22/2006",
            "8/23/2006",
            "8/24/2006",
            "8/25/2006",
            "8/28/2006",
            "8/29/2006",
            "8/30/2006",
            "8/31/2006",
            "9/1/2006",
            "9/4/2006",
            "9/5/2006",
            "9/6/2006",
            "9/7/2006",
            "9/8/2006",
            "9/11/2006",
            "9/12/2006",
            "9/13/2006",
            "9/14/2006",
            "9/15/2006",
            "9/18/2006",
            "9/19/2006",
            "9/20/2006",
            "9/21/2006",
            "9/22/2006",
            "9/25/2006",
            "9/26/2006",
            "9/27/2006",
            "9/28/2006",
            "9/29/2006",
            "10/2/2006",
            "10/3/2006",
            "10/4/2006",
            "10/5/2006",
            "10/6/2006",
            "10/9/2006",
            "10/10/2006",
            "10/11/2006",
            "10/12/2006",
            "10/13/2006",
            "10/16/2006",
            "10/17/2006",
            "10/18/2006",
            "10/19/2006",
            "10/20/2006",
            "10/23/2006",
            "10/24/2006",
            "10/25/2006",
            "10/26/2006",
            "10/27/2006",
            "10/30/2006",
            "10/31/2006",
            "11/1/2006",
            "11/2/2006",
            "11/3/2006",
            "11/6/2006",
            "11/7/2006",
            "11/8/2006",
            "11/9/2006",
            "11/10/2006",
            "11/13/2006",
            "11/14/2006",
            "11/15/2006",
            "11/16/2006",
            "11/17/2006",
            "11/20/2006",
            "11/21/2006",
            "11/22/2006",
            "11/23/2006",
            "11/24/2006",
            "11/27/2006",
            "11/28/2006",
            "11/29/2006",
            "11/30/2006",
            "12/1/2006",
            "12/4/2006",
            "12/5/2006",
            "12/6/2006",
            "12/7/2006",
            "12/8/2006",
            "12/11/2006",
            "12/12/2006",
            "12/13/2006",
            "12/14/2006",
            "12/15/2006",
            "12/18/2006",
            "12/19/2006",
            "12/20/2006",
            "12/21/2006",
            "12/22/2006",
            "12/25/2006",
            "12/26/2006",
            "12/27/2006",
            "12/28/2006",
            "12/29/2006",
            "1/1/2007",
            "1/2/2007",
            "1/3/2007",
            "1/4/2007",
            "1/5/2007",
            "1/8/2007",
            "1/9/2007",
            "1/10/2007",
            "1/11/2007",
            "1/12/2007",
            "1/15/2007",
            "1/16/2007",
            "1/17/2007",
            "1/18/2007",
            "1/19/2007",
            "1/22/2007",
            "1/23/2007",
            "1/24/2007",
            "1/25/2007",
            "1/26/2007",
            "1/29/2007",
            "1/30/2007",
            "1/31/2007",
            "2/1/2007",
            "2/2/2007",
            "2/5/2007",
            "2/6/2007",
            "2/7/2007",
            "2/8/2007",
            "2/9/2007",
            "2/12/2007",
            "2/13/2007",
            "2/14/2007",
            "2/15/2007",
            "2/16/2007",
            "2/19/2007",
            "2/20/2007",
            "2/21/2007",
            "2/22/2007",
            "2/23/2007",
            "2/26/2007",
            "2/27/2007",
            "2/28/2007",
            "3/1/2007",
            "3/2/2007",
            "3/5/2007",
            "3/6/2007",
            "3/7/2007",
            "3/8/2007",
            "3/9/2007",
            "3/12/2007",
            "3/13/2007",
            "3/14/2007",
            "3/15/2007",
            "3/16/2007",
            "3/19/2007",
            "3/20/2007",
            "3/21/2007",
            "3/22/2007",
            "3/23/2007",
            "3/26/2007",
            "3/27/2007",
            "3/28/2007",
            "3/29/2007",
            "3/30/2007",
            "4/2/2007",
            "4/3/2007",
            "4/4/2007",
            "4/5/2007",
            "4/6/2007",
            "4/9/2007",
            "4/10/2007",
            "4/11/2007",
            "4/12/2007",
            "4/13/2007",
            "4/16/2007",
            "4/17/2007",
            "4/18/2007",
            "4/19/2007",
            "4/20/2007",
            "4/23/2007",
            "4/24/2007",
            "4/25/2007",
            "4/26/2007",
            "4/27/2007",
            "4/30/2007",
            "5/1/2007",
            "5/2/2007",
            "5/3/2007",
            "5/4/2007",
            "5/7/2007",
            "5/8/2007",
            "5/9/2007",
            "5/10/2007",
            "5/11/2007",
            "5/14/2007",
            "5/15/2007",
            "5/16/2007",
            "5/17/2007",
            "5/18/2007",
            "5/21/2007",
            "5/22/2007",
            "5/23/2007",
            "5/24/2007",
            "5/25/2007",
            "5/28/2007",
            "5/29/2007",
            "5/30/2007",
            "5/31/2007",
            "6/1/2007",
            "6/4/2007",
            "6/5/2007",
            "6/6/2007",
            "6/7/2007",
            "6/8/2007",
            "6/11/2007",
            "6/12/2007",
            "6/13/2007",
            "6/14/2007",
            "6/15/2007",
            "6/18/2007",
            "6/19/2007",
            "6/20/2007",
            "6/21/2007",
            "6/22/2007",
            "6/25/2007",
            "6/26/2007",
            "6/27/2007",
            "6/28/2007",
            "6/29/2007",
            "7/2/2007",
            "7/3/2007",
            "7/4/2007",
            "7/5/2007",
            "7/6/2007",
            "7/9/2007",
            "7/10/2007",
            "7/11/2007",
            "7/12/2007",
            "7/13/2007",
            "7/16/2007",
            "7/17/2007",
            "7/18/2007",
            "7/19/2007",
            "7/20/2007",
            "7/23/2007",
            "7/24/2007",
            "7/25/2007",
            "7/26/2007",
            "7/27/2007",
            "7/30/2007",
            "7/31/2007",
            "8/1/2007",
            "8/2/2007",
            "8/3/2007",
            "8/6/2007",
            "8/7/2007",
            "8/8/2007",
            "8/9/2007",
            "8/10/2007",
            "8/13/2007",
            "8/14/2007",
            "8/15/2007",
            "8/16/2007",
            "8/17/2007",
            "8/20/2007",
            "8/21/2007",
            "8/22/2007",
            "8/23/2007",
            "8/24/2007",
            "8/27/2007",
            "8/28/2007",
            "8/29/2007",
            "8/30/2007",
            "8/31/2007",
            "9/3/2007",
            "9/4/2007",
            "9/5/2007",
            "9/6/2007",
            "9/7/2007",
            "9/10/2007",
            "9/11/2007",
            "9/12/2007",
            "9/13/2007",
            "9/14/2007",
            "9/17/2007",
            "9/18/2007",
            "9/19/2007",
            "9/20/2007",
            "9/21/2007",
            "9/24/2007",
            "9/25/2007",
            "9/26/2007",
            "9/27/2007",
            "9/28/2007",
            "10/1/2007",
            "10/2/2007",
            "10/3/2007",
            "10/4/2007",
            "10/5/2007",
            "10/8/2007",
            "10/9/2007",
            "10/10/2007",
            "10/11/2007",
            "10/12/2007",
            "10/15/2007",
            "10/16/2007",
            "10/17/2007",
            "10/18/2007",
            "10/19/2007",
            "10/22/2007",
            "10/23/2007",
            "10/24/2007",
            "10/25/2007",
            "10/26/2007",
            "10/29/2007",
            "10/30/2007",
            "10/31/2007",
            "11/1/2007",
            "11/2/2007",
            "11/5/2007",
            "11/6/2007",
            "11/7/2007",
            "11/8/2007",
            "11/9/2007",
            "11/12/2007",
            "11/13/2007",
            "11/14/2007",
            "11/15/2007",
            "11/16/2007",
            "11/19/2007",
            "11/20/2007",
            "11/21/2007",
            "11/22/2007",
            "11/23/2007",
            "11/26/2007",
            "11/27/2007",
            "11/28/2007",
            "11/29/2007",
            "11/30/2007",
            "12/3/2007",
            "12/4/2007",
            "12/5/2007",
            "12/6/2007",
            "12/7/2007",
            "12/10/2007",
            "12/11/2007",
            "12/12/2007",
            "12/13/2007",
            "12/14/2007",
            "12/17/2007",
            "12/18/2007",
            "12/19/2007",
            "12/20/2007",
            "12/21/2007",
            "12/24/2007",
            "12/25/2007",
            "12/26/2007",
            "12/27/2007",
            "12/28/2007",
            "12/31/2007",
            "1/1/2008",
            "1/2/2008",
            "1/3/2008",
            "1/4/2008",
            "1/7/2008",
            "1/8/2008",
            "1/9/2008",
            "1/10/2008",
            "1/11/2008",
            "1/14/2008",
            "1/15/2008",
            "1/16/2008",
            "1/17/2008",
            "1/18/2008",
            "1/21/2008",
            "1/22/2008",
            "1/23/2008",
            "1/24/2008",
            "1/25/2008",
            "1/28/2008",
            "1/29/2008",
            "1/30/2008",
            "1/31/2008",
            "2/1/2008",
            "2/4/2008",
            "2/5/2008",
            "2/6/2008",
            "2/7/2008",
            "2/8/2008",
            "2/11/2008",
            "2/12/2008",
            "2/13/2008",
            "2/14/2008",
            "2/15/2008",
            "2/18/2008",
            "2/19/2008",
            "2/20/2008",
            "2/21/2008",
            "2/22/2008",
            "2/25/2008",
            "2/26/2008",
            "2/27/2008",
            "2/28/2008",
            "2/29/2008",
            "3/3/2008",
            "3/4/2008",
            "3/5/2008",
            "3/6/2008",
            "3/7/2008",
            "3/10/2008",
            "3/11/2008",
            "3/12/2008",
            "3/13/2008",
            "3/14/2008",
            "3/17/2008",
            "3/18/2008",
            "3/19/2008",
            "3/20/2008",
            "3/21/2008",
            "3/24/2008",
            "3/25/2008",
            "3/26/2008",
            "3/27/2008",
            "3/28/2008",
            "3/31/2008",
            "4/1/2008",
            "4/2/2008",
            "4/3/2008",
            "4/4/2008",
            "4/7/2008",
            "4/8/2008",
            "4/9/2008",
            "4/10/2008",
            "4/11/2008",
            "4/14/2008",
            "4/15/2008",
            "4/16/2008",
            "4/17/2008",
            "4/18/2008",
            "4/21/2008",
            "4/22/2008",
            "4/23/2008",
            "4/24/2008",
            "4/25/2008",
            "4/28/2008",
            "4/29/2008",
            "4/30/2008",
            "5/1/2008",
            "5/2/2008",
            "5/5/2008",
            "5/6/2008",
            "5/7/2008",
            "5/8/2008",
            "5/9/2008",
            "5/12/2008",
            "5/13/2008",
            "5/14/2008",
            "5/15/2008",
            "5/16/2008",
            "5/19/2008",
            "5/20/2008",
            "5/21/2008",
            "5/22/2008",
            "5/23/2008",
            "5/26/2008",
            "5/27/2008",
            "5/28/2008",
            "5/29/2008",
            "5/30/2008",
            "6/2/2008",
            "6/3/2008",
            "6/4/2008",
            "6/5/2008",
            "6/6/2008",
            "6/9/2008",
            "6/10/2008",
            "6/11/2008",
            "6/12/2008",
            "6/13/2008",
            "6/16/2008",
            "6/17/2008",
            "6/18/2008",
            "6/19/2008",
            "6/20/2008",
            "6/23/2008",
            "6/24/2008",
            "6/25/2008",
            "6/26/2008",
            "6/27/2008",
            "6/30/2008",
            "7/1/2008",
            "7/2/2008",
            "7/3/2008",
            "7/4/2008",
            "7/7/2008",
            "7/8/2008",
            "7/9/2008",
            "7/10/2008",
            "7/11/2008",
            "7/14/2008",
            "7/15/2008",
            "7/16/2008",
            "7/17/2008",
            "7/18/2008",
            "7/21/2008",
            "7/22/2008",
            "7/23/2008",
            "7/24/2008",
            "7/25/2008",
            "7/28/2008",
            "7/29/2008",
            "7/30/2008",
            "7/31/2008",
            "8/1/2008",
            "8/4/2008",
            "8/5/2008",
            "8/6/2008",
            "8/7/2008",
            "8/8/2008",
            "8/11/2008",
            "8/12/2008",
            "8/13/2008",
            "8/14/2008",
            "8/15/2008",
            "8/18/2008",
            "8/19/2008",
            "8/20/2008",
            "8/21/2008",
            "8/22/2008",
            "8/25/2008",
            "8/26/2008",
            "8/27/2008",
            "8/28/2008",
            "8/29/2008",
            "9/1/2008",
            "9/2/2008",
            "9/3/2008",
            "9/4/2008",
            "9/5/2008",
            "9/8/2008",
            "9/9/2008",
            "9/10/2008",
            "9/11/2008",
            "9/12/2008",
            "9/15/2008",
            "9/16/2008",
            "9/17/2008",
            "9/18/2008",
            "9/19/2008",
            "9/22/2008",
            "9/23/2008",
            "9/24/2008",
            "9/25/2008",
            "9/26/2008",
            "9/29/2008",
            "9/30/2008",
            "10/1/2008",
            "10/2/2008",
            "10/3/2008",
            "10/6/2008",
            "10/7/2008",
            "10/8/2008",
            "10/9/2008",
            "10/10/2008",
            "10/13/2008",
            "10/14/2008",
            "10/15/2008",
            "10/16/2008",
            "10/17/2008",
            "10/20/2008",
            "10/21/2008",
            "10/22/2008",
            "10/23/2008",
            "10/24/2008",
            "10/27/2008",
            "10/28/2008",
            "10/29/2008",
            "10/30/2008",
            "10/31/2008",
            "11/3/2008",
            "11/4/2008",
            "11/5/2008",
            "11/6/2008",
            "11/7/2008",
            "11/10/2008",
            "11/11/2008",
            "11/12/2008",
            "11/13/2008",
            "11/14/2008",
            "11/17/2008",
            "11/18/2008",
            "11/19/2008",
            "11/20/2008",
            "11/21/2008",
            "11/24/2008",
            "11/25/2008",
            "11/26/2008",
            "11/27/2008",
            "11/28/2008",
            "12/1/2008",
            "12/2/2008",
            "12/3/2008",
            "12/4/2008",
            "12/5/2008",
            "12/8/2008",
            "12/9/2008",
            "12/10/2008",
            "12/11/2008",
            "12/12/2008",
            "12/15/2008",
            "12/16/2008",
            "12/17/2008",
            "12/18/2008",
            "12/19/2008",
            "12/22/2008",
            "12/23/2008",
            "12/24/2008",
            "12/25/2008",
            "12/26/2008",
            "12/29/2008",
            "12/30/2008",
            "12/31/2008",
            "1/1/2009",
            "1/2/2009",
            "1/5/2009",
            "1/6/2009",
            "1/7/2009",
            "1/8/2009",
            "1/9/2009",
            "1/12/2009",
            "1/13/2009",
            "1/14/2009",
            "1/15/2009",
            "1/16/2009",
            "1/19/2009",
            "1/20/2009",
            "1/21/2009",
            "1/22/2009",
            "1/23/2009",
            "1/26/2009",
            "1/27/2009",
            "1/28/2009",
            "1/29/2009",
            "1/30/2009",
            "2/2/2009",
            "2/3/2009",
            "2/4/2009",
            "2/5/2009",
            "2/6/2009",
            "2/9/2009",
            "2/10/2009",
            "2/11/2009",
            "2/12/2009",
            "2/13/2009",
            "2/16/2009",
            "2/17/2009",
            "2/18/2009",
            "2/19/2009",
            "2/20/2009",
            "2/23/2009",
            "2/24/2009",
            "2/25/2009",
            "2/26/2009",
            "2/27/2009",
            "3/2/2009",
            "3/3/2009",
            "3/4/2009",
            "3/5/2009",
            "3/6/2009",
            "3/9/2009",
            "3/10/2009",
            "3/11/2009",
            "3/12/2009",
            "3/13/2009",
            "3/16/2009",
            "3/17/2009",
            "3/18/2009",
            "3/19/2009",
            "3/20/2009",
            "3/23/2009",
            "3/24/2009",
            "3/25/2009",
            "3/26/2009",
            "3/27/2009",
            "3/30/2009",
            "3/31/2009",
            "4/1/2009",
            "4/2/2009",
            "4/3/2009",
            "4/6/2009",
            "4/7/2009",
            "4/8/2009",
            "4/9/2009",
            "4/10/2009",
            "4/13/2009",
            "4/14/2009",
            "4/15/2009",
            "4/16/2009",
            "4/17/2009",
            "4/20/2009",
            "4/21/2009",
            "4/22/2009",
            "4/23/2009",
            "4/24/2009",
            "4/27/2009",
            "4/28/2009",
            "4/29/2009",
            "4/30/2009",
            "5/1/2009",
            "5/4/2009",
            "5/5/2009",
            "5/6/2009",
            "5/7/2009",
            "5/8/2009",
            "5/11/2009",
            "5/12/2009",
            "5/13/2009",
            "5/14/2009",
            "5/15/2009",
            "5/18/2009",
            "5/19/2009",
            "5/20/2009",
            "5/21/2009",
            "5/22/2009",
            "5/25/2009",
            "5/26/2009",
            "5/27/2009",
            "5/28/2009",
            "5/29/2009",
            "6/1/2009",
            "6/2/2009",
            "6/3/2009",
            "6/4/2009",
            "6/5/2009",
            "6/8/2009",
            "6/9/2009",
            "6/10/2009",
            "6/11/2009",
            "6/12/2009",
            "6/15/2009",
            "6/16/2009",
            "6/17/2009",
            "6/18/2009",
            "6/19/2009",
            "6/22/2009",
            "6/23/2009",
            "6/24/2009",
            "6/25/2009",
            "6/26/2009",
            "6/29/2009",
            "6/30/2009",
            "7/1/2009",
            "7/2/2009",
            "7/3/2009",
            "7/6/2009",
            "7/7/2009",
            "7/8/2009",
            "7/9/2009",
            "7/10/2009",
            "7/13/2009",
            "7/14/2009",
            "7/15/2009",
            "7/16/2009",
            "7/17/2009",
            "7/20/2009",
            "7/21/2009",
            "7/22/2009",
            "7/23/2009",
            "7/24/2009",
            "7/27/2009",
            "7/28/2009",
            "7/29/2009",
            "7/30/2009",
            "7/31/2009",
            "8/3/2009",
            "8/4/2009",
            "8/5/2009",
            "8/6/2009",
            "8/7/2009",
            "8/10/2009",
            "8/11/2009",
            "8/12/2009",
            "8/13/2009",
            "8/14/2009",
            "8/17/2009",
            "8/18/2009",
            "8/19/2009",
            "8/20/2009",
            "8/21/2009",
            "8/24/2009",
            "8/25/2009",
            "8/26/2009",
            "8/27/2009",
            "8/28/2009",
            "8/31/2009",
            "9/1/2009",
            "9/2/2009",
            "9/3/2009",
            "9/4/2009",
            "9/7/2009",
            "9/8/2009",
            "9/9/2009",
            "9/10/2009",
            "9/11/2009",
            "9/14/2009",
            "9/15/2009",
            "9/16/2009",
            "9/17/2009",
            "9/18/2009",
            "9/21/2009",
            "9/22/2009",
            "9/23/2009",
            "9/24/2009",
            "9/25/2009",
            "9/28/2009",
            "9/29/2009",
            "9/30/2009",
            "10/1/2009",
            "10/2/2009",
            "10/5/2009",
            "10/6/2009",
            "10/7/2009",
            "10/8/2009",
            "10/9/2009",
            "10/12/2009",
            "10/13/2009",
            "10/14/2009",
            "10/15/2009",
            "10/16/2009",
            "10/19/2009",
            "10/20/2009",
            "10/21/2009",
            "10/22/2009",
            "10/23/2009",
            "10/26/2009",
            "10/27/2009",
            "10/28/2009",
            "10/29/2009",
            "10/30/2009",
            "11/2/2009",
            "11/3/2009",
            "11/4/2009",
            "11/5/2009",
            "11/6/2009",
            "11/9/2009",
            "11/10/2009",
            "11/11/2009",
            "11/12/2009",
            "11/13/2009",
            "11/16/2009",
            "11/17/2009",
            "11/18/2009",
            "11/19/2009",
            "11/20/2009",
            "11/23/2009",
            "11/24/2009",
            "11/25/2009",
            "11/26/2009",
            "11/27/2009",
            "11/30/2009",
            "12/1/2009",
            "12/2/2009",
            "12/3/2009",
            "12/4/2009",
            "12/7/2009",
            "12/8/2009",
            "12/9/2009",
            "12/10/2009",
            "12/11/2009",
            "12/14/2009",
            "12/15/2009",
            "12/16/2009",
            "12/17/2009",
            "12/18/2009",
            "12/21/2009",
            "12/22/2009",
            "12/23/2009",
            "12/24/2009",
            "12/25/2009",
            "12/28/2009",
            "12/29/2009",
            "12/30/2009",
            "12/31/2009",
            "1/1/2010",
            "1/4/2010",
            "1/5/2010",
            "1/6/2010",
            "1/7/2010",
            "1/8/2010",
            "1/11/2010",
            "1/12/2010",
            "1/13/2010",
            "1/14/2010",
            "1/15/2010",
            "1/18/2010",
            "1/19/2010",
            "1/20/2010",
            "1/21/2010",
            "1/22/2010",
            "1/25/2010",
            "1/26/2010",
            "1/27/2010",
            "1/28/2010",
            "1/29/2010",
            "2/1/2010",
            "2/2/2010",
            "2/3/2010",
            "2/4/2010",
            "2/5/2010",
            "2/8/2010",
            "2/9/2010",
            "2/10/2010",
            "2/11/2010",
            "2/12/2010",
            "2/15/2010",
            "2/16/2010",
            "2/17/2010",
            "2/18/2010",
            "2/19/2010",
            "2/22/2010",
            "2/23/2010",
            "2/24/2010",
            "2/25/2010",
            "2/26/2010",
            "3/1/2010",
            "3/2/2010",
            "3/3/2010",
            "3/4/2010",
            "3/5/2010",
            "3/8/2010",
            "3/9/2010",
            "3/10/2010",
            "3/11/2010",
            "3/12/2010",
            "3/15/2010",
            "3/16/2010",
            "3/17/2010",
            "3/18/2010",
            "3/19/2010",
            "3/22/2010",
            "3/23/2010",
            "3/24/2010",
            "3/25/2010",
            "3/26/2010",
            "3/29/2010",
            "3/30/2010",
            "3/31/2010",
            "4/1/2010",
            "4/2/2010",
            "4/5/2010",
            "4/6/2010",
            "4/7/2010",
            "4/8/2010",
            "4/9/2010",
            "4/12/2010",
            "4/13/2010",
            "4/14/2010",
            "4/15/2010",
            "4/16/2010",
            "4/19/2010",
            "4/20/2010",
            "4/21/2010",
            "4/22/2010",
            "4/23/2010",
            "4/26/2010",
            "4/27/2010",
            "4/28/2010",
            "4/29/2010",
            "4/30/2010",
            "5/3/2010",
            "5/4/2010",
            "5/5/2010",
            "5/6/2010",
            "5/7/2010",
            "5/10/2010",
            "5/11/2010",
            "5/12/2010",
            "5/13/2010",
            "5/14/2010",
            "5/17/2010",
            "5/18/2010",
            "5/19/2010",
            "5/20/2010",
            "5/21/2010",
            "5/24/2010",
            "5/25/2010",
            "5/26/2010",
            "5/27/2010",
            "5/28/2010",
            "5/31/2010",
            "6/1/2010",
            "6/2/2010",
            "6/3/2010",
            "6/4/2010",
            "6/7/2010",
            "6/8/2010",
            "6/9/2010",
            "6/10/2010",
            "6/11/2010",
            "6/14/2010",
            "6/15/2010",
            "6/16/2010",
            "6/17/2010",
            "6/18/2010",
            "6/21/2010",
            "6/22/2010",
            "6/23/2010",
            "6/24/2010",
            "6/25/2010",
            "6/28/2010",
            "6/29/2010",
            "6/30/2010",
            "7/1/2010",
            "7/2/2010",
            "7/5/2010",
            "7/6/2010",
            "7/7/2010",
            "7/8/2010",
            "7/9/2010",
            "7/12/2010",
            "7/13/2010",
            "7/14/2010",
            "7/15/2010",
            "7/16/2010",
            "7/19/2010",
            "7/20/2010",
            "7/21/2010",
            "7/22/2010",
            "7/23/2010",
            "7/26/2010",
            "7/27/2010",
            "7/28/2010",
            "7/29/2010",
            "7/30/2010",
            "8/2/2010",
            "8/3/2010",
            "8/4/2010",
            "8/5/2010",
            "8/6/2010",
            "8/9/2010",
            "8/10/2010",
            "8/11/2010",
            "8/12/2010",
            "8/13/2010",
            "8/16/2010",
            "8/17/2010",
            "8/18/2010",
            "8/19/2010",
            "8/20/2010",
            "8/23/2010",
            "8/24/2010",
            "8/25/2010",
            "8/26/2010",
            "8/27/2010",
            "8/30/2010",
            "8/31/2010",
            "9/1/2010",
            "9/2/2010",
            "9/3/2010",
            "9/6/2010",
            "9/7/2010",
            "9/8/2010",
            "9/9/2010",
            "9/10/2010",
            "9/13/2010",
            "9/14/2010",
            "9/15/2010",
            "9/16/2010",
            "9/17/2010",
            "9/20/2010",
            "9/21/2010",
            "9/22/2010",
            "9/23/2010",
            "9/24/2010",
            "9/27/2010",
            "9/28/2010",
            "9/29/2010",
            "9/30/2010",
            "10/1/2010",
            "10/4/2010",
            "10/5/2010",
            "10/6/2010",
            "10/7/2010",
            "10/8/2010",
            "10/11/2010",
            "10/12/2010",
            "10/13/2010",
            "10/14/2010",
            "10/15/2010",
            "10/18/2010",
            "10/19/2010",
            "10/20/2010",
            "10/21/2010",
            "10/22/2010",
            "10/25/2010",
            "10/26/2010",
            "10/27/2010",
            "10/28/2010",
            "10/29/2010",
            "11/1/2010",
            "11/2/2010",
            "11/3/2010",
            "11/4/2010",
            "11/5/2010",
            "11/8/2010",
            "11/9/2010",
            "11/10/2010",
            "11/11/2010",
            "11/12/2010",
            "11/15/2010",
            "11/16/2010",
            "11/17/2010",
            "11/18/2010",
            "11/19/2010",
            "11/22/2010",
            "11/23/2010",
            "11/24/2010",
            "11/25/2010",
            "11/26/2010",
            "11/29/2010",
            "11/30/2010",
            "12/1/2010",
            "12/2/2010",
            "12/3/2010",
            "12/6/2010",
            "12/7/2010",
            "12/8/2010",
            "12/9/2010",
            "12/10/2010",
            "12/13/2010",
            "12/14/2010",
            "12/15/2010",
            "12/16/2010",
            "12/17/2010",
            "12/20/2010",
            "12/21/2010",
            "12/22/2010",
            "12/23/2010",
            "12/24/2010",
            "12/27/2010",
            "12/28/2010",
            "12/29/2010",
            "12/30/2010",
            "12/31/2010",
            "1/3/2011",
            "1/4/2011",
            "1/5/2011",
            "1/6/2011",
            "1/7/2011",
            "1/10/2011",
            "1/11/2011",
            "1/12/2011",
            "1/13/2011",
            "1/14/2011",
            "1/17/2011",
            "1/18/2011",
            "1/19/2011",
            "1/20/2011",
            "1/21/2011",
            "1/24/2011",
            "1/25/2011",
            "1/26/2011",
            "1/27/2011",
            "1/28/2011",
            "1/31/2011",
            "2/1/2011",
            "2/2/2011",
            "2/3/2011",
            "2/4/2011",
            "2/7/2011",
            "2/8/2011",
            "2/9/2011",
            "2/10/2011",
            "2/11/2011",
            "2/14/2011",
            "2/15/2011",
            "2/16/2011",
            "2/17/2011",
            "2/18/2011",
            "2/21/2011",
            "2/22/2011",
            "2/23/2011",
            "2/24/2011",
            "2/25/2011",
            "2/28/2011",
            "3/1/2011",
            "3/2/2011",
            "3/3/2011",
            "3/4/2011",
            "3/7/2011",
            "3/8/2011",
            "3/9/2011",
            "3/10/2011",
            "3/11/2011",
            "3/14/2011",
            "3/15/2011",
            "3/16/2011",
            "3/17/2011",
            "3/18/2011",
            "3/21/2011",
            "3/22/2011",
            "3/23/2011",
            "3/24/2011",
            "3/25/2011",
            "3/28/2011",
            "3/29/2011",
            "3/30/2011",
            "3/31/2011",
            "4/1/2011",
            "4/4/2011",
            "4/5/2011",
            "4/6/2011",
            "4/7/2011",
            "4/8/2011",
            "4/11/2011",
            "4/12/2011",
            "4/13/2011",
            "4/14/2011",
            "4/15/2011",
            "4/18/2011",
            "4/19/2011",
            "4/20/2011",
            "4/21/2011",
            "4/22/2011",
            "4/25/2011",
            "4/26/2011",
            "4/27/2011",
            "4/28/2011",
            "4/29/2011",
            "5/2/2011",
            "5/3/2011",
            "5/4/2011",
            "5/5/2011",
            "5/6/2011",
            "5/9/2011",
            "5/10/2011",
            "5/11/2011",
            "5/12/2011",
            "5/13/2011",
            "5/16/2011",
            "5/17/2011",
            "5/18/2011",
            "5/19/2011",
            "5/20/2011",
            "5/23/2011",
            "5/24/2011",
            "5/25/2011",
            "5/26/2011",
            "5/27/2011",
            "5/30/2011",
            "5/31/2011",
            "6/1/2011",
            "6/2/2011",
            "6/3/2011",
            "6/6/2011",
            "6/7/2011",
            "6/8/2011",
            "6/9/2011",
            "6/10/2011",
            "6/13/2011",
            "6/14/2011",
            "6/15/2011",
            "6/16/2011",
            "6/17/2011",
            "6/20/2011",
            "6/21/2011",
            "6/22/2011",
            "6/23/2011",
            "6/24/2011",
            "6/27/2011",
            "6/28/2011",
            "6/29/2011",
            "6/30/2011",
            "7/1/2011",
            "7/4/2011",
            "7/5/2011",
            "7/6/2011",
            "7/7/2011",
            "7/8/2011",
            "7/11/2011",
            "7/12/2011",
            "7/13/2011",
            "7/14/2011",
            "7/15/2011",
            "7/18/2011",
            "7/19/2011",
            "7/20/2011",
            "7/21/2011",
            "7/22/2011",
            "7/25/2011",
            "7/26/2011",
            "7/27/2011",
            "7/28/2011",
            "7/29/2011",
            "8/1/2011",
            "8/2/2011",
            "8/3/2011",
            "8/4/2011",
            "8/5/2011",
            "8/8/2011",
            "8/9/2011",
            "8/10/2011",
            "8/11/2011",
            "8/12/2011",
            "8/15/2011",
            "8/16/2011",
            "8/17/2011",
            "8/18/2011",
            "8/19/2011",
            "8/22/2011",
            "8/23/2011",
            "8/24/2011",
            "8/25/2011",
            "8/26/2011",
            "8/29/2011",
            "8/30/2011",
            "8/31/2011",
            "9/1/2011",
            "9/2/2011",
            "9/5/2011",
            "9/6/2011",
            "9/7/2011",
            "9/8/2011",
            "9/9/2011",
            "9/12/2011",
            "9/13/2011",
            "9/14/2011",
            "9/15/2011",
            "9/16/2011",
            "9/19/2011",
            "9/20/2011",
            "9/21/2011",
            "9/22/2011",
            "9/23/2011",
            "9/26/2011",
            "9/27/2011",
            "9/28/2011",
            "9/29/2011",
            "9/30/2011",
            "10/3/2011",
            "10/4/2011",
            "10/5/2011",
            "10/6/2011",
            "10/7/2011",
            "10/10/2011",
            "10/11/2011",
            "10/12/2011",
            "10/13/2011",
            "10/14/2011",
            "10/17/2011",
            "10/18/2011",
            "10/19/2011",
            "10/20/2011",
            "10/21/2011",
            "10/24/2011",
            "10/25/2011",
            "10/26/2011",
            "10/27/2011",
            "10/28/2011",
            "10/31/2011",
            "11/1/2011",
            "11/2/2011",
            "11/3/2011",
            "11/4/2011",
            "11/7/2011",
            "11/8/2011",
            "11/9/2011",
            "11/10/2011",
            "11/11/2011",
            "11/14/2011",
            "11/15/2011",
            "11/16/2011",
            "11/17/2011",
            "11/18/2011",
            "11/21/2011",
            "11/22/2011",
            "11/23/2011",
            "11/24/2011",
            "11/25/2011",
            "11/28/2011",
            "11/29/2011",
            "11/30/2011",
            "12/1/2011",
            "12/2/2011",
            "12/5/2011",
            "12/6/2011",
            "12/7/2011",
            "12/8/2011",
            "12/9/2011",
            "12/12/2011",
            "12/13/2011",
            "12/14/2011",
            "12/15/2011",
            "12/16/2011",
            "12/19/2011",
            "12/20/2011",
            "12/21/2011",
            "12/22/2011",
            "12/23/2011",
            "12/26/2011",
            "12/27/2011",
            "12/28/2011",
            "12/29/2011",
            "12/30/2011",
            "1/2/2012",
            "1/3/2012",
            "1/4/2012",
            "1/5/2012",
            "1/6/2012",
            "1/9/2012",
            "1/10/2012",
            "1/11/2012",
            "1/12/2012",
            "1/13/2012",
            "1/16/2012",
            "1/17/2012",
            "1/18/2012",
            "1/19/2012",
            "1/20/2012",
            "1/23/2012",
            "1/24/2012",
            "1/25/2012",
            "1/26/2012",
            "1/27/2012",
            "1/30/2012",
            "1/31/2012",
            "2/1/2012",
            "2/2/2012",
            "2/3/2012",
            "2/6/2012",
            "2/7/2012",
            "2/8/2012",
            "2/9/2012",
            "2/10/2012",
            "2/13/2012",
            "2/14/2012",
            "2/15/2012",
            "2/16/2012",
            "2/17/2012",
            "2/20/2012",
            "2/21/2012",
            "2/22/2012",
            "2/23/2012",
            "2/24/2012",
            "2/27/2012",
            "2/28/2012",
            "2/29/2012",
            "3/1/2012",
            "3/2/2012",
            "3/5/2012",
            "3/6/2012",
            "3/7/2012",
            "3/8/2012",
            "3/9/2012",
            "3/12/2012",
            "3/13/2012",
            "3/14/2012",
            "3/15/2012",
            "3/16/2012",
            "3/19/2012",
            "3/20/2012",
            "3/21/2012",
            "3/22/2012",
            "3/23/2012",
            "3/26/2012",
            "3/27/2012",
            "3/28/2012",
            "3/29/2012",
            "3/30/2012",
            "4/2/2012",
            "4/3/2012",
            "4/4/2012",
            "4/5/2012",
            "4/6/2012",
            "4/9/2012",
            "4/10/2012",
            "4/11/2012",
            "4/12/2012",
            "4/13/2012",
            "4/16/2012",
            "4/17/2012",
            "4/18/2012",
            "4/19/2012",
            "4/20/2012",
            "4/23/2012",
            "4/24/2012",
            "4/25/2012",
            "4/26/2012",
            "4/27/2012",
            "4/30/2012",
            "5/1/2012",
            "5/2/2012",
            "5/3/2012",
            "5/4/2012",
            "5/7/2012",
            "5/8/2012",
            "5/9/2012",
            "5/10/2012",
            "5/11/2012",
            "5/14/2012",
            "5/15/2012",
            "5/16/2012",
            "5/17/2012",
            "5/18/2012",
            "5/21/2012",
            "5/22/2012",
            "5/23/2012",
            "5/24/2012",
            "5/25/2012",
            "5/28/2012",
            "5/29/2012",
            "5/30/2012",
            "5/31/2012",
            "6/1/2012",
            "6/4/2012",
            "6/5/2012",
            "6/6/2012",
            "6/7/2012",
            "6/8/2012",
            "6/11/2012",
            "6/12/2012",
            "6/13/2012",
            "6/14/2012",
            "6/15/2012",
            "6/18/2012",
            "6/19/2012",
            "6/20/2012",
            "6/21/2012",
            "6/22/2012",
            "6/25/2012",
            "6/26/2012",
            "6/27/2012",
            "6/28/2012",
            "6/29/2012",
            "7/2/2012",
            "7/3/2012",
            "7/4/2012",
            "7/5/2012",
            "7/6/2012",
            "7/9/2012",
            "7/10/2012",
            "7/11/2012",
            "7/12/2012",
            "7/13/2012",
            "7/16/2012",
            "7/17/2012",
            "7/18/2012",
            "7/19/2012",
            "7/20/2012",
            "7/23/2012",
            "7/24/2012",
            "7/25/2012",
            "7/26/2012",
            "7/27/2012",
            "7/30/2012",
            "7/31/2012",
            "8/1/2012",
            "8/2/2012",
            "8/3/2012",
            "8/6/2012",
            "8/7/2012",
            "8/8/2012",
            "8/9/2012",
            "8/10/2012",
            "8/13/2012",
            "8/14/2012",
            "8/15/2012",
            "8/16/2012",
            "8/17/2012",
            "8/20/2012",
            "8/21/2012",
            "8/22/2012",
            "8/23/2012",
            "8/24/2012",
            "8/27/2012",
            "8/28/2012",
            "8/29/2012",
            "8/30/2012",
            "8/31/2012",
            "9/3/2012",
            "9/4/2012",
            "9/5/2012",
            "9/6/2012",
            "9/7/2012",
            "9/10/2012",
            "9/11/2012",
            "9/12/2012",
            "9/13/2012",
            "9/14/2012",
            "9/17/2012",
            "9/18/2012",
            "9/19/2012",
            "9/20/2012",
            "9/21/2012",
            "9/24/2012",
            "9/25/2012",
            "9/26/2012",
            "9/27/2012",
            "9/28/2012",
            "10/1/2012",
            "10/2/2012",
            "10/3/2012",
            "10/4/2012",
            "10/5/2012",
            "10/8/2012",
            "10/9/2012",
            "10/10/2012",
            "10/11/2012",
            "10/12/2012",
            "10/15/2012",
            "10/16/2012",
            "10/17/2012",
            "10/18/2012",
            "10/19/2012",
            "10/22/2012",
            "10/23/2012",
            "10/24/2012",
            "10/25/2012",
            "10/26/2012",
            "10/29/2012",
            "10/30/2012",
            "10/31/2012",
            "11/1/2012",
            "11/2/2012",
            "11/5/2012",
            "11/6/2012",
            "11/7/2012",
            "11/8/2012",
            "11/9/2012",
            "11/12/2012",
            "11/13/2012",
            "11/14/2012",
            "11/15/2012",
            "11/16/2012",
            "11/19/2012",
            "11/20/2012",
            "11/21/2012",
            "11/22/2012",
            "11/23/2012",
            "11/26/2012",
            "11/27/2012",
            "11/28/2012",
            "11/29/2012",
            "11/30/2012",
            "12/3/2012",
            "12/4/2012",
            "12/5/2012",
            "12/6/2012",
            "12/7/2012",
            "12/10/2012",
            "12/11/2012",
            "12/12/2012",
            "12/13/2012",
            "12/14/2012",
            "12/17/2012",
            "12/18/2012",
            "12/19/2012",
            "12/20/2012",
            "12/21/2012",
            "12/24/2012",
            "12/25/2012",
            "12/26/2012",
            "12/27/2012",
            "12/28/2012",
            "12/31/2012",
            "1/1/2013",
            "1/2/2013",
            "1/3/2013",
            "1/4/2013",
            "1/7/2013",
            "1/8/2013",
            "1/9/2013",
            "1/10/2013",
            "1/11/2013",
            "1/14/2013",
            "1/15/2013",
            "1/16/2013",
            "1/17/2013",
            "1/18/2013",
            "1/21/2013",
            "1/22/2013",
            "1/23/2013",
            "1/24/2013",
            "1/25/2013",
            "1/28/2013",
            "1/29/2013",
            "1/30/2013",
            "1/31/2013",
            "2/1/2013",
            "2/4/2013",
            "2/5/2013",
            "2/6/2013",
            "2/7/2013",
            "2/8/2013",
            "2/11/2013",
            "2/12/2013",
            "2/13/2013",
            "2/14/2013",
            "2/15/2013",
            "2/18/2013",
            "2/19/2013",
            "2/20/2013",
            "2/21/2013",
            "2/22/2013",
            "2/25/2013",
            "2/26/2013",
            "2/27/2013",
            "2/28/2013",
            "3/1/2013",
            "3/4/2013",
            "3/5/2013",
            "3/6/2013",
            "3/7/2013",
            "3/8/2013",
            "3/11/2013",
            "3/12/2013",
            "3/13/2013",
            "3/14/2013",
            "3/15/2013",
            "3/18/2013",
            "3/19/2013",
            "3/20/2013",
            "3/21/2013",
            "3/22/2013",
            "3/25/2013",
            "3/26/2013",
            "3/27/2013",
            "3/28/2013",
            "3/29/2013",
            "4/1/2013",
            "4/2/2013",
            "4/3/2013",
            "4/4/2013",
            "4/5/2013",
            "4/8/2013",
            "4/9/2013",
            "4/10/2013",
            "4/11/2013",
            "4/12/2013",
            "4/15/2013",
            "4/16/2013",
            "4/17/2013",
            "4/18/2013",
            "4/19/2013",
            "4/22/2013",
            "4/23/2013",
            "4/24/2013",
            "4/25/2013",
            "4/26/2013",
            "4/29/2013",
            "4/30/2013",
            "5/1/2013",
            "5/2/2013",
            "5/3/2013",
            "5/6/2013",
            "5/7/2013",
            "5/8/2013",
            "5/9/2013",
            "5/10/2013",
            "5/13/2013",
            "5/14/2013",
            "5/15/2013",
            "5/16/2013",
            "5/17/2013",
            "5/20/2013",
            "5/21/2013",
            "5/22/2013",
            "5/23/2013",
            "5/24/2013",
            "5/27/2013",
            "5/28/2013",
            "5/29/2013",
            "5/30/2013",
            "5/31/2013",
            "6/3/2013",
            "6/4/2013",
            "6/5/2013",
            "6/6/2013",
            "6/7/2013",
            "6/10/2013",
            "6/11/2013",
            "6/12/2013",
            "6/13/2013",
            "6/14/2013",
            "6/17/2013",
            "6/18/2013",
            "6/19/2013",
            "6/20/2013",
            "6/21/2013",
            "6/24/2013",
            "6/25/2013",
            "6/26/2013",
            "6/27/2013",
            "6/28/2013",
            "7/1/2013",
            "7/2/2013",
            "7/3/2013",
            "7/4/2013",
            "7/5/2013",
            "7/8/2013",
            "7/9/2013",
            "7/10/2013",
            "7/11/2013",
            "7/12/2013",
            "7/15/2013",
            "7/16/2013",
            "7/17/2013",
            "7/18/2013",
            "7/19/2013",
            "7/22/2013",
            "7/23/2013",
            "7/24/2013",
            "7/25/2013",
            "7/26/2013",
            "7/29/2013",
            "7/30/2013",
            "7/31/2013",
            "8/1/2013",
            "8/2/2013",
            "8/5/2013",
            "8/6/2013",
            "8/7/2013",
            "8/8/2013",
            "8/9/2013",
            "8/12/2013",
            "8/13/2013",
            "8/14/2013",
            "8/15/2013",
            "8/16/2013",
            "8/19/2013",
            "8/20/2013",
            "8/21/2013",
            "8/22/2013",
            "8/23/2013",
            "8/26/2013",
            "8/27/2013",
            "8/28/2013",
            "8/29/2013",
            "8/30/2013",
            "9/2/2013",
            "9/3/2013",
            "9/4/2013",
            "9/5/2013",
            "9/6/2013",
            "9/9/2013",
            "9/10/2013",
            "9/11/2013",
            "9/12/2013",
            "9/13/2013",
            "9/16/2013",
            "9/17/2013",
            "9/18/2013",
            "9/19/2013",
            "9/20/2013",
            "9/23/2013",
            "9/24/2013",
            "9/25/2013",
            "9/26/2013",
            "9/27/2013",
            "9/30/2013",
            "10/1/2013",
            "10/2/2013",
            "10/3/2013",
            "10/4/2013",
            "10/7/2013",
            "10/8/2013",
            "10/9/2013",
            "10/10/2013",
            "10/11/2013",
            "10/14/2013",
            "10/15/2013",
            "10/16/2013",
            "10/17/2013",
            "10/18/2013",
            "10/21/2013",
            "10/22/2013",
            "10/23/2013",
            "10/24/2013",
            "10/25/2013",
            "10/28/2013",
            "10/29/2013",
            "10/30/2013",
            "10/31/2013",
            "11/1/2013",
            "11/4/2013",
            "11/5/2013",
            "11/6/2013",
            "11/7/2013",
            "11/8/2013",
            "11/11/2013",
            "11/12/2013",
            "11/13/2013",
            "11/14/2013",
            "11/15/2013",
            "11/18/2013",
            "11/19/2013",
            "11/20/2013",
            "11/21/2013",
            "11/22/2013",
            "11/25/2013",
            "11/26/2013",
            "11/27/2013",
            "11/28/2013",
            "11/29/2013",
            "12/2/2013",
            "12/3/2013",
            "12/4/2013",
            "12/5/2013",
            "12/6/2013",
            "12/9/2013",
            "12/10/2013",
            "12/11/2013",
            "12/12/2013",
            "12/13/2013",
            "12/16/2013",
            "12/17/2013",
            "12/18/2013",
            "12/19/2013",
            "12/20/2013",
            "12/23/2013",
            "12/24/2013",
            "12/25/2013",
            "12/26/2013",
            "12/27/2013",
            "12/30/2013",
            "12/31/2013",
            "1/1/2014",
            "1/2/2014",
            "1/3/2014",
            "1/6/2014",
            "1/7/2014",
            "1/8/2014",
            "1/9/2014",
            "1/10/2014",
            "1/13/2014",
            "1/14/2014",
            "1/15/2014",
            "1/16/2014",
            "1/17/2014",
            "1/20/2014",
            "1/21/2014",
            "1/22/2014",
            "1/23/2014",
            "1/24/2014",
            "1/27/2014",
            "1/28/2014",
            "1/29/2014",
            "1/30/2014",
            "1/31/2014",
            "2/3/2014",
            "2/4/2014",
            "2/5/2014",
            "2/6/2014",
            "2/7/2014",
            "2/10/2014",
            "2/11/2014",
            "2/12/2014",
            "2/13/2014",
            "2/14/2014",
            "2/17/2014",
            "2/18/2014",
            "2/19/2014",
            "2/20/2014",
            "2/21/2014",
            "2/24/2014",
            "2/25/2014",
            "2/26/2014",
            "2/27/2014",
            "2/28/2014",
            "3/3/2014",
            "3/4/2014",
            "3/5/2014",
            "3/6/2014",
            "3/7/2014",
            "3/10/2014",
            "3/11/2014",
            "3/12/2014",
            "3/13/2014",
            "3/14/2014",
            "3/17/2014",
            "3/18/2014",
            "3/19/2014",
            "3/20/2014",
            "3/21/2014",
            "3/24/2014",
            "3/25/2014",
            "3/26/2014",
            "3/27/2014",
            "3/28/2014",
            "3/31/2014",
            "4/1/2014",
            "4/2/2014",
            "4/3/2014",
            "4/4/2014",
            "4/7/2014",
            "4/8/2014",
            "4/9/2014",
            "4/10/2014",
            "4/11/2014",
            "4/14/2014",
            "4/15/2014",
            "4/16/2014",
            "4/17/2014",
            "4/18/2014",
            "4/21/2014",
            "4/22/2014",
            "4/23/2014",
            "4/24/2014",
            "4/25/2014",
            "4/28/2014",
            "4/29/2014",
            "4/30/2014",
            "5/1/2014",
            "5/2/2014",
            "5/5/2014",
            "5/6/2014",
            "5/7/2014",
            "5/8/2014",
            "5/9/2014",
            "5/12/2014",
            "5/13/2014",
            "5/14/2014",
            "5/15/2014",
            "5/16/2014",
            "5/19/2014",
            "5/20/2014",
            "5/21/2014",
            "5/22/2014",
            "5/23/2014",
            "5/26/2014",
            "5/27/2014",
            "5/28/2014",
            "5/29/2014",
            "5/30/2014",
            "6/2/2014",
            "6/3/2014",
            "6/4/2014",
            "6/5/2014",
            "6/6/2014",
            "6/9/2014",
            "6/10/2014",
            "6/11/2014",
            "6/12/2014",
            "6/13/2014",
            "6/16/2014",
            "6/17/2014",
            "6/18/2014",
            "6/19/2014",
            "6/20/2014",
            "6/23/2014",
            "6/24/2014",
            "6/25/2014",
            "6/26/2014",
            "6/27/2014",
            "6/30/2014",
            "7/1/2014",
            "7/2/2014",
            "7/3/2014",
            "7/4/2014",
            "7/7/2014",
            "7/8/2014",
            "7/9/2014",
            "7/10/2014",
            "7/11/2014",
            "7/14/2014",
            "7/15/2014",
            "7/16/2014",
            "7/17/2014",
            "7/18/2014",
            "7/21/2014",
            "7/22/2014",
            "7/23/2014",
            "7/24/2014",
            "7/25/2014",
            "7/28/2014",
            "7/29/2014",
            "7/30/2014",
            "7/31/2014",
            "8/1/2014",
            "8/4/2014",
            "8/5/2014",
            "8/6/2014",
            "8/7/2014",
            "8/8/2014",
            "8/11/2014",
            "8/12/2014",
            "8/13/2014",
            "8/14/2014",
            "8/15/2014",
            "8/18/2014",
            "8/19/2014",
            "8/20/2014",
            "8/21/2014",
            "8/22/2014",
            "8/25/2014",
            "8/26/2014",
            "8/27/2014",
            "8/28/2014",
            "8/29/2014",
            "9/1/2014",
            "9/2/2014",
            "9/3/2014",
            "9/4/2014",
            "9/5/2014",
            "9/8/2014",
            "9/9/2014",
            "9/10/2014",
            "9/11/2014",
            "9/12/2014",
            "9/15/2014",
            "9/16/2014",
            "9/17/2014",
            "9/18/2014",
            "9/19/2014",
            "9/22/2014",
            "9/23/2014",
            "9/24/2014",
            "9/25/2014",
            "9/26/2014",
            "9/29/2014",
            "9/30/2014",
            "10/1/2014",
            "10/2/2014",
            "10/3/2014",
            "10/6/2014",
            "10/7/2014",
            "10/8/2014",
            "10/9/2014",
            "10/10/2014",
            "10/13/2014",
            "10/14/2014",
            "10/15/2014",
            "10/16/2014",
            "10/17/2014",
            "10/20/2014",
            "10/21/2014",
            "10/22/2014",
            "10/23/2014",
            "10/24/2014",
            "10/27/2014",
            "10/28/2014",
            "10/29/2014",
            "10/30/2014",
            "10/31/2014",
            "11/3/2014",
            "11/4/2014",
            "11/5/2014",
            "11/6/2014",
            "11/7/2014",
            "11/10/2014",
            "11/11/2014",
            "11/12/2014",
            "11/13/2014",
            "11/14/2014",
            "11/17/2014",
            "11/18/2014",
            "11/19/2014",
            "11/20/2014",
            "11/21/2014",
            "11/24/2014",
            "11/25/2014",
            "11/26/2014",
            "11/27/2014",
            "11/28/2014",
            "12/1/2014",
            "12/2/2014",
            "12/3/2014",
            "12/4/2014",
            "12/5/2014",
            "12/8/2014",
            "12/9/2014",
            "12/10/2014",
            "12/11/2014",
            "12/12/2014",
            "12/15/2014",
            "12/16/2014",
            "12/17/2014",
            "12/18/2014",
            "12/19/2014",
            "12/22/2014",
            "12/23/2014",
            "12/24/2014",
            "12/25/2014",
            "12/26/2014",
            "12/29/2014",
            "12/30/2014",
            "12/31/2014",
            "1/1/2015",
            "1/2/2015",
            "1/5/2015",
            "1/6/2015",
            "1/7/2015",
            "1/8/2015",
            "1/9/2015",
            "1/12/2015",
            "1/13/2015",
            "1/14/2015",
            "1/15/2015",
            "1/16/2015",
            "1/19/2015",
            "1/20/2015",
            "1/21/2015",
            "1/22/2015",
            "1/23/2015",
            "1/26/2015",
            "1/27/2015",
            "1/28/2015",
            "1/29/2015",
            "1/30/2015",
            "2/2/2015",
            "2/3/2015",
            "2/4/2015",
            "2/5/2015",
            "2/6/2015",
            "2/9/2015",
            "2/10/2015",
            "2/11/2015",
            "2/12/2015",
            "2/13/2015",
            "2/16/2015",
            "2/17/2015",
            "2/18/2015",
            "2/19/2015",
            "2/20/2015",
            "2/23/2015",
            "2/24/2015",
            "2/25/2015",
            "2/26/2015",
            "2/27/2015",
            "3/2/2015",
            "3/3/2015",
            "3/4/2015",
            "3/5/2015",
            "3/6/2015",
            "3/9/2015",
            "3/10/2015",
            "3/11/2015",
            "3/12/2015",
            "3/13/2015",
            "3/16/2015",
            "3/17/2015",
            "3/18/2015",
            "3/19/2015",
            "3/20/2015",
            "3/23/2015",
            "3/24/2015",
            "3/25/2015",
            "3/26/2015",
            "3/27/2015",
            "3/30/2015",
            "3/31/2015",
            "4/1/2015",
            "4/2/2015",
            "4/3/2015",
            "4/6/2015",
            "4/7/2015",
            "4/8/2015",
            "4/9/2015",
            "4/10/2015",
            "4/13/2015",
            "4/14/2015",
            "4/15/2015",
            "4/16/2015",
            "4/17/2015",
            "4/20/2015",
            "4/21/2015",
            "4/22/2015",
            "4/23/2015",
            "4/24/2015",
            "4/27/2015",
            "4/28/2015",
            "4/29/2015",
            "4/30/2015",
            "5/1/2015",
            "5/4/2015",
            "5/5/2015",
            "5/6/2015",
            "5/7/2015",
            "5/8/2015",
            "5/11/2015",
            "5/12/2015",
            "5/13/2015",
            "5/14/2015",
            "5/15/2015",
            "5/18/2015",
            "5/19/2015",
            "5/20/2015",
            "5/21/2015",
            "5/22/2015",
            "5/25/2015",
            "5/26/2015",
            "5/27/2015",
            "5/28/2015",
            "5/29/2015",
            "6/1/2015",
            "6/2/2015",
            "6/3/2015",
            "6/4/2015",
            "6/5/2015",
            "6/8/2015",
            "6/9/2015",
            "6/10/2015",
            "6/11/2015",
            "6/12/2015",
            "6/15/2015",
            "6/16/2015",
            "6/17/2015",
            "6/18/2015",
            "6/19/2015",
            "6/22/2015",
            "6/23/2015",
            "6/24/2015",
            "6/25/2015",
            "6/26/2015",
            "6/29/2015",
            "6/30/2015",
            "7/1/2015",
            "7/2/2015",
            "7/3/2015",
            "7/6/2015",
            "7/7/2015",
            "7/8/2015",
            "7/9/2015",
            "7/10/2015",
            "7/13/2015",
            "7/14/2015",
            "7/15/2015",
            "7/16/2015",
            "7/17/2015",
            "7/20/2015",
            "7/21/2015",
            "7/22/2015",
            "7/23/2015",
            "7/24/2015",
            "7/27/2015",
            "7/28/2015",
            "7/29/2015",
            "7/30/2015",
            "7/31/2015",
            "8/3/2015",
            "8/4/2015",
            "8/5/2015",
            "8/6/2015",
            "8/7/2015",
            "8/10/2015",
            "8/11/2015",
            "8/12/2015",
            "8/13/2015",
            "8/14/2015",
            "8/17/2015",
            "8/18/2015",
            "8/19/2015",
            "8/20/2015",
            "8/21/2015",
            "8/24/2015",
            "8/25/2015",
            "8/26/2015",
            "8/27/2015",
            "8/28/2015",
            "8/31/2015",
            "9/1/2015",
            "9/2/2015",
            "9/3/2015",
            "9/4/2015",
            "9/7/2015",
            "9/8/2015",
            "9/9/2015",
            "9/10/2015",
            "9/11/2015",
            "9/14/2015",
            "9/15/2015",
            "9/16/2015",
            "9/17/2015",
            "9/18/2015",
            "9/21/2015",
            "9/22/2015",
            "9/23/2015",
            "9/24/2015",
            "9/25/2015",
            "9/28/2015",
            "9/29/2015",
            "9/30/2015",
            "10/1/2015",
            "10/2/2015",
            "10/5/2015",
            "10/6/2015",
            "10/7/2015",
            "10/8/2015",
            "10/9/2015",
            "10/12/2015",
            "10/13/2015",
            "10/14/2015",
            "10/15/2015",
            "10/16/2015",
            "10/19/2015",
            "10/20/2015",
            "10/21/2015",
            "10/22/2015",
            "10/23/2015",
            "10/26/2015",
            "10/27/2015",
            "10/28/2015",
            "10/29/2015",
            "10/30/2015",
            "11/2/2015",
            "11/3/2015",
            "11/4/2015",
            "11/5/2015",
            "11/6/2015",
            "11/9/2015",
            "11/10/2015",
            "11/11/2015",
            "11/12/2015",
            "11/13/2015",
            "11/16/2015",
            "11/17/2015",
            "11/18/2015",
            "11/19/2015",
            "11/20/2015",
            "11/23/2015",
            "11/24/2015",
            "11/25/2015",
            "11/26/2015",
            "11/27/2015",
            "11/30/2015",
            "12/1/2015",
            "12/2/2015",
            "12/3/2015",
            "12/4/2015",
            "12/7/2015",
            "12/8/2015",
            "12/9/2015",
            "12/10/2015",
            "12/11/2015",
            "12/14/2015",
            "12/15/2015",
            "12/16/2015",
            "12/17/2015",
            "12/18/2015",
            "12/21/2015",
            "12/22/2015",
            "12/23/2015",
            "12/24/2015",
            "12/25/2015",
            "12/28/2015",
            "12/29/2015",
            "12/30/2015",
            "12/31/2015",
            "1/1/2016",
            "1/4/2016",
            "1/5/2016",
            "1/6/2016",
            "1/7/2016",
            "1/8/2016",
            "1/11/2016",
            "1/12/2016",
            "1/13/2016",
            "1/14/2016",
            "1/15/2016",
            "1/18/2016",
            "1/19/2016",
            "1/20/2016",
            "1/21/2016",
            "1/22/2016",
            "1/25/2016",
            "1/26/2016",
            "1/27/2016",
            "1/28/2016",
            "1/29/2016",
            "2/1/2016",
            "2/2/2016",
            "2/3/2016",
            "2/4/2016",
            "2/5/2016",
            "2/8/2016",
            "2/9/2016",
            "2/10/2016",
            "2/11/2016",
            "2/12/2016",
            "2/15/2016",
            "2/16/2016",
            "2/17/2016",
            "2/18/2016",
            "2/19/2016",
            "2/22/2016",
            "2/23/2016",
            "2/24/2016",
            "2/25/2016",
            "2/26/2016",
            "2/29/2016",
            "3/1/2016",
            "3/2/2016",
            "3/3/2016",
            "3/4/2016",
            "3/7/2016",
            "3/8/2016",
            "3/9/2016",
            "3/10/2016",
            "3/11/2016",
            "3/14/2016",
            "3/15/2016",
            "3/16/2016",
            "3/17/2016",
            "3/18/2016",
            "3/21/2016",
            "3/22/2016",
            "3/23/2016",
            "3/24/2016",
            "3/25/2016",
            "3/28/2016",
            "3/29/2016",
            "3/30/2016",
            "3/31/2016",
            "4/1/2016",
            "4/4/2016",
            "4/5/2016",
            "4/6/2016",
            "4/7/2016",
            "4/8/2016",
            "4/11/2016",
            "4/12/2016",
            "4/13/2016",
            "4/14/2016",
            "4/15/2016",
            "4/18/2016",
            "4/19/2016",
            "4/20/2016",
            "4/21/2016",
            "4/22/2016",
            "4/25/2016",
            "4/26/2016",
            "4/27/2016",
            "4/28/2016",
            "4/29/2016",
            "5/2/2016",
            "5/3/2016",
            "5/4/2016",
            "5/5/2016",
            "5/6/2016",
            "5/9/2016",
            "5/10/2016",
            "5/11/2016",
            "5/12/2016",
            "5/13/2016",
            "5/16/2016",
            "5/17/2016",
            "5/18/2016",
            "5/19/2016",
            "5/20/2016",
            "5/23/2016",
            "5/24/2016",
            "5/25/2016",
            "5/26/2016",
            "5/27/2016",
            "5/30/2016",
            "5/31/2016",
            "6/1/2016",
            "6/2/2016",
            "6/3/2016",
            "6/6/2016",
            "6/7/2016",
            "6/8/2016",
            "6/9/2016",
            "6/10/2016",
            "6/13/2016",
            "6/14/2016",
            "6/15/2016",
            "6/16/2016",
            "6/17/2016",
            "6/20/2016",
            "6/21/2016",
            "6/22/2016",
            "6/23/2016",
            "6/24/2016",
            "6/27/2016",
            "6/28/2016",
            "6/29/2016",
            "6/30/2016",
            "7/1/2016",
            "7/4/2016",
            "7/5/2016",
            "7/6/2016",
            "7/7/2016",
            "7/8/2016",
            "7/11/2016",
            "7/12/2016",
            "7/13/2016",
            "7/14/2016",
            "7/15/2016",
            "7/18/2016",
            "7/19/2016",
            "7/20/2016",
            "7/21/2016",
            "7/22/2016",
            "7/25/2016",
            "7/26/2016",
            "7/27/2016",
            "7/28/2016",
            "7/29/2016",
            "8/1/2016",
            "8/2/2016",
            "8/3/2016",
            "8/4/2016",
            "8/5/2016",
            "8/8/2016",
            "8/9/2016",
            "8/10/2016",
            "8/11/2016",
            "8/12/2016",
            "8/15/2016",
            "8/16/2016",
            "8/17/2016",
            "8/18/2016",
            "8/19/2016",
            "8/22/2016",
            "8/23/2016",
            "8/24/2016",
            "8/25/2016",
            "8/26/2016",
            "8/29/2016",
            "8/30/2016",
            "8/31/2016",
            "9/1/2016",
            "9/2/2016",
            "9/5/2016",
            "9/6/2016",
            "9/7/2016",
            "9/8/2016",
            "9/9/2016",
            "9/12/2016",
            "9/13/2016",
            "9/14/2016",
            "9/15/2016",
            "9/16/2016",
            "9/19/2016",
            "9/20/2016",
            "9/21/2016",
            "9/22/2016",
            "9/23/2016",
            "9/26/2016",
            "9/27/2016",
            "9/28/2016",
            "9/29/2016",
            "9/30/2016",
            "10/3/2016",
            "10/4/2016",
            "10/5/2016",
            "10/6/2016",
            "10/7/2016",
            "10/10/2016",
            "10/11/2016",
            "10/12/2016",
            "10/13/2016",
            "10/14/2016",
            "10/17/2016",
            "10/18/2016",
            "10/19/2016",
            "10/20/2016",
            "10/21/2016",
            "10/24/2016",
            "10/25/2016",
            "10/26/2016",
            "10/27/2016",
            "10/28/2016",
            "10/31/2016",
            "11/1/2016",
            "11/2/2016",
            "11/3/2016",
            "11/4/2016",
            "11/7/2016",
            "11/8/2016",
            "11/9/2016",
            "11/10/2016",
            "11/11/2016",
            "11/14/2016",
            "11/15/2016",
            "11/16/2016",
            "11/17/2016",
            "11/18/2016",
            "11/21/2016",
            "11/22/2016",
            "11/23/2016",
            "11/24/2016",
            "11/25/2016",
            "11/28/2016",
            "11/29/2016",
            "11/30/2016",
            "12/1/2016",
            "12/2/2016",
            "12/5/2016",
            "12/6/2016",
            "12/7/2016",
            "12/8/2016",
            "12/9/2016",
            "12/12/2016",
            "12/13/2016",
            "12/14/2016",
            "12/15/2016",
            "12/16/2016",
            "12/19/2016",
            "12/20/2016",
            "12/21/2016",
            "12/22/2016",
            "12/23/2016",
            "12/26/2016",
            "12/27/2016",
            "12/28/2016",
            "12/29/2016",
            "12/30/2016",
            "1/2/2017",
            "1/3/2017",
            "1/4/2017",
            "1/5/2017",
            "1/6/2017",
            "1/9/2017",
            "1/10/2017",
            "1/11/2017",
            "1/12/2017",
            "1/13/2017",
            "1/16/2017",
            "1/17/2017",
            "1/18/2017",
            "1/19/2017",
            "1/20/2017",
            "1/23/2017",
            "1/24/2017",
            "1/25/2017",
            "1/26/2017",
            "1/27/2017",
            "1/30/2017",
            "1/31/2017",
            "2/1/2017",
            "2/2/2017",
            "2/3/2017",
            "2/6/2017",
            "2/7/2017",
            "2/8/2017",
            "2/9/2017",
            "2/10/2017",
            "2/13/2017",
            "2/14/2017",
            "2/15/2017",
            "2/16/2017",
            "2/17/2017",
            "2/20/2017",
            "2/21/2017",
            "2/22/2017",
            "2/23/2017",
            "2/24/2017",
            "2/27/2017",
            "2/28/2017",
            "3/1/2017",
            "3/2/2017",
            "3/3/2017",
            "3/6/2017",
            "3/7/2017",
            "3/8/2017",
            "3/9/2017",
            "3/10/2017",
            "3/13/2017",
            "3/14/2017",
            "3/15/2017",
            "3/16/2017",
            "3/17/2017",
            "3/20/2017",
            "3/21/2017",
            "3/22/2017",
            "3/23/2017",
            "3/24/2017",
            "3/27/2017",
            "3/28/2017",
            "3/29/2017",
            "3/30/2017",
            "3/31/2017",
            "4/3/2017",
            "4/4/2017",
            "4/5/2017",
            "4/6/2017",
            "4/7/2017",
            "4/10/2017",
            "4/11/2017",
            "4/12/2017",
            "4/13/2017",
            "4/14/2017",
            "4/17/2017",
            "4/18/2017",
            "4/19/2017",
            "4/20/2017",
            "4/21/2017",
            "4/24/2017",
            "4/25/2017",
            "4/26/2017",
            "4/27/2017",
            "4/28/2017",
            "5/1/2017",
            "5/2/2017",
            "5/3/2017",
            "5/4/2017",
            "5/5/2017",
            "5/8/2017",
            "5/9/2017",
            "5/10/2017",
            "5/11/2017",
            "5/12/2017",
            "5/15/2017",
            "5/16/2017",
            "5/17/2017",
            "5/18/2017",
            "5/19/2017",
            "5/22/2017",
            "5/23/2017",
            "5/24/2017",
            "5/25/2017",
            "5/26/2017",
            "5/29/2017",
            "5/30/2017",
            "5/31/2017",
            "6/1/2017",
            "6/2/2017",
            "6/5/2017",
            "6/6/2017",
            "6/7/2017",
            "6/8/2017",
            "6/9/2017",
            "6/12/2017",
            "6/13/2017",
            "6/14/2017",
            "6/15/2017",
            "6/16/2017",
            "6/19/2017",
            "6/20/2017",
            "6/21/2017",
            "6/22/2017",
            "6/23/2017",
            "6/26/2017",
            "6/27/2017",
            "6/28/2017",
            "6/29/2017",
            "6/30/2017",
            "7/3/2017",
            "7/4/2017",
            "7/5/2017",
            "7/6/2017",
            "7/7/2017",
            "7/10/2017",
            "7/11/2017",
            "7/12/2017",
            "7/13/2017",
            "7/14/2017",
            "7/17/2017",
            "7/18/2017",
            "7/19/2017",
            "7/20/2017",
            "7/21/2017",
            "7/24/2017",
            "7/25/2017",
            "7/26/2017",
            "7/27/2017",
            "7/28/2017",
            "7/31/2017",
            "8/1/2017",
            "8/2/2017",
            "8/3/2017",
            "8/4/2017",
            "8/7/2017",
            "8/8/2017",
            "8/9/2017",
            "8/10/2017",
            "8/11/2017",
            "8/14/2017",
            "8/15/2017",
            "8/16/2017",
            "8/17/2017",
            "8/18/2017",
            "8/21/2017",
            "8/22/2017",
            "8/23/2017",
            "8/24/2017",
            "8/25/2017",
            "8/28/2017",
            "8/29/2017",
            "8/30/2017",
            "8/31/2017",
            "9/1/2017",
            "9/4/2017",
            "9/5/2017",
            "9/6/2017",
            "9/7/2017",
            "9/8/2017",
            "9/11/2017",
            "9/12/2017",
            "9/13/2017",
            "9/14/2017",
            "9/15/2017",
            "9/18/2017",
            "9/19/2017",
            "9/20/2017",
            "9/21/2017",
            "9/22/2017",
            "9/25/2017",
            "9/26/2017",
            "9/27/2017",
            "9/28/2017",
            "9/29/2017",
            "10/2/2017",
            "10/3/2017",
            "10/4/2017",
            "10/5/2017",
            "10/6/2017",
            "10/9/2017",
            "10/10/2017",
            "10/11/2017",
            "10/12/2017",
            "10/13/2017",
            "10/16/2017",
            "10/17/2017",
            "10/18/2017",
            "10/19/2017",
            "10/20/2017",
            "10/23/2017",
            "10/24/2017",
            "10/25/2017",
            "10/26/2017",
            "10/27/2017",
            "10/30/2017",
            "10/31/2017",
            "11/1/2017",
            "11/2/2017",
            "11/3/2017",
            "11/6/2017",
            "11/7/2017",
            "11/8/2017",
            "11/9/2017",
            "11/10/2017",
            "11/13/2017",
            "11/14/2017",
            "11/15/2017",
            "11/16/2017",
            "11/17/2017",
            "11/20/2017",
            "11/21/2017",
            "11/22/2017",
            "11/23/2017",
            "11/24/2017",
            "11/27/2017",
            "11/28/2017",
            "11/29/2017",
            "11/30/2017",
            "12/1/2017",
            "12/4/2017",
            "12/5/2017",
            "12/6/2017",
            "12/7/2017",
            "12/8/2017",
            "12/11/2017",
            "12/12/2017",
            "12/13/2017",
            "12/14/2017",
            "12/15/2017",
            "12/18/2017",
            "12/19/2017",
            "12/20/2017",
            "12/21/2017",
            "12/22/2017",
            "12/25/2017",
            "12/26/2017",
            "12/27/2017",
            "12/28/2017",
            "12/29/2017",
            "1/1/2018",
            "1/2/2018",
            "1/3/2018",
            "1/4/2018",
            "1/5/2018",
            "1/8/2018",
            "1/9/2018",
            "1/10/2018",
            "1/11/2018",
            "1/12/2018",
            "1/15/2018",
            "1/16/2018",
            "1/17/2018",
            "1/18/2018",
            "1/19/2018",
            "1/22/2018",
            "1/23/2018",
            "1/24/2018",
            "1/25/2018",
            "1/26/2018",
            "1/29/2018",
            "1/30/2018",
            "1/31/2018",
            "2/1/2018",
            "2/2/2018",
            "2/5/2018",
            "2/6/2018",
            "2/7/2018",
            "2/8/2018",
            "2/9/2018",
            "2/12/2018",
            "2/13/2018",
            "2/14/2018",
            "2/15/2018",
            "2/16/2018",
            "2/19/2018",
            "2/20/2018",
            "2/21/2018",
            "2/22/2018",
            "2/23/2018",
            "2/26/2018",
            "2/27/2018",
            "2/28/2018",
            "3/1/2018",
            "3/2/2018",
            "3/5/2018",
            "3/6/2018",
            "3/7/2018",
            "3/8/2018",
            "3/9/2018",
            "3/12/2018",
            "3/13/2018",
            "3/14/2018",
            "3/15/2018",
            "3/16/2018",
            "3/19/2018",
            "3/20/2018",
            "3/21/2018",
            "3/22/2018",
            "3/23/2018",
            "3/26/2018",
            "3/27/2018",
            "3/28/2018",
            "3/29/2018",
            "3/30/2018",
            "4/2/2018",
            "4/3/2018",
            "4/4/2018",
            "4/5/2018",
            "4/6/2018",
            "4/9/2018",
            "4/10/2018",
            "4/11/2018",
            "4/12/2018",
            "4/13/2018",
            "4/16/2018",
            "4/17/2018",
            "4/18/2018",
            "4/19/2018",
            "4/20/2018",
            "4/23/2018",
            "4/24/2018",
            "4/25/2018",
            "4/26/2018",
            "4/27/2018",
            "4/30/2018",
            "5/1/2018",
            "5/2/2018",
            "5/3/2018",
            "5/4/2018",
            "5/7/2018",
            "5/8/2018",
            "5/9/2018",
            "5/10/2018",
            "5/11/2018",
            "5/14/2018",
            "5/15/2018",
            "5/16/2018",
            "5/17/2018",
            "5/18/2018",
            "5/21/2018",
            "5/22/2018",
            "5/23/2018",
            "5/24/2018",
            "5/25/2018",
            "5/28/2018",
            "5/29/2018",
            "5/30/2018",
            "5/31/2018",
            "6/1/2018",
            "6/4/2018",
            "6/5/2018",
            "6/6/2018",
            "6/7/2018",
            "6/8/2018",
            "6/11/2018",
            "6/12/2018",
            "6/13/2018",
            "6/14/2018",
            "6/15/2018",
            "6/18/2018",
            "6/19/2018",
            "6/20/2018",
            "6/21/2018",
            "6/22/2018",
            "6/25/2018",
            "6/26/2018",
            "6/27/2018",
            "6/28/2018",
            "6/29/2018",
            "7/2/2018",
            "7/3/2018",
            "7/4/2018",
            "7/5/2018",
            "7/6/2018",
            "7/9/2018",
            "7/10/2018",
            "7/11/2018",
            "7/12/2018",
            "7/13/2018",
            "7/16/2018",
            "7/17/2018",
            "7/18/2018",
            "7/19/2018",
            "7/20/2018",
            "7/23/2018",
            "7/24/2018",
            "7/25/2018",
            "7/26/2018",
            "7/27/2018",
            "7/30/2018",
            "7/31/2018",
            "8/1/2018",
            "8/2/2018",
            "8/3/2018",
            "8/6/2018",
            "8/7/2018",
            "8/8/2018",
            "8/9/2018",
            "8/10/2018",
            "8/13/2018",
            "8/14/2018",
            "8/15/2018",
            "8/16/2018",
            "8/17/2018",
            "8/20/2018",
            "8/21/2018",
            "8/22/2018",
            "8/23/2018",
            "8/24/2018",
            "8/27/2018",
            "8/28/2018",
            "8/29/2018",
            "8/30/2018",
            "8/31/2018",
            "9/3/2018",
            "9/4/2018",
            "9/5/2018",
            "9/6/2018",
            "9/7/2018",
            "9/10/2018",
            "9/11/2018",
            "9/12/2018",
            "9/13/2018",
            "9/14/2018",
            "9/17/2018",
            "9/18/2018",
            "9/19/2018",
            "9/20/2018",
            "9/21/2018",
            "9/24/2018",
            "9/25/2018",
            "9/26/2018",
            "9/27/2018",
            "9/28/2018",
            "10/1/2018",
            "10/2/2018",
            "10/3/2018",
            "10/4/2018",
            "10/5/2018",
            "10/8/2018",
            "10/9/2018",
            "10/10/2018",
            "10/11/2018",
            "10/12/2018",
            "10/15/2018",
            "10/16/2018",
            "10/17/2018",
            "10/18/2018",
            "10/19/2018",
            "10/22/2018",
            "10/23/2018",
            "10/24/2018",
            "10/25/2018",
            "10/26/2018",
            "10/29/2018",
            "10/30/2018",
            "10/31/2018",
            "11/1/2018",
            "11/2/2018",
            "11/5/2018",
            "11/6/2018",
            "11/7/2018",
            "11/8/2018",
            "11/9/2018",
            "11/12/2018",
            "11/13/2018",
            "11/14/2018",
            "11/15/2018",
            "11/16/2018",
            "11/19/2018",
            "11/20/2018",
            "11/21/2018",
            "11/22/2018",
            "11/23/2018",
            "11/26/2018",
            "11/27/2018",
            "11/28/2018",
            "11/29/2018",
            "11/30/2018",
            "12/3/2018",
            "12/4/2018",
            "12/5/2018",
            "12/6/2018",
            "12/7/2018",
            "12/10/2018",
            "12/11/2018",
            "12/12/2018",
            "12/13/2018",
            "12/14/2018",
            "12/17/2018",
            "12/18/2018",
            "12/19/2018",
            "12/20/2018",
            "12/21/2018",
            "12/24/2018",
            "12/25/2018",
            "12/26/2018",
            "12/27/2018",
            "12/28/2018",
            "12/31/2018",
            "1/1/2019",
            "1/2/2019",
            "1/3/2019",
            "1/4/2019",
            "1/7/2019",
            "1/8/2019",
            "1/9/2019",
            "1/10/2019",
            "1/11/2019",
            "1/14/2019",
            "1/15/2019",
            "1/16/2019",
            "1/17/2019",
            "1/18/2019",
            "1/21/2019",
            "1/22/2019",
            "1/23/2019",
            "1/24/2019",
            "1/25/2019",
            "1/28/2019",
            "1/29/2019",
            "1/30/2019",
            "1/31/2019",
            "2/1/2019",
            "2/4/2019",
            "2/5/2019",
            "2/6/2019",
            "2/7/2019",
            "2/8/2019",
            "2/11/2019",
            "2/12/2019",
            "2/13/2019",
            "2/14/2019",
            "2/15/2019",
            "2/18/2019",
            "2/19/2019",
            "2/20/2019",
            "2/21/2019",
            "2/22/2019",
            "2/25/2019",
            "2/26/2019",
            "2/27/2019",
            "2/28/2019",
            "3/1/2019",
            "3/4/2019",
            "3/5/2019",
            "3/6/2019",
            "3/7/2019",
            "3/8/2019",
            "3/11/2019",
            "3/12/2019",
            "3/13/2019",
            "3/14/2019",
            "3/15/2019",
            "3/18/2019",
            "3/19/2019",
            "3/20/2019",
            "3/21/2019",
            "3/22/2019",
            "3/25/2019",
            "3/26/2019",
            "3/27/2019",
            "3/28/2019",
            "3/29/2019",
            "4/1/2019",
            "4/2/2019",
            "4/3/2019",
            "4/4/2019",
            "4/5/2019",
            "4/8/2019",
            "4/9/2019",
            "4/10/2019",
            "4/11/2019",
            "4/12/2019",
            "4/15/2019",
            "4/16/2019",
            "4/17/2019",
            "4/18/2019",
            "4/19/2019",
            "4/22/2019",
            "4/23/2019",
            "4/24/2019",
            "4/25/2019",
            "4/26/2019",
            "4/29/2019",
            "4/30/2019",
            "5/1/2019",
            "5/2/2019",
            "5/3/2019",
            "5/6/2019",
            "5/7/2019",
            "5/8/2019",
            "5/9/2019",
            "5/10/2019",
            "5/13/2019",
            "5/14/2019",
            "5/15/2019",
            "5/16/2019",
            "5/17/2019",
            "5/20/2019",
            "5/21/2019",
            "5/22/2019",
            "5/23/2019",
            "5/24/2019",
            "5/27/2019",
            "5/28/2019",
            "5/29/2019",
            "5/30/2019",
            "5/31/2019",
            "6/3/2019",
            "6/4/2019",
            "6/5/2019",
            "6/6/2019",
            "6/7/2019",
            "6/10/2019",
            "6/11/2019",
            "6/12/2019",
            "6/13/2019",
            "6/14/2019",
            "6/17/2019",
            "6/18/2019",
            "6/19/2019",
            "6/20/2019",
            "6/21/2019",
            "6/24/2019",
            "6/25/2019",
            "6/26/2019",
            "6/27/2019",
            "6/28/2019",
            "7/1/2019",
            "7/2/2019",
            "7/3/2019",
            "7/4/2019",
            "7/5/2019",
            "7/8/2019",
            "7/9/2019",
            "7/10/2019",
            "7/11/2019",
            "7/12/2019",
            "7/15/2019",
            "7/16/2019",
            "7/17/2019",
            "7/18/2019",
            "7/19/2019",
            "7/22/2019",
            "7/23/2019",
            "7/24/2019",
            "7/25/2019",
            "7/26/2019",
            "7/29/2019",
            "7/30/2019",
            "7/31/2019",
            "8/1/2019",
            "8/2/2019",
            "8/5/2019",
            "8/6/2019",
            "8/7/2019",
            "8/8/2019",
            "8/9/2019",
            "8/12/2019",
            "8/13/2019",
            "8/14/2019",
            "8/15/2019",
            "8/16/2019",
            "8/19/2019",
            "8/20/2019",
            "8/21/2019",
            "8/22/2019",
            "8/23/2019",
            "8/26/2019",
            "8/27/2019",
            "8/28/2019",
            "8/29/2019",
            "8/30/2019",
            "9/2/2019",
            "9/3/2019",
            "9/4/2019",
            "9/5/2019",
            "9/6/2019",
            "9/9/2019",
            "9/10/2019",
            "9/11/2019",
            "9/12/2019",
            "9/13/2019",
            "9/16/2019",
            "9/17/2019",
            "9/18/2019",
            "9/19/2019",
            "9/20/2019",
            "9/23/2019",
            "9/24/2019",
            "9/25/2019",
            "9/26/2019",
            "9/27/2019",
            "9/30/2019",
            "10/1/2019",
            "10/2/2019",
            "10/3/2019",
            "10/4/2019",
            "10/7/2019",
            "10/8/2019",
            "10/9/2019",
            "10/10/2019",
            "10/11/2019",
            "10/14/2019",
            "10/15/2019",
            "10/16/2019",
            "10/17/2019",
            "10/18/2019",
            "10/21/2019",
            "10/22/2019",
            "10/23/2019",
            "10/24/2019",
            "10/25/2019",
            "10/28/2019",
            "10/29/2019",
            "10/30/2019",
            "10/31/2019",
            "11/1/2019",
            "11/4/2019",
            "11/5/2019",
            "11/6/2019",
            "11/7/2019",
            "11/8/2019",
            "11/11/2019",
            "11/12/2019",
            "11/13/2019",
            "11/14/2019",
            "11/15/2019",
            "11/18/2019",
            "11/19/2019",
            "11/20/2019",
            "11/21/2019",
            "11/22/2019",
            "11/25/2019",
            "11/26/2019",
            "11/27/2019",
            "11/28/2019",
            "11/29/2019",
            "12/2/2019",
            "12/3/2019",
            "12/4/2019",
            "12/5/2019",
            "12/6/2019",
            "12/9/2019",
            "12/10/2019",
            "12/11/2019",
            "12/12/2019",
            "12/13/2019",
            "12/16/2019",
            "12/17/2019",
            "12/18/2019",
            "12/19/2019",
            "12/20/2019",
            "12/23/2019",
            "12/24/2019",
            "12/25/2019",
            "12/26/2019",
            "12/27/2019",
            "12/30/2019",
            "12/31/2019",
            "1/1/2020",
            "1/2/2020",
            "1/3/2020",
            "1/6/2020",
            "1/7/2020",
            "1/8/2020",
            "1/9/2020",
            "1/10/2020",
            "1/13/2020",
            "1/14/2020",
            "1/15/2020",
            "1/16/2020",
            "1/17/2020",
            "1/20/2020",
            "1/21/2020",
            "1/22/2020",
            "1/23/2020",
            "1/24/2020",
            "1/27/2020",
            "1/28/2020",
            "1/29/2020",
            "1/30/2020",
            "1/31/2020",
            "2/3/2020",
            "2/4/2020",
            "2/5/2020",
            "2/6/2020",
            "2/7/2020",
            "2/10/2020",
            "2/11/2020",
            "2/12/2020",
            "2/13/2020",
            "2/14/2020",
            "2/17/2020",
            "2/18/2020",
            "2/19/2020",
            "2/20/2020",
            "2/21/2020",
            "2/24/2020",
            "2/25/2020",
            "2/26/2020",
            "2/27/2020",
            "2/28/2020",
            "3/2/2020",
            "3/3/2020",
            "3/4/2020",
            "3/5/2020",
            "3/6/2020",
            "3/9/2020",
            "3/10/2020",
            "3/11/2020",
            "3/12/2020",
            "3/13/2020",
            "3/16/2020",
            "3/17/2020",
            "3/18/2020",
            "3/19/2020",
            "3/20/2020",
            "3/23/2020",
            "3/24/2020",
            "3/25/2020",
            "3/26/2020",
            "3/27/2020",
            "3/30/2020",
            "3/31/2020",
            "4/1/2020",
            "4/2/2020",
            "4/3/2020",
            "4/6/2020",
            "4/7/2020",
            "4/8/2020",
            "4/9/2020",
            "4/10/2020",
            "4/13/2020",
            "4/14/2020",
            "4/15/2020",
            "4/16/2020",
            "4/17/2020",
            "4/20/2020",
            "4/21/2020",
            "4/22/2020",
            "4/23/2020",
            "4/24/2020",
            "4/27/2020",
            "4/28/2020",
            "4/29/2020",
            "4/30/2020",
            "5/1/2020",
            "5/4/2020",
            "5/5/2020",
            "5/6/2020",
            "5/7/2020",
            "5/8/2020",
            "5/11/2020",
            "5/12/2020",
            "5/13/2020",
            "5/14/2020",
            "5/15/2020",
            "5/18/2020",
            "5/19/2020",
            "5/20/2020",
            "5/21/2020",
            "5/22/2020",
            "5/25/2020",
            "5/26/2020",
            "5/27/2020",
            "5/28/2020",
            "5/29/2020",
            "6/1/2020",
            "6/2/2020",
            "6/3/2020",
            "6/4/2020",
            "6/5/2020",
            "6/8/2020",
            "6/9/2020",
            "6/10/2020",
            "6/11/2020",
            "6/12/2020",
            "6/15/2020",
            "6/16/2020",
            "6/17/2020",
            "6/18/2020",
            "6/19/2020",
            "6/22/2020",
            "6/23/2020",
            "6/24/2020",
            "6/25/2020",
            "6/26/2020",
            "6/29/2020",
            "6/30/2020",
            "7/1/2020",
            "7/2/2020",
            "7/3/2020",
            "7/6/2020",
            "7/7/2020",
            "7/8/2020",
            "7/9/2020",
            "7/10/2020",
            "7/13/2020",
            "7/14/2020",
            "7/15/2020",
            "7/16/2020",
            "7/17/2020",
            "7/20/2020",
            "7/21/2020",
            "7/22/2020",
            "7/23/2020",
            "7/24/2020",
            "7/27/2020",
            "7/28/2020",
            "7/29/2020",
            "7/30/2020",
            "7/31/2020",
            "8/3/2020",
            "8/4/2020",
            "8/5/2020",
            "8/6/2020",
            "8/7/2020",
            "8/10/2020",
            "8/11/2020",
            "8/12/2020",
            "8/13/2020",
            "8/14/2020",
            "8/17/2020",
            "8/18/2020",
            "8/19/2020",
            "8/20/2020",
            "8/21/2020",
            "8/24/2020",
            "8/25/2020",
            "8/26/2020",
            "8/27/2020",
            "8/28/2020",
            "8/31/2020",
            "9/1/2020",
            "9/2/2020",
            "9/3/2020",
            "9/4/2020",
            "9/7/2020",
            "9/8/2020",
            "9/9/2020",
            "9/10/2020",
            "9/11/2020",
            "9/14/2020",
            "9/15/2020",
            "9/16/2020",
            "9/17/2020",
            "9/18/2020",
            "9/21/2020",
            "9/22/2020",
            "9/23/2020",
            "9/24/2020",
            "9/25/2020",
            "9/28/2020",
            "9/29/2020",
            "9/30/2020",
            "10/1/2020",
            "10/2/2020",
            "10/5/2020",
            "10/6/2020",
            "10/7/2020",
            "10/8/2020",
            "10/9/2020",
            "10/12/2020",
            "10/13/2020",
            "10/14/2020",
            "10/15/2020",
            "10/16/2020",
            "10/19/2020",
            "10/20/2020",
            "10/21/2020",
            "10/22/2020",
            "10/23/2020",
            "10/26/2020",
            "10/27/2020",
            "10/28/2020",
            "10/29/2020",
            "10/30/2020",
            "11/2/2020",
            "11/3/2020",
            "11/4/2020",
            "11/5/2020",
            "11/6/2020",
            "11/9/2020",
            "11/10/2020",
            "11/11/2020",
            "11/12/2020",
            "11/13/2020",
            "11/16/2020",
            "11/17/2020",
            "11/18/2020",
            "11/19/2020",
            "11/20/2020",
            "11/23/2020",
            "11/24/2020",
            "11/25/2020",
            "11/26/2020",
            "11/27/2020",
            "11/30/2020",
            "12/1/2020",
            "12/2/2020",
            "12/3/2020",
            "12/4/2020",
            "12/7/2020",
            "12/8/2020",
            "12/9/2020",
            "12/10/2020",
            "12/11/2020",
            "12/14/2020",
            "12/15/2020",
            "12/16/2020",
            "12/17/2020",
            "12/18/2020",
            "12/21/2020",
            "12/22/2020",
            "12/23/2020",
            "12/24/2020",
            "12/25/2020",
            "12/28/2020",
            "12/29/2020",
            "12/30/2020",
            "12/31/2020",
            "1/1/2021",
            "1/4/2021",
            "1/5/2021",
            "1/6/2021",
            "1/7/2021",
            "1/8/2021",
            "1/11/2021",
            "1/12/2021",
            "1/13/2021",
            "1/14/2021",
            "1/15/2021",
            "1/18/2021",
            "1/19/2021",
            "1/20/2021",
            "1/21/2021",
            "1/22/2021",
            "1/25/2021",
            "1/26/2021",
            "1/27/2021",
            "1/28/2021",
            "1/29/2021",
            "2/1/2021",
            "2/2/2021",
            "2/3/2021",
            "2/4/2021",
            "2/5/2021",
            "2/8/2021",
            "2/9/2021",
            "2/10/2021",
            "2/11/2021",
            "2/12/2021",
            "2/15/2021",
            "2/16/2021",
            "2/17/2021",
            "2/18/2021",
            "2/19/2021",
            "2/22/2021",
            "2/23/2021",
            "2/24/2021",
            "2/25/2021",
            "2/26/2021",
            "3/1/2021",
            "3/2/2021",
            "3/3/2021",
            "3/4/2021",
            "3/5/2021",
            "3/8/2021",
            "3/9/2021",
            "3/10/2021",
            "3/11/2021",
            "3/12/2021",
            "3/15/2021",
            "3/16/2021",
            "3/17/2021",
            "3/18/2021",
            "3/19/2021",
            "3/22/2021",
            "3/23/2021",
            "3/24/2021",
            "3/25/2021",
            "3/26/2021",
            "3/29/2021",
            "3/30/2021",
            "3/31/2021",
            "4/1/2021",
            "4/2/2021",
            "4/5/2021",
            "4/6/2021",
            "4/7/2021",
            "4/8/2021",
            "4/9/2021",
            "4/12/2021",
            "4/13/2021",
            "4/14/2021",
            "4/15/2021",
            "4/16/2021",
            "4/19/2021",
            "4/20/2021",
            "4/21/2021",
            "4/22/2021",
            "4/23/2021",
            "4/26/2021",
            "4/27/2021",
            "4/28/2021",
            "4/29/2021",
            "4/30/2021",
            "5/3/2021",
            "5/4/2021",
            "5/5/2021",
            "5/6/2021",
            "5/7/2021",
            "5/10/2021",
            "5/11/2021",
            "5/12/2021",
            "5/13/2021",
            "5/14/2021",
            "5/17/2021",
            "5/18/2021",
            "5/19/2021",
            "5/20/2021",
            "5/21/2021",
            "5/24/2021",
            "5/25/2021",
            "5/26/2021",
            "5/27/2021",
            "5/28/2021",
            "5/31/2021",
            "6/1/2021",
            "6/2/2021",
            "6/3/2021",
            "6/4/2021",
            "6/7/2021",
            "6/8/2021",
            "6/9/2021",
            "6/10/2021",
            "6/11/2021",
            "6/14/2021",
            "6/15/2021",
            "6/16/2021",
            "6/17/2021",
            "6/18/2021",
            "6/21/2021",
            "6/22/2021",
            "6/23/2021",
            "6/24/2021",
            "6/25/2021",
            "6/28/2021",
            "6/29/2021",
            "6/30/2021",
            "7/1/2021",
            "7/2/2021",
            "7/5/2021",
            "7/6/2021",
            "7/7/2021",
            "7/8/2021",
            "7/9/2021",
            "7/12/2021",
            "7/13/2021",
            "7/14/2021",
            "7/15/2021",
            "7/16/2021",
            "7/19/2021",
            "7/20/2021",
            "7/21/2021",
            "7/22/2021",
            "7/23/2021",
            "7/26/2021",
            "7/27/2021",
            "7/28/2021",
            "7/29/2021",
            "7/30/2021",
            "8/2/2021",
            "8/3/2021",
            "8/4/2021",
            "8/5/2021",
            "8/6/2021",
            "8/9/2021",
            "8/10/2021",
            "8/11/2021",
            "8/12/2021",
            "8/13/2021",
            "8/16/2021",
            "8/17/2021",
            "8/18/2021",
            "8/19/2021",
            "8/20/2021",
            "8/23/2021",
            "8/24/2021",
            "8/25/2021",
            "8/26/2021",
            "8/27/2021",
            "8/30/2021",
            "8/31/2021",
            "9/1/2021",
            "9/2/2021",
            "9/3/2021",
            "9/6/2021",
            "9/7/2021",
            "9/8/2021",
            "9/9/2021",
            "9/10/2021",
            "9/13/2021",
            "9/14/2021",
            "9/15/2021",
            "9/16/2021",
            "9/17/2021",
            "9/20/2021",
            "9/21/2021",
            "9/22/2021",
            "9/23/2021",
            "9/24/2021",
            "9/27/2021",
            "9/28/2021",
            "9/29/2021",
            "9/30/2021",
            "10/1/2021",
            "10/4/2021",
            "10/5/2021",
            "10/6/2021",
            "10/7/2021",
            "10/8/2021",
            "10/11/2021",
            "10/12/2021",
            "10/13/2021",
            "10/14/2021",
            "10/15/2021",
            "10/18/2021",
            "10/19/2021",
            "10/20/2021",
            "10/21/2021",
            "10/22/2021",
            "10/25/2021",
            "10/26/2021",
            "10/27/2021",
            "10/28/2021",
            "10/29/2021",
            "11/1/2021",
            "11/2/2021",
            "11/3/2021",
            "11/4/2021",
            "11/5/2021",
            "11/8/2021",
            "11/9/2021",
            "11/10/2021",
            "11/11/2021",
            "11/12/2021",
            "11/15/2021",
            "11/16/2021",
            "11/17/2021",
            "11/18/2021",
            "11/19/2021",
            "11/22/2021",
            "11/23/2021",
            "11/24/2021",
            "11/25/2021",
            "11/26/2021",
            "11/29/2021",
            "11/30/2021",
            "12/1/2021",
            "12/2/2021",
            "12/3/2021",
            "12/6/2021",
            "12/7/2021",
            "12/8/2021",
            "12/9/2021",
            "12/10/2021",
            "12/13/2021",
            "12/14/2021",
            "12/15/2021",
            "12/16/2021",
            "12/17/2021",
            "12/20/2021",
            "12/21/2021",
            "12/22/2021",
            "12/23/2021",
            "12/24/2021",
            "12/27/2021",
            "12/28/2021",
            "12/29/2021",
            "12/30/2021",
            "12/31/2021",
            "1/3/2022",
            "1/4/2022",
            "1/5/2022",
            "1/6/2022",
            "1/7/2022",
            "1/10/2022",
            "1/11/2022",
            "1/12/2022",
            "1/13/2022",
            "1/14/2022",
            "1/17/2022",
            "1/18/2022",
            "1/19/2022",
            "1/20/2022",
            "1/21/2022",
            "1/24/2022",
            "1/25/2022",
            "1/26/2022",
            "1/27/2022",
            "1/28/2022",
            "1/31/2022",
            "2/1/2022",
            "2/2/2022",
            "2/3/2022",
            "2/4/2022",
            "2/7/2022",
            "2/8/2022",
            "2/9/2022",
            "2/10/2022",
            "2/11/2022",
            "2/14/2022",
            "2/15/2022",
            "2/16/2022",
            "2/17/2022",
            "2/18/2022",
            "2/21/2022",
            "2/22/2022",
            "2/23/2022",
            "2/24/2022",
            "2/25/2022",
            "2/28/2022",
            "3/1/2022",
            "3/2/2022",
            "3/3/2022",
            "3/4/2022",
            "3/7/2022",
            "3/8/2022",
            "3/9/2022",
            "3/10/2022",
            "3/11/2022",
            "3/14/2022",
            "3/15/2022",
            "3/16/2022",
            "3/17/2022",
            "3/18/2022",
            "3/21/2022",
            "3/22/2022",
            "3/23/2022",
            "3/24/2022",
            "3/25/2022",
            "3/28/2022",
            "3/29/2022",
            "3/30/2022",
            "3/31/2022",
            "4/1/2022",
            "4/4/2022",
            "4/5/2022",
            "4/6/2022",
            "4/7/2022",
            "4/8/2022",
            "4/11/2022",
            "4/12/2022",
            "4/13/2022",
            "4/14/2022",
            "4/15/2022",
            "4/18/2022",
            "4/19/2022",
            "4/20/2022",
            "4/21/2022",
            "4/22/2022",
            "4/25/2022",
            "4/26/2022",
            "4/27/2022",
            "4/28/2022",
            "4/29/2022",
            "5/2/2022",
            "5/3/2022",
            "5/4/2022",
            "5/5/2022",
            "5/6/2022",
            "5/9/2022",
            "5/10/2022",
            "5/11/2022",
            "5/12/2022",
            "5/13/2022",
            "5/16/2022",
            "5/17/2022",
            "5/18/2022",
            "5/19/2022",
            "5/20/2022",
            "5/23/2022",
            "5/24/2022",
            "5/25/2022",
            "5/26/2022",
            "5/27/2022",
            "5/30/2022",
            "5/31/2022",
            "6/1/2022",
            "6/2/2022",
            "6/3/2022",
            "6/6/2022",
            "6/7/2022",
            "6/8/2022",
            "6/9/2022",
            "6/10/2022",
            "6/13/2022",
            "6/14/2022",
            "6/15/2022",
            "6/16/2022",
            "6/17/2022",
            "6/20/2022",
            "6/21/2022",
            "6/22/2022",
            "6/23/2022",
            "6/24/2022",
            "6/27/2022",
            "6/28/2022",
            "6/29/2022",
            "6/30/2022",
            "7/1/2022",
            "7/4/2022",
            "7/5/2022",
            "7/6/2022",
            "7/7/2022",
            "7/8/2022",
            "7/11/2022",
            "7/12/2022",
            "7/13/2022",
            "7/14/2022",
            "7/15/2022",
            "7/18/2022",
            "7/19/2022",
            "7/20/2022",
            "7/21/2022",
            "7/22/2022",
            "7/25/2022",
            "7/26/2022",
            "7/27/2022",
            "7/28/2022",
            "7/29/2022",
            "8/1/2022",
            "8/2/2022",
            "8/3/2022",
            "8/4/2022",
            "8/5/2022",
            "8/8/2022",
            "8/9/2022",
            "8/10/2022",
            "8/11/2022",
            "8/12/2022",
            "8/15/2022",
            "8/16/2022",
            "8/17/2022",
            "8/18/2022",
            "8/19/2022",
            "8/22/2022",
            "8/23/2022",
            "8/24/2022",
            "8/25/2022",
            "8/26/2022",
            "8/29/2022",
            "8/30/2022",
            "8/31/2022",
            "9/1/2022",
            "9/2/2022",
            "9/5/2022",
            "9/6/2022",
            "9/7/2022",
            "9/8/2022",
            "9/9/2022",
            "9/12/2022",
            "9/13/2022",
            "9/14/2022",
            "9/15/2022",
            "9/16/2022",
            "9/19/2022",
            "9/20/2022",
            "9/21/2022",
            "9/22/2022",
            "9/23/2022",
            "9/26/2022",
            "9/27/2022",
            "9/28/2022",
            "9/29/2022",
            "9/30/2022",
            "10/3/2022",
            "10/4/2022",
            "10/5/2022",
            "10/6/2022",
            "10/7/2022",
            "10/10/2022",
            "10/11/2022",
            "10/12/2022",
            "10/13/2022",
            "10/14/2022",
            "10/17/2022",
            "10/18/2022",
            "10/19/2022",
            "10/20/2022",
            "10/21/2022",
            "10/24/2022",
            "10/25/2022",
            "10/26/2022",
            "10/27/2022",
            "10/28/2022",
            "10/31/2022",
            "11/1/2022",
            "11/2/2022",
            "11/3/2022",
            "11/4/2022",
            "11/7/2022",
            "11/8/2022",
            "11/9/2022",
            "11/10/2022",
            "11/11/2022",
            "11/14/2022",
            "11/15/2022",
            "11/16/2022",
            "11/17/2022",
            "11/18/2022",
            "11/21/2022",
            "11/22/2022",
            "11/23/2022",
            "11/24/2022",
            "11/25/2022",
            "11/28/2022",
            "11/29/2022",
            "11/30/2022",
            "12/1/2022",
            "12/2/2022",
            "12/5/2022",
            "12/6/2022",
            "12/7/2022",
            "12/8/2022",
            "12/9/2022",
            "12/12/2022",
            "12/13/2022",
            "12/14/2022",
            "12/15/2022",
            "12/16/2022",
            "12/19/2022",
            "12/20/2022",
            "12/21/2022",
            "12/22/2022",
            "12/23/2022",
            "12/26/2022",
            "12/27/2022",
            "12/28/2022",
            "12/29/2022",
            "12/30/2022",
            "1/2/2023",
            "1/3/2023",
            "1/4/2023",
            "1/5/2023",
            "1/6/2023",
            "1/9/2023",
            "1/10/2023",
            "1/11/2023",
            "1/12/2023",
            "1/13/2023",
            "1/16/2023",
            "1/17/2023",
            "1/18/2023",
            "1/19/2023",
            "1/20/2023",
            "1/23/2023",
            "1/24/2023",
            "1/25/2023",
            "1/26/2023",
            "1/27/2023",
            "1/30/2023",
            "1/31/2023",
            "2/1/2023",
            "2/2/2023",
            "2/3/2023",
            "2/6/2023",
            "2/7/2023",
            "2/8/2023",
            "2/9/2023",
            "2/10/2023",
            "2/13/2023",
            "2/14/2023",
            "2/15/2023",
            "2/16/2023",
            "2/17/2023",
            "2/20/2023",
            "2/21/2023",
            "2/22/2023",
            "2/23/2023",
            "2/24/2023",
            "2/27/2023",
            "2/28/2023",
            "3/1/2023",
            "3/2/2023",
            "3/3/2023",
            "3/6/2023",
            "3/7/2023",
            "3/8/2023",
            "3/9/2023",
            "3/10/2023",
            "3/13/2023",
            "3/14/2023",
            "3/15/2023",
            "3/16/2023",
            "3/17/2023",
            "3/20/2023",
            "3/21/2023",
            "3/22/2023",
            "3/23/2023",
            "3/24/2023",
            "3/27/2023",
            "3/28/2023",
            "3/29/2023",
            "3/30/2023",
            "3/31/2023",
            "4/3/2023",
            "4/4/2023",
            "4/5/2023",
            "4/6/2023",
            "4/7/2023",
            "4/10/2023",
            "4/11/2023",
            "4/12/2023",
            "4/13/2023",
            "4/14/2023",
            "4/17/2023",
            "4/18/2023",
            "4/19/2023",
            "4/20/2023",
            "4/21/2023",
            "4/24/2023",
            "4/25/2023",
            "4/26/2023",
            "4/27/2023",
            "4/28/2023",
            "5/1/2023",
            "5/2/2023",
            "5/3/2023",
            "5/4/2023",
            "5/5/2023",
            "5/8/2023",
            "5/9/2023",
            "5/10/2023",
            "5/11/2023",
            "5/12/2023",
            "5/15/2023",
            "5/16/2023",
            "5/17/2023",
            "5/18/2023",
            "5/19/2023",
            "5/22/2023",
            "5/23/2023",
            "5/24/2023",
            "5/25/2023",
            "5/26/2023",
            "5/29/2023",
            "5/30/2023",
            "5/31/2023",
            "6/1/2023",
            "6/2/2023",
            "6/5/2023",
            "6/6/2023",
            "6/7/2023",
            "6/8/2023",
            "6/9/2023",
            "6/12/2023",
            "6/13/2023",
            "6/14/2023",
            "6/15/2023",
            "6/16/2023",
            "6/19/2023",
            "6/20/2023",
            "6/21/2023",
            "6/22/2023",
            "6/23/2023",
            "6/26/2023",
            "6/27/2023",
            "6/28/2023",
            "6/29/2023",
            "6/30/2023",
            "7/3/2023",
            "7/4/2023",
            "7/5/2023",
            "7/6/2023",
            "7/7/2023",
            "7/10/2023",
            "7/11/2023",
            "7/12/2023",
            "7/13/2023",
            "7/14/2023",
            "7/17/2023",
            "7/18/2023",
            "7/19/2023",
            "7/20/2023",
            "7/21/2023",
            "7/24/2023",
            "7/25/2023",
            "7/26/2023",
            "7/27/2023",
            "7/28/2023",
            "7/31/2023",
            "8/1/2023",
            "8/2/2023",
            "8/3/2023",
            "8/4/2023",
            "8/7/2023",
            "8/8/2023",
            "8/9/2023",
            "8/10/2023",
            "8/11/2023",
            "8/14/2023",
            "8/15/2023",
            "8/16/2023",
            "8/17/2023",
            "8/18/2023",
            "8/21/2023",
            "8/22/2023",
            "8/23/2023",
            "8/24/2023",
            "8/25/2023",
            "8/28/2023",
            "8/29/2023",
            "8/30/2023",
            "8/31/2023",
            "9/1/2023",
            "9/4/2023",
            "9/5/2023",
            "9/6/2023",
            "9/7/2023",
            "9/8/2023",
            "9/11/2023",
            "9/12/2023",
            "9/13/2023",
            "9/14/2023",
            "9/15/2023",
            "9/18/2023",
            "9/19/2023",
            "9/20/2023",
            "9/21/2023",
            "9/22/2023",
            "9/25/2023",
            "9/26/2023",
            "9/27/2023",
            "9/28/2023",
            "9/29/2023"
        ],
        title: {
            text: null
        },
        tickInterval: 2500
    },
    yAxis: [
        {
            title: {
                text: "%"
            },
            opposite: false,
            max: 100
        }
    ],
    series: [
        {
            name: "",
            type: "column",
            yAxis: 0,
            data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                79,
                80,
                81,
                82,
                83,
                84,
                84,
                85,
                86,
                87,
                87,
                88,
                89,
                90,
                91,
                92,
                93,
                93,
                94,
                95,
                96,
                97,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                88,
                89,
                90,
                91,
                92,
                92,
                92,
                92,
                92,
                92,
                92,
                92,
                92,
                92,
                92,
                91,
                91,
                91,
                91,
                91,
                91,
                91,
                91,
                91,
                90,
                90,
                90,
                90,
                90,
                90,
                90,
                90,
                90,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                97,
                96,
                96,
                95,
                94,
                93,
                92,
                91,
                90,
                89,
                88,
                87,
                86,
                85,
                84,
                84,
                83,
                82,
                82,
                81,
                81,
                80,
                82,
                82,
                81,
                81,
                77,
                76,
                76,
                75,
                74,
                74,
                73,
                73,
                72,
                71,
                71,
                70,
                69,
                68,
                68,
                67,
                67,
                67,
                66,
                65,
                65,
                64,
                64,
                64,
                64,
                63,
                63,
                63,
                63,
                63,
                64,
                64,
                64,
                63,
                63,
                63,
                63,
                64,
                64,
                65,
                65,
                66,
                66,
                67,
                68,
                69,
                70,
                71,
                72,
                73,
                75,
                75,
                77,
                78,
                79,
                80,
                81,
                82,
                83,
                84,
                85,
                95,
                96,
                97,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                95,
                92,
                89,
                86,
                84,
                81,
                79,
                77,
                74,
                72,
                70,
                67,
                65,
                62,
                60,
                57,
                55,
                53,
                51,
                49,
                47,
                45,
                44,
                42,
                40,
                38,
                36,
                34,
                32,
                31,
                29,
                27,
                25,
                23,
                20,
                18,
                16,
                14,
                12,
                11,
                9,
                7,
                5,
                3,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                30,
                30,
                30,
                29,
                29,
                29,
                29,
                28,
                28,
                27,
                27,
                27,
                26,
                26,
                26,
                25,
                25,
                24,
                24,
                23,
                23,
                22,
                22,
                21,
                20,
                19,
                18,
                18,
                17,
                16,
                15,
                14,
                13,
                11,
                10,
                9,
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                3,
                4,
                4,
                5,
                7,
                8,
                9,
                9,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                26,
                28,
                29,
                31,
                32,
                34,
                36,
                37,
                38,
                40,
                41,
                42,
                44,
                45,
                46,
                48,
                49,
                50,
                52,
                53,
                54,
                56,
                57,
                58,
                59,
                60,
                61,
                61,
                63,
                63,
                65,
                66,
                67,
                68,
                70,
                71,
                73,
                74,
                76,
                77,
                78,
                79,
                80,
                80,
                81,
                82,
                83,
                84,
                84,
                85,
                86,
                87,
                93,
                93,
                94,
                94,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                95,
                93,
                91,
                88,
                85,
                83,
                80,
                77,
                74,
                72,
                69,
                66,
                63,
                60,
                57,
                55,
                52,
                49,
                46,
                44,
                41,
                38,
                36,
                34,
                31,
                29,
                27,
                24,
                22,
                20,
                18,
                16,
                14,
                12,
                9,
                7,
                4,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                4,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                21,
                22,
                23,
                23,
                24,
                25,
                11,
                12,
                13,
                22,
                23,
                24,
                26,
                27,
                28,
                30,
                31,
                32,
                34,
                35,
                35,
                36,
                37,
                38,
                39,
                41,
                42,
                43,
                44,
                44,
                45,
                46,
                47,
                48,
                49,
                50,
                51,
                52,
                54,
                55,
                56,
                57,
                58,
                59,
                59,
                60,
                61,
                61,
                62,
                62,
                62,
                63,
                64,
                64,
                65,
                67,
                67,
                68,
                69,
                69,
                70,
                71,
                71,
                72,
                72,
                73,
                73,
                74,
                75,
                75,
                75,
                76,
                85,
                86,
                86,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                97,
                96,
                95,
                93,
                92,
                90,
                89,
                87,
                86,
                85,
                83,
                82,
                81,
                80,
                78,
                77,
                75,
                74,
                72,
                71,
                69,
                74,
                72,
                71,
                59,
                57,
                56,
                54,
                53,
                51,
                50,
                49,
                47,
                46,
                45,
                43,
                42,
                40,
                39,
                38,
                36,
                35,
                34,
                32,
                31,
                29,
                28,
                26,
                25,
                23,
                22,
                20,
                19,
                17,
                16,
                15,
                13,
                12,
                11,
                10,
                9,
                8,
                7,
                6,
                4,
                3,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                8,
                8,
                9,
                9,
                10,
                10,
                10,
                11,
                11,
                12,
                13,
                13,
                14,
                14,
                14,
                15,
                15,
                15,
                15,
                16,
                16,
                16,
                16,
                17,
                17,
                17,
                18,
                18,
                18,
                18,
                19,
                19,
                20,
                20,
                21,
                21,
                22,
                22,
                23,
                23,
                23,
                23,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                25,
                25,
                25,
                26,
                26,
                26,
                27,
                27,
                28,
                28,
                22,
                22,
                22,
                21,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                32,
                32,
                31,
                31,
                31,
                30,
                30,
                29,
                29,
                28,
                28,
                28,
                27,
                27,
                27,
                27,
                26,
                26,
                26,
                25,
                25,
                24,
                23,
                23,
                22,
                21,
                20,
                20,
                19,
                18,
                18,
                17,
                14,
                13,
                13,
                12,
                32,
                31,
                30,
                29,
                29,
                28,
                27,
                26,
                26,
                25,
                25,
                25,
                25,
                24,
                24,
                23,
                23,
                23,
                22,
                22,
                21,
                21,
                20,
                20,
                19,
                19,
                18,
                18,
                18,
                18,
                18,
                18,
                18,
                18,
                18,
                18,
                18,
                18,
                18,
                17,
                17,
                16,
                16,
                16,
                16,
                15,
                15,
                15,
                15,
                16,
                16,
                16,
                16,
                16,
                16,
                15,
                15,
                15,
                14,
                14,
                14,
                12,
                12,
                12,
                12,
                13,
                13,
                13,
                14,
                14,
                14,
                14,
                14,
                13,
                13,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                11,
                11,
                11,
                10,
                10,
                10,
                10,
                10,
                10,
                11,
                11,
                11,
                11,
                12,
                12,
                12,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                14,
                14,
                14,
                15,
                15,
                16,
                17,
                17,
                17,
                17,
                7,
                7,
                7,
                8,
                5,
                5,
                5,
                6,
                6,
                6,
                7,
                7,
                8,
                8,
                9,
                9,
                10,
                10,
                10,
                11,
                11,
                12,
                13,
                13,
                14,
                14,
                15,
                15,
                16,
                16,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                14,
                14,
                14,
                13,
                13,
                13,
                13,
                13,
                13,
                12,
                24,
                24,
                24,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                29,
                29,
                29,
                29,
                29,
                29,
                28,
                28,
                28,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                28,
                28,
                28,
                27,
                27,
                26,
                26,
                26,
                26,
                25,
                25,
                25,
                24,
                24,
                25,
                25,
                25,
                25,
                25,
                25,
                24,
                24,
                24,
                24,
                24,
                24,
                25,
                25,
                25,
                25,
                26,
                32,
                32,
                33,
                33,
                36,
                36,
                36,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                36,
                36,
                35,
                35,
                34,
                34,
                34,
                33,
                33,
                33,
                33,
                32,
                32,
                32,
                32,
                32,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                34,
                34,
                35,
                35,
                36,
                36,
                36,
                36,
                35,
                35,
                35,
                35,
                36,
                36,
                45,
                46,
                46,
                46,
                48,
                48,
                48,
                48,
                48,
                48,
                48,
                49,
                49,
                48,
                48,
                48,
                48,
                48,
                49,
                49,
                50,
                51,
                52,
                52,
                52,
                53,
                53,
                53,
                54,
                54,
                54,
                55,
                55,
                55,
                55,
                56,
                56,
                56,
                56,
                56,
                57,
                57,
                58,
                59,
                59,
                59,
                60,
                60,
                60,
                61,
                61,
                61,
                61,
                61,
                61,
                61,
                62,
                62,
                62,
                63,
                63,
                62,
                62,
                61,
                60,
                59,
                66,
                65,
                65,
                75,
                74,
                74,
                74,
                73,
                72,
                72,
                71,
                71,
                71,
                71,
                71,
                71,
                70,
                70,
                69,
                69,
                69,
                69,
                68,
                68,
                68,
                68,
                67,
                66,
                66,
                65,
                64,
                64,
                63,
                63,
                62,
                62,
                61,
                61,
                60,
                60,
                59,
                59,
                59,
                58,
                57,
                57,
                56,
                56,
                55,
                54,
                54,
                53,
                52,
                52,
                51,
                50,
                50,
                49,
                49,
                48,
                48,
                48,
                48,
                48,
                48,
                48,
                47,
                46,
                46,
                33,
                33,
                33,
                33,
                33,
                33,
                32,
                32,
                31,
                30,
                30,
                29,
                29,
                29,
                29,
                28,
                27,
                27,
                26,
                26,
                25,
                25,
                25,
                25,
                25,
                24,
                24,
                23,
                22,
                22,
                21,
                20,
                19,
                19,
                18,
                18,
                17,
                17,
                17,
                16,
                15,
                15,
                14,
                14,
                13,
                13,
                13,
                12,
                12,
                11,
                10,
                10,
                9,
                9,
                8,
                7,
                6,
                5,
                5,
                4,
                4,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                3,
                3,
                3,
                4,
                4,
                4,
                4,
                5,
                5,
                5,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                7,
                7,
                7,
                8,
                9,
                9,
                10,
                10,
                11,
                11,
                11,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                3,
                3,
                4,
                4,
                5,
                5,
                6,
                6,
                6,
                7,
                7,
                8,
                9,
                9,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                11,
                11,
                11,
                12,
                12,
                12,
                12,
                13,
                13,
                14,
                14,
                15,
                16,
                16,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                18,
                18,
                19,
                20,
                28,
                28,
                28,
                31,
                31,
                31,
                32,
                32,
                32,
                32,
                31,
                31,
                31,
                31,
                31,
                31,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                31,
                31,
                31,
                31,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                31,
                31,
                31,
                34,
                34,
                34,
                41,
                41,
                41,
                41,
                41,
                42,
                42,
                42,
                42,
                41,
                41,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                39,
                39,
                38,
                38,
                37,
                37,
                37,
                38,
                38,
                38,
                39,
                39,
                39,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                40,
                41,
                41,
                41,
                41,
                41,
                41,
                41,
                41,
                41,
                42,
                42,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                46,
                46,
                46,
                46,
                46,
                47,
                47,
                48,
                48,
                49,
                50,
                50,
                51,
                51,
                51,
                51,
                51,
                51,
                51,
                52,
                52,
                53,
                53,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                55,
                55,
                55,
                55,
                55,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                54,
                62,
                61,
                61,
                61,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                74,
                75,
                75,
                75,
                75,
                75,
                75,
                75,
                75,
                75,
                75,
                74,
                74,
                73,
                73,
                73,
                73,
                73,
                73,
                73,
                73,
                73,
                73,
                72,
                72,
                72,
                72,
                72,
                72,
                72,
                72,
                72,
                71,
                71,
                71,
                71,
                72,
                72,
                66,
                66,
                66,
                59,
                59,
                59,
                59,
                59,
                59,
                59,
                58,
                58,
                58,
                58,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                57,
                57,
                58,
                58,
                58,
                58,
                58,
                58,
                58,
                58,
                58,
                57,
                57,
                57,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                56,
                55,
                55,
                55,
                55,
                45,
                46,
                46,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                43,
                44,
                44,
                44,
                44,
                45,
                45,
                45,
                46,
                46,
                46,
                46,
                47,
                47,
                47,
                48,
                48,
                48,
                48,
                49,
                49,
                49,
                49,
                50,
                50,
                50,
                50,
                50,
                50,
                51,
                52,
                53,
                54,
                54,
                55,
                56,
                57,
                57,
                58,
                59,
                60,
                61,
                61,
                62,
                62,
                63,
                64,
                65,
                66,
                67,
                68,
                68,
                83,
                83,
                83,
                84,
                86,
                87,
                88,
                89,
                90,
                91,
                92,
                93,
                94,
                95,
                95,
                96,
                97,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                97,
                96,
                95,
                94,
                93,
                92,
                91,
                90,
                89,
                87,
                86,
                85,
                84,
                83,
                81,
                80,
                79,
                78,
                77,
                76,
                75,
                73,
                72,
                71,
                69,
                68,
                67,
                66,
                64,
                63,
                63,
                62,
                60,
                58,
                67,
                66,
                65,
                63,
                62,
                61,
                60,
                59,
                58,
                57,
                56,
                55,
                54,
                53,
                52,
                50,
                49,
                48,
                47,
                46,
                45,
                44,
                43,
                42,
                40,
                39,
                38,
                37,
                36,
                36,
                35,
                34,
                32,
                31,
                30,
                29,
                28,
                27,
                27,
                26,
                25,
                24,
                24,
                23,
                22,
                21,
                20,
                19,
                19,
                18,
                17,
                16,
                16,
                15,
                14,
                14,
                13,
                13,
                12,
                12,
                12,
                12,
                12,
                11,
                11,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                3,
                4,
                4,
                4,
                5,
                5,
                6,
                6,
                5,
                5,
                6,
                9,
                9,
                10,
                11,
                11,
                12,
                13,
                14,
                14,
                15,
                15,
                15,
                15,
                15,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                17,
                17,
                18,
                18,
                19,
                19,
                19,
                20,
                20,
                20,
                20,
                21,
                21,
                21,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                21,
                21,
                21,
                21,
                22,
                22,
                22,
                22,
                22,
                8,
                9,
                9,
                28,
                28,
                28,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                28,
                28,
                28,
                28,
                28,
                28,
                27,
                27,
                27,
                27,
                27,
                26,
                26,
                26,
                25,
                25,
                25,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                23,
                23,
                23,
                23,
                23,
                22,
                22,
                21,
                21,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                19,
                19,
                19,
                19,
                20,
                20,
                20,
                19,
                19,
                17,
                17,
                17,
                16,
                16,
                16,
                16,
                15,
                15,
                15,
                14,
                13,
                12,
                12,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                11,
                11,
                10,
                10,
                10,
                9,
                9,
                9,
                9,
                9,
                8,
                8,
                8,
                8,
                8,
                7,
                7,
                7,
                6,
                5,
                5,
                4,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                7,
                8,
                9,
                9,
                10,
                10,
                11,
                11,
                12,
                12,
                13,
                14,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                21,
                22,
                23,
                24,
                13,
                14,
                16,
                18,
                19,
                20,
                21,
                22,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                30,
                31,
                31,
                31,
                32,
                32,
                32,
                33,
                33,
                34,
                34,
                35,
                35,
                35,
                35,
                35,
                36,
                36,
                37,
                37,
                38,
                38,
                38,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                38,
                38,
                38,
                37,
                37,
                37,
                36,
                36,
                36,
                36,
                35,
                35,
                34,
                27,
                27,
                26,
                20,
                19,
                19,
                19,
                18,
                18,
                17,
                17,
                16,
                15,
                15,
                14,
                14,
                14,
                15,
                15,
                14,
                14,
                13,
                13,
                12,
                11,
                10,
                10,
                9,
                9,
                8,
                8,
                7,
                7,
                6,
                6,
                6,
                5,
                5,
                4,
                4,
                3,
                3,
                2,
                2,
                2,
                2,
                1,
                1,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                3,
                3,
                4,
                4,
                5,
                5,
                6,
                6,
                7,
                7,
                7,
                8,
                8,
                8,
                9,
                9,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                11,
                4,
                4,
                4,
                13,
                13,
                13,
                13,
                13,
                13,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                13,
                13,
                13,
                13,
                14,
                14,
                14,
                15,
                15,
                15,
                16,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                17,
                18,
                18,
                18,
                19,
                19,
                19,
                19,
                19,
                19,
                20,
                20,
                20,
                20,
                21,
                21,
                21,
                21,
                21,
                21,
                11,
                11,
                11,
                23,
                23,
                23,
                22,
                22,
                22,
                22,
                21,
                21,
                21,
                21,
                21,
                21,
                21,
                21,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                20,
                19,
                19,
                19,
                19,
                18,
                18,
                17,
                17,
                16,
                16,
                16,
                15,
                15,
                15,
                14,
                14,
                13,
                13,
                13,
                13,
                12,
                12,
                12,
                12,
                11,
                11,
                10,
                10,
                9,
                9,
                9,
                9,
                9,
                10,
                10,
                10,
                10,
                10,
                6,
                6,
                6,
                6,
                14,
                14,
                14,
                14,
                14,
                14,
                13,
                13,
                13,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                13,
                13,
                13,
                13,
                13,
                13,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                11,
                11,
                11,
                11,
                11,
                11,
                10,
                10,
                9,
                8,
                7,
                6,
                6,
                5,
                5,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                16,
                16,
                16,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                23,
                23,
                23,
                22,
                22,
                22,
                21,
                21,
                21,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                21,
                21,
                21,
                21,
                21,
                22,
                22,
                22,
                23,
                24,
                24,
                25,
                26,
                27,
                27,
                28,
                29,
                29,
                30,
                30,
                31,
                31,
                32,
                32,
                32,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                38,
                37,
                36,
                36,
                36,
                35,
                35,
                35,
                35,
                36,
                36,
                37,
                37,
                37,
                37,
                36,
                35,
                34,
                34,
                33,
                32,
                32,
                31,
                30,
                30,
                29,
                29,
                29,
                28,
                28,
                28,
                28,
                28,
                28,
                28,
                26,
                26,
                27,
                36,
                36,
                37,
                37,
                37,
                38,
                38,
                38,
                39,
                40,
                41,
                41,
                42,
                43,
                43,
                44,
                45,
                46,
                46,
                46,
                47,
                47,
                47,
                48,
                48,
                48,
                49,
                49,
                50,
                51,
                51,
                51,
                51,
                51,
                51,
                51,
                51,
                51,
                51,
                51,
                51,
                52,
                52,
                52,
                53,
                53,
                53,
                53,
                53,
                53,
                53,
                52,
                52,
                52,
                51,
                51,
                51,
                50,
                50,
                49,
                49,
                49,
                49,
                55,
                55,
                55,
                52,
                53,
                53,
                53,
                54,
                54,
                54,
                54,
                53,
                53,
                53,
                52,
                52,
                52,
                52,
                52,
                53,
                53,
                53,
                53,
                53,
                53,
                53,
                53,
                54,
                54,
                54,
                54,
                53,
                54,
                54,
                54,
                55,
                56,
                56,
                56,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                57,
                58,
                58,
                58,
                59,
                59,
                59,
                60,
                60,
                60,
                61,
                61,
                62,
                62,
                62,
                62,
                62,
                48,
                48,
                48,
                31,
                31,
                30,
                30,
                29,
                29,
                29,
                29,
                29,
                29,
                29,
                28,
                28,
                27,
                26,
                26,
                25,
                24,
                24,
                24,
                23,
                23,
                23,
                23,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                21,
                21,
                20,
                19,
                18,
                18,
                17,
                17,
                17,
                16,
                16,
                15,
                15,
                15,
                14,
                14,
                14,
                13,
                13,
                13,
                12,
                12,
                12,
                11,
                11,
                10,
                10,
                10,
                9,
                9,
                9,
                10,
                9,
                9,
                8,
                8,
                7,
                7,
                7,
                6,
                6,
                6,
                5,
                5,
                5,
                5,
                6,
                6,
                6,
                7,
                7,
                7,
                7,
                7,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                7,
                7,
                7,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                9,
                9,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                7,
                7,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                5,
                5,
                5,
                5,
                5,
                5,
                6,
                6,
                6,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                7,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                5,
                5,
                5,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                15,
                16,
                16,
                16,
                16,
                16,
                16,
                17,
                17,
                17,
                18,
                18,
                19,
                19,
                19,
                20,
                20,
                21,
                21,
                21,
                22,
                22,
                23,
                23,
                23,
                24,
                24,
                25,
                25,
                25,
                26,
                26,
                29,
                29,
                28,
                28,
                29,
                29,
                30,
                30,
                30,
                31,
                31,
                32,
                32,
                32,
                33,
                33,
                34,
                34,
                34,
                35,
                35,
                36,
                36,
                36,
                37,
                37,
                38,
                38,
                38,
                39,
                39,
                40,
                40,
                40,
                41,
                41,
                42,
                42,
                43,
                43,
                44,
                44,
                44,
                45,
                45,
                46,
                46,
                46,
                47,
                47,
                48,
                48,
                48,
                49,
                49,
                50,
                50,
                50,
                51,
                51,
                52,
                52,
                52,
                53,
                53,
                54,
                58,
                59,
                55,
                56,
                57,
                58,
                58,
                59,
                60,
                61,
                62,
                62,
                63,
                64,
                65,
                65,
                66,
                67,
                68,
                69,
                69,
                70,
                71,
                72,
                72,
                73,
                74,
                75,
                76,
                77,
                78,
                79,
                81,
                82,
                83,
                84,
                84,
                85,
                86,
                87,
                87,
                88,
                88,
                88,
                89,
                89,
                90,
                90,
                90,
                91,
                91,
                91,
                92,
                92,
                92,
                93,
                93,
                93,
                94,
                95,
                96,
                96,
                97,
                97,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                97,
                97,
                96,
                96,
                95,
                94,
                94,
                93,
                93,
                92,
                92,
                92,
                91,
                91,
                90,
                90,
                89,
                89,
                89,
                88,
                88,
                87,
                86,
                86,
                85,
                84,
                83,
                82,
                81,
                80,
                80,
                79,
                78,
                78,
                77,
                77,
                76,
                76,
                75,
                74,
                51,
                50,
                49,
                49,
                43,
                42,
                41,
                40,
                40,
                39,
                38,
                38,
                38,
                37,
                37,
                36,
                36,
                35,
                35,
                34,
                34,
                33,
                33,
                32,
                31,
                31,
                30,
                29,
                28,
                28,
                27,
                27,
                26,
                25,
                25,
                24,
                23,
                23,
                22,
                22,
                22,
                21,
                21,
                21,
                20,
                20,
                19,
                19,
                18,
                18,
                18,
                17,
                17,
                16,
                16,
                15,
                15,
                14,
                14,
                14,
                13,
                13,
                13,
                12,
                12,
                4,
                4,
                3,
                3,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                9,
                9,
                9,
                9,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                7,
                7,
                7,
                6,
                6,
                6,
                6,
                6,
                6,
                5,
                5,
                5,
                5,
                5,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                5,
                5,
                5,
                5,
                4,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                6,
                7,
                7,
                8,
                8,
                9,
                9,
                10,
                10,
                11,
                12,
                12,
                13,
                14,
                14,
                15,
                15,
                15,
                16,
                16,
                16,
                16,
                17,
                17,
                18,
                18,
                19,
                19,
                20,
                20,
                21,
                22,
                22,
                23,
                24,
                24,
                25,
                25,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                27,
                27,
                27,
                28,
                28,
                28,
                29,
                29,
                29,
                29,
                29,
                29,
                29,
                29,
                21,
                21,
                21,
                21,
                29,
                28,
                28,
                28,
                28,
                27,
                27,
                27,
                26,
                25,
                25,
                24,
                24,
                24,
                23,
                23,
                22,
                22,
                22,
                22,
                22,
                21,
                21,
                20,
                20,
                19,
                19,
                18,
                18,
                17,
                16,
                15,
                15,
                14,
                14,
                13,
                13,
                12,
                12,
                12,
                11,
                11,
                10,
                10,
                9,
                9,
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                4,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                10,
                11,
                12,
                13,
                9,
                10,
                11,
                23,
                23,
                24,
                25,
                25,
                26,
                26,
                27,
                27,
                27,
                28,
                28,
                28,
                28,
                29,
                28,
                28,
                28,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                27,
                26,
                26,
                26,
                26,
                25,
                25,
                25,
                24,
                23,
                22,
                21,
                20,
                19,
                18,
                18,
                18,
                17,
                17,
                17,
                16,
                16,
                16,
                16,
                15,
                15,
                14,
                13,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                3,
                3,
                4,
                4,
                5,
                5,
                6,
                6,
                2,
                1,
                2,
                7,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                8,
                9,
                9,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                11,
                11,
                11,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                11,
                11,
                11,
                11,
                12,
                12,
                12,
                12,
                12,
                12,
                19,
                19,
                18,
                18,
                31,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                31,
                31,
                31,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                31,
                30,
                30,
                29,
                28,
                28,
                28,
                28,
                28,
                41,
                41,
                41,
                48,
                47,
                46,
                46,
                45,
                45,
                45,
                45,
                45,
                45,
                45,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                44,
                45,
                45,
                44,
                44,
                43,
                42,
                33,
                33,
                33,
                35,
                36,
                36,
                36,
                36,
                35,
                35,
                35,
                35,
                35,
                35,
                35,
                35,
                34,
                34,
                34,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                33,
                34,
                34,
                34,
                35,
                35,
                35,
                36,
                36,
                36,
                37,
                37,
                38,
                39,
                40,
                41,
                42,
                52,
                52,
                52,
                53,
                59,
                60,
                61,
                62,
                63,
                64,
                65,
                65,
                65,
                66,
                66,
                67,
                68,
                68,
                69,
                70,
                70,
                70,
                71,
                71,
                72,
                73,
                73,
                74,
                75,
                75,
                76,
                77,
                77,
                78,
                79,
                80,
                80,
                81,
                82,
                82,
                83,
                84,
                84,
                85,
                86,
                86,
                87,
                87,
                88,
                88,
                88,
                89,
                89,
                89,
                90,
                90,
                90,
                90,
                91,
                91,
                91,
                91,
                91,
                91,
                91,
                97,
                97,
                97,
                98,
                90,
                90,
                90,
                90,
                90,
                91,
                91,
                92,
                93,
                93,
                94,
                94,
                95,
                96,
                96,
                97,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                96,
                94,
                92,
                90,
                89,
                87,
                85,
                83,
                81,
                79,
                77,
                75,
                73,
                71,
                70,
                67,
                65,
                63,
                61,
                59,
                57,
                29,
                27,
                25,
                23,
                21,
                19,
                17,
                15,
                13,
                11,
                9,
                7,
                5,
                3,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                2,
                2,
                2,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                21,
                21,
                20,
                20,
                19,
                19,
                18,
                18,
                18,
                17,
                17,
                16,
                16,
                15,
                15,
                14,
                14,
                13,
                13,
                13,
                13,
                13,
                12,
                12,
                12,
                12,
                11,
                11,
                11,
                10,
                9,
                9,
                8,
                8,
                7,
                7,
                7,
                7,
                6,
                6,
                5,
                5,
                4,
                4,
                4,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                2,
                2,
                2,
                4,
                4,
                5,
                8,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                8,
                8,
                8,
                8,
                7,
                7,
                7,
                7,
                6,
                6,
                6,
                5,
                5,
                4,
                4,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                5,
                4,
                4,
                4,
                3,
                3,
                3,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                3,
                3,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                5,
                5,
                5,
                6,
                7,
                7,
                8,
                8,
                9,
                8,
                8,
                9,
                9,
                10,
                10,
                11,
                11,
                11,
                11,
                11,
                11,
                11,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                13,
                13,
                13,
                13,
                14,
                14,
                14,
                14,
                14,
                14,
                13,
                13,
                12,
                12,
                11,
                11,
                11,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                10,
                9,
                9,
                9,
                9,
                8,
                8,
                8,
                5,
                5,
                5,
                5,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                5,
                5,
                5,
                5,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                7,
                7,
                7,
                7,
                7,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                6,
                7,
                7,
                7,
                7,
                8,
                8,
                8,
                9,
                9,
                9,
                9,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                4,
                4,
                4,
                4,
                4,
                4,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                5,
                4,
                4,
                4,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                3,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                8,
                7,
                7,
                7,
                8,
                8,
                8,
                7,
                7,
                7,
                7,
                6,
                6,
                6,
                6,
                6,
                5,
                5,
                4,
                4,
                3,
                2,
                2,
                2,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                10,
                10,
                10,
                11,
                11,
                11,
                12,
                12,
                12,
                12,
                12,
                12,
                12,
                13,
                13,
                14,
                14,
                14,
                14,
                14,
                14,
                14,
                13,
                13,
                13,
                13,
                13,
                14,
                14,
                15,
                15,
                15,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                16,
                15,
                15,
                14,
                14,
                14,
                14,
                19,
                19,
                19,
                24,
                24,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                24,
                24,
                24,
                25,
                25,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                26,
                25,
                25,
                24,
                24,
                24,
                24,
                24,
                24,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                23,
                35,
                35,
                35,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                33,
                33,
                33,
                32,
                32,
                31,
                31,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                30,
                31,
                31,
                31,
                32,
                32,
                33,
                33,
                33,
                34,
                34,
                35,
                35,
                35,
                36,
                36,
                37,
                37,
                37,
                38,
                38,
                39,
                39,
                40,
                40,
                40,
                41,
                32,
                32,
                28,
                29,
                29,
                30,
                30,
                30,
                31,
                31,
                32,
                32,
                33,
                33,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                32,
                33,
                33,
                34,
                34,
                35,
                35,
                35,
                36,
                36,
                37,
                37,
                37,
                37,
                37,
                37,
                37,
                36,
                36,
                35,
                35,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                38,
                38,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                37,
                37,
                37,
                38,
                38,
                39,
                39,
                39,
                39,
                39,
                39,
                39,
                38,
                38,
                37,
                37,
                37,
                36,
                36,
                35,
                35,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                34,
                33,
                33,
                32,
                32,
                31,
                31,
                31,
                30,
                30,
                29,
                29,
                28,
                28,
                28,
                27,
                27,
                26,
                26,
                25,
                25,
                22,
                21,
                16,
                16,
                15,
                15,
                14,
                14,
                13,
                13,
                13,
                12,
                12,
                11,
                11,
                10,
                10,
                10,
                10,
                10,
                10,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                9,
                10,
                10,
                11,
                11,
                11,
                12,
                12,
                13,
                13,
                14,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                13,
                10,
                10,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                1,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                4,
                5,
                5,
                5,
                5,
                5,
                5,
                4,
                4,
                4,
                3,
                3,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                3,
                4,
                4,
                4,
                5,
                5,
                6,
                6,
                7,
                7,
                8,
                8,
                9,
                9,
                10,
                10,
                10,
                10,
                11,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                21,
                21,
                22,
                22,
                23,
                23,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                24,
                19,
                19,
                19,
                19,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                22,
                23,
                23,
                24,
                24,
                25,
                25,
                26,
                26,
                27,
                27,
                27,
                28,
                28,
                29,
                29,
                30,
                30,
                30,
                30,
                30,
                30,
                29,
                29,
                28,
                28,
                27,
                27,
                27,
                26,
                26,
                25,
                25,
                24,
                23,
                22,
                21,
                20,
                19,
                19,
                16,
                16,
                15,
                16,
                15,
                14,
                13,
                12,
                11,
                10,
                9,
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                5,
                7,
                9,
                11,
                13,
                14,
                16,
                18,
                19,
                22,
                24,
                26,
                29,
                31,
                33,
                34,
                35,
                36,
                37,
                38,
                39,
                40,
                41,
                40,
                41,
                43,
                45,
                51,
                52,
                53,
                55,
                56,
                57,
                58,
                59,
                60,
                61,
                62,
                63,
                65,
                66,
                67,
                68,
                69,
                70,
                71,
                72,
                73,
                74,
                75,
                77,
                78,
                79,
                80,
                81,
                81,
                82,
                82,
                83,
                83,
                84,
                84,
                84,
                84,
                84,
                84,
                84,
                84,
                84,
                84,
                84,
                83,
                83,
                83,
                82,
                82,
                81,
                80,
                80,
                80,
                80,
                80,
                80,
                80,
                78,
                77,
                76,
                75,
                73,
                72,
                71,
                70,
                71,
                70,
                69,
                68,
                67,
                66,
                65,
                64,
                62,
                61,
                60,
                59,
                58,
                57,
                55,
                54,
                52,
                50,
                49,
                47,
                46,
                44,
                42,
                41,
                39,
                37,
                36,
                34,
                33,
                31,
                29,
                28,
                26,
                24,
                22,
                21,
                19,
                18,
                16,
                14,
                13,
                11,
                10,
                8,
                6,
                5,
                3,
                1,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                3,
                5,
                7,
                9,
                12,
                14,
                17,
                19,
                21,
                24,
                25,
                27,
                29,
                31,
                33,
                34,
                36,
                38,
                40,
                41,
                43,
                45,
                47,
                48,
                50,
                52,
                54,
                55,
                57,
                59,
                61,
                62,
                64,
                65,
                66,
                67,
                67,
                68,
                69,
                74,
                75,
                76,
                77,
                78,
                79,
                80,
                82,
                83,
                84,
                85,
                86,
                88,
                89,
                91,
                93,
                94,
                95,
                96,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                34,
                20,
                12,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                2,
                5,
                7,
                10,
                13,
                15,
                18,
                20,
                23,
                26,
                28,
                31,
                33,
                35,
                37,
                39,
                41,
                43,
                45,
                47,
                49,
                51,
                52,
                54,
                56,
                57,
                59,
                60,
                61,
                63,
                64,
                65,
                67,
                68,
                69,
                74,
                76,
                77,
                81,
                82,
                83,
                84,
                86,
                87,
                88,
                89,
                90,
                92,
                93,
                94,
                95,
                96,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                98,
                96,
                94,
                93,
                92,
                91,
                90,
                88,
                86,
                84,
                82,
                80,
                79,
                76,
                74,
                72,
                71,
                69,
                68,
                67,
                66,
                65,
                64,
                62,
                61,
                60,
                58,
                57,
                56,
                54,
                53,
                52,
                51,
                49,
                47,
                46,
                44,
                58,
                57,
                56,
                56,
                55,
                55,
                53,
                52,
                51,
                50,
                49,
                48,
                47,
                46,
                45,
                43,
                42,
                41,
                40,
                39
            ]
        }
    ],
    legend: {
        enabled: false
    },
    title: {
        text: null
    }

}
