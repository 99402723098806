import {
    SECTIONS,
    GET_SECTION_DATA,
    SET_INITIAL_PAGE_ID, SET_PAGE_ID, SET_THEME, SET_TERMS
} from '../constants/reducers'

const defaultLib = {
    libSections: [],
    sectionData: [],
    initialPageId: 0,
    pageId: 0,
    theme: 0,
    termsAgreed: false,
};


export default (lib = defaultLib, action) => {
    const {type, payload} = action;

    switch (type) {

        case SET_PAGE_ID:
            return {...lib, pageId: payload.pageId};

        case SET_THEME:
            return {...lib, theme: payload.themeId};

        case SET_INITIAL_PAGE_ID:
            return {...lib, initialPageId: payload};

        case SECTIONS:
            return {...lib, libSections: payload.libSections.sections};

        case GET_SECTION_DATA:
            return {...lib, sectionData: payload};

        case SET_TERMS:
            return {...lib, termsAgreed: payload};

        default:
            return lib;
    }
}