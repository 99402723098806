import React, {useEffect, useState} from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {makeStyles} from '@material-ui/core/styles';
import {TextRegular} from "../shared/typography";
import {ButtonQ} from "../shared/formElements";
import {useSelector} from "react-redux";
import axios from "axios";
import {api} from "../constants/endpoints";
import {setAuthorizationToken, setName, setStatus} from "../actions";


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    text:{
        maxWidth: '650px'
    },
    buttonsWrap:{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        marginTop: '40px'
    }
}));

export const UserConfirmation = (props) => {
    const classes = useStyles();
    const openMode = useSelector((state) => state.auth.openMode);

    const [open, setOpen] = useState(props.open);

    useEffect(() => {

        setOpen(props.open)

    }, [props.open]);

    return (
                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '20px'
                    }}
                    open={open}
                    onClose={props.onCancel}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <TextRegular fontSize={20} className={classes.text}>
                                You are an “accredited investor” as put forth by The Securities and Exchange Commission (SEC) in Rule 501 of Regulation D.</TextRegular>
                           <div className={classes.buttonsWrap}><ButtonQ onClick={props.onCancel}>No</ButtonQ> <ButtonQ onClick={props.onConfirm}>Yes</ButtonQ></div>
                        </div>
                    </Fade>
                </Modal>
       )

};