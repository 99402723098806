import React, {useState} from "react";
import axios from "axios";
import {api} from "../../constants/endpoints";
import {BLUE, BLUE_SMOOTH, GRAY30, GRAY50, GRAY60, GRAY80, ORANGE} from "../../constants/colors";
import {Star} from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';
import styled from "styled-components";

export const Favorite = props => {
    const [fav, setFav] = useState(props.favorite);

    const data = {id: props.id};
    const full = props.fullsize;

    const removeFromFav = () => {
        if (fav === 2) return;
        axios.delete(`${api}delete_favorite/${props.id}`, data).then(response => {
                setFav(fav - 1)
            }, error => {
            }
        )
    };

    const addToFav = () => {
        axios.post(`${api}add_favorite`, data).then(response => {
                setFav(fav + 1)
            }, error => {
            }
        )
    };

    let styleStar =
        {
            color: fav ? ORANGE : GRAY60,
            marginLeft: '10px',
            cursor: 'pointer',
        }

    let styleText = {
        color: fav === 1 ? BLUE : GRAY80,
        background: GRAY30,
        padding: "3px 10px",
        borderRadius: "5px",
        marginLeft: "20px",
        cursor: "pointer"
    }
    let tooltipTitle = fav === 1 ? 'Remove from Favourites' : 'Add to Favourites';

    if (full) return (
        <div onClick={fav ? removeFromFav : addToFav}
             style={styleText}>{fav === 1 && 'Remove from Favourites'}{fav === 0 && 'Add to Favourites'} {fav === 2 && 'Added by Admin'}</div>
    );

    return (
        <Tooltip title={tooltipTitle} arrow>
            <Star style={styleStar}
                  onClick={fav ? removeFromFav : addToFav}
            />
        </Tooltip>
)
};

const favButton = styled.div`
    
`;