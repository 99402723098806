import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {ButtonR, LineSpacer, InputDefault} from '../shared/formElements'
import {Title, TextLink, TextRegular} from '../shared/typography';
import Logo from '../components/logo';
import {Redirect} from 'react-router';
import css from './login.module.css';
import axios from 'axios';
import {stringify} from "qs";
import {setStatus, setName, setAuthorizationToken} from '../actions';
import {connect} from 'react-redux';
import {api} from '../constants/endpoints';
import {UserConfirmation} from "../components/UserConfirmation";
import {TermsConfirmation} from '../components/TermsConfirmation';
import {BLUE} from "../constants/colors";

class Register extends Component {

    state = {
        password: '',
        c_password: '',
        email: '',
        name: '',
        company: '',
        emailInitial: true,
        nameInitial: true,
        passwordInitial: true,
        showPassword: false,
        loading: false,
        errorResponse: null,
        redirectToHome: false,
        registered: false,
        counter: 5,
        validationPopup: false,
        validatedUser: false,
        termsAgreed: false,
    };

    timeout = '';

    componentDidMount() {
        if (this.props.authorizationToken) {
            axios.defaults.headers.common['Authorization'] = this.props.authorizationToken;
        } else {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.termsAgreed !== prevState.termsAgreed){

                this.counter();

        }
    }




    handleRegister() {
        this.setState({loading: false, registered: true});
    }

    handleRegisterError(error) {
        this.setState({loading: false, errorResponse: error});
    }

    isValid() {
        let trimPassword = this.state.password.trim();
        let trimEmail = this.state.email.trim();
        this.setState({
            email: trimEmail,
            password: trimPassword
        });
        return trimEmail && trimPassword && this.state.name
            && trimEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            && trimPassword.length >= 6;
    }

    checkTerms(){
        if(this.isValid()){
            this.setState({validationPopup:true});
        } else {
            this.setState({
                emailInitial: false,
                nameInitial: false,
                passwordInitial: false
            });
        }
    }

    register() {

        if (this.isValid()) {

            this.setState({loading: true});

            const postData = stringify({
                email: this.state.email,
                password: this.state.password,
                c_password: this.state.c_password,
                name: this.state.name,
                company: this.state.company,
                grant_type: "password",
                client_id: 1,
                client_secret: '4zbjNDlHFJ5s9dcKCZkI4dDPoekMGnEl9XvzYy6e'
            });

            axios.post(`${api}register`, postData)
                .then(response => {
                    this.handleRegister(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        this.handleRegisterError(error.response.data);
                    } else if (error.request) {

                    } else {

                    }

                });
        } else {
            this.setState({
                emailInitial: false,
                nameInitial: false,
                passwordInitial: false
            });
        }
    }

    handleChange = prop => event => {
        this.setState({[prop]: event.target.value}); //, [`${prop}Initial`]: false
    };

    validateOnCancel = () => {
        this.setState({validationPopup: false, validatedUser: false})
    }

    validateOnConfirm = () => {
        this.setState({validationPopup: false, validatedUser: true})
    }

    termsOnConfirm = () => {
        this.setState({validatedUser: false, termsAgreed: true})
        this.register();
    }
    termsOnCancel = () => {
        this.setState({validatedUser: false, termsAgreed: false})
    }
    counter = () => {
        let counter = this.state.counter;

        let interval = setInterval(()=>{
            counter -= 1;
            this.setState({counter: counter})
            if (counter === 0){
                clearInterval(interval);
                this.setState({validatedUser: false, termsAgreed: false})
                this.props.history.push('/');
            }
        }, 1000)
    }

    render() {
        const {password, c_password, email, name, company, emailInitial, nameInitial, passwordInitial} = this.state;

        if (this.props.status || this.props.status === 0){
            return <Redirect to={'/'}/>
        }

        if (this.state.registered) {
            return (
                <Box>
                    <Container maxWidth="xs">
                        <Grid container alignItems="center" direction="column" className={css.wrapper}>
                            <Logo className={css.logo} justify="center"/>
                            <Title className={css.register}>Registration</Title>
                            <p style={{textAlign: "center", color: BLUE }}>Successfully registered. Wait for confirmation on your email. You will be redirected in <span style={{color: "red"}}>{this.state.counter} </span> ...</p>
                        </Grid>
                    </Container>
                </Box>
            )
        }
        return (
            <>
                <UserConfirmation
                    open = {this.state.validationPopup}
                    onCancel = {this.validateOnCancel}
                    onConfirm = {this.validateOnConfirm}
                />
                <TermsConfirmation
                    open = {this.state.validatedUser}
                    onCancel = {this.termsOnCancel}
                    onConfirm = {this.termsOnConfirm}
                />
                <Box style={{paddingBottom: '40px'}}>
                    <Container maxWidth="xs">
                        <Grid container alignItems="center" direction="column" className={css.wrapper}>
                            <Logo className={css.logo} justify="center"/>
                            <Title className={css.register}>Registration</Title>
                            <InputDefault id="name" label="Name" variant="outlined" fullWidth
                                          error={!nameInitial && !name}
                                          value={name}
                                          onChange={this.handleChange('name')}
                                          inputProps={{
                                              'aria-label': 'Name',
                                          }}
                            />

                            <InputDefault id="email" label="Email" variant="outlined" fullWidth
                                          error={!emailInitial && !(email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) || Boolean(this.state.errorResponse)}
                                          id="emailNew"
                                          value={email}
                                          onChange={this.handleChange('email')}
                                          inputProps={{
                                              'aria-label': 'EmailNew',
                                          }}
                                          type='email'
                            />


                            <InputDefault id="companyName" label="Company Name (Optional)" variant="outlined" fullWidth
                                          onChange={this.handleChange('company')}
                                          value={company}
                            />

                            <InputDefault id="password" label="Password" variant="outlined" fullWidth
                                          error={!passwordInitial && !(password && password.length >= 6)}
                                          type={'password'}
                                          value={password}
                                          onChange={this.handleChange('password')}
                                          autoComplete="new-password"
                            />

                            <InputDefault id="c_password" label="Repeat Password" variant="outlined" fullWidth
                                          type={'password'}
                                          error={password !== c_password}
                                          onChange={this.handleChange('c_password')}
                            />
                            {this.state.errorResponse && <p style={{color: "red"}}>{this.state.errorResponse.data.email}</p> }
                            <ButtonR fullWidth
                                     onClick={() => {
                                         if (this.state.termsAgreed){
                                             this.register();
                                         } else {
                                             this.checkTerms();
                                         }
                                     }}>Create account</ButtonR>

                            <TextRegular margin="20px 0 0">Subject to RandomTrack approval</TextRegular>
                            <LineSpacer margin="15px 0 20px "/>
                            <TextRegular>I already have an account. <TextLink href="/login">Log In</TextLink>
                            </TextRegular>
                        </Grid>
                    </Container>
                </Box>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    authorizationToken: state.auth.authorizationToken,
    openMode: state.auth.openMode,
    status: state.auth.status,
});

export default connect(mapStateToProps)(Register);