import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {api} from "../constants/endpoints";
import {setLinks, setStatus, setAuthorizationToken, setName, setExpired} from "../actions";
import axios from 'axios';

import ListItem from '@material-ui/core/ListItem';
import './style.css';
import './background.css';

import {Container, Box, Grid, withStyles, IconButton} from '@material-ui/core';
import {ChevronRight, ExpandMore} from "@material-ui/icons";
import Preloader from '../svg/Preloader';
import {BoxWrapper} from '../shared/layout';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Title, TextRegular, SubTitle, TextLink} from '../shared/typography';
import {THEMES} from "../constants/themes";
import {DARK, GRAY80, BLUE} from "../constants/colors";

import styled from 'styled-components';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {ChartBlock} from "../components/PageData/ChartBlock";
import Footer from "../components/Footer";
import {TableBlock} from "../components/PageData/TableBlock";
import {Favorite} from "../components/PageData/Favorite";
import {TextBlock} from "../components/PageData/TextBlock";
import Card from "../components/Card";
import {CheckAuth} from "../components/CheckAuth";

class Favourites extends Component {
    state = {
        loading: false,
        isLoaded: false,
        error: false,
        favoriteData: null,
        isUserAuth: true
    };

    handleSections = sections => this.props.setLinks(sections);
    handle401 = () => {
        axios.interceptors.response.use(
            response => response,
            error => {
                const {status} = error.response;
                if (status === 401) {


                }
                return Promise.reject(error);
            }
        );
    }
    getFavouritesList = () => {
        this.setState({loading: true});
        axios.get(`${api}favorites_sections_list`).then(response => {
                this.setState({favoriteData: response.data.data});
                this.setState({loading: false});
                this.setState({isLoaded: true});
            }, error => {
                let {status} = error.response;
                if (status === 401) {
                    axios.defaults.headers.common['Authorization'] = undefined;
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('status');
                    localStorage.removeItem('name');
                    this.props.setAuthorizationToken(null);
                    this.props.setStatus(null);
                    this.props.setName(null);
                    this.props.history.push('/login');
                }
                this.setState({
                    error
                });
            }
        )
    };
    handleChangeUserAuth = bool => this.setState({isUserAuth: bool});

    componentDidMount() {
        let authToken = this.props.authorizationToken || localStorage.getItem('access_token');
        if (authToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        }
        if (!this.state.favoriteData) this.getFavouritesList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        const {loading, isUserAuth} = this.state;
        const {name} = this.props;

        if (!isUserAuth) {
            return <Redirect to="/login" />
        }

        if (!this.state.isLoaded) {
            return loading &&
                <BoxWrapper><Grid container justify={"center"} style={{paddingTop: "35px"}}><Preloader color={BLUE}/></Grid></BoxWrapper>
        } else {
            const {sections} = this.state.favoriteData;

            return (
                <>
                    {name && <CheckAuth history={this.props.history} onChangeAuth={this.handleChangeUserAuth} />}
                    <BoxWrapper style={{backgroundColor: "#fafafc", height: "100%", overflowY:"scroll"}}>
                        <Container maxWidth={"lg"} style={{height: "100%"}}>
                            <Container maxWidth={"md"}
                                       style={{padding: 0, height: "100%", display: "flex", flexDirection: "column"}}>
                                <Title style={{margin: "80px 0 30px"}}>Favourites</Title>
                                {
                                    sections.map((section, key) => {

                                        return (
                                            <ExpansionPanel key={key} style={{
                                                boxShadow: "none",
                                                border: "none",
                                                borderRadius: "10px",
                                                marginBottom: "30px"
                                            }}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMore/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    style={{height: '90px'}}
                                                >
                                                    <SubTitle >{section.title}</SubTitle>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails style={{
                                                    flexDirection: "column",
                                                }}>
                                                    {section.pages.map((page, key) => {


                                                        return (
                                                            <div key={key}>

                                                                {page.content.map((block, key) => {
                                                                    const theme = THEMES[page.theme];
                                                                    let descriptionBg = block.type === 'text' && theme ? theme.descriptionBlockBG : false;
                                                                    return (
                                                                        <Card theme={theme} descriptionBg={descriptionBg}
                                                                              key={key} style={{
                                                                            marginTop: "30px",
                                                                            padding: "10px"
                                                                        }}>
                                                                            <TitleBlock style={{
                                                                                margin: "10px 0 20px",
                                                                                color: theme.color
                                                                            }}><SubTitle
                                                                                fontSize={20}>{block.title}</SubTitle>
                                                                                <Favorite
                                                                                    favorite={block['is_favorite']}
                                                                                    id={block.id}
                                                                                /></TitleBlock>
                                                                            {block.charts ? <ChartBlock
                                                                                    options={block.charts}
                                                                                    key={key}
                                                                                    theme={theme}
                                                                                />

                                                                                : ''}

                                                                            {block.tables ?
                                                                                <TableBlock data={block.tables}
                                                                                            theme={theme}/>
                                                                                : ''}
                                                                            {block.type === 'text' ?
                                                                                <TextBlock text={block.description}
                                                                                           theme={theme}
                                                                                />
                                                                                : ''}
                                                                        </Card>)
                                                                })}
                                                            </div>
                                                        )
                                                    })}
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        )
                                    })
                                }
                                <Footer hiddenCR/>
                            </Container>
                        </Container>

                    </BoxWrapper>
                </>
            )
        }

    }
}

const TitleBlock = styled.div`
    display: flex;
    align-items: center;
`;


const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    libSections: state.lib.libSections
});

export default connect(mapStateToProps, {setName, setLinks, setStatus, setAuthorizationToken, setExpired})(Favourites);

const SectionListItem = styled(ListItem)`
    &&{
        padding: 20px;
        }
`;

const SectionData = styled.div`
    
`;