import {Link} from 'react-router-dom'
import styled from "styled-components";
import {BLUE_SMOOTH, BLUE, DARK} from "../../constants/colors";



export const AuthLink = styled(Link)`
    color: white;
    font-family: Quicksand;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;    
    
    border: 3px solid transparent;
    padding: 23px 0;
    margin-right: 30px;
        @media screen and (max-width: 960px) {
        margin-right: 0px;
        color: ${DARK};
        font-size: 18px;
    }
`;

export const RegButton = styled(Link)`
    font-family: Quicksand;
    padding: 5px 25px;
    background: ${BLUE_SMOOTH};
    color: ${BLUE};
    text-decoration: none;
    border-radius: 30px;
    height: 25px;
    line-height: 25px;
            @media screen and (max-width: 960px) {
        font-size: 18px
        
    }
`;