import {BLUE, DARK, GRAY20, GRAY30, GRAY50, GRAY60, GRAY90, WHITE} from "./colors";

export const THEMES = {
    1: {
        // chart
        background: '#fff',
        color: DARK,
        chartColors: ['#2f7ed8', '#1b395a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
        legend: DARK,
        labels: DARK,
        exportStroke:'#cacaca',
        exportStrokeHover:'#b1b1b1',
        gridLineDashStyle: '',
    //    table
        thFontColor:GRAY90,
        headFontColor:GRAY60,
        cellFontColor:GRAY90,
        tableHeaderBorderColor: BLUE,
        cellBorderColor: GRAY50,
        rowBackground : '',
        rowBackgroundOdd: '',
        //description
        descriptionBlockBG:'',
        descriptionBlockTextColor : GRAY90,
    },
    2: {
        background: '#f0f3fa',
        chartColors: ['#FFA492', '#FF5C6E', '#069697', '#E1D9E5', '#F3AA18', '#367BF5'],
        color: DARK,
        legend: GRAY60,
        labels: GRAY60,
        exportStroke:'#cacaca',
        exportStrokeHover:'#b1b1b1',
        gridLineDashStyle: 'dot',

        //    table
        thFontColor:GRAY90,
        headFontColor:GRAY60,
        cellFontColor:GRAY90,
        tableHeaderBorderColor: '#69A1A4',
        cellBorderColor: 'transparent',
        rowBackground : 'transparent',
        rowBackgroundOdd: '#EAEBF5',
        //description
        descriptionBlockBG:'linear-gradient(90deg, #F3E7E9 0%, #E3EEFF 100%)',
        descriptionBlockTextColor : GRAY90,
    },
    3: {
        background: '#4a4e51',
        chartColors: ['#367BF5', '#FF5C6E', '#FFA492', '#F3AA18',   '#84FAB2', '#8FEBFC'],

        color: '#e1e2e4',
        legend: WHITE,
        labels: WHITE,
        exportStroke:'#343434',
        exportStrokeHover:'#565656',
        gridLineDashStyle: '',

        //    table
        thFontColor:GRAY20,
        headFontColor:GRAY60,
        cellFontColor:GRAY20,
        tableHeaderBorderColor: 'transparent',
        cellBorderColor: 'transparent',
        rowBackground : 'transparent',
        rowBackgroundOdd: 'linear-gradient(90deg, rgba(255, 243, 243, 0.05) 0%, rgba(235, 209, 255, 0.05) 100%)',
        //description
        descriptionBlockBG:'linear-gradient(90deg, #FEF7DF 0%, #F0DCCB 100%)',
        descriptionBlockTextColor : GRAY90,
    },
    4: {
        background: '#3d3e50',
        color: '#e1e2e4',
        chartColors: ['#FF6B8B', '#367BF5', '#FFA492', '#F4E2D0', '#ECD2FF', '#DCF7E3'],
        legend: WHITE,
        labels: WHITE,
        exportStroke:'#343434',
        exportStrokeHover:'#565656',
        gridLineDashStyle: '',

        //    table
        thFontColor:'#fff',
        headFontColor:GRAY30,
        cellFontColor:GRAY30,
        tableHeaderBorderColor: '#505265',
        cellBorderColor: '#505265',
        rowBackground : 'transparent',
        rowBackgroundOdd: 'transparent',
        //description
        descriptionBlockBG: '#4653C9',
        descriptionBlockTextColor : WHITE,
    },


};