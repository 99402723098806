import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {GRAY90, GRAY50, GRAY60, GRAY80} from "../constants/colors";
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Logo from '../components/logo';
import styled from "styled-components";
import {MainMenu} from "./MainMenu";
import {TextRegular} from "../shared/typography"
import Hidden from "@material-ui/core/Hidden";
import {FOOTER_MENU} from "../constants/menus"

export default class Footer extends Component {
    render() {
        const hiddenCR = this.props.hiddenCR;
        const full = this.props.full;
        return (
            <Grid style={{marginTop: "auto", paddingTop:"100px", paddingBottom: "30px"}} container alignItems={"center"} justify={"flex-start"}>
                <Hidden mdDown>
                    <Grid item md={'auto'}>
                        <Logo size="35px" fontSize="21px"/>
                    </Grid>
                </Hidden>
                <Hidden xsDown>
                    <Grid container item xs={12} sm={8} md={7} lg={6} style={{marginLeft:"20px"}}>
                        <MainMenu
                            nodecorate={1}
                            fontSize={"14px"}
                            inverted={1}
                            footer={1}
                            items={FOOTER_MENU}
                        />
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <TextRegularSmall fontSize={"12px"} light={true}>© Macro Portfolio Manager, LLC. All Rights Reserved.</TextRegularSmall>
                    <TextRegularSmall fontSize={"11.1px"} light={true}>All data and analysis provided here are historical and are not reliable indicators of future outcomes. All data and analysis provided here are for information only. All data and analysis contained herein are provided without warranty or guarantee of any kind, either expressed or implied. Using this data and analysis could incur substantial financial losses. The provider of this data and analysis shall not have any liability for any loss sustained by anyone who has relied on the data and analysis contained herein. All data and analysis provided here are subject to change without notice. The data and analysis provided here may produce contradictory results with respect to the same security. The provider of this data and analysis makes no representation or warranty that the information contained herein is accurate, fair, or correct or that any transaction is appropriate for any person and it should not be relied on as such. The data and analysis contained herein are not intended to be an offer to buy or sell, or a solicitation of an offer to buy or sell, any securities.</TextRegularSmall>
                </Grid>
            </Grid>
        );
    }
};
const TextRegularSmall = styled(TextRegular)`
    line-height: 17px;
    color: ${GRAY80};
    @media screen and (max-width: 960px) {
        margin-bottom: 20px;
    }
`;
