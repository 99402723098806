import React from 'react';
import {BLUE, GRAY90} from '../constants/colors';

const LogoSVG = ({
                            inverted = false,
                            size = '',
                            style = {},
                            fillBG = inverted ? 'white' : BLUE,
                            fillLogo = inverted ? GRAY90 : 'white',
                            width = size ? size : '40',
                            height = size ? size : '40',
                            viewBox = '0 0 40 40',

                        }) => (

    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect width="40" height="40" rx="20" fill={fillBG}/>
        <path d="M21 10V13.03C24.39 13.52 27 16.42 27 19.95C27 20.85 26.82 21.7 26.5 22.49L29.12 24.02C29.68 22.78 30 21.4 30 19.95C30 14.77 26.05 10.5 21 10ZM20 26.95C18.1435 26.95 16.363 26.2125 15.0503 24.8997C13.7375 23.587 13 21.8065 13 19.95C13 16.42 15.61 13.52 19 13.03V10C13.94 10.5 10 14.76 10 19.95C10 22.6022 11.0536 25.1457 12.9289 27.0211C13.8575 27.9497 14.9599 28.6863 16.1732 29.1888C17.3864 29.6913 18.6868 29.95 20 29.95C23.3 29.95 26.23 28.34 28.05 25.86L25.45 24.33C24.17 25.95 22.21 26.95 20 26.95Z" fill={fillLogo}/>
    </svg>
);

export default LogoSVG;