import React, {useEffect} from "react";
import IdleTimer from 'react-idle-timer'
import {useDispatch, useSelector} from "react-redux";
import {setAuthorizationToken, setExpired, setInitialPageId, setName, setPageId, setStatus} from "../actions";
import axios from "axios";
import {useHistory} from "react-router";

export const SetIdleTimer = (props) => {
    let idleTimer = null;
    const openMode = useSelector((state) => state.auth.openMode);
    const dispatch = useDispatch();
    const history = useHistory();
    let onAction = (e) => {

    }

    let onActive = (e) => {

    }

    let onIdle = (e) => {
        axios.defaults.headers.common['Authorization'] = undefined;
        localStorage.removeItem('access_token');
        localStorage.removeItem('status');
        localStorage.removeItem('name');
        dispatch(setAuthorizationToken(null));
        dispatch(setStatus(null));
        dispatch(setName(null));
        dispatch(setExpired(true));
        history.push('/login');
    }

    if (openMode) return null;
    return <IdleTimer
        ref={ref => {
            idleTimer = ref
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={1000 * 60 * 60}/>
};

