import React from 'react';
import Authorization from "../Authorization";
import {Container, Grid} from "@material-ui/core";
import styled from "styled-components";
import {NavLink} from 'react-router-dom'
import {DARK} from "../../constants/colors";


export const MobileMenu = ({items, handleMenu, openMode, status, ...props}) => {

    return (
        <MobileMenuWrapper>
            <Container>
                <Grid container justify={"space-between"}>
                    {
                        items.map((item, key) => {
                            return <Grid item xs={12} key={key}>
                                <MenuLink  {...props}
                                            onClick={handleMenu}
                                           to={item.link}>{item.title}</MenuLink></Grid>
                        })
                    }
                </Grid>
                <AuthWrapper item><Authorization openMode={openMode} status={status} handleMenu={handleMenu}/></AuthWrapper>
            </Container>
        </MobileMenuWrapper>
    );
};

const AuthWrapper = styled(Grid)`
    && {
        margin-top: auto;
    }
`

const MobileMenuWrapper = styled.div`
    display: flex;
    position: fixed;
    height: calc(100% - 100px);
    width: 100%;
    background: white; 
    top: 70px;
    left: 0;
    z-index: 9;
    padding-top: 30px;
    overflow-y: scroll;
`;

const MenuLink = styled(NavLink)`
    font-size: 32px;
    color: ${DARK};
    text-decoration: none;
    text-align: center;
    padding: 20px;
    display: block;
    font-family: Quicksand;
`;