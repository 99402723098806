import {TextRegular} from "../../shared/typography";
import {GRAY60} from "../../constants/colors";
import React from "react";

export const TextBlock = props => {
    if (!props.text) return null;
    return (
        <TextRegular color={props.theme.descriptionBlockTextColor} dangerouslySetInnerHTML={{__html: props.text}}/>
    )
};
