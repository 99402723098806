import React, { Component } from "react";
import { Container } from "@material-ui/core";
import { BoxWrapper } from "../shared/layout";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Title, TextRegular, TextLink } from "../shared/typography";
import { Dot } from "../shared/formElements";
import { GRAY80 } from "../constants/colors";
import Footer from "../components/Footer";
import { CONTACT_URI } from "../constants/sitemap";

export default class Home extends Component {
  render() {
    return (
      <BoxWrapper>
        <Container maxWidth={"lg"}>
          <Container maxWidth={"md"} style={{ padding: 0 }}>
            <Title style={{ margin: "80px 0 30px" }}>About RandomTrack</Title>
            <TextRegular>
              RandomTrack is a self-directed, multi-asset portfolio management
              tool. Our economic and financial market analysis follows a
              systematic diagnostic method which generates a quantified
              corollary time series index for each study variable. The system
              inputs are sourced from published data and do not incorporate
              forecasts. This objectivity acts in opposition to groupthink and
              confirmation bias. RandomTrack's systematised time series database
              provides an empiric platform of indices to explore testable and
              replicable research to inform an investment professional's process
              from single to multi-asset portfolios.
            </TextRegular>
            <TextRegular margin={"10px 0 20px"} style={{ color: GRAY80 }}>
              In general, the expertise, insight and metrics developed by
              RandomTrack are available in three forms:
            </TextRegular>
            <List>
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <Dot />
                </ListItemIcon>
                <ListItemText style={{ color: GRAY80 }}>
                  Referencing the visuals on this site.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <Dot />
                </ListItemIcon>
                <ListItemText style={{ color: GRAY80 }}>
                  Developing one's own research from the diagnostic indices made
                  available by RandomTrack.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <Dot />
                </ListItemIcon>
                <ListItemText style={{ color: GRAY80 }}>
                  Directly engaging RandomTrack's systematic diagnostic alpha,
                  beta and portfolio allocation research.
                </ListItemText>
              </ListItem>
            </List>
            <TextRegular margin={"10px 0 20px"} style={{ color: GRAY80 }}>
              RandomTrack's economic and financial market literacy derives from
              decades of experience in variable modelling, risk management and
              capital allocation.
            </TextRegular>
            <Title style={{ marginTop: "20px" }}>RandomTrack's Services</Title>
            <List>
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <Dot />
                </ListItemIcon>
                <ListItemText style={{ color: GRAY80 }}>
                  <b>Database:</b> An investment professional or a financial
                  institution may request a static copy of RandomTrack's
                  database for proprietary exploration. The provision of the
                  database is free of charge.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <Dot />
                </ListItemIcon>
                <ListItemText style={{ color: GRAY80 }}>
                  <b>Strategist: </b>
                  An investment professional or financial institution may
                  request daily updates of RandomTrack's database for opportune
                  use with an investment process. This service is subject to a
                  fee.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <Dot />
                </ListItemIcon>
                <ListItemText style={{ color: GRAY80 }}>
                  <b>Portfolio Manager: </b>A request for asset management
                  services tailored to the investor's specifications. This
                  service is subject to a fee.
                </ListItemText>
              </ListItem>
            </List>
            <TextRegular margin={"25px 0 20px"} style={{ color: GRAY80 }}>
              Please, detail your interest in RandomTrack's services on{" "}
              <TextLink href={CONTACT_URI}>contact form</TextLink>, thank you.
            </TextRegular>
          </Container>
          <Footer />
        </Container>
      </BoxWrapper>
    );
  }
}
