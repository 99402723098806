export const BLUE = '#367BF5';
export const BLUE_SMOOTH = '#E6EFFF';
export const BLUE_LIGHT = '#F9FAFE';
export const WHITE = '#f1f3f4';
export const GRAY20 = '#F1F3F4';
export const GRAY30 = '#E3E5E6';
export const GRAY50 = '#A6ADAF';
export const GRAY60 = '#919699';
export const GRAY80 = '#5E6366';
export const GRAY90 = '#3E4345';
export const DARK = '#000000de';
export const ORANGE = '#F3AA18';