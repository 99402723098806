import {createStore, compose} from 'redux';
import rootReducer from '../reducers';

const initialState = {};


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
	rootReducer,
	initialState,
	composeEnhancers(),
);

window.store = store;

export default store
