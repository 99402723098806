import React, {Component} from 'react';
import {connect} from "react-redux";
import {api} from "../constants/endpoints";
import {
    getSectionData,
    setAuthorizationToken,
    setExpired,
    setName,
    setPageId,
    setStatus,
    setTermsAgreed,
    setTheme
} from "../actions";
import axios from 'axios';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {Container, Grid} from '@material-ui/core';
import {ChevronRight, KeyboardBackspace} from "@material-ui/icons";
import {LIBRARY_URI, SERVICES_URI} from "../constants/sitemap";
import {SubTitle} from '../shared/typography';
import {BoxWrapper} from '../shared/layout';
import {BLUE, BLUE_SMOOTH} from "../constants/colors";
import styled from 'styled-components';
import {Link, Redirect, Route, Switch} from "react-router-dom";
import Preloader from "../svg/Preloader";
import Footer from "../components/Footer";
import {TabContent} from "../components/PageData/TabContent"

import {ButtonQ} from "../shared/formElements";

import {UserConfirmation} from "../components/UserConfirmation";
import {TermsConfirmation} from "../components/TermsConfirmation";
import {CheckAuth} from "../components/CheckAuth";

class LibraryPage extends Component {
    state = {
        loading: false,
        isLoaded: false,
        error: false,
        value: 0,
        page_data: {},

        validationPopup: false,
        validatedUser: false,
        termsAgreed: false,
        isUserAuth: true,
        userRoles: [
            'Registered User',
            'Strategist & Portfolio Manager',
            'Portfolio Manager'
        ]
    };

    handleSectionData = data => this.props.getSectionData(data);

    getSectionPages = id => {
        this.setState({loading: true});

        axios.get(`${api}section_data/${id}`).then(response => {

                this.handleSectionData(response.data.data);
                this.setState({loading: false});
                this.setState({isLoaded: true});
            }, error => {
                console.log(error)
                let {status} = error.response;
                if (status === 401) {
                    axios.defaults.headers.common['Authorization'] = undefined;
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('status');
                    localStorage.removeItem('name');
                    this.props.setAuthorizationToken(null);
                    this.props.setStatus(null);
                    this.props.setName(null);
                    // this.props.history.push('/login');
                    this.setState({isUserAuth: false})
                }
                this.setState({
                    error
                });
            }
        )
    };

    componentDidMount() {
        if (!this.state.validationPopup) this.setState({validationPopup: true});

        const {
            params: {pageId}
        } = this.props.match;


        let authToken = this.props.authorizationToken || localStorage.getItem('access_token');

        if (authToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        }
        if (!this.props.sectionData.length) this.getSectionPages(pageId);
    }

    handleTabChange = (event, value) => {

        if (value === Number(this.props.initialPageId)) return;
        this.setState({value});
    };
    handleTabClick = (id, theme) => {

        if (id === Number(this.props.initialPageId)) return;

        this.props.history.push(`${this.props.match.url}/${id}`);
        this.props.setTheme(theme);
        this.props.setPageId(id);
    };
    handleDecline = () => {
        this.props.history.push(`/`);
    };
    validateOnCancel = () => {
        this.setState({validationPopup: false, validatedUser: false})
        this.handleDecline();
    }
    validateOnConfirm = () => {
        this.setState({validationPopup: false, validatedUser: true})
    }
    termsOnConfirm = () => {
        this.setState({validatedUser: true, termsAgreed: true})
        this.props.setTermsAgreed(true)

    }
    termsOnCancel = () => {
        this.setState({validatedUser: false, termsAgreed: false})
        this.handleDecline();
    }
    handleChangeUserAuth = bool => this.setState({isUserAuth: bool});

    render() {
        const {isLoaded, loading, error, isUserAuth, userRoles} = this.state;
        const {
            match: {
                params: {pageId}
            },
            name,
            status,
            sectionData: {section, pages},
            openMode,
            initialPageId
        } = this.props;
        const userAccessMinLevel = section ? section.access_pages_min_level ? section.access_pages_min_level : section.access_subsections_min_level : null
        const userAccessMaxLevel = section ? section.access_pages_max_level ? section.access_pages_max_level : section.access_subsections_max_level : null
        const userAccessLevel = userRoles.slice(userAccessMinLevel, userAccessMaxLevel + 1)
        const falsyStatus = status === '' || status === null;

        if (!isUserAuth) {
            return <Redirect to="/login" />
        }

        if (falsyStatus && !openMode) {
            return <BoxWrapper><Container maxWidth={"md"}>
                <Grid container direction={"column"} alignContent={"center"} style={{marginTop: "100px"}}><SubTitle
                    style={{display: "inline"}} textAlign={"center"}>You must be a registered user in order to access
                    data. </SubTitle>
                    <Grid container justify={"space-around"} style={{padding: "50px 0"}}><ButtonQ onClick={() => {
                        this.props.history.push("/register")
                    }}>Register</ButtonQ> <ButtonQ onClick={() => {
                        this.props.history.push("/login")
                    }}>Log In</ButtonQ></Grid>
                </Grid>
            </Container></BoxWrapper>
        }

        if (!this.state.isLoaded) {
            return loading &&
                <BoxWrapper><Grid container justify={"center"} style={{paddingTop: "35px"}}><Preloader color={BLUE}/></Grid></BoxWrapper>
        } else {
            return (
                <>
                    {name && <CheckAuth history={this.props.history} onChangeAuth={this.handleChangeUserAuth} />}

                    {openMode && falsyStatus && !this.props.termsAgreed ?
                        <>
                            <UserConfirmation
                                open={this.state.validationPopup}
                                onCancel={this.validateOnCancel}
                                onConfirm={this.validateOnConfirm}
                            />
                            <TermsConfirmation
                                open={this.state.validatedUser}
                                onCancel={this.termsOnCancel}
                                onConfirm={this.termsOnConfirm}
                            />
                        </> : null}

                    <BoxWrapper>
                        {error || !pages.length ?
                            <Grid container justify={"center"} style={{paddingTop: "35px", alignItems: "center"}}>
                                <p style={{ textAlign: 'center' }}>
                                    {
                                        userAccessMinLevel === 2 && userAccessMaxLevel === 2 ?
                                            '\'Portfolio Manager\'' :
                                            '\'Strategist\' and \'Portfolio Manager\''
                                    } registered users have access to this data and analysis.
                                    <br /><br /> For more information, please reference the <Link to={SERVICES_URI} style={{ color: BLUE }}>'Services'</Link> page.
                                </p>
                            </Grid> :
                            <Container maxWidth={"lg"}>
                                <Grid container>
                                    <Grid item xs={12} md={3} style={{marginTop: "35px"}}>
                                        <SubTitle style={{display: "flex", alignItems: "center"}}
                                                  margin={"0 0 20px"}>
                                            <Link to={LIBRARY_URI}
                                                  style={{display: "flex"}}>
                                                <KeyboardBackspace
                                                    style={{color: BLUE, paddingRight: "5px"}}/>
                                            </Link>{section.title}
                                        </SubTitle>
                                        <TabsLib
                                            orientation="vertical"
                                            value={this.props.initialPageId ? Number(this.props.initialPageId) : pages[0].id}
                                            onChange={this.handleTabChange}
                                        >
                                            {pages.map((page, key) => {
                                                return <TabLib label={page.title} id={page.id}
                                                               value={page.id}
                                                               onClick={() => this.handleTabClick(page.id, page.theme)}
                                                               key={page.id}
                                                />
                                            })}
                                        </TabsLib>
                                    </Grid>
                                    <TabContentWrapper item sm={12} md={9} >
                                        <Switch>
                                            <Route path={`${this.props.match.url}/:tabId`}
                                                   render={(props) => <TabContent {...this.props}/>}
                                            />
                                            <Route render={props => <TabContent {...this.props}/>}/>
                                        </Switch>
                                    </TabContentWrapper>
                                </Grid>
                                <Footer/>
                            </Container>
                        }
                    </BoxWrapper>
                </>
            )
        }
    }
}
const TabContentWrapper = styled(Grid)`
    &&{
        background-color: #fafafc;
        padding: 0 40px 0 20px;
        border-left: 1px solid #F1F3F4;
            @media screen and (max-width: 960px) {
                padding: 0;
                overflow-x: scroll;
        }
    }
`

const TabsLib = styled(Tabs)`
&&{
    padding-right: 40px; 
    overflow: auto;
        
        & .MuiTabs-indicator {
            width: 100%;
            background: ${BLUE_SMOOTH};
            border-radius: 50px;
            
            z-index: -1;
        }
    }
`;
const TabLib = styled(Tab)`
    &&{
        padding-left: 35px;
        font-size: 14px;
        text-transform: none;
        text-align: left;
        @media screen and (max-width: 960px) {
            width: 100%;
            max-width: none;    
        }
        &.Mui-selected{
            color: ${BLUE};
            font-weight: 600;
        }
        & .MuiTab-wrapper{
            align-items: flex-start;
        }
        & .MuiTouchRipple-root{
            border-radius: 50px;
        }
    }
`;


const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    libSections: state.lib.libSections,
    sectionData: state.lib.sectionData,
    initialPageId: state.lib.initialPageId.initialPageId,
    pageId: state.lib.pageId,
    theme: state.lib.theme,
    openMode: state.auth.openMode,
    termsAgreed: state.lib.termsAgreed,
});

export default connect(mapStateToProps, {
    getSectionData,
    setStatus,
    setPageId,
    setTheme,
    setExpired,
    setAuthorizationToken,
    setName,
    setTermsAgreed
})(LibraryPage);
