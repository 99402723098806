import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import {Redirect} from 'react-router';
import {ButtonR, LineSpacer, InputDefault} from '../shared/formElements'
import {Title, TextLink, TextRegular} from '../shared/typography';
import css from './login.module.css';
import Logo from '../components/logo';
import axios from 'axios';
import {stringify} from "qs";
import {setStatus, setName, setAuthorizationToken, setExpired} from '../actions';
import {connect} from 'react-redux';
import {api} from '../constants/endpoints';
import Preloader from '../svg/Preloader';

import {UserConfirmation} from "../components/UserConfirmation";
import {TermsConfirmation} from "../components/TermsConfirmation";
import {BoxWrapper} from "../shared/layout";

class Login extends Component {

    state = {
        loading: false,
        email: '',
        emailInitial: true,
        success: false,
        message: '',
    };

    componentDidMount() {

    }


    handleChange = prop => event => {
        this.setState({[prop]: event.target.value}); //, [`${prop}Initial`]: false
    };

    handleEnterPress = event => {
        if (event.key === 'Enter') {
            this.restore();
        }
    };

    handleError(error) {
        this.setState({loading: false, errorResponse: error});
    }

    isValid() {

        let trimEmail = this.state.email.trim();
        this.setState({
            email: trimEmail,
        });
        return trimEmail && trimEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    restore() {
        if (this.isValid()) {
            this.setState({loading: true});
            const postData = stringify({
                email: this.state.email,
            });

            axios.post(`${api}password/email`, postData)
                .then(response => {
                    this.setState({success: response.data.success, message: response.data.message, loading: false, errorResponse: false});
                })
                .catch((error) => {
                    if (error.response) {
                        this.handleError(error.response.data);
                    }
                });
        } else {
            this.setState({
                emailInitial: false,
                passwordInitial: false,
            });
        }
    }

    render() {
        const {errorResponse, loading, success, email, emailInitial, message} = this.state;
        return (
            <>

                <BoxWrapper>
                    <Container maxWidth="xs">
                        <Grid container alignItems="center" direction="column" className={css.wrapper}>
                            <Logo className={css.logo} justify="center"/>
                            <Title className={css.login}>Restore password</Title>


                            <InputDefault label="Email" variant="outlined" fullWidth
                                          error={!emailInitial && !(email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))}
                                          id="email"
                                          value={email}
                                          onChange={this.handleChange('email')}
                                          onKeyPress={this.handleEnterPress}
                                          inputProps={{
                                              'aria-label': 'Email',
                                          }}

                            />

                            <ButtonR fullWidth style={{marginTop:"15px"}}
                                     onClick={() => {
                                         this.restore()

                                     }}
                            >Send Reset Link {loading && <Preloader/>} </ButtonR>

                            {errorResponse && <TextRegular style={{color: "red"}}>
                                {errorResponse.message || 'Error'}
                            </TextRegular>}
                            {success && <TextRegular style={{color: "green"}}>
                                {message || 'Email has been sent'}
                            </TextRegular>}



                        </Grid>
                    </Container>
                </BoxWrapper>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    expired: state.auth.expired,
    openMode: state.auth.openMode,
});

export default connect(mapStateToProps, {
    setStatus, setName, setAuthorizationToken, setExpired
})(Login);