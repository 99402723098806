import React, {Component} from 'react';
import {DARK} from "../../constants/colors";
import {AuthLink, RegButton} from './styled'
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import axios from "axios";
import Popover from "@material-ui/core/Popover";
import {TextRegular} from "../../shared/typography";
import {Grid, withStyles} from "@material-ui/core";
import {stringify} from "qs";
import {api} from "../../constants/endpoints";

const styles = theme => ({

    wrapMobile: {
        [theme.breakpoints.down("sm")]: {
            flexDirection:"column",
            paddingBottom: "30px",
        }
    },
    linkMobile: {
        [theme.breakpoints.down("sm")]: {
            color: DARK
        }
    },
});

 class Authorization extends Component {
    state = {
        anchorEl: undefined,
    };

    handleSetStatus = status => this.props.setStatus(status);
    handleSetName = name => this.props.setName(name);
    handleSetAuthorizationToken = token => this.props.setAuthorizationToken(token);

    handlePopup = event => {
        event.preventDefault();
        this.setState({anchorEl: event.currentTarget})
    };
    handlePopupClose = () =>{
        this.setState({anchorEl: undefined})
    };
    handleLogout = event => {
        const postData = stringify({});

        event.preventDefault();
        axios.post(`${api}logout`, postData)
            .then(response => {
                if (response.data.success) {
                    axios.defaults.headers.common['Authorization'] = undefined;
                    localStorage.clear();
                    this.handleSetAuthorizationToken(null);
                    this.handleSetStatus(null);
                    this.handleSetName(null);

                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    render() {

        let {anchorEl} = this.state;
        let {status, classes} = this.props;

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return Boolean(status) || status === 0 ?
            <Grid container alignItems={"center"} justify={"flex-end"}>

                <AccountCircle fontSize="large" style={{color: 'white', cursor: "pointer"}} onClick={this.handlePopup}/>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopupClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <TextRegular onClick={this.handleLogout} style={{cursor:"pointer", padding: "5px 10px", fontSize: "14px", display: "flex"}}>Log Out <ExitToApp fontSize="small" style={{color: 'lightgray', marginLeft: '10px'}}/></TextRegular>
                </Popover>
            </Grid>
            :
            <Grid container item alignItems={"center"} className={classes.wrapMobile}>
                <AuthLink onClick={this.props.handleMenu} to="/login" className={classes.linkMobile}>Log In</AuthLink>
                <RegButton onClick={this.props.handleMenu} to="/register">Registration</RegButton>
            </Grid>;

    }
}

 export default withStyles(styles)(Authorization);
