import React, {Component} from 'react';
import {MenuWrapper, MenuLink} from "./styled";
import styled from "styled-components";
import {TextRegular} from "../../shared/typography";
import {GRAY80} from "../../constants/colors";

export const MainMenu = ({items, inverted, fontSize, footer, ...props}) => {
    return (
        <MenuWrapper>
            {
                items.map((item, key) => {
                    if (footer) {
                        return <FooterMenuLink inverted={inverted} fontSize={fontSize} {...props} key={key}
                                               to={item.link}>{item.title}</FooterMenuLink>
                    }
                    return <MenuLink inverted={inverted} fontSize={fontSize} {...props} key={key}
                                     to={item.link}>{item.title}</MenuLink>;
                })
            }
        </MenuWrapper>
    );
};

const FooterMenuLink = styled(MenuLink)`
    ;padding-top: 30px;
`;