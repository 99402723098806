import React, {Component} from 'react';
import {Container, Box, Grid, Button} from '@material-ui/core';
import {BoxWrapper} from '../shared/layout';
import {Title, TextRegular, SubTitle, TextLink} from '../shared/typography';
import axios from "axios";
import {connect} from "react-redux";
import {setAuthorizationToken, setName, setStatus} from "../actions";
import {api} from "../constants/endpoints";
import {BLUE, BLUE_SMOOTH, GRAY30} from "../constants/colors";
import Preloader from "../svg/Preloader";
import Footer from "../components/Footer";

class Terms extends Component {
    state = {
        declined: false,
        loading: false,
        policyContent: null,
    };

    getPolicyText = () => {
        this.setState({loading: true});
        axios.get(`${api}policy`).then(response => {
                this.setState({policyContent: response.data.data[0].content});
                this.setState({loading: false});
                this.setState({isLoaded: true});
            }, error => {
                this.setState({
                    error
                });
            }
        )
    };


    componentDidMount() {

        this.getPolicyText();

    }

    render() {
        let {status, openMode} = this.props;
        let {isLoaded, loading} = this.state;

        return (
            <BoxWrapper>
                <Container maxWidth={"lg"}>
                    <Container maxWidth={"md"} style={{padding: 0}}>
                        <Title style={{margin: "80px 0 30px"}}>Privacy Policy</Title>
                        {loading && <Grid container justify={"center"} style={{paddingTop: "35px"}}><Preloader color={BLUE}/></Grid>}
                        <Grid container spacing={3}>
                            <Grid item container md={12} xs={12}>

                                <div dangerouslySetInnerHTML={{__html: this.state.policyContent}}/>


                            </Grid>
                        </Grid>
                    <Footer/>
                    </Container>
                </Container>


            </BoxWrapper>
        )
    }
}



const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    openMode: state.auth.openMode,
});

export default connect(mapStateToProps, {
    setStatus, setName, setAuthorizationToken
})(Terms);