import {Grid} from "@material-ui/core";
import {useDispatch, useSelector} from 'react-redux'
import {setAuthorizationToken, setExpired, setInitialPageId, setName, setPageId, setStatus} from "../../actions";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

import Card from "../Card";
import {SubTitle} from "../../shared/typography";
import React, {useEffect, useState} from "react";
import {TableBlock} from "./TableBlock";
import {ChartBlock} from "./ChartBlock";
import {TextBlock} from "./TextBlock";
import {Favorite} from "./Favorite";
import {Route, useRouteMatch, useParams, Link} from "react-router-dom";
import axios from "axios";
import {api} from "../../constants/endpoints";
import Preloader from "../../svg/Preloader";
import {BLUE} from "../../constants/colors";
import {CSSTransition} from "react-transition-group";
import {THEMES} from "../../constants/themes";
import logo from "../logo";
import {BoxWrapper} from "../../shared/layout";
import {SERVICES_URI} from "../../constants/sitemap";

export const TabContent = (props) => {
    let {path, url} = useRouteMatch();
    let {tabId} = useParams();


    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [userRoles, setUserRoles] = useState([
        'Registered User',
        'Strategist & Portfolio Manager',
        'Portfolio Manager'
    ])
    const status = useSelector((state) => state.auth.status)

    const {value, index, tableTitle, chartTitle, ...rest} = props;

    const userAccessMinLevel = data ? data.page.access_content_min_level ? data.page.access_content_min_level : data.page.access_content_min_level : null
    const userAccessMaxLevel = data ? data.page.access_content_max_level ? data.page.access_content_max_level : data.page.access_content_max_level : null
    const userAccessLevel = userRoles.slice(userAccessMinLevel, userAccessMaxLevel + 1)
    tabId = tabId ? tabId : props.sectionData.pages[0].id;

    const dispatch = useDispatch();

    dispatch(setInitialPageId(tabId));


    useEffect(() => {


        const getPageData = tabId => {
            setData(null);
            axios.get(`${api}page_data/${tabId}`).then(response => {
                    setData({...response.data.data});
                    setLoaded(true);
                    setError(false);
                }, error => {
                    const {status} = error.response;
                    if (status === 401) {
                        axios.defaults.headers.common['Authorization'] = undefined;
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('status');
                        localStorage.removeItem('name');
                        dispatch(setAuthorizationToken(null));
                        dispatch(setStatus(null));
                        dispatch(setName(null));
                   //     dispatch(setExpired(true));
                        props.history.push('/login');
                    }
                    setLoaded(false);
                    setError(true)
                }
            )
        };
        getPageData(tabId);
    }, [tabId, error]);

    if (error) return <Grid container justify={"center"} style={{paddingTop: "35px"}}>There is no data!</Grid>;
    if (!data) return <Grid container justify={"center"} style={{paddingTop: "35px"}}><Preloader color={BLUE}/></Grid>;

    else {

        const theme = THEMES[data.page.theme];

        return (
            <CSSTransition
                in={loaded}
                timeout={300}
                classNames="alert"
                unmountOnExit
            >
                <>
                    {data.content.length ? <Grid component="div"
                                          role="tabpanel"
                                          hidden={value !== index}
                    >
                        {data.content.map((section, key)=>{
                            let descriptionBg = section.type==='text' ? theme.descriptionBlockBG : false;
                            let descriptionTitleColor = section.type==='text' ? theme.descriptionBlockTextColor : theme.color;

                            return <Card key={key} theme={theme} descriptionBg={descriptionBg}>
                                <SubTitle fontSize={"20px"}
                                          margin={"0 0 20px"}
                                          style={{color:descriptionTitleColor}}
                                >
                                    {section.title ? section.title : ""}
                                    <Favorite favorite={section['is_favorite']}
                                              id={section.id}
                                    />
                                </SubTitle>
                                {section.type === 'chart' ? <ChartBlock
                                        options={section.charts}
                                        theme={theme}
                                    />
                                    : ''}
                                {section.type==='table' ? <TableBlock theme={theme} data={section.tables}/> : ''}
                                {section.type==='text' ? <TextBlock theme={theme} text={section.description}/> : ''}
                            </Card>
                        })}
                    </Grid> :
                        <BoxWrapper>
                            <Grid container justify={"center"} style={{paddingTop: "35px", alignItems: "center"}}>
                                <p style={{ textAlign: 'center' }}>
                                    {
                                        userAccessMinLevel === 2 && userAccessMaxLevel === 2 ?
                                            '\'Portfolio Manager\'' :
                                            '\'Strategist\' and \'Portfolio Manager\''
                                    } registered users have access to this data and analysis.
                                    <br /><br /> For more information, please reference the <Link to={SERVICES_URI} style={{ color: BLUE }}>'Services'</Link> page.
                                </p>
                            </Grid>
                        </BoxWrapper>
                    }

                </>
            </CSSTransition>
        )
    }
};