import React, {useEffect} from "react";
import {setOpenMode} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {api} from "../constants/endpoints";

export const CheckOpenMode = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
            axios.get(`${api}mode`).then(response => {
                    dispatch(setOpenMode(response.data.data.mode));
                }, error => {
                }
            )
    }, []);

    return null
};