import React, {Component} from 'react';
import {Container, Grid, withStyles} from '@material-ui/core';

import {Title, TextRegular, SubTitle} from '../shared/typography';
import {BoxWrapper} from '../shared/layout';
import {ButtonQ} from '../shared/formElements';
import {BLUE_LIGHT, DARK} from "../constants/colors";

import {ChartBlock} from "../components/PageData/ChartBlock";
import {chart1, chart2, chart3} from '../constants/hardCharts'
import {THEMES} from "../constants/themes";

import styled from "styled-components";

import {connect} from "react-redux";
import {setAuthorizationToken, setName, setStatus} from "../actions";


import Footer from "../components/Footer";

import HighchartsReact from 'highcharts-react-official'
import NavigationPrompt from "react-router-navigation-prompt";
import {UserConfirmation} from "../components/UserConfirmation";
import {Favorite} from "../components/PageData/Favorite";
import {TableBlock} from "../components/PageData/TableBlock";
import {TextBlock} from "../components/PageData/TextBlock";
import Card from "../components/Card";
import {SERVICES_URI} from "../constants/sitemap";


const styles = theme => ({
    title: {
        fontSize: "81px",
        textAlign: 'center',
        marginBottom: "10px",
        letterSpacing: '10px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "34px",
            marginBottom: '0',
            lineHeight: 'normal'
        }
    },
    subtitle: {
        fontSize: "34px",
        textAlign: "center",
        margin: "0 0 25px",
        [theme.breakpoints.down("sm")]: {
            lineHeight: "24px",
            fontSize: "28px",
        }
    },
    flexInvert: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column-reverse',
            '& > *': {
                flex: 1,
            },
        }
    },
    descriptionBlock: {
        padding: '30px',
        [theme.breakpoints.down("sm")]: {
            padding: '0'
        }
    }

});

const ChartContainer = styled(Grid)`
    margin-top: 130px;
`;
const theme1 = THEMES[1];

class Home extends Component {

    render() {
        return (
            <React.Fragment>
                {this.body}
            </React.Fragment>
        );
    }


    get body() {

        let {classes} = this.props;
        return (
            <BoxWrapper>
                <Container maxWidth={false} style={{padding: 0}}>
                    <Container style={{backgroundColor: BLUE_LIGHT, padding: 0}} maxWidth={false}>
                        <Container maxWidth={"lg"}>

                            <Grid container direction={'column'} item justify={'center'} alignItems={'center'} xs={12}
                                  style={{padding: "100px 0 50px"}}>
                                <Title className={classes.title}>
                                    RandomTrack
                                </Title>

                                <TextRegular className={classes.subtitle}>
                                    Financial & Economic
                                    Diagnostic Analysis
                                </TextRegular>
                            </Grid>
                        </Container>
                    </Container>


                    <Container maxWidth="lg">
                        <Grid container justify="center">

                            <Grid container item xs={12} md={6} justify={'center'} alignItems={'center'}>
                                <SubTitle margin="0" textAlign="center" style={{marginBottom: '30px'}}>
                                A compendium of systematic diagnostic indices or their quantified analogues to inform the user's comprehension of financial markets and economic variables.
                                </SubTitle>
                                <ButtonQ rounded={1} href="/library">Library</ButtonQ>
                            </Grid>

                            <ChartContainer container alignItems="center">

                                <Grid item xs={12} md={4} style={{paddingRight: '30px'}}>
                                    <TextRegular>
                                    Systematic frameworks deliver representations of market variables.
                                    </TextRegular>
                                </Grid>

                                <Grid item xs={12} md={8}>

                                    <Card theme={theme1} descriptionBg={theme1.descriptionBlockBG}>
                                        <SubTitle fontSize={"20px"}
                                                  margin={"0 0 20px"}
                                                  style={{color: theme1.color}}
                                        >
                                            US Govt Bond Composite Yield & Analogue
                                        </SubTitle>


                                        <ChartBlock options={chart1} theme={theme1}/>

                                    </Card>

                                </Grid>
                            </ChartContainer>

                            <ChartContainer container alignItems="center" justify={"center"}
                                            className={classes.flexInvert}>
                                <Grid item xs={12} md={8}>
                                    <Card theme={theme1} descriptionBg={theme1.descriptionBlockBG}>
                                        <SubTitle fontSize={"20px"}
                                                  margin={"0 0 20px"}
                                                  style={{color: theme1.color}}
                                        >
                                            Rolling Monthly Return
                                        </SubTitle>

                                        <ChartBlock options={chart2} theme={theme1}/>

                                    </Card>
                                </Grid>

                                <Grid item xs={12} md={4} className={classes.descriptionBlock}>
                                    <TextRegular>
                                    Systematic diagnostics offers a platform for empiric testing and replication of investable strategies.
                                    </TextRegular>
                                </Grid>

                            </ChartContainer>

                            <ChartContainer container alignItems="center">
                                <Grid item xs={12} md={4}>
                                    <TextRegular style={{paddingRight: '20px'}}>
                                    Systematic frameworks deliver representations of economic variables.
                                    </TextRegular>
                                </Grid>
                                <Grid item xs={12} md={8}>

                                    <Card theme={theme1} descriptionBg={theme1.descriptionBlockBG}>
                                        <SubTitle fontSize={"20px"}
                                                  margin={"0 0 20px"}
                                                  style={{color: theme1.color}}
                                        >
                                            Recession Probability, within 12-months
                                        </SubTitle>


                                        <ChartBlock options={chart3} theme={theme1}/>

                                    </Card>


                                </Grid>
                            </ChartContainer>
                            <ChartContainer>
                                <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                    <Grid item md={8} xs={12}><SubTitle style={{textAlign: 'center'}}>Please visit
                                        Services for the details on Database, Strategist and Portfolio Manager user
                                        options.</SubTitle></Grid>
                                    <ButtonQ href={SERVICES_URI} rounded={1} margin={"30px 0 auto"}>Services</ButtonQ>
                                </Grid>
                            </ChartContainer>

                        </Grid>
                    </Container>
                    <Container><Footer full/></Container>
                </Container>
            </BoxWrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    authorizationToken: state.auth.authorizationToken
});

export default connect(mapStateToProps, {
    setStatus, setName, setAuthorizationToken
})(withStyles(styles)(Home));

