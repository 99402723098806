import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {createMuiTheme} from "@material-ui/core";



export const TableBlock = props => {
    let {theme} = props;
    const useStyles = makeStyles({
        table: {

        },
        head:{
            borderBottom: `2px solid ${theme.tableHeaderBorderColor}`,

        },
        headCell:{
            fontWeight: 700,
            color:theme.headFontColor,
            '&:not(:first-of-type)':{
                textAlign:"center",
            }
        },

        tableRow:{

            background: theme.rowBackground,
            '&:nth-child(even)':{
                background: theme.rowBackgroundOdd,
            }
        },
        thCell:{
            color: theme.thFontColor,
            fontWeight: 700,
            borderBottom: `1px solid ${theme.cellBorderColor}`,
        },
        tableCell:{
            textAlign:"center",
            color:theme.cellFontColor,
            borderBottom: `1px solid ${theme.cellBorderColor}`,
            fontSize: '0.750rem',
        },

    });
    const classes = useStyles();

    if (!props.data) return null;

    let {head,data} = props.data;

    return (
        <TableContainer>
            <Table aria-label="customized table" className={classes.table}>
                <TableHead className={classes.head}>
                    <TableRow>
                        {head.map((cell, key)=>{
                            return <TableCell key={key} className={classes.headCell}>{cell}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row,key) => (
                        <TableRow key={key} className={classes.tableRow}>
                            {row.map((cell, key)=>{
                                if (key===0) return <TableCell className={classes.thCell} component={"th"} key={key} scope="row">{cell}</TableCell>;
                                else return <TableCell className={classes.tableCell} key={key} scope="row">{cell}</TableCell>;
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};
