import Button from "@material-ui/core/Button";
import styled from "styled-components";
import {BLUE, GRAY30} from "../constants/colors";
import TextField from '@material-ui/core/TextField';
import Check from "@material-ui/icons/Check";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Link} from "react-router-dom";


const ButtonMain = styled(Button)`
  && {
    color: white;
    background-color: ${BLUE};
    text-transform: none;
    border-radius: ${props => props.rounded ? '30px' : '3px'};
    margin: ${props => props.margin ? props.margin : ''};
    padding: 0;
    padding-left: 35px;
    padding-right: 35px;
    height: 40px;
    line-height: 40px;
    box-shadow: 0px 2px 4px rgba(54, 123, 245, 0.24), 0px 4px 8px rgba(54, 123, 245, 0.16);
    &:hover{
        background-color: ${BLUE};
    }
    @media screen and (max-width: 960px) {
        line-height: normal;
        padding-left: 15px;
        padding-right: 15px;
        
    }
  }
`;

export const InputDefault = styled(TextField)`
    margin-bottom: 15px !important;

`;

export const ButtonR = styled(ButtonMain)`
    font-size: 20px !important;
        height: 50px !important;
    line-height: 50px !important;
    
`;
export const ButtonQ = styled(ButtonMain)`
    font-family: Quicksand;
    font-size: 18px !important;
    &:hover{
    background: ${BLUE};
    }
`;



export const LineSpacer = styled.div`
    height: 1px;
    width: 100%;
    background: ${GRAY30};
    margin: ${props => props.margin ? props.margin : '0'};
`;

export const Checked = styled(Check)`
    color: ${BLUE}
`;
export const Dot = styled(FiberManualRecordIcon)`
    color: ${BLUE};
    width: 0.6em !important;
    height: 0.6em !important;
    
`;