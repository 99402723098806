import React, {Component} from 'react';
import {Container, Box, Grid, Button} from '@material-ui/core';
import AttachFile from "@material-ui/icons/AttachFile";
import {Title, TextRegular, SubTitle, TextLink} from '../shared/typography';
import {BoxWrapper} from '../shared/layout';
import {ButtonR, Checked, InputDefault, LineSpacer} from "../shared/formElements";
import {ButtonQ} from '../shared/formElements';
import {GRAY80, BLUE, BLUE_SMOOTH} from "../constants/colors";
import {stringify} from "qs";
import axios from "axios";
import {api} from "../constants/endpoints";
import Preloader from '../svg/Preloader';
import {Redirect} from "react-router";
import Hidden from "@material-ui/core/Hidden";
import Footer from "../components/Footer";

export default class Contact extends Component {
    state = {
        name: '',
        email: '',
        company: '',
        message: '',
        fileName: '',
        file: '',
        emailInitial: true,
        nameInitial: true,
        messageInitial: true,
        loading: false,
        redirectToReferrer: false,
        errorResponse: null,
        successResponse: null,
        openSnackbar: false
    };

    fileInput = React.createRef();

    handleSubmit(data) {
        this.setState({loading: false, redirectToReferrer: true, successResponse: data});
    }

    handleSubmitError(error) {
        this.setState({loading: false, errorResponse: error, openSnackbar: true});
    }

    isValid() {
        let trimEmail = this.state.email.trim();
        let trimName = this.state.name.trim();
        let trimMessage = this.state.message.trim();
        this.setState({
            email: trimEmail,
            name: trimName,
            message: trimMessage,
        });
        return trimEmail && trimEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && this.state.name && this.state.message

    }

    submit() {
        if (this.isValid()) {
            this.setState({loading: true});

            const postData = {
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
                company: this.state.company,
                file: this.state.file,
                grant_type: "password",
                client_id: 1,
                client_secret: '4zbjNDlHFJ5s9dcKCZkI4dDPoekMGnEl9XvzYy6e'
            };

            const formData = new FormData();
            Object.keys(postData).map((key, i) => {
                formData.append(key, postData[key]);
            });

            axios.post(`${api}contact`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data;'
                }
            })
                .then(response => {
                    this.handleSubmit(response.data);
                })
                .catch((error) => {
                    if (error.response) {

                        this.handleSubmitError(error.response.data);
                    } else if (error.request) {

                    } else {

                    }

                });
        } else {
            this.setState({
                emailInitial: false,
                nameInitial: false,
                messageInitial: false,
                passwordInitial: false
            });
        }
    }


    handleChange = prop => event => {
        if (prop === "file") {

            this.setState({[prop]: this.fileInput.current.files[0]});
            this.setState({[`${prop}Name`]: this.fileInput.current.files[0].name});
        } else {
            this.setState({[prop]: event.target.value}); //, [`${prop}Initial`]: false
        }
    };

    render() {
        const {email, name, company, emailInitial, nameInitial, messageInitial, message, fileName, loading, redirectToReferrer, successResponse} = this.state;
        if (redirectToReferrer) {
            return <Redirect to='/'/>;
        }

        return (
            <BoxWrapper>
                <Container maxWidth={"lg"}>
                    <Container maxWidth={"md"} style={{padding: 0}}>
                        <Title style={{margin: "80px 0 30px"}}>Contact Us</Title>
                        <Grid container spacing={3} style={{position: "relative"}}>
                            <Grid item container md={6} xs={12} style={{position: "relative"}}>
                                <InputDefault id="name" label="Name" variant="outlined" fullWidth
                                              error={!nameInitial && !name}
                                              value={name}
                                              onChange={this.handleChange('name')}
                                              inputProps={{
                                                  'aria-label': 'Name',
                                              }}
                                />
                                <InputDefault id="email" label="Email" variant="outlined" fullWidth
                                              error={!emailInitial && !(email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))}
                                              value={email}
                                              onChange={this.handleChange('email')}
                                              inputProps={{
                                                  'aria-label': 'Email',
                                              }}
                                              type='email'
                                />
                                <InputDefault id="companyName" label="Company Name (Optional)" variant="outlined"
                                              fullWidth
                                              onChange={this.handleChange('company')}
                                              value={company}
                                />

                                <Button variant="outlined" component="label"
                                        style={{
                                            color: BLUE,
                                            borderColor: BLUE_SMOOTH,
                                            fontSize: "14px",
                                            marginTop: "60px"
                                        }}>
                                    <AttachFile fontSize="small" style={{color: BLUE}}/> ATTACH FILE <input type="file"
                                                                                                            ref={this.fileInput}
                                                                                                            onChange={this.handleChange('file')}
                                                                                                            style={{display: "none"}}/>
                                </Button>
                                {fileName &&
                                <p style={{fontSize: "14px", position: "absolute", bottom: "50px"}}>Uploaded
                                    file: {fileName}</p>}

                            </Grid>
                            <Hidden smDown>
                                <LineSpacer style={{
                                    position: "absolute",
                                    bottom: "27%",
                                    padding: "0 12px",
                                    width: "96%",
                                    left: "50%",
                                    transform: "translateX(-50%)"
                                }}/>
                            </Hidden>


                            <Grid container item md={6} xs={12} justify={"flex-end"}>
                                <Grid item xs={12}>
                                    <InputDefault id="message" label="Message" variant="outlined"
                                                  error={!messageInitial && !message}
                                                  fullWidth
                                                  multiline
                                                  rows="9"
                                                  onChange={this.handleChange('message')}
                                                  value={message}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    {successResponse && <TextRegular style={{color: "green"}}>
                                        {successResponse.message || 'Successfully sent!'}
                                    </TextRegular>}
                                    <ButtonR fullWidth style={{marginTop: "60px"}}
                                             onClick={() => {
                                                 this.submit();
                                             }}>Submit {loading && <Preloader/>}</ButtonR>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Container>
                    <Footer/>
                </Container>
            </BoxWrapper>
        )
    }
}

