import {
    STATUS, NAME, SET_AUTHORIZATION_TOKEN, SET_OPEN_MODE
} from './../constants/reducers'
import {SET_EXPIRED} from "../constants/reducers";

const defaultAuth = {
    status: +localStorage.getItem('status') || null,
    name: localStorage.getItem('name') || null,
    editUserId: localStorage.getItem('editUserId') || null,
    categories: [],
    selectCategory: null,
    authorizationToken: localStorage.getItem('access_token') || null,
    editFileId: null,
    openMode: true,
    expired: false,
};

export default (auth = defaultAuth, action) => {
    const {type, payload} = action;

    switch (type) {
        case STATUS:
            return {...auth, status: payload.status};
        case NAME:
            return {...auth, name: payload.name};
        case SET_AUTHORIZATION_TOKEN:
            return {...auth, authorizationToken: payload.authorizationToken};
        case SET_OPEN_MODE:
            return {...auth, openMode: payload.value};
        case SET_EXPIRED:
            return {...auth, expired: payload.value};
        default:
            return auth;
    }
}