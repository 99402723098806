import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {GRAY90, GRAY50} from "../constants/colors";
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Logo from '../components/logo';
import styled from "styled-components";
import {MainMenu} from "./MainMenu";
import {TextRegular} from "../shared/typography"


const Card = props => {
    return (
        <CardWrapper theme={props.theme} descriptionBg={props.descriptionBg}>
            {props.children}
            {props.noDisclaimer || <Caption/>}

        </CardWrapper>
    );
};

const CardWrapper = styled.div`
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    background: ${props => props.descriptionBg ? props.theme.descriptionBlockBG : props.theme.background};
`;

const Caption = props => {
    return (
        <TextRegular fontSize={"10px"}
                     textAlign={"left"}
                     color={GRAY50}
                     lineHeight={'10px'}
                     margin={"20px 0 0"}>©️ Macro Portfolio Manager, LLC. All data and analysis contained herein are historical and are not reliable indicators of future outcomes. All data and analysis contained herein are for information only. All data and analysis contained herein are provided without warranty or guarantee of any kind, either expressed or implied. Using this data and analysis could incur substantial financial losses. The provider of this data and analysis shall not have any liability for any loss sustained by anyone who has relied on the data and analysis contained herein. All data and analysis contained herein are subject to change without notice. The provider of this data and analysis makes no representation or warranty that the information contained herein is accurate, fair, or correct or that any transaction is appropriate for any person and it should not be relied on as such. The data and analysis contained herein are not intended to be an offer to buy or sell, or a solicitation of an offer to buy or sell, any securities.</TextRegular>
    )
};
export default Card;
