import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Provider, useSelector} from "react-redux";
import store from "./store";
import {Redirect, Route, Router, BrowserRouter, Switch} from "react-router-dom";
import Header from "./components/Header";
import {
    HOME_URI,
    LOGIN_URI,
    REGISTER_URI,
    CONTACT_URI,
    LIBRARY_URI,
    LIBRARY_PAGE_URI,
    RESTORE_URI,
    TERMS_URI,
    PRIVACY_URI, SERVICES_URI, RESET_URI
} from "./constants/sitemap";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Favourites from "./pages/Favourites";
import Library from "./pages/Library";
import LibraryPage from "./pages/LibraryPage";
import Terms from "./pages/Terms";
import Footer from "./components/Footer";
import {UserConfirmation} from "./components/UserConfirmation";
import {SetIdleTimer} from './components/SetIdleTimer'
import {createBrowserHistory} from "history";
import Privacy from "./pages/Privacy";
import {CheckOpenMode} from "./components/CheckOpenMode";
import {CheckAuth} from "./components/CheckAuth";
import logo from "./components/logo";
import Restore from "./pages/Restore";
import ResetPass from "./pages/ResetPass";


const hist = createBrowserHistory();

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router history={hist}>
                    <CheckOpenMode/>

                    <SetIdleTimer/>
                    <Switch>
                        <Route exact path={LOGIN_URI} component={Login}/>
                        <Route exact path={RESTORE_URI} component={Restore}/>
                        <Route exact path={RESET_URI} component={ResetPass}/>
                        <Route exact path={REGISTER_URI} component={Register}/>


                        <Route render={()=><MainRoutes history={hist}/>}/>
                    </Switch>
                </Router>
            </Provider>
        )
    }
}


const AuthenticateRoutes = props => {
    const isLoggedIn = useSelector((state) => state.auth.name);

    return (
        <>

            <Switch>
                <Route exact path={HOME_URI} render={(props) => {
                    return (
                        isLoggedIn ? <Favourites history={props.history}/> : <Home/>
                    )
                }}/>

                <Route path="*" render={() => (<Redirect to={"/"}/>)}/>
            </Switch>
        </>
    );
};


const MainRoutes = props => (
    <React.Fragment>
        <Header history={props.history}/>
        <Switch>
            <Route exact path={SERVICES_URI} component={Services}/>
            <Route exact path={CONTACT_URI} component={Contact}/>
            <Route exact path={LIBRARY_URI} component={Library}/>
            <Route path={`${LIBRARY_URI}/:pageId`} component={LibraryPage}/>
            <Route exact path={TERMS_URI} render={()=><Terms history={hist}/>}/>
            <Route exact path={PRIVACY_URI} component={Privacy}/>
            <Route render={()=><AuthenticateRoutes history={props.history}/>}/>
        </Switch>
    </React.Fragment>
);