import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {GRAY90} from "../constants/colors";
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Logo from '../components/logo';
import styled from "styled-components";
import {MainMenu} from "./MainMenu";
import Authorization from "./Authorization";
import {connect} from "react-redux";
import {setAuthorizationToken, setName, setStatus} from "../actions";
import Hidden from "@material-ui/core/Hidden";
import {Menu, Close} from "@material-ui/icons";
import {MobileMenu} from './MainMenu/MobileMenu';
import {MAIN_MENU, MOBILE_MAIN_MENU} from '../constants/menus'

import {CSSTransition} from 'react-transition-group';

class Header extends Component {
    state = {
        menuOpen: false,
    };

    handleMenu = () => {
        this.setState({menuOpen: !this.state.menuOpen})

    };

    preventDefault = (e) => {
        e.preventDefault();
    };
    preventDefaultForScrollKeys = (e) => {
        var keys = {37: 1, 38: 1, 39: 1, 40: 1};
        if (keys[e.keyCode]) {
            this.preventDefault(e);
            return false;
        }
    };
    disableScroll = () => {
        var supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () {
                    supportsPassive = true;
                }
            }));
        } catch (e) {
        }
        var wheelOpt = supportsPassive ? {passive: false} : false;
        var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    };
    enableScroll = () => {
        var supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () {
                    supportsPassive = true;
                }
            }));
        } catch (e) {
        }
        var wheelOpt = supportsPassive ? {passive: false} : false;
        var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    };

    render() {
        let {menuOpen} = this.state;
        let {openMode} = this.props;

        return (
            <HeaderWrapper>
                <MenuWrapper maxWidth="lg">
                    <MenuContainer container alignItems="center" justify={"space-between"}>

                        <Hidden mdUp>
                            {menuOpen ? <Close onClick={this.handleMenu} fontSize="large"
                                               style={{color: "white", marginRight: "10px"}}/> :
                                <Menu onClick={this.handleMenu} fontSize="large"
                                      style={{color: "white", marginRight: "10px"}}/>}
                            <CSSTransition
                                in={menuOpen}
                                timeout={300}
                                classNames="alert"
                                unmountOnExit
                            ><MobileMenu openMode={openMode} status={this.props.status} handleMenu={this.handleMenu}
                                         items={MOBILE_MAIN_MENU}/></CSSTransition>
                        </Hidden>

                        <Grid item xs={"auto"} md={3}>
                            <Logo inverted={true} to={'/'} onClick={()=>{this.setState({menuOpen: false})}}/>
                        </Grid>

                        <Hidden smDown>
                            <Grid item xs={4} sm={4} md={6} >
                                <MainMenu
                                    items={MAIN_MENU}
                                />
                            </Grid>
                        </Hidden>


                        <Grid container item xs={1} md={3} justify={"flex-end"} alignItems={"center"}>
                            {this.props.status || this.props.status === 0 ?
                                <Authorization {...this.props}
                                />
                                :
                                <Hidden smDown><Authorization {...this.props}
                                /></Hidden>}
                        </Grid>

                    </MenuContainer>
                </MenuWrapper>

            </HeaderWrapper>
        );
    }
}


const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    authorizationToken: state.auth.authorizationToken,
    openMode: state.auth.openMode
});

export default connect(mapStateToProps, {
    setStatus, setName, setAuthorizationToken
})(Header);


const HeaderWrapper = styled(Box)`
    background: ${GRAY90};
    height: 70px;
    box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
    position: relative;
    z-index: 10;
    @media screen and (max-width: 960px) {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
    }
`;
const MenuWrapper = styled(Container)`
    height: 100%;
`;
const MenuContainer = styled(Grid)`
    height: 100%;
`;