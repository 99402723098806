import styled from "styled-components";
import React from 'react';
import {BLUE_SMOOTH, DARK} from "../../constants/colors";
import {NavLink} from 'react-router-dom'



export const MenuLink = styled(({primary, ...props}) => <NavLink {...props} />)`
    color: ${props => props.inverted ? DARK : 'white'};
    font-family: Quicksand;
    font-weight: 500;
    font-size: ${props => props.fontSize ? props.fontSize : '16px'};
    text-decoration: none;
    display: inline-block;    
    margin: 0 10px;
    border: 3px solid transparent;
    padding: 23px 0;
    width: max-content;
    &:first-of-type{
        margin-left: 0;
    }
    &:last-of-type{
        margin-right: 0;
    }
    
    ${(props) => props.nodecorate || `
        &:hover{
            border-bottom: 3px solid ${BLUE_SMOOTH};
        }
        &.active{
            color: white;
            border-bottom: 3px solid ${BLUE_SMOOTH};
        }
    `}
    
`;

export const MenuWrapper = styled.nav`
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: none;
    height: 100%;
    
`;