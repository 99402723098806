export const HOME_URI = '/';
export const LOGIN_URI = '/login';
export const REGISTER_URI = '/register';
export const CONTACT_URI = '/contact';
export const LIBRARY_URI = '/library';
export const LIBRARY_PAGE_URI = '/library-page';
export const SERVICES_URI = '/services';
export const TERMS_URI = '/terms';
export const PRIVACY_URI = '/privacy';
export const RESTORE_URI = '/restore-password';
export const RESET_URI = '/password/reset';