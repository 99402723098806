import React, {Component} from 'react';
import {connect} from "react-redux";
import {api} from "../constants/endpoints";
import {setAuthorizationToken, setExpired, setLinks, setName, setStatus, setTermsAgreed} from "../actions";
import axios from 'axios';
import List from '@material-ui/core/List';
import {Link, Redirect} from 'react-router-dom';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {LIBRARY_PAGE_URI, LIBRARY_URI} from "../constants/sitemap";
import ListItem from '@material-ui/core/ListItem';

import {Container, Box, Grid, withStyles, IconButton} from '@material-ui/core';
import {ChevronRight, ExpandMore, KeyboardBackspace} from "@material-ui/icons";

import Preloader from '../svg/Preloader';
import Grow from '@material-ui/core/Grow';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import {Title, TextRegular, SubTitle, TextLink} from '../shared/typography';
import {BoxWrapper} from '../shared/layout';
import {DARK, GRAY80, BLUE, GRAY30} from "../constants/colors";

import styled from 'styled-components';
import Footer from "../components/Footer";
import {ButtonLink, ButtonQ, ButtonR} from "../shared/formElements";
import Terms from "./Terms";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {UserConfirmation} from "../components/UserConfirmation";
import {TermsConfirmation} from "../components/TermsConfirmation";
import {CheckAuth} from "../components/CheckAuth";

class Library extends Component {
    state = {
        loading: false,
        error: false,
        scrolledDown: false,

        validationPopup: false,
        validatedUser: false,
        termsAgreed: false,
        isUserAuth: true,
        userRoles: [
            'Registered User',
            'Strategist',
            'Portfolio Manager'
        ]
    };

    handleSections = sections => this.props.setLinks(sections);

    getSectionList = () => {
        this.setState({loading: true});
        axios.get(`${api}sections_list`).then(response => {
                this.handleSections(response.data.data);
                this.setState({loading: false});
            }, error => {
            let {status} = error.response;
                if (status === 401) {
                    axios.defaults.headers.common['Authorization'] = undefined;
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('status');
                    localStorage.removeItem('name');
                    this.props.setAuthorizationToken(null);
                    this.props.setStatus(null);
                    this.props.setName(null);
                    // this.props.history.push('/login');
                    this.setState({isUserAuth: false})
                }
                this.setState({
                    error
                });
            }
        )
    };

    componentDidMount() {
        if (!this.state.validationPopup) this.setState({validationPopup: true});

        let authToken = this.props.authorizationToken || localStorage.getItem('access_token');
        if (authToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
        }
        if (!this.props.libSections.length) this.getSectionList();
    }

    handleDecline = () => {
        this.props.history.push(`/`);
    };
    validateOnCancel = () => {
        this.setState({validationPopup: false, validatedUser: false})
        this.handleDecline();
    }
    validateOnConfirm = () => {
        this.setState({validationPopup: false, validatedUser: true})
    }
    termsOnConfirm = () => {
        this.setState({validatedUser: true, termsAgreed: true})
        this.props.setTermsAgreed(true)
    }
    termsOnCancel = () => {
        this.setState({validatedUser: false, termsAgreed: false})
        this.handleDecline();
    }
    handleChangeUserAuth = bool => this.setState({isUserAuth: bool});

    render() {
        const {status, libSections, openMode, name} = this.props;
        const {loading, isUserAuth, userRoles} = this.state;
        const userAboveRoles = userRoles.slice(status + 1);
        const falsyStatus = status === '' || status === null;

        if (!isUserAuth) {
            return <Redirect to="/login" />
        }

        if (falsyStatus && !openMode) {
            return <BoxWrapper><Container maxWidth={"md"}>
                <Grid container direction={"column"} alignContent={"center"} style={{marginTop: "100px"}}><SubTitle
                    style={{display: "inline"}} textAlign={"center"}>You must be a registered user in order to access
                    data. </SubTitle>
                    <Grid container justify={"space-around"} style={{padding: "50px 0"}}><ButtonQ onClick={() => {
                        this.props.history.push("/register")
                    }}>Register</ButtonQ> <ButtonQ onClick={() => {
                        this.props.history.push("/login")
                    }}>Log In</ButtonQ></Grid>
                </Grid>
            </Container></BoxWrapper>
        }

        return (
            <>
                {name && <CheckAuth history={this.props.history} onChangeAuth={this.handleChangeUserAuth} />}

                {openMode && falsyStatus && !this.props.termsAgreed ?
                <>
                    <UserConfirmation
                        open={this.state.validationPopup}
                        onCancel={this.validateOnCancel}
                        onConfirm={this.validateOnConfirm}
                    />
                    <TermsConfirmation
                        open={this.state.validatedUser}
                        onCancel={this.termsOnCancel}
                        onConfirm={this.termsOnConfirm}
                    />
                </> : null}

                <BoxWrapper style={{backgroundColor: "#fafafc", height: "100%"}}>
                    <Container maxWidth={"lg"} style={{height: "100%"}}>
                        <Container maxWidth={"md"}
                                   style={{padding: 0, height: "100%", display: "flex", flexDirection: "column"}}>

                            <Title style={{margin: "80px 0 30px"}}>Library</Title>

                            {
                                loading ?
                                    <Grid container justify={"center"}><Preloader color={BLUE}/></Grid> :
                                    <List>
                                        {
                                            !libSections.length ?
                                                <BoxWrapper>
                                                    <Grid container justify={"center"} style={{paddingTop: "35px", alignItems: "center"}}>
                                                        You should be {
                                                        userAboveRoles.map((role, i) => {
                                                            const roleName = role
                                                            const text = userAboveRoles[i+1] ? ' or ' : ''
                                                            return roleName + text
                                                        })
                                                    } to see this data
                                                    </Grid>
                                                </BoxWrapper> :
                                                libSections.map(section => {
                                                    const {id, title, description} = section;

                                                    const labelId = `section-list-${id}`;

                                                    if (!section.children.length)
                                                        return (
                                                            <SectionLink to={`${LIBRARY_URI}/${section.id}`} key={section.id}
                                                                         id={labelId}>
                                                                <SectionListItem dense button
                                                                                 style={{
                                                                                     borderRadius: "10px",
                                                                                     marginBottom: "30px",
                                                                                     background: '#fff',
                                                                                     height: '90px'
                                                                                 }}
                                                                >
                                                                    <SectionData>
                                                                        <SubTitle margin={"0"}  style={{paddingRight: "30px"}}>{section.title}</SubTitle>
                                                                        <TextRegular color={GRAY80}>{description}</TextRegular>
                                                                    </SectionData>

                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="comments">
                                                                            <ChevronRight fontSize={"large"}/>
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </SectionListItem>
                                                            </SectionLink>
                                                        );

                                                    return (
                                                        <ExpansionPanel key={id} style={{
                                                            boxShadow: "none",
                                                            border: "none",
                                                            borderRadius: "10px",
                                                            marginBottom: "30px"
                                                        }}>
                                                            <ExpansionPanelSummary
                                                                expandIcon={<ExpandMore/>}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                style={{height: '90px'}}
                                                            >
                                                                <SubTitle>{title}</SubTitle>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails style={{
                                                                flexDirection: "column",
                                                            }}>
                                                                {section.children.map((subsection, key) => {
                                                                    if (!subsection.children.length)
                                                                        return (
                                                                            <SectionLink to={`${LIBRARY_URI}/${subsection.id}`}
                                                                                         key={subsection.id} id={labelId}>
                                                                                <SectionListItem dense button
                                                                                                 style={{
                                                                                                     borderRadius: "10px",
                                                                                                     marginBottom: "30px",
                                                                                                     background: "#fafafa",
                                                                                                     height: '90px'
                                                                                                 }}
                                                                                >
                                                                                    <SectionData>

                                                                                        <SubTitle style={{paddingRight: "30px"}}
                                                                                                  margin={"0"}>{subsection.title}</SubTitle>
                                                                                        <TextRegular
                                                                                            color={GRAY80}>{description}</TextRegular>
                                                                                    </SectionData>

                                                                                    <ListItemSecondaryAction>
                                                                                        <IconButton edge="end" aria-label="comments">
                                                                                            <ChevronRight fontSize={"large"}/>
                                                                                        </IconButton>
                                                                                    </ListItemSecondaryAction>
                                                                                </SectionListItem>
                                                                            </SectionLink>
                                                                        )
                                                                    return (
                                                                        <ExpansionPanel key={subsection.id} style={{
                                                                            boxShadow: "none",
                                                                            border: "none",
                                                                            borderRadius: "10px",
                                                                            marginBottom: "30px",
                                                                            background: "#fafafa"
                                                                        }}>
                                                                            <ExpansionPanelSummary
                                                                                expandIcon={<ExpandMore/>}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                                style={{height: '90px'}}
                                                                            >
                                                                                <SubTitle>{subsection.title}</SubTitle>
                                                                            </ExpansionPanelSummary>
                                                                            <ExpansionPanelDetails style={{
                                                                                flexDirection: "column",
                                                                            }}>
                                                                                {subsection.children.map((subsection, key) => {
                                                                                    return (
                                                                                        <SectionLink
                                                                                            to={`${LIBRARY_URI}/${subsection.id}`}
                                                                                            key={subsection.id} id={labelId}>
                                                                                            <SectionListItem dense button
                                                                                                             style={{
                                                                                                                 borderRadius: "10px",
                                                                                                                 marginBottom: "30px",
                                                                                                                 background: '#fff'
                                                                                                             }}
                                                                                            >
                                                                                                <SectionData>

                                                                                                    <SubTitle
                                                                                                        margin={"0"}>{subsection.title}</SubTitle>
                                                                                                    <TextRegular
                                                                                                        color={GRAY80}>{description}</TextRegular>
                                                                                                </SectionData>

                                                                                                <ListItemSecondaryAction>
                                                                                                    <IconButton edge="end"
                                                                                                                aria-label="comments">
                                                                                                        <ChevronRight
                                                                                                            fontSize={"large"}/>
                                                                                                    </IconButton>
                                                                                                </ListItemSecondaryAction>
                                                                                            </SectionListItem>
                                                                                        </SectionLink>
                                                                                    )
                                                                                })}
                                                                            </ExpansionPanelDetails>
                                                                        </ExpansionPanel>
                                                                    )
                                                                })}

                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                    );
                                                })
                                        }
                                    </List>
                            }
                            <Footer hiddenCR/>
                        </Container>
                    </Container>
                </BoxWrapper>
            </>
        )
    }
}


const SectionLink = styled(Link)`
    && {
    color: ${DARK} !important;
    text-decoration: none;
    text-decoration-color: none;
        &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    }
`;
const mapStateToProps = (state) => ({
    status: state.auth.status,
    name: state.auth.name,
    libSections: state.lib.libSections,
    openMode: state.auth.openMode,
    termsAgreed: state.lib.termsAgreed,
});

export default connect(mapStateToProps, {setLinks, setAuthorizationToken, setStatus, setName, setExpired, setTermsAgreed})(Library);

const SectionListItem = styled(ListItem)`
    &&{
        padding: 20px;
        }
`;

const SectionData = styled.div`
    
`;